import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const ArrowLeftIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.55171 10.7205L12.6365 7.42485L10.9565 5.63L5 11.9938L10.9565 18.3576L12.6365 16.5627L9.55082 13.2661H18.9999V10.7205H9.55171Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default ArrowLeftIcon;
