import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';

const currencyFormatter = ({
  currencyDisplayMode,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
}: {
  currencyDisplayMode: CurrencyDisplayMode;
  maximumFractionDigits: number;
  minimumFractionDigits: number;
}) => {
  const options: Intl.NumberFormatOptions = {
    // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits,

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits,

    style: 'decimal', // (causes 2500.99 to be printed as $2,501)
  };

  if (currencyDisplayMode === CurrencyDisplayMode.USD) {
    options.style = 'currency';
    options.currency = currencyDisplayMode;
  }

  return new Intl.NumberFormat('en-US', options);
};

export default currencyFormatter;
