import { isEmpty } from 'lodash';
import {
  DeclarativeMutationConfig,
  Disposable,
  Environment,
  GraphQLTaggedNode,
  IEnvironment,
  MutationConfig,
  MutationParameters,
  Network,
  PayloadError,
  RecordSource,
  SelectorStoreUpdater,
  Store,
  Uploadable,
  VariablesOf,
} from 'relay-runtime';

import { AUTH_TOKEN } from 'constants/Auth';
import MPGraphQLError from 'errors/MPGraphQLError';

//
type ExcludeKeysWithTypeOf<T, V> = {
  [K in keyof T]: Exclude<T[K], undefined> extends V ? never : K;
}[keyof T];

type IncludeKeysWithTypeOf<T, V> = {
  [K in keyof T]: Exclude<T[K], undefined> extends V ? K : never;
}[keyof T];

type Without<T, V> = Pick<T, ExcludeKeysWithTypeOf<T, V>>;

type With<T, V> = Pick<T, IncludeKeysWithTypeOf<T, V>>;

type SecretUploadableKeyName = '__SecretUploadableKeyName';
type ExcludeUploadables<T> = Without<T, SecretUploadableKeyName>;
type PickUploadables<T> = {
  [K in keyof With<T, SecretUploadableKeyName>]: Uploadable;
};
declare module 'react-relay' {
  export interface UseMPMutationConfig<TMutation extends MutationParameters> {
    variables: ExcludeUploadables<VariablesOf<TMutation>>;
    configs?: DeclarativeMutationConfig[] | undefined;
    onCompleted?:
      | ((
          response: TMutation['response'],
          errors: PayloadError[] | null
        ) => void | null)
      | undefined;
    onError?: ((error: Error) => void | null) | undefined;
    onUnsubscribe?: (() => void | null) | undefined;
    optimisticResponse?: TMutation['rawResponse'] | undefined;
    optimisticUpdater?:
      | SelectorStoreUpdater<TMutation['response']>
      | null
      | undefined;
    updater?: SelectorStoreUpdater<TMutation['response']> | null | undefined;
    uploadables?: PickUploadables<VariablesOf<TMutation>>;
  }

  export function useMutation<TMutation extends MutationParameters>(
    mutation: GraphQLTaggedNode,
    commitMutationFn?: (
      environment: IEnvironment,
      config: MutationConfig<TMutation>
    ) => Disposable
  ): [(config: UseMPMutationConfig<TMutation>) => Disposable, boolean];
}

function fetchQuery(operation, variables, cacheConfig, uploadables) {
  const requestVariables = {
    headers: {
      Accept: 'application/json',
      Authorization: '',
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };

  const currentAuthToken = localStorage.getItem(AUTH_TOKEN);

  if (currentAuthToken) {
    requestVariables.headers.Authorization = currentAuthToken;
  }

  let body;

  if (!isEmpty(uploadables)) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        operationName: operation.name,
        query: operation.text,
        variables,
      })
    );

    const pathMap = {};
    let i = 0;

    Object.keys(uploadables).forEach((key) => {
      pathMap[++i] = [`variables.${key}`];
    });
    formData.append('map', JSON.stringify(pathMap));

    i = 0;
    Object.keys(uploadables).forEach((key) => {
      formData.append(
        (++i).toString(),
        uploadables[key],
        uploadables[key].name
      );
    });

    body = formData;
    delete requestVariables.headers['Content-Type'];
  } else {
    requestVariables.headers['Content-Type'] = 'application/json';

    body = JSON.stringify({
      query: operation.text,
      variables,
    });
  }

  return window
    .fetch(`${process.env.apiServer || ''}/graphql/`, {
      ...requestVariables,
      body,
      credentials: 'include',
    })
    .then((response) => response.json())
    .then((result) => {
      if (result?.errors) {
        result?.errors.forEach((error) => {
          if (error?.code) {
            throw new MPGraphQLError(error);
          } else {
            throw error;
          }
        });
      }
      return result;
    });
}

export default new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});
