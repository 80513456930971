import { lazy } from 'react';
import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

const ImportTokensPage = lazy(() => import('./ImportTokensPage'));

const ImportTokensRoute = (
  <Route path="import-tokens" element={<ImportTokensPage />}>
    <Route index element={<SlashRedirect />} />
    <Route path="*" element={<Reload />} />
  </Route>
);

export default ImportTokensRoute;
