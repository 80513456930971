import { SvgIcon } from '@mui/material';

export default function CheckIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        strokeOpacity="1"
        strokeMiterlimit="4"
        d="M 22.999512 268.333659 L 25.949056 272.336914 L 31.975098 262.999512 "
        transform="matrix(1.846154,0,0,1.846154,-38.769231,-481.846154)"
      />
    </SvgIcon>
  );
}
