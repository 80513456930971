export { default as AlipayIcon } from './AlipayIcon';
export { default as AmexIcon } from './AmexIcon';
export { default as DinersIcon } from './DinersIcon';
export { default as DiscoverIcon } from './DiscoverIcon';
export { default as EloIcon } from './EloIcon';
export { default as GenericIcon } from './GenericIcon';
export { default as HiperIcon } from './HiperIcon';
export { default as JCBIcon } from './JCBIcon';
export { default as MastercardIcon } from './MastercardIcon';
export { default as MaestroIcon } from './MaestroIcon';
export { default as UnionPayIcon } from './UnionPayIcon';
export { default as VisaIcon } from './VisaIcon';
