.wallet {
  padding: 16px;
  border: 1px solid var(--color-SolidNeutralGray1);
  color: var(--color-SolidNeutralGray5);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.wallet:not(:has(> :global(.disabled))):not(:global(.disabled)):hover, .wallet:not(:has(> :global(.disabled))):not(:global(.disabled)):focus {
  border: 1px solid var(--color-commonBlack);
  cursor: pointer;
}
