import { useContext, useEffect, useRef } from 'react';

import IsDjangoContext from 'pages/navbar/IsDjangoContext';

// Prevents scroll on old django pages, due to modal elements, such as MPDialog or MPDrawer
export default function usePreventScrollEffect(open: boolean) {
  const eventListener = useRef(null);
  const isDjango = useContext(IsDjangoContext);

  useEffect(() => {
    // Unsure why, but within the django app, the first drawer opening causes the page to scroll to the bottom.
    if (!isDjango) return;
    if (open) {
      window.parent.document.removeEventListener(
        'scroll',
        eventListener.current
      );
      const { scrollTop } =
        window.parent.document.getElementsByTagName('html')[0];
      eventListener.current = () => {
        window.parent.document.getElementsByTagName('html')[0].scrollTop =
          scrollTop;
      };
      window.parent.document.addEventListener('scroll', eventListener.current);
      // eslint-disable-next-line consistent-return
      return () =>
        window.parent.document.removeEventListener(
          'scroll',
          eventListener.current
        );
    }
    window.parent.document.removeEventListener('scroll', eventListener.current);
  }, [open, isDjango]);
}
