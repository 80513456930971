import { useAccount } from 'wagmi';

import NFTsRankedAuctionLeaderboardQueryType, {
  NFTsRankedAuctionLeaderboardQuery,
} from 'graphql/__generated__/NFTsRankedAuctionLeaderboardQuery.graphql';

import usePollLoadQuery from 'hooks/graphqlLogic/usePollLoadQuery';
import useSession from 'hooks/useSession';
import { NFTRankedAuctionType, PurchasableNFTType } from 'types/graphql/NFT';
import { AccountConnectionStatus } from 'utils/jwt/walletUtils';
import { nftHasUserPlacedExistingRankedOffer } from 'utils/nftUtils';

import Leaderboard from './Leaderboard';

type NFTRankedAuctionProps = {
  nft: PurchasableNFTType;
  collapsible?: boolean;
  hasBottomBorder?: boolean;
  onAuctionUpdate?: (partialAuction: NFTRankedAuctionType) => void;
};

function NFTRankedAuction({
  nft,
  collapsible = false,
  hasBottomBorder = false,
  onAuctionUpdate,
}: NFTRankedAuctionProps) {
  const session = useSession();
  const { address: currentAccountAddress, status: currentAccountStatus } =
    useAccount();

  const [queryRef] = usePollLoadQuery<NFTsRankedAuctionLeaderboardQuery>({
    intervalMs: 3000,
    queryType: NFTsRankedAuctionLeaderboardQueryType,
    queryVariables: {
      digitalMediaId: +nft.metadata.pk,
      first: 1,
      isLiveOnChain: true,
    },
  });

  const hasUserPlacedRankedOffer = nftHasUserPlacedExistingRankedOffer(nft);

  return !queryRef ? null : (
    <Leaderboard
      queryRef={queryRef}
      collapsible={collapsible}
      currentUserAddress={
        currentAccountStatus === AccountConnectionStatus.Connected
          ? currentAccountAddress
          : null
      }
      currentUserLastBid={
        hasUserPlacedRankedOffer ? nft.listing.rankedAuction.lastBid : null
      }
      currentUsername={session.account?.username}
      currentUserWallets={session.account?.wallets}
      hasBottomBorder={hasBottomBorder}
      totalEditions={nft.listing.rankedAuction.count}
      onAuctionUpdate={onAuctionUpdate}
    />
  );
}

export default NFTRankedAuction;
