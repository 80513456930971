.container {
  box-sizing: border-box;
}

.rightSection {
  flex-grow: 1;
  flex-basis: 0;
}

.noEvents {
  pointer-events: none;
  user-select: none;
}

.actionButton {
  width: 45px;
  height: 45px;
}

.time {
  display: flex;
  justify-content: space-between;

  & > span {
    display: inline-block;
    text-align: center;
    width: 2ch;
  }
}

.progress {
  background-color: var(--color-SolidNeutralGray1);
  border-left: solid var(--color-commonBlack);
  border-left-width: var(--audioPlayer-progressWidth);
  box-sizing: border-box;
  display: block;
  height: 5px;
  position: relative;
  width: 100%;
  z-index: 0;

  > .progressIndicator {
    --audioPlayer-progressIndicatorSize: 11px;

    background-color: var(--color-commonBlack);
    border-radius: 50%;
    content: ' ';
    display: block;
    height: var(--audioPlayer-progressIndicatorSize);
    position: absolute;
    transform: translate(-50%, -25%);
    transition: width 200ms ease-in-out, height 200ms ease-in-out, transform 200ms ease-in-out;
    width: var(--audioPlayer-progressIndicatorSize);
    z-index: 1;

    &:hover {
      --audioPlayer-progressIndicatorSize: 14px;

      cursor: pointer;
      transform: translate(-50%, -30%);
    }
  }
}

.hiddenAudioPlayer {
  display: none;
}
