import { SvgIcon } from '@mui/material';

export default function NorthEastArrowIconIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="surface1">
        <path
          fill="none"
          strokeWidth="2.005208"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
          strokeMiterlimit="4"
          d="M 0.430501 8.562337 L 8.521159 0.811849 "
          transform="matrix(2.181818,0,0,2.181818,2.181818,2.181818)"
        />
        <path
          fill="none"
          strokeWidth="2.005208"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
          strokeMiterlimit="4"
          d="M 8.521159 0.811849 L 3.733724 0.811849 "
          transform="matrix(2.181818,0,0,2.181818,2.181818,2.181818)"
        />
        <path
          fill="none"
          strokeWidth="2.005208"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
          strokeMiterlimit="4"
          d="M 8.521159 5.278809 L 8.521159 0.811849 "
          transform="matrix(2.181818,0,0,2.181818,2.181818,2.181818)"
        />
      </g>
    </SvgIcon>
  );
}
