import { useCallback, useState } from 'react';

import { MPActionButton } from '@mp-frontend/core-components';

import SubSection from '../common/SubSection';
import PasswordDialog from './PasswordDialog';

export default function PasswordSubSection() {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handlePasswordModalOpen = useCallback(
    () => setShowPasswordModal(true),
    []
  );
  const handlePasswordModalClose = useCallback(
    () => setShowPasswordModal(false),
    []
  );

  return (
    <>
      <SubSection title="Password">
        <MPActionButton
          fullWidth
          size="large"
          variant="secondary"
          onClick={handlePasswordModalOpen}
        >
          Change Password
        </MPActionButton>
      </SubSection>

      {!!showPasswordModal && (
        <PasswordDialog onClose={handlePasswordModalClose} />
      )}
    </>
  );
}
