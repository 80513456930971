import { fetchQuery } from 'react-relay';

import SessionCreatorContractsQueryNode, {
  SessionCreatorContractsQuery,
} from 'graphql/__generated__/SessionCreatorContractsQuery.graphql';

import RelayEnvironment from '../../RelayEnvironment';

export default async function getContracts() {
  const contracts = fetchQuery<SessionCreatorContractsQuery>(
    RelayEnvironment,
    SessionCreatorContractsQueryNode,
    {}
  );
  const res = await contracts.toPromise();
  return res?.creatorContracts;
}
