import { Fragment } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import { paddedNumberToString } from 'utils/nftUtils';

import * as styles from 'css/components/auction/AuctionTimer.module.css';

interface AuctionTimerProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  className?: string;
}

function AuctionTimer({
  className,
  days,
  hours,
  minutes,
  seconds,
}: AuctionTimerProps) {
  const countdownValues = [days || null, hours, minutes, seconds].map((value) =>
    paddedNumberToString(value)
  );

  return (
    <div className={joinClasses(styles.container, className)}>
      {countdownValues.map((value, idx) =>
        value ? (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={idx}>
            <span style={{ width: `${value.length}ch` }}>{value}</span>
            {idx === countdownValues.length - 1 ? '' : ':'}
          </Fragment>
        ) : null
      )}
    </div>
  );
}

export default AuctionTimer;
