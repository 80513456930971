import { MouseEvent, useCallback, useState } from 'react';

import { MPStandardDialog } from '@mp-frontend/core-components';
import { useOnEnterKey } from '@mp-frontend/core-utils';

import CopyLinkButton from 'components/buttons/shareButtons/CopyLinkButton';
import TwitterButton from 'components/buttons/shareButtons/TwitterButton';
import { NFTType } from 'types/graphql/NFT';
import { generateTweetText } from 'utils/nftUtils';

import * as styles from 'css/pages/product/ProductSocialBar.module.css';

export default function useShareProduct({ nft }: { nft: NFTType }) {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const link = window.location.href;
  const tweet = generateTweetText(nft);

  const handleOpenMouseClick = useCallback((event: MouseEvent) => {
    event.preventDefault();

    setShowDialog(true);
  }, []);
  const handleOpenKeyboardPress = useOnEnterKey(handleOpenMouseClick);

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, []);

  return [
    handleOpenMouseClick,
    handleOpenKeyboardPress,

    <MPStandardDialog
      title="Share this Artwork"
      open={showDialog}
      onClose={handleClose}
      maxDesktopWidth={390}
    >
      <div className={styles.shareDialog}>
        <TwitterButton link={link} tweet={tweet} />
        <CopyLinkButton link={link} />
      </div>
    </MPStandardDialog>,
  ] as const;
}
