import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { ModularPagesSectionsQuery } from 'graphql/__generated__/ModularPagesSectionsQuery.graphql';
import {
  PageCardPositionEnum,
  PageCardTypeEnum,
} from 'types/__generated__/graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import EditorialCard from 'components/cards/EditorialCard';
import ExhibitionCard from 'components/cards/ExhibitionCard';
import SeriesCard from 'components/cards/SeriesCard';
import UserCard from 'components/cards/UserCard';
import Markdown from 'components/common/Markdown';
import ROUTES from 'constants/Routes';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/modularPage/sections/TextWithCardBlockSection.module.css';

type TextWithCardBlockType =
  ModularPagesSectionsQuery['response']['modularPageSections']['edges'][number]['node']['textWithCardBlock'];

interface TextWithCardBlockSectionProps {
  textWithCardBlock: TextWithCardBlockType;
  className?: string;
}

export default function TextWithCardBlockSection({
  className,
  textWithCardBlock,
}: TextWithCardBlockSectionProps) {
  return (
    textWithCardBlock && (
      <section
        className={joinClasses(
          CSSGlobal.Flex.InlineRowCenterAlign,
          CSSGap[32],
          styles.container,
          className,
          {
            [styles.reversed]:
              textWithCardBlock.cardPosition === PageCardPositionEnum.Left,
          }
        )}
      >
        <div
          className={joinClasses(
            MPFonts.paragraphSmall,
            CSSGlobal.Cursor.Default,
            styles.descriptionColumn
          )}
        >
          <Markdown>{textWithCardBlock.description}</Markdown>
        </div>

        <div className={styles.cardColumn}>
          {textWithCardBlock.cardType === PageCardTypeEnum.Artist ? (
            <UserCard
              user={textWithCardBlock.artist}
              biography={textWithCardBlock.artist.biography}
              createdArtworks={textWithCardBlock.artist.createdArtworks}
              store={textWithCardBlock.artist.store}
            />
          ) : textWithCardBlock.cardType === PageCardTypeEnum.Artwork ? (
            <ArtworkCard hideTag nft={textWithCardBlock.artwork} />
          ) : textWithCardBlock.cardType === PageCardTypeEnum.Editorial ? (
            <EditorialCard
              editorial={{
                ...textWithCardBlock.editorial,
                order: 0,
                viewUrl: ROUTES.MODULAR_PAGE(textWithCardBlock.editorial.slug),
              }}
            />
          ) : textWithCardBlock.cardType === PageCardTypeEnum.Exhibition ? (
            <ExhibitionCard exhibition={textWithCardBlock.exhibition} />
          ) : textWithCardBlock.cardType === PageCardTypeEnum.Collection ? (
            <SeriesCard series={textWithCardBlock.collection} />
          ) : null}
        </div>
      </section>
    )
  );
}
