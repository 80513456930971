.container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  width: 100%;
}

.content {
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}
