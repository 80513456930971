import { Dispatch, SetStateAction, useState } from 'react';

import {
  MPActionButton,
  MpConfirmDialogProps,
} from '@mp-frontend/core-components';

import StackStateDialog from './StackStateDialog';

/**
 * StackStateConfirm Dialog differs from MPConfirm dialog in styling, since the intention is to
 * display the confirm dialog within the same modal, we keep the same styling as the standard dialog
 * and disallow for custom stylings like MPConfirmDialog does.
 */
export default function StackStateConfirmDialog({
  open,
  onCancel,
  onConfirm,
  title,
  content,
  confirmText = 'Confirm',
}: MpConfirmDialogProps) {
  return (
    <StackStateDialog
      title={title}
      open={open}
      onClose={onCancel}
      actionButton={
        <MPActionButton
          size="large"
          key="confirm"
          fullWidth
          onClick={onConfirm}
          autoFocus
        >
          {confirmText}
        </MPActionButton>
      }
    >
      {content}
    </StackStateDialog>
  );
}

export function useStackStateConfirmDialog({
  title,
  content,
  onCancel,
  onConfirm,
  confirmText,
}: Omit<MpConfirmDialogProps, 'open'>): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  any
] {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const cancel = () => {
    close();
    onCancel();
  };
  const confirm = async () => {
    await onConfirm();
    close();
  };

  return [
    open,
    setOpen,
    <>
      {!!open && (
        <StackStateConfirmDialog
          open={open}
          content={content}
          onCancel={cancel}
          onConfirm={confirm}
          title={title}
          confirmText={confirmText}
        />
      )}
    </>,
  ];
}
