/**
 * Not the DRYest component out there. We should really be playing with Relay cache more as well,
 * rather than invalidating and refetching from network all the time. But that adds scope and time
 * we do not have right now. - jw 5.24.23
 */
import { startTransition } from 'react';
import { PreloadedQuery, useMutation, usePreloadedQuery } from 'react-relay';

import {
  MPColorClass,
  MPColorValue,
  MPFonts,
  MPSwitch,
} from '@mp-frontend/core-components';
import {
  LikesIcon,
  NotifyCommentedIcon,
  NotifyFollowIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import NotificationsMuteMutation, {
  MuteNotificationsArguments,
  NotificationsMuteMutation as NotificationsMuteMutationParameters,
  NotificationsMuteMutation$data,
} from 'graphql/__generated__/NotificationsMuteMutation.graphql';
import NotificationsSettingsNode, {
  NotificationsSettingsQuery,
} from 'graphql/__generated__/NotificationsSettingsQuery.graphql';
import { NotificationActionEnum } from 'types/__generated__/graphql';

import GTM from 'GTM';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/Navbar/Unified/Notifications/NotificationsSettings.module.css';

type NotificationsSettingsProps = {
  invalidateNotificationCount: () => void;
  invalidateNotificationSettings: () => void;
  invalidateNotifications: () => void;
  notificationsSettingsQueryRef: PreloadedQuery<NotificationsSettingsQuery>;
};

export default function NotificationsSettings({
  notificationsSettingsQueryRef,
  invalidateNotificationSettings,
  invalidateNotificationCount,
  invalidateNotifications,
}: NotificationsSettingsProps) {
  const [
    commitNotificationsMuteMutation,
    isCommitNotificationsMuteMutationInFlight,
  ] = useMutation(NotificationsMuteMutation);

  const { notificationsSettings } =
    usePreloadedQuery<NotificationsSettingsQuery>(
      NotificationsSettingsNode,
      notificationsSettingsQueryRef
    );

  /** One problem we have is our API returns a mute object only if the user has muted it before.
   * Therefore, we can't simply map over the response list and generate these switches/values. For now,
   * we'll hard-code the limited set of JSX elements.
   *
   * TODO: In the future, have the backend return data so that we can .map() generate these settings.
   */
  const likesMuted =
    notificationsSettings.find(
      (a) => a.objectAction === NotificationActionEnum.Liked
    )?.isMuted ?? false;
  const commentsMuted =
    notificationsSettings.find(
      (a) => a.objectAction === NotificationActionEnum.Commented
    )?.isMuted ?? false;
  const followsMuted =
    notificationsSettings.find(
      (a) => a.objectAction === NotificationActionEnum.Followed
    )?.isMuted ?? false;

  const commitNotificationsMuteMutationAsync = async (
    requestData: MuteNotificationsArguments
  ): Promise<NotificationsMuteMutation$data> =>
    new Promise((onCompleted, onError) => {
      commitNotificationsMuteMutation({
        onCompleted: (
          response: NotificationsMuteMutationParameters['response']
        ) => {
          if (response.muteNotifications.success) {
            startTransition(() => {
              if (invalidateNotificationCount) {
                invalidateNotificationCount(); // TODO: Handle iframe case if fn null
              }
              invalidateNotificationSettings();
              invalidateNotifications();

              switch (requestData.objectAction) {
                case NotificationActionEnum.Commented:
                  GTM.notification.trackCommentToggle(!requestData.isMuted);
                  break;
                case NotificationActionEnum.Followed:
                  GTM.notification.trackFollowToggle(!requestData.isMuted);
                  break;
                case NotificationActionEnum.Liked:
                  GTM.notification.trackLikeToggle(!requestData.isMuted);
                  break;
                default:
                  break;
              }
            });
          }
        },
        onError,
        variables: {
          request_data: requestData,
        },
      });
    });

  const toggleNotificationSetting = async (
    requestData: MuteNotificationsArguments
  ) => {
    await commitNotificationsMuteMutationAsync(requestData);
  };

  return (
    <div
      className={joinClasses(
        CSSGlobal.Flex.Col,
        styles.notificationsSettingsBody
      )}
    >
      <div
        className={joinClasses(MPFonts.paragraphSmall, styles.instructionBox)}
      >
        Control which notifications you would like to receive.
      </div>

      <div
        className={joinClasses(
          CSSGlobal.Flex.Row,
          styles.notificationsSettingsRow
        )}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.Row,
            styles.notificationsSettingsRowLeft
          )}
        >
          <div className={joinClasses('flexCenter', styles.centerIcon)}>
            <LikesIcon
              htmlColor={MPColorValue.SolidNeutralGray3 as string}
              fontSize="17"
            />
          </div>
          <span
            className={joinClasses(
              MPFonts.notificationSettingsLabel,
              MPColorClass.CommonBlack
            )}
          >
            Likes&nbsp;
          </span>
        </div>
        <MPSwitch
          className={styles.notificationsSettingsRowRight}
          name="isHiddenOnCreate"
          checked={!likesMuted}
          onChange={() =>
            isCommitNotificationsMuteMutationInFlight
              ? null
              : toggleNotificationSetting({
                  isMuted: !likesMuted,
                  objectAction: NotificationActionEnum.Liked,
                })
          }
        />
      </div>
      <div className={styles.notificationsSettingsDivider} />
      <div
        className={joinClasses(
          CSSGlobal.Flex.Row,
          styles.notificationsSettingsRow
        )}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.Row,
            styles.notificationsSettingsRowLeft
          )}
        >
          <div className={joinClasses('flexCenter', styles.centerIcon)}>
            <NotifyCommentedIcon htmlColor={MPColorValue.SolidNeutralGray3} />
          </div>
          <span
            className={joinClasses(
              MPFonts.notificationSettingsLabel,
              MPColorClass.CommonBlack
            )}
          >
            Comments&nbsp;
          </span>
        </div>
        <MPSwitch
          className={styles.notificationsSettingsRowRight}
          name="isHiddenOnCreate"
          checked={!commentsMuted}
          onChange={() =>
            isCommitNotificationsMuteMutationInFlight
              ? null
              : toggleNotificationSetting({
                  isMuted: !commentsMuted,
                  objectAction: NotificationActionEnum.Commented,
                })
          }
        />
      </div>
      <div className={styles.notificationsSettingsDivider} />

      <div
        className={joinClasses(
          CSSGlobal.Flex.Row,
          styles.notificationsSettingsRow
        )}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.Row,
            styles.notificationsSettingsRowLeft
          )}
        >
          <div className={joinClasses('flexCenter', styles.centerIcon)}>
            <NotifyFollowIcon
              htmlColor={MPColorValue.SolidNeutralGray3}
              fontSize="27"
            />
          </div>
          <span
            className={joinClasses(
              MPFonts.notificationSettingsLabel,
              MPColorClass.CommonBlack
            )}
          >
            Follows&nbsp;
          </span>
        </div>
        <MPSwitch
          className={styles.notificationsSettingsRowRight}
          name="isHiddenOnCreate"
          checked={!followsMuted}
          onChange={() =>
            isCommitNotificationsMuteMutationInFlight
              ? null
              : toggleNotificationSetting({
                  isMuted: !followsMuted,
                  objectAction: NotificationActionEnum.Followed,
                })
          }
        />
      </div>
      <div className={styles.notificationsSettingsDivider} />
    </div>
  );
}
