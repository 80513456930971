import MPSVGIcon from './MPSVGIcon';

export default function MetadataIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9964 4.93994L22.3865 11.9809L15.9732 19.0553L13.3464 16.674L17.5998 11.9822L13.371 7.32268L15.9964 4.93994ZM8.01341 4.94067L10.6402 7.32195L6.38679 12.0138L10.6156 16.6733L7.99019 19.056L1.6001 12.0151L8.01341 4.94067Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
