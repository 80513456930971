/**
 * @generated SignedSource<<135f48b14a2e0313aca160177b1919eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HomepageEditorialTilesQuery$variables = {};
export type HomepageEditorialTilesQuery$data = {
  readonly nftEditorials: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly backgroundImageUrl: string | null;
        readonly description: string;
        readonly id: string;
        readonly order: number;
        readonly pk: string;
        readonly title: string;
        readonly type: string;
        readonly viewUrl: string;
      } | null;
    } | null>;
  } | null;
};
export type HomepageEditorialTilesQuery = {
  variables: HomepageEditorialTilesQuery$variables;
  response: HomepageEditorialTilesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 12
      }
    ],
    "concreteType": "NFTEditorialTypeConnection",
    "kind": "LinkedField",
    "name": "nftEditorials",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NFTEditorialTypeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTEditorialType",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundImageUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pk",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "nftEditorials(first:12)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomepageEditorialTilesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomepageEditorialTilesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0b448a6e65ae6d87945cd127b24a77f7",
    "id": null,
    "metadata": {},
    "name": "HomepageEditorialTilesQuery",
    "operationKind": "query",
    "text": "query HomepageEditorialTilesQuery {\n  nftEditorials(first: 12) {\n    edges {\n      node {\n        backgroundImageUrl\n        description\n        id\n        order\n        pk\n        title\n        type\n        viewUrl\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0763977cd7f9d5dba0981e35b463feb3";

export default node;
