/**
 * @generated SignedSource<<3fa1ce58499a1f5028c4f63e58d08d09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NFTsRelatedSlideShowQuery$variables = {
  cursor?: string | null;
  digitalMediaId?: number | null;
  first?: number | null;
  relatedImageLimit?: number | null;
};
export type NFTsRelatedSlideShowQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NFTsRelatedSlideShow">;
};
export type NFTsRelatedSlideShowQuery = {
  variables: NFTsRelatedSlideShowQuery$variables;
  response: NFTsRelatedSlideShowQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "digitalMediaId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "relatedImageLimit"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "digitalMediaId",
    "variableName": "digitalMediaId"
  },
  {
    "kind": "Literal",
    "name": "filterPublished",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Literal",
    "name": "isLiveOnChain",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "relatedImageLimit",
    "variableName": "relatedImageLimit"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTsRelatedSlideShowQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NFTsRelatedSlideShow"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTsRelatedSlideShowQuery",
    "selections": [
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMetadataAbstractType",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasVideo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "videoUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailImage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "standardImage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "highResImage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTMediaMetadataType",
                        "kind": "LinkedField",
                        "name": "mediaMetadata",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "width",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "height",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "filesize",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountAbstractType",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTListingAbstractType",
                    "kind": "LinkedField",
                    "name": "listing",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullNftUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": "results",
        "args": (v1/*: any*/),
        "filters": [
          "filterPublished",
          "isLiveOnChain",
          "relatedImageLimit",
          "digitalMediaId"
        ],
        "handle": "connection",
        "key": "NFTsRelatedSlideShow__results",
        "kind": "LinkedHandle",
        "name": "nfts"
      }
    ]
  },
  "params": {
    "cacheID": "202e322a1e53043979d92742e0bf0ae2",
    "id": null,
    "metadata": {},
    "name": "NFTsRelatedSlideShowQuery",
    "operationKind": "query",
    "text": "query NFTsRelatedSlideShowQuery(\n  $cursor: String\n  $digitalMediaId: Float\n  $first: Int\n  $relatedImageLimit: Float\n) {\n  ...NFTsRelatedSlideShow\n}\n\nfragment NFTsRelatedSlideShow on Query {\n  results: nfts(first: $first, after: $cursor, filterPublished: true, isLiveOnChain: true, relatedImageLimit: $relatedImageLimit, digitalMediaId: $digitalMediaId) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        pk\n        metadata {\n          id\n          pk\n          title\n          hasVideo\n          videoUrl\n          thumbnailImage\n          standardImage\n          highResImage\n          mediaMetadata {\n            id\n            width\n            height\n            filesize\n          }\n          author {\n            fullName\n            id\n          }\n        }\n        listing {\n          pk\n          id\n          fullNftUrl\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1166e9939a578ab35b1b24403d1c7bd6";

export default node;
