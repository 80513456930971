import {
  MouseEvent,
  startTransition,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  MPIconButton,
  MPIconButtonThemes,
} from '@mp-frontend/core-components';
import { PauseIcon, PlayIcon } from '@mp-frontend/core-components/icons';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import { ModularPagesSectionsQuery } from 'graphql/__generated__/ModularPagesSectionsQuery.graphql';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSPadding from 'types/enums/css/Padding';

import * as styles from 'css/pages/modularPage/sections/AudioPlayerSection.module.css';

const formatMsToTime = (sec: number): string[] => {
  const totalSeconds = Math.floor(sec);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const padded = (num: number) => num.toString().padStart(2, '0');

  const hours = totalHours % 24;
  const minutes = totalMinutes % 60;
  const seconds = totalSeconds % 60;

  return [hours && padded(hours), padded(minutes), padded(seconds)].filter(
    Boolean
  );
};

type AudioBlockType =
  ModularPagesSectionsQuery['response']['modularPageSections']['edges'][number]['node']['audioBlock'];

interface AudioPlayerSectionProps {
  audioBlock: AudioBlockType;
}

export default function AudioPlayerSection({
  audioBlock,
}: AudioPlayerSectionProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const PlayPauseIcon = useMemo(
    () =>
      isPlaying ? <PauseIcon fontSize="16" /> : <PlayIcon fontSize="20" />,
    [isPlaying]
  );
  const formattedDuration = useMemo(() => formatMsToTime(duration), [duration]);
  const formattedCurrentTime = useMemo(
    () => formatMsToTime(currentTime),
    [currentTime]
  );
  const progressWidth = useMemo(
    () =>
      (currentTime / duration) *
      (progressRef.current?.getBoundingClientRect()?.width || 0),
    [currentTime, duration]
  );

  const handleLoadMetadata = useCallback(
    (event: SyntheticEvent<HTMLAudioElement>) =>
      setDuration(event.currentTarget.duration),
    []
  );
  const handleTimeUpdate = useCallback(
    (event: SyntheticEvent<HTMLAudioElement>) =>
      setCurrentTime(event.currentTarget.currentTime),
    []
  );
  const handlePlay = useCallback(() => setIsPlaying(true), []);
  const handlePause = useCallback(() => {
    setIsPlaying(false);
    if (audioRef.current?.currentTime === duration) {
      audioRef.current.currentTime = 0;
      setCurrentTime(audioRef.current.currentTime);
    }
  }, [duration]);
  const handleTogglePlayPause = useCallback(async () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      await audioRef.current?.play().catch(() => {});
    }
  }, [isPlaying]);
  const handleSeek = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const progressBar = event.currentTarget;
      const clickX = event.clientX - progressBar.getBoundingClientRect().left;
      const progressBarWidth = progressBar.offsetWidth;
      const isPlayingBeforeSeek = !audioRef.current!.paused;
      audioRef.current!.pause();
      const newTime = (clickX / progressBarWidth) * duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
      startTransition(() => {
        if (isPlayingBeforeSeek) audioRef.current.play();
        else audioRef.current.pause();
      });
    },
    [duration]
  );
  const ariaSeekOnEnter = useOnEnterKey(handleSeek);
  const handleMouseDown = useCallback(() => setIsDragging(true), []);
  const handleMouseUp = useCallback(() => setIsDragging(false), []);
  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isDragging) return;

      const { left: progressBarLeft, width: progressBarWidth } =
        progressRef.current.getBoundingClientRect();
      const newProgressBarLeft = Math.max(
        0,
        Math.min(event.clientX - progressBarLeft, progressBarWidth)
      );
      audioRef.current.currentTime =
        (newProgressBarLeft / progressBarWidth) * duration;
    },
    [duration, isDragging]
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove as any);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove as any);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    <section
      className={joinClasses(
        MPBackgroundColorClass.CommonWhite,
        MPColorClass.CommonBlack,
        CSSGlobal.Cursor.Default,
        CSSGlobal.Flex.InlineRowCenterAlign,
        CSSGap[10],
        CSSPadding.AROUND[24],
        styles.container
      )}
    >
      <MPIconButton
        theme={MPIconButtonThemes.DARK}
        className={styles.actionButton}
        onClick={handleTogglePlayPause}
      >
        {PlayPauseIcon}
      </MPIconButton>

      <div
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGap[4],
          styles.rightSection
        )}
      >
        <div className={joinClasses(MPFonts.textSmallMedium, styles.noEvents)}>
          {audioBlock.title}
        </div>
        <div
          ref={progressRef}
          className={styles.progress}
          style={
            {
              '--audioPlayer-progressWidth': `${progressWidth}px`,
            } as any
          }
          role="slider"
          aria-label="Audio progress"
          aria-valuenow={currentTime}
          tabIndex={0}
          onClick={handleSeek}
          onKeyPress={ariaSeekOnEnter}
        >
          <div
            className={styles.progressIndicator}
            role="slider"
            aria-label="Audio progress indicator"
            aria-valuenow={currentTime}
            tabIndex={0}
            onMouseDown={handleMouseDown}
          />
        </div>
        <div
          className={joinClasses(
            MPFonts.textSmallRegular,
            MPColorClass.SolidNeutralGray5,
            CSSGlobal.Flex.Row,
            styles.noEvents
          )}
        >
          <div className={styles.time}>
            {formattedCurrentTime.map((time, index, array) => (
              <>
                {/* eslint-disable-next-line react/no-array-index-key */}
                <span key={index}>{time}</span>
                {index === array.length - 1 ? '' : ':'}
              </>
            ))}
          </div>
          /
          <div className={styles.time}>
            {formattedDuration.map((time, index, array) => (
              <>
                {/* eslint-disable-next-line react/no-array-index-key */}
                <span key={index}>{time}</span>
                {index === array.length - 1 ? '' : ':'}
              </>
            ))}
          </div>
        </div>
      </div>

      <audio
        ref={audioRef}
        className={styles.hiddenAudioPlayer}
        src={audioBlock.audioUrl}
        onLoadedMetadata={handleLoadMetadata}
        onLoadedData={handleLoadMetadata}
        onTimeUpdate={handleTimeUpdate}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handlePause}
      />
    </section>
  );
}
