import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { ProductTypeBidEnum } from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';
import IsGlobalContractError from 'utils/errors/contracts/global';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

interface PaymentViewPricingProps {
  amountInEth: number;
  amountInUsd: number;
  paymentMethod: ProductTypeBidEnum;
  error?: Error;
}

export default function PaymentViewPricing({
  amountInEth,
  amountInUsd,
  paymentMethod,
  error,
}: PaymentViewPricingProps) {
  const displayableError = IsGlobalContractError.InsufficientFunds(error)
    ? error
    : null;

  return (
    <div className={styles.paymentViewPricing}>
      <ErrorDisplay error={displayableError} />
      <div
        className={joinClasses(
          CSSGlobal.Flex.RowSpaceBetween,
          CSSGlobal.Flex.RowCenterAlign
        )}
      >
        <div className={MPFonts.textNormalMedium}>Total</div>
        <div
          className={joinClasses(
            MPFonts.subPrice,
            styles.paymentViewPricingValues
          )}
        >
          {paymentMethod === ProductTypeBidEnum.Creditcard ? (
            <>
              <span>
                {generatePriceString(amountInUsd, CurrencyDisplayMode.USD)}
              </span>
              <span className={styles.paymentViewPricingSecondary}>
                {generatePriceString(amountInEth, CurrencyDisplayMode.ETH)}
              </span>
            </>
          ) : (
            <>
              <span>
                {generatePriceString(amountInEth, CurrencyDisplayMode.ETH)}
              </span>
              <span className={styles.paymentViewPricingSecondary}>
                {generatePriceString(amountInUsd, CurrencyDisplayMode.USD)}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
