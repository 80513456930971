import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const OpenSeaIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon ref={ref} {...props}>
    <path
      d="M15.605 13.6158C15.2927 14.0343 14.6357 14.7291 14.2703 14.7291H12.572V13.6149H13.9247C14.1182 13.6149 14.3027 13.5366 14.4359 13.3989C15.1307 12.6789 15.5321 11.8221 15.5321 10.9032C15.5321 9.33543 14.3603 7.94943 12.5711 7.11423V6.36543C12.5711 6.04323 12.3101 5.78223 11.9879 5.78223C11.6657 5.78223 11.4047 6.04323 11.4047 6.36543V6.67593C10.9034 6.52923 10.3724 6.41763 9.81797 6.35373C10.736 7.35093 11.2967 8.68473 11.2967 10.1472C11.2967 11.4585 10.8485 12.6627 10.0952 13.6167H11.4047V14.7327H9.48767C9.22847 14.7327 9.01787 14.523 9.01787 14.2629V13.7751C9.01787 13.6896 8.94857 13.6194 8.86217 13.6194H6.33497C6.28547 13.6194 6.24317 13.6599 6.24317 13.7094C6.24137 15.7056 7.82087 17.2041 9.70997 17.2041H15.0425C16.3205 17.2041 16.892 15.5661 17.6408 14.5239C17.9315 14.1207 18.629 13.7967 18.8405 13.7067C18.8792 13.6905 18.9017 13.6545 18.9017 13.6122V12.9642C18.9017 12.8985 18.8369 12.849 18.773 12.867C18.773 12.867 15.6905 13.5753 15.6545 13.5852C15.6185 13.596 15.605 13.6167 15.605 13.6167V13.6158Z"
      fill="transparent"
    />
    <path
      d="M10.3107 10.138C10.3107 9.13273 9.96596 8.20753 9.38996 7.47583L6.48926 12.4951H9.60956C10.0533 11.8183 10.3116 11.0083 10.3116 10.1389L10.3107 10.138Z"
      fill="transparent"
    />
    <path
      d="M12.0786 3.00031C7.04581 2.95891 2.95891 7.04581 3.00031 12.0786C3.04261 16.9656 7.03501 20.9589 11.9211 20.9994C16.9539 21.0426 21.0426 16.9539 20.9994 11.9211C20.9589 7.03591 16.9656 3.04261 12.0786 3.00031ZM9.38761 7.47961C9.96361 8.21131 10.3083 9.13741 10.3083 10.1418C10.3083 11.0121 10.05 11.8221 9.60631 12.498H6.48601L9.38671 7.47871L9.38761 7.47961ZM18.9006 12.9624V13.6104C18.9006 13.6527 18.8781 13.6887 18.8394 13.7049C18.6279 13.7949 17.9304 14.1189 17.6397 14.5221C16.8909 15.5643 16.3194 17.2023 15.0414 17.2023H9.70891C7.82071 17.2023 6.24121 15.7038 6.24211 13.7076C6.24211 13.6581 6.28441 13.6176 6.33391 13.6176H8.86111C8.94841 13.6176 9.01681 13.6878 9.01681 13.7733V14.2611C9.01681 14.5203 9.22651 14.7309 9.48661 14.7309H11.4036V13.6149H10.0941C10.8474 12.6609 11.2956 11.4567 11.2956 10.1454C11.2956 8.68291 10.7349 7.34911 9.81691 6.35191C10.3722 6.41671 10.9032 6.52741 11.4036 6.67411V6.36361C11.4036 6.04141 11.6646 5.78041 11.9868 5.78041C12.309 5.78041 12.57 6.04141 12.57 6.36361V7.11241C14.3592 7.94761 15.531 9.33361 15.531 10.9014C15.531 11.8212 15.1287 12.6771 14.4348 13.3971C14.3016 13.5348 14.1171 13.6131 13.9236 13.6131H12.5709V14.7273H14.2692C14.6355 14.7273 15.2925 14.0325 15.6039 13.614C15.6039 13.614 15.6174 13.5933 15.6534 13.5825C15.6894 13.5717 18.7719 12.8643 18.7719 12.8643C18.8367 12.8463 18.9006 12.8958 18.9006 12.9615V12.9624Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default OpenSeaIcon;
