import { useCallback, useState } from 'react';
import { useMutation } from 'react-relay';
import { useAccount, useSignMessage } from 'wagmi';

import SessionGenerateNonceMutation, {
  GenerateNonceArguments,
  SessionGenerateNonceMutation$data,
} from 'graphql/__generated__/SessionGenerateNonceMutation.graphql';
import SessionLoginWithWalletMutation, {
  SessionLoginWithWalletMutation$data,
} from 'graphql/__generated__/SessionLoginWithWalletMutation.graphql';
import { LoginWithWalletArguments } from 'types/__generated__/graphql';

import saveBearerToken from 'utils/jwt/saveBearerToken';
import saveBearerTokenExpireAt from 'utils/jwt/saveBearerTokenExpireAt';
import { HexString } from 'utils/jwt/walletUtils';
import promisifyMutation from 'utils/promisifyMutation';

import { useRefreshSession } from './useSession';

import Session from 'Session';

export default function useSignInWithWallet() {
  const { signMessageAsync, reset } = useSignMessage();
  const { address } = useAccount();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);

  const resetSignIn = () => {
    reset();
    setIsLoading(false);
    setIsError(false);
    setError('');
    setIsInProgress(false);
  };

  const [commitGenerateNonceMutation] = useMutation(
    SessionGenerateNonceMutation
  );

  const [commitLoginWithWalletMutation] = useMutation(
    SessionLoginWithWalletMutation
  );

  const commitGenerateNonceAsync = promisifyMutation<
    { requestData: GenerateNonceArguments },
    SessionGenerateNonceMutation$data
  >(commitGenerateNonceMutation);

  const loginWithWallet = promisifyMutation<
    { requestData: LoginWithWalletArguments },
    SessionLoginWithWalletMutation$data
  >(commitLoginWithWalletMutation);

  const refreshSession = useRefreshSession();

  const signInWithWalletOperation = async (signInAddress?: HexString) => {
    if (isInProgress) return null;

    resetSignIn();

    try {
      setIsLoading(true);
      setIsInProgress(true);

      const walletAddress = signInAddress || address;

      const { generateSignatureMessage } = await commitGenerateNonceAsync({
        requestData: { walletAddress },
      });

      const signature = await signMessageAsync({
        account: walletAddress,
        message: generateSignatureMessage.message,
      });

      const walletAuthenticationPayload = await loginWithWallet({
        requestData: {
          key: generateSignatureMessage.key,
          signature,
          walletAddress,
        },
      });

      if (!walletAuthenticationPayload) {
        throw new Error('Could not sign in with wallet');
      }

      saveBearerToken(walletAuthenticationPayload.loginWithWallet.token);
      saveBearerTokenExpireAt(
        walletAuthenticationPayload.loginWithWallet.expireAt
      );

      Session.processLogin(
        walletAuthenticationPayload.loginWithWallet.token,
        walletAuthenticationPayload.loginWithWallet.expireAt
      );

      await Session.awaitSessionData();
      refreshSession();
      setIsInProgress(false);
      setIsLoading(false);
      return walletAuthenticationPayload;
    } catch (signInError) {
      setError(signInError.message || signInError.toString());
      setIsError(true);
      setIsLoading(false);
      setIsInProgress(false);
      throw signInError;
    }
  };

  const signInWithWallet = useCallback(signInWithWalletOperation, [
    signInWithWalletOperation,
    signMessageAsync,
    address,
    commitGenerateNonceAsync,
    isInProgress,
    loginWithWallet,
  ]);

  return {
    error,
    isError,
    isLoading: isLoading || isInProgress,
    reset: resetSignIn,
    signInWithWallet,
  };
}
