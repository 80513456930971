import { useEffect } from 'react';

import useLogout from 'hooks/useLogout';

export default function LogoutPage() {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return <div />;
}
