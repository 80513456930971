import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const CollectorRewardIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M11.9799 8.79755L14.3562 4H9.69995L11.9799 8.79755Z"
      fill="currentColor"
    />
    <path
      d="M10.2698 8.86277L7.94168 4.17944L4.10425 8.86277H10.2698Z"
      fill="currentColor"
    />
    <path
      d="M19.8877 8.86277L16.0342 4.17944L13.7061 8.86277H19.8797H19.8877Z"
      fill="currentColor"
    />
    <path
      d="M19.9999 10.2906H12.8308V20L19.9999 10.2906Z"
      fill="currentColor"
    />
    <path d="M4 10.2906L11.1611 20V10.2906H4Z" fill="currentColor" />
  </MPSVGIcon>
));

export default CollectorRewardIcon;
