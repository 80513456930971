.container {
  padding: 0 0 0 var(--heroCard-padding);

  @media (--mq-is-mobile) {
    padding: var(--heroCard-padding) 0 0 0;
  }
}

.artworksContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;

  @media (--mq-is-mobile) {
    flex-basis: initial;
  }
}

.artworksContainer > .artworks {
  margin: 0;

  & > div {
    --gap: 16px;

    @media (--mq-is-mobile) {
      --gap: 12px;
    }
  }
}

.artworksContainer > .artworks .artwork {
  --standardCard-mobileHeight: 344px;
  --standardCard-desktopHeight: 636px;
  --standardCard-monitorHeight: 698px;
  --artworkCard-assetCustomMobileHeight: 296px;
  --artworkCard-assetCustomDesktopHeight: 572px;
  --artworkCard-assetCustomMonitorHeight: 634px;

  overflow: hidden;
  width: 448px;

  @media screen and (min-width: 1920px) {
    width: 554px;
  }

  @media (--mq-is-mobile) {
    height: var(--artworkCard-assetCustomMobileHeight, 306px);
    width: 273px;
  }
}

.rail {
  box-sizing: border-box;
  padding: 0 var(--heroCard-padding) 0 0;
  width: calc(350px + var(--heroCard-padding));

  @media (--mq-is-mobile) {
    padding: 0 20px var(--heroCard-padding);
    width: 100%;
  }
}
