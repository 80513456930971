.MPTextField {
  box-sizing: border-box;

  :global(.MuiInputBase-root):global(.MuiInput-root):global(.MuiInput-underline):before {
    border-bottom: 2px solid var(--color-SolidNeutralGray5);
  }
  :global(.MuiInputBase-root):after {
    display: none;
  }
  :global(.MuiInputLabel-root) {
    display: flex;
    left: -14px;
    position: relative;
    top: -20px;
  }
  :global(.MuiInputLabel-root.Mui-focused) {
    color: var(--color-primaryMain);
  }
  :global(.MuiInputBase-root) {
    padding: 10px 0;
  }
  :global(.MuiInputBase-input) {
    padding: 0;
  }
  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
  }
  :global(.MuiOutlinedInput-root) {
    padding: 0 4px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-primaryP20);

    [error] {
      border-color: var(--color-ErrorMain);
    }
  }
}
