.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.item {
  align-items: center;
  color: var(--color-commonBlack);
  display: flex;
  flex-direction: row;
  gap: 2.5px;
  text-decoration: none;

  & > svg {
    font-size: 22.5px;
  }

  & .infoIcon {
    color: var(--color-SolidNeutralGray3);
    font-size: 16px;
    margin-left: 1.5px;
  }
}

.button {
  align-content: center;
  margin-top: 16px;
}
