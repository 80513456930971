import { Link } from 'react-router-dom';

import { useIsMobile } from '@mp-frontend/core-components';

import { useTrackingContext } from 'components/trackingContext';
import GTM from 'GTM';

import ProductAuthorDetails from '../ProductAuthorDetails';
import ProductBadges, { ProductBadgesProps } from '../ProductBadges';
import ProductTitle from '../ProductTitle';
import { ProductPageCardProps } from '.';

interface ProductCardTopSectionProps {
  nftMetadata: Pick<
    ProductPageCardProps['nftMetadata'],
    'title' | 'author' | 'collaborators' | 'nftCollection'
  > & {
    collectorReward?: ProductPageCardProps['nftMetadata']['collectorReward'];
  };
  link?: string;
  nft?: ProductBadgesProps['nft'];
}

function ProductCardTopSection({
  link,
  nft,
  nftMetadata,
}: ProductCardTopSectionProps) {
  const isMobile = useIsMobile();
  const { source, dropTitle } = useTrackingContext();
  return (
    <>
      {!!nft && <ProductBadges nft={nft} nftMetadata={nftMetadata} />}
      {link ? (
        <Link
          className="defaultLink"
          to={link}
          reloadDocument
          onClick={() =>
            GTM.pdp.trackClickEventProductTitleType(source, dropTitle)
          }
        >
          <ProductTitle title={nftMetadata.title} />
        </Link>
      ) : (
        <ProductTitle title={nftMetadata.title} />
      )}
      <ProductAuthorDetails
        author={nftMetadata.author}
        collaborators={nftMetadata.collaborators}
        collection={nftMetadata.nftCollection}
        hasBottomMargin={isMobile}
      />
    </>
  );
}

export default ProductCardTopSection;
