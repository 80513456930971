import { PureComponent, useEffect } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { useParams } from 'react-router-dom';
import { useStatsigClient } from '@statsig/react-bindings';

import {
  MPAnimations,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ModularPagesQueryType, {
  ModularPagesQuery,
} from 'graphql/__generated__/ModularPagesQuery.graphql';
import { MpErrors } from 'types/__generated__/graphql';

import { PageWithFooter } from 'components/Footer';
import { GrouppedLoadingProvider } from 'components/GrouppedLoadingProvider';
import ROUTES from 'constants/Routes';
import { STATSIG_EVENT } from 'constants/Statsig';
import { APP_NAME } from 'constants/Utils';
import FourOFourError from 'errors/FourOFourError';
import { EcommerceSourceType } from 'GTM';
import PasswordPage, { ErrorType } from 'pages/private/PasswordPage';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { LOCAL_STORAGE_KEYS } from 'utils/localStorageUtils';
import setDocTitle from 'utils/setDocTitle';

import FooterSection from './sections/FooterSection';
import Sections from './sections';

import * as styles from 'css/pages/modularPage/ModularPage.module.css';

export const generateModularPageSessionStorageKey = (pageSlug: string) =>
  `${LOCAL_STORAGE_KEYS.MODULAR_PAGE_PASSWORD}-${pageSlug}`;

interface ModularPageProps {
  pageQuery: WithLoadQueryProps<ModularPagesQuery>;
}

export const getModularPagePassword = (pageSlug: string) =>
  sessionStorage.getItem(generateModularPageSessionStorageKey(pageSlug));

const ModularPage = withLoadQuery(
  ({ pageQuery: { queryRef } }: ModularPageProps) => {
    const { modularPages } = usePreloadedQuery<ModularPagesQuery>(
      ModularPagesQueryType,
      queryRef
    );
    const page = modularPages.edges[0]?.node;
    const { pageSlug } = useParams();

    const savedPassword = getModularPagePassword(pageSlug);

    useEffect(() => {
      if (!page?.title) return;

      setDocTitle(`${page.title} | ${APP_NAME}`);
    }, [page?.title]);

    if (!page) throw new FourOFourError();
    return (
      <div className={styles.container}>
        <div className={joinClasses(CSSGlobal.Flex.Col, styles.content)}>
          <div
            className={joinClasses(
              MPColorClass.CommonBlack,
              CSSGlobal.Cursor.Default,
              CSSGlobal.Flex.CenteredCol,
              CSSGlobal.TextAlign.Centered,
              CSSGap[16],
              styles.header
            )}
          >
            {!!(page.type || page.isPrivate) && (
              <div
                className={joinClasses(
                  MPColorClass.GoldMain,
                  MPFonts.textSmallMedium
                )}
              >
                {page.isPrivate ? 'Private Sales' : page.type}
              </div>
            )}
            <h1 className={joinClasses('reset', MPFonts.headline4)}>
              {page.title}
            </h1>
            {!!page.author?.fullName && (
              <div className={MPFonts.textSmallMedium}>
                by{' '}
                <a
                  className={joinClasses(
                    'invisibleAnchor',
                    MPAnimations.Color.DarkToLight
                  )}
                  href={ROUTES.PROFILE.SHOP(page.author.username)}
                >
                  {page.author.fullName}
                </a>
              </div>
            )}
          </div>

          <Sections
            sectionsQuery={{
              variables: { password: savedPassword, slug: page.slug },
            }}
          />

          {!!page.author && (
            <FooterSection
              isPrivate={page.isPrivate}
              slug={page.author.username}
              showNewsletterSignUp={page.isShowNewsletterSignUp}
            />
          )}
        </div>
      </div>
    );
  },
  {
    pageQuery: { concreteRequest: ModularPagesQueryType },
  },
  {
    grouppedLoadingKey: `page:details`,
  }
);

type RouteParams = {
  pageSlug: string;
};

interface ProtectedModularPageProps {
  pageSlug: string;
  statsigClient: ReturnType<typeof useStatsigClient>;
}

interface ProtectedModularPageState {
  error: ErrorType | null;
  password: string | null;
  submitted: boolean;
}

class ProtectedModularPage<T> extends PureComponent<
  ProtectedModularPageProps & T,
  ProtectedModularPageState
> {
  static getDerivedStateFromError(error: ErrorType) {
    return { error };
  }

  static willCatch(error: ErrorType) {
    return error?.name === MpErrors.Locked;
  }

  constructor(props: ProtectedModularPageProps & T) {
    super(props);
    const savedPassword = getModularPagePassword(props.pageSlug);
    this.state = { error: null, password: savedPassword, submitted: false };
  }

  componentDidCatch(error: ErrorType) {
    if (!ProtectedModularPage.willCatch(error)) throw error;
  }

  handleSubmit = (password: string) => {
    const { statsigClient } = this.props;

    this.setState({ submitted: true });
    sessionStorage.setItem(
      generateModularPageSessionStorageKey(this.props.pageSlug),
      password
    );

    statsigClient.logEvent(
      STATSIG_EVENT.MP.PRIVATE_SALE.ENTER_PASSWORD,
      document.location.href,
      { source: EcommerceSourceType.ModularPage }
    );

    this.setState({ password }, this.resetErrorBoundary);
  };

  resetErrorBoundary = () => {
    this.setState({ error: null });
  };

  render() {
    const { pageSlug } = this.props;
    const { error, password, submitted } = this.state;
    const hasError = ProtectedModularPage.willCatch(error);

    return hasError ? (
      <PasswordPage
        password={password}
        onSubmit={this.handleSubmit}
        curatorFullName={error?.additionalData?.curatorFullName}
        curatorUsername={error?.additionalData?.curatorUsername}
        isInvalidPassword={!!hasError && submitted}
      />
    ) : (
      <GrouppedLoadingProvider>
        <PageWithFooter>
          <ModularPage
            pageQuery={{
              variables: {
                first: 1,
                password,
                slug: pageSlug,
              },
            }}
          />
        </PageWithFooter>
      </GrouppedLoadingProvider>
    );
  }
}

function ProtectedModularPageWrapper<T>(props: Omit<T, 'pageSlug'>) {
  const { pageSlug } = useParams<RouteParams>();
  const statsigClient = useStatsigClient();

  return (
    <ProtectedModularPage
      {...props}
      pageSlug={pageSlug}
      statsigClient={statsigClient}
    />
  );
}

export default ProtectedModularPageWrapper;
