import {
  CSSProperties,
  MouseEvent,
  ReactNode,
  ReactText,
  useMemo,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/cards/BaseCard.module.css';

interface HeroCardProps {
  children: ReactNode;
  onClick: (event: MouseEvent) => void;
  className?: string;
  disableBrowserNavigate?: boolean;
  disableInAppNavigate?: boolean;
  reloadDocument?: boolean;
  style?: CSSProperties | Record<string, ReactText>;
  to?: string;
}

export default function HeroCard({
  children,
  className,
  to,
  disableBrowserNavigate = false,
  disableInAppNavigate = false,
  reloadDocument = false,
  style,
  onClick,
}: HeroCardProps) {
  const { ref, inView: isInView } = useInView({
    fallbackInView: true,
    triggerOnce: true,
  });
  const finalClassName = useMemo(
    () =>
      joinClasses(styles.heroCard, styles.animated, className, {
        [styles.isVisible]: isInView,
      }),
    [className, isInView]
  );

  return disableBrowserNavigate ? (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div ref={ref} className={finalClassName} style={style} onClick={onClick}>
      {children}
    </div>
  ) : disableInAppNavigate ? (
    <a
      ref={ref}
      href={to}
      className={finalClassName}
      style={style}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      ref={ref}
      to={to}
      reloadDocument={reloadDocument}
      className={finalClassName}
      style={style}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
