import { useCallback } from 'react';
import { Disposable, useMutation, UseMutationConfig } from 'react-relay';

import FollowMutationType, {
  AccountFollowUserMutation as FollowMutationParameters,
  FollowUnfollowUserArguments,
} from 'graphql/__generated__/AccountFollowUserMutation.graphql';
import UnfollowMutationType, {
  AccountUnfollowUserMutation as UnfollowMutationParameters,
} from 'graphql/__generated__/AccountUnfollowUserMutation.graphql';
import FollowersMutationV2Type, {
  FollowersMutation as FollowersMutationV2Parameters,
} from 'graphql/__generated__/FollowersMutation.graphql';

import GTM from '../GTM';

export function useFollowMutationV2(): [
  (config: UseMutationConfig<FollowersMutationV2Parameters>) => Disposable,
  boolean
] {
  const [follow, isTogglingFollow] = useMutation(FollowersMutationV2Type);
  const trackFollow = useCallback(
    (config: UseMutationConfig<FollowersMutationV2Parameters>) =>
      follow({
        ...config,
        onCompleted(
          response: FollowersMutationV2Parameters['response'],
          errors
        ) {
          GTM.social.trackFollow(
            config.variables.globalSlug,
            config.variables.follow
          );
          if (config.onCompleted) config.onCompleted(response, errors);
        },
      }),
    [follow]
  );
  return [trackFollow, isTogglingFollow];
}

type ToggleFollowUserArguments = FollowUnfollowUserArguments & {
  follow: boolean;
  globalSlug: string;
};

type ToggleFollowUserMutation = {
  response: {
    success: boolean;
  };
  variables: ToggleFollowUserArguments;
};

export function useFollowMutation() {
  const [followMutation, isFollowLoading] = useMutation(FollowMutationType);
  const [unfollowMutation, isUnfollowLoading] =
    useMutation(UnfollowMutationType);

  const trackToggle = useCallback(
    (config: UseMutationConfig<ToggleFollowUserMutation>) => {
      const mutation = config.variables.follow
        ? followMutation
        : unfollowMutation;

      return mutation({
        ...config,
        onCompleted(
          response:
            | FollowMutationParameters['response']
            | UnfollowMutationParameters['response'],
          errors
        ) {
          GTM.social.trackFollow(
            config.variables.globalSlug,
            config.variables.follow
          );
          if (config.onCompleted)
            config.onCompleted(
              config.variables.follow
                ? (response as FollowMutationParameters['response']).followUser
                : (response as UnfollowMutationParameters['response'])
                    .unfollowUser,
              errors
            );
        },
        variables: {
          request_data: {
            userId: config.variables.userId,
          },
        },
      });
    },
    [followMutation, unfollowMutation]
  );

  return [
    trackToggle,
    isFollowLoading || isUnfollowLoading,
    followMutation,
    unfollowMutation,
  ] as const;
}

export default useFollowMutation;
