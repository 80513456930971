.paddingHorizontal16 {
  padding-left: 16px;
  padding-right: 16px;
}

.paddingHorizontal24 {
  padding-left: 24px;
  padding-right: 24px;
}

.paddingVertical16 {
  padding-bottom: 16px;
  padding-top: 16px;
}

.paddingVertical24 {
  padding-bottom: 24px;
  padding-top: 24px;
}
