import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { noop } from 'lodash';

import { MPBackgroundColorClass } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ENV_VARS from 'envVars';
import CSSGlobal from 'types/enums/css/Global';

import { DefaultLoader, DefaultLoaderProps } from './DefaultSuspense';
import { SiteLoader } from './SiteLoadingSuspense';

import * as styles from 'css/components/GrouppedLoadingProvider.module.css';

type GrouppedLoadingContextStateType = Record<string, boolean>;
type GrouppedLoadingUpdaterType = (
  loaderKey: string,
  isLoading: boolean
) => void;

const GrouppedLoadingContext = createContext<GrouppedLoadingUpdaterType>(noop);

export function useGrouppedLoading(loaderKey: string, isLoading: boolean) {
  const updater = useContext(GrouppedLoadingContext);
  if (updater === noop && ENV_VARS.isDev) {
    throw new Error(
      'useGrouppedLoading must be used within GrouppedLoadingProvider'
    );
  }

  useEffect(() => {
    updater(loaderKey, isLoading);

    return () => updater(loaderKey, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
}

interface GrouppedLoadingProviderProps
  extends Pick<DefaultLoaderProps, 'position'> {
  children: ReactNode;
}

export function GrouppedLoadingProvider({
  children,
  position,
}: GrouppedLoadingProviderProps) {
  const [state, setState] = useState<GrouppedLoadingContextStateType>({});
  const updater = useCallback(
    (loaderKey: string, isLoading: boolean) =>
      setState((prev) => ({ ...prev, [loaderKey]: isLoading })),
    []
  );
  const isLoading = useMemo(() => Object.values(state).some(Boolean), [state]);

  return (
    <>
      <GrouppedLoadingContext.Provider value={updater}>
        {children}
      </GrouppedLoadingContext.Provider>

      {isLoading ? (
        position === 'absolute' ? (
          <div
            className={joinClasses(
              MPBackgroundColorClass.BackgroundDefault,
              CSSGlobal.Flex.ColCenterAlign,
              styles.absoluteContainer
            )}
          >
            <DefaultLoader />
          </div>
        ) : (
          <SiteLoader />
        )
      ) : null}
    </>
  );
}
