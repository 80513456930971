import { startTransition, useEffect } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import NFTTransactionPollingQueryType, {
  NFTTransactionPollingQuery,
} from 'graphql/__generated__/NFTTransactionPollingQuery.graphql';
import { EthereumTransactionStateEnum } from 'types/__generated__/graphql';

interface TransactionPollingProps {
  invalidate: () => void;
  queryRef: PreloadedQuery<NFTTransactionPollingQuery>;
}
export default function usePollTransactionUntilMined({
  queryRef,
  invalidate,
}: TransactionPollingProps) {
  const { transactions } = usePreloadedQuery<NFTTransactionPollingQuery>(
    NFTTransactionPollingQueryType,
    queryRef
  );

  const transaction =
    transactions?.edges.length > 0 ? transactions.edges[0].node : null;

  const transactionState = transaction?.transactionState;

  useEffect(() => {
    const interval = setInterval(() => {
      startTransition(() => {
        if (transactionState === EthereumTransactionStateEnum.Mined) {
          clearInterval(interval);
        } else {
          invalidate();
        }
      });
    }, 5000);
    return () => clearInterval(interval);
  }, [transactionState, invalidate]);

  return transaction;
}
