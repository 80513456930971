.heroCard {
  --heroCard-padding: 32px;
  --heroCard-height: 760px;

  display: flex;
  flex-direction: row;
  height: var(--heroCard-height);
  min-height: var(--heroCard-height);


  @media (--mq-is-desktop) {
    --heroCard-height: 700px;
  }

  @media (--mq-is-mobile) {
    flex-direction: column;
    --heroCard-height: initial;
  }
}

.standardCard {
  box-sizing: border-box;
  display: flex;
  height: var(--standardCard-desktopHeight, 600px);
  overflow: hidden;
  position: relative;
  width: inherit;

  @media (--mq-is-mobile) {
    height: var(--standardCard-mobileHeight, 480px);
  }

  @media screen and (min-width: 1920px) {
    height: var(--standardCard-monitorHeight, 700px);
  }
}

.heroCard.animated,
.standardCard.animated {
  --card-opacityTransitionDuration: 1200ms;
  --card-transformTransitionDuration: 2000ms;
  --card-transitionDelay: 0ms;
  --card-transitionTimingFunction: cubic-bezier(0.23, 1, 0.32, 1);

  opacity: 0;
  transform: translateY(10%);
  transition:
      opacity var(--card-opacityTransitionDuration) var(--card-transitionTimingFunction) var(--card-transitionDelay),
      transform var(--card-transformTransitionDuration) var(--card-transitionTimingFunction) var(--card-transitionDelay);
  will-change: opacity, transform;

  &:nth-child(2n) {
    --card-transitionDelay: 100ms;
  }

  &:nth-child(3n)  {
    --card-transitionDelay: 200ms;
  }

  &.isVisible {
    transform: translateY(0);
    opacity: 1;
  }
}
