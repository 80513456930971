import { BigNumber } from 'ethers';

import { parseUnits } from 'ethers/lib/utils.js';

export default function getSafeBigNumberFromUserInput(
  numberString: string
): BigNumber {
  try {
    return parseUnits(numberString, 'ether');
  } catch (_ignoredError) {
    return BigNumber.from('0');
  }
}
