import { Hash } from 'viem';

import { CreatorContractType } from 'types/__generated__/graphql';

import { APP_NAME } from 'constants/Utils';
import useManifoldContract from 'hooks/contracts/useManifoldContract';

import PendingApprovalsManager from '../PendingApprovalsManager';
import Approval from './Approval';

interface ManifoldAdminApprovalProps {
  contract: CreatorContractType;
  walletAddress: Hash;
  className?: string;
  contractTitle?: string;
}

export default function ManifoldAdminApproval({
  contract,
  walletAddress,
  className,
  contractTitle = '',
}: ManifoldAdminApprovalProps) {
  const { useApproveAdmin, useRevokeAdmin } = useManifoldContract({
    abi: JSON.parse(contract.abidata).abi,
    account: walletAddress,
    contractAddress: contract.address as Hash,
  });

  const approveAdminManager = useApproveAdmin({
    adminAddress: contract.manifoldAdminAddress as Hash,
  });

  const revokeAdminManager = useRevokeAdmin({
    adminAddress: contract.manifoldAdminAddress as Hash,
  });

  const fetchParams = PendingApprovalsManager.useReadManifoldAdminApproval(
    contract,
    walletAddress
  );

  return (
    <Approval
      className={className}
      title={`Admin Access${contractTitle ? ` - ${contractTitle}` : ''}`}
      explanation={`You will grant ${APP_NAME} admin access to your personal contract in order to mint artworks during your exhibition`}
      fetchParams={fetchParams}
      manager={fetchParams[0] ? revokeAdminManager : approveAdminManager}
      walletAddress={walletAddress}
    />
  );
}
