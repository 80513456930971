import { MouseEvent, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  MPAnimations,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { FollowButton } from 'components/buttons/FollowButton';
import ROUTES from 'constants/Routes';
import THRESHOLDS from 'constants/Thresholds';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { AccountArtistFragment } from 'types/graphql/Account';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import getProfileImage from 'utils/getProfileImage';
import toNumericShorthand from 'utils/numericShorthand';

import * as styles from 'css/components/accounts/User.module.css';

function LinkOrNot({
  username,
  className,
  children,
  onClick,
}: {
  children: ReactNode;
  className: string;
  username: string;
  onClick?: (event: MouseEvent) => void;
}) {
  const classNameWithoutTransitions = useMemo(
    () =>
      Object.values(MPAnimations.Color).reduce(
        (memo, value) => memo.replace(value, ''),
        className
      ),
    [className]
  );

  return username ? (
    <Link
      className={joinClasses('anchor', className)}
      to={ROUTES.PROFILE(username)}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <div className={classNameWithoutTransitions}>{children}</div>
  );
}

type Size = 'small' | 'medium' | 'large';
const profileImageClassSizeMap: Record<Size, string> = {
  large: styles.large,
  medium: styles.medium,
  small: styles.small,
};
const nameFontSizeMap: Record<Size, string> = {
  large: MPFonts.textLargeMedium,
  medium: MPFonts.textNormalMedium,
  small: MPFonts.textNormalMedium,
};

type BottomSectionType = 'stats' | 'follow' | 'none' | ReactNode;

export interface UserProps {
  user: Pick<
    AccountArtistFragment,
    'pk' | 'username' | 'profileImageUrl' | 'fullName'
  > & {
    followerCount?: AccountArtistFragment['followerCount'];
    totalVolume?: AccountArtistFragment['totalVolume'];
  };
  bottomSection?: BottomSectionType;
  className?: string;
  nameColorClassName?: string;
  onClick?: (event: MouseEvent) => void;
  size?: Size;
  statsColorClassName?: string;
  topSection?: ReactNode;
}

export default function User({
  user,
  bottomSection = 'stats',
  className = joinClasses(
    CSSGlobal.Overflow.Hidden,
    CSSGlobal.Flex.RowCenterAlign,
    CSSGlobal.Flex.NoWrap
  ),
  nameColorClassName = joinClasses(
    MPColorClass.CommonBlack,
    MPAnimations.Color.DarkToLight
  ),
  size = 'medium',
  statsColorClassName = MPColorClass.SolidNeutralGray5,
  topSection,
  onClick,
}: UserProps) {
  return (
    <div className={joinClasses(CSSGap[8], className)}>
      <LinkOrNot
        username={user.username}
        className={joinClasses(
          styles.profileImage,
          profileImageClassSizeMap[size]
        )}
        onClick={onClick}
      >
        <img
          src={getProfileImage(user.profileImageUrl)}
          alt={`${user.fullName}`}
        />
      </LinkOrNot>
      <div
        className={joinClasses(
          CSSGlobal.Flex.ColCenterAlign,
          styles.rightSection
        )}
      >
        {topSection}

        <LinkOrNot
          className={joinClasses(
            CSSGlobal.Ellipsis,
            nameFontSizeMap[size],
            nameColorClassName
          )}
          username={user.username}
          onClick={onClick}
        >
          {generateFormattedUserFullName(user.fullName)}
        </LinkOrNot>

        {bottomSection === 'stats' ? (
          user.totalVolume?.totalVolumeInUsd > THRESHOLDS.TOTAL_SALES ? (
            <div
              className={joinClasses(
                CSSGlobal.Cursor.Default,
                CSSGlobal.Ellipsis,
                MPFonts.textSmallMedium,
                statsColorClassName
              )}
            >
              {`$${toNumericShorthand(
                user.totalVolume?.totalVolumeInUsd
              )} in sales`}
            </div>
          ) : user.followerCount > THRESHOLDS.AUDIENCE ? (
            <div
              className={joinClasses(
                CSSGlobal.Cursor.Default,
                CSSGlobal.Ellipsis,
                MPFonts.textSmallMedium,
                statsColorClassName
              )}
            >
              {`${toNumericShorthand(user.followerCount)} followers`}
            </div>
          ) : null
        ) : bottomSection === 'follow' ? (
          <FollowButton
            username={user.username}
            userId={parseInt(user.pk, 10)}
            className={styles.action}
          />
        ) : bottomSection === 'none' ? null : (
          bottomSection
        )}
      </div>
    </div>
  );
}
