import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import { CollectedArtworksSubcomponent } from './WalletSubComponents';

import { type Wallets } from 'Session';

interface WalletProps {
  wallet: Wallets[0] | undefined;
}

export default function SafeboxWallet({ wallet }: WalletProps) {
  return (
    <>
      <div className={joinClasses(CSSGlobal.Flex.RowSpaceBetween, 'disabled')}>
        <span>Custodial Collection Wallet</span>
      </div>
      <div className={joinClasses(CSSGlobal.Flex.Row, CSSGap[20])}>
        <CollectedArtworksSubcomponent wallet={wallet} />
      </div>
    </>
  );
}
