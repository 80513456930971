/* Copied from ProductPurchaseOfferDialog */
.paymentFormCreditCardElementWrapper {
  border-color: var(--color-commonBlack);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  color: var(--color-commonBlack);
  padding: 11px 16px;

  &:not(.focused):hover {
    border-width: 2px;
    background-color: var(--color-backgroundDefault);
    padding: 10px 16px;
  }

  &.empty {
    border-color: var(--color-SolidNeutralGray3);
    color: var(--color-SolidNeutralGray3);
  }

  &.complete:not(.focused) {
    border-color: var(--color-commonBlack);
  }

  &.focused {
    border-color: var(--color-commonBlack);
    border-width: 2px;
    color: var(--color-commonBlack);
    padding: 10px 16px;
  }

  &.error {
    border-color: var(--color-errorLight);
    border-width: 1px;

    &:not(.focused):hover {
      border-color: var(--color-errorLight);
      border-width: 2px;
      color: var(--color-errorMain);
    }

    &.focused {
      border-color: var(--color-errorMain);
      border-width: 2px;
      color: var(--color-errorMain);
    }
  }
}
