import { useSimulateContract, useWriteContract } from 'wagmi';
import { WriteContractReturnType } from '@wagmi/core';

import { METAMASK_DEEP_LINK_URL } from 'constants/ExternalUrls';

export enum ConnectorName {
  CoinbaseWallet = 'Coinbase\u00A0Wallet',
  Injected = 'Injected',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
}

export enum WalletId {
  Coinbase = 'com.coinbase.wallet',
  CoinbaseSDK = 'coinbaseWalletSDK',
  Metamask = 'io.metamask',
  MetamaskMobile = 'io.metamask.mobile',
  MetamaskSDK = 'metaMaskSDK',
  WalletConnect = 'walletConnect',
}

export enum AccountConnectionStatus {
  Connected = 'connected',
  Connecting = 'connecting',
  Disconnected = 'disconnected',
  Reconnecting = 'reconnecting',
}

export enum PendingConnectionStatus {
  Error = 'error',
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
}

export type ContractWriteResults = ReturnType<
  typeof useWriteContract
>['writeContract'];

export enum ContractWriteStatus {
  Error = 'error',
  Idle = 'idle',
  Loading = 'loading',
  Success = 'success',
}

export interface ContractActionObject {
  mutate: {
    write: () => void;
    writeAsync: () => Promise<WriteContractReturnType>;
  } & Omit<
    ReturnType<typeof useWriteContract>,
    'writeContract' | 'writeContractAsync'
  >;
  simulate: ReturnType<typeof useSimulateContract>;
}

export type HexString = `0x${string}`;

export const TRANSACTION_ERROR_MESSAGE_DURATION_MS = 1_500;

export const DEFAULT_PRICE_FLOAT_PRECISION = 0.001;

export function isEthAddress(input: string): boolean {
  const hexRegex = /^(0x){1}[0-9a-fA-F]{40}$/;
  return hexRegex.test(input);
}

export function openMetaMaskDeepLink(): void {
  window.open(
    `${METAMASK_DEEP_LINK_URL}${window.location.host}${window.location.pathname}`
  );
}

export default {};
