.assetsContainer {
  height: 125px;

  @media screen and (min-width: 1920px) {
    height: 160px;
  }

  @media (--mq-is-mobile) {
    height: 96px;
  }

  .asset {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;
  }
}

.textContainer {
  height: 40px;
}
