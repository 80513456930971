/**
 * @generated SignedSource<<b6005b26f5569dfc18beb8cf50bb8729>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountChangePasswordMutation$variables = {
  oldPassword: string;
  newPassword: string;
};
export type AccountChangePasswordMutation$data = {
  readonly changePassword: {
    readonly success: boolean | null;
    readonly token: string | null;
  } | null;
};
export type AccountChangePasswordMutation = {
  variables: AccountChangePasswordMutation$variables;
  response: AccountChangePasswordMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newPassword"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "oldPassword"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "newPassword",
            "variableName": "newPassword"
          },
          {
            "kind": "Variable",
            "name": "oldPassword",
            "variableName": "oldPassword"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "ChangePasswordMutation",
    "kind": "LinkedField",
    "name": "changePassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountChangePasswordMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountChangePasswordMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "674472430552c7b944ab5a4e72cefdbe",
    "id": null,
    "metadata": {},
    "name": "AccountChangePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation AccountChangePasswordMutation(\n  $oldPassword: String!\n  $newPassword: String!\n) {\n  changePassword(requestData: {oldPassword: $oldPassword, newPassword: $newPassword}) {\n    success\n    token\n  }\n}\n"
  }
};
})();

(node as any).hash = "3390c937b95646ca37117179ffb19623";

export default node;
