.container {
  padding-right: 16px;

  @media (--mq-is-mobile) {
    padding-right: 0;
  }
}

.title {
  margin: 0px 0px 22px;

  @media (--mq-is-mobile) {
    margin: 0px 24px 22px;
  }
}
