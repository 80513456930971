/**
 * @generated SignedSource<<c3a177371a7bdd197304ca62fad8de14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoggingFromFrontendArguments = {
  message: string;
  isException?: boolean | null;
  nftId?: number | null;
  transactionId?: string | null;
};
export type LoggingFromFrontendMutation$variables = {
  request_data: LoggingFromFrontendArguments;
};
export type LoggingFromFrontendMutation$data = {
  readonly loggingFromFrontend: {
    readonly success: boolean | null;
  } | null;
};
export type LoggingFromFrontendMutation = {
  variables: LoggingFromFrontendMutation$variables;
  response: LoggingFromFrontendMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request_data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "request_data"
      }
    ],
    "concreteType": "LoggingFromFrontendMutation",
    "kind": "LinkedField",
    "name": "loggingFromFrontend",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoggingFromFrontendMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoggingFromFrontendMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1b550902d487f3c5f3b1d069140928e7",
    "id": null,
    "metadata": {},
    "name": "LoggingFromFrontendMutation",
    "operationKind": "mutation",
    "text": "mutation LoggingFromFrontendMutation(\n  $request_data: LoggingFromFrontendArguments!\n) {\n  loggingFromFrontend(requestData: $request_data) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "86f5a533371d01f22e89ef0c95387179";

export default node;
