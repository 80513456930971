import { useCallback, useState } from 'react';

import {
  MPFonts,
  MPIconButton,
  MPIconButtonThemes,
} from '@mp-frontend/core-components';
import { RemoveIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import CSSGlobal from 'types/enums/css/Global';
import { LOCAL_STORAGE_KEYS } from 'utils/localStorageUtils';

import * as styles from 'css/components/SiteNotice/DowngradedCreator.module.css';

export default function DowngradedCreatorNotice() {
  const [shouldShow, setShouldShow] = useState(
    !localStorage.getItem(LOCAL_STORAGE_KEYS.DOWNGRADED_CREATOR_BANNER_SEEN)
  );
  const hide = useCallback(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.DOWNGRADED_CREATOR_BANNER_SEEN,
      '1'
    );
    setShouldShow(false);
  }, []);

  return (
    <div
      className={joinClasses(
        MPFonts.textSmallMedium,
        CSSGlobal.Flex.CenteredRow,
        styles.container,
        { [styles.show]: shouldShow }
      )}
    >
      <span className={styles.content}>
        Please note that your account is in collector mode. To learn why this
        has happened, please&nbsp;
        <a href={ROUTES.ARTIST_DOWNGRADE_INFORMATION()} className={styles.link}>
          click here
        </a>
        .
      </span>

      {!!shouldShow && (
        <MPIconButton
          className={styles.closeButton}
          theme={MPIconButtonThemes.DARK}
          onClick={hide}
        >
          <RemoveIcon />
        </MPIconButton>
      )}
    </div>
  );
}
