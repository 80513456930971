import { ReactNode } from 'react';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

export default function PaymentViewBody({
  children,
}: {
  children?: ReactNode;
}) {
  return <div className={styles.body}>{children}</div>;
}
