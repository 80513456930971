/**
 * @generated SignedSource<<0c43143c7e561401e363c3204f3dad4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateRankedBidInETHArguments = {
  bidAmountInWei: any;
  bidderAddress: `0x${string}`;
  digitalMediaReleaseId: number;
  email: string;
  nonce: number;
  transactionId: `0x${string}`;
};
export type ListingCreateRankedBidInETHMutation$variables = {
  requestData: CreateRankedBidInETHArguments;
};
export type ListingCreateRankedBidInETHMutation$data = {
  readonly createRankedBidInEth: {
    readonly success: boolean | null;
  } | null;
};
export type ListingCreateRankedBidInETHMutation = {
  variables: ListingCreateRankedBidInETHMutation$variables;
  response: ListingCreateRankedBidInETHMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "CreateRankedBidInETHMutation",
    "kind": "LinkedField",
    "name": "createRankedBidInEth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingCreateRankedBidInETHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingCreateRankedBidInETHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7a4959e6ea44517360f4b0e4c0bc4eb",
    "id": null,
    "metadata": {},
    "name": "ListingCreateRankedBidInETHMutation",
    "operationKind": "mutation",
    "text": "mutation ListingCreateRankedBidInETHMutation(\n  $requestData: CreateRankedBidInETHArguments!\n) {\n  createRankedBidInEth(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "33a2d6577dec703d2b0fdcb18a1060bb";

export default node;
