.progressTrack {
  width: 100%;
  height: 3px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.progressTrack::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--color-SolidNeutralGray1);
  z-index: 0;
  opacity: 0.4;
}

.progressBar {
  position: absolute;
  height: 3px;
  background-color: var(--color-commonWhite);
}

.largeActionBar {
  padding: 0 64px 32px;
  box-sizing: border-box;
  gap: 16px;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  display: flex;
}

.largeActionBarLeft {
  flex-grow: 1;
  align-items: flex-start;
  gap: 4px;
  width: calc(100% - 70px);
  box-sizing: border-box;
  overflow: hidden;
  flex-wrap: nowrap;
}

.largeActionBarRight {
  align-items: center;
  display: flex;
  gap: 6px;
  justify-content: flex-end;
}

.navBar {
  display: flex;
  flex-direction: row-reverse;
  padding: 40px 64px;
  box-sizing: border-box;
}

:global(.fullscreen-enabled) {
  & .largeActionBar {
    padding-left: 40px;
    padding-right: 40px;
  }

  & .navBar {
    padding: 16px 40px;
  }
}
