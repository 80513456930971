:root {
  --mp-tense-out: cubic-bezier(0.23, 1, 0.32, 1);
}

@keyframes colorGray3to1Animation {
  from {color: var(--color-SolidNeutralGray3);}
  to {color: var(--color-SolidNeutralGray1);}
}

@keyframes colorGray3to5Animation {
  from {color: var(--color-SolidNeutralGray3);}
  to {color: var(--color-SolidNeutralGray5);}
}

.textStyle {
  transition: color 300ms var(--mp-tense-out);
}

.textStyle:global(.isAnimating) {
  animation-timing-function: var(--mp-tense-out);
  animation-name: colorGray3to1Animation;
  animation-duration: 1200ms;
}

.textStyle:hover, .textStyle:active:not(:global(.isAnimating)) {
  transition: color 300ms var(--mp-tense-out);
  color: var(--color-SolidNeutralGray3);
}

.lightToDark {
  transition: color 300ms var(--mp-tense-out);
}

.lightToDark:global(.isAnimating) {
  animation-timing-function: var(--mp-tense-out);
  animation-name: colorGray3to5Animation;
  animation-duration: 1200ms;
}

.lightToDark:hover, .lightToDark:active:not(:global(.isAnimating)) {
  transition: color 300ms var(--mp-tense-out);
  color: var(--color-SolidNeutralGray3);
}

.hidden {
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0s 0.2s;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transition: opacity 0.2s ease, visibility 0s;
    visibility: visible;
  }
}
