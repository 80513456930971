import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const GenerateIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path d="M19.4999 19.5H10.9285V10.9286H19.4999V19.5Z" fill="currentColor" />
    <path
      d="M7.71436 7.71423V16.2857H9.67876V9.70467H16.2858V7.71423H7.71436Z"
      fill="currentColor"
    />
    <path
      d="M13.0714 4.5H4.5V13.0714H6.46441V6.49044H13.0714V4.5Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default GenerateIcon;
