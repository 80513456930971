export interface CloudinaryParams {
  crop?: 'limit' | 'fill' | 'scale' | 'fit' | 'pad';
  height?: number;
  quality?: 'auto' | 'auto:good' | 'auto:best' | 'auto:low' | 'auto:eco';
  width?: number;
}

export const withCloudinaryParams = (url: string, params: CloudinaryParams) => {
  if (!url || !params) return url;

  const { crop, width, height, quality } = params;
  const paramsString = [
    crop ? `c_${crop}` : '',
    height ? `h_${height}` : '',
    quality ? `q_${quality}` : '',
    width ? `w_${width}` : '',
  ]
    .filter(Boolean)
    .join(',');

  return [url, paramsString].filter(Boolean).join('?tx=');
};

export default {
  withCloudinaryParams,
};
