/**
 * @generated SignedSource<<f96ed33599ad8b1a6970f957702f7192>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NFTOwnersipPcts = {
  username: string;
  percentage: number;
};
export type NFTDraftMutation$variables = {
  title: string;
  description: string;
  totalSupply: number;
  uploadedFile: "__SecretUploadableKeyName";
  previewFile?: "__SecretUploadableKeyName" | null;
  isHiddenOnCreate?: boolean | null;
  ownershipPcts?: ReadonlyArray<NFTOwnersipPcts | null> | null;
  previewCropRatio?: number | null;
};
export type NFTDraftMutation$data = {
  readonly draftNft: {
    readonly success: boolean | null;
    readonly digitalMediaId: number | null;
  } | null;
};
export type NFTDraftMutation = {
  variables: NFTDraftMutation$variables;
  response: NFTDraftMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHiddenOnCreate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ownershipPcts"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewCropRatio"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewFile"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "totalSupply"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uploadedFile"
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "description",
            "variableName": "description"
          },
          {
            "kind": "Variable",
            "name": "isHiddenOnCreate",
            "variableName": "isHiddenOnCreate"
          },
          {
            "kind": "Variable",
            "name": "ownershipPcts",
            "variableName": "ownershipPcts"
          },
          {
            "kind": "Variable",
            "name": "previewCropRatio",
            "variableName": "previewCropRatio"
          },
          {
            "kind": "Variable",
            "name": "previewFile",
            "variableName": "previewFile"
          },
          {
            "kind": "Variable",
            "name": "title",
            "variableName": "title"
          },
          {
            "kind": "Variable",
            "name": "totalSupply",
            "variableName": "totalSupply"
          },
          {
            "kind": "Variable",
            "name": "uploadedFile",
            "variableName": "uploadedFile"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "DraftNFTMutation",
    "kind": "LinkedField",
    "name": "draftNft",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "digitalMediaId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTDraftMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTDraftMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "993c4192e1d3fedc981fc99b79af3067",
    "id": null,
    "metadata": {},
    "name": "NFTDraftMutation",
    "operationKind": "mutation",
    "text": "mutation NFTDraftMutation(\n  $title: String!\n  $description: String!\n  $totalSupply: Int!\n  $uploadedFile: Upload!\n  $previewFile: Upload\n  $isHiddenOnCreate: Boolean\n  $ownershipPcts: [NFTOwnersipPcts]\n  $previewCropRatio: Float\n) {\n  draftNft(requestData: {title: $title, description: $description, totalSupply: $totalSupply, uploadedFile: $uploadedFile, previewFile: $previewFile, isHiddenOnCreate: $isHiddenOnCreate, ownershipPcts: $ownershipPcts, previewCropRatio: $previewCropRatio}) {\n    success\n    digitalMediaId\n  }\n}\n"
  }
};
})();

(node as any).hash = "e223694fff4362c1b653e79bf5c74439";

export default node;
