import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  MPAnimations,
  MPColorClass,
  MPColorValue,
  MPDivider,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { useTrackingContext } from 'components/trackingContext';
import ROUTES from 'constants/Routes';
import GTM from 'GTM';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import { DropType } from 'types/graphql/Drop';

import * as styles from 'css/pages/product/ProductExhibition.module.css';

interface ProductExhibitionProps {
  exhibition: Pick<
    DropType,
    'dropTitle' | 'heroBackgroundColor' | 'heroImageUrl' | 'slug'
  >;
}

export default function ProductExhibition({
  exhibition,
}: ProductExhibitionProps) {
  const { source } = useTrackingContext();

  const handleClick = useCallback(
    () =>
      GTM.pdp.trackClickExhibitionType(
        source,
        exhibition.slug,
        exhibition.dropTitle
      ),
    [exhibition, source]
  );

  if (
    !exhibition ||
    !exhibition.heroImageUrl ||
    !exhibition.heroBackgroundColor
  ) {
    return null;
  }

  return (
    <>
      <MPDivider />

      <Link
        className={joinClasses(
          CSSMargin.TOP[32],
          CSSMargin.BOTTOM[32],
          styles.container
        )}
        style={
          {
            '--pdp-rail-exhibitionBackgroundImage': `url(${exhibition.heroImageUrl})`,
            '--pdp-rail-exhibitionGradient': `linear-gradient(0deg, rgb(${exhibition.heroBackgroundColor.r}, ${exhibition.heroBackgroundColor.g}, ${exhibition.heroBackgroundColor.b}) 0%, ${MPColorValue.TransparentPrimary} 100%)`,
          } as any
        }
        to={ROUTES.EXHIBITION(exhibition.slug)}
        onClick={handleClick}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.Col,
            MPColorClass.CommonWhite,
            styles.content
          )}
        >
          <div className={joinClasses(MPFonts.textSmallSemiBold, styles.label)}>
            Part of the Exhibition
          </div>
          <div
            className={joinClasses(
              CSSGlobal.Ellipsis,
              CSSGlobal.Cursor.Pointer,
              MPFonts.textNormalMedium,
              MPAnimations.Color.LightToDark
            )}
          >
            {exhibition.dropTitle}
          </div>
        </div>
      </Link>
    </>
  );
}
