import { merge } from 'lodash';
import {
  buttonBaseClasses,
  SxProps,
  tabClasses,
  Tabs,
  tabsClasses,
  TabsProps,
  Theme,
} from '@mui/material';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';

import useDefaultTheme from '../hooks/useDefaultTheme';
import MPTabsVariant from './variant';

const designSxMap: Record<MPTabsVariant, SxProps<Theme>> = {
  [MPTabsVariant.Text]: {
    [`& .${tabsClasses.flexContainer}`]: {
      gap: '32px',
    },
    [`& .${tabsClasses.indicator}`]: {
      backgroundColor: MPColorValue.PrimaryMain,
    },
  },
  [MPTabsVariant.Standard]: {
    minHeight: 'auto',
    [`& .${tabsClasses.flexContainer}`]: {
      borderBottomColor: MPColorValue.SolidNeutralGray1,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      gap: '8px',
    },
    [`& .${tabsClasses.indicator}`]: {
      transform: 'translateY(2px)',
      zIndex: 1,
    },
    [`& .${tabClasses.root}.${buttonBaseClasses.root}`]: {
      padding: '6px 10px',
    },
  },
};

const minHeighMap: Record<MPTabsVariant, string> = {
  [MPTabsVariant.Standard]: '48px',
  [MPTabsVariant.Text]: 'auto',
};

export interface MPTabsProps extends TabsProps {
  /**
   * Either filled or standard
   */
  design?: MPTabsVariant;

  /**
   * Overrides MUI CSS stylings.
   */
  sx?: SxProps<Theme>;
}

export default function MPTabs({
  sx,
  design = MPTabsVariant.Standard,
  ...passedProps
}: MPTabsProps) {
  const theme = useDefaultTheme();

  const allSx = {
    [`& .${tabsClasses.scrollButtons}`]: {
      [theme.breakpoints.down('desktop')]: {
        display: 'none',
      },
    },
    [`.${tabClasses.root}`]: {
      [theme.breakpoints.down('desktop')]: {
        minHeight: minHeighMap[design],
      },
    },
    [theme.breakpoints.down('desktop')]: {
      minHeight: minHeighMap[design],
    },
    [`& .${tabsClasses.scroller}`]: {
      height: 'fit-content',
    },
  };

  const mergedSx = merge({}, designSxMap[design], allSx, sx ?? {});

  return <Tabs sx={mergedSx} {...passedProps} />;
}
