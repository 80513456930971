import MPSVGIcon from './MPSVGIcon';

export default function MagnifyingMinusIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        d="M19.5799 18.1561L16.5613 15.145C17.5303 13.9703 18.1116 12.4684 18.1116 10.8327C18.1116 7.0632 15.0409 4 11.2695 4C7.49813 4 4.41992 7.0632 4.41992 10.8327C4.41992 14.6022 7.49068 17.6654 11.2695 17.6654C12.462 17.6654 13.5726 17.3532 14.5489 16.8178L17.739 20L19.5799 18.1636V18.1561ZM7.03603 10.8327C7.03603 8.49814 8.92916 6.60967 11.2695 6.60967C13.6098 6.60967 15.503 8.49814 15.503 10.8327C15.503 13.1673 13.6098 15.0558 11.2695 15.0558C8.92916 15.0558 7.03603 13.1673 7.03603 10.8327Z"
        fill="currentColor"
      />
      <path
        d="M13.9292 9.71729H8.6001V11.9478H13.9292V9.71729Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
