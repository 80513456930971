import { merge } from 'lodash';
import { MuiChipsInput, MuiChipsInputProps } from 'mui-chips-input';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';

export type MPChipsInputProps = MuiChipsInputProps;

export default function MPInputChips({
  sx = {},
  ...passedProps
}: MPChipsInputProps) {
  const baseSx = {
    '& > .MuiInputBase-root > fieldset, & > .MuiInputBase-root:hover > fieldset, & > .MuiInputBase-root.Mui-focused > fieldset, .MuiInputBase-root.Mui-focused:hover > fieldset':
      {
        borderColor: MPColorValue.PrimaryP20,
        borderRadius: '4px',
        borderWidth: '1px',
      },
  };

  const mergedSx = merge(baseSx, sx);
  return <MuiChipsInput sx={mergedSx} {...passedProps} />;
}
