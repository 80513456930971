import { useCallback, useEffect } from 'react';
import { noop } from 'lodash';

import ProductPageCard, { ProductPageCardProps } from 'pages/product/card';
import { setPasswordForProduct } from 'pages/product/ProductPage';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';

import HeroCard from './HeroCard';

import * as styles from 'css/components/cards/HeroArtworkCard.module.css';

interface HeroArtworkCardProps
  extends Omit<ProductPageCardProps, 'invalidate'> {
  password?: string;
}

export default function HeroArtworkCard({
  nft,
  nftMetadata,
  password,
}: HeroArtworkCardProps) {
  const track = useHomepageGTM();

  useEffect(() => {
    if (password) {
      setPasswordForProduct(nft.listing.productSlug, password);
    }
  }, [nft, password]);

  const handleClick = useCallback(
    () =>
      track.clickCard(
        CardType.HeroArtworkCard,
        nft.listing.pk,
        nft.listing.productSlug
      ),
    [track, nft.listing.pk, nft.listing.productSlug]
  );

  return (
    <HeroCard
      disableBrowserNavigate
      className={styles.container}
      onClick={handleClick}
    >
      <ProductPageCard
        nft={nft}
        nftMetadata={nftMetadata}
        limitVideoSizeOnMobile={false}
        invalidate={noop}
      />
    </HeroCard>
  );
}
