import { ReactNode } from 'react';

import {
  MPCollapsible,
  MPCollapsibleProps,
  MPDivider,
} from '@mp-frontend/core-components';

interface ProductCollapsibleContainerProps {
  children: ReactNode;
  className?: string;

  collapsed?: boolean;
  collapsible?: boolean;
  onToggle?: MPCollapsibleProps['onToggle'];
  showIcon?: boolean;
  showTopBorder?: boolean;
  title?: string;
}

function ProductCollapsibleContainer({
  children,
  collapsed = true,
  collapsible = true,
  showTopBorder = true,
  ...otherProps
}: ProductCollapsibleContainerProps) {
  return (
    <>
      {!!showTopBorder && <MPDivider />}

      <MPCollapsible
        isCollapsedByDefault={collapsed}
        collapsed={collapsible ? undefined : collapsed}
        {...otherProps}
      >
        {children}
      </MPCollapsible>
    </>
  );
}

export default ProductCollapsibleContainer;
