import { ReactNode } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

interface SubSectionProps {
  children: ReactNode;
  title: ReactNode;
}

export default function SubSection({ title, children }: SubSectionProps) {
  return (
    <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
      <div className={MPFonts.textSmallSemiBold}>{title}</div>
      {children}
    </div>
  );
}
