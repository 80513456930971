.gap32 {
  gap: 32px;
}

.gap28 {
  gap: 28px;
}

.gap24 {
  gap: 24px;
}

.gap20 {
  gap: 20px;
}

.gap18 {
  gap: 18px;
}

.gap16 {
  gap: 16px;
}

.gap14 {
  gap: 14px;
}

.gap12 {
  gap: 12px;
}

.gap10 {
  gap: 10px;
}

.gap8 {
  gap: 8px;
}

.gap6 {
  gap: 6px;
}

.gap4 {
  gap: 4px;
}

.gap2 {
  gap: 2px;
}
