import useVideo from 'hooks/useVideo';

interface InViewVideoProps {
  src: string;
  className?: string;
  poster?: string;
}

export default function InViewVideo({
  className,
  poster,
  src,
}: InViewVideoProps) {
  const { onMouseEnterHandler, setLoaded, videoRef } = useVideo({
    autoPlayOnLoad: true,
  });

  return (
    <video
      ref={videoRef}
      className={className}
      loop
      muted
      playsInline
      poster={poster}
      preload="metadata"
      onLoadedData={setLoaded}
      onLoadedMetadata={setLoaded}
      onMouseEnter={onMouseEnterHandler}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
}
