import { forwardRef } from 'react';
import {
  Fade,
  FadeProps,
  Slide,
  SlideProps,
  useMediaQuery,
} from '@mui/material';

import useDefaultTheme from '../hooks/useDefaultTheme';

export default forwardRef(
  ({ children, ...props }: FadeProps | SlideProps, ref) => {
    const isMobile = useMediaQuery(
      useDefaultTheme().breakpoints.down('desktop')
    );
    return isMobile ? (
      <Fade ref={ref} {...props}>
        {children}
      </Fade>
    ) : (
      <Slide ref={ref} {...props}>
        {children}
      </Slide>
    );
  }
);
