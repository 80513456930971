import { NFTType } from 'types/graphql/NFT';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import useDigitalMediaSaleCoreContract from '../useDigitalMediaSaleCoreContract';

interface useProductAcceptBidEthReturnObject {
  reset: ContractActionObject['mutate']['reset'];
  simulationError: Error | null;
  write: ContractActionObject['mutate']['write'];
  writeAsync: ContractActionObject['mutate']['writeAsync'];
}

function useProductAcceptBidEth(
  nft: NFTType
): useProductAcceptBidEthReturnObject {
  const currentBid = nft.listing.liveBid;

  const { useAcceptBid } = useDigitalMediaSaleCoreContract({
    abi: JSON.parse(currentBid.contract.abidata).abi,
    contractAddress: currentBid.contract.address as HexString,
  });
  const acceptBid = useAcceptBid({
    onchainId: currentBid.onchainId,
    tokenAddress: nft.contract.address,
    tokenId: nft.onchainId,
  });

  return {
    reset: acceptBid.mutate.reset,
    simulationError: acceptBid.simulate.error || null,
    write: acceptBid.mutate.write,
    writeAsync: acceptBid.mutate.writeAsync,
  };
}

export default useProductAcceptBidEth;
