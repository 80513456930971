import currencyFormatter from '../currency/currentFormatter';

const nFormatter = (
  num,
  currencyDisplayMode?,
  minimumFractionDigits?,
  maximumFractionDigits?,
  digits = 2
) => {
  /** Converts a number to n format (K, M, B, T)
      To use without currency, pass `null` for `currencyDisplayMode` arg
   */
  if (!num || typeof num !== 'number') {
    return 0;
  }
  if (num <= 1) {
    return num.toString();
  }

  const lookup = [
    { symbol: '', value: 1 },
    { symbol: 'K', value: 1e3 },
    { symbol: 'M', value: 1e6 },
    { symbol: 'B', value: 1e9 },
    { symbol: 'T', value: 1e12 },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => num >= i.value);

  const value = (num / item.value).toFixed(digits).replace(rx, '$1');
  return item
    ? currencyFormatter({
        currencyDisplayMode,
        maximumFractionDigits,
        minimumFractionDigits,
      }).format(+value) + item.symbol
    : '0';
};

export default nFormatter;
