.upcomingContainer {
  --upcomingExhibitionCard-thumbnailSize: 32px;

  box-sizing: border-box;
  display: block;
  padding: 0 18px;
}

.upcomingContentContainer {
  border-bottom: 2px solid var(--color-backgroundDefault);
  box-sizing: border-box;
  height: 53px;
  padding: 12px 0;
}

.upcomingContentUser {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
}

.upcomingActionButton {
  --actionButtonColor: var(--color-commonBlack);
  --actionButtonColor-active: var(--color-SolidNeutralGray1);
  --actionButtonColor-hover: var(--color-SolidNeutralGray3);
  background-color: var(--color-backgroundDefault);
  border-color: var(--color-backgroundDefault);
  padding-left: 18px;
  padding-right: 12px;

  &:hover {
    border-color: var(--color-backgroundDefault);
  }

  &:active {
    border-color: var(--color-backgroundDefault);
  }
}

.upcomingNoActionTimer {
  padding-left: 18px;
  padding-right: 12px;
}

.upcomingFooterContainer {
  box-sizing: content-box;
  height: var(--upcomingExhibitionCard-thumbnailSize);
  padding: 12px 0;
}

.upcomingExhibitionTitle {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
}

.upcomingThumbnailMedia {
  box-sizing: border-box;
  height: var(--upcomingExhibitionCard-thumbnailSize);
  object-fit: cover;
  position: relative;
  width: var(--upcomingExhibitionCard-thumbnailSize);
}

.upcomingThumbnailContainer {
  flex-shrink: 0;
  justify-content: end;
}

.upcomingAssetBorder {
  border: 1px solid var(--color-SolidNeutralGray1);
}
