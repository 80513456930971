import BaseMarkdown from 'markdown-to-jsx';

import { MPAnimations } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/common/Markdown.module.css';

function CustomLink({ href, children, ...props }) {
  return (
    <a
      {...props}
      href={href}
      className={joinClasses(
        'defaultLink',
        'underline',
        MPAnimations.Color.DarkToLight
      )}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}

function Blockquote({ children }) {
  return <blockquote className={styles.blockquote}>{children}</blockquote>;
}

function Paragraph({ children }) {
  return <p className="reset">{children}</p>;
}

interface MarkdownProps {
  children: string;
  className?: string;
}

export default function Markdown({ children, className }: MarkdownProps) {
  return (
    <BaseMarkdown
      options={{
        overrides: {
          a: {
            component: CustomLink,
            props: { className },
          },
          blockquote: {
            component: Blockquote,
            props: { className },
          },
          p: {
            component: Paragraph,
            props: { className },
          },
        },
      }}
    >
      {children}
    </BaseMarkdown>
  );
}
