/**
 * @generated SignedSource<<b77475cd4b535882e6236e7da790da3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NFTCommentEditMutation$variables = {
  commentId: number;
  body: string;
};
export type NFTCommentEditMutation$data = {
  readonly editCommentNft: {
    readonly success: boolean | null;
    readonly comment: {
      readonly pk: string;
      readonly parentId: number | null;
      readonly updatedAt: any;
      readonly createdAt: any;
      readonly body: string;
    } | null;
  } | null;
};
export type NFTCommentEditMutation = {
  variables: NFTCommentEditMutation$variables;
  response: NFTCommentEditMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "body",
        "variableName": "body"
      },
      {
        "kind": "Variable",
        "name": "commentId",
        "variableName": "commentId"
      }
    ],
    "kind": "ObjectValue",
    "name": "requestData"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTCommentEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EditCommentNFTMutation",
        "kind": "LinkedField",
        "name": "editCommentNft",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTCommentType",
            "kind": "LinkedField",
            "name": "comment",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTCommentEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EditCommentNFTMutation",
        "kind": "LinkedField",
        "name": "editCommentNft",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTCommentType",
            "kind": "LinkedField",
            "name": "comment",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9cf27e16c3932370547adc8d892eb366",
    "id": null,
    "metadata": {},
    "name": "NFTCommentEditMutation",
    "operationKind": "mutation",
    "text": "mutation NFTCommentEditMutation(\n  $commentId: Int!\n  $body: String!\n) {\n  editCommentNft(requestData: {commentId: $commentId, body: $body}) {\n    success\n    comment {\n      pk\n      parentId\n      updatedAt\n      createdAt\n      body\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "50ed73470ac80d93e68a1f28d7001d6f";

export default node;
