.container .slides .slide:not(.current) {
  display: none;
}

.container .slides .slide > .slideImage {
  width: 100%;

  > img {
    width: 100%;
  }

  &.hero {
    max-height: 628px;
    overflow: hidden;

    @media screen and (min-width: 1920px) {
      max-height: 742px;
    }

    @media (--mq-is-mobile) {
      max-height: 242px;
    }

    > img {
      max-height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
