function ShouldIUploadHiResContent() {
  return (
    <div>
      <p>
        <span className="bold">Yes</span>. We highly recommend uploading only
        high resolution digital files where possible. Collectors are most
        interested in purchasing the highest quality version of your work, to
        display and print after they purchase.
      </p>
      <p>
        Every digital creation uploaded on MakersPlace is stored in a secure
        location that&#39;s only available to you and collectors, upon purchase.
      </p>
    </div>
  );
}

export default {
  Component: ShouldIUploadHiResContent,
  title: 'Should I upload my high resolution digital files?',
};
