.artistsFilter {
  box-sizing: border-box;
  gap: 2px;
  list-style-type: none;
  margin: -8px;
  padding: 0;

  @media (--mq-is-mobile) {
    gap: 6px;
  }

  & > li > label {
    box-sizing: border-box;
    padding: 8px;
    width: 100%;

    &:hover {
      background-color: var(--color-backgroundDefault);
    }
  }
}

.artistFilterContainer {
  width: 320px;
  @media (--mq-is-mobile) {
    width: 100%;
  }
}

.artistSearchSection, .activityArtistDivider {
  padding: 8px 0;
}

.availableArtistSection {
  padding-top: 10px;
}

.artistSearchTitle {
  padding: 8px 0 4px 0;
}

.artistSearchIcon {
  height: 18px;
  width: 18px;
}
