import { useCallback } from 'react';

import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { ExploreArtistsPaginatedFragment$data } from 'graphql/__generated__/ExploreArtistsPaginatedFragment.graphql';

import User, { UserProps } from 'components/accounts/User';
import LineClamp from 'components/common/LineClamp';
import ROUTES from 'constants/Routes';
import THRESHOLDS from 'constants/Thresholds';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';
import toNumericShorthand from 'utils/numericShorthand';

import StandardCard from './StandardCard';

import * as styles from 'css/components/cards/UserCard.module.css';

interface UserCardStatProps {
  title: string;
  value: string;
}

function UserCardStat({ title, value }: UserCardStatProps) {
  return (
    <div className={CSSGlobal.Flex.RowSpaceBetween}>
      <div
        className={joinClasses(
          MPFonts.textSmallMedium,
          MPColorClass.SolidNeutralGray5
        )}
      >
        {title}
      </div>
      <div className={MPFonts.textNormalMedium}>{value}</div>
    </div>
  );
}

interface UserCardProps extends UserProps {
  biography: string;
  createdArtworks: ExploreArtistsPaginatedFragment$data['artist']['results']['edges'][0]['node']['createdArtworks'];
  store: ExploreArtistsPaginatedFragment$data['artist']['results']['edges'][0]['node']['store'];
}

function UserCard({
  className,
  user,
  biography,
  store,
  createdArtworks,
}: UserCardProps) {
  const track = useHomepageGTM();

  const handleClick = useCallback(
    () =>
      track.clickCard(
        CardType.ArtistCard,
        user.pk,
        generateFormattedUserFullName(user.fullName)
      ),
    [track, user.pk, user.fullName]
  );

  const metSalesThreshold =
    user.totalVolume?.totalVolumeInUsd > THRESHOLDS.TOTAL_SALES;
  const metArtworkThreshold = store.totalItems > THRESHOLDS.ARTWORKS;
  const metCollectorThreshold = store.numOfOwners > THRESHOLDS.COLLECTORS;

  return (
    <StandardCard
      className={joinClasses(
        'commonPadding',
        MPBackgroundColorClass.CommonWhite,
        className
      )}
      to={ROUTES.PROFILE(user.username)}
      disableBrowserNavigate={!user.username}
      style={{
        '--standardCard-desktopHeight': '452px',
        '--standardCard-mobileHeight': '407px',
        '--standardCard-monitorHeight': '487px',
      }}
      onClick={handleClick}
    >
      <div
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGap[24],
          CSSGlobal.Width.fullWidth
        )}
      >
        <User user={user} size="large" bottomSection="follow" />
        <div
          className={joinClasses(
            styles.assetsContainer,
            CSSGlobal.Flex.Row,
            CSSGap[4]
          )}
        >
          {createdArtworks.edges.map(({ node }) => (
            <div
              key={node.id}
              className={styles.asset}
              style={{ backgroundImage: `url(${node.thumbnailImage})` }}
            />
          ))}
        </div>
        <div className={styles.textContainer}>
          <LineClamp
            lineClamp={2}
            text={biography}
            className={MPFonts.paragraphSmall}
          />
        </div>
        {!!(
          metSalesThreshold ||
          metArtworkThreshold ||
          metCollectorThreshold
        ) && (
          <div className={joinClasses(CSSGlobal.Flex.Row, CSSGap[10])}>
            {!!metSalesThreshold && (
              <UserCardStat
                title="Sales"
                value={`$${toNumericShorthand(
                  user.totalVolume?.totalVolumeInUsd
                )}`}
              />
            )}
            {!!metCollectorThreshold && (
              <UserCardStat
                title="Collectors"
                value={toNumericShorthand(store.numOfOwners)}
              />
            )}
            {!!metArtworkThreshold && (
              <UserCardStat
                title="Original Artworks"
                value={toNumericShorthand(store.totalItems)}
              />
            )}
          </div>
        )}
      </div>
    </StandardCard>
  );
}

export default UserCard;
