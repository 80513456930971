import { SvgIcon } from '@mui/material';

export default function PurchasedArtworkIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1596 7.88599H2V24H5.53049L11.845 15.943L18.1596 24V7.88599ZM9.07107 12.4224C9.07107 13.8105 7.94131 14.9371 6.54929 14.9371C5.15727 14.9371 4.02751 13.8105 4.02751 12.4224C4.02751 11.0344 5.15727 9.90778 6.54929 9.90778C7.94131 9.90778 9.07107 11.0344 9.07107 12.4224Z"
        fill="currentColor"
      />
      <rect x="20" y="1" width="2" height="6" fill="currentColor" />
      <rect x="14" width="1" height="3" fill="currentColor" />
      <rect
        x="24"
        y="3"
        width="2"
        height="6"
        transform="rotate(90 24 3)"
        fill="currentColor"
      />
      <rect
        x="16"
        y="1"
        width="1"
        height="3"
        transform="rotate(90 16 1)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
