import { useMemo } from 'react';
import { addDays } from 'date-fns';

import { MPFonts } from '@mp-frontend/core-components';
import { TimerIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import AuctionTimer from 'components/auction/AuctionTimer';
import useCountdown from 'hooks/useCountdown';
import useForceUpdate from 'hooks/useForceUpdate';
import hasDatePassed from 'utils/hasDatePassed';
import { AuctionState } from 'utils/nftUtils';

import * as styles from 'css/pages/product/ProductLabels.module.css';

export enum TimerType {
  Drop = 'Drop',
  OpenEdition = 'OpenEdition',
  Presale = 'Presale',
  ProductAuction = 'ProductAuction',
  RankedAuction = 'RankedAuction',
}

const useTimerTypeMessagesMap = () => {
  const timerTypeMessagesMap: {
    [key in TimerType]?: Partial<Record<AuctionState, string>>;
  } = {
    [TimerType.Drop]: {
      finalizing: `The exhibition has ended.`,
    },
    [TimerType.ProductAuction]: {
      finalizing:
        'The auction has ended and the creator is accepting the highest bids.',
      live: 'Highest offer must stand for at least 15 minutes for the auction to end.',
      upcoming: 'The auction will open for offers soon.',
    },
    [TimerType.Presale]: {},
  };
  timerTypeMessagesMap[TimerType.RankedAuction] = {
    ...timerTypeMessagesMap[TimerType.ProductAuction],
    live: null,
  };
  timerTypeMessagesMap[TimerType.OpenEdition] =
    timerTypeMessagesMap[TimerType.Drop];

  return timerTypeMessagesMap;
};

interface ProductTimerLabelProps {
  onEnd: () => void;
  type: TimerType;
  endDate?: string;
  startDate?: string;
  upcomingLabel?: string;
}

function ProductTimerLabel({
  onEnd,
  type,
  startDate,
  endDate,
  upcomingLabel = 'Available In',
}: ProductTimerLabelProps) {
  const [forceUpdate, renderId] = useForceUpdate();

  const state = useMemo(() => {
    if (startDate && !hasDatePassed(startDate)) {
      return AuctionState.Upcoming;
    }

    if (endDate && hasDatePassed(endDate)) {
      return AuctionState.Finalizing;
    }

    if (
      (startDate && hasDatePassed(startDate)) ||
      (endDate && !hasDatePassed(endDate))
    ) {
      return type === TimerType.Drop
        ? AuctionState.Finalizing
        : AuctionState.Live;
    }
    return null;

    // renderId is added to dependencies and used to force update the component
    // when the countdown reaches the target date and the state changes
    // even props are the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderId, type, startDate, endDate]);
  const targetDate = useMemo(
    () => (state === AuctionState.Upcoming ? startDate : endDate),
    [state, startDate, endDate]
  );
  const countdown = useCountdown({
    endDate: targetDate,
    onEnd: () => {
      onEnd();
      forceUpdate();
    },
    skipHandlerIfDateWasInPast: true,
  });
  const timerTypeMessagesMap = useTimerTypeMessagesMap();

  const message = timerTypeMessagesMap[type][state];

  return (
    <>
      {!(
        state === AuctionState.Finalizing ||
        (state === AuctionState.Live &&
          endDate &&
          new Date(endDate).getTime() > addDays(new Date(), 7).getTime())
      ) ? (
        <div className={joinClasses(styles.productLabel, styles.countdown)}>
          <div
            className={joinClasses(
              styles.productLabelText,
              styles.secondary,
              MPFonts.textNormalMedium
            )}
          >
            {state === AuctionState.Upcoming ? upcomingLabel : 'Ends In'}
          </div>

          <div className={joinClasses(styles.productLabelText, MPFonts.price)}>
            <TimerIcon className={styles.productTimerIcon} />
            <AuctionTimer
              days={countdown.days}
              hours={countdown.hours}
              minutes={countdown.minutes}
              seconds={countdown.seconds}
            />
          </div>
        </div>
      ) : null}

      {!!message && (
        <div
          className={joinClasses(
            MPFonts.paragraphSmall,
            styles.productMessage,
            {
              [styles.center]: state === AuctionState.Upcoming,
            }
          )}
        >
          {message}
        </div>
      )}
    </>
  );
}

export default ProductTimerLabel;
