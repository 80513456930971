.container {
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.content {
  white-space: pre-wrap;
  word-wrap: break-word;

  &.contentClamped {
    -webkit-line-clamp: var(--line-clamp, 3);
    /* stylelint-disable-next-line property-no-unknown */
    line-clamp: var(--line-clamp, 3);
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    transition: max-height 0.3s ease;
  }

  & a {
    display: inline-flex;
    align-items: center;
    text-underline-offset: 4px;
    color: var(--color-commonBlack);
    transition: var(--transition-colors-smoothly);

    &:hover {
      color: var(--color-SolidNeutralGray3);
    }

    &:active {
      color: var(--color-SolidNeutralGray1);
    }
  }
}

.viewMore {
  cursor: pointer;
}
