import { useMemo } from 'react';

import { APP_NAME } from 'constants/Utils';
import { createGATrackingQueryString } from 'GTM';

import { tagPush } from './base';

export const SUBSCRIPTION_SOURCE = {
  AUTO_OPEN: `${APP_NAME} AutoPopup`,
  EDITORIAL_FOOTER: `${APP_NAME} Editorial Footer`,
  FOOTER: `${APP_NAME} Footer`,
};

export enum NewsletterActionType {
  Close = 'close_newsletter_sign_up',
  Open = 'open_newsletter_sign_up',
  Reject = 'reject_newsletter_sign_up',
  Submit = 'submit_newsletter_sign_up',
}

export default function useNewsletterGTM() {
  return useMemo(
    () => ({
      async registerAction(actionType: NewsletterActionType, source: string) {
        return tagPush(actionType, {
          detail: createGATrackingQueryString({ source }),
        });
      },
    }),
    []
  );
}
