import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import {
  MPActionButton,
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { NotificationsIcon } from '@mp-frontend/core-components/icons';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import { TrackingContext } from 'components/trackingContext';
import ROUTES from 'constants/Routes';
import { DropClickEventType } from 'GTM';
import useOnBodyResize from 'hooks/useOnBodyResize';
import { Timer } from 'pages/drops/components/StatusBar';
import SubscriptionDialog from 'pages/drops/components/SubscriptionDialog';
import { MediaSize } from 'pages/product/productPreview';
import PreviewPagination, {
  usePreviewPaginationState,
} from 'pages/product/productPreview/PreviewPagination';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { AccountArtistFragment } from 'types/graphql/Account';
import { DropType } from 'types/graphql/Drop';
import { NFTType } from 'types/graphql/NFT';
import useDropPageGTM from 'utils/GTM/drop';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';
import hasDatePassed from 'utils/hasDatePassed';
import { getNFTPrimaryMedia, NFTMedia } from 'utils/nftUtils';

import ExhibitionFadingEffect from './ExhibitionFadingEffect';
import HeroCard from './HeroCard';

import * as styles from 'css/components/cards/HeroExhibitionCard.module.css';

type HeroMedia = NFTMedia &
  MediaSize & {
    title: string;
    videoEndTime: number;
    videoPreviewImage: string;
    videoStartTime: number;
  };

const DEFAULT_INITIAL_SCALE = 1.85;
const DEFAULT_INTERVAL_MS = 1800;
const MEDIA_PROP: keyof Omit<NFTMedia, 'hasVideo'> = 'mediumResUrl';

interface HeroExhibitionCardProps {
  exhibition: Pick<
    DropType,
    | 'dropTitle'
    | 'dropsAt'
    | 'heroDescription'
    | 'pk'
    | 'presenterText'
    | 'slug'
  > & {
    creators: ReadonlyArray<Pick<AccountArtistFragment, 'fullName'>>;

    sortedNftMetadatas: {
      edges: ReadonlyArray<{
        node: Pick<
          NFTType['metadata'],
          | 'hasVideo'
          | 'highResImage'
          | 'id'
          | 'rawfileExtension'
          | 'standardImage'
          | 'thumbnailImage'
          | 'title'
          | 'videoUrl'
        > & {
          dropMetadata: {
            previewVideoStartTime: string;
            videoPreviewImageUrl: string;
          };
          hasVideo: boolean;
          mediaMetadata: MediaSize;
        };
      }>;
    };
  };
  invalidate?: () => void;
}

export default function HeroExhibitionCard({
  exhibition,
  invalidate,
}: HeroExhibitionCardProps) {
  const isMobile = useIsMobile();
  const { width: resizeBodyWidth } = useOnBodyResize();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] =
    useState<boolean>(false);
  const track = useHomepageGTM();
  const dropTrack = useDropPageGTM();

  const handleOpenSubscriptionDialog = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setShowSubscriptionDialog(true);
      dropTrack.trackClickEventType(DropClickEventType.SubscribeForUpdates);
    },
    [dropTrack]
  );
  const handleCloseSubscriptionDialog = useCallback(
    (subscribed = false) => {
      if (subscribed) invalidate?.();

      setShowSubscriptionDialog(false);
    },
    [invalidate]
  );
  const isUpcoming = useMemo(
    () => !hasDatePassed(exhibition?.dropsAt),
    [exhibition?.dropsAt]
  );
  const medias = useMemo(
    () =>
      (exhibition?.sortedNftMetadatas.edges.map(({ node: nftMetadata }) => {
        const videoStartTime = nftMetadata.dropMetadata.previewVideoStartTime
          .split(':')
          .reduce(
            (acc, time, index) => acc + parseInt(time, 10) * 60 ** (2 - index),
            0
          );

        return {
          ...getNFTPrimaryMedia(nftMetadata, isMobile),
          height: nftMetadata.mediaMetadata.height,
          title: nftMetadata.title,
          videoEndTime: videoStartTime + DEFAULT_INTERVAL_MS / 1000,
          videoPreviewImage: nftMetadata.dropMetadata.videoPreviewImageUrl,
          videoStartTime,
          width: nftMetadata.mediaMetadata.width,
        } as HeroMedia;
      }) ?? []) as HeroMedia[],
    [exhibition?.sortedNftMetadatas, isMobile]
  );
  const paginationState = usePreviewPaginationState({
    infinite: true,
    intervalMs: DEFAULT_INTERVAL_MS,
    medias,
  });
  const handleMouseEnter = useCallback(() => {
    paginationState.start();
    setIsHovered(true);
  }, [paginationState]);
  const handleMouseLeave = useCallback(() => {
    paginationState.reset();
    setIsHovered(false);
  }, [paginationState]);
  const handleClick = useCallback(
    (event: MouseEvent) => {
      track.clickCard(
        CardType.HeroExhibitionCard,
        exhibition.pk,
        exhibition.dropTitle
      );
      event.stopPropagation();
    },
    [track, exhibition.pk, exhibition.dropTitle]
  );
  const coverMedia = medias.length ? medias[0] : null;
  const [mediaContainerRef, setMediaContainerRef] =
    useRefState<HTMLDivElement>();
  const [mediaRef, setMediaRef] = useRefState<
    HTMLImageElement | HTMLVideoElement
  >();
  const [scale, setScale] = useState<number>(DEFAULT_INITIAL_SCALE);
  useEffect(() => {
    let newScale = DEFAULT_INITIAL_SCALE;

    if (mediaContainerRef && mediaRef && coverMedia) {
      const renderedHeight = Math.min(
        (coverMedia.height / coverMedia.width) * mediaRef.offsetWidth,
        mediaRef.offsetHeight
      );
      const renderedWidth =
        (coverMedia.width / coverMedia.height) * renderedHeight;
      for (let i = 1; i < 10; i += 0.1) {
        const scaledHeight = renderedHeight * i;
        const scaledWidth = renderedWidth * i;
        if (
          scaledHeight > mediaContainerRef.offsetHeight &&
          scaledWidth > mediaContainerRef.offsetWidth
        ) {
          newScale = i;
          break;
        }
      }
      newScale += 0.15;
    }

    setScale(newScale);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaContainerRef, coverMedia, resizeBodyWidth]);
  useEffect(() => {
    const currentMedia = medias[paginationState.currentIndex];
    if (!currentMedia?.hasVideo || !(mediaRef instanceof HTMLVideoElement))
      return;

    const video = mediaRef as HTMLVideoElement;
    video.currentTime = currentMedia.videoStartTime;
    (async () => {
      if (isHovered) {
        await video.play();
      } else {
        video.pause();
      }
    })().catch(() => {});
  }, [isHovered, medias, mediaRef, paginationState.currentIndex]);

  if (!medias.length) return null;

  return (
    <TrackingContext dropTitle={exhibition.dropTitle}>
      <HeroCard
        to={ROUTES.EXHIBITION(exhibition.slug)}
        className={joinClasses(
          CSSGlobal.Cursor.Pointer,
          CSSGap[32],
          MPColorClass.CommonBlack,
          MPBackgroundColorClass.CommonWhite,
          'anchor',
          styles.container
        )}
        onClick={handleClick}
      >
        <div
          ref={setMediaContainerRef}
          className={joinClasses(styles.mediaContainer, {
            [styles.hovered]: isHovered,
          })}
          style={
            {
              '--heroExhibitionCard-coverScale': scale,
            } as any
          }
          {...(!isMobile
            ? {
                onMouseEnter: handleMouseEnter,
                onMouseLeave: handleMouseLeave,
              }
            : {})}
        >
          <div
            className={joinClasses(
              CSSGlobal.Flex.Col,
              CSSGap[32],
              styles.media
            )}
          >
            {isMobile ? (
              <img
                alt={`The "${coverMedia.title}" artwork from the "${exhibition.dropTitle}" exhibition.`}
                aria-label={`Click to explore the "${exhibition.dropTitle}" exhibition.`}
                className={styles.cover}
                src={
                  (coverMedia.hasVideo && coverMedia.videoPreviewImage) ||
                  coverMedia.mediumResUrl
                }
              />
            ) : (
              <div className={styles.artworkContainer}>
                {medias.map((media, index) =>
                  media.hasVideo ? (
                    <video
                      key={media.id}
                      aria-label={`Click to explore the "${exhibition.dropTitle}" exhibition.`}
                      className={joinClasses(styles.artwork, {
                        [styles.active]: index === paginationState.currentIndex,
                      })}
                      controls={false}
                      itemScope
                      itemType="http://schema.org/VideoObject"
                      muted
                      playsInline
                      preload="auto"
                      ref={(node) => {
                        if (index === paginationState.currentIndex) {
                          setMediaRef(node);
                        }
                      }}
                      poster={media.videoPreviewImage}
                    >
                      <source
                        src={`${media.videoUrl}#t=${media.videoStartTime},${media.videoEndTime}`}
                        type="video/mp4"
                      />
                      <meta
                        content={`The "${media.title}" artwork from the "${exhibition.dropTitle}" exhibition.`}
                        itemProp="description"
                      />
                      <meta content={media.title} itemProp="name" />
                      <meta
                        content={media.videoPreviewImage}
                        itemProp="thumbnail"
                      />
                    </video>
                  ) : (
                    <img
                      key={media.id}
                      alt={`The "${media.title}" artwork from the "${exhibition.dropTitle}" exhibition.`}
                      aria-label={`Click to explore the "${exhibition.dropTitle}" exhibition.`}
                      className={joinClasses(styles.artwork, {
                        [styles.active]: index === paginationState.currentIndex,
                      })}
                      ref={(node) => {
                        if (index === paginationState.currentIndex) {
                          setMediaRef(node);
                        }
                      }}
                      src={media[MEDIA_PROP]}
                    />
                  )
                )}
              </div>
            )}

            <PreviewPagination
              className={styles.pagination}
              disableAutoStart
              disableSelection
              medias={medias}
              {...paginationState}
            />
          </div>
        </div>

        <div
          className={joinClasses(
            CSSGlobal.Flex.ColCenterAlign,
            CSSGap[24],
            styles.rail
          )}
        >
          <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[2])}>
            <ExhibitionFadingEffect>
              {exhibition.creators.map(({ fullName }) => (
                <div key={fullName} className={MPFonts.headline4}>
                  {fullName}
                </div>
              ))}
            </ExhibitionFadingEffect>
            <span className={MPFonts.textNormalMedium}>
              {exhibition.dropTitle}
            </span>
            {!!exhibition.presenterText && (
              <span
                className={joinClasses(
                  MPFonts.textSmallSemiBold,
                  MPColorClass.SolidNeutralGray5
                )}
              >
                Presented by{' '}
                {exhibition.presenterText.replace(/( )?present(s|ed)?$/i, '')}
              </span>
            )}
          </div>

          <div className={MPFonts.paragraphSmall}>
            {exhibition.heroDescription}
          </div>

          {isUpcoming ? (
            <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[12])}>
              <MPActionButton
                fullWidth
                size="large"
                onClick={handleOpenSubscriptionDialog}
              >
                <ExhibitionFadingEffect>
                  {[
                    <div
                      key="copy"
                      className={CSSGlobal.Flex.InlineRowCenterAlign}
                    >
                      Get Exhibition Reminder
                      <NotificationsIcon fontSize="20" />
                    </div>,
                    <div
                      key="timer"
                      className={CSSGlobal.Flex.InlineRowCenterAlign}
                    >
                      <Timer endDate={exhibition.dropsAt} hideIcon />
                      <NotificationsIcon fontSize="20" />
                    </div>,
                  ]}
                </ExhibitionFadingEffect>
              </MPActionButton>
              <MPActionButton fullWidth size="large" variant="secondary">
                Preview the Exhibition
              </MPActionButton>
            </div>
          ) : (
            <MPActionButton fullWidth size="large">
              Explore the Exhibition
            </MPActionButton>
          )}
        </div>
      </HeroCard>

      {!!showSubscriptionDialog && (
        <SubscriptionDialog
          dropId={exhibition.pk}
          dropTitle={exhibition.dropTitle}
          dropsAt={exhibition.dropsAt}
          onClose={handleCloseSubscriptionDialog}
        />
      )}
    </TrackingContext>
  );
}
