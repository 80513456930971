import {
  Children,
  FunctionComponent,
  ReactElement,
  ReactNode,
  ReactPortal,
} from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import {
  MPTab,
  MPTabProps,
  MPTabs,
  MPTabsProps,
  MPTabsSize,
  MPTabsVariant,
} from '@mp-frontend/core-components';

import TabPanel from './TabPanel';

export type TabPassedProps = { value: MPTabProps['value'] } & Omit<
  MPTabProps,
  'design' | 'size' | 'component | value'
>;

export interface TabsProps<
  TP = Record<string, unknown>,
  TPP extends TabPassedProps = TabPassedProps
> {
  currentTab: string;
  tabs: Array<TPP>;
  TabComponent?: {
    component: FunctionComponent<any>;
    props: TP;
    type: FunctionComponent<
      TPP &
        TP & {
          component: any;
          design: MPTabsVariant;
          size: MPTabsSize;
        }
    >;
  };
  children?: ReactNode;
  design?: MPTabsVariant;
  onChange?: MPTabsProps['onChange'];
  panelContainerNode?: any;
  size?: MPTabsSize;
  tabsSx?: MPTabsProps['sx'];
}

type ReactExistingChild = Exclude<ReactNode, boolean | null | undefined>;
type ReactExistingChildComponent = Exclude<ReactExistingChild, string | number>;
function isReactChildComponent(
  node: ReactExistingChild
): node is ReactExistingChildComponent {
  const typeOfChild = typeof node;
  return !(typeOfChild === 'string' || typeOfChild === 'number');
}

function Tabs<
  TP extends Record<string, unknown> = Record<string, unknown>,
  TPP extends TabPassedProps = TabPassedProps
>({
  currentTab,
  tabs,
  onChange,
  panelContainerNode,
  tabsSx,
  children,
  TabComponent = {
    component: Link as any,
    props: {} as TP,
    type: MPTab as any,
  },
  design = MPTabsVariant.Standard,
  size = MPTabsSize.Small,
}: TabsProps<TP, TPP>) {
  const panels = Children.toArray(children).map((child) => {
    if (
      isReactChildComponent(child) &&
      'props' in (child as ReactExistingChildComponent)
    ) {
      const { props } = child as ReactElement | ReactPortal;
      return (
        <TabPanel
          value={props.value}
          key={props.value}
          currentSelected={currentTab}
        >
          {child}
        </TabPanel>
      );
    }
    return null;
  });

  return (
    <>
      <MPTabs
        sx={tabsSx}
        value={currentTab}
        design={design}
        onChange={onChange}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          // eslint-disable-next-line react/jsx-pascal-case
          <TabComponent.type
            key={tab.value}
            design={design}
            size={size}
            component={TabComponent.component}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            {...tab}
            {...TabComponent.props}
          />
        ))}
      </MPTabs>
      {panelContainerNode !== undefined && panels.length
        ? panelContainerNode
          ? createPortal(panels, panelContainerNode)
          : null
        : panels}
    </>
  );
}

export default Tabs;
