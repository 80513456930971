.example {
  box-sizing: border-box;

  > .image {
    height: 84px;
    width: 84px;
  }

  > .details {
    flex-grow: 1;
    flex-basis: 0;
  }
}
