import { MutableRefObject, useEffect, useRef, useState } from 'react';

export enum StickyElementState {
  ScrollingDownEnter = 'ScrollingDownEnter',
  ScrollingDownLeave = 'ScrollingDownLeave',
  ScrollingUpEnter = 'ScrollingUpEnter',
  ScrollingUpLeave = 'ScrollingUpLeave',
}

function useStickyPinned(
  elementRef: MutableRefObject<HTMLElement>,
  rootRef?: MutableRefObject<HTMLElement>
) {
  const observerRef = useRef<IntersectionObserver>(null);
  const [stuck, setStuck] = useState<boolean>(false);
  const [state, setState] = useState<StickyElementState>(null);
  const previousYRef = useRef<number>(0);
  const previousRatioRef = useRef<number>(0);

  const thresholds = (steps: number) =>
    Array(steps + 1)
      .fill(0)
      .map((_, index) => index / steps || 0);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(
      ([
        {
          isIntersecting,
          intersectionRatio: currentRatio,
          boundingClientRect: { y: currentY },
        },
      ]: Array<IntersectionObserverEntry>) => {
        if (currentY < previousYRef.current) {
          if (currentRatio > previousRatioRef.current && isIntersecting) {
            setState(StickyElementState.ScrollingDownEnter);
          } else {
            setState(StickyElementState.ScrollingDownLeave);
          }
        } else if (currentY > previousYRef.current && isIntersecting) {
          if (currentRatio < previousRatioRef.current) {
            setState(StickyElementState.ScrollingUpLeave);
          } else {
            setState(StickyElementState.ScrollingUpEnter);
          }
        }

        previousYRef.current = currentY;
        previousRatioRef.current = currentRatio;
        setStuck(currentRatio < 1);
      },
      {
        root: rootRef?.current,
        threshold: thresholds(10),
      }
    );
    const { current } = elementRef;
    if (!current) {
      return undefined;
    }

    observerRef.current.observe(current);
    return () => {
      observerRef.current.unobserve(current);
    };
  }, [elementRef.current, rootRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return [stuck, state] as const;
}

export default useStickyPinned;
