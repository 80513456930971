/**
 * @generated SignedSource<<3f9f8d20d104d43d3469507fffad1dce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NFTsRankedAuctionLeaderboardQuery$variables = {
  digitalMediaId?: number | null;
  isLiveOnChain?: boolean | null;
  first?: number | null;
};
export type NFTsRankedAuctionLeaderboardQuery$data = {
  readonly nfts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly listing: {
          readonly rankedAuction: {
            readonly endsAt?: any | null;
            readonly startsAt?: any | null;
            readonly totalBids?: number | null;
            readonly totalPartialBids?: number | null;
            readonly leaderboard?: ReadonlyArray<{
              readonly id: string;
              readonly bidderAddress: `0x${string}` | null;
              readonly bidderUser: {
                readonly fullName: string | null;
                readonly username: string;
                readonly profileImageUrl: string;
                readonly store: {
                  readonly storeSlug: string | null;
                } | null;
              } | null;
              readonly bidAmount: number | null;
              readonly bidInEther: number | null;
              readonly bidInUsd: number | null;
              readonly createdAt: any | null;
              readonly isCcBid: boolean | null;
              readonly isEtherBid: boolean | null;
            } | null> | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};
export type NFTsRankedAuctionLeaderboardQuery = {
  variables: NFTsRankedAuctionLeaderboardQuery$variables;
  response: NFTsRankedAuctionLeaderboardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "digitalMediaId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLiveOnChain"
},
v3 = [
  {
    "kind": "Variable",
    "name": "digitalMediaId",
    "variableName": "digitalMediaId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isLiveOnChain",
    "variableName": "isLiveOnChain"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalBids",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPartialBids",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidderAddress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeSlug",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidAmount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInEther",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInUsd",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCcBid",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEtherBid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTsRankedAuctionLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTListingAbstractType",
                    "kind": "LinkedField",
                    "name": "listing",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "rankedAuction",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "auction",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "NFTRankedBidType",
                                "kind": "LinkedField",
                                "name": "leaderboard",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/),
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AccountAbstractType",
                                    "kind": "LinkedField",
                                    "name": "bidderUser",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "StoreTypeV3",
                                        "kind": "LinkedField",
                                        "name": "store",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "NFTRankedAuctionType",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTsRankedAuctionLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTListingAbstractType",
                    "kind": "LinkedField",
                    "name": "listing",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "rankedAuction",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "auction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "NFTRankedBidType",
                                "kind": "LinkedField",
                                "name": "leaderboard",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/),
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AccountAbstractType",
                                    "kind": "LinkedField",
                                    "name": "bidderUser",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "StoreTypeV3",
                                        "kind": "LinkedField",
                                        "name": "store",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "type": "NFTRankedAuctionType",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "type": "NFTAuctionType",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5724d5f03b583c4fabefae7dee4393f2",
    "id": null,
    "metadata": {},
    "name": "NFTsRankedAuctionLeaderboardQuery",
    "operationKind": "query",
    "text": "query NFTsRankedAuctionLeaderboardQuery(\n  $digitalMediaId: Float\n  $isLiveOnChain: Boolean\n  $first: Int\n) {\n  nfts(digitalMediaId: $digitalMediaId, first: $first, isLiveOnChain: $isLiveOnChain) {\n    edges {\n      node {\n        id\n        listing {\n          rankedAuction: auction {\n            __typename\n            ... on NFTRankedAuctionType {\n              endsAt\n              startsAt\n              totalBids\n              totalPartialBids\n              leaderboard {\n                id\n                bidderAddress\n                bidderUser {\n                  fullName\n                  username\n                  profileImageUrl\n                  store {\n                    storeSlug\n                    id\n                  }\n                  id\n                }\n                bidAmount\n                bidInEther\n                bidInUsd\n                createdAt\n                isCcBid\n                isEtherBid\n              }\n              id\n            }\n            ... on NFTAuctionType {\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d64a18b69300ce25d4d3da3ebf4da8c8";

export default node;
