import { ReactNode } from 'react';
import { styled } from '@mui/system';

import { joinClasses } from '@mp-frontend/core-utils';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';
import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import MPAnimations from '../css/MPAnimations';

const StyledButton = styled('button')`
  align-items: center;
  background-color: ${MPColorValue.CommonWhite};
  border: 1px solid ${MPColorValue.SolidNeutralGray1};
  box-sizing: border-box;
  color: ${MPColorValue.CommonBlack};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 40px;
  justify-content: center;
  outline: none;
  padding: 16px;
  text-align: left;
  width: 100%;

  &:hover {
    border-color: ${MPColorValue.SolidNeutralGray3};
    border-width: 2px;
  }

  &:disabled {
    color: ${MPColorValue.SolidNeutralGray1};
    cursor: default;
  }

  &.selected {
    border-color: ${MPColorValue.CommonBlack};
    border-width: 2px;
  }

  &.filled {
    background-color: ${MPColorValue.BackgroundDefault};
    border: none;
  }

  &.dark {
    background-color: ${MPColorValue.CommonBlack};
    color: ${MPColorValue.CommonWhite};
    border: none;
  }
`;

const StyledTitle = styled('span')`
  flex-grow: 0;
  flex-basis: 0;
`;

const StyledSuffix = styled('span')`
  color: ${MPColorValue.SolidNeutralGray5};
`;

export type MPSelectorItemVariant = 'standard' | 'filled' | 'dark';

export interface MPSelectorItemProps {
  onClick: () => void;
  title: string;
  classes?: {
    root?: string;
    suffix?: string;
    title?: string;
  };
  disabled?: boolean;
  prefix?: ReactNode;
  selected?: boolean;
  suffix?: ReactNode;
  variant?: MPSelectorItemVariant;
}

export default function MPSelectorItem({
  title,
  classes = {},
  disabled = false,
  prefix = null,
  selected = false,
  suffix = null,
  variant = 'dark',
  onClick,
}: MPSelectorItemProps) {
  return (
    <StyledButton
      type="button"
      className={joinClasses(classes.root, selected && 'selected', variant, {
        [MPAnimations.Color.DarkToLight]: variant === 'dark',
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {prefix}

      <StyledTitle
        className={joinClasses(MPFonts.textNormalMedium, classes.title)}
      >
        {title}
      </StyledTitle>

      {suffix ? (
        <StyledSuffix
          className={joinClasses(MPFonts.textSmallMedium, classes.suffix)}
        >
          {suffix}
        </StyledSuffix>
      ) : null}
    </StyledButton>
  );
}
