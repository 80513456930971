:root {
  --activity-horizontal-padding: 32px;

  @media (--mq-is-mobile) {
    --activity-horizontal-padding: 24px;
  }
}

.title {
  box-sizing: border-box;
  padding: 28px var(--activity-horizontal-padding);

  @media (--mq-is-mobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.table {
  margin: 60px var(--activity-horizontal-padding) 0;

  @media (--mq-is-mobile) {
    margin-top: 20px;
  }

  @media screen and (min-width: 1920px) {
    margin: 60px auto 0;
    max-width: 1700px;
  }

  &.noResults {
    text-align: center;
  }

  & .card {
    transition:
      background-color 300ms var(--mp-tense-out),
      border-color 300ms var(--mp-tense-out);
    border: 1px solid;
    border-color: var(--color-commonWhite);
    box-sizing: border-box;
  }

  @media (--mq-is-not-mobile) {
    &:hover .card {
      background-color: var(--color-backgroundDefault);
      border-color: var(--color-SolidNeutralGray1);

      &:hover {
        background-color: var(--color-commonWhite);
        border-color: var(--color-commonWhite);
      }
    }
  }
}
