import { ComponentProps } from 'react';
import { SvgIcon } from '@mui/material';

export default function CoinbaseWalletIcon(
  props: ComponentProps<typeof SvgIcon>
) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#icon-coinbase-wallet-clip-path)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99872 0.5H19.0003C21.7622 0.5 24 2.90768 24 5.87792V19.1221C24 22.0923 21.7622 24.5 19.0013 24.5H4.99872C2.23776 24.5 0 22.0923 0 19.1221V5.87792C0 2.90768 2.23776 0.5 4.99872 0.5Z"
          fill="#0052FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9965 3.97461C16.7043 3.97461 20.5203 7.79061 20.5203 12.4985C20.5203 17.2063 16.7043 21.0223 11.9965 21.0223C7.28866 21.0223 3.47266 17.2063 3.47266 12.4985C3.47266 7.79061 7.28866 3.97461 11.9965 3.97461Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9023 9.77539H14.1004C14.446 9.77539 14.7253 10.0768 14.7253 10.4474V14.5514C14.7253 14.9229 14.445 15.2234 14.1004 15.2234H9.9023C9.5567 15.2234 9.27734 14.922 9.27734 14.5514V10.4474C9.27734 10.0768 9.55766 9.77539 9.9023 9.77539Z"
          fill="#0052FF"
        />
      </g>
      <defs>
        <clipPath id="icon-coinbase-wallet-clip-path">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
