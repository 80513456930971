import { SvgIcon } from '@mui/material';

export default function DefaultUserIcon(props) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2332_3311)">
        <path
          d="M12 10.944C14.9288 10.944 17.2998 8.496 17.2998 5.472C17.2998 2.448 14.9288 0 12 0C9.0712 0 6.70026 2.448 6.70026 5.472C6.70026 8.496 9.0712 10.944 12 10.944Z"
          fill="currentColor"
        />
        <path
          d="M22.2857 13.38H1.71429V24H22.2857V13.38Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2332_3311">
          <rect
            width="20.5714"
            height="24"
            fill="currentColor"
            transform="translate(1.71429)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
