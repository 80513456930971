import { forwardRef } from 'react';
import { merge } from 'lodash';
import { SvgIcon, SvgIconProps } from '@mui/material';

export interface MPSVGIconProps<T extends typeof SvgIcon = typeof SvgIcon>
  extends Omit<SvgIconProps, 'fontSize'> {
  IconClass?: T;
  fontSize?: SvgIconProps['fontSize'] | `${number}`;
}

function isSvgFontSizeType(
  fontSize: MPSVGIconProps['fontSize']
): fontSize is SvgIconProps['fontSize'] {
  return !!fontSize && Number.isNaN(+fontSize);
}

const MPSVGIcon = forwardRef<SVGSVGElement, MPSVGIconProps>(
  ({ fontSize, sx, IconClass = SvgIcon, ...props }: MPSVGIconProps, ref) => {
    const mergedSx = merge(
      !!fontSize && !Number.isNaN(+fontSize)
        ? { height: `${fontSize}px`, width: `${fontSize}px` }
        : {},
      sx ?? {}
    );

    return (
      <IconClass
        fontSize={isSvgFontSizeType(fontSize) ? fontSize : undefined}
        {...props}
        ref={ref}
        sx={mergedSx}
      />
    );
  }
);

export default MPSVGIcon;
