import { SvgIcon } from '@mui/material';

export default function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.5455 5.64896C21.1515 5.04297 21.1515 4.06048 20.5455 3.45449C19.9395 2.8485 18.957 2.8485 18.351 3.45449L12 9.80553L5.64896 3.45449C5.04297 2.8485 4.06048 2.8485 3.45449 3.45449C2.8485 4.06048 2.8485 5.04297 3.45449 5.64896L9.80553 12L3.45449 18.351C2.8485 18.957 2.84851 19.9395 3.45449 20.5455C4.06048 21.1515 5.04297 21.1515 5.64896 20.5455L12 14.1945L18.351 20.5455C18.957 21.1515 19.9395 21.1515 20.5455 20.5455C21.1515 19.9395 21.1515 18.957 20.5455 18.351L14.1945 12L20.5455 5.64896Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
