import { Children, ReactNode, useEffect, useState } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/cards/ExhibitionFadingEffect.module.css';

/**
 * NOTE: This component only works for exhibitions usage at the current homepage as per design system.
 * For complex usage this would need to be refactored.
 * */
interface ExhibitionFadingEffectProps {
  children: ReactNode[];
  className?: string;
  intervalMs?: number;
  transitionMs?: number;
}

function ExhibitionFadingEffect({
  children,
  className,
  transitionMs = 600,
  intervalMs = 2200,
}: ExhibitionFadingEffectProps) {
  const [state, setState] = useState<{ firstRun: boolean; index: number }>({
    firstRun: true,
    index: 0,
  });
  const count = Children.count(children);

  useEffect(() => {
    if (count <= 1) return undefined;
    const intervalId = setInterval(() => {
      setState(({ index }) => ({
        firstRun: false,
        index: (index + 1) % count,
      }));
    }, intervalMs + 2 * transitionMs);
    return () => clearInterval(intervalId);
  }, [children, intervalMs, transitionMs, count]);

  return (
    <div
      className={className}
      style={
        {
          '--interval-ms': `${intervalMs}ms`,
          '--transition-ms': `${transitionMs}ms`,
        } as any
      }
    >
      {count > 1
        ? Children.map(children, (child, index) => (
            <div
              className={joinClasses(styles.fadeInOutAnimation, {
                [CSSGlobal.Hidden]: index !== state.index,
                [styles.first]: state.index === 0 && state.firstRun,
              })}
            >
              {child}
            </div>
          ))
        : children}
    </div>
  );
}

export default ExhibitionFadingEffect;
