import { PaletteColor } from '@mui/material';

import ColorValue from './colors';
/* eslint-disable sort-keys-fix/sort-keys-fix */

/**
 * MP Design Schema includes 4 additional shades of each themes main color. This function
 * generates those colors.
 */
const generatePaletteColor = (function anon() {
  function parseHex(hex: string) {
    let c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    return [
      parseInt(c[0] + c[1], 16),
      parseInt(c[2] + c[3], 16),
      parseInt(c[4] + c[5], 16),
    ];
  }
  return function innerGeneratePaletteColor(
    color: Partial<PaletteColor> & { main: string }
  ) {
    let opacity = 1;
    let parts;
    let rgba;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color.main)) {
      parts = parseHex(color.main);
    } else if (
      /* eslint-disable-next-line no-cond-assign */
      (rgba = color.main.match(
        /^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,s*([\d.]+)\s*\)$/i
      ))
    ) {
      if (rgba) {
        /* eslint-disable-next-line prefer-destructuring */
        opacity = rgba[3];
        parts = rgba.slice(0, 3);
      }
    } else {
      throw new Error('unknown color');
    }
    return {
      p5: `rgba(${parts[0]},${parts[1]},${parts[2]},${0.05 * opacity})`,
      p20: `rgba(${parts[0]},${parts[1]},${parts[2]},${0.2 * opacity})`,
      p45: `rgba(${parts[0]},${parts[1]},${parts[2]},${0.45 * opacity})`,
      p60: `rgba(${parts[0]},${parts[1]},${parts[2]},${0.6 * opacity})`,
      p80: `rgba(${parts[0]},${parts[1]},${parts[2]},${0.8 * opacity})`,
      ...color,
    };
  };
})();

const Primary = generatePaletteColor({
  main: ColorValue.DefaultBlack,
  light: ColorValue.Gray,
  dark: ColorValue.EmphasizedBlack,
});

const Secondary = generatePaletteColor({
  main: ColorValue.DefaultBlack,
  light: ColorValue.Gray,
  dark: ColorValue.EmphasizedBlack,
});

const Success = generatePaletteColor({
  main: ColorValue.Green,
  light: ColorValue.LightGreen,
  dark: ColorValue.DarkGreen,
});

// renamed to avoid conflict with Error
const ErrorColor = generatePaletteColor({
  main: ColorValue.Red,
  light: ColorValue.LightRed,
  dark: ColorValue.DarkRed,
});

const Text = {
  primary: ColorValue.DefaultBlack,
  secondary: Secondary.main,
  disabled: ColorValue.DisabledGray,
};

const Action = {
  primary: ColorValue.OrangePrimary,
  disabled: ColorValue.White,
};

const Common = {
  selectedGray: ColorValue.SelectedGray,
};

const Drop = {
  live: ColorValue.LightGreen,
  upcoming: ColorValue.LightOrange,
};

const Background = {
  default: ColorValue.BackgroundWhite,
  paper: ColorValue.White,
  overlay: ColorValue.Overlay,
};

const SolidNeutral = {
  Gray1: ColorValue.SolidNeutralGray1,
  Gray2: ColorValue.SolidNeutralGray2,
  Gray3: ColorValue.SolidNeutralGray3,
  Gray4: ColorValue.SolidNeutralGray4,
  Gray5: ColorValue.SolidNeutralGray5,
  Gray6: ColorValue.SolidNeutralGray6,
  Gray7: ColorValue.SolidNeutralGray7,
  OffBlack: ColorValue.OffBlack,
};

const Orange = {
  Primary: ColorValue.OrangePrimary,
  Highlight: ColorValue.OrangeHighlight,
  Lowlight: ColorValue.OrangeLowlight,
};

const Anchor = {
  primary: ColorValue.AnchorBlue,
};

const Transparent = {
  primary: ColorValue.Transparent,
};

const Gold = generatePaletteColor({
  main: ColorValue.Gold,
});

const MPPalette = {
  primary: Primary,
  secondary: Secondary,
  success: Success,
  error: ErrorColor,
  action: Action,
  text: Text,
  common: Common,
  background: Background,
  drop: Drop,
  SolidNeutral,
  orange: Orange,
  anchor: Anchor,
  transparent: Transparent,
  gold: Gold,
};

export default MPPalette;
