import {
  Dispatch,
  SetStateAction,
  startTransition,
  useEffect,
  useState,
} from 'react';
import { useMutation } from 'react-relay';

import DelistSaleWithPersonalWalletMutation from 'graphql/__generated__/ListingDelistSaleWithPersonalWalletMutation.graphql';
import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import WalletActionButton from 'components/WalletActionButton';
import useDigitalMediaSaleCoreContract from 'hooks/contracts/useDigitalMediaSaleCoreContract';
import useLogging from 'hooks/useLogging';
import { NFTType } from 'types/graphql/NFT';
import { HexString } from 'utils/jwt/walletUtils';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';

interface CustodialDelistButtonProps {
  children: any;
  nft: NFTType;
  saleContract: Pick<
    NFTContractQuery$data['nftContract'],
    'abidata' | 'address'
  >;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

export default function WalletDelistButton({
  nft,
  setOwnershipState,
  saleContract,
  setCurrentTransactionHash,
  children,
}: CustodialDelistButtonProps) {
  const { logNFTException } = useLogging();
  const { useCancelSale } = useDigitalMediaSaleCoreContract({
    abi: JSON.parse(saleContract.abidata).abi,
    contractAddress: saleContract.address as HexString,
  });

  const {
    mutate: cancelSaleWrite,
    simulate: {
      isError: isCancelSaleError,
      isPending: isCancelSaleFetching,
      isFetching: isCancelSaleRefetching,
    },
  } = useCancelSale({
    tokenAddress: nft.contract.address as HexString,
    tokenId: parseInt(nft.onchainId, 10),
  });

  const [
    isValidatingCancelSaleTransaction,
    setIsValidatingCancelSaleTransaction,
  ] = useState<boolean>(false);

  const [commitDelistSaleWithPersonalWalletMutation] = useMutation(
    DelistSaleWithPersonalWalletMutation
  );

  useEffect(() => {
    if (cancelSaleWrite.isError) {
      logNFTException(nft.pk, cancelSaleWrite.error);
    }
  }, [cancelSaleWrite.isError, cancelSaleWrite.error, nft.pk, logNFTException]);

  const delistWithPersonalWallet = async ({ saleId, transactionId }) =>
    new Promise((resolve, reject) => {
      commitDelistSaleWithPersonalWalletMutation({
        onCompleted: (data: any) => {
          resolve(data);
        },
        onError(error) {
          reject(error);
        },
        variables: {
          request_data: {
            saleId,
            transactionId,
          },
        },
      });
    });

  const delistTokenPersonal = async () => {
    try {
      setIsValidatingCancelSaleTransaction(true);
      const transactionResult = await cancelSaleWrite.writeAsync();
      await delistWithPersonalWallet({
        saleId: parseInt(nft.listing.liveSale.pk, 10),
        transactionId: transactionResult,
      });
      setCurrentTransactionHash(transactionResult);
      setIsValidatingCancelSaleTransaction(false);
      startTransition(() => {
        setOwnershipState(ProductOwnerStates.DELIST_PENDING);
      });
    } catch (error) {
      cancelSaleWrite.reset();
      setIsValidatingCancelSaleTransaction(false);
      logNFTException(nft.pk, error);
    }
  };

  const isLoading =
    isCancelSaleFetching ||
    isCancelSaleRefetching ||
    isValidatingCancelSaleTransaction;

  return (
    <WalletActionButton
      fullWidth
      onClick={delistTokenPersonal}
      disabled={isLoading || isCancelSaleError}
      warnIfAddressNotRegistered
      size="large"
    >
      {children}
    </WalletActionButton>
  );
}
