.cardDetailRow {
  border: 1px solid var(--color-SolidNeutralGray3);
  color: var(--color-SolidNeutralGray3);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  box-sizing: border-box;
}

.dialogBody {
  padding: 16px 34px;
}

.removeConfirmationText {
  margin-bottom: 16px;
}
