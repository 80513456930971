import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const CopyIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M15.3799 3.75L4.87988 3.75001V15.75H7.37988V6.25H15.3799V3.75ZM19.1299 7.75H8.87988V20.25H19.1299V7.75ZM16.6299 17.75H11.3799V10.25H16.6299V17.75Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default CopyIcon;
