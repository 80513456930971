import { useCallback, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { joinClasses } from '@mp-frontend/core-utils';

import ModularPagesSectionsQueryType, {
  ModularPagesSectionsQuery,
} from 'graphql/__generated__/ModularPagesSectionsQuery.graphql';
import {
  HomepageSectionTypeEnum,
  PageMediaVariantEnum,
  PageSectionTypeEnum,
} from 'types/__generated__/graphql';

import HeroArtistCardSection from 'components/cards/HeroArtistCard';
import HeroArtworkCard from 'components/cards/HeroArtworkCard';
import HeroEditorialCard from 'components/cards/HeroEditorialCard';
import HeroExhibitionCard from 'components/cards/HeroExhibitionCard';
import HeroSeriesCard from 'components/cards/HeroSeriesCard';
import ROUTES from 'constants/Routes';
import CuratedArtworks from 'pages/homepage/CuratedArtworks';
import HomepageTextBlockSection from 'pages/homepage/HomepageTextBlockSection';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import AudioPlayerSection from './AudioPlayerSection';
import ImageGallerySection from './ImageGallerySection';
import TextWithCardBlockSection from './TextWithCardBlockSection';
import VideoBlockSection from './VideoBlockSection';

import * as styles from 'css/pages/modularPage/ModularPage.module.css';

type SectionType =
  ModularPagesSectionsQuery['response']['modularPageSections']['edges'][number]['node'];

const getEmbedUrl = (url: string): string => {
  if (url) {
    const videoId = url.split('v=')[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }
  return undefined;
};

const _renderSection = (section: SectionType, idx: number, password: string) =>
  section.sectionType === HomepageSectionTypeEnum.HeroArtist ? (
    <section key={`hero-artist-${section.artist.id}`} className={styles.large}>
      <HeroArtistCardSection artist={section.artist} />
    </section>
  ) : section.sectionType === HomepageSectionTypeEnum.HeroArtwork ? (
    <section
      key={`hero-artwork-${section.artwork.id}`}
      className={styles.large}
    >
      <HeroArtworkCard
        nft={section.artwork}
        nftMetadata={section.artwork.metadata}
        password={password}
      />
    </section>
  ) : section.sectionType === HomepageSectionTypeEnum.HeroCollection ? (
    <section
      key={`hero-collection-${section.collection.id}`}
      className={styles.large}
    >
      <HeroSeriesCard collection={section.collection} />
    </section>
  ) : section.sectionType === HomepageSectionTypeEnum.HeroEditorial ? (
    <section
      key={`hero-editorial-${(section.editorial as any).id}`}
      className={styles.large}
    >
      <HeroEditorialCard
        editorial={{
          ...section.editorial,
          viewUrl: ROUTES.MODULAR_PAGE(section.editorial.slug),
        }}
      />
    </section>
  ) : section.sectionType === HomepageSectionTypeEnum.HeroExhibition ? (
    <section
      key={`hero-exhibition-${section.exhibition.id}`}
      className={styles.large}
    >
      <HeroExhibitionCard exhibition={section.exhibition} />
    </section>
  ) : section.sectionType ===
    HomepageSectionTypeEnum.CuratedArtworksCarousel ? (
    <CuratedArtworks
      key="curated-artworks-carousel"
      artworkList={section.artworkList}
      className={styles.large}
      carouselContentClassName={styles.artworksCarouselContent}
      curatedNftsQuery={{
        variables: {
          artworkListId: parseInt(section.artworkList.pk, 10),
          password,
        },
      }}
    />
  ) : section.sectionType === HomepageSectionTypeEnum.TextBlock ? (
    <HomepageTextBlockSection
      key={`text-block-${section.textBlock.id}`}
      hideTopMargin={idx !== 0}
      textBlock={section.textBlock}
    />
  ) : section.sectionType === PageSectionTypeEnum.ImageGallery ? (
    <ImageGallerySection
      key={`image-gallery-${section.imageGallery.id}`}
      className={joinClasses({
        [styles.large]:
          section.imageGallery.variant === PageMediaVariantEnum.Hero,
      })}
      imageGallery={section.imageGallery}
    />
  ) : section.sectionType === PageSectionTypeEnum.TextWithCardBlock ? (
    <TextWithCardBlockSection
      key={`text-with-card-block-${section.textWithCardBlock.id}`}
      className={styles.medium}
      textWithCardBlock={section.textWithCardBlock}
    />
  ) : section.sectionType === PageSectionTypeEnum.AudioBlock ? (
    <AudioPlayerSection
      key={`audio-block-${section.audioBlock.id}`}
      audioBlock={section.audioBlock}
    />
  ) : section.sectionType === PageSectionTypeEnum.VideoBlock ? (
    <VideoBlockSection
      className={joinClasses({
        [styles.large]:
          section.videoBlock.variant === PageMediaVariantEnum.Hero,
      })}
      key={`video-block-${section.videoBlock.id}`}
      videoUrl={section.videoBlock.videoUrl}
      externalUrl={getEmbedUrl(section.videoBlock.externalUrl)}
    />
  ) : null;

interface SectionsProps {
  sectionsQuery: WithLoadQueryProps<ModularPagesSectionsQuery>;
}
export default withLoadQuery(
  ({ sectionsQuery: { queryRef } }: SectionsProps) => {
    const { password } = queryRef.variables;
    const { modularPageSections } =
      usePreloadedQuery<ModularPagesSectionsQuery>(
        ModularPagesSectionsQueryType,
        queryRef
      );
    const sections = useMemo(
      () => modularPageSections.edges.map(({ node: section }) => section),
      [modularPageSections]
    );

    const renderSection = useCallback(
      (section: SectionType, idx: number) =>
        _renderSection(section, idx, password),
      [password]
    );

    return <>{sections.map(renderSection)}</>;
  },
  {
    sectionsQuery: { concreteRequest: ModularPagesSectionsQueryType },
  },
  {
    grouppedLoadingKey: `page:sections`,
  }
);
