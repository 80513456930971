import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';

function toFixed(num: string, fixed: number) {
  // Unsure if there is actually a useless escape here
  /* eslint-disable-next-line no-useless-escape */
  const re = new RegExp(`^-?\\d+(?:\.\\d{0,${fixed || -1}})?`);
  return num.match(re)[0];
}

function useHandleMax(
  setState: Dispatch<SetStateAction<string>>,
  maxDecimals: number,
  maxValue: number
): [
  (val: string) => void,
  (event: ChangeEvent<HTMLInputElement>) => void,
  () => void
] {
  const set = useCallback(
    (val: string) => {
      const valWithPrecedingZero = val.charAt(0) === '.' ? `0${val}` : val;

      // Strip out any non decimal character
      const charStrippedVal = valWithPrecedingZero.replace(/[^0-9.]/g, '');
      if (!charStrippedVal) {
        setState('');
        return;
      }
      const clampedTxtVal = toFixed(charStrippedVal, maxDecimals);
      const clampedVal = parseFloat(clampedTxtVal);
      setState((prevVal) =>
        clampedVal > maxValue
          ? parseFloat(prevVal) > maxValue
            ? `${maxValue}`
            : prevVal
          : clampedTxtVal
      );
    },
    [setState, maxDecimals, maxValue]
  );

  const clampPrevVal = useCallback(() => {
    setState((prevVal) => {
      const clampedTxtVal = toFixed(prevVal || '0', maxDecimals);
      const clampedVal = parseFloat(clampedTxtVal);
      return clampedVal > maxValue ? `${maxValue}` : clampedTxtVal;
    });
  }, [setState, maxDecimals, maxValue]);

  const handle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => set(event.target.value),
    [set]
  );
  return [set, handle, clampPrevVal];
}

function useHandleSetETH(setState: Dispatch<SetStateAction<string>>) {
  return useHandleMax(setState, 18, 10000);
}

function useHandleSetUSD(setState: Dispatch<SetStateAction<string>>) {
  return useHandleMax(setState, 2, 9999999999);
}

export { useHandleSetETH, useHandleSetUSD };
