import { usePreloadedQuery } from 'react-relay';

import { MPChip } from '@mp-frontend/core-components';

import NFTsTagAndProvenanceQueryType, {
  NFTsTagAndProvenanceQuery,
} from 'graphql/__generated__/NFTsTagAndProvenanceQuery.graphql';

import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import ProductCollapsibleContainer from './ProductCollapsibleContainer';

import * as styles from 'css/pages/product/ProductTags.module.css';

interface ProductTagsProps {
  tagAndProvenanceQuery: WithLoadQueryProps<NFTsTagAndProvenanceQuery>;
}

function ProductTags({
  tagAndProvenanceQuery: { queryRef },
}: ProductTagsProps) {
  const { nfts } = usePreloadedQuery<NFTsTagAndProvenanceQuery>(
    NFTsTagAndProvenanceQueryType,
    queryRef
  );
  const nft = nfts.edges[0].node;
  const { tags } = nft.metadata;
  return (
    <>
      {tags.length > 0 && (
        <ProductCollapsibleContainer title="Tags">
          <div className={styles.productTags}>
            {tags.map((tag) => (
              <MPChip key={tag} label={tag} variant="outlined" />
            ))}
          </div>
        </ProductCollapsibleContainer>
      )}
    </>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(ProductTags, {
    tagAndProvenanceQuery: { concreteRequest: NFTsTagAndProvenanceQueryType },
  }),
  { hideState: true }
);
