/**
 * @generated SignedSource<<28619dbc2b986c955ac63074b639d98a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateSaleWithPersonalWalletArguments = {
  fromAddress: `0x${string}`;
  nonce: string;
  price: number;
  releaseId: number;
  transactionId: `0x${string}`;
};
export type ListingCreateSaleWithPersonalWalletMutation$variables = {
  request_data: CreateSaleWithPersonalWalletArguments;
};
export type ListingCreateSaleWithPersonalWalletMutation$data = {
  readonly createSaleWithPersonalWallet: {
    readonly success: boolean | null;
  } | null;
};
export type ListingCreateSaleWithPersonalWalletMutation = {
  variables: ListingCreateSaleWithPersonalWalletMutation$variables;
  response: ListingCreateSaleWithPersonalWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request_data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "request_data"
      }
    ],
    "concreteType": "CreateSaleWithPersonalWalletMutation",
    "kind": "LinkedField",
    "name": "createSaleWithPersonalWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingCreateSaleWithPersonalWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingCreateSaleWithPersonalWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "92ba0dafc2221a82d4a781bd67b0f215",
    "id": null,
    "metadata": {},
    "name": "ListingCreateSaleWithPersonalWalletMutation",
    "operationKind": "mutation",
    "text": "mutation ListingCreateSaleWithPersonalWalletMutation(\n  $request_data: CreateSaleWithPersonalWalletArguments!\n) {\n  createSaleWithPersonalWallet(requestData: $request_data) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1a64e539826f00b2db44611c6c4b68c";

export default node;
