import { useMemo } from 'react';
import { atom, useAtom } from 'jotai';
import { Hash } from 'viem';
import { Connector } from 'wagmi';

import { areSameAddress } from 'utils/areSameAddress';

const accountsByConnectorAtom = atom<Record<string, readonly Hash[]>>({});
const accountsByAddressAtom = atom<Record<Hash, Connector>>({});

const getAccounts = (function getAccountsScope() {
  const existingAsyncRequests = {};

  return async function _getAccounts(connector: Connector) {
    const { id } = connector;
    if (!existingAsyncRequests[connector.id]) {
      existingAsyncRequests[connector.id] = new Promise((resolve, reject) => {
        connector
          .getAccounts()
          .then(resolve)
          .catch(reject)
          .finally(() => delete existingAsyncRequests[id]);
      });
    }
    return existingAsyncRequests[connector.id];
  };
})();

/**
 * Cached list of non blocking read only wagmi accounts
 */
export default function useGetAccounts(connectors: readonly Connector[]) {
  const [connectorCache, setConnectorCache] = useAtom(accountsByConnectorAtom);
  const [addressCache, setAddressCache] = useAtom(accountsByAddressAtom);

  return useMemo(
    () => ({
      getConnectorByAddress: function getAccountByAddress(_address: Hash) {
        const address = _address?.toLowerCase();
        const connector: Connector = addressCache[address];
        if (connector) {
          const accounts = connectorCache[addressCache[address].id];
          if (accounts) {
            if (
              accounts.find((account) =>
                areSameAddress(account, address as Hash)
              )
            ) {
              return connector;
            }
            // Cache is invalid, delete
            delete addressCache[address];
            setAddressCache({ ...addressCache });
          }
        }
        connectors.forEach(async (newConnector) => {
          const accounts = await getAccounts(newConnector);
          accounts.forEach((account) => {
            addressCache[account.toLowerCase()] = newConnector;
          });
          setAddressCache(addressCache);
          connectorCache[newConnector.id] = accounts;
          setConnectorCache(connectorCache);
        });
        return connector;
      },
    }),
    [
      connectors,
      addressCache,
      connectorCache,
      setConnectorCache,
      setAddressCache,
    ]
  );
}
