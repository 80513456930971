import { Children, isValidElement, ReactNode } from 'react';

import { useIsMobile } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTType } from 'types/graphql/NFT';
import { getNFTAdditionalMedias, getNFTPrimaryMedia } from 'utils/nftUtils';

import ProductPreview from './productPreview';
import ProductRightRailContainer from './ProductRightRailContainer';
import ProductTopSection from './ProductTopSection';

import * as styles from 'css/pages/product/ProductPage.module.css';

interface ProductLayoutProps {
  children: ReactNode;
  nftMetadata: Pick<
    NFTType['metadata'],
    | 'id'
    | 'pk'
    | 'nftAdditionalMediasRanked'
    | 'hasVideo'
    | 'highResImage'
    | 'id'
    | 'thumbnailImage'
    | 'standardImage'
    | 'rawfileExtension'
    | 'videoUrl'
  >;
}

export default function ProductLayout({
  nftMetadata,
  children,
}: ProductLayoutProps) {
  const isMobile = useIsMobile();

  let topSectionChild = null;
  const otherChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === ProductTopSection) {
        topSectionChild = child;
      } else {
        return child;
      }
    }

    return null;
  });

  return (
    <div
      className={joinClasses(styles.productPageContainer, 'thresholdElement')}
    >
      {!!isMobile && topSectionChild}
      <div className={styles.productGalleryFlex}>
        <ProductPreview
          primaryMedia={getNFTPrimaryMedia(nftMetadata, isMobile)}
          additionalMedias={getNFTAdditionalMedias(nftMetadata)}
        />
      </div>
      <ProductRightRailContainer>
        {!isMobile && topSectionChild}
        {otherChildren}
      </ProductRightRailContainer>
    </div>
  );
}
