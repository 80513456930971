import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from 'react-relay';
import { useAccount } from 'wagmi';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';
import NFTCreateApprovalMutationType, {
  NFTCreateApprovalMutation,
} from 'graphql/__generated__/NFTCreateApprovalMutation.graphql';

import WalletActionButton from 'components/WalletActionButton';
import useApprovedCreatorRegistryContract from 'hooks/contracts/useApprovedCreatorRegistryContract';
import useLogging from 'hooks/useLogging';
import usePublicClient from 'hooks/useWeb3Client';
import { NFTType } from 'types/graphql/NFT';
import { HexString } from 'utils/jwt/walletUtils';

interface ProductApprovalOBOProps {
  approvalRegistryContract: NFTContractQuery$data['nftContract'];
  currentTransactionHash: string;
  invalidateNFTForSaleApproval: () => void;
  nft: NFTType;
  saleContract: Pick<
    NFTContractQuery$data['nftContract'],
    'abidata' | 'address'
  >;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
}

function ProductApprovalOBO({
  currentTransactionHash,
  saleContract,
  approvalRegistryContract,
  setCurrentTransactionHash,
  invalidateNFTForSaleApproval,
  nft,
}: ProductApprovalOBOProps) {
  const [
    isValidatingSaleContractApproval,
    setIsValidatingSaleContractApproval,
  ] = useState(false);

  const provider = usePublicClient();
  const { logNFTException } = useLogging();
  const { address: currentUserAddress } = useAccount();

  const [nftSaleContractApprovalMutation] =
    useMutation<NFTCreateApprovalMutation>(NFTCreateApprovalMutationType);

  const { useSetOboApprovalForAll } = useApprovedCreatorRegistryContract({
    abi: JSON.parse(approvalRegistryContract.abidata).abi,
    contractAddress: approvalRegistryContract.address as HexString,
  });

  const setOboApprovalForAll = useSetOboApprovalForAll({
    isApproved: true,
    operatorAddress: saleContract.address as HexString,
  });

  useEffect(() => {
    if (setOboApprovalForAll.mutate.isError) {
      setIsValidatingSaleContractApproval(false);
    }
  }, [setOboApprovalForAll.mutate.error, setOboApprovalForAll.mutate.isError]);

  const approveSaleContract = async () => {
    try {
      setIsValidatingSaleContractApproval(true);
      const transactionResult = await setOboApprovalForAll.mutate.writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      setIsValidatingSaleContractApproval(false);
      setCurrentTransactionHash(transactionResult);
      invalidateNFTForSaleApproval();
      nftSaleContractApprovalMutation({
        onCompleted() {
          setIsValidatingSaleContractApproval(false);
        },
        variables: {
          request_data: {
            approvalContractAddress: approvalRegistryContract.address,
            nonce,
            operatorContractAddress: saleContract.address,
            transactionId: transactionResult,
            userAddress: currentUserAddress,
          },
        },
      });
    } catch (error) {
      logNFTException(nft.pk, error);
      setIsValidatingSaleContractApproval(false);
      setOboApprovalForAll.mutate.reset();
    }
  };

  const isLoading =
    isValidatingSaleContractApproval ||
    setOboApprovalForAll.mutate.isPending ||
    setOboApprovalForAll.simulate.isFetching ||
    setOboApprovalForAll.simulate.isPending;

  return (
    <>
      {!currentTransactionHash && (
        <>
          <WalletActionButton
            fullWidth
            onClick={approveSaleContract}
            disabled={isLoading}
            warnIfAddressNotRegistered
            size="large"
          >
            Give Approval
          </WalletActionButton>
        </>
      )}
    </>
  );
}

export default ProductApprovalOBO;
