import { lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Reload from 'components/Reload';

import UploadNFTPage from './create/UploadNFTPage';

const NFTMetadataPage = lazy(() => import('./releases/NFTMetadataPage'));

const DigitalMediaRoute = (
  <Route path="digital-media" element={<Outlet />}>
    <Route path="create" element={<UploadNFTPage />} />
    <Route path="releases" element={<Outlet />}>
      <Route path=":metadataId" element={<NFTMetadataPage />} />
    </Route>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default DigitalMediaRoute;
