import { useCallback, useState } from 'react';
import { useMutation } from 'react-relay';

import LockListingMutation, {
  ListingLockListingMutation$data,
  ListingLockListingMutation$variables,
} from 'graphql/__generated__/ListingLockListingMutation.graphql';
import UnlockListingMutation, {
  ListingUnlockListingMutation$data,
  ListingUnlockListingMutation$variables,
} from 'graphql/__generated__/ListingUnlockListingMutation.graphql';

import getNFTReserveExpirationDate from 'utils/getNFTReserveExpirationDate';
import { isNFTAlreadyReservedFromErrorString } from 'utils/nftUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

export const useLockProduct = (
  productId: number,
  isPresale: boolean
): [boolean, Date, () => Promise<void>, () => Promise<void>] => {
  const [lockEndDate, setLockEndDate] = useState<Date>(new Date());
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const [commitLockListingMutation] = useMutation(LockListingMutation);
  const commitLockListingMutationAsync = promisifyMutationWithRequestData<
    ListingLockListingMutation$variables,
    ListingLockListingMutation$data
  >(commitLockListingMutation);

  const [commitUnlockListingMutation] = useMutation(UnlockListingMutation);
  const commitUnlockListingMutationAsync = promisifyMutationWithRequestData<
    ListingUnlockListingMutation$variables,
    ListingUnlockListingMutation$data
  >(commitUnlockListingMutation);

  const lock = useCallback(async () => {
    try {
      setIsLocked(false);
      await commitLockListingMutationAsync({ isPresale, productId });
      setLockEndDate(getNFTReserveExpirationDate());
    } catch (error) {
      if (isNFTAlreadyReservedFromErrorString(error?.toString())) {
        setIsLocked(true);
      } else {
        throw error;
      }
    }
  }, [isPresale, productId, commitLockListingMutationAsync]);

  const unlock = useCallback(async () => {
    await commitUnlockListingMutationAsync({ productId });
    setIsLocked(false);
  }, [productId, commitUnlockListingMutationAsync]);

  return [isLocked, lockEndDate, lock, unlock];
};

export default { useLockProduct };
