.container {
  --heroExhibitionCard-dividerWidth: 2px;
  --heroExhibitionCard-transitionDuration: 300ms;
}

.mediaContainer {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;

  @media (--mq-is-mobile) {
    flex-basis: initial;
  }
}

.mediaContainer,
.mediaContainer > .media,
.mediaContainer > .media > .artworkContainer,
.mediaContainer > .media > .artworkContainer > .artwork,
.mediaContainer > .media > .cover {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.mediaContainer > .media {
  position: relative;
  transform: scale(var(--heroExhibitionCard-coverScale)) translateY(calc(var(--pdp-image-preview-size) + var(--heroCard-padding)));
  transition: transform var(--heroExhibitionCard-transitionDuration) ease-out;

  @media (--mq-is-mobile) {
    height: 498px;
    transform: none;

    > .cover {
      object-fit: cover;
      object-position: center;
    }
  }
}

.mediaContainer > .media > .artworkContainer {
  height: calc(100% - (var(--pdp-image-preview-size) + var(--heroCard-padding)));
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.mediaContainer > .media > .artworkContainer > .artwork {
  object-fit: contain;
  object-position: center;
  opacity: 0;
  position: absolute;
  top: 0;
  transition:
    filter var(--heroExhibitionCard-transitionDuration) ease-out,
    opacity var(--heroExhibitionCard-transitionDuration) ease,
    visibility 0ms var(--heroExhibitionCard-transitionDuration);
  visibility: hidden;
  z-index: -1;

  &.active {
    opacity: 1;
    transition:
      opacity var(--heroExhibitionCard-transitionDuration) ease,
      visibility 0s;
    visibility: visible;
    z-index: 1;
  }
}

.mediaContainer > .media .pagination {
  transform: translateY(600%);
  transition: transform var(--heroExhibitionCard-transitionDuration) ease;

  @media (--mq-is-mobile) {
    display: none;
    transform: none;
  }
}

@media (--mq-is-not-mobile) {
  .mediaContainer.hovered {
    background-image: none;
    padding: var(--heroCard-padding) var(--heroExhibitionCard-dividerWidth) var(--heroCard-padding) 0px;

    &::after {
      background-color: var(--color-backgroundDefault);
      bottom: var(--heroCard-padding);
      content: ' ';
      position: absolute;
      right: 0;
      top: var(--heroCard-padding);
      width: var(--heroExhibitionCard-dividerWidth);
      z-index: 1;
    }

    > .media {
      opacity: 1;
      padding: 0 calc(2 * var(--heroCard-padding));
      transform: scale(1) translateY(0);

      > .artworkContainer {
        filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 4px 16px);
      }

      .pagination {
        transform: translateY(0);
      }
    }
  }
}

.rail {
  box-sizing: border-box;
  padding: 0 var(--heroCard-padding) 0 0;
  width: calc(350px + var(--heroCard-padding));

  @media (--mq-is-mobile) {
    padding: 0 20px var(--heroCard-padding);
    width: 100%;
  }
}
