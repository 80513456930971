import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  PreloadedQuery,
  useMutation,
  usePaginationFragment,
  usePreloadedQuery,
} from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NotificationsFragment, {
  NotificationsFragment$key,
} from 'graphql/__generated__/NotificationsFragment.graphql';
import NotificationsQuery, {
  NotificationsQuery as NotificationsQueryType,
} from 'graphql/__generated__/NotificationsQuery.graphql';
import NotificationsReadMutation, {
  NotificationsReadMutation as NotificationsReadMutationParameters,
  NotificationsReadMutation$data,
  SetReadNotificationsArguments,
} from 'graphql/__generated__/NotificationsReadMutation.graphql';

import { DefaultLoader } from 'components/DefaultSuspense';
import useInfiniteQueryScroll from 'hooks/useInfiniteQueryScroll';

import Notification from './Notification';

import * as styles from 'css/components/Navbar/Unified/Notifications/Notifications.module.css';

interface NotificationsListProps {
  notificationsQueryRef: PreloadedQuery<
    NotificationsQueryType,
    Record<string, unknown>
  >;
  className?: string;
  invalidateNotificationCount?: () => void;
  showNotifications?: boolean;
}

export default function NotificationsList({
  notificationsQueryRef,
  className,
  showNotifications = null,
  invalidateNotificationCount = null,
}: NotificationsListProps) {
  const visibilityRef = useRef(null);
  const scrollRef = useRef(null);
  const {
    data: notificationsData,
    hasMore: notificationsHasMore,
    loading: notificationsLoading,
  } = useInfiniteQueryScroll({
    pageSize: 10,
    paginatedQueryResults: usePaginationFragment<
      NotificationsQueryType,
      NotificationsFragment$key
    >(
      NotificationsFragment,
      usePreloadedQuery<NotificationsQueryType>(
        NotificationsQuery,
        notificationsQueryRef
      )
    ),
    ref: visibilityRef,
    scrollRef,
  });

  const [
    commitNotificationsReadMutation,
    isCommitNotificationsReadMutationInFlight,
  ] = useMutation(NotificationsReadMutation);

  /** An invisible input whose onClick handler fires the Apollo equivalent of invalidateNotificationCount
   *  TODO: Remove this once django pages are migrated
   * */
  const remoteInvalidationTarget = window.parent.document.getElementById(
    'django-noti-count-invalidation-button'
  );

  const commitNotificationsReadMutationAsyncCallback = useCallback(
    async (
      requestData: SetReadNotificationsArguments = {}
    ): Promise<NotificationsReadMutation$data> =>
      new Promise((_, onError) => {
        commitNotificationsReadMutation({
          onCompleted: (
            response: NotificationsReadMutationParameters['response']
          ) => {
            if (response.setNotificationsAsRead.success) {
              startTransition(() => {
                if (invalidateNotificationCount) {
                  invalidateNotificationCount();
                }
                // TODO: Remove this once django pages are migrated
                remoteInvalidationTarget?.click();
              });
            }
          },
          onError,
          optimisticResponse: {
            setNotificationsAsRead: {
              success: true,
            },
          },
          variables: {
            request_data: requestData,
          },
        });
      }),
    [
      remoteInvalidationTarget,
      invalidateNotificationCount,
      commitNotificationsReadMutation,
    ]
  );

  const callCommitNotificationsReadMutationAsyncCallback = useCallback(
    () =>
      commitNotificationsReadMutationAsyncCallback({
        ids: notificationsData?.map((x) => parseInt(x.pk, 10)),
      }),
    [notificationsData, commitNotificationsReadMutationAsyncCallback]
  );

  const dataLength = useMemo(
    () => notificationsData?.length,
    [notificationsData]
  );
  // When drawer is opened on a SPA page, Fire read mutations to mark notifications as read.
  useEffect(() => {
    /** Note:
     * Atomic read mutation on bell click is not practical. First payload (preloaded for iframe performance reasons)
     * always comes down unread (assuming user has >20 unread). When they click the bell to open the drawer,
     * we simultaneously nuke all notis to read state. The first 20 is stored in cache, the remaining pages are
     * loaded straight from the DB with up to date data, therefore, always **READ**.
     *
     * Solution:
     * We mark notifications read as they are paged into existence. For now, no deduping,
     * we send the entire list of IDs to the backend and let the server handle it.
     *
     * Note: This will handle initial & subsequent pages for mp-frontend views.
     *       For mp-django, the initial load is handled by the `MutationObserver` above.
     */
    if (
      !notificationsLoading &&
      showNotifications === true &&
      !isCommitNotificationsReadMutationInFlight &&
      dataLength > 0
    ) {
      callCommitNotificationsReadMutationAsyncCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLength]);

  return (
    <div
      className={joinClasses(styles.notificationsList, className)}
      ref={scrollRef}
    >
      {notificationsData?.length === 0 && !notificationsHasMore && (
        <div
          className={joinClasses(
            styles.defaultNotification,
            MPFonts.notificationRegularText
          )}
        >
          You haven&#39;t received any notifications.
        </div>
      )}
      {notificationsData?.length >= 0 && (
        <>
          {notificationsData.map((node) => (
            <Notification key={node.id} notification={node} />
          ))}
        </>
      )}
      <div ref={visibilityRef}>&nbsp;</div>
      {!!notificationsLoading && (
        <DefaultLoader loaderClassName={styles.notificationLoader} />
      )}
    </div>
  );
}
