import {
  MPChip,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import {
  ActivityActionEnum,
  DateTimeRangeType,
} from 'types/__generated__/graphql';

import ActionsFilter, {
  ACTION_TITLES,
} from 'pages/activity/filters/ActionsFilter';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';

import ArtistsFilter from './filters/ArtistsFilter';
import DateFilter, {
  DEFAULT_DATE,
  formatDateToChipString,
  formatDateToQueryString,
} from './filters/DateFilter';
import PriceFilter, { PriceFilterValue } from './filters/PriceFilter';

import * as styles from 'css/pages/activity/ActivityFilters.module.css';

export interface FiltersState {
  actions: ActivityActionEnum[];
  activityDate: DateTimeRangeType;
  isShowFollowing: boolean;
  price: PriceFilterValue;
  userNames: string[];
}

export type FiltersStateChangeEvent = Partial<FiltersState>;

interface ActivityPageFiltersProps {
  filters: FiltersState;
  onChange: (filters: FiltersStateChangeEvent) => void;
}

export default function ActivityPageFilters({
  filters,
  onChange,
}: ActivityPageFiltersProps) {
  const isMobile = useIsMobile();

  return (
    <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[10])}>
      <div
        className={joinClasses(
          {
            [CSSGlobal.Flex.CenteredRow]: !isMobile,
            [CSSGlobal.Flex.Row]: isMobile,
          },
          CSSGlobal.NoScrollbar,
          styles.filters
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.RowCenterAlign, CSSGap[8])}>
          <span
            className={joinClasses(
              CSSGlobal.Cursor.Default,
              MPFonts.textSmallMedium,
              MPColorClass.SolidNeutralGray5
            )}
          >
            Filter by
          </span>

          <ActionsFilter
            values={filters.actions}
            onChange={(actions) => onChange({ actions })}
          />
          <PriceFilter
            value={filters.price}
            onChange={(price) => onChange({ price })}
          />
          <DateFilter
            value={{
              from: filters.activityDate.min,
              to: filters.activityDate.max,
            }}
            onChange={(activityDate) =>
              onChange({
                activityDate: {
                  max: activityDate.to,
                  min: activityDate.from,
                },
              })
            }
          />
          <ArtistsFilter
            value={{
              isShowFollowing: filters.isShowFollowing,
              userNames: filters.userNames,
            }}
            onChange={(value) => onChange(value)}
          />
        </div>
      </div>

      <div
        className={joinClasses(
          {
            [CSSGlobal.Flex.CenteredRow]: !isMobile,
            [CSSGlobal.Flex.Row]: isMobile,
          },
          CSSGlobal.NoScrollbar,
          styles.filters
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.RowCenterAlign, CSSGap[8])}>
          {!!filters.actions.length &&
            filters.actions.map((action) => (
              <MPChip
                key={action}
                label={ACTION_TITLES[action]}
                onDelete={() =>
                  onChange({
                    actions: filters.actions.filter(
                      (actionToDelete) => actionToDelete !== action
                    ),
                  })
                }
              />
            ))}

          {!!(filters.price.min || filters.price.max) &&
            (!filters.price.min ? (
              <MPChip
                label={`Max ${generatePriceString(
                  filters.price.max,
                  CurrencyDisplayMode.USD
                )}`}
                onDelete={() =>
                  onChange({
                    price: {},
                  })
                }
              />
            ) : !filters.price.max ? (
              <MPChip
                label={`Min ${generatePriceString(
                  filters.price.min,
                  CurrencyDisplayMode.USD
                )}`}
                onDelete={() => onChange({ price: {} })}
              />
            ) : (
              <MPChip
                label={`Min ${generatePriceString(
                  filters.price.min,
                  CurrencyDisplayMode.USD
                )} - Max ${generatePriceString(
                  filters.price.max,
                  CurrencyDisplayMode.USD
                )}`}
                onDelete={() => onChange({ price: {} })}
              />
            ))}

          {(!filters.activityDate.min && !filters.activityDate.max) ||
          (filters.activityDate.min ===
            formatDateToQueryString(DEFAULT_DATE.FROM) &&
            filters.activityDate.max ===
              formatDateToQueryString(DEFAULT_DATE.TO)) ? null : filters
              .activityDate.max === formatDateToQueryString(DEFAULT_DATE.TO) ? (
            <MPChip
              label={`From ${formatDateToChipString(
                filters.activityDate.min
              )} to now`}
              onDelete={() =>
                onChange({
                  activityDate: {
                    max: '',
                    min: '',
                  },
                })
              }
            />
          ) : (
            <MPChip
              label={`From ${formatDateToChipString(
                filters.activityDate.min
              )} to ${formatDateToChipString(filters.activityDate.max)}`}
              onDelete={() =>
                onChange({
                  activityDate: {
                    max: '',
                    min: '',
                  },
                })
              }
            />
          )}
          {!!filters.isShowFollowing && (
            <MPChip
              key="artist-you-follow-chip"
              label="Artists You Follow"
              onDelete={() => onChange({ isShowFollowing: undefined })}
            />
          )}
          {!!filters.userNames.length &&
            filters.userNames.map((username) => (
              <MPChip
                key={username}
                label={username}
                onDelete={() =>
                  onChange({
                    userNames: filters.userNames.filter(
                      (userNameToDelete) => userNameToDelete !== username
                    ),
                  })
                }
              />
            ))}
        </div>
      </div>
    </div>
  );
}
