import { SvgIcon } from '@mui/material';

export default function MetadataIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect x="5" y="7" width="14" height="3" fill="currentColor" />
      <rect x="5" y="14" width="14" height="3" fill="currentColor" />
    </SvgIcon>
  );
}
