.card {
  position: relative;
  width: calc(100% - 16px);

  @media (--mq-is-mobile) {
    &.last {
      width: 100%;
    }
  }
}
