import { Dispatch, SetStateAction, useState } from 'react';
import { merge } from 'lodash';
import { SxProps, Theme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, {
  StandardTextFieldProps,
  TextFieldProps,
} from '@mui/material/TextField';

import { joinClasses } from '@mp-frontend/core-utils';

import { MPColorClass, MPFonts } from '../..';

import * as styles from '../css/text_field/MPTextField.module.css';

type AllowedInputProps = Partial<
  Pick<TextFieldProps['inputProps'], 'startAdornment' | 'endAdornment'>
>;

export interface MPTextFieldProps extends StandardTextFieldProps {
  /**
   *
   */
  endAdornment?: AllowedInputProps['endAdornment'];

  /**
   * replacement for input ClassName
   */
  inputClassName?: string;

  /**
   * Setter for the State Value, if this is not Set, the Text Field will use local State for Setting Value.
   */
  setValue?: Dispatch<SetStateAction<string>>;

  /**
   * shows placeholder while focusing, but not if there is content. default=false
   */
  showFocusedPlaceholder?: boolean;

  /**
   *
   */
  startAdornment?: AllowedInputProps['startAdornment'];

  /**
   * Overrides MUI CSS stylings.
   */
  sx?: SxProps<Theme>;

  /**
   * State Value for the Text Field input.
   */
  value?: string;
}

export default function MPTextField({
  value,
  setValue,
  onChange,
  startAdornment,
  inputClassName,
  inputMode,
  className,
  sx = {},
  showFocusedPlaceholder = false,
  error = false,
  endAdornment = undefined,
  ...passedProps
}: MPTextFieldProps) {
  const defaultState = useState('');

  const mergedSx = merge(
    showFocusedPlaceholder
      ? {
          '& .MuiInputBase-input:focus::placeholder': {
            color: 'black !important',
          },
        }
      : {},
    sx
  );

  const [val, setVal] = setValue ? [value, setValue] : defaultState;

  const InputProps: TextFieldProps['InputProps'] = {
    classes: {
      root: joinClasses(
        MPFonts.inputText,
        (onChange ? value : val) ? '' : `MuiInputBase-empty`
      ),
    },
    endAdornment,
    inputMode,
  };
  if (startAdornment)
    InputProps.startAdornment = (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    );

  // Who thought it was a good idea to have two props differ only in case?
  /* eslint-disable react/jsx-no-duplicate-props */
  return (
    <TextField
      value={onChange ? value : val}
      className={joinClasses(styles.MPTextField, className)}
      onChange={onChange || ((e) => setVal(e?.target.value))}
      InputLabelProps={{
        className: `${MPFonts.textSmallRegular} ${MPColorClass.SolidNeutralGray4}`,
        shrink: false,
      }}
      InputProps={InputProps}
      inputProps={{ className: inputClassName || MPFonts.inputText }}
      error={error}
      sx={mergedSx}
      {...passedProps}
    />
  );
}
