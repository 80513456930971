import { useCallback } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { LinkIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import copyToClipboard from 'utils/copyToClipboard';

import { BaseShareButtonProps } from './BaseShareButton';

import * as styles from 'css/components/buttons/ShareButton.module.css';

const variantClassNames = {
  default: styles.defaultVariant,
  premium: styles.premiumVariant,
} as const;

export default function CopyLinkButton({
  link,
  variant = 'default',
}: BaseShareButtonProps) {
  const handleCopyLinkClick = useCallback(() => copyToClipboard(link), [link]);

  return (
    <button
      type="button"
      onClick={handleCopyLinkClick}
      className={styles.container}
    >
      <div
        className={joinClasses(
          styles.icon,
          variantClassNames[variant] as string
        )}
      >
        <LinkIcon sx={{ fontSize: '19px' }} />
      </div>

      <span className={joinClasses(MPFonts.textSmallMedium, styles.title)}>
        Copy Link
      </span>
    </button>
  );
}
