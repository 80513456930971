import { startTransition, useEffect } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { LoaderIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTTransactionPollingQueryType, {
  NFTTransactionPollingQuery,
} from 'graphql/__generated__/NFTTransactionPollingQuery.graphql';
import { EthereumTransactionStateEnum } from 'types/__generated__/graphql';

import useLogging from 'hooks/useLogging';
import usePollTransactionUntilMined from 'hooks/usePollTransactionUntilMined';
import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { PurchasableNFTType } from 'types/graphql/NFT';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import {
  WithLoadQueryPropsV1,
  withLoadQueryV1,
} from 'utils/hocs/withLoadQuery';
import { getTransactionUrl } from 'utils/nftUtils';

import * as modalStyles from 'css/pages/product/ProductSetBuyNow.module.css';

export function PendingOnChainDisclaimer({
  transactionId,
}: {
  transactionId: string;
}) {
  const session = useSession();
  return (
    <div
      className={joinClasses(MPFonts.paragraphNormal, CSSGlobal.Cursor.Default)}
    >
      We are now waiting for the transaction to confirm and will update here
      shortly. Transaction details can be seen on&nbsp;
      <a
        className="anchorV2"
        href={getTransactionUrl(session.contractNetworkUrl, transactionId)}
        target="_blank"
        rel="noreferrer"
      >
        Etherscan
      </a>
      .
    </div>
  );
}

interface ProductPendingOnChainProps
  extends WithLoadQueryPropsV1<NFTTransactionPollingQuery> {
  nft?: PurchasableNFTType;
  onSuccess?: () => void;
}

function ProductPendingOnChain({
  queryRef,
  invalidate,
  onSuccess,
  nft,
}: ProductPendingOnChainProps) {
  const transaction = usePollTransactionUntilMined({
    invalidate,
    queryRef,
  });

  const { txtId } = queryRef.variables;

  const { logTransactionException } = useLogging();

  const transactionState = transaction?.transactionState;

  useEffect(() => {
    startTransition(() => {
      if (transactionState === EthereumTransactionStateEnum.Mined) {
        onSuccess?.();
      }

      if (transactionState === EthereumTransactionStateEnum.Failed) {
        const hash = transaction?.txid;
        const message = nft
          ? `Transaction failed for NFT ID: ${nft.pk} with txid: ${hash}`
          : `Transaction failed with txid: ${hash} and no NFT`;
        logTransactionException(hash, message);
      }
    });
  }, [
    transactionState,
    onSuccess,
    logTransactionException,
    nft,
    transaction?.txid,
  ]);

  return (
    <div>
      {!!txtId && (
        <div className={modalStyles.productSellContainer}>
          <div
            className={joinClasses(
              CSSGlobal.Flex.CenteredCol,
              CSSGap[16],
              modalStyles.productSellMessageContainer
            )}
          >
            <LoaderIcon fontSize="68" />
            <PendingOnChainDisclaimer transactionId={txtId} />
          </div>
        </div>
      )}
    </div>
  );
}

export default withDefaultErrorBoundary(
  withLoadQueryV1<NFTTransactionPollingQuery, ProductPendingOnChainProps>(
    ProductPendingOnChain,
    NFTTransactionPollingQueryType
  ),
  { hideState: true }
);
