.errorMessage {
  color: var(--color-errorMain);
  margin-top: -1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.errorMessageWithPadding {
  color: var(--color-errorMain);
  padding: 4px 0;
  text-align: center;
  margin: 0px;
}