import { SvgIcon } from '@mui/material';

export default function FacebookIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M21 12.055C21 7.05406 16.9706 3 12 3C7.02943 3 3 7.05406 3 12.055C3 16.5745 6.29115 20.3207 10.5938 21V14.6725H8.30859V12.055H10.5938V10.0601C10.5938 7.79066 11.9374 6.53711 13.9932 6.53711C14.9779 6.53711 16.0078 6.71397 16.0078 6.71397V8.94235H14.873C13.755 8.94235 13.4062 9.64039 13.4062 10.3565V12.055H15.9023L15.5033 14.6725H13.4062V21C17.7088 20.3207 21 16.5747 21 12.055Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
