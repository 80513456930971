const EVENT_PREFIX = {
  MP: 'mp::',
  SENTRY: 'sentry::',
};

export const STATSIG_EVENT = {
  MP: {
    EXPLORE: {
      OPEN: `${EVENT_PREFIX.MP}explore:open`,
    },
    GENERATIVE: {
      BEGIN_CHECKOUT: `${EVENT_PREFIX.MP}begin_checkout:generative`,
      PURCHASE_ERROR: `${EVENT_PREFIX.MP}purchase_error:generative`,
      PURCHASE_SUCCESS: `${EVENT_PREFIX.MP}purchase_success:generative`,
    },
    OPEN_EDITION: {
      BEGIN_CHECKOUT: `${EVENT_PREFIX.MP}begin_checkout:open_edition`,
      PURCHASE_ERROR: `${EVENT_PREFIX.MP}purchase_error:open_edition`,
      PURCHASE_SUCCESS: `${EVENT_PREFIX.MP}purchase_success:open_edition`,
    },
    PRIVATE_SALE: {
      ENTER_PASSWORD: `${EVENT_PREFIX.MP}private_sale:enter_password`,
    },
    PRODUCT: {
      BEGIN_CHECKOUT: `${EVENT_PREFIX.MP}begin_checkout:product`,
      PURCHASE_ERROR: `${EVENT_PREFIX.MP}purchase_error:product`,
      PURCHASE_SUCCESS: `${EVENT_PREFIX.MP}purchase_success:product`,
    },
  },
  SENTRY: {
    APP_ERROR: `${EVENT_PREFIX.SENTRY}app_error`,
  },
};

export default { STATSIG_EVENT };
