.field {
  width: 100%;
}

.field .input {
  color: var(--color-commonBlack);

  &::placeholder {
    color: inherit;
  }
}
