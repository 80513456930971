/* eslint-disable import/prefer-default-export */

import { HomepageEditorialFragment$data } from 'graphql/__generated__/HomepageEditorialFragment.graphql';

import { FullArtistProps } from 'components/accounts/FullArtist';

export const MISSION_EDITORIAL: Omit<
  HomepageEditorialFragment$data,
  'order' | ' $fragmentType'
> = {
  backgroundImageUrl:
    'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-editorial-1.png',
  description:
    'Artist OneFallArt has been in the web3 art scene since he was first invited to MakersPlace in 2018. Armed with a Master’s degree in psychology and a concomitant obsession with peering into and embodying emotion and mental disorder in his work.',
  id: '1',
  pk: '1',
  title:
    'Creating a long lasting impact in the intersection of art and technology',
  type: 'Mission',
  viewUrl: 'https://google.com',
};

export const CONCIERGE_EDITORIAL: Omit<
  HomepageEditorialFragment$data,
  'order' | ' $fragmentType'
> = {
  backgroundImageUrl:
    'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-editorial-2.png',
  description:
    'Artist OneFallArt has been in the web3 art scene since he was first invited to MakersPlace in 2018. Armed with a Master’s degree in psychology and a concomitant obsession with peering into and embodying emotion and mental disorder in his work.',
  id: '2',
  pk: '2',
  title: 'White Glove services delivered directly to a wall near you',
  type: 'Mission',
  viewUrl: 'https://google.com',
};

export const CURATORS: ReadonlyArray<
  FullArtistProps['artist'] & {
    title?: string;
  }
> = [
  {
    biography:
      'Former CEO of Fandom and Gracenote, Craig Palmer has 30 years of broad industry experience spanning B2B and B2C markets and a successful track record of almost $10B in successful exits.',
    followerCount: 0,
    fullName: 'Jessica',
    id: '1',
    pk: '1',
    profileImageUrl: '',
    title: 'MakersPlace Curator',
    username: '',
  },
  {
    biography:
      'Former CEO of Fandom and Gracenote, Craig Palmer has 30 years of broad industry experience spanning B2B and B2C markets and a successful track record of almost $10B in successful exits.',
    followerCount: 0,
    fullName: 'Caitlin C',
    id: '2',
    pk: '2',
    profileImageUrl: '',
    title: 'MakersPlace Curator',
    username: '',
  },
  {
    biography:
      'Former CEO of Fandom and Gracenote, Craig Palmer has 30 years of broad industry experience spanning B2B and B2C markets and a successful track record of almost $10B in successful exits.',
    followerCount: 0,
    // eslint-disable-next-line @cspell/spellchecker
    fullName: 'Eleonora',
    id: '3',
    pk: '3',
    profileImageUrl: '',
    title: 'MakersPlace Curator',
    username: '',
  },
  {
    biography:
      'Former CEO of Fandom and Gracenote, Craig Palmer has 30 years of broad industry experience spanning B2B and B2C markets and a successful track record of almost $10B in successful exits.',
    followerCount: 0,
    fullName: 'Hernan',
    id: '4',
    pk: '4',
    profileImageUrl: '',
    title: 'MakersPlace Curator',
    username: '',
  },
  {
    biography:
      'Former CEO of Fandom and Gracenote, Craig Palmer has 30 years of broad industry experience spanning B2B and B2C markets and a successful track record of almost $10B in successful exits.',
    followerCount: 0,
    fullName: 'Aisha',
    id: '5',
    pk: '5',
    profileImageUrl: '',
    title: 'MakersPlace Curator',
    username: '',
  },
  {
    biography:
      'Former CEO of Fandom and Gracenote, Craig Palmer has 30 years of broad industry experience spanning B2B and B2C markets and a successful track record of almost $10B in successful exits.',
    followerCount: 0,
    // eslint-disable-next-line @cspell/spellchecker
    fullName: 'Jarid',
    id: '6',
    pk: '6',
    profileImageUrl: '',
    title: 'MakersPlace Curator',
    username: '',
  },
];

export const EXECUTIVE_TEAM: ReadonlyArray<
  FullArtistProps['artist'] & {
    title?: string;
  }
> = [
  {
    biography:
      'Former CEO of Fandom and Gracenote, Craig Palmer has 30 years of broad industry experience spanning B2B and B2C markets and a successful track record of almost $10B in successful exits.',
    followerCount: 0,
    fullName: 'Craig Palmer',
    id: '1',
    pk: '1',
    profileImageUrl: '',
    title: 'CEO',
    username: '',
  },
  {
    biography:
      'Previously, Claus led Global Growth and BD at Flipboard, a pioneering social magazine, growing the platform to 100MM+ users. Before that, Claus led growth at Texture, an unlimited magazines app that was acquired by Apple.',
    followerCount: 0,
    // eslint-disable-next-line @cspell/spellchecker
    fullName: 'Claus Enevoldsen',
    id: '2',
    pk: '2',
    profileImageUrl: '',
    title: 'Head of Growth & Marketing',
    username: '',
  },
  {
    biography:
      'Michael is a true engineering maestro. His journey from the grappling mat to the world of software engineering spans a rich spectrum of startups to the tech giant Google.',
    followerCount: 0,
    fullName: 'Michael Skinner',
    id: '3',
    pk: '3',
    profileImageUrl: '',
    title: 'Head of Engineering',
    username: '',
  },
  {
    biography:
      'Claudio has vast leadership experience across Finance, Operations, Strategy and Analytics. Most recently he ran Sales Finance at Elastic, and before that he held senior positions at Dropbox and Salesforce.',
    followerCount: 0,
    fullName: 'Claudio Lee',
    id: '4',
    pk: '4',
    profileImageUrl: '',
    title: 'Head of Finances',
    username: '',
  },
  {
    biography:
      "Andre brings a rich design heritage from his time at Apple, where he was the creative lead on projects like Apple's first AR app, the Apple Home App, and multiple Apple product launches, such as Apple Vision Pro.",
    followerCount: 0,
    fullName: 'Andre Camara',
    id: '5',
    pk: '5',
    profileImageUrl: '',
    title: 'Head of Design',
    username: '',
  },
  {
    biography:
      'Michael is a true engineering maestro. His journey from the grappling mat to the world of software engineering spans a rich spectrum of startups to the tech giant Google.',
    followerCount: 0,
    // eslint-disable-next-line @cspell/spellchecker
    fullName: 'Bruno Orsini',
    id: '6',
    pk: '6',
    profileImageUrl: '',
    title: 'Head of Product',
    username: '',
  },
];

export const INVESTORS: ReadonlyArray<{
  imageUrl: string;
  name: string;
  viewUrl: string;
}> = [
  {
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-investors-bessemer-2.png',
    name: 'Bessemer Venture Partners',
    viewUrl: 'https://www.bvp.com',
  },
  {
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-investors-pantera-2.png',
    name: 'Pantera Capital',
    viewUrl: 'https://panteracapital.com',
  },
  {
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-investors-uncork-2.png',
    name: 'Uncork Capital',
    viewUrl: 'https://uncorkcapital.com',
  },
  {
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-investors-coinbase-2.png',
    name: 'Coinbase Ventures',
    viewUrl: 'https://ventures.coinbase.com',
  },
  {
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-investors-sony-music-2.png',
    name: 'Sony Music',
    viewUrl: 'https://www.sonymusic.com',
  },
  {
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-investors-draper-dragon-2.png',
    name: 'Draper Dragon',
    viewUrl: 'http://draperdragon.com',
  },
];

export const PRESS: ReadonlyArray<{
  description: string;
  imageUrl: string;
  name: string;
  viewUrl: string;
}> = [
  {
    description:
      'Eminem, Sony Music Invest in NFT Marketplace Startup MakersPlace as Part of $30 Million Funding Round',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-press-variety-1.png',
    name: 'Variety',
    viewUrl: 'https://variety.com',
  },
  {
    description:
      'Eminem, Sony Music Invest in NFT Marketplace Startup MakersPlace as Part of $30 Million Funding Round',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-press-variety-1.png',
    name: 'Yahoo!',
    viewUrl: 'https://variety.com',
  },
  {
    description:
      'Eminem, Sony Music Invest in NFT Marketplace Startup MakersPlace as Part of $30 Million Funding Round',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-press-variety-1.png',
    name: 'TechCrunch',
    viewUrl: 'https://variety.com',
  },
  {
    description:
      'Eminem, Sony Music Invest in NFT Marketplace Startup MakersPlace as Part of $30 Million Funding Round',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-press-variety-1.png',
    name: 'Coin Telegraph',
    viewUrl: 'https://variety.com',
  },
  {
    description:
      'Eminem, Sony Music Invest in NFT Marketplace Startup MakersPlace as Part of $30 Million Funding Round',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-press-variety-1.png',
    name: 'ARTnet',
    viewUrl: 'https://variety.com',
  },
  {
    description:
      'Eminem, Sony Music Invest in NFT Marketplace Startup MakersPlace as Part of $30 Million Funding Round',
    imageUrl:
      'https://media.mkpcdn.com/prod/otherstuff/about-page/about-page-press-variety-1.png',
    name: 'HYPEBEAST',
    viewUrl: 'https://variety.com',
  },
];
