.intro {
  text-align: center;
}

.guideline {
  margin-top: 16px;
}

.pricingGuideImg {
  display: block;
  margin: auto;
  max-width: 600px;
}
