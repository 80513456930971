import { useCallback } from 'react';

import { MPActionButton, MPChip } from '@mp-frontend/core-components';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import CSSGlobal from 'types/enums/css/Global';

import useFiltersApplied from '../hooks/useFiltersApplied';
import { EditorialsFiltersState } from './Filters';

interface ChipsProps {
  filters: Partial<EditorialsFiltersState>;
  onChange: (state: Partial<EditorialsFiltersState>) => void;
  onReset: () => void;
}

export function ChipsList({ filters, onChange }: Omit<ChipsProps, 'onReset'>) {
  return (
    <>
      {!!filters.query && (
        <MPChip
          label={`Results for: "${filters.query}"`}
          onDelete={() => onChange({ query: '' })}
        />
      )}
    </>
  );
}

export default function Chips({ filters, onChange, onReset }: ChipsProps) {
  const areFiltersApplied = useFiltersApplied(filters);
  const handleReset = useCallback(() => onReset(), [onReset]);

  return areFiltersApplied ? (
    <FilterScrollContainer>
      <FiltersGroup>
        <ChipsList filters={filters} onChange={onChange} />
        <MPActionButton
          className={CSSGlobal.Width.maxContent}
          variant="tertiary"
          onClick={handleReset}
        >
          Clear All
        </MPActionButton>
      </FiltersGroup>
    </FilterScrollContainer>
  ) : null;
}
