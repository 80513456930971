import { ForwardedRef, forwardRef, ReactNode } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from '../../css/pages/product/ProductDetails.module.css';

interface ProductRightRailContainerProps {
  children?: ReactNode;
  className?: string;
}

const ProductRightRailContainer = forwardRef(
  (
    { children, className }: ProductRightRailContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      className={joinClasses(styles.productRightRailContainer, className)}
    >
      {children}
    </div>
  )
);

export default ProductRightRailContainer;
