/**
 * @generated SignedSource<<c3d0028aac70dfd14f6476ec815449cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationsCountQuery$variables = {};
export type NotificationsCountQuery$data = {
  readonly notificationsCount: number | null;
};
export type NotificationsCountQuery = {
  variables: NotificationsCountQuery$variables;
  response: NotificationsCountQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "notificationsCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsCountQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsCountQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b79fc9dc081d92dc1f202a553638ba09",
    "id": null,
    "metadata": {},
    "name": "NotificationsCountQuery",
    "operationKind": "query",
    "text": "query NotificationsCountQuery {\n  notificationsCount\n}\n"
  }
};
})();

(node as any).hash = "7be06a7617946fc3607a7f92b093f26e";

export default node;
