/**
 * @generated SignedSource<<c7a59ff2bd279e2d8fd2c7942675d646>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FollowUnfollowUserArguments = {
  userId: number;
};
export type AccountUnfollowUserMutation$variables = {
  request_data: FollowUnfollowUserArguments;
};
export type AccountUnfollowUserMutation$data = {
  readonly unfollowUser: {
    readonly success: boolean | null;
  } | null;
};
export type AccountUnfollowUserMutation = {
  variables: AccountUnfollowUserMutation$variables;
  response: AccountUnfollowUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request_data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "request_data"
      }
    ],
    "concreteType": "UnfollowUserMutation",
    "kind": "LinkedField",
    "name": "unfollowUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountUnfollowUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountUnfollowUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1e3cacc3777a92d12a9b70476e5957dc",
    "id": null,
    "metadata": {},
    "name": "AccountUnfollowUserMutation",
    "operationKind": "mutation",
    "text": "mutation AccountUnfollowUserMutation(\n  $request_data: FollowUnfollowUserArguments!\n) {\n  unfollowUser(requestData: $request_data) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "55ea5920eed28f5d01a062db943b5a53";

export default node;
