import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-relay';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { MPActionButton } from '@mp-frontend/core-components';

import AccountStripeCardsQueryType, {
  AccountStripeCardsQuery,
} from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import NFTUnlockNftUnlockableMutationType, {
  NFTUnlockNftUnlockableMutation,
} from 'graphql/__generated__/NFTUnlockNftUnlockableMutation.graphql';
import { UnlockNftUnlockableArguments } from 'types/__generated__/graphql';

import { useStripePayment } from 'hooks/product/usePaymentFormState';
import { NFTUnlockableType } from 'types/graphql/NFT';
import roundUpToNearestCent from 'utils/currency/roundUpToNearestCent';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import CongratulationsModal from './Congratulations';
import GreetingsModal from './Greetings';
import PaymentModal, { PaymentChangeValue } from './Payment';
import ShippingModal, {
  COUNTRY_SHIPPING_REGION_MAP,
  StripeAddressElementChangeValue,
} from './Shipping';

import * as styles from 'css/pages/product/ProductClaimUnlockable.module.css';

enum UnlockableStep {
  Greetings,
  Shipping,
  Payment,
  Congratulations,
}

interface ClaimUnlockableProps {
  invalidate: () => void;
  nftId: number;
  stripeQuery: WithLoadQueryProps<AccountStripeCardsQuery>;
  unlockable: NFTUnlockableType;
}

function ClaimUnlockable({
  nftId,
  stripeQuery,
  unlockable: {
    shippingPaidSeparately: isPaymentRequired,
    shippingCostEu: shippingCostEurope,
    shippingCostUs: shippingCostNorthAmerica,
    shippingCostAp: shippingCostOther,
  },
  invalidate,
}: ClaimUnlockableProps) {
  const stripe = useStripe();
  const elements = useElements();
  const { getPaymentMethodId, withNextPaymentAction } = useStripePayment();
  const [currentStep, setCurrentStep] = useState<UnlockableStep>(
    UnlockableStep.Greetings
  );
  const [show, setShow] = useState<boolean>(false);
  const [address, setAddress] = useState<StripeAddressElementChangeValue>(null);
  const [payment, setPayment] = useState<PaymentChangeValue>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [priceInCents, priceInUsd] = useMemo(() => {
    let shippingPriceInCents: number;
    const country = address?.address?.country;

    if (isPaymentRequired && country) {
      shippingPriceInCents = COUNTRY_SHIPPING_REGION_MAP.EUROPE.has(country)
        ? shippingCostEurope
        : COUNTRY_SHIPPING_REGION_MAP.NORTH_AMERICA.has(country)
        ? shippingCostNorthAmerica
        : shippingCostOther;
    }
    shippingPriceInCents = shippingPriceInCents || 0;

    return [
      shippingPriceInCents,
      roundUpToNearestCent(shippingPriceInCents / 100),
    ];
  }, [
    address?.address?.country,
    isPaymentRequired,
    shippingCostEurope,
    shippingCostNorthAmerica,
    shippingCostOther,
  ]);

  const [nftUnlockUnlockableMutation] =
    useMutation<NFTUnlockNftUnlockableMutation>(
      NFTUnlockNftUnlockableMutationType
    );
  const commitPurchaseAsync = withNextPaymentAction<
    NFTUnlockNftUnlockableMutation['variables'],
    NFTUnlockNftUnlockableMutation['response']
  >(promisifyMutationWithRequestData(nftUnlockUnlockableMutation));

  const handleAddressChange = useCallback(
    (value: StripeAddressElementChangeValue, complete: boolean) => {
      if (!complete) return;

      setAddress(value);
    },
    []
  );

  const handlePaymentChange = useCallback(
    (value: PaymentChangeValue, complete: boolean) => {
      if (!complete) return;

      setPayment(value);
    },
    []
  );

  const handleSubmit = useCallback(async (): Promise<boolean> => {
    if (!address || (isPaymentRequired && !payment)) return false;

    setIsSubmitting(true);

    let paymentMethodId: string = null;
    const variables: UnlockNftUnlockableArguments = {
      nftId,
      shippingAddressCity: address.address.city,
      shippingAddressCountry: address.address.country,
      shippingAddressFullName: address.name,
      shippingAddressLine1: address.address.line1,
      shippingAddressLine2: address.address.line2,
      shippingAddressPhoneNumber: address.phone,
      shippingAddressPostalCode: address.address.postal_code,
      shippingAddressState: address.address.state,
    };

    if (isPaymentRequired) {
      paymentMethodId = await getPaymentMethodId(
        payment.selectionType,
        payment.savedCardId,
        payment.fullName
      );
      variables.amountInCents = priceInCents;
      variables.rememberCard = payment.rememberCard;
    }

    try {
      const result = await commitPurchaseAsync({
        ...variables,
        paymentMethodId,
      });

      return !!result.unlockNftUnlockable?.success;
    } catch {
      return false;
    } finally {
      setIsSubmitting(false);
    }
  }, [
    payment,
    address,
    priceInCents,
    nftId,
    isPaymentRequired,
    commitPurchaseAsync,
    getPaymentMethodId,
  ]);

  const handleStart = useCallback(() => setShow(true), []);

  const handleClose = useCallback(() => {
    if (currentStep === UnlockableStep.Congratulations) invalidate();

    setShow(false);
  }, [currentStep, invalidate]);

  const handleNextStep = useCallback(async () => {
    let nextStep = currentStep + 1;

    if (nextStep === UnlockableStep.Payment && !isPaymentRequired) nextStep++;
    if (
      nextStep > UnlockableStep.Congratulations ||
      (nextStep === UnlockableStep.Congratulations && !(await handleSubmit()))
    )
      return;

    setCurrentStep(nextStep);
  }, [currentStep, isPaymentRequired, handleSubmit]);

  const currentModal = {
    [UnlockableStep.Greetings]: (
      <GreetingsModal onNext={handleNextStep} onClose={handleClose} />
    ),
    [UnlockableStep.Shipping]: (
      <ShippingModal
        isPaymentRequired={isPaymentRequired}
        shippingCostEurope={shippingCostEurope}
        shippingCostNorthAmerica={shippingCostNorthAmerica}
        shippingCostOther={shippingCostOther}
        onChange={handleAddressChange}
        onNext={handleNextStep}
        onClose={handleClose}
      />
    ),
    [UnlockableStep.Payment]: (
      <PaymentModal
        stripeCardsQueryRef={stripeQuery.queryRef}
        isSubmitting={isSubmitting}
        priceInUsd={priceInUsd}
        shippingFullName={address?.name || ''}
        onChange={handlePaymentChange}
        onNext={handleNextStep}
        onClose={handleClose}
      />
    ),
    [UnlockableStep.Congratulations]: (
      <CongratulationsModal onClose={handleClose} />
    ),
  }[currentStep];

  return stripe && elements ? (
    <div>
      <MPActionButton
        fullWidth
        size="large"
        variant="secondary"
        onClick={handleStart}
        className={styles.productUnlockableBtn}
      >
        Unlock
      </MPActionButton>

      {!!show && currentModal}
    </div>
  ) : null;
}

export default withLoadQuery(ClaimUnlockable, {
  stripeQuery: { concreteRequest: AccountStripeCardsQueryType },
});
