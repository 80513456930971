import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { MPDropdown, MPStyledTextField } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useStateBackedObjRef from 'hooks/useStateBackedObjRef';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import useActivityGTM from 'utils/GTM/activity';

import * as styles from 'css/pages/activity/ActivityFilters.module.css';

const TITLE = 'Price Range';

const FLOAT_REGEX = /^\d+(\.\d+)?$/;

const isValidFloat = (value: string) =>
  FLOAT_REGEX.test(value) && (value.length <= 1 || value[0] !== '0');

export type PriceFilterValue = Partial<{
  max: number;
  min: number;
}>;

interface PriceFilterProps {
  onChange: (value: PriceFilterValue) => void;
  value: PriceFilterValue;
}

export default function PriceFilter({ value, onChange }: PriceFilterProps) {
  const track = useActivityGTM();
  const [validValue, setValidValue] = useState<{
    max: string;
    min: string;
  }>({
    max: '',
    min: '',
  });
  const [valueRef, getValue, setValue] = useStateBackedObjRef(validValue);

  useEffect(() => {
    const newValidValue = {
      max: value.max ? value.max.toString() : '',
      min: value.min ? value.min.toString() : '',
    };
    setValidValue(newValidValue);
    setValue('max', newValidValue.max);
    setValue('min', newValidValue.min);
  }, [value.max, value.min, setValue]);

  const handleToggle = useCallback(
    (open: boolean) => track.toggleFilter(TITLE, open),
    [track]
  );

  const handleMaxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setValue('max', event.target.value),
    [setValue]
  );

  const handleMinChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setValue('min', event.target.value),
    [setValue]
  );

  const handleChange = useCallback(
    (key: keyof PriceFilterValue) => {
      if (
        (valueRef.current[key] && !isValidFloat(valueRef.current[key])) ||
        (valueRef.current.min &&
          valueRef.current.max &&
          parseFloat(valueRef.current.min) >= parseFloat(valueRef.current.max))
      ) {
        setValue(key, validValue[key]);
        return;
      }

      const newValidValue = { ...validValue, [key]: valueRef.current[key] };
      setValidValue(newValidValue);
      onChange({
        max: parseFloat(newValidValue.max),
        min: parseFloat(newValidValue.min),
      });
    },
    [valueRef, onChange, setValue, validValue]
  );

  return (
    <MPDropdown title={TITLE} onToggle={handleToggle}>
      <div
        className={joinClasses(
          CSSGlobal.Flex.Row,
          CSSGlobal.Flex.CenteredRow,
          CSSGap[16],
          styles.priceFilter
        )}
      >
        <MPStyledTextField
          label="Minimum Price"
          placeholder="Min Price"
          value={getValue('min') || ''}
          onChange={handleMinChange}
          onBlur={() => handleChange('min')}
          inputMode="numeric"
          startAdornment="$"
          className={styles.input}
        />

        <MPStyledTextField
          label="Maximum Price"
          placeholder="Max Price"
          value={getValue('max') || ''}
          onChange={handleMaxChange}
          onBlur={() => handleChange('max')}
          inputMode="numeric"
          startAdornment="$"
          className={styles.input}
        />
      </div>
    </MPDropdown>
  );
}
