.leaderboard {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  width: 100%;
}

.leaderboardTableScrollableContainer {
  max-width: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.leaderboardTableContainer {
  min-width: fit-content;
  width: 100%;
}

.leaderboardTable {
  border-spacing: 0;
  width: 100%;

  &.withLowest {
    margin-top: -1px;

    &.showTopBorder {
      border-top: 1px solid var(--color-SolidNeutralGray1);
    }
  }

  &.showBottomBorder {
    border-bottom: 1px solid var(--color-SolidNeutralGray1);
  }
}

.leaderboardTable > thead {
  display: block;
  border-bottom: 1px solid var(--color-SolidNeutralGray1);
  margin-bottom: 8px;
}

.leaderboardTable > thead > tr {
  display: block;
  width: 100%;
}

.leaderboardTable > thead > tr > td {
  color: var(--color-SolidNeutralGray5);
  padding: 0 25px 8px 2px;
  white-space: nowrap;
}

.leaderboardTable > tbody > tr > td {
  line-height: 117%;
  min-width: calc(4ch + 4px);
  white-space: nowrap;
  padding: 8px 0;
}

.leaderboardTable > thead > tr > td:first-child {
  padding: 0px 2px 8px 2px;
  min-width: 4ch;
}

.leaderboardTable > thead > tr > td:last-child {
  padding: 0 0 0 15px;
  text-align: right;
}

.leaderboardTable > tbody > tr > td:first-child {
  color: var(--color-SolidNeutralGray5)
}

.leaderboardTable > tbody > tr > td:last-child {
  padding-right: 5px;
  text-align: right;
}

.leaderboardTable > thead > tr > td:not(:first-child):not(:last-child),
.leaderboardTable > tbody > tr > td:not(:first-child):not(:last-child) {
  white-space: initial;
  width: 99%;
}

.leaderboardTable > tbody {
  display: block;
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.leaderboardTable.limitedHeight > tbody {
  max-height: 399px;
}

.leaderboardTable > tbody > tr {
  background-color: var(--color-commonWhite);
  width: 100%;

  &.sticky {
    position: sticky;
    bottom: -1px;

    &.stuck {
      &::after {
        border-top: 1px solid var(--color-SolidNeutralGray1);
        content: " ";
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &.own {
    color: var(--color-goldMain);
  }
}

.leaderboardCollector {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.leaderboardCollectorProfileImage {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.leaderboardCollectorName {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 13ch;
}

.leaderboardPrice {
  align-items: center;
  color: var(--color-SolidNeutralGray5);
  display: inline-flex;
  gap: 4px;

  & .primary {
    color: var(--color-commonBlack);
  }
}

.leaderboardNoBidsMessage {
  border-bottom: 1px solid var(--color-SolidNeutralGray1);
  color: var(--color-SolidNeutralGray5);
  padding: 32px 0;
  text-align: center;
}

.leaderboardFooter {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 14px;

  & > .leaderboardBidsSummary {
    color: var(--color-SolidNeutralGray5);
    line-height: 1.4;
    margin-bottom: 10px;
  }
}

.details {
  display: flex;
  flex-direction: column;
}

.detailsContent {
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > p {
    margin: 0;
    padding: 0;
  }
}
