import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const PlusIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <rect
      x="19"
      y="13.4998"
      width="14"
      height="3"
      transform="rotate(-180 19 13.4998)"
      fill="currentColor"
    />
    <rect
      x="10.5005"
      y="19"
      width="14"
      height="3"
      transform="rotate(-90 10.5005 19)"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default PlusIcon;
