.productLabels {
  display: flex;
  flex-direction: column;
}

.productLabel {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;

  &.countdown {
    margin-bottom: 24px;
  }
}

.productLabelText {
  display: flex;
  flex-direction: row;

  &.secondary {
    color: var(--color-SolidNeutralGray5);
  }

  &.premium {
    color: var(--color-goldMain);
  }

  &.link {
    color: var(--color-commonBlack);
    text-decoration: none;
  }
}

.productMessage {
  margin-bottom: 24px;
  text-align: left;

  &.center {
    text-align: center;
  }
}

.productTimerIcon {
  font-size: 18px;
  margin-right: 4px;
  position: relative;
}

.unlockableMessageContainer {
  display: flex;
  justify-content: center;
  margin: 8px 0 24px 0;
}
