import { useState } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import DefaultSuspense from 'components/DefaultSuspense';
import useProductOwnership from 'hooks/product/useProductOwnership';
import useSession from 'hooks/useSession';
import { NFTType } from 'types/graphql/NFT';
import { isMintedOnChain, isNFTPendingSale } from 'utils/nftUtils';

import ProductOwnerUpdateListing from './ProductOwnerUpdateListing';

import * as styles from '../../../css/pages/product/ProductOwnerUpdateListing.module.css';

interface ProductOwnerModeProps {
  approvalRegistryContract: NFTContractQuery$data['nftContract'];
  nft: NFTType;
  invalidate?: () => void;
}

function ProductOwnerMode({
  nft,
  invalidate,
  approvalRegistryContract,
}: ProductOwnerModeProps) {
  const session = useSession();
  const { liveSale } = nft.listing;
  const isCurrentUserTokenOwner = useProductOwnership({ nft });
  const isPendingSale = isNFTPendingSale(nft.listing);
  const [showListingModel, setShowListingModel] = useState(false);
  const isOnChain = isMintedOnChain(nft);
  const canShowSellArtworkMessage =
    !liveSale && !nft.listing.hasReservePrice && isOnChain && nft.isSendable;

  return (
    <>
      {!!isCurrentUserTokenOwner && session.isLoggedIn() && (
        <>
          {!!isPendingSale && (
            <div
              className={joinClasses(
                MPFonts.paragraphSmall,
                styles.ownerModeDisplayMessage
              )}
            >
              This artwork has recently sold and is being transferred to the new
              owner. New offers can be placed soon.
            </div>
          )}
          {!nft.isSendable && (
            <div
              className={joinClasses(
                MPFonts.paragraphSmall,
                styles.ownerModeDisplayMessage
              )}
            >
              Artworks purchased with a credit card cannot be resold or sent for
              72 hours after purchase, enough time to verify the sale.
            </div>
          )}
          {!!canShowSellArtworkMessage && (
            <div
              className={joinClasses(
                MPFonts.paragraphSmall,
                styles.ownerModeDisplayMessage
              )}
            >
              Sell your artwork as a fixed price or an auction
            </div>
          )}
          {!!(liveSale || nft.listing.hasReservePrice) && (
            <div
              className={joinClasses(
                MPFonts.paragraphSmall,
                styles.ownerModeDisplayMessage
              )}
            >
              Delisting your artwork will cancel the lowest asking price. Buyers
              will still be able to bid on this artwork
            </div>
          )}
          {!!nft.isSendable && !isPendingSale && (
            <>
              <MPActionButton
                fullWidth
                size="large"
                onClick={() => setShowListingModel(true)}
              >
                {liveSale || nft.listing.hasReservePrice
                  ? 'Edit or Delist'
                  : 'Sell Artwork'}
              </MPActionButton>
            </>
          )}
          <DefaultSuspense>
            <ProductOwnerUpdateListing
              nft={nft}
              setShowDialog={setShowListingModel}
              showDialog={showListingModel}
              invalidate={invalidate}
              approvalRegistryContract={approvalRegistryContract}
            />
          </DefaultSuspense>
        </>
      )}
    </>
  );
}

export default ProductOwnerMode;
