import { SvgIcon } from '@mui/material';

export default function LinkIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M22.2729 6.63692C21.0845 5.44666 19.5218 4.84052 17.9592 4.84052L18.9826 8.96232C19.1917 9.07253 19.3788 9.19376 19.5438 9.3701C19.973 9.79991 20.2151 10.384 20.2151 10.9902C20.2151 11.5963 19.973 12.1804 19.5438 12.6102L14.6909 17.4704C14.2617 17.9003 13.6785 18.1427 13.0732 18.1427C12.468 18.1427 11.8848 17.9003 11.4556 17.4704C11.0264 17.0406 10.7843 16.4565 10.7843 15.8504C10.7843 15.3655 10.9384 14.9136 11.2025 14.5279L10.2561 9.96523L8.7265 11.4971C6.32754 13.8997 6.32754 17.8011 8.7265 20.2036C9.92598 21.4049 11.4996 22.011 13.0732 22.011C14.6469 22.011 16.2205 21.4049 17.42 20.2036L22.2729 15.3434C24.6719 12.9409 24.6719 9.03947 22.2729 6.63692Z"
        fill="currentColor"
      />
      <path
        d="M15.3402 4.80742C14.1407 3.60615 12.567 3 10.9934 3C9.41978 3 7.84614 3.60615 6.64666 4.80742L1.80472 9.65661C-0.594248 12.0592 -0.594248 15.9606 1.80472 18.3631C2.99319 19.5534 4.55582 20.1595 6.11845 20.1595L5.09504 16.0377C4.88595 15.9275 4.69888 15.8063 4.53381 15.6299C4.10464 15.2001 3.86254 14.616 3.86254 14.0099C3.86254 13.4037 4.10464 12.8196 4.53381 12.3898L9.38676 7.52958C9.81593 7.09977 10.3992 6.85731 11.0044 6.85731C11.6097 6.85731 12.1929 7.09977 12.6221 7.52958C13.0512 7.9594 13.2933 8.5435 13.2933 9.14965C13.2933 9.7558 13.0512 10.3399 12.6221 10.7697L12.402 10.9901L13.3373 15.5087L15.3402 13.5029C17.7391 11.1003 17.7391 7.19896 15.3402 4.7964V4.80742Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
