import { SvgIcon } from '@mui/material';

export default function GalleryIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M7.73679 3H3V14.3683H7.73679V3Z" fill="currentColor" />
      <path d="M7.73679 16.2632H3V21H7.73679V16.2632Z" fill="currentColor" />
      <path d="M9.63094 3H14.3677V7.73679H9.63094V3Z" fill="currentColor" />
      <path d="M21 3H16.2632V14.3683H21V3Z" fill="currentColor" />
      <path
        d="M9.63094 9.63092H14.3677V20.9992H9.63094V9.63092Z"
        fill="currentColor"
      />
      <path d="M21 16.2632H16.2632V21H21V16.2632Z" fill="currentColor" />
    </SvgIcon>
  );
}
