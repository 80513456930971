.modalLoginContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modalLoginContent > :global(.MuiButtonBase-root) {
  width: 100%;
  margin-bottom: 15px;
}

.modalSignUpContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalSignUpEmailFields {
  width: 100%;
}

.emailSignUpLabel {
  margin-top: 0px;
  margin-bottom: 8px;
}

.emailLoginForm {
  width: 100%;
  margin-bottom: 10px;
}

.signInButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.signInButtons > :global(.MuiButtonBase-root) {
  width: 100%;
  min-height: 40px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.alreadyHaveAnAccountText {
  margin-top: 8px;
  margin-bottom: 6px;
}

.signUpSecondaryText {
  margin-top: -7px;
  margin-bottom: 18px;
}

.loginDividerMiddle {
  margin: 0 0 8px 0;
  width: 100%;
}

.loginDividerTermsAndConditions {
  margin: 8px 0 16px 0;
  width: 100%;
}

.loginDividerBottom {
  margin: 16px 0;
  width: 110%;
}

.signUpSuccessDialogContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 245px;
  text-align: center;
  margin-top: -57px;
}

.termsAndAgreementsText {
  margin-top: 5px;
  margin-bottom: 12px;
}

.signInButtonsEmailAddress {
  margin: 0;
}

.emailSignUpPassword {
  margin: 0;
}

.emailSignUpPasswordConfirm {
  margin: 0;
}

.emailSignUpDivider {
  width: 120%;
  margin-top: 3px;
  left: -10%;
  margin-bottom: 16px;
  position: relative;
}

.loginEmailAddress {
  margin-top: 10px;
}

.loginEmailPassword {
  margin-top: 2px;
  margin-bottom: 1px;
}

.forgotPasswordLink {
  margin-top: 4px;
  margin-bottom: 4px;
}

.dontHavePasswordText {
  margin-top: 8px;
  margin-bottom: 16px;
}

.newAccountIconWrapper {
  width: 47px;
  height: 47px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 100px;
  background: var(--color-commonBlack);
}

.newAccountIconInner {
  height: 20px;
  width: 20px;
  color: var(--color-commonWhite);
}

.welcomeToMakersPlaceHeading {
  margin-top: 6px;
  margin-bottom: 0px;
}
.welcomeToMakersPlaceDescription {
  margin-top: 0px;
  margin-bottom: 20px;
}

@media(--mq-is-mobile) {
  .modalContentWrapper {
    padding: 12px 24px 20px;
  }
  .signUpSecondaryText {
    margin-top: 8px;
  }
  .alreadyHaveAnAccountText {
    margin-top: 13px;
  }
}

.walletIconDisabled {
  filter: grayscale(0.9);
  opacity: 0.75;
}

.requestRejectedModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.requestRejectedMessage {
  margin: 20px 0;
  text-align: center;
}
