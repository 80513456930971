import { useCallback } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { ProgressEllipse } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { NftTraitsType } from 'types/__generated__/graphql';

import * as styles from 'css/pages/product/ProductAttributes.module.css';

interface ProductAttributeProps {
  traits: NftTraitsType;
}

const RARE_PERCENTAGE = 10;
const PROGRESS_ITEMS_LENGTH = 34;

function ProductAttribute({ traits }: ProductAttributeProps) {
  const blackOrGoldText = useCallback(
    (...originalClasses: string[]) =>
      joinClasses(
        ...originalClasses,
        ...(traits.rarity <= RARE_PERCENTAGE ? [styles.attributeItemRare] : [])
      ),
    [traits.rarity]
  );

  const progressDarkStyle = Math.ceil(
    PROGRESS_ITEMS_LENGTH * (traits.rarity / 100)
  );

  return (
    <div className={styles.attributeItem}>
      <div
        className={blackOrGoldText(
          MPFonts.textNormalMedium,
          styles.attributeItemTypeContainer
        )}
      >
        <div
          className={joinClasses(
            styles.attributeItemType,
            MPFonts.textNormalMedium
          )}
        >
          <span>{traits.type}</span>
          <span className="textOverflowEllipsis" title={traits.value}>
            {traits.value}
          </span>
        </div>
        <div
          className={blackOrGoldText(
            MPFonts.textSmallMedium,
            styles.attributeItemPercentageLabel
          )}
        >
          {traits.rarity}% have this
        </div>
      </div>
      <div className={styles.attributeItemPercentageContainer}>
        {Array.from({ length: PROGRESS_ITEMS_LENGTH }, (_, i) => (
          <ProgressEllipse
            key={i}
            className={blackOrGoldText(
              styles.attributePercentageProgressEllipse,
              i < progressDarkStyle ? null : styles.light
            )}
          />
        ))}
      </div>
    </div>
  );
}

export default ProductAttribute;
