import { Fragment, useCallback, useState, useTransition } from 'react';
import { noop } from 'lodash';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPFonts,
  MPStandardDialog,
  useMPConfirmDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountDeleteCreditCard, {
  AccountDeleteCreditCardMutation,
} from 'graphql/__generated__/AccountDeleteCreditCardMutation.graphql';
import AccountSetDefaultCreditCard, {
  AccountSetDefaultCreditCardMutation,
} from 'graphql/__generated__/AccountSetDefaultCreditCardMutation.graphql';
import { PaymentMethodType } from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import creditCardUtils from 'utils/creditCard';
import promisifyMutation from 'utils/promisifyMutation';

import * as styles from 'css/pages/settings/wallet/creditCard/ManageCardDialog.module.css';

function useSetDefaultButton({
  cancel,
  invalidate,
  card,
  setError,
  shouldBlock,
  setShouldBlock,
}): [boolean, JSX.Element] {
  const [isLoading, setIsLoading] = useState(false);
  const [isTransitioning, startTransition] = useTransition();
  const [setDefaultCreditCard] =
    useMutation<AccountSetDefaultCreditCardMutation>(
      AccountSetDefaultCreditCard
    );

  const setDefault = useCallback(async () => {
    if (isLoading || isTransitioning || shouldBlock) return;
    setIsLoading(true);
    setShouldBlock(true);
    setError(undefined);
    try {
      const result = await promisifyMutation(setDefaultCreditCard)({
        paymentId: card.id,
      });
      if (result) {
        startTransition(() => {
          invalidate();
        });
        cancel();
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
      setShouldBlock(false);
    }
  }, [
    cancel,
    card?.id,
    invalidate,
    isLoading,
    isTransitioning,
    setDefaultCreditCard,
    setError,
    setShouldBlock,
    shouldBlock,
  ]);

  return [
    isLoading || isTransitioning,
    <MPActionButton
      key="makeDefault"
      variant="primary"
      fullWidth
      size="large"
      isLoading={isLoading || isTransitioning}
      disabled={shouldBlock}
      onClick={setDefault}
    >
      Make Default
    </MPActionButton>,
  ];
}

function useDeleteButton({
  cancel,
  invalidate,
  card,
  setError,
  shouldBlock,
  setShouldBlock,
}): [boolean, JSX.Element] {
  const [isLoading, setIsLoading] = useState(false);
  const [isTransitioning, startTransition] = useTransition();

  const [deleteCreditCard] = useMutation<AccountDeleteCreditCardMutation>(
    AccountDeleteCreditCard
  );

  const deleteCard = useCallback(async () => {
    if (isLoading || isTransitioning || shouldBlock) return;
    setIsLoading(true);
    setShouldBlock(true);
    setError(undefined);
    try {
      const result = await promisifyMutation(deleteCreditCard)({
        cardId: card.id,
      });
      if (result) {
        startTransition(() => {
          invalidate();
        });
        cancel();
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
      setShouldBlock(false);
    }
  }, [
    cancel,
    card?.id,
    invalidate,
    isLoading,
    isTransitioning,
    deleteCreditCard,
    setError,
    setShouldBlock,
    shouldBlock,
  ]);

  const CreditCardIcon = creditCardUtils.getIcon(card.brand);

  const [, setDialogOpen, ConfirmDialog] = useMPConfirmDialog({
    content: (
      <>
        <div
          className={joinClasses(MPFonts.textSmallMedium, styles.dialogBody)}
        >
          <div className={styles.removeConfirmationText}>
            You will no longer be able to make USD purchases using this credit
            card. Are you sure still want to remove?
          </div>
          <div className={styles.cardDetailRow}>
            <span
              className={joinClasses(
                CSSGlobal.Flex.InlineRowCenterAlign,
                CSSGap[8]
              )}
            >
              <CreditCardIcon />
              &nbsp;
              <span>●●●●&nbsp;●●●●&nbsp;●●●●&nbsp;{card.number}</span>
              <span>
                {String(card.expMonth).padStart(2, '0')}&nbsp;/&nbsp;
                {card.expYear.toString().slice(-2)}
              </span>
            </span>
          </div>
        </div>
      </>
    ),
    onCancel: noop,
    onConfirm: deleteCard,
    title: 'Remove Credit Card',
  });

  return [
    isLoading || isTransitioning,
    <Fragment key="deleteCard">
      <MPActionButton
        key="deleteCard"
        variant="secondary"
        fullWidth
        size="large"
        isLoading={isLoading || isTransitioning}
        disabled={shouldBlock}
        onClick={() => setDialogOpen(true)}
      >
        Remove Card
      </MPActionButton>
      {ConfirmDialog}
    </Fragment>,
  ];
}

interface ManageCardDialogProps {
  cancel: () => void;
  card: PaymentMethodType;
  invalidate: () => void;
  isDefault: boolean;
  isOpen: boolean;
}

export default function ManageCardDialog({
  card,
  isOpen,
  isDefault,
  invalidate,
  cancel,
}: ManageCardDialogProps) {
  const [shouldBlock, setShouldBlock] = useState(false);
  const [error, setError] = useState<Error>(undefined);

  const [, DeleteButtonTSX] = useDeleteButton({
    cancel,
    card,
    invalidate,
    setError,
    setShouldBlock,
    shouldBlock,
  });
  const [, SetDefaultButtonTSX] = useSetDefaultButton({
    cancel,
    card,
    invalidate,
    setError,
    setShouldBlock,
    shouldBlock,
  });

  const buttons = [DeleteButtonTSX];
  if (!isDefault) {
    buttons.push(SetDefaultButtonTSX);
  }

  const CreditCardIcon = creditCardUtils.getIcon(card.brand);

  return (
    <MPStandardDialog
      title="Manage Credit Card"
      open={isOpen}
      onClose={cancel}
      actionButton={buttons}
    >
      <ErrorDisplay error={error} className={CSSGlobal.TextAlign.Centered} />
      <div
        className={joinClasses(
          MPFonts.textSmallMedium,
          CSSGlobal.Flex.Col,
          CSSGap[16]
        )}
      >
        <div className={MPFonts.textSmallSemiBold}>{`${
          isDefault ? 'Default ' : ''
        }Credit Card`}</div>
        <div className={styles.cardDetailRow}>{card.name}</div>
        <div className={styles.cardDetailRow}>
          <span
            className={joinClasses(
              CSSGlobal.Flex.InlineRowCenterAlign,
              CSSGap[8]
            )}
          >
            <CreditCardIcon />
            &nbsp;
            <span>●●●●&nbsp;●●●●&nbsp;●●●●&nbsp;{card.number}</span>
          </span>
          <span
            className={joinClasses(
              CSSGlobal.Flex.InlineRowCenterAlign,
              CSSGap[16]
            )}
          >
            <span>
              {String(card.expMonth).padStart(2, '0')}&nbsp;/&nbsp;
              {card.expYear.toString().slice(-2)}
            </span>
            <span>●●●</span>
          </span>
        </div>
      </div>
    </MPStandardDialog>
  );
}
