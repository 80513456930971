import { MPColorClass } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useMPConnect from 'hooks/wallet/useMPConnect';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { type WalletId } from 'utils/jwt/walletUtils';
import { get24PxConnectorIcon } from 'utils/wallet/connectorUtils';

import WalletNameDisplay from './WalletNameDisplay';
import {
  BalanceSubcomponent,
  CollectedArtworksSubcomponent,
} from './WalletSubComponents';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

import { type Wallets } from 'Session';

interface WalletProps {
  wallet: Wallets[0];
}

export default function CollectionWallet({ wallet }: WalletProps) {
  const { findConnectorByAddress } = useMPConnect();
  const Icon = get24PxConnectorIcon(
    findConnectorByAddress(wallet?.address)?.id as WalletId
  );
  return (
    <>
      <div className={CSSGlobal.Flex.RowSpaceBetween}>
        <span
          className={joinClasses(
            CSSGlobal.Flex.InlineRowCenterAlign,
            CSSGap[8],
            CSSGlobal.Ellipsis
          )}
        >
          {Icon || null}
          <span
            className={joinClasses(
              CSSGlobal.Ellipsis,
              MPColorClass.CommonBlack
            )}
          >
            <WalletNameDisplay name={wallet?.name} address={wallet?.address} />
          </span>
        </span>
      </div>
      <div className={pageStyles.walletAttributes}>
        <CollectedArtworksSubcomponent wallet={wallet} />
        <BalanceSubcomponent wallet={wallet} />
      </div>
    </>
  );
}
