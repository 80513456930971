/**
 * @generated SignedSource<<1c657730efef10810f1e5d0d468becb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BeforePurchaseInETHArguments = {
  buyerAddress: `0x${string}`;
  productId: number;
  isPresale?: boolean | null;
  emailAddress?: string | null;
  count?: number | null;
};
export type ListingBeforePurchaseInETHMutation$variables = {
  requestData: BeforePurchaseInETHArguments;
};
export type ListingBeforePurchaseInETHMutation$data = {
  readonly beforePurchaseInEth: {
    readonly success: boolean | null;
    readonly response: {
      readonly depositRequestId: number | null;
      readonly depositMetadataId: number | null;
      readonly depositMetadataMarketPriceInWei: any | null;
    } | null;
  } | null;
};
export type ListingBeforePurchaseInETHMutation = {
  variables: ListingBeforePurchaseInETHMutation$variables;
  response: ListingBeforePurchaseInETHMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "BeforePurchaseInETHMutation",
    "kind": "LinkedField",
    "name": "beforePurchaseInEth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SalePurchaseDepositDataType",
        "kind": "LinkedField",
        "name": "response",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "depositRequestId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "depositMetadataId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "depositMetadataMarketPriceInWei",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingBeforePurchaseInETHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingBeforePurchaseInETHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f73992fdc703d342613a38398c61b60f",
    "id": null,
    "metadata": {},
    "name": "ListingBeforePurchaseInETHMutation",
    "operationKind": "mutation",
    "text": "mutation ListingBeforePurchaseInETHMutation(\n  $requestData: BeforePurchaseInETHArguments!\n) {\n  beforePurchaseInEth(requestData: $requestData) {\n    success\n    response {\n      depositRequestId\n      depositMetadataId\n      depositMetadataMarketPriceInWei\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4de503469917d2c75c4b8845a6861f3f";

export default node;
