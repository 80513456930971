/**
 * @generated SignedSource<<831776ee01b850ab8e3866e9ac2f867f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateGlobalBidInETHArguments = {
  bidAmountInWei: any;
  bidderAddress: `0x${string}`;
  digitalMediaId: number;
  digitalMediaReleaseId: number;
  email: string;
  nonce: number;
  transactionId: `0x${string}`;
};
export type ListingCreateGlobalBidInETHMutation$variables = {
  requestData: CreateGlobalBidInETHArguments;
};
export type ListingCreateGlobalBidInETHMutation$data = {
  readonly createGlobalBidInEth: {
    readonly success: boolean | null;
  } | null;
};
export type ListingCreateGlobalBidInETHMutation = {
  variables: ListingCreateGlobalBidInETHMutation$variables;
  response: ListingCreateGlobalBidInETHMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "CreateGlobalBidInETHMutation",
    "kind": "LinkedField",
    "name": "createGlobalBidInEth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingCreateGlobalBidInETHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingCreateGlobalBidInETHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4dfa232eddc96d0a3986ebfb44a1ccca",
    "id": null,
    "metadata": {},
    "name": "ListingCreateGlobalBidInETHMutation",
    "operationKind": "mutation",
    "text": "mutation ListingCreateGlobalBidInETHMutation(\n  $requestData: CreateGlobalBidInETHArguments!\n) {\n  createGlobalBidInEth(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "d2b73daf08159fb0982d06f761c15d0a";

export default node;
