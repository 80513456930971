import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';

const generateEthAndUsdPricing = (priceInEth: number, priceInUsd: number) =>
  `${generatePriceString(
    priceInEth,
    CurrencyDisplayMode.ETH
  )} (${generatePriceString(priceInUsd, CurrencyDisplayMode.USD)})`;

export default generateEthAndUsdPricing;
