.productSellContainer {
  margin: 32px;
}

.productSellMessageContainer {
  margin-bottom: 16px;
}

.productSellFooter {
  width: 100%;
  margin: 24px 0;
  border-top: 1px solid var(--color-SolidNeutralGray1);
}

.productSellListingBtnContainer {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin: 20px 32px;
}

.buyReservePricingContainer {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
}

.buyNowPriceItem:last-child {
  margin-left: auto;
}

.buyReserveSecondaryPrice {
  color: var(--color-SolidNeutralGray5);
}

.deslistingMessage {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  color: var(--color-SolidNeutralGray5);
}

.productBuyNowReserveInputContainer {
  margin-top: 16px;
}

.productMessageCentered {
  display: flex;
  justify-content: center;
}
