import { useCallback, useEffect, useMemo, useState } from 'react';
import { PreloadedQuery } from 'react-relay';

import {
  MPActionButton,
  MPFonts,
  MPStandardDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { AccountStripeCardsQuery } from 'graphql/__generated__/AccountStripeCardsQuery.graphql';

import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';
import { NFTCardSelectionType } from 'utils/nftUtils';

import CreditCard from '../purchaseOfferDialog/paymentView/CreditCard';

import * as styles from 'css/pages/product/ProductClaimUnlockable.module.css';

export interface PaymentChangeValue {
  fullName: string;
  rememberCard: boolean;
  savedCardId: string;
  selectionType: NFTCardSelectionType;
}

interface PaymentProps {
  isSubmitting: boolean;
  onChange: (value: PaymentChangeValue, complete: boolean) => void;
  onClose: () => void;
  onNext: () => void;
  priceInUsd: number;
  shippingFullName: string;
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
}

export default function PaymentModal({
  onChange,
  onClose,
  onNext,
  isSubmitting,
  priceInUsd,
  shippingFullName,
  stripeCardsQueryRef,
}: PaymentProps) {
  const [fullName, setFullName] = useState(shippingFullName);
  const [selectionType, setSelectionType] = useState<NFTCardSelectionType>(
    NFTCardSelectionType.New
  );
  const [isValidCard, setIsValidCard] = useState<boolean>(false);
  const [savedCardId, setSavedCardId] = useState<string>('');
  const [rememberCard, setRememberCard] = useState(false);

  const isValid = useMemo(
    () =>
      !!(selectionType === NFTCardSelectionType.New
        ? fullName && isValidCard
        : savedCardId),
    [fullName, isValidCard, savedCardId, selectionType]
  );

  useEffect(() => {
    onChange(
      {
        fullName,
        rememberCard,
        savedCardId,
        selectionType,
      },
      isValid
    );
  }, [fullName, selectionType, savedCardId, rememberCard, isValid, onChange]);

  const handleSubmit = useCallback(() => {
    if (!isValid) return;

    onNext();
  }, [isValid, onNext]);

  return (
    <MPStandardDialog
      title="Unlock Physical"
      actionButton={
        <MPActionButton
          fullWidth
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit}
        >
          Purchase
        </MPActionButton>
      }
      onClose={onClose}
    >
      <div className={joinClasses(styles.container, styles.payment)}>
        <div className={MPFonts.paragraphNormal}>
          Add your payment information to finish unlocking your physical
          artwork.
        </div>

        <CreditCard
          stripeCardsQueryRef={stripeCardsQueryRef}
          type={selectionType}
          onTypeSelect={setSelectionType}
          onDefaultIdChange={setSavedCardId}
          cardholderName={fullName}
          onCardholderNameChange={setFullName}
          remember={rememberCard}
          onRememberChange={setRememberCard}
          onCardElementChange={({ complete, error, empty }) =>
            setIsValidCard(
              complete && !empty && error?.type !== 'validation_error'
            )
          }
        />

        <div className={styles.pricing}>
          <span className={MPFonts.textNormalMedium}>Shipping Cost</span>
          <span className={MPFonts.price}>
            {generatePriceString(priceInUsd, CurrencyDisplayMode.USD)}
          </span>
        </div>
      </div>
    </MPStandardDialog>
  );
}
