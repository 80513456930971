export const format = (
  template: string,
  variables: { [key: string]: string | number } = {}
): string =>
  template.replace(/\$\[(.*?)\]/g, (_, key) =>
    Object.prototype.hasOwnProperty.call(variables, key)
      ? variables[key].toString()
      : ''
  );

export default format;
