@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fadeInOutAnimation {
  animation-delay: 0ms, calc(var(--transition-ms) + var(--interval-ms));
  animation-direction: normal;
  animation-duration: var(--transition-ms), var(--transition-ms);
  animation-name: fadeIn, fadeOut;

  &.first {
    animation-delay: calc(var(--transition-ms) + var(--interval-ms));
    animation-duration: calc(2 * var(--transition-ms));
    animation-name: fadeOut;
  }
}
