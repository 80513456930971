import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import useForceUpdate from './useForceUpdate';

export enum ScrollDirection {
  Down = 'down',
  Up = 'up',
}

export interface ConnectionField {
  edges: Array<{ node: unknown }> | ReadonlyArray<{ node: unknown }>;
}

interface UseInfiniteQueryScrollConfig {
  paginatedQueryResults: {
    data: any;
    hasNext: boolean;
    isLoadingNext: boolean;
    loadNext: (count) => any;
  };
  ref: MutableRefObject<Element | null>;
  direction?: ScrollDirection;
  getConnectionField?: (data: any) => ConnectionField;
  onUpdate?: (data: any) => void;
  pageSize?: number;
  scrollRef?: MutableRefObject<Element | typeof window | null>;
}

function transformResults(resp: ConnectionField) {
  return resp.edges.map((edge) => edge.node);
}

function defaultGetConnectionField(data: any) {
  return data?.results;
}

function useInfiniteQueryScroll({
  ref,
  paginatedQueryResults,
  getConnectionField = defaultGetConnectionField,
  onUpdate,
  pageSize = 10,
  scrollRef = { current: window },
  direction = ScrollDirection.Down,
}: UseInfiniteQueryScrollConfig) {
  const { data, loadNext, hasNext, isLoadingNext } = paginatedQueryResults;

  const hasMore = useRef(true);
  const [forceUpdate] = useForceUpdate();

  const state = useRef<{
    hasMore: boolean;
    isLoading: boolean;
    shouldFetchMore: boolean;
  }>({
    hasMore: true,
    isLoading: false,
    shouldFetchMore: false,
  });

  const differential = 800;

  const visibilityRef = useRef<boolean | undefined>(undefined);

  const queryRef = useRef<{
    data: any;
    hasMore: boolean;
    loadMore: any;
    loading: boolean;
  }>({
    data: null,
    hasMore: hasNext,
    loadMore: loadNext,
    loading: isLoadingNext,
  });

  const results = data ? getConnectionField(data) : null;
  queryRef.current = {
    data: results ? transformResults(results) : null,
    hasMore: hasNext,
    loadMore: loadNext,
    loading: isLoadingNext,
  };

  // handleScroll should have no changeable dependencies to avoid the need for additional cleanup.
  const handleScroll = useCallback(() => {
    if (
      queryRef.current?.loading ||
      !visibilityRef.current ||
      !hasMore.current ||
      state.current.shouldFetchMore ||
      state.current.isLoading
    )
      return;
    const offsetHeight = 11;
    if (!scrollRef.current) return;
    if (scrollRef.current === window) {
      if (
        direction === ScrollDirection.Down
          ? window.innerHeight +
              document.documentElement.scrollTop +
              offsetHeight <
            document.documentElement.offsetHeight - differential
          : document.documentElement.scrollTop > offsetHeight
      ) {
        return;
      }
    } else {
      const el = scrollRef.current as Element;
      if (
        direction === ScrollDirection.Down
          ? el.scrollHeight -
              (el.scrollTop + el.getBoundingClientRect().height) >
            offsetHeight
          : el.scrollTop > offsetHeight
      ) {
        return;
      }
    }

    state.current.shouldFetchMore = true;
    forceUpdate();
    // We explicitly have an empty array to indicate that handleScroll should not change
    // the exhaustive-deps cannot properly check references coming from outside scope
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const fetch = useCallback(
    (force = false) => {
      if (!force && (!state.current.shouldFetchMore || state.current.isLoading))
        return;
      state.current.isLoading = true;
      queryRef.current.loadMore(pageSize, {
        onComplete() {
          state.current.isLoading = false;
          state.current.shouldFetchMore = false;
          handleScroll();
          forceUpdate();
          if (onUpdate) onUpdate(queryRef.current.data);
        },
      });
    },
    [state, queryRef, handleScroll, pageSize, onUpdate, forceUpdate]
  );

  useEffect(() => {
    if (hasNext) fetch();
  }, [state.current.shouldFetchMore, state.current.isLoading, hasNext, fetch]);

  handleScroll();

  const setVisibility = useCallback(
    (entries: Array<IntersectionObserverEntry>) => {
      const [entry] = entries;
      if (visibilityRef.current !== entry.isIntersecting) {
        if (entry.isIntersecting) {
          scrollRef.current?.addEventListener('scroll', handleScroll);
        } else {
          scrollRef.current?.removeEventListener('scroll', handleScroll);
        }
      }
      visibilityRef.current = entry.isIntersecting;
      if (visibilityRef.current) handleScroll();
    },
    [handleScroll, scrollRef]
  );

  const observerRef = useRef<IntersectionObserver>(null);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(setVisibility, {
      root: null,
      rootMargin: '0px 0px 500px',
      threshold: 0,
    });
    const { current } = ref;
    if (current) {
      observerRef.current.observe(current);
      return () => {
        observerRef.current.unobserve(current);
      };
    }
    return undefined;
  }, [ref, setVisibility]);

  // this should handle any one time cleanup
  useEffect(
    () => () => {
      window.removeEventListener('scroll', handleScroll);
      if (observerRef.current) observerRef.current.disconnect();
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  );

  queryRef.current.loading =
    queryRef.current.loading || state.current.isLoading;
  return queryRef.current;
}

export default useInfiniteQueryScroll;
