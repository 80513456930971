.panel {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.profileImgLink, .profileImg {
  width: 72px;
  height: 72px;
}

.profileImg {
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid var(--color-backgroundDefault);
}

.bannerContainer {
  position: relative;
  top: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
}

.aboutBannerImage, .aboutBannerImageOverlay  {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.aboutBannerImageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.6;
  backdrop-filter: blur(16px);
}

.profileInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: -36px;
  padding-left: var(--horz-margin);
  padding-right: var(--horz-margin);
  padding-bottom: 29px;
  z-index: 2;
}

.panelBody {
  padding-top: 11px;
}

@media(--mq-is-mobile) {
  .panel {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    overflow: overlay;
  }

  .bannerContainer {
    width: 100vw;
    right: var(--horz-margin);
    z-index: 0;
  }

  .profileInfo {
    margin-top: -44px;
    padding-bottom: 0px;
  }

  .profileImgLink, .profileImg {
    width: 88px;
    height: 88px;
    margin-left: -2px;
  }

  div.panelBody {
    background-color: var(--color-backgroundDefault);
    overflow-x: hidden;
    --horz-margin: 34px;
    padding-top: 0px;
  }

  .aboutBannerImage, .aboutBannerImageOverlay  {
    height: 120px;
  }
}
