/**
 * @generated SignedSource<<eb5d3317fe99de342497c04008b3e9d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProvenanceObjectType = "MINT" | "SALE" | "LAZY_MINT" | "LISTING" | "PRODUCT_BID" | "MEDIA_BID" | "PURCHASE" | "TRANSFER";
export type NFTsTagAndProvenanceQuery$variables = {
  productSlug?: string | null;
  digitalMediaId?: number | null;
  isLiveOnChain?: boolean | null;
  first?: number | null;
  orderBy?: string | null;
  password?: string | null;
};
export type NFTsTagAndProvenanceQuery$data = {
  readonly nfts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly printEdition: number;
        readonly provenance: ReadonlyArray<{
          readonly type: ProvenanceObjectType;
          readonly source: string | null;
          readonly dest: string | null;
          readonly amountUsd: number | null;
          readonly amountEth: number | null;
          readonly currency: string | null;
          readonly date: any | null;
          readonly store: {
            readonly id: string;
            readonly pk: string;
            readonly storeSlug: string | null;
            readonly storeName: string;
            readonly owner: {
              readonly profileImageUrl: string;
            } | null;
          } | null;
        } | null> | null;
        readonly metadata: {
          readonly id: string;
          readonly pk: string;
          readonly tags: ReadonlyArray<string>;
          readonly title: string;
          readonly totalSupply: number;
        } | null;
        readonly contract: {
          readonly isExternal: boolean | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};
export type NFTsTagAndProvenanceQuery = {
  variables: NFTsTagAndProvenanceQuery$variables;
  response: NFTsTagAndProvenanceQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "digitalMediaId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLiveOnChain"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productSlug"
},
v6 = [
  {
    "kind": "Variable",
    "name": "digitalMediaId",
    "variableName": "digitalMediaId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isLiveOnChain",
    "variableName": "isLiveOnChain"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "password",
    "variableName": "password"
  },
  {
    "kind": "Variable",
    "name": "productSlug",
    "variableName": "productSlug"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printEdition",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dest",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountUsd",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountEth",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeSlug",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "NFTMetadataAbstractType",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSupply",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isExternal",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTsTagAndProvenanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProvenanceType",
                    "kind": "LinkedField",
                    "name": "provenance",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoreTypeV3",
                        "kind": "LinkedField",
                        "name": "store",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountAbstractType",
                            "kind": "LinkedField",
                            "name": "owner",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTEthereumContractType",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTsTagAndProvenanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "nfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProvenanceType",
                    "kind": "LinkedField",
                    "name": "provenance",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoreTypeV3",
                        "kind": "LinkedField",
                        "name": "store",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountAbstractType",
                            "kind": "LinkedField",
                            "name": "owner",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTEthereumContractType",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf0b03068f276c0ab2ddf17cbbd2c1c1",
    "id": null,
    "metadata": {},
    "name": "NFTsTagAndProvenanceQuery",
    "operationKind": "query",
    "text": "query NFTsTagAndProvenanceQuery(\n  $productSlug: String\n  $digitalMediaId: Float\n  $isLiveOnChain: Boolean\n  $first: Int\n  $orderBy: String\n  $password: String\n) {\n  nfts(productSlug: $productSlug, digitalMediaId: $digitalMediaId, first: $first, isLiveOnChain: $isLiveOnChain, orderBy: $orderBy, password: $password) {\n    edges {\n      node {\n        id\n        pk\n        printEdition\n        provenance {\n          type\n          source\n          dest\n          amountUsd\n          amountEth\n          currency\n          date\n          store {\n            id\n            pk\n            storeSlug\n            storeName\n            owner {\n              profileImageUrl\n              id\n            }\n          }\n        }\n        metadata {\n          id\n          pk\n          tags\n          title\n          totalSupply\n        }\n        contract {\n          isExternal\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "418ee608050105213438c28434539cec";

export default node;
