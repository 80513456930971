import { ReactNode } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import { GrouppedLoadingProvider } from 'components/GrouppedLoadingProvider';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/explore/Layout.module.css';

export type BaseLayoutProps = {
  hideHero?: boolean;
};

interface LayoutProps extends BaseLayoutProps {
  cards: ReactNode;
  hero: ReactNode;
  children?: ReactNode;
}

export default function Layout({
  hero,
  hideHero,
  cards,
  children,
}: LayoutProps) {
  return (
    <GrouppedLoadingProvider position="absolute">
      <div
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGlobal.Cursor.Default,
          styles.container
        )}
      >
        {!hideHero && hero}

        {children ?? cards}
      </div>
    </GrouppedLoadingProvider>
  );
}
