import { Hash } from 'viem';

import { MPTooltip } from '@mp-frontend/core-components';

import getAddressDisplay from 'utils/getAddressDisplay';

interface WalletNameDisplayProps {
  address: Hash;
  name?: string;
}

export default function WalletNameDisplay({
  address,
  name,
}: WalletNameDisplayProps) {
  return name ? (
    <MPTooltip title={address} placement="top">
      <span>{name}</span>
    </MPTooltip>
  ) : (
    <>{getAddressDisplay(address)}</>
  );
}
