.container {
  padding: 20px 21px;

  @media screen and (min-width: 1920px) {
    padding: 32px;
  }

  @media (--mq-is-mobile) {
    padding: 24px;
  }
}

.assetsSection {
  height: 383px;

  @media screen and (min-width: 1920px) {
    height: 459px;
  }

  @media (--mq-is-mobile) {
    height: 255px;
  }
}

.assetContainer {
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
  padding: 18px;
}

.asset {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 100%;
}
