/**
 * @generated SignedSource<<8d2cbfce8e73f3464d12fc7a92f8364c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateSaleWithCustodialWalletArguments = {
  availableForSale: boolean;
  productId: number;
  salePrice: number;
  auctionDate?: string | null;
  saleId?: number | null;
};
export type ListingCreateSaleWithCustodialWalletMutation$variables = {
  request_data: CreateSaleWithCustodialWalletArguments;
};
export type ListingCreateSaleWithCustodialWalletMutation$data = {
  readonly createSaleWithCustodialWallet: {
    readonly success: boolean | null;
  } | null;
};
export type ListingCreateSaleWithCustodialWalletMutation = {
  variables: ListingCreateSaleWithCustodialWalletMutation$variables;
  response: ListingCreateSaleWithCustodialWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request_data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "request_data"
      }
    ],
    "concreteType": "CreateSaleWithCustodialWalletMutation",
    "kind": "LinkedField",
    "name": "createSaleWithCustodialWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingCreateSaleWithCustodialWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingCreateSaleWithCustodialWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f86cff15dc0bd4f1922fc56e21128c0",
    "id": null,
    "metadata": {},
    "name": "ListingCreateSaleWithCustodialWalletMutation",
    "operationKind": "mutation",
    "text": "mutation ListingCreateSaleWithCustodialWalletMutation(\n  $request_data: CreateSaleWithCustodialWalletArguments!\n) {\n  createSaleWithCustodialWallet(requestData: $request_data) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "521d1b6a046ed8dedaabc6f6aa2aa4d0";

export default node;
