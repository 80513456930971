import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const UnmuteIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M3.90991 9.30773V14.7022H7.50626L12.0017 19.1977V4.81229L7.50626 9.30773H3.90991ZM16.0476 12.005C16.0476 10.4136 15.1305 9.04699 13.7999 8.38167V15.6193C15.1305 14.963 16.0476 13.5964 16.0476 12.005ZM13.7999 4.12V5.97211C16.3982 6.74533 18.2953 9.15488 18.2953 12.005C18.2953 14.8551 16.3982 17.2646 13.7999 18.0379V19.89C17.4052 19.0718 20.0935 15.8531 20.0935 12.005C20.0935 8.15689 17.4052 4.93816 13.7999 4.12Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default UnmuteIcon;
