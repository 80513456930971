import { SvgIcon } from '@mui/material';

export default function PreviousArrow(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M-7.52253e-06 6.99994L6.22221 13.2222L8.97207 13.2222L3.72207 7.97217L14 7.97217L14 6.02772L3.72207 6.02772L8.97207 0.777723L6.22221 0.777723L-7.52253e-06 6.99994Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
