/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: make link button class
import { defaults } from 'lodash';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useNFTBuyReserveLockCountdown from 'hooks/useNFTBuyReserveLockCountdown';
import format from 'utils/string/format';

import * as styles from 'css/pages/product/ProductReserveLockTimer.module.css';

interface NFTBuyReserveLockTimerProps {
  reserveLockEndDate: Date;
  classes?: {
    container?: string;
    text?: string;
  };
  extendAuction?: () => Promise<void>;
  stringTemplates?: { expired?: string; notExpired?: string };
}

function NFTBuyReserveLockTimer({
  classes = {},
  reserveLockEndDate,
  extendAuction,
  stringTemplates = {},
}: NFTBuyReserveLockTimerProps) {
  const { secondsRemaining, minutesRemaining, isExpired } =
    useNFTBuyReserveLockCountdown(reserveLockEndDate);
  defaults(stringTemplates, {
    expired: 'This artwork is no longer reserved.',
    notExpired:
      "We've reserved this artwork for you for the next: $[minutes]m $[seconds]s",
  });

  return (
    <div className={joinClasses(classes.container, styles.timerContainer)}>
      {!isExpired ? (
        <span className={classes.text || MPFonts.bodyMedium}>
          {format(stringTemplates.notExpired, {
            minutes: minutesRemaining,
            seconds: secondsRemaining,
          })}
          &nbsp;
          {extendAuction ? (
            <a
              className={styles.extendButton}
              onClick={extendAuction}
              onKeyPress={extendAuction}
              role="button"
              tabIndex={0}
            >
              Extend
            </a>
          ) : null}
        </span>
      ) : (
        <span className={classes.text || MPFonts.bodyMedium}>
          {stringTemplates.expired}
        </span>
      )}
    </div>
  );
}

export default NFTBuyReserveLockTimer;
