.row {
  padding: 16px;
  border: 1px solid var(--color-SolidNeutralGray1);
  cursor: pointer;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.row:hover, .row:focus {
  border-color: var(--color-CommonBlack);
}

.exp {
  margin-left: 8px;
}

.dialogBody {
  padding: 20px 34px 8px;
}

@media (--mq-is-mobile) {
  .row {
    flex-direction: column;
    align-self: unset;
    align-items: flex-start;
  }
  .topRow {
    justify-content: space-between;
    width: 100%;
  }
  .exp {
    margin: 4px 0px 0px;
  }
}
