import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

import SearchPage from './SearchPage';

const SearchRoute = (
  <Route path="search" element={<SearchPage />}>
    <Route index element={<SlashRedirect />} />
    <Route path="*" element={<Reload />} />
  </Route>
);

export default SearchRoute;
