import {
  forwardRef,
  HTMLProps,
  MouseEvent,
  MouseEventHandler,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useIsMobile } from '@mp-frontend/core-components';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import useForceUpdate from 'hooks/useForceUpdate';
import useVideo from 'hooks/useVideo';

import * as styles from 'css/pages/product/ProductPreview.module.css';

const SHORT_VIDEO_DURATION_SECONDS = 5;
const MOBILE_CONTROLS_TIMEOUT_MS = 4000;

const hasAudio = (videoEl: HTMLVideoElement): boolean => {
  const el = videoEl as any;
  if (!el) return false;

  return !!(
    el.mozHasAudio ||
    el.webkitAudioDecodedByteCount ||
    el.audioTracks?.length
  );
};

interface PlayButtonProps {
  isPlaying: boolean;
  percentage: number;
  showProgress: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

function PlayButton({
  isPlaying,
  percentage,
  showProgress,
  onClick,
}: PlayButtonProps) {
  const r = 15;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100;

  return (
    <button
      type="button"
      aria-label={isPlaying ? 'Pause' : 'Play'}
      onClick={onClick}
    >
      <svg width={32} height={32}>
        {showProgress ? (
          <g transform="rotate(-90, 16, 16)">
            <circle
              r={r}
              cx={16}
              cy={16}
              fill="transparent"
              stroke={strokePct !== circ ? `currentColor` : ''}
              strokeWidth="1.9px"
              strokeDasharray={circ}
              strokeDashoffset={0}
            />
            <circle
              r={r}
              cx={16}
              cy={16}
              fill="transparent"
              stroke={strokePct !== circ ? 'var(--color-commonBlack)' : ''}
              strokeWidth="2.1px"
              strokeDasharray={circ}
              strokeDashoffset={percentage ? strokePct : 0}
            />
          </g>
        ) : null}

        {isPlaying ? (
          <>
            <rect
              x="11.7144"
              y="22"
              width="12"
              height="2.57143"
              transform="rotate(-90 11.7144 22)"
              fill="currentColor"
            />
            <rect
              x="17.7144"
              y="22"
              width="12"
              height="2.57143"
              transform="rotate(-90 17.7144 22)"
              fill="currentColor"
            />
          </>
        ) : (
          <path
            d="M13.7605 10.6345L21.3244 16.0006L13.7605 21.3667L13.7605 10.6345Z"
            fill="currentColor"
          />
        )}
      </svg>
    </button>
  );
}

interface MuteButtonProps {
  isMuted: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

function MuteButton({ isMuted, onClick }: MuteButtonProps) {
  return (
    <button
      type="button"
      aria-label={isMuted ? 'Unmute' : 'Mute'}
      onClick={onClick}
    >
      <svg width={32} height={32}>
        <path
          d={
            isMuted
              ? 'M18.6667 15.9998C18.6667 14.9509 18.0623 14.0502 17.1853 13.6117V14.9213L18.6371 16.3732C18.6549 16.2547 18.6667 16.1302 18.6667 15.9998ZM20.1482 15.9998C20.1482 16.5569 20.0297 17.0784 19.8282 17.5643L20.723 18.4591C21.1142 17.7243 21.3334 16.8887 21.3334 15.9998C21.3334 13.4635 19.5616 11.3421 17.1853 10.8028V12.0235C18.8979 12.5332 20.1482 14.1213 20.1482 15.9998ZM11.4193 10.6665L10.6667 11.4191L13.4697 14.2221H10.6667V17.7776H13.0371L16.0001 20.7406V16.7524L18.5186 19.2709C18.1216 19.5791 17.6771 19.8221 17.1853 19.9702V21.1909C18.003 21.0072 18.7438 20.628 19.3719 20.1184L20.5808 21.3332L21.3334 20.5806L16.0001 15.2472L11.4193 10.6665ZM16.0001 11.2591L14.7616 12.4976L16.0001 13.7361V11.2591Z'
              : 'M10.6667 14.125V17.7213H13.0643L16.0613 20.7183V11.128L13.0643 14.125H10.6667ZM18.7585 15.9232C18.7585 14.8622 18.1471 13.9512 17.2601 13.5076V18.3327C18.1471 17.8952 18.7585 16.9841 18.7585 15.9232ZM17.2601 10.6665V11.9012C18.9923 12.4167 20.257 14.0231 20.257 15.9232C20.257 17.8232 18.9923 19.4296 17.2601 19.9451V21.1798C19.6636 20.6344 21.4558 18.4886 21.4558 15.9232C21.4558 13.3578 19.6636 11.2119 17.2601 10.6665Z'
          }
          fill="currentColor"
        />
      </svg>
    </button>
  );
}

export type VideoProps = Pick<HTMLProps<HTMLVideoElement>, 'src'> &
  Partial<{
    currentTime: number;
    height: number;
    isFullScreen: boolean;
    isMuted: boolean;
    isPlaying: boolean;
    onClick: (event: MouseEvent) => void;
    onLoaded: () => void;
    onMutedUpdate: (isMuted: boolean) => void;
    onPlayingUpdate: (isPlaying: boolean) => void;
    onTimeUpdate: (currentTime: number) => void;
    width: number;
    autoPlayOnLoad?: boolean;
  }>;

export default forwardRef(
  (
    {
      currentTime: currentTimeInitialValue,
      isPlaying: isPlayingInitialValue,
      isMuted: isMutedInitialValue,
      isFullScreen,
      src,
      onClick,
      onLoaded,
      onTimeUpdate,
      onPlayingUpdate,
      onMutedUpdate,
      height,
      width,
      autoPlayOnLoad = true,
    }: VideoProps,
    setForwardRef: (instance: HTMLVideoElement | null) => void
  ) => {
    const inViewVideo = useVideo({
      autoPlayOnLoad,
    });
    const isMobile = useIsMobile();
    const [ref, , setRef] = useRefState<HTMLVideoElement>(undefined);
    const [progress, setProgress] = useState<number>(0);
    const [durationSeconds, setDurationSeconds] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(isPlayingInitialValue);
    const [isMuted, setIsMuted] = useState<boolean>(isMutedInitialValue);
    const controlsTimeoutRef = useRef<number>(null);
    const [forceUpdate] = useForceUpdate();
    const [show, setShow] = useState<boolean>(true);

    const handleShowControls = useCallback((): void => {
      if (!ref || !isFullScreen || !isMobile) return;

      if (controlsTimeoutRef.current)
        window.clearTimeout(controlsTimeoutRef.current);

      controlsTimeoutRef.current = window.setTimeout(() => {
        controlsTimeoutRef.current = null;
        forceUpdate();
      }, MOBILE_CONTROLS_TIMEOUT_MS);
      forceUpdate();
    }, [ref, controlsTimeoutRef, isFullScreen, isMobile, forceUpdate]);

    const handlePlayToggleClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        if (!ref) return;

        if (ref.paused) {
          ref.play();
        } else {
          ref.pause();
        }
        handleShowControls();
      },
      [ref, handleShowControls]
    );

    const handleMuteToggleClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        if (!ref) return;

        ref.muted = !ref.muted;
        setIsMuted((prev) => !prev);
        handleShowControls();
      },
      [ref, handleShowControls]
    );

    const handleVideoTimeUpdate = useCallback(() => {
      if (!ref) return;

      setDurationSeconds(ref.duration);
      setProgress(((ref.currentTime / ref.duration) * 100) % 100);
      onTimeUpdate?.(ref.currentTime);
    }, [ref, onTimeUpdate]);

    const handleVideoPlay = useCallback(() => {
      setIsPlaying(true);
      onPlayingUpdate?.(true);
    }, [onPlayingUpdate]);

    const handleVideoPause = useCallback(() => {
      setIsPlaying(false);
      onPlayingUpdate?.(false);
    }, [onPlayingUpdate]);

    const handleVideoVolumeChange = useCallback(() => {
      if (!ref) return;

      setIsMuted(ref.muted);
      onMutedUpdate?.(ref.muted);
    }, [ref, onMutedUpdate]);

    const handleVideoClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();

        onClick?.(event);
        handleShowControls();
      },
      [onClick, handleShowControls]
    );

    const handleLoaded = useCallback(() => {
      inViewVideo.setLoaded();
      onLoaded?.();
    }, [inViewVideo, onLoaded]);

    useEffect(() => {
      setShow(false);
      startTransition(() => setShow(true));
    }, [src]);

    useEffect(() => {
      if (!ref) return undefined;

      if (currentTimeInitialValue) ref.currentTime = currentTimeInitialValue;

      ref.addEventListener('timeupdate', handleVideoTimeUpdate);
      ref.addEventListener('play', handleVideoPlay);
      ref.addEventListener('pause', handleVideoPause);
      ref.addEventListener('volumechange', handleVideoVolumeChange);

      return () => {
        ref.removeEventListener('timeupdate', handleVideoTimeUpdate);
        ref.removeEventListener('play', handleVideoPlay);
        ref.removeEventListener('pause', handleVideoPause);
        ref.removeEventListener('volumechange', handleVideoVolumeChange);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      ref,
      handleVideoTimeUpdate,
      handleVideoPlay,
      handleVideoPause,
      handleVideoVolumeChange,
    ]);

    useEffect(() => {
      if (!ref) return undefined;

      handleShowControls();

      return () => {
        if (controlsTimeoutRef.current) {
          window.clearTimeout(controlsTimeoutRef.current);
        }
      };
    }, [ref, controlsTimeoutRef, handleShowControls]);

    const VideoControls = (
      <div
        className={joinClasses(styles.videoControls, {
          [styles.visible]: !!(
            isMobile &&
            isFullScreen &&
            controlsTimeoutRef.current
          ),
        })}
      >
        <PlayButton
          onClick={handlePlayToggleClick}
          isPlaying={isPlaying}
          percentage={progress}
          showProgress={durationSeconds > SHORT_VIDEO_DURATION_SECONDS}
        />
        {hasAudio(ref) ? (
          <MuteButton onClick={handleMuteToggleClick} isMuted={isMuted} />
        ) : null}
      </div>
    );

    return (
      <>
        <div className={styles.video} style={{ height, width }}>
          {!!show && (
            <video
              ref={(node) => {
                setRef(node);
                setForwardRef?.(node);
                inViewVideo.videoRef(node);
              }}
              autoPlay={isPlayingInitialValue}
              playsInline
              loop
              muted={isMuted}
              controls={false}
              onClick={handleVideoClick}
              onLoadedData={handleLoaded}
              onLoadedMetadata={handleLoaded}
              onMouseEnter={inViewVideo.onMouseEnterHandler}
              height={height}
              width={width}
            >
              <source src={src} type="video/mp4" />
            </video>
          )}

          {(!isMobile || !isFullScreen) && VideoControls}
        </div>

        {!!isFullScreen && !!isMobile && VideoControls}
      </>
    );
  }
);
