import { forwardRef } from 'react';
import { Link, LinkProps } from '@mui/material';

import { MPActionButton } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { DropQuery } from 'graphql/__generated__/DropQuery.graphql';

import { DropClickEventType } from 'GTM';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import useDropPageGTM from 'utils/GTM/drop';

import Section from './Section';

export const LinkComponent = forwardRef<HTMLAnchorElement>(
  (props: LinkProps, ref) => (
    <Link {...props} ref={ref} target="_blank" rel="noreferrer" />
  )
);

interface LinksProps {
  links: DropQuery['response']['drops']['edges'][number]['node']['moreAboutLinks'];
}

export default function Links({ links }: LinksProps) {
  const track = useDropPageGTM();
  return (
    !!links.length && (
      <Section title="More Links">
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
          {links.map(({ id, title, url }) => (
            <MPActionButton
              key={id}
              href={url}
              LinkComponent={LinkComponent}
              size="large"
              variant="secondary"
              onClick={() =>
                track.trackClickEventType(DropClickEventType.DropMoreLink)
              }
            >
              {title}
            </MPActionButton>
          ))}
        </div>
      </Section>
    )
  );
}
