import { TransactionExecutionError, UserRejectedRequestError } from 'viem';
import { ConnectorAlreadyConnectedError } from 'wagmi';
import {
  ConnectorAccountNotFoundError,
  ConnectorNotConnectedError,
} from '@wagmi/core';

const WALLET_CONNECTION_RESET_ERROR_MESSAGE =
  'Connection request reset. Please try again.';

const VIEM_METAMASK_USER_REJECTED_SIGNATURE = 'User rejected the request.';

/**
 * For Viem Errors, instanceof should be enough, but it's flaky in other places,
 * duck typing just in case.
 *
 * Wallet Connect only returns back a message
 */
const isWalletError = {
  ConnectorAccountNotFoundError: (error: any) =>
    error instanceof ConnectorAccountNotFoundError,
  ConnectorAlreadyConnected: (error: any) =>
    error instanceof ConnectorAlreadyConnectedError,
  ConnectorNotConnected: (error: any) =>
    error instanceof ConnectorNotConnectedError,
  RequestPermissionAlreadyPending: (error: any) =>
    // code is standard resource not available, with metamask specific message
    // eslint-disable-next-line eqeqeq
    error?.code == -32002 &&
    error?.message?.startsWith(
      `Request of type 'wallet_requestPermissions' already pending`
    ),
  userRejected: (error: any) =>
    error instanceof UserRejectedRequestError ||
    error?.code === UserRejectedRequestError.code ||
    (error instanceof TransactionExecutionError &&
      error.shortMessage === VIEM_METAMASK_USER_REJECTED_SIGNATURE),
  walletConnectUserRejectedConnection: (error: any) =>
    error?.message === WALLET_CONNECTION_RESET_ERROR_MESSAGE,
};

export default isWalletError;
