import { useCallback, useEffect } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { PauseIcon, PlayIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGlobal from 'types/enums/css/Global';

import DropVideoPlayerActionIcon, {
  DropVideoPlayerActionIconSize,
} from './ActionIcon';
import { DropVideoPlayerControlsProps } from './DropVideoPlayerControlsProps';

import * as containerStyles from 'css/pages/drops/components/VideoPlayer/index.module.css';

export default function StandardBar({
  screenHandle,
  isPlaying,
  setIsPlaying,
  title,
}: Pick<
  DropVideoPlayerControlsProps,
  'screenHandle' | 'isPlaying' | 'title' | 'setIsPlaying'
>) {
  const handleClick = useCallback(() => {
    setIsPlaying(true);
    screenHandle.enter();
  }, [screenHandle, setIsPlaying]);

  useEffect(() => {
    if (!screenHandle.active) setIsPlaying(false);
  }, [setIsPlaying, screenHandle.active]);

  return (
    <>
      <div className={joinClasses(containerStyles.standardBar)}>
        <DropVideoPlayerActionIcon
          size={DropVideoPlayerActionIconSize.SMALL}
          onClick={handleClick}
          Icon={isPlaying ? PauseIcon : PlayIcon}
        />
        <span
          className={joinClasses(
            CSSGlobal.Cursor.Default,
            CSSGlobal.Ellipsis,
            MPFonts.textSmallMedium
          )}
        >
          {title}
        </span>
      </div>
    </>
  );
}
