import { SvgIcon } from '@mui/material';

export default function PinterestIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.9963 3C7.02057 3 3 7.02797 3 11.9963C3 15.8095 5.36939 19.0675 8.71617 20.378C8.63472 19.6672 8.56808 18.5714 8.74578 17.7939C8.90868 17.0905 9.7972 13.3217 9.7972 13.3217C9.7972 13.3217 9.53065 12.7812 9.53065 11.9889C9.53065 10.7376 10.2563 9.80461 11.1596 9.80461C11.9297 9.80461 12.2999 10.3821 12.2999 11.0708C12.2999 11.8408 11.8112 12.9959 11.552 14.0695C11.3373 14.9654 12.0037 15.6985 12.8848 15.6985C14.4842 15.6985 15.7133 14.0103 15.7133 11.5817C15.7133 9.42699 14.1658 7.9239 11.9519 7.9239C9.38996 7.9239 7.88687 9.84162 7.88687 11.826C7.88687 12.5961 8.18305 13.4253 8.55327 13.877C8.62732 13.9659 8.63472 14.0473 8.61251 14.1362C8.54587 14.4175 8.39037 15.0321 8.36076 15.158C8.32373 15.3209 8.22747 15.3579 8.05717 15.2764C6.94652 14.7433 6.25051 13.0995 6.25051 11.7816C6.25051 8.94571 8.30892 6.33936 12.1962 6.33936C15.3135 6.33936 17.7421 8.56067 17.7421 11.5372C17.7421 14.6396 15.7873 17.1349 13.0773 17.1349C12.1666 17.1349 11.3077 16.661 11.0189 16.0983C11.0189 16.0983 10.5673 17.8161 10.4562 18.2382C10.2563 19.023 9.70835 20.0004 9.33812 20.6002C10.1822 20.8593 11.0708 21 12.0037 21C16.972 21 21 16.972 21 12.0037C20.9926 7.02797 16.9646 3 11.9963 3Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
