.user {
  align-items: center;
}

.profileImage {
  display: inline-block;

  &.small {
    width: 28px;
    height: 28px;
  }

  &.medium {
    width: 38px;
    height: 38px;
  }

  &.large {
    width: 68px;
    height: 68px;
  }

  > img {
    border-radius: 50%;
    width: inherit;
    height: inherit;
  }
}

.rightSection {
  align-items: flex-start;
  overflow: hidden;
}

.action {
  margin-top: 7px;
}
