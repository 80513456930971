import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const NotificationsIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M16.5955 13.3558V8.52979C16.5955 6.0349 14.4652 4 11.8588 4C9.25248 4 7.1222 6.04269 7.1222 8.52979V13.3558L5 14.499V16.8379C10.3571 16.8379 13.3606 16.8379 18.7177 16.8379V14.499L16.5955 13.3558Z"
      fill="currentColor"
    />
    <path
      d="M11.8571 20C13.1159 20 14.1407 19.0098 14.1407 17.7936H9.58154C9.58154 19.0098 10.6063 20 11.8651 20H11.8571Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default NotificationsIcon;
