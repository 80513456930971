import { useEffect, useMemo } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { useAccount } from 'wagmi';

import OpenEditionLeaderboardQueryType, {
  OpenEditionLeaderboardQuery,
} from 'graphql/__generated__/OpenEditionLeaderboardQuery.graphql';

import usePollLoadQuery from 'hooks/graphqlLogic/usePollLoadQuery';
import useSession from 'hooks/useSession';
import LeaderboardContainer from 'pages/product/rankedAuction/LeaderboardContainer';
import {
  LeaderboardRowData,
  LeaderboardUserCell,
} from 'pages/product/rankedAuction/LeaderboardRow';
import LeaderboardTable from 'pages/product/rankedAuction/LeaderboardTable';
import {
  OpenEditionDropMetadataType,
  OpenEditionType,
} from 'types/graphql/OpenEdition';
import { AccountConnectionStatus, HexString } from 'utils/jwt/walletUtils';

import * as styles from 'css/pages/openEdition/OpenEditionPage.module.css';

import { SessionType } from 'Session';

type LeaderboardTableData = {
  count: number;
} & LeaderboardRowData;

export type OpenEditionUpdateEvent = {
  dropsAt: OpenEditionDropMetadataType['dropsAt'];
  editionsSold: OpenEditionType['editionsSold'];
  endsAt: OpenEditionDropMetadataType['endsAt'];
};

interface LeaderboardProps {
  collapsible: boolean;
  hasBottomBorder: boolean;
  queryRef: PreloadedQuery<OpenEditionLeaderboardQuery>;
  currentUserAddress?: HexString;
  currentUserWallets?: SessionType['account']['wallets'];
  currentUsername?: string;
  hideIfEmpty?: boolean;
  onOpenEditionUpdate?: (event: OpenEditionUpdateEvent) => void;
}

function Leaderboard({
  queryRef,
  collapsible,
  currentUserAddress,
  currentUserWallets,
  currentUsername,
  hasBottomBorder,
  hideIfEmpty,
  onOpenEditionUpdate,
}: LeaderboardProps) {
  const result = usePreloadedQuery<OpenEditionLeaderboardQuery>(
    OpenEditionLeaderboardQueryType,
    queryRef
  );
  const dropMetadata = useMemo(
    () => result.dropsMedias.edges[0]?.node.nftMetadata.dropMetadata,
    [result]
  );
  const openEdition = useMemo(() => dropMetadata?.listing, [dropMetadata]);
  const rows = useMemo(
    () =>
      (openEdition?.leaderboard ?? []).map(
        ({ count, buyer }): LeaderboardTableData => ({
          count,
          user: buyer,
        })
      ),
    [openEdition]
  );
  useEffect(() => {
    if (!openEdition) return;

    onOpenEditionUpdate?.({
      dropsAt: dropMetadata.dropsAt,
      editionsSold: openEdition.editionsSold,
      endsAt: dropMetadata.endsAt,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dropMetadata?.dropsAt,
    dropMetadata?.endsAt,
    openEdition?.editionsSold,
    onOpenEditionUpdate,
  ]);

  return (
    !!(!hideIfEmpty || rows.length) && (
      <LeaderboardContainer
        collapsible={collapsible}
        hasBottomBorder={hasBottomBorder}
      >
        <LeaderboardTable<LeaderboardTableData>
          columns={['Rank', 'Collector', 'Editions Minted']}
          currentUserAddress={currentUserAddress}
          currentUserWallets={currentUserWallets}
          currentUsername={currentUsername}
          classes={{ emptyMessage: styles.emptyMessage }}
          emptyMessage="There have been no purchases yet."
          rows={rows}
          renderCells={({ user, isOwn, count }, index) => [
            index + 1,
            <LeaderboardUserCell user={user} isOwn={isOwn} />,
            count,
          ]}
        />
      </LeaderboardContainer>
    )
  );
}

export default function OpenEditionLeaderboard({
  openEditionId,
  openEditionSlug,
  collapsible = false,
  hasBottomBorder = false,
  hideIfEmpty = false,
  onOpenEditionUpdate,
}: {
  openEditionId: number;
  openEditionSlug: string;
  collapsible?: boolean;
  hasBottomBorder?: boolean;
  hideIfEmpty?: boolean;
  onOpenEditionUpdate?: LeaderboardProps['onOpenEditionUpdate'];
}) {
  const session = useSession();
  const { address: currentAccountAddress, status: currentAccountStatus } =
    useAccount();

  const [queryRef] = usePollLoadQuery<OpenEditionLeaderboardQuery>({
    intervalMs: 3000,
    queryType: OpenEditionLeaderboardQueryType,
    queryVariables: { openEditionId, openEditionSlug },
  });

  return !queryRef ? null : (
    <Leaderboard
      queryRef={queryRef}
      collapsible={collapsible}
      currentUserAddress={
        currentAccountStatus === AccountConnectionStatus.Connected
          ? currentAccountAddress
          : null
      }
      currentUsername={session.account?.username}
      currentUserWallets={session.account?.wallets}
      hasBottomBorder={hasBottomBorder}
      hideIfEmpty={hideIfEmpty}
      onOpenEditionUpdate={onOpenEditionUpdate}
    />
  );
}
