import { ReactNode, useEffect, useState } from 'react';

import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import useOnBodyResize from 'hooks/useOnBodyResize';

import * as styles from 'css/components/Marquee.module.css';

interface MarqueeProps {
  children: ReactNode;
  className?: string;
  disablePauseOnHover?: boolean;
  speedMultiplier?: number;
}

export default function Marquee({
  children,
  className,
  disablePauseOnHover = false,
  speedMultiplier = 0,
}: MarqueeProps) {
  const [marqueeNode, setMarqueeRef] = useRefState<HTMLDivElement>(null);
  const [needsInitialCheck, setNeedsInitialCheck] = useState<boolean>(true);
  const [shouldMarquee, setShouldMarquee] = useState<boolean>(false);

  const marqueeStyle = {
    animationDuration: `${(shouldMarquee ? speedMultiplier : 0) * 4}s`,
  };
  const { width: resizeBodyWidth } = useOnBodyResize();

  useEffect(() => {
    setNeedsInitialCheck(true);
  }, [resizeBodyWidth]);

  useEffect(() => {
    if (needsInitialCheck && marqueeNode) {
      setShouldMarquee(marqueeNode.scrollWidth > marqueeNode.offsetWidth);
      setNeedsInitialCheck(false);
    }
  }, [needsInitialCheck, marqueeNode]);

  const marquee = !needsInitialCheck && shouldMarquee;

  return (
    <div
      className={joinClasses(
        styles.marqueeContainer,
        marquee && styles.isAnimating,
        className
      )}
      ref={setMarqueeRef}
    >
      <div
        className={joinClasses(styles.marquee, {
          [styles.pausable]: !disablePauseOnHover,
        })}
        style={marqueeStyle}
      >
        {children}
        {!!marquee && children}
      </div>
    </div>
  );
}
