import { some } from 'lodash';

import { HexString } from './jwt/walletUtils';

import { SessionType } from 'Session';

export function areSameAddress(
  addressOne: HexString,
  addressTwo: HexString
): boolean {
  return addressOne?.toLowerCase() === addressTwo?.toLowerCase();
}

export function hasUserRegisteredWalletAddress(
  address: HexString,
  accountWallets?: SessionType['account']['wallets']
) {
  return some(accountWallets, ({ address: userWalletAddress }) =>
    areSameAddress(userWalletAddress as HexString, address)
  );
}

export function hasUserRegisteredAddress(
  address: HexString,
  userAddress?: HexString,
  userWallets?: SessionType['account']['wallets']
) {
  return (
    userAddress &&
    (areSameAddress(userAddress, address) ||
      hasUserRegisteredWalletAddress(address, userWallets))
  );
}
