import {
  CSSProperties,
  SourceHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isEmpty, merge } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useExperiment } from '@statsig/react-bindings';

import { MPColorValue } from '@mp-frontend/core-components';
import { CloseIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import GTM from 'GTM';
import { getViewCount, incrementViewCount } from 'utils/localStorageUtils';

import * as styles from 'css/components/FullScreenOverlayExperiment.module.css';

const STATSIG_EXPERIMENT = 'exp_full_screen_overlay';
const CLOSE_ICON_SX = {
  cursor: 'pointer',
  fontSize: 24,
  justifyContent: 'space-between',
  position: 'absolute',
  right: '10px',
  top: '10px',
  zIndex: 2000,
};

interface FullScreenOverlayExperimentValue extends Record<string, any> {
  expectedViewCount: number;
  imageUrl: string;
  name: string;
  navigateUrl: string;
  pathnames: string[];
  styles: {
    close?: CSSProperties;
    container?: CSSProperties;
    media?: CSSProperties;
    mediaContainer?: CSSProperties;
  };
  uniqueId: string;
  videoSources: SourceHTMLAttributes<HTMLSourceElement>[];
}

export default function FullScreenOverlayExperiment() {
  const experiment = useExperiment(STATSIG_EXPERIMENT);
  const experimentValue = experiment.value as FullScreenOverlayExperimentValue;
  const isExperimentLoading = isEmpty(experimentValue);
  const location = useLocation();
  const [visible, setVisible] = useState<boolean>(false);
  const validViewCount = useRef<boolean>(false);
  const [isAssetLoading, setIsAssetLoading] = useState<boolean>(true);
  const gtm = useRef<{
    click(): Promise<void>;
    close(): Promise<void>;
    open(): Promise<void>;
  }>(null);

  useEffect(() => {
    const {
      uniqueId,
      name,
      pathnames,
      imageUrl,
      videoSources,
      navigateUrl,
      expectedViewCount,
    } = experimentValue || {};

    if (
      isExperimentLoading ||
      !uniqueId ||
      (Array.isArray(pathnames) && !pathnames.includes(location.pathname))
    ) {
      return;
    }

    const localStorageKey = `mp:view_count:${STATSIG_EXPERIMENT}:${uniqueId}`;
    const viewCount = getViewCount(localStorageKey);
    if (expectedViewCount && viewCount < expectedViewCount) {
      validViewCount.current = true;
      incrementViewCount(localStorageKey);
    }

    if (
      (!expectedViewCount || validViewCount.current) &&
      (Array.isArray(videoSources) || imageUrl) &&
      navigateUrl
    ) {
      gtm.current = GTM.fullScreenOverlay(
        name || `full-screen-overlay-${uniqueId || 'anonymous'}`
      );
      setVisible(true);
    }
  }, [isExperimentLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = useCallback(() => {
    gtm.current?.close();
    setVisible(false);
  }, []);
  const handleClick = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      gtm.current?.click();
      setVisible(false);
      window.location.href = experimentValue.navigateUrl;
    },
    [setVisible, experimentValue]
  );
  const handleLoaded = useCallback(() => {
    setIsAssetLoading(false);
    gtm.current?.open();
  }, []);

  const mergedCloseSx = merge({}, CLOSE_ICON_SX, experimentValue.styles?.close);

  return isExperimentLoading || !visible ? null : (
    <div
      className={joinClasses(
        styles.fullScreenOverlayExperiment,
        isAssetLoading ? styles.loading : null
      )}
      style={experimentValue.styles?.container}
    >
      <button
        type="button"
        aria-label="Navigate to URL from the Full Screen Overlay"
        onClick={handleClick}
        onKeyUp={handleClick}
        style={experimentValue.styles?.mediaContainer}
      >
        {Array.isArray(experimentValue.videoSources) &&
        experimentValue.videoSources.length ? (
          <video
            autoPlay
            muted
            playsInline
            preload="auto"
            onCanPlay={handleLoaded}
            style={experimentValue.styles?.media}
          >
            {experimentValue.videoSources.map(
              (source: SourceHTMLAttributes<HTMLSourceElement>) => (
                <source key={source.src} {...source} />
              )
            )}
          </video>
        ) : experimentValue.imageUrl ? (
          <img
            src={experimentValue.imageUrl}
            alt="Full Screen Overlay"
            onLoad={handleLoaded}
            style={experimentValue.styles?.media}
          />
        ) : null}
      </button>

      <CloseIcon
        sx={mergedCloseSx}
        htmlColor={MPColorValue.PrimaryMain}
        alt="Close Full Screen Overlay"
        onClick={handleClose}
      />
    </div>
  );
}
