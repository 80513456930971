.container {
  margin: 0 var(--homepage-rightMargin) 0 0;
  max-width: 890px;

  @media (--mq-is-mobile) {
    max-width: 90vw;
  }

  &.hasTopMargin {
    margin-top: var(--homepage-gap);
  }
}

.description {
  white-space: pre-wrap;
}
