import { SvgIcon } from '@mui/material';

export default function YoutubeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0337 5.38829C19.8087 5.6023 20.4177 6.2306 20.6251 7.0301H20.6236C21 8.47778 21 11.5 21 11.5C21 11.5 21 14.5222 20.6236 15.9699C20.4162 16.7694 19.8072 17.3977 19.0323 17.6117C17.6291 18 12 18 12 18C12 18 6.37093 18 4.96773 17.6117C4.19279 17.3977 3.5838 16.7694 3.37636 15.9699C3 14.5222 3 11.5 3 11.5C3 11.5 3 8.47778 3.37636 7.0301C3.5838 6.2306 4.19279 5.6023 4.96773 5.38829C6.37093 5 12 5 12 5C12 5 17.6291 5 19.0337 5.38829ZM14.8775 11.5L10.1997 8.71472V14.2853L14.8775 11.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
