.gridContainer {
  padding-right: 16px;
}

.grid {
  column-gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;

  @media (--mq-is-mobile) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
