import { usePreloadedQuery } from 'react-relay';

import { MPExpandable } from '@mp-frontend/core-components';

import GetNFTMetaDataTraitsType, {
  NFTMetadataTraitsQuery,
} from 'graphql/__generated__/NFTMetadataTraitsQuery.graphql';

import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import {
  WithLoadQueryPropsV1,
  withLoadQueryV1,
} from 'utils/hocs/withLoadQuery';

import ProductCollapsibleContainer from '../ProductCollapsibleContainer';
import AttributeItem from './ProductAttribute';

import * as styles from 'css/pages/product/ProductAttributes.module.css';

function ProductAttributes({
  queryRef,
}: WithLoadQueryPropsV1<NFTMetadataTraitsQuery>) {
  const traits = usePreloadedQuery<NFTMetadataTraitsQuery>(
    GetNFTMetaDataTraitsType,
    queryRef
  ).nftMetadata.nftTraits;

  return traits.length ? (
    <ProductCollapsibleContainer title="Attributes">
      <MPExpandable maxHeight={300}>
        <div className={styles.attributesListContainer}>
          {traits.map((attrs) => (
            <AttributeItem
              key={`${attrs.type}-${attrs.value}`}
              traits={attrs}
            />
          ))}
        </div>
      </MPExpandable>
    </ProductCollapsibleContainer>
  ) : null;
}

export default withDefaultErrorBoundary(
  withLoadQueryV1(ProductAttributes, GetNFTMetaDataTraitsType),
  { hideState: true }
);
