import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const UnlockableIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path d="M20.01 4.01001H4.01001V20.01H20.01V4.01001Z" fill="currentColor" />
    <path
      d="M19.5203 9.46712L9.47437 4.42554L4.43278 14.4714L14.4787 19.513L19.5203 9.46712Z"
      fill="white"
    />
    <path
      d="M16.7858 14.2054L14.4519 7.16907L7.41553 9.50294L9.7494 16.5393L16.7858 14.2054Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default UnlockableIcon;
