import { erc20Abi, Hash } from 'viem';
import { useReadContract } from 'wagmi';

import {
  ApprovalTypeEnum,
  CreatorContractType,
} from 'types/__generated__/graphql';

import DropSaleCoreApproval from './approvals/DropSaleCoreApproval';
import ManifoldAdminApproval from './approvals/ManifoldAdminApproval';
import TLAdminApproval from './approvals/TLAdminApproval';
import VaultCoreApproval from './approvals/VaultCoreApproval';

interface ContractProps {
  contract: CreatorContractType;
  walletAddress: Hash;
  className?: string;
}

export default function Contract({
  className,
  contract,
  walletAddress,
}: ContractProps) {
  const result = useReadContract({
    abi: erc20Abi,
    address: contract.address as Hash,
    functionName: 'name',
  });

  const { abi } = JSON.parse(contract.abidata);

  return (
    <>
      {contract.requireApprovalsOfContract.some(
        (approval) => approval === ApprovalTypeEnum.DropSaleCoreApproval
      ) &&
        abi.find((f) => f.name === 'tokenOperatorToCustodialAccounts') &&
        abi.find((f) => f.name === 'setOboApprovalForAll') && (
          <DropSaleCoreApproval
            className={className}
            contract={contract}
            contractTitle={result.data}
            walletAddress={walletAddress}
          />
        )}
      {contract.requireApprovalsOfContract.some(
        (approval) => approval === ApprovalTypeEnum.VaultCoreApproval
      ) &&
        abi.find((f) => f.name === 'isApprovedForAll') &&
        abi.find((f) => f.name === 'setApprovalForAll') && (
          <VaultCoreApproval
            className={className}
            contract={contract}
            contractTitle={result.data}
            walletAddress={walletAddress}
          />
        )}
      {!!contract.isManifold &&
        contract.requireApprovalsOfContract.some(
          (approval) => approval === ApprovalTypeEnum.AddAdminApproval
        ) && (
          <ManifoldAdminApproval
            className={className}
            contract={contract}
            contractTitle={result.data}
            walletAddress={walletAddress}
          />
        )}
      {!!contract.isTransientLabs &&
        contract.requireApprovalsOfContract.some(
          (approval) => approval === ApprovalTypeEnum.AddAdminApproval
        ) && (
          <TLAdminApproval
            className={className}
            contract={contract}
            contractTitle={result.data}
            walletAddress={walletAddress}
          />
        )}
    </>
  );
}
