import { SvgIcon } from '@mui/material';

export default function ArrowDownIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.42006 8.95804L12.0349 12.3416L15.6498 8.95804L17.0699 10.2872L12.0349 15L7 10.2872L8.42006 8.95804Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
