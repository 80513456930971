import { useMutation } from 'react-relay';

import LoggingFromFrontendMutation from '../graphql/__generated__/LoggingFromFrontendMutation.graphql';

const logSeen = {
  nft: {},
  transaction: {},
};

export default function useLogging() {
  const [loggingFromFrontendMutation] = useMutation(
    LoggingFromFrontendMutation
  );

  const logNFTException = (id, errorMessage) => {
    try {
      if (logSeen.nft[id]?.[errorMessage]) return;
      logSeen.nft[id] = logSeen.nft[id] || {};
      logSeen.nft[id][errorMessage] = true;
      loggingFromFrontendMutation({
        variables: {
          request_data: {
            isException: true,
            message: errorMessage,
            nftId: id,
          },
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e, errorMessage);
    }
  };

  const logTransactionException = (txid, errorMessage) => {
    try {
      if (logSeen.transaction[txid]?.[errorMessage]) return;
      logSeen.transaction[txid] = logSeen.nft[txid] || {};
      logSeen.transaction[txid][errorMessage] = true;
      loggingFromFrontendMutation({
        variables: {
          request_data: {
            isException: true,
            message: errorMessage,
            transactionId: txid,
          },
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e, errorMessage);
    }
  };

  return { logNFTException, logTransactionException };
}
