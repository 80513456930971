import { ReactNode } from 'react';

import { MPBackgroundColorClass, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

interface SectionProps {
  children: ReactNode;
  title: string;
  contentClassName?: string;
}

export default function Section({
  title,
  children,
  contentClassName,
}: SectionProps) {
  return (
    <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[20])}>
      <div className={MPFonts.headline4}>{title}</div>

      <div
        className={joinClasses(
          'commonPadding',
          MPBackgroundColorClass.CommonWhite,
          CSSGlobal.Flex.Col,
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
}
