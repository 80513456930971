import { HTMLAttributes, useContext, useEffect, useRef } from 'react';

import {
  MPAnimations,
  MPDrawer,
  MPDrawerProps,
  MPFonts,
  MPIconButton,
  MPIconButtonThemes,
  useIsMobile,
} from '@mp-frontend/core-components';
import { RemoveIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import { TrackingContext } from 'components/trackingContext';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';

import * as styles from 'css/components/panels/Panel.module.css';

interface PanelProps extends MPDrawerProps {
  actionIcons?: Array<any>;
  isNav?: boolean;
  title?: string;
  trackingTitle?: string;
  useDark?: boolean;
}

const defaultActionIcon = [];

function ActionIcon({ children, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div {...props}>{children}</div>;
}

export default function Panel({
  anchor,
  onClose,
  title,
  children,
  open,
  trackingTitle,
  isNav = false,
  actionIcons = defaultActionIcon,
  useDark = false,
  ...props
}: PanelProps) {
  const isMobile = useIsMobile();

  const eventListener = useRef(null);

  const isDjango = useContext(IsDjangoContext);

  useEffect(() => {
    // Unsure why, but within the django app, the first drawer opening causes the page to scroll to the bottom.
    if (!isDjango) return;
    if (open) {
      window.parent.document.removeEventListener(
        'scroll',
        eventListener.current
      );
      const { scrollTop } =
        window.parent.document.getElementsByTagName('html')[0];
      eventListener.current = () => {
        window.parent.document.getElementsByTagName('html')[0].scrollTop =
          scrollTop;
      };
      window.parent.document.addEventListener('scroll', eventListener.current);
      // eslint-disable-next-line consistent-return
      return () =>
        window.parent.document.removeEventListener(
          'scroll',
          eventListener.current
        );
    }
    window.parent.document.removeEventListener('scroll', eventListener.current);
  }, [open, isDjango]);

  return (
    <TrackingContext panel={trackingTitle}>
      <MPDrawer
        open={open}
        clipTop={isMobile && isNav ? 64 : undefined}
        anchor={anchor}
        ModalProps={{ disableScrollLock: true }}
        onClose={onClose}
        {...props}
      >
        <DefaultErrorBoundary>
          <div
            className={joinClasses(styles.panelBody, isNav ? styles.isNav : '')}
          >
            <div
              className={joinClasses(
                styles.header,
                styles.panelCell,
                anchor === 'right' ? styles.reverse : ''
              )}
            >
              {(!isNav || !isMobile || actionIcons.length > 0) && (
                <div className={styles.actionIconSection}>
                  {!(isMobile && isNav) && (
                    <MPIconButton
                      theme={
                        useDark
                          ? MPIconButtonThemes.DARK
                          : MPIconButtonThemes.DEFAULT
                      }
                    >
                      <RemoveIcon onClick={onClose as any} />
                    </MPIconButton>
                  )}
                  {actionIcons.map((icon) => (
                    <ActionIcon
                      key={icon.key}
                      className={joinClasses(
                        styles.actionIcon,
                        icon.props['data-action_wrapper_classname'] ??
                          joinClasses(
                            styles.backgroundDefault,
                            MPAnimations.Color.DarkToLight
                          )
                      )}
                      onClick={icon.props.onClick}
                    >
                      {icon}
                    </ActionIcon>
                  ))}
                </div>
              )}
              {!!title && (
                <div className={joinClasses(styles.title, MPFonts.headline4)}>
                  {title}
                </div>
              )}
            </div>
            <div
              className={joinClasses(
                styles.panelCell,
                title ? styles.showBorderTop : undefined
              )}
            >
              {children}
            </div>
          </div>
        </DefaultErrorBoundary>
      </MPDrawer>
    </TrackingContext>
  );
}
