/**
 * @generated SignedSource<<ebcb37dc5ac45f412cfead66b454cbe6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArtistSortTypeEnum = "TOTAL_SALES" | "TOTAL_CREATIONS" | "LATEST_CREATION" | "LAST_SOLD_AT" | "QUANT_SCORE";
export type SortOrderEnum = "DESC" | "ASC";
export type PaginatedExploreArtistsQuery$variables = {
  after?: string | null;
  artists?: ReadonlyArray<string | null> | null;
  first?: number | null;
  query?: string | null;
  sortOrder?: SortOrderEnum | null;
  sortType?: ArtistSortTypeEnum | null;
};
export type PaginatedExploreArtistsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExploreArtistsPaginatedFragment">;
};
export type PaginatedExploreArtistsQuery = {
  variables: PaginatedExploreArtistsQuery$variables;
  response: PaginatedExploreArtistsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "artists"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortOrder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "artists",
    "variableName": "artists"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "sortOrder"
  },
  {
    "kind": "Variable",
    "name": "sortType",
    "variableName": "sortType"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaginatedExploreArtistsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ExploreArtistsPaginatedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaginatedExploreArtistsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExploreArtistQuery",
        "kind": "LinkedField",
        "name": "artist",
        "plural": false,
        "selections": [
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "concreteType": "ArtistConnection",
            "kind": "LinkedField",
            "name": "artists",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ArtistEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAbstractType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "biography",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "followerCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profileImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TotalVolumeType",
                        "kind": "LinkedField",
                        "name": "totalVolume",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalVolumeInEth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalVolumeInUsd",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 3
                          }
                        ],
                        "concreteType": "NFTMetadataConnectionTypeConnection",
                        "kind": "LinkedField",
                        "name": "createdArtworks",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NFTMetadataConnectionTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "NFTMetadataAbstractType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "thumbnailImage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "createdArtworks(first:3)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StoreTypeV3",
                        "kind": "LinkedField",
                        "name": "store",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numOfOwners",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalItems",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "filters": [
              "artists",
              "query",
              "sortOrder",
              "sortType"
            ],
            "handle": "connection",
            "key": "PaginatedExploreArtistsQuery__results",
            "kind": "LinkedHandle",
            "name": "artists"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4901c2782acafcaaaf262838ab929b76",
    "id": null,
    "metadata": {},
    "name": "PaginatedExploreArtistsQuery",
    "operationKind": "query",
    "text": "query PaginatedExploreArtistsQuery(\n  $after: String\n  $artists: [String]\n  $first: Int\n  $query: String\n  $sortOrder: SortOrderEnum\n  $sortType: ArtistSortTypeEnum\n) {\n  ...ExploreArtistsPaginatedFragment\n}\n\nfragment ExploreArtistsPaginatedFragment on Query {\n  artist {\n    results: artists(first: $first, after: $after, artists: $artists, query: $query, sortOrder: $sortOrder, sortType: $sortType) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          biography\n          followerCount\n          fullName\n          id\n          pk\n          profileImageUrl\n          totalVolume {\n            totalVolumeInEth\n            totalVolumeInUsd\n          }\n          username\n          createdArtworks(first: 3) {\n            edges {\n              node {\n                id\n                pk\n                thumbnailImage\n              }\n            }\n          }\n          store {\n            numOfOwners\n            totalItems\n            id\n          }\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1c8f38bdcb77d047572fb37a389382d";

export default node;
