.productUnlockableBtn {
  margin-top: 16px;
}

.container {
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.shipping .address {
  display: block;

  &:not(.expanded) {
    padding-bottom: 200px;
  }
}

.payment .pricing {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.congratulations {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .icon {
    height: 72px;
    width: 72px;
  }

  & .title {
    font-size: 34px;
  }

  & .message {
    color: var(--color-SolidNeutralGray5);
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 250px;

    & a {
      color: var(--color-SolidNeutralGray5);

      &:hover {
        color: var(--color-commonBlack);
      }
    }
  }

  & .action {
    width: 235px;
  }
}
