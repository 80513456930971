/**
 * @generated SignedSource<<62e3a47be555b1a01317e92e035bcb0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagTypeEnum = "FEATURED" | "RECENT_BID" | "NEW_MINTS" | "ENDING_SOON" | "RECENTLY_SOLD" | "RANKED_AUCTION";
export type HomepageFeaturedNftsQuery$variables = {};
export type HomepageFeaturedNftsQuery$data = {
  readonly homepageFeaturedNfts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly contract: {
          readonly isExternal: boolean | null;
        } | null;
        readonly currentOwner: {
          readonly fullName: string | null;
          readonly id: string;
        } | null;
        readonly id: string;
        readonly listing: {
          readonly availableForPurchase: boolean | null;
          readonly hasReservePrice: boolean | null;
          readonly liveBid: {
            readonly bidInEther: number | null;
            readonly bidInUsd: number | null;
          } | null;
          readonly lastSale: {
            readonly usdPriceAtSale: number;
          } | null;
          readonly liveSale: {
            readonly custodialPriceEth: number | null;
            readonly custodialPriceUsd: number | null;
          } | null;
          readonly lowestAskInEth: number | null;
          readonly lowestAskInUsd: number | null;
          readonly productAuction: {
            readonly auctionEnded?: boolean | null;
            readonly endsAt?: any;
            readonly id?: string;
          } | null;
          readonly productSlug: string;
          readonly rankedAuction: {
            readonly auctionEnded?: boolean | null;
            readonly endsAt?: any | null;
            readonly highestBid?: {
              readonly bidInEther: number | null;
              readonly bidInUsd: number | null;
              readonly id: string;
            } | null;
            readonly id?: string;
          } | null;
          readonly reservePriceInEth: number | null;
          readonly reservePriceInUsd: number | null;
        } | null;
        readonly metadata: {
          readonly artworkImageUrl: string | null;
          readonly artworkVideoUrl: string | null;
          readonly author: {
            readonly fullName: string | null;
            readonly id: string;
            readonly store: {
              readonly storeSlug: string | null;
            } | null;
          } | null;
          readonly hasVideo: boolean | null;
          readonly highResImage: string | null;
          readonly id: string;
          readonly mediaMetadata: {
            readonly filesize: number | null;
            readonly height: number | null;
            readonly width: number | null;
          } | null;
          readonly rawfileExtension: string;
          readonly title: string;
          readonly totalSupply: number;
        } | null;
        readonly pk: string;
        readonly printEdition: number;
        readonly tag: TagTypeEnum;
      } | null;
    } | null>;
  } | null;
};
export type HomepageFeaturedNftsQuery = {
  variables: HomepageFeaturedNftsQuery$variables;
  response: HomepageFeaturedNftsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isExternal",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountAbstractType",
  "kind": "LinkedField",
  "name": "currentOwner",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableForPurchase",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasReservePrice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInEther",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bidInUsd",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usdPriceAtSale",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custodialPriceEth",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "custodialPriceUsd",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lowestAskInEth",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lowestAskInUsd",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auctionEnded",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/),
    (v2/*: any*/)
  ],
  "type": "NFTAuctionType",
  "abstractKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productSlug",
  "storageKey": null
},
v17 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v2/*: any*/)
],
v18 = {
  "kind": "InlineFragment",
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NFTRankedBidType",
      "kind": "LinkedField",
      "name": "highestBid",
      "plural": false,
      "selections": (v17/*: any*/),
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "type": "NFTRankedAuctionType",
  "abstractKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reservePriceInEth",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reservePriceInUsd",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "artworkImageUrl",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "artworkVideoUrl",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeSlug",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVideo",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highResImage",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filesize",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawfileExtension",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalSupply",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printEdition",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tag",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v36 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomepageFeaturedNftsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "homepageFeaturedNfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTEthereumContractType",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTListingAbstractType",
                    "kind": "LinkedField",
                    "name": "listing",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTBidType",
                        "kind": "LinkedField",
                        "name": "liveBid",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTPurchaseHistoryType",
                        "kind": "LinkedField",
                        "name": "lastSale",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTSaleType",
                        "kind": "LinkedField",
                        "name": "liveSale",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": "productAuction",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "auction",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      {
                        "alias": "rankedAuction",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "auction",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMetadataAbstractType",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountAbstractType",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoreTypeV3",
                            "kind": "LinkedField",
                            "name": "store",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTMediaMetadataType",
                        "kind": "LinkedField",
                        "name": "mediaMetadata",
                        "plural": false,
                        "selections": [
                          (v26/*: any*/),
                          (v27/*: any*/),
                          (v28/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomepageFeaturedNftsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NFTAbstractTypeConnection",
        "kind": "LinkedField",
        "name": "homepageFeaturedNfts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTAbstractTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NFTAbstractType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTEthereumContractType",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTListingAbstractType",
                    "kind": "LinkedField",
                    "name": "listing",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTBidType",
                        "kind": "LinkedField",
                        "name": "liveBid",
                        "plural": false,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTPurchaseHistoryType",
                        "kind": "LinkedField",
                        "name": "lastSale",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTSaleType",
                        "kind": "LinkedField",
                        "name": "liveSale",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": "productAuction",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "auction",
                        "plural": false,
                        "selections": [
                          (v35/*: any*/),
                          (v15/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v36/*: any*/),
                            "type": "NFTRankedAuctionType",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      {
                        "alias": "rankedAuction",
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "auction",
                        "plural": false,
                        "selections": [
                          (v35/*: any*/),
                          (v18/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v36/*: any*/),
                            "type": "NFTAuctionType",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMetadataAbstractType",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountAbstractType",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "StoreTypeV3",
                            "kind": "LinkedField",
                            "name": "store",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTMediaMetadataType",
                        "kind": "LinkedField",
                        "name": "mediaMetadata",
                        "plural": false,
                        "selections": [
                          (v26/*: any*/),
                          (v27/*: any*/),
                          (v28/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v34/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba735acb761760445c52c0d63fb068c4",
    "id": null,
    "metadata": {},
    "name": "HomepageFeaturedNftsQuery",
    "operationKind": "query",
    "text": "query HomepageFeaturedNftsQuery {\n  homepageFeaturedNfts {\n    edges {\n      node {\n        contract {\n          isExternal\n          id\n        }\n        currentOwner {\n          fullName\n          id\n        }\n        id\n        listing {\n          availableForPurchase\n          hasReservePrice\n          liveBid {\n            bidInEther\n            bidInUsd\n            id\n          }\n          lastSale {\n            usdPriceAtSale\n            id\n          }\n          liveSale {\n            custodialPriceEth\n            custodialPriceUsd\n            id\n          }\n          lowestAskInEth\n          lowestAskInUsd\n          productAuction: auction {\n            __typename\n            ... on NFTAuctionType {\n              auctionEnded\n              endsAt\n              id\n            }\n            ... on NFTRankedAuctionType {\n              id\n            }\n          }\n          productSlug\n          rankedAuction: auction {\n            __typename\n            ... on NFTRankedAuctionType {\n              auctionEnded\n              endsAt\n              highestBid {\n                bidInEther\n                bidInUsd\n                id\n              }\n              id\n            }\n            ... on NFTAuctionType {\n              id\n            }\n          }\n          reservePriceInEth\n          reservePriceInUsd\n          id\n        }\n        metadata {\n          artworkImageUrl\n          artworkVideoUrl\n          author {\n            fullName\n            id\n            store {\n              storeSlug\n              id\n            }\n          }\n          hasVideo\n          highResImage\n          id\n          mediaMetadata {\n            filesize\n            height\n            width\n            id\n          }\n          rawfileExtension\n          title\n          totalSupply\n        }\n        pk\n        printEdition\n        tag\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37ab008e61a7f6471541729a1c3ac4df";

export default node;
