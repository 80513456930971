import MPSVGIcon from './MPSVGIcon';

export default function NotifyBidAcceptedIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        d="M9.77822 9.77822L12 4L14.2218 9.77822L20 12L14.2218 14.2218L12 20L9.77822 14.2218L4 12L9.77822 9.77822Z"
        fill="currentColor"
      />
      <path
        d="M6.77332 4.88721L8.65943 6.77332L6.77332 8.65943L4.88721 6.77332L6.77332 4.88721Z"
        fill="currentColor"
      />
      <path
        d="M19.1287 17.2347L17.2426 15.3486L15.3564 17.2347L17.2426 19.1209L19.1287 17.2347Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
