import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { noop } from 'lodash';

import isDjangoContext from 'pages/navbar/IsDjangoContext';

const DEFAULT_BACKGROUND_COLOR = 'var(--color-backgroundDefault)';

type GlobalStyle = {
  reset: () => void;
  update: (value: string) => void;
  value: string;
};

interface GlobalStyleContextProps {
  bodyBackgroundColor: GlobalStyle;
}

const GlobalStyleContext = createContext<GlobalStyleContextProps>({
  bodyBackgroundColor: {
    reset: noop,
    update: noop,
    value: DEFAULT_BACKGROUND_COLOR,
  },
});

const useGlobalStyle = () => useContext(GlobalStyleContext);

function GlobalStyleProvider({ children }) {
  const isDjango = useContext(isDjangoContext);
  const [backgroundColor, setBackgroundColor] = useState(
    DEFAULT_BACKGROUND_COLOR
  );

  const updateBackgroundColor = useCallback(
    (value: string) => setBackgroundColor(value),
    []
  );

  const resetBackgroundColor = useCallback(
    () => updateBackgroundColor(DEFAULT_BACKGROUND_COLOR),
    [updateBackgroundColor]
  );

  useEffect(() => {
    const body = isDjango ? window.parent.document.body : document.body;
    body.style.backgroundColor = backgroundColor;

    return () => {
      body.style.backgroundColor = DEFAULT_BACKGROUND_COLOR;
    };
  }, [backgroundColor, isDjango]);

  const value = useMemo(
    (): GlobalStyleContextProps => ({
      bodyBackgroundColor: {
        reset: resetBackgroundColor,
        update: updateBackgroundColor,
        value: backgroundColor,
      },
    }),
    [backgroundColor, resetBackgroundColor, updateBackgroundColor]
  );

  return (
    <GlobalStyleContext.Provider value={value}>
      {children}
    </GlobalStyleContext.Provider>
  );
}

export { GlobalStyleProvider, useGlobalStyle };
