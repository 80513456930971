export default function hasDatePassed(date: string | Date): boolean {
  return date && new Date().getTime() >= new Date(date).getTime();
}

export function hasDateBetween(
  startDate: string | Date,
  endDate: string | Date
): boolean {
  return (
    startDate && endDate && hasDatePassed(startDate) && !hasDatePassed(endDate)
  );
}
