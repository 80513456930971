import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { noop } from 'lodash';
import { Hash } from 'viem';

import { MPActionButton } from '@mp-frontend/core-components';

import { CreatorContractType } from 'types/__generated__/graphql';

import { useSelectWalletConnectDialog } from 'components/wallet/SelectWalletConnectDialog';
import useManifoldContract from 'hooks/contracts/useManifoldContract';
import isWalletError from 'utils/errors/wallet';

import PendingApprovalsManager from '../wallet/wallets/ManageWalletDialog/Contracts/PendingApprovalsManager';

interface SetManifoldAdminButtonProps {
  contract: CreatorContractType;
  setError: Dispatch<SetStateAction<Error>>;
  walletAddress: Hash;
  onlyShowApproval?: boolean;
}

export default function SetManifoldAdminButton({
  contract,
  walletAddress,
  setError,
  onlyShowApproval = false,
}: SetManifoldAdminButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [pollVal, setPollVal] = useState(0);
  const [prevVal, setPrevVal] = useState<boolean>(true);

  const { useApproveAdmin, useRevokeAdmin } = useManifoldContract({
    abi: JSON.parse(contract.abidata).abi,
    account: walletAddress,
    contractAddress: contract.address as Hash,
  });

  const [isAdmin, refetch, isFetching] =
    PendingApprovalsManager.useReadManifoldAdminApproval(
      contract,
      walletAddress
    );

  // Linter automatically turns this into an arrow function.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPolling) {
      if (isAdmin === undefined || isAdmin === !prevVal) {
        if (!isFetching) {
          refetch?.();
        }
        const id = setTimeout(() => setPollVal((prev) => prev + 1), 1000);
        return () => clearTimeout(id);
      }
      setPrevVal(!prevVal);
      setIsPolling(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isPolling, pollVal, prevVal, refetch, isFetching]);

  const approveAdminManager = useApproveAdmin({
    adminAddress: contract.manifoldAdminAddress as Hash,
  });

  const revokeAdminManager = useRevokeAdmin({
    adminAddress: contract.manifoldAdminAddress as Hash,
  });

  const toggleManifoldAdminApprovalRef = useRef(noop);

  const [openSelectWalletDialog, selectWalletDialogJSX] =
    useSelectWalletConnectDialog(
      walletAddress,
      useCallback(() => toggleManifoldAdminApprovalRef.current(), [])
    );

  toggleManifoldAdminApprovalRef.current = useCallback(
    async (ev) => {
      ev?.preventDefault();
      if (isAdmin === undefined) return;
      const wasApproved = !isAdmin;
      try {
        setIsLoading(true);
        setError(undefined);
        await (isAdmin
          ? revokeAdminManager
          : approveAdminManager
        ).mutate.writeAsync();
        setPrevVal(wasApproved);
        setIsPolling(true);
      } catch (e) {
        if (
          isWalletError.ConnectorNotConnected(e) ||
          isWalletError.ConnectorAccountNotFoundError(e)
        ) {
          openSelectWalletDialog();
        } else {
          setError(e);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      approveAdminManager,
      revokeAdminManager,
      isAdmin,
      setError,
      openSelectWalletDialog,
    ]
  );

  return onlyShowApproval && isAdmin !== false ? null : (
    <>
      <MPActionButton
        variant="primary"
        fullWidth
        size="large"
        onClick={toggleManifoldAdminApprovalRef.current}
        isLoading={isLoading || isPolling || isAdmin === undefined}
      >
        {`${
          (isPolling ? !prevVal : isAdmin) ? 'Revoke' : 'Grant'
        } Admin Access`}
      </MPActionButton>
      {selectWalletDialogJSX}
    </>
  );
}
