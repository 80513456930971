import { useCallback } from 'react';

import { MPActionButton, MPChip } from '@mp-frontend/core-components';

import {
  AspectRatioEnum,
  EditionTypeEnum,
  MediaTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import { GENRES_FILTER_LABELS } from 'components/filters/GenresFilter';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import * as utils from 'utils/currency/generatePricing';

import useFiltersApplied from '../hooks/useFiltersApplied';
import { ArtworksFiltersState } from './Filters';

const generatePriceString = (value: string) =>
  utils.generatePriceString(parseFloat(value || '0'), CurrencyDisplayMode.USD, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

interface ChipsProps {
  filters: Partial<ArtworksFiltersState>;
  onChange: (state: Partial<ArtworksFiltersState>) => void;
  onReset: () => void;
}

export function ChipsList({ filters, onChange }: Omit<ChipsProps, 'onReset'>) {
  return (
    <>
      {!!filters.query && (
        <MPChip
          label={`Results for: "${filters.query}"`}
          onDelete={() => onChange({ query: '' })}
        />
      )}
      {!!filters.status[StatusEnum.BuyNow] && (
        <MPChip
          label="Listed For Sale"
          onDelete={() =>
            onChange({
              status: { ...filters.status, [StatusEnum.BuyNow]: false },
            })
          }
        />
      )}
      {!!filters.showFollowingArtists && (
        <MPChip
          label="Artists You Follow"
          onDelete={() => onChange({ showFollowingArtists: false })}
        />
      )}
      {!!filters.artists?.length && (
        <>
          {filters.artists.map((artist) => (
            <MPChip
              key={artist}
              label={artist}
              onDelete={() =>
                onChange({
                  artists: filters.artists.filter((a) => a !== artist),
                })
              }
            />
          ))}
        </>
      )}
      {!!filters.status[StatusEnum.InAuction] && (
        <MPChip
          label="In Auction"
          onDelete={() =>
            onChange({
              status: { ...filters.status, [StatusEnum.InAuction]: false },
            })
          }
        />
      )}

      {!!filters.status[StatusEnum.HasOffers] && (
        <MPChip
          label="Has Offers"
          onDelete={() =>
            onChange({
              status: { ...filters.status, [StatusEnum.HasOffers]: false },
            })
          }
        />
      )}

      {!!filters.editions[EditionTypeEnum.Single] && (
        <MPChip
          label="Uniques"
          onDelete={() =>
            onChange({ editions: { [EditionTypeEnum.Single]: false } })
          }
        />
      )}

      {!!filters.editions[EditionTypeEnum.Limited] && (
        <MPChip
          label="Limited Editions"
          onDelete={() =>
            onChange({ editions: { [EditionTypeEnum.Limited]: false } })
          }
        />
      )}

      {!!filters.price.min && !filters.price.max && (
        <MPChip
          label={`Min Price: ${generatePriceString(filters.price.min)}`}
          onDelete={() => onChange({ price: { ...filters.price, min: '' } })}
        />
      )}

      {!!filters.price.max && !filters.price.min && (
        <MPChip
          label={`Max Price: ${generatePriceString(filters.price.max)}`}
          onDelete={() => onChange({ price: { ...filters.price, max: '' } })}
        />
      )}

      {!!filters.price.min && !!filters.price.max && (
        <MPChip
          label={`Price: ${generatePriceString(
            filters.price.min
          )} - ${generatePriceString(filters.price.max)}`}
          onDelete={() => onChange({ price: { max: '', min: '' } })}
        />
      )}

      {!!filters.genres?.length && (
        <>
          {filters.genres.map((genre) => (
            <MPChip
              key={genre}
              label={GENRES_FILTER_LABELS[genre]}
              onDelete={() =>
                onChange({
                  genres: filters.genres.filter((g) => g !== genre),
                })
              }
            />
          ))}
        </>
      )}

      {!!filters.mediaTypes[MediaTypeEnum.Still] && (
        <MPChip
          label="Still"
          onDelete={() =>
            onChange({
              mediaTypes: {
                ...filters.mediaTypes,
                [MediaTypeEnum.Still]: false,
              },
            })
          }
        />
      )}

      {!!filters.mediaTypes[MediaTypeEnum.Motion] && (
        <MPChip
          label="Motion"
          onDelete={() =>
            onChange({
              mediaTypes: {
                ...filters.mediaTypes,
                [MediaTypeEnum.Motion]: false,
              },
            })
          }
        />
      )}

      {!!filters.aspectRatios[AspectRatioEnum.Landscape] && (
        <MPChip
          label="Landscape"
          onDelete={() =>
            onChange({
              aspectRatios: {
                ...filters.aspectRatios,
                [AspectRatioEnum.Landscape]: false,
              },
            })
          }
        />
      )}

      {!!filters.aspectRatios[AspectRatioEnum.Portrait] && (
        <MPChip
          label="Portrait"
          onDelete={() =>
            onChange({
              aspectRatios: {
                ...filters.aspectRatios,
                [AspectRatioEnum.Portrait]: false,
              },
            })
          }
        />
      )}

      {!!filters.aspectRatios[AspectRatioEnum.Square] && (
        <MPChip
          label="Square"
          onDelete={() =>
            onChange({
              aspectRatios: {
                ...filters.aspectRatios,
                [AspectRatioEnum.Square]: false,
              },
            })
          }
        />
      )}

      {!!filters.size.minHeight && (
        <MPChip
          label={`Min Height: ${filters.size.minHeight}px`}
          onDelete={() =>
            onChange({ size: { ...filters.size, minHeight: '' } })
          }
        />
      )}

      {!!filters.size.minWidth && (
        <MPChip
          label={`Min Width: ${filters.size.minWidth}px`}
          onDelete={() => onChange({ size: { ...filters.size, minWidth: '' } })}
        />
      )}
    </>
  );
}

export default function Chips({ filters, onChange, onReset }: ChipsProps) {
  const areFiltersApplied = useFiltersApplied(filters);
  const handleReset = useCallback(() => onReset(), [onReset]);

  return areFiltersApplied ? (
    <FilterScrollContainer>
      <FiltersGroup>
        <ChipsList filters={filters} onChange={onChange} />
        <MPActionButton
          className={CSSGlobal.Width.maxContent}
          variant="tertiary"
          onClick={handleReset}
        >
          Clear All
        </MPActionButton>
      </FiltersGroup>
    </FilterScrollContainer>
  ) : null;
}
