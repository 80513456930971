import {
  CoinbaseWalletIcon,
  MetaMaskIcon,
  WalletConnectIcon,
} from '@mp-frontend/core-components/icons';

import {
  COINBASE_LINK_URL,
  METAMASK_DEEP_LINK_URL,
} from 'constants/ExternalUrls';
import { ConnectorName, WalletId } from 'utils/jwt/walletUtils';

export const CONNECTOR_ICON_MAPPING: Record<WalletId, (props) => JSX.Element> =
  {
    [WalletId.Coinbase]: CoinbaseWalletIcon,
    [WalletId.CoinbaseSDK]: CoinbaseWalletIcon,
    [WalletId.Metamask]: MetaMaskIcon,
    [WalletId.MetamaskMobile]: MetaMaskIcon,
    [WalletId.MetamaskSDK]: MetaMaskIcon,
    [WalletId.WalletConnect]: WalletConnectIcon,
  } as const;

export const CONNECTOR_ICON_24PX_MAPPING: Record<WalletId, JSX.Element> = {
  [WalletId.Coinbase]: <CoinbaseWalletIcon />,
  [WalletId.CoinbaseSDK]: <CoinbaseWalletIcon />,
  [WalletId.Metamask]: <MetaMaskIcon />,
  [WalletId.MetamaskMobile]: <MetaMaskIcon />,
  [WalletId.MetamaskSDK]: <MetaMaskIcon />,
  [WalletId.WalletConnect]: <WalletConnectIcon />,
} as const;

export const CONNECTOR_NAME_MAPPING: Record<WalletId, ConnectorName> = {
  [WalletId.Coinbase]: ConnectorName.CoinbaseWallet,
  [WalletId.CoinbaseSDK]: ConnectorName.CoinbaseWallet,
  [WalletId.Metamask]: ConnectorName.MetaMask,
  [WalletId.MetamaskMobile]: ConnectorName.MetaMask,
  [WalletId.MetamaskSDK]: ConnectorName.MetaMask,
  [WalletId.WalletConnect]: ConnectorName.WalletConnect,
} as const;

export function getConnectorIcon(id: WalletId): (props) => JSX.Element {
  return CONNECTOR_ICON_MAPPING[id];
}

export function get24PxConnectorIcon(id: WalletId): JSX.Element {
  return CONNECTOR_ICON_24PX_MAPPING[id];
}

export function getConnectorName(id: WalletId): string {
  return CONNECTOR_NAME_MAPPING[id];
}

export function getConnectorLink(id: WalletId, action?: string): string {
  if (
    id === WalletId.Metamask ||
    id === WalletId.MetamaskMobile ||
    id === WalletId.MetamaskSDK
  )
    return `${METAMASK_DEEP_LINK_URL}${window.location.host}${
      window.location.pathname
    }${action ? `#${action}` : ''}`;
  if (id === WalletId.Coinbase)
    return `${COINBASE_LINK_URL}${window.location.host}${
      window.location.pathname
    }${action ? `#${action}` : ''}`;
  return '';
}
