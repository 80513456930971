import { ReactNode } from 'react';

import { MPActionButton } from '@mp-frontend/core-components';

import WalletActionButton from 'components/WalletActionButton';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

interface PaymentViewFooterProps {
  onSubmit: () => void;
  submitTitle: string;
  children?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  useWalletButton?: boolean;
}

export default function PaymentViewFooter({
  onSubmit,
  submitTitle,
  children = null,
  isDisabled = false,
  isLoading = false,
  useWalletButton = false,
}: PaymentViewFooterProps) {
  return (
    <div className={styles.footer}>
      {useWalletButton ? (
        <WalletActionButton
          fullWidth
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={isDisabled}
          warnIfAddressNotRegistered
          size="large"
        >
          {submitTitle}
        </WalletActionButton>
      ) : (
        <MPActionButton
          fullWidth
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={isDisabled}
          size="large"
        >
          {submitTitle}
        </MPActionButton>
      )}

      {children}
    </div>
  );
}
