import { SvgIconProps } from '@mui/material';

import {
  ArtstationIcon,
  BehanceIcon,
  DiscordIcon,
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  TwitterIcon,
  WebsiteIcon,
  YoutubeIcon,
} from '@mp-frontend/core-components/icons';

import { StoreLinkLinkType } from 'types/__generated__/graphql';

import {
  ARTSTATION_BASE_URL,
  BEHANCE_BASE_URL,
  DISCORD_USR_BASE_URL,
  FACEBOOK_BASE_URL,
  INSTAGRAM_BASE_URL,
  PINTEREST_BASE_URL,
  TWITTER_BASE_URL,
  YOUTUBE_CHANNEL_BASE_URL,
  YOUTUBE_USER_BASE_URL,
} from './ExternalUrls';

/**
 * This is a list of Social Media Links we display. This contains support for legacy
 * links and thus does not match the list of links we allow inputs for.
 */
const OrderedSocialMediaMap: Array<{
  Icon: (props: SvgIconProps) => JSX.Element;
  baseUrl: string;
  linkType: StoreLinkLinkType;
  tooltip: string;
}> = [
  {
    Icon: WebsiteIcon,
    baseUrl: '',
    linkType: StoreLinkLinkType.Website,
    tooltip: 'Personal Website',
  },
  {
    Icon: InstagramIcon,
    baseUrl: INSTAGRAM_BASE_URL,
    linkType: StoreLinkLinkType.Instagram,
    tooltip: 'Instagram',
  },
  {
    Icon: TwitterIcon,
    baseUrl: TWITTER_BASE_URL,
    linkType: StoreLinkLinkType.Twitter,
    tooltip: 'Twitter',
  },
  {
    Icon: DiscordIcon,
    baseUrl: DISCORD_USR_BASE_URL,
    linkType: StoreLinkLinkType.DiscordUser,
    tooltip: 'Discord',
  },
  {
    Icon: YoutubeIcon,
    baseUrl: YOUTUBE_CHANNEL_BASE_URL,
    linkType: StoreLinkLinkType.YoutubeChannel,
    tooltip: 'Youtube Channel',
  },
  {
    Icon: YoutubeIcon,
    baseUrl: YOUTUBE_USER_BASE_URL,
    linkType: StoreLinkLinkType.YoutubeUser,
    tooltip: 'Youtube Profile',
  },
  {
    Icon: FacebookIcon,
    baseUrl: FACEBOOK_BASE_URL,
    linkType: StoreLinkLinkType.Facebook,
    tooltip: 'Facebook',
  },
  {
    Icon: PinterestIcon,
    baseUrl: PINTEREST_BASE_URL,
    linkType: StoreLinkLinkType.Pinterest,
    tooltip: 'Pinterest',
  },
  {
    Icon: ArtstationIcon,
    baseUrl: ARTSTATION_BASE_URL,
    linkType: StoreLinkLinkType.Artstation,
    tooltip: 'Artstation',
  },
  {
    Icon: BehanceIcon,
    baseUrl: BEHANCE_BASE_URL,
    linkType: StoreLinkLinkType.Behance,
    tooltip: 'Behance',
  },
];

export default OrderedSocialMediaMap;
