import { useCallback, useState, useTransition } from 'react';
import { noop } from 'lodash';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPFonts,
  useMPConfirmDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountDeletePayoutWallet, {
  AccountDeletePayoutWalletMutation,
} from 'graphql/__generated__/AccountDeletePayoutWalletMutation.graphql';

import ErrorDisplay from 'components/Error';
import useSession, { useRefreshSession } from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';
import promisifyMutation from 'utils/promisifyMutation';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

function DeleteCoinbaseButton() {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const refreshSession = useRefreshSession();
  const [deleteCoinbaseMutation] =
    useMutation<AccountDeletePayoutWalletMutation>(AccountDeletePayoutWallet);
  const [isTransitioning, startTransition] = useTransition();

  const deleteCoinbase = useCallback(async () => {
    try {
      setError(undefined);
      setIsLoading(true);
      await promisifyMutation(deleteCoinbaseMutation)({});

      startTransition(refreshSession);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [deleteCoinbaseMutation, refreshSession]);

  const [, setDialogOpen, ConfirmDialog] = useMPConfirmDialog({
    content: (
      <>
        <div
          className={joinClasses(
            MPFonts.textSmallMedium,
            pageStyles.dialogBody
          )}
        >
          <ErrorDisplay
            className={CSSGlobal.TextAlign.Centered}
            error={error}
          />
          <div>Are you sure you want to disconnect your Coinbase Account?</div>
        </div>
      </>
    ),
    onCancel: noop,
    onConfirm: deleteCoinbase,
    title: 'Disconnect Coinbase Account',
  });

  return (
    <>
      <MPActionButton
        size="large"
        fullWidth
        variant="secondary"
        onClick={() => setDialogOpen(true)}
        isLoading={isLoading || isTransitioning}
      >
        Disconnect&nbsp;Coinbase&nbsp;Account
      </MPActionButton>
      {ConfirmDialog}
    </>
  );
}

export default function ConnectDigitalWalletSection() {
  const [error] = useState<Error>();
  const session = useSession();

  return (
    <>
      <ErrorDisplay error={error} />
      {!session.account.ethPayoutWallet && (
        <a href={session.coinbaseOauthUrl}>
          <MPActionButton size="large" fullWidth variant="primary">
            Connect&nbsp;Coinbase&nbsp;Account
          </MPActionButton>
        </a>
      )}
      {!!session.account.ethPayoutWallet &&
        (!session.account.ethPayoutWallet.coinbaseCustomerId ? (
          <div
            className={joinClasses(
              MPFonts.paragraphSmall,
              pageStyles.invalidActionInstructions
            )}
          >
            Disconnect your Digital Wallet if you want to use a Coinbase Account
          </div>
        ) : (
          <DeleteCoinbaseButton />
        ))}
    </>
  );
}
