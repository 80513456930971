import { RecoilState, useRecoilValue } from 'recoil';
import { graphQLSelector } from 'recoil-relay';

import ConcreteNFTContractRequest, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import RelayEnvironment from '../../../RelayEnvironment';

export const VaultCoreContractQuery: RecoilState<
  NFTContractQuery['response']['nftContract']
> = graphQLSelector({
  environment: RelayEnvironment,
  key: 'vaultCoreContract',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.VaultCore }),
});

export default function useVaultCoreContract() {
  return useRecoilValue(VaultCoreContractQuery);
}
