import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

export interface MPRadioGroupProps extends Omit<RadioGroupProps, 'options'> {
  options: Array<string | { label: string; value: string }>;
}

let radioSx;
let labelSx;

function MPRadioSelect({
  value,
  onChange,
  options,
  ...props
}: MPRadioGroupProps) {
  radioSx = radioSx || {
    '& .MuiSvgIcon-root': {
      fontSize: 'inherit',
    },
  };

  labelSx = labelSx || {
    '& .MuiFormControlLabel-label': {
      fontSize: 'inherit',
    },
    backgroundColor: 'var(--color-primaryP5)',
    border: '2px solid var(--color-primaryP80)',
    borderRadius: '6px',
    justifyContent: 'space-between',
    margin: 0,
    padding: '6px 6px 6px 15px',
  };

  return (
    <RadioGroup value={value ?? ''} onChange={onChange} {...props}>
      {options.map((option) => (
        <FormControlLabel
          key={typeof option === 'string' ? option : option.value}
          value={typeof option === 'string' ? option : option.value}
          control={<Radio sx={radioSx} />}
          label={typeof option === 'string' ? option : option.label}
          labelPlacement="start"
          sx={labelSx}
        />
      ))}
    </RadioGroup>
  );
}

export default MPRadioSelect;
