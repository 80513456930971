import { memo, useCallback, useMemo, useState } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import {
  ACCOUNT_NUMBER,
  BANK_NAME,
  RECIPIENT_ADDRESS,
  RECIPIENT_NAME,
  ROUTING_NUMBER,
} from 'constants/WireTransfer';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import copyToClipboard from 'utils/copyToClipboard';
import generatePriceString from 'utils/currency/generatePricing';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

interface WireTransferLineItemProps {
  label: string;
  value: string;
  buttonName?: string;
}

interface WireTransferProps {
  amount: number;
}

const WireTransferLineItem = memo(
  ({ label, value, buttonName }: WireTransferLineItemProps) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = useCallback(async () => {
      await copyToClipboard(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    }, [value]);

    return (
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[6])}>
        <div className={MPFonts.paragraphSmall}>{label}</div>
        <div
          className={joinClasses(
            MPFonts.textSmallMedium,
            styles.wireTransferItemValue
          )}
        >
          {value}
        </div>
        {!!buttonName && (
          <div>
            <MPActionButton
              onClick={handleCopy}
              size="small"
              variant="secondary"
            >
              {copied ? `Copied ${buttonName}` : `Copy ${buttonName}`}
            </MPActionButton>
          </div>
        )}
      </div>
    );
  }
);

interface WireTransferLineItemsProps {
  label: string;
  value: string;
  buttonName?: string;
}

function WireTransfer({ amount }: WireTransferProps) {
  const lineItems: WireTransferLineItemsProps[] = useMemo(
    () => [
      {
        label: 'USD Total',
        value: generatePriceString(amount, CurrencyDisplayMode.USD),
      },
      {
        buttonName: 'Routing Number',
        label: 'Bank Routing Number (ABA)',
        value: ROUTING_NUMBER,
      },
      {
        buttonName: 'Account Number',
        label: 'Account Number',
        value: ACCOUNT_NUMBER,
      },
      { label: 'Bank Name', value: BANK_NAME },
      { label: 'Recipient Name', value: RECIPIENT_NAME },
      {
        label: 'Recipient Address',
        value: RECIPIENT_ADDRESS,
      },
    ],
    [amount]
  );

  return (
    <div className={styles.wireTransferContainer}>
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
        {lineItems.map((item) => (
          <WireTransferLineItem
            key={item.label}
            label={item.label}
            value={item.value}
            buttonName={item.buttonName}
          />
        ))}
      </div>
    </div>
  );
}

export default WireTransfer;
