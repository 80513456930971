import { ChangeEvent, useCallback, useState, useTransition } from 'react';
import { useMutation } from 'react-relay';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import {
  MPActionButton,
  MPCheckbox,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountAddCreditCard, {
  AccountAddCreditCardMutation,
} from 'graphql/__generated__/AccountAddCreditCardMutation.graphql';

import CardElement from 'components/CardElement';
import ErrorDisplay from 'components/Error';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import promisifyMutation from 'utils/promisifyMutation';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

interface ManageCCDialogProps {
  cancel: () => void;
  invalidate: () => void;
  isOpen: boolean;
}

export default function AddNewCardDialog({
  isOpen,
  invalidate,
  cancel,
}: ManageCCDialogProps) {
  const [cardholderName, setCardholderName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [creditCardIsValid, setCreditCardIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>(undefined);

  const handleCardChange = useCallback(
    ({ complete, error: _error, empty }) =>
      setCreditCardIsValid(
        complete && !empty && _error?.type !== 'validation_error'
      ),
    [setCreditCardIsValid]
  );
  const stripe = useStripe();
  const elements = useElements();

  const [addCreditCard] =
    useMutation<AccountAddCreditCardMutation>(AccountAddCreditCard);

  const [, startTransition] = useTransition();

  const addNewCard = useCallback(async (): Promise<void> => {
    if (!stripe || !elements) return;
    try {
      setIsLoading(true);
      const token = await stripe.createToken(elements.getElement('card'), {});
      if (token.error) throw new Error(token.error?.message);
      await promisifyMutation(addCreditCard)({
        isDefault,
        name: cardholderName,
        tokenStr: token.token.id,
      });
      // Unfortunately, our backend call to stripe isn't fully updated yet =/
      startTransition(invalidate);
      cancel();
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    stripe,
    elements,
    invalidate,
    cardholderName,
    isDefault,
    addCreditCard,
    cancel,
  ]);

  return (
    <MPStandardDialog
      title="New Credit Card"
      open={isOpen}
      onClose={cancel}
      actionButton={
        <MPActionButton
          fullWidth
          size="large"
          disabled={!creditCardIsValid || !cardholderName}
          isLoading={isLoading}
          onClick={addNewCard}
        >
          Add Your Card
        </MPActionButton>
      }
    >
      <ErrorDisplay error={error} className={CSSGlobal.TextAlign.Centered} />
      <div
        className={joinClasses(
          MPFonts.textSmallSemiBold,
          pageStyles.formHeader
        )}
      >
        Credit Card
      </div>
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
        <MPStyledTextField
          placeholder="Name on Card"
          value={cardholderName}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setCardholderName(event.target.value)
          }
        />
        <CardElement onChange={handleCardChange} />
        <MPCheckbox
          isChecked={isDefault}
          label="Make this card your default credit card"
          name="isDefault"
          onChange={(e) => setIsDefault(!!e?.target?.checked)}
        />
      </div>
    </MPStandardDialog>
  );
}
