import { useEffect, useRef, useState } from 'react';

import hasDatePassed from 'utils/hasDatePassed';

export default function useHasDatePassed(endDate: Date): boolean {
  const [hasDatePassedValue, setHasDatePassedValue] = useState(
    hasDatePassed(endDate)
  );

  const timeout = useRef<number>(null);

  useEffect(() => {
    const checkIfPassed = () => {
      if (hasDatePassed(endDate)) {
        setHasDatePassedValue(true);
      } else {
        timeout.current = window.setTimeout(
          checkIfPassed,
          endDate.getTime() - new Date().getTime() + 1
        );
      }
    };

    timeout.current = window.setTimeout(
      checkIfPassed,
      endDate.getTime() - new Date().getTime()
    );

    return () => clearTimeout(timeout.current);
  }, [endDate, hasDatePassedValue]);

  return hasDatePassedValue;
}
