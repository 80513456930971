import { MPDropdownSelect } from '@mp-frontend/core-components';

import { GenreEnum } from 'types/__generated__/graphql';

export const GENRES_FILTER_LABELS = {
  [GenreEnum.AiArt]: 'AI',
  [GenreEnum.Animation]: 'Animation',
  [GenreEnum.GenerativeArt]: 'Generative',
  [GenreEnum.Illustration]: 'Illustration',
  [GenreEnum.Photography]: 'Photography',
  [GenreEnum.Phygitals]: 'Unlockables',
  [GenreEnum.X3DArt]: '3D Art',
};

const FILTER_CONFIG = {
  ...Object.fromEntries(
    [
      GenreEnum.GenerativeArt,
      GenreEnum.Illustration,
      GenreEnum.Photography,
      GenreEnum.X3DArt,
      GenreEnum.Animation,
      GenreEnum.AiArt,
      GenreEnum.Phygitals,
    ].map((genre) => [genre, GENRES_FILTER_LABELS[genre]])
  ),
};

export type GenresFilterValue = Array<GenreEnum>;

interface GenresFilterProps {
  onChange: (value: GenresFilterValue) => void;
  value: GenresFilterValue;
}

export default function GenresFilter({ value, onChange }: GenresFilterProps) {
  return (
    <MPDropdownSelect
      config={FILTER_CONFIG}
      selectionType="single"
      title="Genres"
      values={value}
      onChange={onChange}
    />
  );
}
