import { forwardRef } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const _AccessTimeIcon = forwardRef<any, Omit<MPSVGIconProps, 'IconClass'>>(
  (props, ref) => <MPSVGIcon {...props} ref={ref} IconClass={AccessTimeIcon} />
);

export default _AccessTimeIcon;
