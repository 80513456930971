/**
 * @generated SignedSource<<b87cc59d9fd129788e135d0e895d90dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ApprovalTypeEnum = "DIGITAL_MEDIA_SALE_CORE_APPROVAL" | "DROP_SALE_CORE_APPROVAL" | "VAULT_CORE_APPROVAL" | "ADD_ADMIN_APPROVAL";
export type SessionCreatorContractsQuery$variables = {};
export type SessionCreatorContractsQuery$data = {
  readonly creatorContracts: ReadonlyArray<{
    readonly abidata: string;
    readonly address: string;
    readonly isManifold: boolean;
    readonly isMakersplace: boolean;
    readonly isTransientLabs: boolean;
    readonly manifoldAdminAddress: string;
    readonly requireApprovalsOfContract: ReadonlyArray<ApprovalTypeEnum | null>;
  } | null> | null;
};
export type SessionCreatorContractsQuery = {
  variables: SessionCreatorContractsQuery$variables;
  response: SessionCreatorContractsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreatorContractType",
    "kind": "LinkedField",
    "name": "creatorContracts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "abidata",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isManifold",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMakersplace",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTransientLabs",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "manifoldAdminAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "requireApprovalsOfContract",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionCreatorContractsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionCreatorContractsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e0c174f1d3dbc94c38d7903f5bc3f1d2",
    "id": null,
    "metadata": {},
    "name": "SessionCreatorContractsQuery",
    "operationKind": "query",
    "text": "query SessionCreatorContractsQuery {\n  creatorContracts {\n    abidata\n    address\n    isManifold\n    isMakersplace\n    isTransientLabs\n    manifoldAdminAddress\n    requireApprovalsOfContract\n  }\n}\n"
  }
};
})();

(node as any).hash = "580cbb8710e472e5c7cbf364423bcbef";

export default node;
