import { SvgIcon } from '@mui/material';

export default function ArrowUpIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5799 15.042L11.9651 11.6584L8.35017 15.042L6.9301 13.7128L11.9651 9L17 13.7128L15.5799 15.042Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
