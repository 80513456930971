import { merge } from 'lodash';
import { buttonClasses, SxProps, Theme } from '@mui/material';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';

import MPButton, { MPButtonProps, MPButtonVariant } from './MPButton';

export type MPActionButtonVariant =
  | 'primary'
  | 'primary-premium'
  | 'secondary'
  | 'secondary-black'
  | 'secondary-black-inverted'
  | 'tertiary'
  | 'tertiary-inverted'
  | 'tertiary-custom';

export interface MPActionButtonProps extends Omit<MPButtonProps, 'variant'> {
  /**
   * Allowed variants
   */
  variant?: MPActionButtonVariant;
}

const variantMapProps: {
  [key in MPActionButtonVariant]: {
    sx: SxProps<Theme>;
    variant: MPButtonVariant;
  };
} = {
  primary: {
    sx: {
      '&:active': {
        backgroundColor: MPColorValue.SolidNeutralGray1,
        boxShadow: 'none',
      },
      '&:disabled': {
        backgroundColor: MPColorValue.SolidNeutralGray1,
        boxShadow: 'none',
      },
      '&:hover': {
        backgroundColor: MPColorValue.SolidNeutralGray5,
        boxShadow: 'none',
      },
      borderRadius: '0px',
      boxShadow: 'none',
      color: MPColorValue.CommonWhite,
      maxHeight: '36px',
    },
    variant: 'contained',
  },
  'primary-premium': {
    sx: {},
    variant: 'contained',
  },
  secondary: {
    sx: {
      '&:active': {
        backgroundColor: 'initial',
        borderColor: MPColorValue.SolidNeutralGray1,
        color: MPColorValue.SolidNeutralGray1,
      },
      '&:disabled': {
        backgroundColor: 'initial',
        borderColor: MPColorValue.SolidNeutralGray1,
        color: MPColorValue.SolidNeutralGray1,
      },
      '&:hover': {
        backgroundColor: 'initial',
        borderColor: MPColorValue.SolidNeutralGray3,
        color: MPColorValue.SolidNeutralGray3,
      },
      backgroundColor: 'initial',
      borderColor: MPColorValue.CommonBlack,
      borderRadius: '100px',
      color: MPColorValue.CommonBlack,
      maxHeight: '36px',
    },
    variant: 'outlined',
  },
  'secondary-black': {
    sx: {},
    variant: 'outlined',
  },
  'secondary-black-inverted': {
    sx: {},
    variant: 'outlined',
  },
  tertiary: {
    sx: {
      '&:active': {
        backgroundColor: 'initial',
        borderColor: MPColorValue.SolidNeutralGray1,
        color: MPColorValue.SolidNeutralGray1,
      },
      '&:hover': {
        backgroundColor: 'initial',
        borderColor: MPColorValue.SolidNeutralGray3,
        color: MPColorValue.SolidNeutralGray3,
      },
      backgroundColor: 'initial',
      borderColor: MPColorValue.CommonBlack,
      borderRadius: '100px',
      color: MPColorValue.CommonBlack,
      maxHeight: '29px',
      padding: '8px 16px',
      width: 'min-content',
    },
    variant: 'outlined',
  },
  'tertiary-custom': {
    sx: {
      '&:active': {
        borderColor: `var(--actionButtonColor-active, --actionButtonColor)`,
        color: `var(--actionButtonColor-active, --actionButtonColor)`,
      },
      '&:hover': {
        borderColor: `var(--actionButtonColor-hover, --actionButtonColor)`,
        color: `var(--actionButtonColor-hover, --actionButtonColor)`,
      },
      backgroundColor: 'transparent',
      borderColor: `var(--actionButtonColor)`,
      borderRadius: '100px',
      color: `var(--actionButtonColor)`,
      maxHeight: '29px',
      padding: '8px 16px',
      width: 'min-content',
    },
    variant: 'outlined',
  },
  'tertiary-inverted': {
    sx: {
      '&:active': {
        backgroundColor: MPColorValue.SolidNeutralGray1,
        borderColor: MPColorValue.SolidNeutralGray1,
      },
      '&:hover': {
        backgroundColor: MPColorValue.SolidNeutralGray3,
        borderColor: MPColorValue.SolidNeutralGray3,
      },
      backgroundColor: MPColorValue.CommonBlack,
      borderColor: MPColorValue.CommonBlack,
      borderRadius: '100px',
      color: MPColorValue.CommonWhite,
      maxHeight: '29px',
      padding: '8px 16px',
      width: 'min-content',
    },
    variant: 'outlined',
  },
};
variantMapProps['primary-premium'] = merge({}, variantMapProps.primary, {
  sx: {
    '&:active': {
      backgroundColor: MPColorValue.GoldP20,
    },
    '&:hover': {
      backgroundColor: MPColorValue.GoldP60,
    },
    backgroundColor: MPColorValue.GoldMain,
  },
});
variantMapProps['secondary-black'] = merge({}, variantMapProps.secondary, {
  sx: {
    '&:active': {
      backgroundColor: MPColorValue.CommonBlack,
      borderColor: MPColorValue.SolidNeutralGray5,
      color: MPColorValue.SolidNeutralGray5,
    },
    '&:hover': {
      backgroundColor: MPColorValue.CommonBlack,
      borderColor: MPColorValue.SolidNeutralGray3,
      color: MPColorValue.SolidNeutralGray3,
    },
    backgroundColor: MPColorValue.CommonBlack,
    borderColor: MPColorValue.CommonWhite,
    color: MPColorValue.CommonWhite,
  },
});
variantMapProps['secondary-black-inverted'] = merge(
  {},
  variantMapProps.secondary,
  {
    sx: {
      '&:active': {
        backgroundColor: MPColorValue.SolidNeutralGray5,
        borderColor: MPColorValue.SolidNeutralGray5,
        color: MPColorValue.CommonBlack,
      },
      '&:hover': {
        backgroundColor: MPColorValue.SolidNeutralGray3,
        borderColor: MPColorValue.SolidNeutralGray3,
        color: MPColorValue.CommonBlack,
      },
      backgroundColor: MPColorValue.CommonWhite,
      borderColor: MPColorValue.CommonWhite,
      color: MPColorValue.CommonBlack,
    },
  }
);

const baseSx = {
  textTransform: 'unset',
  transition:
    'color 300ms var(--mp-tense-out), background-color 300ms var(--mp-tense-out), border-color 300ms var(--mp-tense-out)',
  [`& > .${buttonClasses.startIcon}`]: {
    marginLeft: '-8px',
    marginRight: '0',
  },
  [`& > .${buttonClasses.endIcon}`]: {
    marginLeft: '0',
    marginRight: '-8px',
  },
};

export default function MPActionButton({
  children,
  variant = 'primary',
  sx,
  ...passedProps
}: MPActionButtonProps) {
  const { sx: variantSx, variant: variantType } = variantMapProps[variant];
  const mergedSx = merge({}, baseSx, variantSx, sx ?? {});

  return (
    <MPButton {...passedProps} sx={mergedSx} variant={variantType}>
      {children}
    </MPButton>
  );
}
