import { Dispatch, SetStateAction, startTransition, useState } from 'react';
import { useMutation } from 'react-relay';

import { MPActionButton } from '@mp-frontend/core-components';

import DelistSaleOrReservePriceMutation from 'graphql/__generated__/ListingDelistSaleOrReservePriceMutation.graphql';

import useLogging from 'hooks/useLogging';
import { NFTType } from 'types/graphql/NFT';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';

interface CustodialDelistButtonProps {
  children: any;
  nft: NFTType;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

export default function CustodialDelistButton({
  nft,
  setOwnershipState,
  children,
}: CustodialDelistButtonProps) {
  const { logNFTException } = useLogging();
  const [
    isValidatingCancelSaleTransaction,
    setIsValidatingCancelSaleTransaction,
  ] = useState<boolean>(false);

  const [commitDelistWithCustodialWalletMutation] = useMutation(
    DelistSaleOrReservePriceMutation
  );

  const delistWithCustodialWallet = ({ productId }) =>
    new Promise((resolve, reject) => {
      commitDelistWithCustodialWalletMutation({
        onCompleted: (data: any) => {
          resolve(data);
        },
        onError(error) {
          reject(error);
        },
        variables: {
          request_data: {
            productId,
          },
        },
      });
    });

  const delistTokenCustodial = async () => {
    try {
      setIsValidatingCancelSaleTransaction(true);

      await delistWithCustodialWallet({
        productId: parseInt(nft.listing.pk, 10),
      });

      setIsValidatingCancelSaleTransaction(false);
      startTransition(() => {
        setOwnershipState(ProductOwnerStates.DELISTED);
      });
    } catch (error) {
      setIsValidatingCancelSaleTransaction(false);
      logNFTException(nft.pk, error);
    }
  };

  return (
    <MPActionButton
      fullWidth
      onClick={delistTokenCustodial}
      disabled={isValidatingCancelSaleTransaction}
      size="large"
    >
      {children}
    </MPActionButton>
  );
}
