import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const LockIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M18.4001 11.09H17.742C17.742 11.09 17.742 11.0817 17.742 11.0734V9.29473C17.742 9.29473 17.742 9.22854 17.742 9.20372V9.18718C17.6022 6.2999 15.1096 4 12.0495 4C8.9893 4 6.40627 6.35781 6.32401 9.29473V11.0734C6.32401 11.0734 6.32401 11.0817 6.32401 11.09H5.6001V20.0083H18.4001V11.09ZM12.8309 13.696V17.3361H11.2351V13.696H12.8309ZM12.033 5.87797C14.0402 5.87797 15.6608 7.38366 15.743 9.27818V11.0817H8.32298V9.26991C8.41347 7.38366 10.034 5.88625 12.033 5.88625V5.87797Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default LockIcon;
