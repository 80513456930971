import { ReactNode } from 'react';
import { merge } from 'lodash';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';

import { MPColorValue, MPFonts } from '../..';
import { CircleIcon } from '../../icons';

interface MPCheckboxProps {
  isChecked: boolean;
  label: ReactNode;
  name: string;
  onChange: CheckboxProps['onChange'];
  classes?: FormControlLabelProps['classes'];
  disabled?: boolean;
  sx?: CheckboxProps['sx'];
}

export default function MPCheckbox({
  name,
  isChecked,
  onChange,
  sx,
  label,
  disabled,
  classes = {},
}: MPCheckboxProps) {
  const labelSx = {
    '& .Mui-disabled.MuiCheckbox-root': {
      borderColor: MPColorValue.SolidNeutralGray1,
    },
    '& .MuiCheckbox-root': {
      borderColor: MPColorValue.SolidNeutralGray3,
      borderRadius: '0px',
      borderStyle: 'solid',
      borderWidth: '1px',
      boxSizing: 'border-box',
      padding: '0px',
    },
    '& .MuiCheckbox-root:hover': {
      borderColor: MPColorValue.SolidNeutralGray3,
      borderWidth: '2px',
    },
    gap: '8px',
    margin: '0px',
  };
  const checkboxSx = merge(
    {
      '& .PrivateSwitchBase-input': {
        padding: '0px',
      },
      '&.Mui-disabled': {
        color: MPColorValue.SolidNeutralGray1,
      },
      color: MPColorValue.SolidNeutralGray3,
      height: '16px',
      padding: '0px',
      position: 'relative',
      width: '16px',
    },
    sx ?? {}
  );
  const iconSx = {
    height: '10px',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '10px',
  };

  const mergedClasses = merge(
    {
      label: MPFonts.textSmallRegular,
    },
    classes
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          sx={checkboxSx}
          name={name}
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
          icon={<div />}
          checkedIcon={<CircleIcon sx={iconSx} />}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        />
      }
      label={label}
      classes={mergedClasses}
      sx={labelSx}
    />
  );
}
