import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

const CreatorsRoute = (
  <Route path="creators" element={<SlashRedirect />}>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default CreatorsRoute;
