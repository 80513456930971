/**
 * @generated SignedSource<<621d6911d3ddcb9c7d5e756d35f6954a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SessionLogoutMutation$variables = {};
export type SessionLogoutMutation$data = {
  readonly logout: {
    readonly success: boolean | null;
  } | null;
};
export type SessionLogoutMutation = {
  variables: SessionLogoutMutation$variables;
  response: SessionLogoutMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LogoutMutation",
    "kind": "LinkedField",
    "name": "logout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionLogoutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionLogoutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "793147767e8acdbaf236412a50cc008c",
    "id": null,
    "metadata": {},
    "name": "SessionLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation SessionLogoutMutation {\n  logout {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "91f382c6d3ddab1d8786be960eff055a";

export default node;
