import { startTransition, Suspense, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useAccount } from 'wagmi';
import { dialogClasses } from '@mui/material';

import {
  MPActionButton,
  MPDialog,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  BidAcceptIcon,
  PurchasedArtworkIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import WalletActionButton from 'components/WalletActionButton';
import useTokenReadContract from 'hooks/contracts/useTokenReadContract';
import useProductAcceptBid from 'hooks/product/useProductAcceptBid';
import useProductRejectBid from 'hooks/product/useProductRejectBid';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import { NFTType } from 'types/graphql/NFT';
import IsDigitalSaleCoreContractError from 'utils/errors/contracts/DigialMediaSaleCore';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import { HexString } from 'utils/jwt/walletUtils';
import { generateTweetText } from 'utils/nftUtils';
import toNumericShorthand from 'utils/numericShorthand';

import ProductPendingOnChain from '../ProductPendingOnChain';
import SuccessView from '../purchaseOfferDialog/SuccessView';
import ProductOwnerApprove from './ProductOwnerApprove';
import ProductOwnerMessage from './ProductOwnerMessage';

import * as styles from '../../../css/pages/product/ProductAcceptOrRejectBid.module.css';

enum ProductBidStates {
  ACCEPTED = 'ACCEPTED',
  PENDING_ACCEPT = 'PENDING_ACCEPT',
  PENDING_APPROVAL_ALL = 'PENDING_APPROVAL_ALL',
  PENDING_APPROVAL_CC = 'PENDING_APPROVAL_CC',
  PENDING_CHAIN_APPROVAL_ALL = 'PENDING_CHAIN_APPROVAL_ALL',
  PENDING_REJECT = 'PENDING_REJECT',
  PROCESSING_ETH_ACCEPT = 'PROCESSING_ETH_ACCEPT',
  REJECTED = 'REJECTED',
}

const MODAL_TITLES = {
  [ProductBidStates.PENDING_APPROVAL_ALL]: 'Approval',
  [ProductBidStates.PENDING_APPROVAL_CC]: 'Approval',
  [ProductBidStates.PENDING_CHAIN_APPROVAL_ALL]: 'Approval Processing',
  [ProductBidStates.PENDING_ACCEPT]: 'Accept Offer',
  [ProductBidStates.PENDING_REJECT]: 'Reject Offer',
  [ProductBidStates.REJECTED]: 'Reject Offer',
  [ProductBidStates.ACCEPTED]: 'You’ve Sold an Artwork',
  [ProductBidStates.PROCESSING_ETH_ACCEPT]: 'Accept Offer',
};

const APPROVAL_MESSAGE =
  'In order to accept this offer, we require your approval to grant our smart contract the right to transfer your artwork to the buyer.';

const ACCEPT_BID_MESSAGE = 'Are you sure you want to accept this offer?';
const REJECT_BID_MESSAGE = 'Are you sure you want to reject this offer?';

interface ProductAcceptOrRejectBidProps {
  approvalRegistryContract: NFTContractQuery$data['nftContract'];
  invalidate: () => void;
  nft: NFTType;
  transferCoreContract: NFTContractQuery$data['nftContract'];
}

function ProductAcceptOrRejectBid({
  invalidate,
  nft,
  approvalRegistryContract,
  transferCoreContract,
}: ProductAcceptOrRejectBidProps) {
  const isMobile = useIsMobile();
  const { address: currentAddress } = useCachedAccount();
  const [currentTransactionHash, setCurrentTransactionHash] =
    useState<string>(null);
  const [currentBidState, setCurrentBidState] =
    useState<ProductBidStates>(null);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const { address: currentUserAddress } = useAccount();
  const currentBid = nft.listing.liveBid;
  const { isCustodialOwner } = nft;
  const isCCBid = currentBid?.isCcBid;
  const acceptBidPriceText = isCCBid
    ? `$${toNumericShorthand(currentBid.bidInUsd)}`
    : `${toNumericShorthand(currentBid.bidInEther)} ETH`;

  const timeAgo = moment(currentBid.createdAt).fromNow();
  const hideBidAcceptReject =
    (currentBid.isGlobalBid && currentBid.isGlobalBidIgnored) ||
    nft.isOnHold ||
    !currentBid.isBidConfirmed;

  const tokenContractAddress = nft.contract.address;
  const tokenContractABI = JSON.parse(nft.contract.abidata).abi;

  const { useIsApprovedForAll } = useTokenReadContract({
    abi: tokenContractABI,
    contractAddress: tokenContractAddress as HexString,
  });

  const {
    data: isSaleContractApprovedByUser,
    isFetching: isSaleContractApprovalFetching,
    isRefetching: isSaleContractApprovalRefetching,
    refetch: saleContractApprovalRefetch,
  } = useIsApprovedForAll({
    operatorAddress: currentBid.contract.address,
    owner: currentUserAddress,
  });

  const needApproveAll =
    !isCustodialOwner &&
    !isSaleContractApprovedByUser &&
    !isSaleContractApprovalFetching &&
    !isSaleContractApprovalRefetching &&
    !currentBid.isCcBid;

  const [needApproveAllForAccept, setNeedApproveAllForAccept] =
    useState<boolean>(true);

  useEffect(() => {
    setNeedApproveAllForAccept(needApproveAll);
  }, [needApproveAll]);

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const {
    handleAcceptBidCustodial,
    handleAcceptBidPersonalWallet,
    handleAcceptBidPersonalWalletError,
  } = useProductAcceptBid({
    nft,
    setCurrentTransactionHash,
    setShowDialog,
    transferCoreContract,
  });

  const {
    handleRejectBidCustodial,
    handleRejectBidPersonalWallet,
    handleRejectBidPersonalWalletError,
  } = useProductRejectBid({
    invalidate,
    nft,
    setCurrentTransactionHash,
    setShowDialog,
  });

  const handleRejectBid = async (): Promise<void> => {
    setIsValidating(true);
    const success = isCustodialOwner
      ? await handleRejectBidCustodial()
      : await handleRejectBidPersonalWallet();
    if (success) {
      setCurrentBidState(ProductBidStates.REJECTED);
    }
    setIsValidating(false);
  };

  const handleAcceptBid = async (): Promise<void> => {
    setIsValidating(true);
    if (isCustodialOwner) {
      handleAcceptBidCustodial();
      setCurrentBidState(ProductBidStates.ACCEPTED);
      setShowDialog(false);
      setShowSuccessDialog(true);
    } else if (needApproveAllForAccept && !showDialog) {
      setCurrentBidState(ProductBidStates.PENDING_APPROVAL_ALL);
    } else if (!isCustodialOwner && currentBid.isCcBid && !showDialog) {
      setCurrentBidState(ProductBidStates.PENDING_APPROVAL_CC);
    } else {
      const success = await handleAcceptBidPersonalWallet();
      if (success) {
        setCurrentBidState(ProductBidStates.PROCESSING_ETH_ACCEPT);
      }
    }
    setIsValidating(false);
  };

  const handleETHAcceptPersonalSync = () => {
    // Check if the contract was approved.
    saleContractApprovalRefetch().then((response) => {
      const isApprovedAll = response.data;
      // if approved then fire the accept bid transaction.
      if (isApprovedAll) {
        handleAcceptBidPersonalWallet().then((success) => {
          if (success) {
            setCurrentBidState(ProductBidStates.PROCESSING_ETH_ACCEPT);
          }
        });
      }
    });
  };

  const handlePostSuccessOnChain = () => {
    startTransition(() => {
      setCurrentTransactionHash(null);
      setShowDialog(false);
      const isPendingAccept =
        currentBidState === ProductBidStates.PROCESSING_ETH_ACCEPT ||
        currentBidState === ProductBidStates.PENDING_ACCEPT;
      if (currentBidState === ProductBidStates.PENDING_CHAIN_APPROVAL_ALL) {
        handleETHAcceptPersonalSync();
      } else if (isPendingAccept) {
        setCurrentBidState(ProductBidStates.ACCEPTED);
        setShowSuccessDialog(true);
      } else {
        invalidate();
      }
    });
  };

  const closeSuccessDialog = () => {
    setCurrentBidState(null);
    invalidate();
  };

  const updateProductBidState = (state: ProductBidStates) => {
    setCurrentBidState(state);
    setShowDialog(true);
  };

  const showCCApprovalConfirmation =
    currentBid.isCcBid &&
    !isCustodialOwner &&
    !currentTransactionHash &&
    currentBidState === ProductBidStates.PENDING_ACCEPT;
  const showApproveAllConfirmation =
    needApproveAll &&
    !currentTransactionHash &&
    currentBidState === ProductBidStates.PENDING_ACCEPT;

  const canAcceptBid =
    currentBidState === ProductBidStates.PENDING_ACCEPT &&
    !showCCApprovalConfirmation &&
    !showApproveAllConfirmation;
  const canRejectBid = currentBidState === ProductBidStates.PENDING_REJECT;

  if (hideBidAcceptReject) {
    return null;
  }

  const currentBidAddress = currentBid.bidderAddress;
  const bidderAddressOrName =
    currentBidAddress || currentBid.bidderUser?.fullName;

  const acceptBidErrorMessage = isCustodialOwner
    ? undefined
    : IsDigitalSaleCoreContractError.acceptBid.getMessage(
        handleAcceptBidPersonalWalletError,
        nft.currentOwnerAddress
      );
  const rejectBidErrorMessage = isCustodialOwner
    ? undefined
    : IsDigitalSaleCoreContractError.rejectBid.getMessage(
        handleRejectBidPersonalWalletError,
        currentAddress,
        nft.currentOwnerAddress
      );

  return (
    <div className={styles.productBidContainer}>
      <div className={styles.productBidGrid}>
        <div>
          <BidAcceptIcon className={styles.productBidAcceptIcon} />
        </div>
        <div>
          <div
            className={joinClasses(
              MPFonts.paragraphNormal,
              styles.productAcceptBidTitle
            )}
          >
            You&apos;ve Received a Bid
          </div>
          <div
            className={joinClasses(
              MPFonts.paragraphNormal,
              styles.productOfferTxt
            )}
          >
            {generateFormattedUserFullName(bidderAddressOrName)} placed a bid on
            this artwork for {acceptBidPriceText}
          </div>
          <div
            className={joinClasses(
              MPFonts.paragraphSmall,
              styles.productBidTimeAgo
            )}
          >
            {timeAgo}
          </div>
        </div>
      </div>
      <div className={styles.productOfferCTAContainer}>
        {isCustodialOwner ? (
          <>
            <MPActionButton
              color="gold"
              style={{ color: 'var(--color-commonBlack)' }}
              onClick={() =>
                updateProductBidState(ProductBidStates.PENDING_ACCEPT)
              }
              fullWidth
            >
              Accept
            </MPActionButton>
            <MPActionButton
              className={styles.productOfferRejectCTA}
              variant="secondary"
              fullWidth
              onClick={() =>
                updateProductBidState(ProductBidStates.PENDING_REJECT)
              }
            >
              Reject
            </MPActionButton>
          </>
        ) : (
          <>
            <WalletActionButton
              color="gold"
              style={{ color: 'var(--color-commonBlack)' }}
              onClick={() =>
                updateProductBidState(ProductBidStates.PENDING_ACCEPT)
              }
              successOnConnection={() =>
                updateProductBidState(ProductBidStates.PENDING_ACCEPT)
              }
              fullWidth
            >
              Accept
            </WalletActionButton>
            <WalletActionButton
              className={styles.productOfferRejectCTA}
              variant="secondary"
              fullWidth
              onClick={() =>
                updateProductBidState(ProductBidStates.PENDING_REJECT)
              }
              successOnConnection={() =>
                updateProductBidState(ProductBidStates.PENDING_REJECT)
              }
            >
              Reject
            </WalletActionButton>
          </>
        )}
      </div>
      {!!showDialog && (
        <MPDialog
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
          }}
          title={MODAL_TITLES[currentBidState]}
          sx={{
            [`& .${dialogClasses.paper}`]: {
              height: 'fit-content',
              maxWidth: isMobile ? '100%' : 'var(--mp-defaultDialogWidth)',
            },
            [`& .${dialogClasses.container}`]: {
              alignItems: isMobile ? 'flex-end' : 'center',
            },
          }}
        >
          {!!showCCApprovalConfirmation && (
            <ProductOwnerMessage
              isWalletActionButton
              message={ACCEPT_BID_MESSAGE}
              onClick={handleAcceptBid}
              primaryCTAName="Confirm"
            />
          )}
          {!!showApproveAllConfirmation && (
            <ProductOwnerApprove
              nft={nft}
              approvalRegistryContract={approvalRegistryContract}
              currentTransactionHash={currentTransactionHash}
              setCurrentTransactionHash={setCurrentTransactionHash}
              saleContract={currentBid.contract}
              saleContractApprovalRefetch={saleContractApprovalRefetch}
              invalidate={() =>
                setCurrentBidState(ProductBidStates.PENDING_CHAIN_APPROVAL_ALL)
              }
              message={APPROVAL_MESSAGE}
            />
          )}
          {!!canAcceptBid && (
            <ProductOwnerMessage
              isWalletActionButton={!isCustodialOwner}
              message={ACCEPT_BID_MESSAGE}
              onClick={handleAcceptBid}
              primaryCTAName="Confirm"
              simulationErrorMessage={acceptBidErrorMessage}
              ctaDisabled={isValidating}
              isMessageCentered
            />
          )}
          {!!canRejectBid && (
            <ProductOwnerMessage
              isWalletActionButton={!isCustodialOwner}
              message={REJECT_BID_MESSAGE}
              simulationErrorMessage={rejectBidErrorMessage}
              onClick={handleRejectBid}
              primaryCTAName="Confirm"
              ctaDisabled={isValidating}
              isMessageCentered
            />
          )}
          {!!currentTransactionHash && (
            <Suspense>
              <ProductPendingOnChain
                queryVariables={{ txtId: currentTransactionHash }}
                nft={nft}
                onSuccess={handlePostSuccessOnChain}
              />
            </Suspense>
          )}
        </MPDialog>
      )}
      {!!showSuccessDialog && (
        <SuccessView
          Icon={PurchasedArtworkIcon}
          title={MODAL_TITLES[ProductBidStates.ACCEPTED]}
          message="Share this moment with your community to celebrate!"
          onClose={closeSuccessDialog}
          variant="premium"
          link={window.location.href}
          tweet={generateTweetText(nft)}
        />
      )}
    </div>
  );
}

export default ProductAcceptOrRejectBid;
