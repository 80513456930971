import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { buttonClasses } from '@mui/material';
import { styled } from '@mui/system';

import {
  MPColorClass,
  MPColorValue,
} from '../themes/default/__generated__/MPColorsEnum';
import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { ArrowDownIcon, ArrowUpIcon } from '../../icons';
import { MPButton } from '..';

const joinClasses = (...classes: (string | null | undefined)[]): string =>
  classes.filter(Boolean).join(' ');

const StyledContainer = styled('div')`
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledHeader = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

const StyledList = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${MPColorValue.SolidNeutralGray1};
  border-bottom: none;
`;

interface StyledItemProps {
  $bordered: boolean;
  $collapsible: boolean;
  $selected: boolean;
}
const StyledItem = styled('div', {
  shouldForwardProp: (prop: string) => !prop.startsWith('$'),
})<StyledItemProps>`
  align-items: center;
  background-color: ${({ $selected }) =>
    $selected ? MPColorValue.BackgroundDefault : MPColorValue.CommonWhite};
  cursor: ${({ $collapsible }) => ($collapsible ? 'pointer' : 'default')};
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
  border-bottom: ${({ $bordered }) =>
    $bordered ? `1px solid ${MPColorValue.SolidNeutralGray1}` : 'none'};

  &:active {
    border: ${({ $selected }) =>
      $selected ? '' : `2px solid ${MPColorValue.CommonBlack}`};
    padding: ${({ $selected }) => ($selected ? '16px' : '14px')};
  }
`;

const StyledItemTitle = styled('span')`
  flex-grow: 1;
  flex-basis: 0;
`;

export interface MPDropdownSelectorItemProps {
  prefix: ReactNode;
  title: ReactNode;
  value: string;
}

export interface MPDropdownSelectorProps {
  items: MPDropdownSelectorItemProps[];
  onChange: (value: string) => void;
  value: string;
  action?: { onClick: () => void; title: string };
  label?: string;
}

const actionButtonSx = {
  [`&.${buttonClasses.root}, &.${buttonClasses.root}:hover`]: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    minWidth: 'unset',
    padding: 0,
    textDecoration: 'underline',
  },
  [`&.${buttonClasses.root}`]: {
    [`&:active`]: {
      color: MPColorValue.SolidNeutralGray1,
    },
    [`&:hover`]: {
      color: MPColorValue.SolidNeutralGray3,
    },
    color: MPColorValue.SolidNeutralGray5,
  },
};

export default function MPDropdownSelector({
  action,
  items,
  label,
  onChange,
  value,
}: MPDropdownSelectorProps) {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const selectedItem = useMemo<MPDropdownSelectorItemProps>(
    () => items.find((item) => item.value === value) ?? items[0],
    [items, value]
  );
  const otherItems = useMemo<MPDropdownSelectorItemProps[]>(
    () => items.filter((item) => item.value !== selectedItem.value),
    [items, selectedItem]
  );
  const handleToggle = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (!otherItems.length) return;

      setCollapsed((prevValue) => !prevValue);
    },
    [otherItems, setCollapsed]
  );

  return selectedItem ? (
    <StyledContainer>
      <StyledHeader>
        {label ? (
          <div
            className={joinClasses(
              MPFonts.inputLabel,
              MPColorClass.CommonBlack
            )}
          >
            {label}
          </div>
        ) : null}

        {action ? (
          <MPButton
            type="button"
            className={MPFonts.textSmallSemiBold}
            onClick={action.onClick}
            variant="text"
            sx={actionButtonSx}
          >
            {action.title}
          </MPButton>
        ) : null}
      </StyledHeader>

      {collapsed ? (
        <StyledItem
          $bordered={false}
          $selected
          $collapsible={!!otherItems.length}
          onClick={handleToggle}
        >
          {selectedItem.prefix}
          <StyledItemTitle>{selectedItem.title}</StyledItemTitle>
          {otherItems.length ? <ArrowDownIcon /> : null}
        </StyledItem>
      ) : (
        <StyledList>
          <StyledItem $bordered $selected $collapsible onClick={handleToggle}>
            {selectedItem.prefix}
            <StyledItemTitle>{selectedItem.title}</StyledItemTitle>
            <ArrowUpIcon />
          </StyledItem>
          {otherItems.map((item) => (
            <StyledItem
              key={item.value}
              $bordered
              $selected={false}
              $collapsible
              onClick={(event: MouseEvent) => {
                handleToggle(event);
                onChange(item.value);
              }}
            >
              {item.prefix}
              <StyledItemTitle>{item.title}</StyledItemTitle>
            </StyledItem>
          ))}
        </StyledList>
      )}
    </StyledContainer>
  ) : null;
}
