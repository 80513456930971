.exhibitionCard {
  --exhibitionCard-thumbnailHeight: 85px;
  --exhibitionCard-thumbnailPaddingBottom: 24px;
  --exhibitionCard-thumbnailPaddingTop: 10px;
  --exhibitionCard-transition: 300ms ease-out;

  background-image: linear-gradient(
      180deg,
      var(--exhibitionCard-colorFrom) 53.8%,
      var(--exhibitionCard-colorTo) 102.39%
    ),
    var(--exhibitionCard-mediaUrl);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.exhibitionCardOverlay {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  &::before {
    background: linear-gradient(
      180deg,
      transparent 0%,
      var(--exhibitionCard-colorTo) 100%
    );
    bottom: 0;
    content: " ";
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateY(100%);
    width: 100%;
    z-index: 1;
  }
}

.exhibitionCardOverlayContent {
  margin: 0 0 0 34px;
  position: relative;
  transition: transform var(--exhibitionCard-transition);
  transform: translateY(
    calc(
      var(--exhibitionCard-thumbnailHeight) +
        var(--exhibitionCard-thumbnailPaddingTop) +
        var(--exhibitionCard-thumbnailPaddingBottom) - 30px
    )
  );
  z-index: 2;

  @media (--mq-is-mobile) {
    transform: unset;
    height: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column-reverse;
  }

  & .exhibitionCardOverlayText {
    padding-right: 34px;
  }
}

.exhibitionCardOverlayPresenter {
  opacity: 0.65;
}

.exhibitionCardOverlayThumbnails {
  opacity: 0;
  overflow: hidden;
  padding: var(--exhibitionCard-thumbnailPaddingTop) 0
    var(--exhibitionCard-thumbnailPaddingBottom) 0;
  transition: opacity var(--exhibitionCard-transition);
  position: relative;
  z-index: 0;

  &.long::after {
    content: " ";
    display: block;
    height: var(--exhibitionCard-thumbnailHeight);
    position: absolute;
    right: 0;
    top: var(--exhibitionCard-thumbnailPaddingTop);
    width: 64px;
    background: linear-gradient(
      90deg,
      rgba(26, 26, 26, 0) 11%,
      var(--exhibitionCard-colorTo) 111%
    );
    z-index: 2;
  }
}

.exhibitionCardOverlayThumbnail {
  box-sizing: content-box;
  height: var(--exhibitionCard-thumbnailHeight);
  position: relative;
  width: auto;
  z-index: 1;
}

@media (--mq-is-not-mobile) {
  .exhibitionCard:hover > .exhibitionCardOverlay {
    &::before {
      transform: translateY(0);
    }

    > .exhibitionCardOverlayContent {
      transform: translateY(0);

      > .exhibitionCardOverlayThumbnails {
        opacity: 1;
      }
    }
  }
}
