function WhatIsADigitalEditionContent() {
  return (
    <div>
      <p>
        A <span className="bold">digital edition</span> is synonymous to the
        popular concept in&nbsp;
        <a
          target="_BLANK"
          className="bold invisibleAnchor"
          href="https://en.wikipedia.org/wiki/Edition_(printmaking)"
          rel="noreferrer"
        >
          printmaking
        </a>
        . When you upload your digital creations on MakersPlace, you&#39;re
        uploading it as a limited edition. This ensures that only a limited
        number of copies of your digital creation can ever be owned by
        collectors. <br />
        <i>This is strictly enforced via blockchain technology</i>.
      </p>
      <p>
        <span className="bold">Limited Editions Equate to Value</span>
        <br />
        The number of editions you issue has an important impact on the value of
        your work. A smaller number of editions will increase the rarity and
        attractiveness to a collector, since collectors place enormous value
        owning a unique asset over something that&#39;s more commonly
        attainable. A larger number of editions, however, allows your work to be
        owned by more collectors at a lower price point, but may not be
        attractive to collectors who place more value on rarity.
      </p>
      <p>
        <span className="bold">A Guide to Setting your Editions</span>
        <br />
        Here is a simple guide that we recommend following:
      </p>
      <p style={{ paddingTop: '.5em' }}>
        <span className="bold">1</span> -&nbsp;
        <span className="bold">
          <i>A one-of-a-kind creation</i>
        </span>
        . Creations with 1 edition can only be owned by one collector at any
        given time, making it the most attractive to a collector. These
        creations should command the highest sale price.
      </p>
      <p>
        <span className="bold">10</span> -&nbsp;
        <span className="bold">
          <i>A rare digital creation</i>
        </span>
        . Creations with 10 editions may be sold and owned by up to 10
        collectors at any given time. Although still rare, because it can be
        owned by multiple collectors, each edition should command a slightly
        lower sale price than an edition of one.
      </p>
      <p>
        <span className="bold">100+</span> -&nbsp;
        <span className="bold">
          <i>A limited digital creation</i>
        </span>
        . Creations with 100+ editions may be owned by many collectors at a
        given time. Although still limited, because it can be owned by many
        collectors, collectors will expect the price of each edition to be much
        lower.
      </p>
      <p>
        MakersPlace gives you the flexibility to set your own editions, however
        we highly discourage setting a very large edition count.
      </p>
    </div>
  );
}

export default {
  Component: WhatIsADigitalEditionContent,
  title: 'What is a Digital Edition?',
};
