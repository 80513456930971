import { useCallback } from 'react';
import {
  // eslint-disable-next-line camelcase
  useRecoilRefresher_UNSTABLE,
  // eslint-disable-next-line camelcase
  useRecoilValue_TRANSITION_SUPPORT_UNSTABLE,
} from 'recoil';

import Session from '../Session';

export function useRefreshSession() {
  const refresh = useRecoilRefresher_UNSTABLE(Session.recoilValue);
  return useCallback(() => {
    Session.deleteSession();
    return refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, Session.recoilValue]);
}

export default function useSession() {
  return useRecoilValue_TRANSITION_SUPPORT_UNSTABLE(Session.recoilValue);
}
