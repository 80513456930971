import { merge } from 'lodash';
import { dialogClasses, dialogTitleClasses } from '@mui/material';

import {
  MPDialog,
  MPDialogProps,
  useIsMobile,
} from '@mp-frontend/core-components';

import animatedBackgroundImage from 'static/images/congratulation-background.svg';

import * as styles from 'css/components/dialogs/CongratulationDialog.module.css';

export default function CongratulationDialog({
  sx,
  children,
  ...props
}: MPDialogProps) {
  const isMobile = useIsMobile();

  const mergedSx = merge(
    {},
    {
      [`& .${dialogClasses.paper}`]: {
        height: isMobile ? '100%' : '567px',
        maxHeight: isMobile ? '100vh' : '85vh',
        width: isMobile ? '100%' : 'var(--mp-defaultDialogWidth, 502px)',
      },
      [`& .${dialogTitleClasses.root}`]: {
        display: 'none',
      },
    },
    sx || {}
  );

  return (
    <MPDialog {...props} sx={mergedSx}>
      <>
        <div
          className={styles.container}
          style={{ backgroundImage: `url(${animatedBackgroundImage})` }}
        >
          <div className={styles.content}>{children}</div>
        </div>
      </>
    </MPDialog>
  );
}
