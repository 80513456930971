import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ErrorMessage,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { UseMPMutationConfig, useMutation } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import {
  MPActionButton,
  MPColorValue,
  MPFonts,
  MPGrid,
  MPTextArea,
  MPTooltip,
} from '@mp-frontend/core-components';
import { VideoIcon } from '@mp-frontend/core-components/icons';
import {
  joinClasses,
  useOnEnterKey,
  useRefState,
} from '@mp-frontend/core-utils';

import DraftNFTMutation, {
  NFTDraftMutation,
} from 'graphql/__generated__/NFTDraftMutation.graphql';
import { MpErrors } from 'types/__generated__/graphql';

import ConditionalWrapper from 'components/ConditionalWrapper';
import ROUTES from 'constants/Routes';
import FourOFourError from 'errors/FourOFourError';
import MPGraphQLError from 'errors/MPGraphQLError';
import useCreatorDetails from 'hooks/session/useCreatorDetails';
import useSession from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import CreatorGuidelinesDialog from 'pages/creator-guidelines/CreatorGuidelinesDialog';
import ShouldIUploadHiResDialog from 'pages/faq/howMPWorks/UploadHiRes/ShouldIUploadHiResDialog';
import WhatIsADigitalEditionDialog from 'pages/faq/selling/WhatIsADigitalEdition/WhatIsADigitalEditionDialog';
import CSSGlobal from 'types/enums/css/Global';
import emptyFunc from 'utils/emptyFunc';
import getAddressDisplay from 'utils/getAddressDisplay';
import setDocTitle from 'utils/setDocTitle';

import AddCollaboratorDialog, { CollabUserInfo } from './AddCollaboratorDialog';

import * as inputStyles from 'css/global/Inputs.module.css';
import * as styles from 'css/pages/digital-media/create/UploadNFTPage.module.css';

interface UploadNFTFormElement extends HTMLFormElement {
  elements: HTMLFormControlsCollection & {
    asset: HTMLInputElement;
    assetPreview: HTMLInputElement;
  };
}

function usePreviewImage(
  assetPreviewSrc: string,
  playVideo: () => void,
  ariaPlayVideo: (e: KeyboardEvent) => void,
  playStartedOnce: boolean
) {
  return useCallback(
    (children) => (
      <div
        onClick={playVideo}
        onKeyPress={ariaPlayVideo}
        className={styles.digitalMediaVideoWrapper}
        role="button"
        tabIndex={0}
        style={{ backgroundImage: `url(${assetPreviewSrc})` }}
      >
        <div
          className="flexAbsCenter"
          style={{ display: playStartedOnce ? 'none' : undefined }}
        >
          <VideoIcon
            className={styles.playIcon}
            htmlColor={MPColorValue.CommonWhite}
            alt="Play Video"
          />
        </div>
        {children}
      </div>
    ),
    [assetPreviewSrc, playVideo, ariaPlayVideo, playStartedOnce]
  );
}

const DraftSchema = Yup.object().shape({
  asset: Yup.mixed().required('Required'),
  assetPreview: Yup.mixed().when('asset', {
    is: (asset) => asset && asset.type.startsWith('video'),
    then: Yup.mixed().required('Required'),
  }),
  description: Yup.string().required('Required'),
  editions: Yup.number()
    .required('Required')
    .positive()
    .integer()
    .lessThan(10001),
  title: Yup.string().required('Required'),
});

function validateForm(values) {
  try {
    validateYupSchema(values, DraftSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return undefined;
}

let ErrorMap: Partial<Record<MpErrors, string>>;

const ONE_EDITIONS = '1';
const RARE_EDITIONS_MIN = 2;
const RARE_EDITIONS_MAX = 10;
const LIMITED_EDITIONS_MIN = 11;
const LIMITED_EDITIONS_MAX = 100;

export default function UploadNFTPage() {
  setDocTitle('Publish Your Creation');
  const navigate = useNavigate();
  const session = useSession();
  if (!session.isCreator()) throw new FourOFourError();
  const creatorDetails = useCreatorDetails();
  const [collabUserInfo, setCollabUserInfo] = useState<CollabUserInfo>();

  const form = useRef<UploadNFTFormElement>(null);
  const [currentAsset, setCurrentAsset] = useState<File>(null);

  const isAssetVideo = () =>
    !!currentAsset?.type.toLowerCase().startsWith('video');
  const [currentAssetPreview, _setCurrentAssetPreview] = useState<File>(null);
  const [playStartedOnce, setPlayStartedOnce] = useState<boolean>(false);
  const [videoNode, videoNodeRef] = useRefState<HTMLVideoElement>(null);
  const setCurrentAssetPreview = useCallback(
    (asset: File) => {
      if (videoNode) videoNode.pause();
      setPlayStartedOnce(false);
      _setCurrentAssetPreview(asset);
    },
    [_setCurrentAssetPreview, setPlayStartedOnce, videoNode]
  );
  const playVideo = useCallback(() => {
    if (!videoNode) return;
    setPlayStartedOnce(true);
    videoNode.play();
  }, [videoNode, setPlayStartedOnce]);
  const assetSrc = useMemo(
    () => (currentAsset ? URL.createObjectURL(currentAsset) : undefined),
    [currentAsset]
  );
  const assetPreviewSrc = useMemo(
    () =>
      currentAssetPreview
        ? URL.createObjectURL(currentAssetPreview)
        : undefined,
    [currentAssetPreview]
  );
  const ariaPlayVideo = useOnEnterKey(playVideo);

  // Dialog Controllers
  const [isGuidelineDialogOpen, openGuidelineDialog, closeGuidelineDialog] =
    useSimpleDialogController({ preventDefault: true });
  const [
    isWhatEditionDialogOpen,
    openWhatEditionDialog,
    closeWhatEditionDialog,
  ] = useSimpleDialogController({ preventDefault: true });
  const [isHiResDialogOpen, openHiResDialog, closeHiResDialog] =
    useSimpleDialogController({ preventDefault: true });
  const [
    isAddCollaboratorDialogOpen,
    openAddCollaboratorDialog,
    closeAddCollaboratorDialog,
    ,
    ariaOpenAddCollaboratorDialog,
  ] = useSimpleDialogController();

  const assetInputRef = useRef<HTMLInputElement | null>(null);
  const openAssetPicker = useCallback((e) => {
    if (assetInputRef.current) {
      assetInputRef.current.click();
    }
    e.preventDefault();
    return false;
  }, []);
  const ariaOpenAssetPicker = useOnEnterKey(openAssetPicker);
  const updateCurrentAsset = () => {
    if (!form.current || !form.current.elements.asset.files.length) return;
    const asset = form.current.elements.asset.files[0];
    setCurrentAsset(asset);
    if (!asset.type.toLowerCase().startsWith('video'))
      setCurrentAssetPreview(undefined);
  };
  const assetPreviewInputRef = useRef<HTMLInputElement | null>(null);
  const openAssetPreviewPicker = useCallback(() => {
    if (assetPreviewInputRef.current) assetPreviewInputRef.current.click();
  }, []);
  const ariaOpenAssetPreviewPicker = useOnEnterKey(openAssetPreviewPicker);
  const updateCurrentAssetPreview = () => {
    if (!form.current || !form.current.elements.asset.files.length) return;
    setCurrentAssetPreview(form.current.elements.assetPreview.files[0]);
  };
  const previewImage = usePreviewImage(
    assetPreviewSrc,
    playVideo,
    ariaPlayVideo,
    playStartedOnce
  );
  const [draft] = useMutation<NFTDraftMutation>(DraftNFTMutation);

  const formikPropsRef = useRef<any>({
    handleSubmit: emptyFunc,
  });

  const [globalError, setGlobalError] = useState('');

  return (
    <div className={styles.updateNFTPage}>
      <div className={styles.headerContent}>
        <div className={MPFonts.titleMedium}>
          Upload{creatorDetails.isFirstUpload ? ' Your First ' : ' a '}Digital
          Creation
        </div>
        <a
          className={joinClasses(
            'invisibleAnchor',
            styles.popupLink,
            styles.creatorGuidelines
          )}
          href="/creator-guidelines/"
          onClick={openGuidelineDialog}
        >
          Read our Creation Best Practices
        </a>
        <div className={styles.creationAddressBlock}>
          Creation Wallet Address:&nbsp;
          <MPTooltip
            title={creatorDetails.creationAddress?.address ?? ''}
            arrow
            placement="top"
          >
            <span className="bold">
              {getAddressDisplay(creatorDetails.creationAddress?.address ?? '')}
            </span>
          </MPTooltip>
          &nbsp;
          <a href="/profile/" target="_BLANK">
            (change)
          </a>
        </div>
      </div>
      <MPGrid
        container
        columns={{ desktop: 2, mobile: 1, wide: 2, xwide: 2 }}
        spacing={4}
      >
        <MPGrid item mobile={1} desktop={1} wide={1} xwide={1}>
          <Formik
            initialValues={{
              asset: undefined,
              assetPreview: undefined,
              description: undefined,
              editions: undefined,
              title: undefined,
            }}
            validate={validateForm}
            onSubmit={(values, { setSubmitting }) => {
              const uploadables: UseMPMutationConfig<NFTDraftMutation>['uploadables'] =
                {
                  uploadedFile: currentAsset,
                };
              if (currentAssetPreview)
                uploadables.previewFile = currentAssetPreview;
              draft({
                onCompleted: (resp) => {
                  setSubmitting(false);
                  if (resp?.draftNft.digitalMediaId) {
                    navigate(
                      ROUTES.DIGITAL_MEDIA.RELEASES.ID(
                        resp.draftNft.digitalMediaId
                      )
                    );
                  }
                },
                onError: (error: MPGraphQLError | Error) => {
                  ErrorMap =
                    ErrorMap ||
                    (function genErrorMap() {
                      const m: Partial<Record<MpErrors, string>> = {};
                      [
                        MpErrors.CreateMediaDuplicateImageSignature,
                        MpErrors.CreateMediaUploadImageFailure,
                        MpErrors.CreateMediaUploadedFileMissed,
                        MpErrors.CreateMediaFileOversize,
                      ].forEach((err) => {
                        m[err] = 'asset';
                      });
                      [
                        MpErrors.CreateMediaFileUndersize,
                        MpErrors.CreateMediaPreviewFileMissed,
                      ].forEach((err) => {
                        m[err] = 'assetPreview';
                      });
                      [
                        MpErrors.CreateMediaTitleInvalid,
                        MpErrors.CreateMediaMissingTitle,
                      ].forEach((err) => {
                        m[err] = 'title';
                      });
                      [MpErrors.CreateMediaDescriptionInvalid].forEach(
                        (err) => {
                          m[err] = 'description';
                        }
                      );
                      [
                        MpErrors.CreateMediaTotalSupplyOversize,
                        MpErrors.CreateMediaTotalSupplyUndersize,
                        MpErrors.CreateMediaMissingTotalSupply,
                      ].forEach((err) => {
                        m[err] = 'editions';
                      });
                      return m;
                    })();
                  if (error?.name) {
                    if (ErrorMap[error.name]) {
                      formikPropsRef.current.setFieldError(
                        ErrorMap[error.name],
                        error.message
                      );
                    } else {
                      let msg = error.message;
                      if (
                        error.name ===
                        MpErrors.CreateMediaOwnershipPctsWalletNotConnected
                      )
                        msg = `${
                          (error instanceof MPGraphQLError &&
                            error.additionalData) ||
                          'Your collaborator'
                        } has not finished connecting a wallet`;
                      setGlobalError(msg);
                    }
                  } else {
                    setGlobalError(error?.message ?? 'Something went wrong');
                  }
                  setSubmitting(false);
                },
                uploadables,
                variables: {
                  description: values.description,
                  // eslint-disable-next-line @cspell/spellchecker
                  ownershipPcts: collabUserInfo && [
                    {
                      percentage: collabUserInfo.percent,
                      username: session.account.username,
                    },
                    {
                      percentage: 100 - collabUserInfo.percent,
                      username: collabUserInfo.user.username,
                    },
                  ],
                  previewCropRatio:
                    videoNode?.videoHeight && videoNode?.videoWidth
                      ? videoNode.videoWidth / videoNode.videoHeight
                      : undefined,
                  title: values.title,
                  totalSupply: values.editions,
                },
              });
              setGlobalError('');
              setSubmitting(true);
            }}
          >
            {(props) => {
              formikPropsRef.current = props;

              const updateAsset = () => {
                props.setFieldValue(
                  'asset',
                  form.current.elements.asset.files[0]
                );
                updateCurrentAsset();
              };

              const updateAssetPreview = () => {
                props.setFieldValue(
                  'assetPreview',
                  form.current.elements.assetPreview.files[0]
                );
                updateCurrentAssetPreview();
              };

              return (
                <form ref={form}>
                  <input
                    style={{ display: 'none' }}
                    multiple
                    ref={assetInputRef}
                    name="asset"
                    type="file"
                    onChange={updateAsset}
                  />
                  <input
                    style={{ display: 'none' }}
                    multiple
                    ref={assetPreviewInputRef}
                    name="assetPreview"
                    type="file"
                    onChange={updateAssetPreview}
                  />
                  <div
                    className={joinClasses(
                      CSSGlobal.Flex.Row,
                      styles.fakeFileInput
                    )}
                    onClickCapture={openAssetPicker}
                  >
                    <MPTextArea
                      className={styles.fakeFileInputTextArea}
                      placeholder="Choose file to upload"
                      label="Digital Media File"
                      name="fileName"
                      onChange={emptyFunc}
                      rows={1}
                      value={currentAsset?.name ?? ''}
                    />
                    <MPActionButton onKeyPress={ariaOpenAssetPicker}>
                      Browse
                    </MPActionButton>
                  </div>
                  <ErrorMessage name="asset">
                    {(msg) => (
                      <div className={inputStyles.errorMessage}>{msg}</div>
                    )}
                  </ErrorMessage>
                  <div
                    className={joinClasses(
                      styles.fakeFileInput,
                      !isAssetVideo() ? 'hidden' : CSSGlobal.Flex.Row
                    )}
                    onClickCapture={openAssetPreviewPicker}
                  >
                    <MPTextArea
                      className={styles.fakeFileInputTextArea}
                      placeholder="Choose file to upload"
                      label="Preview Image File"
                      name="filePreviewName"
                      onChange={emptyFunc}
                      rows={1}
                      value={currentAssetPreview?.name ?? ''}
                    />
                    <MPActionButton onKeyPress={ariaOpenAssetPreviewPicker}>
                      Browse
                    </MPActionButton>
                  </div>
                  <ErrorMessage
                    name="assetPreview"
                    className={!isAssetVideo() ? 'hidden' : CSSGlobal.Flex.Row}
                  >
                    {(msg) => (
                      <div className={inputStyles.errorMessage}>{msg}</div>
                    )}
                  </ErrorMessage>
                  <MPTextArea
                    placeholder="The title of your creation"
                    label="Title"
                    rows={1}
                    name="title"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.title}
                  />
                  <ErrorMessage name="title">
                    {(msg) => (
                      <div className={inputStyles.errorMessage}>{msg}</div>
                    )}
                  </ErrorMessage>
                  <MPTextArea
                    placeholder="The story.  What is this creation about?  What inspired you to create it?  How did you create it?"
                    label="Description"
                    rows={7}
                    name="description"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.description}
                  />
                  <ErrorMessage name="description">
                    {(msg) => (
                      <div className={inputStyles.errorMessage}>{msg}</div>
                    )}
                  </ErrorMessage>
                  <MPTextArea
                    placeholder="The total number of editions"
                    label="Editions"
                    rows={1}
                    name="editions"
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      if (!/^\d+$/.test(e.target.value)) {
                        props.setFieldValue(
                          'editions',
                          e.target.value.replace(/\D/g, '')
                        );
                        return;
                      }
                      props.handleChange(e);
                    }}
                    onBlur={props.handleBlur}
                    value={props.values.editions}
                  />
                  <ErrorMessage name="editions">
                    {(msg) => (
                      <div className={inputStyles.errorMessage}>{msg}</div>
                    )}
                  </ErrorMessage>
                  {!!props.touched.editions && (
                    <div className={styles.editionsBlurb}>
                      {props.values.editions === ONE_EDITIONS && (
                        <span>
                          <span className="bold">One-of-a-kind &ndash;</span> A
                          truly unique digital creation that will command the
                          highest price.
                        </span>
                      )}
                      {props.values.editions >= RARE_EDITIONS_MIN &&
                        props.values.editions <= RARE_EDITIONS_MAX && (
                          <span>
                            <span className="bold">
                              A rare creation &ndash;
                            </span>
                            &nbsp; Editions up to 10 are considered rare. Expect
                            the price of each edition to decrease as the total
                            number of editions increases.
                          </span>
                        )}
                      {props.values.editions >= LIMITED_EDITIONS_MIN &&
                        props.values.editions <= LIMITED_EDITIONS_MAX && (
                          <span>
                            <span className="bold">
                              A limited edition &ndash;
                            </span>
                            &nbsp; Editions greater than 10 are considered
                            limited. However, the higher the edition count, the
                            less attractive it becomes to collectors.
                          </span>
                        )}
                      {props.values.editions > LIMITED_EDITIONS_MAX && (
                        <span>
                          <span className="bold">A common edition &ndash;</span>
                          &nbsp; Editions greater than 100 are less attractive
                          to collectors. Expect each edition to sell only at low
                          prices.
                        </span>
                      )}
                      {props.values.editions > 0 && (
                        <>
                          &nbsp;
                          <a
                            className={joinClasses(
                              'invisibleAnchor',
                              styles.popupLink
                            )}
                            href="/faq/#what-editions"
                            onClick={openWhatEditionDialog}
                            target="_blank"
                          >
                            (Learn More)
                          </a>
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className={joinClasses(
                      CSSGlobal.Flex.Row,
                      styles.flexOptions
                    )}
                  >
                    {!props.values.editions && (
                      <a
                        className={joinClasses(
                          'invisibleAnchor',
                          styles.popupLink
                        )}
                        href="/faq/#what-editions"
                        onClick={openWhatEditionDialog}
                        target="_blank"
                      >
                        What&#39;s an edition?
                      </a>
                    )}
                    {props.values.editions > 0 && <span />}
                    <span
                      className="pointer"
                      onClick={openAddCollaboratorDialog}
                      onKeyPress={ariaOpenAddCollaboratorDialog}
                      role="button"
                      tabIndex={0}
                    >
                      {collabUserInfo ? (
                        <span className={styles.collabUserProfileSpan}>
                          <MPTooltip
                            title={collabUserInfo.user.name}
                            placement="top"
                            arrow
                          >
                            <img
                              className={styles.collabUserProfileImg}
                              src={collabUserInfo.user.image}
                            />
                          </MPTooltip>
                          &nbsp;Edit Collaborator
                        </span>
                      ) : (
                        <span>+ Add Collaborator</span>
                      )}
                    </span>
                  </div>
                  {!!globalError && (
                    <div className={styles.globalError}>{globalError}</div>
                  )}
                  <div
                    className={joinClasses(
                      CSSGlobal.Flex.Row,
                      styles.flexOptions,
                      styles.actionRow
                    )}
                  >
                    <MPActionButton
                      size="large"
                      onClick={formikPropsRef.current.handleSubmit}
                      isLoading={props.isSubmitting}
                      disabled={
                        props.isSubmitting ||
                        validateForm(props.values) !== undefined
                      }
                    >
                      Save
                    </MPActionButton>
                  </div>
                </form>
              );
            }}
          </Formik>
        </MPGrid>
        <MPGrid item mobile={1} desktop={1} wide={1} xwide={1}>
          <div className={styles.previewSection}>
            {!!currentAsset?.type.toLowerCase().startsWith('image') && (
              <img className={styles.assetPreview} src={assetSrc} />
            )}
            {!!currentAsset?.type.toLowerCase().startsWith('video') && (
              <ConditionalWrapper
                condition={!!assetPreviewSrc}
                wrapper={previewImage}
              >
                <video
                  ref={videoNodeRef}
                  className={styles.assetPreview}
                  playsInline
                  controlsList="nodownload"
                  disablePictureInPicture
                  controls
                  style={{
                    visibility:
                      !assetPreviewSrc || playStartedOnce
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  <source src={assetSrc} type="video/mp4" />
                  Your browser does not support playing videos.
                </video>
              </ConditionalWrapper>
            )}
            {!!currentAsset &&
              !currentAsset?.type.toLowerCase().startsWith('image') &&
              !currentAsset?.type.toLowerCase().startsWith('video') && (
                <span>This type of file is not supported at this time</span>
              )}
            {!currentAsset && (
              <div className={styles.previewExplanation}>
                <div className={MPFonts.titleSmall}>Preview</div>
                <br />
                <div className={MPFonts.textSmallMedium}>
                  Tip: Upload only high resolution files
                </div>
                <a
                  className={joinClasses(
                    'invisibleAnchor',
                    styles.popupLink,
                    MPFonts.subtitle1
                  )}
                  href="/faq/#upload-high-res"
                  onClick={openHiResDialog}
                >
                  Learn More
                </a>
              </div>
            )}
          </div>
        </MPGrid>
      </MPGrid>
      <CreatorGuidelinesDialog
        isOpen={isGuidelineDialogOpen}
        cancel={closeGuidelineDialog}
      />
      <WhatIsADigitalEditionDialog
        isOpen={isWhatEditionDialogOpen}
        cancel={closeWhatEditionDialog}
      />
      <ShouldIUploadHiResDialog
        isOpen={isHiResDialogOpen}
        cancel={closeHiResDialog}
      />
      {!!isAddCollaboratorDialogOpen && (
        <AddCollaboratorDialog
          isOpen={isAddCollaboratorDialogOpen}
          onUpdate={(collabs) => setCollabUserInfo(collabs?.[0])}
          initialCollabUser={collabUserInfo?.user}
          initialPercentage={collabUserInfo?.percent}
          cancel={closeAddCollaboratorDialog}
        />
      )}
    </div>
  );
}
