import { PaginatedExploreArtistsQuery$variables } from 'graphql/__generated__/PaginatedExploreArtistsQuery.graphql';
import { ArtistSortTypeEnum, SortOrderEnum } from 'types/__generated__/graphql';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import {
  SortArtistFilter,
  SortArtistsFilterValue,
} from 'components/filters/SortFilter';
import useFiltersGTM, { FilterType } from 'utils/GTM/filters';

export interface ArtistsFiltersState {
  query: string;
  sort: SortArtistsFilterValue;
}

export const ARTISTS_FILTERS_DEFAULT_STATE: ArtistsFiltersState = {
  query: '',
  sort: { order: SortOrderEnum.Desc, type: ArtistSortTypeEnum.QuantScore },
};

export const useArtistsFiltersQueryValues = (
  filters: ArtistsFiltersState
): PaginatedExploreArtistsQuery$variables => ({
  first: 9,
  query: filters.query,
  sortOrder: filters.sort.order,
  sortType: filters.sort.type,
});

interface ArtitsFiltersProps {
  onChange: (state: Partial<ArtistsFiltersState>) => void;
  state: ArtistsFiltersState;
}

export function Filters({ state, onChange }: ArtitsFiltersProps) {
  const { updateFilter } = useFiltersGTM();
  return (
    <FilterScrollContainer>
      <FiltersSection inline inlineMobile>
        <FiltersGroup>
          <FiltersGroup title="Sorting">
            <SortArtistFilter
              value={state.sort}
              onChange={(sort) => {
                onChange({ sort });
                updateFilter(FilterType.SortFilter, sort);
              }}
            />
          </FiltersGroup>
        </FiltersGroup>
      </FiltersSection>
    </FilterScrollContainer>
  );
}
