import {
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

import { useOnEnterKey } from '@mp-frontend/core-utils';

interface useSimpleDialogControllerProps {
  preventDefault?: boolean;
}

export default function useSimpleDialogController<
  E extends HTMLElement = HTMLElement
>({ preventDefault = false }: useSimpleDialogControllerProps = {}): [
  boolean,
  (e?: MouseEvent<E>) => void,
  () => void,
  Dispatch<SetStateAction<boolean>>,
  (e?: KeyboardEvent<E>) => void
] {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = useCallback(
    (e?: MouseEvent<E>) => {
      if (preventDefault && e) e.preventDefault();
      setIsDialogOpen(true);
    },
    [setIsDialogOpen, preventDefault]
  );
  const cancel = useCallback(() => setIsDialogOpen(false), [setIsDialogOpen]);
  const ariaOpenDialog = useOnEnterKey(openDialog);
  return [isDialogOpen, openDialog, cancel, setIsDialogOpen, ariaOpenDialog];
}
