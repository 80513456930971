import { startTransition, useEffect, useRef, useState } from 'react';
import { merge } from 'lodash';
import { List, styled } from '@mui/material';
import { SxProps } from '@mui/system';

import useDetectClickOutside from '../../../makersplace/src/hooks/useDetectClickOutside';
import MPChipButton from '../chips/MPChipButton';
import { ExpandLessIcon, ExpandMoreIcon } from '../icons';

export interface MPChipSelectProps {
  label: string;
  value: string;
  children?: any;
  sx?: SxProps;
}

const MPChipSelectWrapper = styled('div')`
  z-index: 300;
  position: relative;
`;

const IconSx = {
  fontSize: '18px',
};

export default function MPChipSelect({
  children,
  label,
  value,
  sx = {},
}: MPChipSelectProps) {
  const chipSelectWrapper = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useDetectClickOutside(
    chipSelectWrapper,
    false,
    true
  );
  const [selectedValue, setSelectedValue] = useState(value);
  const toggleIsOpen = () =>
    setIsOpen((currentValue: boolean) => !currentValue);

  useEffect(() => {
    if (selectedValue !== value) {
      startTransition(() => {
        setSelectedValue(value);
        setIsOpen(false);
      });
    }
  }, [selectedValue, value, setIsOpen, setSelectedValue]);

  const mergedSx = merge(
    {
      flexDirection: `row-reverse`,
    },
    sx
  );

  return (
    <MPChipSelectWrapper ref={chipSelectWrapper}>
      <MPChipButton
        onClick={toggleIsOpen}
        label={label}
        icon={
          isOpen ? (
            <ExpandLessIcon sx={IconSx} />
          ) : (
            <ExpandMoreIcon sx={IconSx} />
          )
        }
        sx={mergedSx}
      />

      {!!isOpen && (
        <List
          sx={{
            bgcolor: 'background.paper',
            borderRadius: '16px',
            boxShadow: 8,
            display: 'relative',
            marginTop: '4px',
            position: 'absolute',
            width: '100%',
            zIndex: 20,
          }}
        >
          {children}
        </List>
      )}
    </MPChipSelectWrapper>
  );
}
