import { startTransition, useCallback } from 'react';
import { UseMPMutationConfig, useMutation } from 'react-relay';
import { Disposable } from 'relay-runtime';
import { Hash } from 'viem';
import useWagmiClient from 'wagmi-client';
import { Connector, signMessage } from '@wagmi/core';

import AccountRegisterWallet, {
  AccountRegisterWalletMutation,
} from 'graphql/__generated__/AccountRegisterWalletMutation.graphql';

import { useRefreshSession } from 'hooks/useSession';

export default function useRegister(): [
  (
    address: Hash,
    connector: Connector,
    isCreationEnabled?: boolean
  ) => Promise<void>,
  [
    (config: UseMPMutationConfig<AccountRegisterWalletMutation>) => Disposable,
    boolean
  ]
] {
  const mutation = useMutation<AccountRegisterWalletMutation>(
    AccountRegisterWallet
  );
  const wagmiConfig = useWagmiClient();
  const refreshSession = useRefreshSession();
  const [registerWalletMutation] = mutation;

  const registerWallet = useCallback(
    async (address, connector, isCreationEnabled = false) => {
      // TODO: handle case when signature fails for isLoading and error.
      const signature = await signMessage(wagmiConfig, {
        account: address,
        connector,
        // Message must match, otherwise things explode
        message: `Register digital wallet at address: ${address}`,
      });
      return new Promise((resolve, reject) => {
        registerWalletMutation({
          onCompleted: resolve,
          onError: reject,
          variables: { address, isCreationEnabled, signature },
        });
      }).then(() => startTransition(refreshSession));
    },
    [registerWalletMutation, refreshSession, wagmiConfig]
  );

  return [registerWallet, mutation];
}
