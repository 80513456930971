import { NftSaleStatusEnum } from 'types/__generated__/graphql';

import { PurchasableNFTType } from 'types/graphql/NFT';
import { hasDateBetween } from 'utils/hasDatePassed';
import {
  getNFTPresaleState,
  getNFTPrivateSaleState,
  isNFTPendingSale,
} from 'utils/nftUtils';

export interface ProductStateProps {
  nft: PurchasableNFTType;
  showDropsTimer: boolean;
  showProductAuctionTimer: boolean;
  showRankedAuctionTimer: boolean;
}

export default function useProductState({
  nft,
  showDropsTimer,
  showProductAuctionTimer,
  showRankedAuctionTimer,
}: ProductStateProps) {
  const { showPresale, isPresaleActive, isPresaleEligible } =
    getNFTPresaleState(
      !!nft.listing?.liveSale?.custodialPresalePriceUsd,
      nft.metadata.dropMetadata
    );
  const isPendingSale = isNFTPendingSale(nft.listing);
  const { showPrivateSale, isPrivateSalePending } = getNFTPrivateSaleState(nft);

  const isLiveSale =
    nft.listing.liveSale?.listingStatus === NftSaleStatusEnum.Live;
  const isRankedAuctionLiveOrDoesNotExist =
    !showRankedAuctionTimer ||
    hasDateBetween(
      nft.listing.rankedAuction.startsAt,
      nft.listing.rankedAuction.endsAt
    );
  const canBuy =
    isLiveSale &&
    !showDropsTimer &&
    isRankedAuctionLiveOrDoesNotExist &&
    !isPrivateSalePending;
  const canOffer =
    nft.listing.availableForBid &&
    !nft.listing.disableBidAccepts &&
    isRankedAuctionLiveOrDoesNotExist;
  const showBuyNow =
    !isPendingSale &&
    (nft.listing.availableForBid || nft.listing.availableForPurchase) &&
    canBuy;
  const showReservePrice =
    nft.listing.hasReservePrice &&
    !nft.listing.liveSale &&
    !nft.listing.productAuction;
  const showPrice =
    !showBuyNow &&
    !showReservePrice &&
    ((!!nft.listing.liveSale &&
      !isPendingSale &&
      nft.listing.liveSale.listingStatus !==
        NftSaleStatusEnum.PendingCancelled) ||
      (nft.listing.hasReservePrice && !nft.listing.productAuction));

  return {
    canBuy,
    canOffer,
    isPendingSale,
    isPresaleActive,
    isPresaleEligible,
    isPrivateSalePending,
    showBuyNow,
    showPresale: showPresale && !showRankedAuctionTimer,
    showPrice,
    showPrivateSale,
    showReservePrice,
    showTimer:
      showDropsTimer || showProductAuctionTimer || showRankedAuctionTimer,
  } as const;
}
