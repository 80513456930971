import { useCallback, useState } from 'react';
import { PreloadedQuery } from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { AccountStripeCardsQuery } from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import {
  ContractNameEnum,
  ProductTypeBidEnum,
} from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import {
  DEFAULT_FORM_STATE,
  useCreditCardPaymentFormState,
} from 'hooks/product/usePaymentFormState';
import {
  usePurchaseWithCreditCard,
  usePurchaseWithEthereum,
} from 'hooks/product/usePurchaseProduct';
import useHasDatePassed from 'hooks/useHasDatePassed';
import useLoadQuery from 'hooks/useLoadQuery';
import { CuratorDialogWithAction } from 'pages/product/ProductPrivateCurator';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import { PurchasableNFTType } from 'types/graphql/NFT';
import { getGeneralError } from 'utils/flows/purchaseBids';
import { getNFTPresaleState, getNFTPrivateSaleState } from 'utils/nftUtils';

import ProductPendingOnChain from '../../ProductPendingOnChain';
import PaymentViewBody from './PaymentViewBody';
import PaymentViewDialog from './PaymentViewDialog';
import PaymentViewFooter from './PaymentViewFooter';
import PaymentViewForm from './PaymentViewForm';
import PaymentViewPricing from './PaymentViewPricing';
import PaymentViewReserveLockTimer from './PaymentViewReserveLockTimer';
import WireTransferConfirmation from './WireTransferConfirmation';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

export enum WireTransferViewType {
  CONFIRMATION = 'confirmation',
  INITIAL = 'initial',
}

interface PaymentViewPurchaseProps {
  nft: PurchasableNFTType;
  onClose: () => void;
  onSuccess: () => void;
  reserveLockEndDate: Date;
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
}

function PaymentViewPurchase({
  nft,
  reserveLockEndDate,
  depositFundContractQueryRef,
  stripeCardsQueryRef,
  onClose,
  onSuccess,
}: PaymentViewPurchaseProps & {
  depositFundContractQueryRef: PreloadedQuery<NFTContractQuery>;
}) {
  const [paymentMethod, setPaymentMethod] = useState<ProductTypeBidEnum>(
    ProductTypeBidEnum.Creditcard
  );
  const [paymentFormState, updateFormState] = useCreditCardPaymentFormState();
  const [wireTransferView, setWireTransferView] =
    useState<WireTransferViewType>(WireTransferViewType.INITIAL);
  const isReserveLockExpired = useHasDatePassed(reserveLockEndDate);

  const [purchaseWithCreditCard, purchaseWithCreditCardState] =
    usePurchaseWithCreditCard({
      ...paymentFormState,
      nft,
      onSuccess,
    });

  const [
    purchaseWithEthereum,
    purchaseWithEthereumState,
    purchaseWithEthereumTransaction,
    resetPurchaseWithEthereumTransaction,
  ] = usePurchaseWithEthereum({
    depositFundContractQueryRef,
    discountCode: paymentFormState.discountCode,
    nft,
    onSuccess,
  });

  const handleEthereumTransactionSuccess = useCallback(() => {
    if (purchaseWithEthereumTransaction) {
      resetPurchaseWithEthereumTransaction();
    }
    onSuccess();
  }, [
    onSuccess,
    purchaseWithEthereumTransaction,
    resetPurchaseWithEthereumTransaction,
  ]);

  const confirmWireTransfer = useCallback(() => {
    setWireTransferView(WireTransferViewType.CONFIRMATION);
  }, []);

  const purchaseState =
    paymentMethod === ProductTypeBidEnum.Creditcard
      ? purchaseWithCreditCardState
      : paymentMethod === ProductTypeBidEnum.Ether
      ? purchaseWithEthereumState
      : DEFAULT_FORM_STATE;

  const { isPresaleActive, isPresaleEligible } = getNFTPresaleState(
    !!(paymentMethod === ProductTypeBidEnum.Creditcard
      ? nft.listing.liveSale?.custodialPresalePriceUsd
      : nft.listing.liveSale?.custodialPresalePriceEth),
    nft.metadata.dropMetadata
  );

  const generalError = getGeneralError(purchaseState);
  const isPrivateSale = getNFTPrivateSaleState(nft);
  const isWireTransfer = paymentMethod === ProductTypeBidEnum.WireTransfer;
  const purchaseCtaTitle = isWireTransfer
    ? 'Confirm Wire Initiation'
    : 'Purchase';

  const purchaseAction =
    paymentMethod === ProductTypeBidEnum.Creditcard
      ? purchaseWithCreditCard
      : paymentMethod === ProductTypeBidEnum.WireTransfer
      ? confirmWireTransfer
      : purchaseWithEthereum;

  return wireTransferView === WireTransferViewType.CONFIRMATION ? (
    <WireTransferConfirmation
      nft={nft}
      onClose={onClose}
      onSuccess={onSuccess}
      setWireTransferView={setWireTransferView}
      reserveLockEndDate={reserveLockEndDate}
    />
  ) : (
    <PaymentViewDialog title="Make a Purchase" onClose={onClose}>
      {purchaseWithEthereumTransaction ? (
        <ProductPendingOnChain
          nft={nft}
          queryVariables={{
            txtId: purchaseWithEthereumTransaction,
          }}
          onSuccess={handleEthereumTransactionSuccess}
        />
      ) : (
        <>
          <PaymentViewBody>
            {generalError ? (
              <ErrorDisplay
                error={generalError}
                className={CSSGlobal.TextAlign.Centered}
              />
            ) : null}
            <PaymentViewForm
              nft={nft}
              paymentMethod={paymentMethod}
              stripeCardsQueryRef={stripeCardsQueryRef}
              onPaymentMethodChange={setPaymentMethod}
              onFormChange={updateFormState}
              acceptFiat={nft.listing.acceptsFiatPurchase}
              acceptWireTransfer={isPrivateSale?.showPrivateSale}
            />
            {isWireTransfer ? (
              <div
                className={joinClasses(
                  MPFonts.paragraphSmall,
                  CSSMargin.TOP[24]
                )}
              >
                Once you complete the wire transfer with your bank, please click
                the button below. Our finance team will let you know once we
                have received the funds (generally takes 1-3 business days) to
                complete the purchase.
              </div>
            ) : (
              <PaymentViewPricing
                amountInEth={
                  isPresaleActive && isPresaleEligible
                    ? nft.listing.liveSale.custodialPresalePriceEth
                    : nft.listing.lowestAskInEth
                }
                amountInUsd={
                  isPresaleActive && isPresaleEligible
                    ? nft.listing.liveSale.custodialPresalePriceUsd
                    : nft.listing.lowestAskInUsd
                }
                paymentMethod={paymentMethod}
                error={
                  purchaseState.mutationError || purchaseState.simulationError
                }
              />
            )}
          </PaymentViewBody>

          <PaymentViewFooter
            submitTitle={purchaseCtaTitle}
            onSubmit={purchaseAction}
            isDisabled={isReserveLockExpired || purchaseState.isDisabled}
            isLoading={purchaseState.isValidating}
          >
            {!!isWireTransfer &&
              nft.listing.liveSale?.curators.map((curator) => (
                <CuratorDialogWithAction
                  key={curator.pk}
                  fullName={curator.fullName}
                  username={curator.username}
                />
              ))}

            <PaymentViewReserveLockTimer
              classes={{
                container: styles.reserved,
                text: MPFonts.paragraphSmall,
              }}
              reserveLockEndDate={reserveLockEndDate}
              stringTemplates={{
                expired: 'Your reservation has expired. Close and try again.',
                notExpired: 'Reserved for the next $[minutes]m $[seconds]s',
              }}
            />
          </PaymentViewFooter>
        </>
      )}
    </PaymentViewDialog>
  );
}

export default function PaymentViewWrapper(props: PaymentViewPurchaseProps) {
  const [depositFundContractQueryRef] = useLoadQuery<NFTContractQuery>(
    NFTContractQueryType,
    {
      name: ContractNameEnum.DepositFund,
    }
  );

  return depositFundContractQueryRef ? (
    <PaymentViewPurchase
      {...props}
      depositFundContractQueryRef={depositFundContractQueryRef}
    />
  ) : null;
}
