import { MouseEventHandler } from 'react';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import WalletActionButton from 'components/WalletActionButton';

import * as modalStyles from '../../../css/pages/product/ProductSetBuyNow.module.css';

interface ProductOwnerMessageProps {
  isWalletActionButton: boolean;
  message: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  primaryCTAName: string;
  ctaDisabled?: boolean;
  isMessageCentered?: boolean;
  simulationErrorMessage?: string;
}

function ProductOwnerMessage({
  message,
  onClick,
  primaryCTAName,
  simulationErrorMessage,
  isWalletActionButton = false,
  ctaDisabled = false,
  isMessageCentered = false,
}: ProductOwnerMessageProps) {
  return (
    <div>
      <div className={modalStyles.productSellContainer}>
        {!!simulationErrorMessage || !isWalletActionButton ? (
          <div
            className={joinClasses(
              modalStyles.productSellMessageContainer,
              MPFonts.paragraphNormal,
              isMessageCentered ? modalStyles.productMessageCentered : ''
            )}
          >
            {message}
          </div>
        ) : (
          <div
            className={joinClasses(
              modalStyles.productSellMessageContainer,
              MPFonts.paragraphNormal,
              MPColorClass.ErrorMain,
              isMessageCentered ? modalStyles.productMessageCentered : ''
            )}
          >
            {simulationErrorMessage}
          </div>
        )}
      </div>
      <div className={modalStyles.productSellFooter}>
        <div className={modalStyles.productSellListingBtnContainer}>
          {isWalletActionButton ? (
            <WalletActionButton
              variant="primary"
              onClick={onClick}
              fullWidth
              size="large"
              disabled={ctaDisabled || !!simulationErrorMessage}
            >
              {primaryCTAName}
            </WalletActionButton>
          ) : (
            <MPActionButton
              variant="primary"
              fullWidth
              onClick={onClick}
              disabled={ctaDisabled}
            >
              {primaryCTAName}
            </MPActionButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductOwnerMessage;
