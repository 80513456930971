import { useCallback, useEffect, useRef, useState, useTransition } from 'react';
import { noop } from 'lodash';
import { Hash } from 'viem';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ErrorDisplay from 'components/Error';
import { useSelectWalletConnectDialog } from 'components/wallet/SelectWalletConnectDialog';
import { WalletActionButton } from 'components/wallet/WalletClickActionComponent';
import CSSMargin from 'types/enums/css/Margin';
import isWalletError from 'utils/errors/wallet';
import { ContractActionObject } from 'utils/jwt/walletUtils';

import PendingApprovalDialog from './PendingApprovalDialog';

interface ApprovalProps {
  explanation: string;
  fetchParams: [boolean, () => void, boolean];
  manager: ContractActionObject;
  title: string;
  walletAddress: Hash;
  className?: string;
}

export default function Approval({
  title,
  explanation,
  fetchParams,
  manager,
  walletAddress,
  className,
}: ApprovalProps) {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [isTransitioning] = useTransition();
  const [isPolling, setIsPolling] = useState(false);
  const [pollVal, setPollVal] = useState(0);
  const [prevVal, setPrevVal] = useState<boolean>(true);
  const [didUserInitiateApproval, setUserInitiatedApproval] =
    useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<Hash>();

  const [isApproved, refetch, isFetching] = fetchParams;

  // Linter automatically turns this into an arrow function.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPolling) {
      if (isApproved === undefined || isApproved === !prevVal) {
        if (!isFetching) {
          refetch?.();
        }
        const id = setTimeout(() => setPollVal((prev) => prev + 1), 1000);
        return () => clearTimeout(id);
      }
      setPrevVal(!prevVal);
      setIsPolling(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isPolling, pollVal, prevVal, refetch, isFetching]);

  const toggleApprovalRef = useRef(noop);

  const [openSelectWalletDialog, selectWalletDialogJSX] =
    useSelectWalletConnectDialog(
      walletAddress,
      useCallback(() => toggleApprovalRef.current(), [])
    );

  toggleApprovalRef.current = useCallback(
    async (ev) => {
      ev?.preventDefault();
      if (isApproved === undefined) return;
      const _prevVal = !isApproved;
      try {
        setIsLoading(true);
        setError(undefined);
        setTransactionId(await manager.mutate.writeAsync());
        setPrevVal(_prevVal);
        setIsPolling(true);
        setUserInitiatedApproval(true);
      } catch (e) {
        if (
          isWalletError.ConnectorNotConnected(e) ||
          isWalletError.ConnectorAccountNotFoundError(e)
        ) {
          openSelectWalletDialog();
        } else {
          setError(e);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [isApproved, manager, openSelectWalletDialog]
  );

  return isApproved !== false && !didUserInitiateApproval ? null : (
    <div className={className}>
      <div
        className={joinClasses(CSSMargin.BOTTOM[4], MPFonts.textSmallMedium)}
      >
        {title}
      </div>
      <div
        className={joinClasses(CSSMargin.BOTTOM[20], MPFonts.paragraphXSmall)}
      >
        {explanation}
      </div>
      <ErrorDisplay error={error} className={CSSMargin.BOTTOM[16]} />
      <WalletActionButton
        variant="primary"
        fullWidth
        size="large"
        onAction={toggleApprovalRef.current}
        requiredAddress={walletAddress}
        isLoading={
          isLoading || isTransitioning || isPolling || isApproved === undefined
        }
      >
        Approve Contract
      </WalletActionButton>
      <PendingApprovalDialog
        isPolling={isPolling}
        approvedContract={didUserInitiateApproval}
        setApprovedContract={setUserInitiatedApproval}
        transactionId={transactionId}
      />
      {selectWalletDialogJSX}
    </div>
  );
}
