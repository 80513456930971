import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import { Hash } from 'viem';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { LoaderIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import StackStateDialog, {
  useStackStateDialogContext,
} from 'components/dialogs/StackStateDialog';
import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { getTransactionUrl } from 'utils/nftUtils';

import { useGetApprovals } from '../PendingApprovalsManager';

interface PendingApprovalDialogProps {
  approvedContract: boolean;
  isPolling: boolean;
  setApprovedContract: Dispatch<SetStateAction<boolean>>;
  transactionId: Hash;
}

export default function PendingApprovalDialog({
  approvedContract,
  setApprovedContract,
  isPolling,
  transactionId,
}: PendingApprovalDialogProps) {
  const session = useSession();
  const stackContext = useStackStateDialogContext();

  const setApprovedContractFalse = useCallback(
    () => setApprovedContract(false),
    [setApprovedContract]
  );
  const [hasPendingApprovals] = useGetApprovals();
  const actionButton = useMemo(
    () =>
      !isPolling ? (
        <MPActionButton
          variant="primary"
          fullWidth
          size="large"
          onClick={
            hasPendingApprovals ? setApprovedContractFalse : stackContext.close
          }
        >
          {hasPendingApprovals ? 'Review Next Approval' : 'Continue'}
        </MPActionButton>
      ) : undefined,
    [
      isPolling,
      hasPendingApprovals,
      setApprovedContractFalse,
      stackContext.close,
    ]
  );
  return approvedContract ? (
    <StackStateDialog
      shouldBreak
      title={
        isPolling
          ? 'Approving Contract'
          : hasPendingApprovals
          ? 'Approved Contract'
          : 'All Required Contracts Approved'
      }
      open
      onClose={noop}
      actionButton={actionButton}
    >
      {isPolling ? (
        <div className={joinClasses(CSSGlobal.Flex.CenteredCol, CSSGap[16])}>
          <LoaderIcon fontSize="68" />
          <div>
            We are now waiting for the transaction to confirm and will update
            here shortly. Transaction details can be seen on&nbsp;
            <a
              className="anchorV2"
              href={getTransactionUrl(
                session.contractNetworkUrl,
                transactionId
              )}
              target="_blank"
              rel="noreferrer"
            >
              Etherscan
            </a>
            .
          </div>
        </div>
      ) : hasPendingApprovals ? (
        <div className={MPFonts.paragraphSmall}>
          Successful contract approval. Please review the next approval request.
        </div>
      ) : (
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.paragraphSmall
          )}
        >
          Your contract approval was successful
        </div>
      )}
    </StackStateDialog>
  ) : null;
}
