import { useMemo, useState } from 'react';
import { useFullScreenHandle as useReactFullScreenHandle } from 'react-full-screen';

export default function useFullScreenHandle() {
  // Screw Apple and the iphone
  const screenHandle = useReactFullScreenHandle();
  const [isActive, setIsActive] = useState(false);
  return useMemo(
    () =>
      document.fullscreenEnabled
        ? screenHandle
        : (() =>
            new Proxy(screenHandle, {
              get(target, prop, receiver) {
                if (prop === 'active') {
                  return isActive;
                }
                if (prop === 'enter') {
                  return function iphoneEnter() {
                    setIsActive(true);
                    const video =
                      screenHandle.node.current.getElementsByTagName(
                        'video'
                      )[0];
                    // @ts-ignore
                    video.webkitEnterFullScreen?.();
                    video.addEventListener('webkitendfullscreen', () => {
                      setIsActive(false);
                    });
                  };
                }
                if (prop === 'exit') {
                  return function iphoneExit() {
                    setIsActive(false);
                    screenHandle.node.current
                      .getElementsByTagName('video')[0]
                      // @ts-ignore
                      .webkitExitFullscreen?.();
                  };
                }
                return Reflect.get(target, prop, receiver);
              },
            }))(),
    [screenHandle, isActive]
  );
}
