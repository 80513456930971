import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { Collapse } from '@mui/material';

import { joinClasses, useOnEnterKey, UUID } from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { MPIconButton } from '../..';
import { AddIcon, RemoveIcon } from '../icons';

import * as styles from '../css/collapsibles/MPCollapsible.module.css';

export interface MPCollapsibleProps {
  children: ReactNode;
  className?: string;

  /**
   * Pass in a value to control this component.
   */
  collapsed?: boolean | undefined;

  /**
   * Whether or not the Collapsible is closed by default, only applies if
   * collapsible is uncontrolled.
   */
  isCollapsedByDefault?: boolean;
  onToggle?: () => void;
  showIcon?: boolean;
  title?: string;
}

export default function MPCollapsible({
  children,
  title,
  className,
  collapsed,
  showIcon = true,
  isCollapsedByDefault = true,
  onToggle,
}: MPCollapsibleProps) {
  const [open, setOpen] = useState<boolean>(!isCollapsedByDefault);
  const isControlled = collapsed !== undefined;
  const isOpen = isControlled ? !collapsed : open;

  const Icon = isOpen ? RemoveIcon : AddIcon;

  const handleToggle = useCallback(
    (event: MouseEvent) => {
      if (isControlled) return;
      event.preventDefault();
      setOpen((prevValue) => !prevValue);
      onToggle?.();
    },
    [setOpen, isControlled, onToggle]
  );
  const onEnter = useOnEnterKey(handleToggle);

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const ariaId = useMemo(UUID.weak, []);

  return (
    <div
      className={joinClasses(styles.container, className, {
        [styles.open]: isOpen,
      })}
    >
      {!!title && (
        <div
          className={joinClasses(styles.actionContainer, {
            [styles.disabled]: isControlled,
          })}
          onClick={handleToggle}
          aria-expanded={isOpen}
          role="combobox"
          tabIndex={0}
          aria-controls={ariaId}
          onKeyPress={onEnter}
        >
          <div className={joinClasses(MPFonts.headline4, styles.title)}>
            {title}
          </div>

          {showIcon ? (
            <MPIconButton>
              <Icon />
            </MPIconButton>
          ) : null}
        </div>
      )}

      <Collapse id={ariaId} in={isOpen}>
        {children}
      </Collapse>
    </div>
  );
}
