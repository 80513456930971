import { useCallback, useState, useTransition } from 'react';
import { useMutation } from 'react-relay';
import { useSignMessage } from 'wagmi';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountCreatePayoutWallet, {
  AccountCreatePayoutWalletMutation,
} from 'graphql/__generated__/AccountCreatePayoutWalletMutation.graphql';

import ErrorDisplay from 'components/Error';
import NewWalletConnectDialog from 'components/wallet/NewWalletConnectDialog';
import useWallets from 'hooks/session/useWallets';
import useSession, { useRefreshSession } from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import useRegister from 'hooks/wallet/mutations/useRegister';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import { hasUserRegisteredWalletAddress } from 'utils/areSameAddress';
import isWalletError from 'utils/errors/wallet';
import promisifyMutation from 'utils/promisifyMutation';

import Wallet from '../wallets/Wallet';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

export default function ConnectDigitalWalletSection() {
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const refreshSession = useRefreshSession();
  const account = useCachedAccount();
  const session = useSession();
  const [wallets] = useWallets();
  const [registerWallet] = useRegister();
  const [isTransitioning, startTransition] = useTransition();
  const { signMessageAsync } = useSignMessage();
  const [
    isConnectNewCollectionWalletDialogOpen,
    openConnectNewCollectionWalletDialog,
    closeConnectNewCollectionWalletDialog,
  ] = useSimpleDialogController({ preventDefault: true });

  const [createPayoutWallet] = useMutation<AccountCreatePayoutWalletMutation>(
    AccountCreatePayoutWallet
  );

  const connectDigitalWallet = useCallback(async () => {
    setIsLoading(true);
    setError(undefined);
    try {
      const address = account?.address;
      if (!hasUserRegisteredWalletAddress(address, wallets)) {
        try {
          await registerWallet(address, account.connector);
        } catch (e) {
          throw isWalletError.userRejected(e)
            ? new Error(
                'You must verify this wallet before it can be used to withdraw funds'
              )
            : e;
        }
      }
      const signature = await signMessageAsync({
        account: address,
        message: `verify wallet`,
      });
      await promisifyMutation(createPayoutWallet)({
        signature,
        walletAddress: address,
      });
      startTransition(refreshSession);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    account,
    wallets,
    createPayoutWallet,
    refreshSession,
    registerWallet,
    signMessageAsync,
  ]);

  const closeAndCheckAccountDialog = useCallback(() => {
    closeConnectNewCollectionWalletDialog();
    window.setTimeout(() => {
      if (account.address) connectDigitalWallet();
    }, 0);
  }, [
    closeConnectNewCollectionWalletDialog,
    connectDigitalWallet,
    account.address,
  ]);

  return (
    <>
      <ErrorDisplay error={error} />
      {!session.account.ethPayoutWallet && (
        <MPActionButton
          size="large"
          fullWidth
          variant="primary"
          onClick={
            account?.address
              ? connectDigitalWallet
              : openConnectNewCollectionWalletDialog
          }
          isLoading={isLoading || isTransitioning}
        >
          Connect&nbsp;Digital&nbsp;Wallet
        </MPActionButton>
      )}
      {!!isConnectNewCollectionWalletDialogOpen && (
        <NewWalletConnectDialog
          title="Connect Wallet"
          description="Choose a wallet to connect. You will need to connect and verify this wallet in order to continue."
          isCreationEnabled={false}
          onError={() => {}}
          open={isConnectNewCollectionWalletDialogOpen}
          onClose={closeAndCheckAccountDialog}
        />
      )}
      {!!session.account.ethPayoutWallet &&
        (session.account.ethPayoutWallet.coinbaseCustomerId ? (
          <div
            className={joinClasses(
              pageStyles.invalidActionInstructions,
              MPFonts.paragraphSmall
            )}
          >
            Disconnect your Coinbase Account if you want to use a Digital Wallet
          </div>
        ) : (
          <Wallet
            wallet={wallets.find(
              (wallet) =>
                wallet.address.toLowerCase() ===
                session.account.ethPayoutWallet.address.toLowerCase()
            )}
            isPayout
          />
        ))}
    </>
  );
}
