import { ComponentProps } from 'react';
import { SvgIcon } from '@mui/material';

export default function MetaMaskIcon(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.8033 1.92773L13.3086 8.23684L14.8795 4.51455L21.8033 1.92773Z"
        fill="#E2761B"
        stroke="#E2761B"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.18939 1.92773L10.6158 8.2966L9.12172 4.51455L2.18939 1.92773ZM18.7433 16.5522L16.4809 20.0184L21.3216 21.3502L22.7132 16.6291L18.7433 16.5522ZM1.29297 16.6291L2.67602 21.3502L7.5167 20.0184L5.2543 16.5522L1.29297 16.6291Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.24343 10.6956L5.89453 12.736L10.7011 12.9495L10.5303 7.78437L7.24343 10.6956ZM16.7455 10.6956L13.4159 7.72461L13.305 12.9495L18.1029 12.736L16.7455 10.6956ZM7.51663 20.0184L10.4023 18.6097L7.90935 16.6632L7.51663 20.0184ZM13.5867 18.6097L16.4808 20.0184L16.0796 16.6632L13.5867 18.6097Z"
        fill="#E4761B"
        stroke="#E4761B"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4838 20.0239L13.5896 18.6152L13.8201 20.502L13.7945 21.296L16.4838 20.0239ZM7.51953 20.0239L10.2088 21.296L10.1917 20.502L10.4052 18.6152L7.51953 20.0239Z"
        fill="#D7C1B3"
        stroke="#D7C1B3"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2513 15.423L7.84375 14.7144L9.54268 13.9375L10.2513 15.423ZM13.7431 15.423L14.4517 13.9375L16.1591 14.7144L13.7431 15.423Z"
        fill="#233447"
        stroke="#233447"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5163 20.0226L7.9261 16.5564L5.25391 16.6333L7.5163 20.0226ZM16.0707 16.5564L16.4805 20.0226L18.7429 16.6333L16.0707 16.5564ZM18.1026 12.7402L13.3046 12.9537L13.7486 15.421L14.4572 13.9355L16.1646 14.7124L18.1026 12.7402ZM7.84072 14.7124L9.54819 13.9355L10.2483 15.421L10.7007 12.9537L5.89421 12.7402L7.84072 14.7124Z"
        fill="#CD6116"
        stroke="#CD6116"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.89453 12.7402L7.90935 16.6674L7.84105 14.7124L5.89453 12.7402ZM16.165 14.7124L16.0796 16.6674L18.1029 12.7402L16.165 14.7124ZM10.7011 12.9537L10.2486 15.421L10.812 18.3322L10.9401 14.4989L10.7011 12.9537ZM13.305 12.9537L13.0744 14.4904L13.1769 18.3322L13.7489 15.421L13.305 12.9537Z"
        fill="#E4751F"
        stroke="#E4751F"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7516 15.4176L13.1796 18.3288L13.5894 18.6106L16.0823 16.664L16.1677 14.709L13.7516 15.4176ZM7.84375 14.709L7.91205 16.664L10.405 18.6106L10.8147 18.3288L10.2513 15.4176L7.84375 14.709Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7945 21.2935L13.8201 20.4996L13.6067 20.3118H10.3881L10.1917 20.4996L10.2088 21.2935L7.51953 20.0215L8.45864 20.7898L10.3625 22.1131H13.6323L15.5446 20.7898L16.4838 20.0215L13.7945 21.2935Z"
        fill="#C0AD9E"
        stroke="#C0AD9E"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5854 18.6157L13.1756 18.334H10.8107L10.4009 18.6157L10.1875 20.5025L10.3839 20.3146H13.6024L13.8159 20.5025L13.5854 18.6157Z"
        fill="#161616"
        stroke="#161616"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1578 8.64663L22.8835 5.16339L21.7993 1.92773L13.5863 8.0234L16.7452 10.6956L21.2102 12.0018L22.2005 10.8493L21.7737 10.5419L22.4567 9.91869L21.9273 9.5089L22.6103 8.98812L22.1578 8.64663ZM1.11328 5.16339L1.83896 8.64663L1.37794 8.98812L2.06093 9.5089L1.54015 9.91869L2.22314 10.5419L1.79627 10.8493L2.77806 12.0018L7.2431 10.6956L10.4019 8.0234L2.18899 1.92773L1.11328 5.16339Z"
        fill="#763D16"
        stroke="#763D16"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2106 12.0087L16.7456 10.7025L18.103 12.743L16.0797 16.6701L18.7433 16.636H22.7132L21.2106 12.0087ZM7.2435 10.7025L2.77847 12.0087L1.29297 16.636H5.2543L7.90942 16.6701L5.8946 12.743L7.2435 10.7025ZM13.305 12.9564L13.5868 8.03034L14.8844 4.52148H9.12172L10.4023 8.03034L10.7011 12.9564L10.8036 14.5102L10.8121 18.3349H13.177L13.194 14.5102L13.305 12.9564Z"
        fill="#F6851B"
        stroke="#F6851B"
        strokeWidth="0.0853735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
