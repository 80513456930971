
.termsAndConditions {
  padding: 22px 0 8px;
  text-align: center;
}

.walletLinkSection {
  color: var(--color-SolidNeutralGray5);
  padding: 22px 0;
  text-align: center;
}
