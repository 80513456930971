import { ReactNode } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type VariantType = 'filled' | 'outlined';

interface CommentIconProps extends SvgIconProps {
  variant?: VariantType;
}

const variantsMap: Record<VariantType, ReactNode> = {
  filled: <path d="M0 0V24L4.8 19.2H24V0H0Z" fill="currentColor" />,
  outlined: (
    <path
      d="M19.8 4.2V15H4.2V4.2H19.8ZM24 0H0V24L4.8 19.2H24V0Z"
      fill="currentColor"
    />
  ),
};

export default function CommentIcon({
  variant = 'filled',
  ...props
}: CommentIconProps) {
  return <SvgIcon {...props}>{variantsMap[variant]}</SvgIcon>;
}
