enum DropVideoPlayerMode {
  COMPACT = 'COMPACT',
  HERO = 'HERO',
  LARGE = 'LARGE',
  STANDARD = 'STANDARD',
}

/**
 * Note: Product final asks do not match Design
 *
 * Compact: Not implemented, @Nikita to fill in with any information, intention is that this
 * is an inlined subsection on cards
 *
 * Standard: intended on as a subsection (particularly in more about and as a PDP subsection), in which
 * clicking
 *
 * Large: This would be standard fully interactible video player you see on most sites
 *
 * Hero: This is yet another special product version, which is intended to showcase the video. Product ask is that
 * when inlined this shows no toolbar, when interacted with, on mobile should expand out into full screen, and on
 * desktop is essentially large all the way through.
 */
export default DropVideoPlayerMode;
