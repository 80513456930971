.button {
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navColorTheme {
  background-color: var(--color-commonWhite);
  color: var(--color-SolidNeutralGray5);
}

.defaultColorTheme {
  background-color: var(--color-backgroundDefault);
  color: var(--color-SolidNeutralGray5);
}

.grayColorTheme {
  background-color: var(--color-SolidNeutralGray5);
  color: var(--color-commonWhite);
}

.darkColorTheme {
  background-color: var(--color-primaryDark);
  color: var(--color-commonWhite);
}

.goldColorTheme {
  background-color: var(--color-goldMain);
  color: var(--color-commonWhite);
}
