import { BigNumber } from 'ethers';

import { parseUnits } from 'ethers/lib/utils.js';

export const MAX_DECIMAL_PRECISION = 6;

export function getSafeWeiAmountFromEthUserInput(
  amount: number | undefined
): BigNumber {
  return amount && amount > 0
    ? parseUnits(amount.toFixed(MAX_DECIMAL_PRECISION).toString())
    : BigNumber.from('0');
}

export function getSafeNumberFromEthPrice(price: number) {
  return parseFloat(price.toFixed(MAX_DECIMAL_PRECISION));
}
