@keyframes marqueeAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-50% - var(--gap, 0) / 2));
  }
}

.marqueeContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 24px;
}

.marqueeContainer.isAnimating {
  width: calc(100% + 2 * var(--horz-padding, 24px));
  left: calc(-1 * var(--horz-padding, 24px));
}

.marquee {
  --gap: 24px;
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  animation: marqueeAnimation linear infinite;
  animation-duration: 10s;
  gap: var(--gap);
}

.marquee.pausable:hover {
  animation-play-state: paused;
}
