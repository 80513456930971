import { createContext, ReactNode, useContext, useMemo } from 'react';

type Primitive = string | boolean | number | bigint | symbol | null | undefined;

const InnerTrackingContext = createContext<Record<string, any>>({});

export function useTrackingContext() {
  return useContext(InnerTrackingContext);
}

type TrackingContextProps = {
  [P in string]: Primitive | ReactNode;
};

interface ChildrenProps {
  children: ReactNode | undefined;
}

// We'll enforce only allowing primitives as way of preventing the tracking context
// from containing all the data.
export function TrackingContext({
  children,
  ...props
}: TrackingContextProps & ChildrenProps) {
  const proto = useTrackingContext();
  const data = useMemo(() => Object.create(proto), [proto]);

  Object.keys(data).forEach((key) => {
    delete data[key];
  });
  Object.entries(props).forEach(([key, value]) => {
    data[key] = value;
  });

  return (
    <InnerTrackingContext.Provider value={data}>
      {children}
    </InnerTrackingContext.Provider>
  );
}
