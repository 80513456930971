import { useCallback } from 'react';

import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { HomepageEditorialTilesQuery$data } from 'graphql/__generated__/HomepageEditorialTilesQuery.graphql';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';

import StandardCard from './StandardCard';

import * as styles from 'css/components/cards/EditorialCard.module.css';

interface EditorialCardProps {
  editorial: HomepageEditorialTilesQuery$data['nftEditorials']['edges'][number]['node'];
  className?: string;
}

function EditorialCard({ editorial, className }: EditorialCardProps) {
  const { backgroundImageUrl, description, title, type, viewUrl } = editorial;
  const track = useHomepageGTM();

  const handleClick = useCallback(
    () =>
      track.clickCard(CardType.EditorialCard, editorial.pk, editorial.title),
    [track, editorial.pk, editorial.title]
  );

  return (
    <StandardCard
      className={joinClasses(
        CSSGlobal.Flex.Col,
        CSSGap[18],
        MPColorClass.CommonBlack,
        MPBackgroundColorClass.CommonWhite,
        styles.container,
        className
      )}
      to={viewUrl}
      disableInAppNavigate
      style={{
        '--standardCard-desktopHeight': '506px',
        '--standardCard-mobileHeight': '475px',
        '--standardCard-monitorHeight': '581px',
      }}
      onClick={handleClick}
    >
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[2])}>
        {!!type && (
          <span
            className={joinClasses(
              MPFonts.textSmallMedium,
              MPColorClass.GoldMain
            )}
          >
            {type}
          </span>
        )}
        {!!title && (
          <span
            className={joinClasses(
              MPFonts.textNormalMedium,
              CSSGlobal.Ellipsis,
              styles.paragraph,
              styles.title
            )}
          >
            {title}
          </span>
        )}
      </div>

      <div
        className={styles.media}
        style={
          {
            '--editorialCard-backgroundImageUrl': `url(${backgroundImageUrl})`,
          } as any
        }
      />

      <div
        className={joinClasses(
          MPFonts.paragraphSmall,
          CSSGlobal.Ellipsis,
          styles.description,
          styles.paragraph
        )}
      >
        {description}
      </div>

      <div>
        <span
          className={joinClasses(
            MPFonts.textSmallBold,
            CSSGlobal.Cursor.Pointer,
            'invisibleAnchor'
          )}
        >
          Learn More
        </span>
      </div>
    </StandardCard>
  );
}

export default EditorialCard;
