import { defaults } from 'lodash';
/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilValue } from 'recoil';

import { ETH_SYMBOL } from 'constants/Symbols';
import { SelectedCurrencyDisplaySymbol } from 'state/SelectedCurrencyDisplayMode';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import nFormatter from 'utils/string/nFormatter';

import currencyFormatter from './currentFormatter';

interface GeneratePriceStringOptions {
  isMobile?: boolean;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  useUserCurrency?: boolean;
}

export const generatePriceString = (
  price: number | bigint,
  currencyDisplayMode: CurrencyDisplayMode,
  options: GeneratePriceStringOptions = {}
) => {
  if (price === null || price === undefined) {
    return '0';
  }

  const displayModeIsCrypto = currencyDisplayMode === CurrencyDisplayMode.ETH;
  defaults(options, {
    isMobile: false,
    maximumFractionDigits: displayModeIsCrypto ? 5 : 2,
    minimumFractionDigits: displayModeIsCrypto ? 2 : 2,
    useUserCurrency: false,
  } as GeneratePriceStringOptions);

  // ! TODO: Urgent, refactor this so we are not violating rules of hooks. useRecoilValue should be
  // ! called at the root level of a component func or a custom React hook func.
  let cryptoSymbol = '';
  if (options.useUserCurrency) {
    cryptoSymbol = displayModeIsCrypto
      ? useRecoilValue(SelectedCurrencyDisplaySymbol)
      : '';
  } else {
    cryptoSymbol =
      currencyDisplayMode === CurrencyDisplayMode.ETH ? ETH_SYMBOL : '';
  }

  let amountStr =
    price > 1e6 && options.isMobile
      ? nFormatter(
          price,
          currencyDisplayMode,
          3,
          0,
          options.maximumFractionDigits
        )
      : currencyFormatter({
          currencyDisplayMode,
          maximumFractionDigits: options.maximumFractionDigits,
          minimumFractionDigits: options.minimumFractionDigits,
        }).format(price);

  if (
    currencyDisplayMode === CurrencyDisplayMode.ETH &&
    price > 0 &&
    amountStr === '0.'.padEnd(options.maximumFractionDigits + 2, '0')
  ) {
    amountStr = `≲${'0.'.padEnd(options.maximumFractionDigits + 1, '0')}1`;
  }

  return currencyDisplayMode === CurrencyDisplayMode.USD
    ? `${cryptoSymbol}${amountStr}`
    : `${amountStr} ETH`;
};

export const generateShortPriceString = (
  price: number | bigint,
  currencyDisplayMode: CurrencyDisplayMode
) =>
  generatePriceString(price, currencyDisplayMode, {
    maximumFractionDigits: 2,
  });

export const generateShortUsdPriceString = (price: number | bigint) =>
  generateShortPriceString(price, CurrencyDisplayMode.USD);

export const generateShortEthPriceString = (price: number | bigint) =>
  generateShortPriceString(price, CurrencyDisplayMode.ETH);

export default generatePriceString;
