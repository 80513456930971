import { Suspense } from 'react';

import { DefaultLoader } from './DefaultSuspense';

import * as styles from 'css/components/SiteLoadingSuspense.module.css';

export function SiteLoader() {
  return (
    <div className={styles.container}>
      <DefaultLoader className={styles.loader} />
    </div>
  );
}

export default function SiteLoadingSuspense({ children }) {
  return <Suspense fallback={<SiteLoader />}>{children}</Suspense>;
}
