import { ReactNode, useCallback, useState } from 'react';
import { styled } from '@mui/system';

import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import MPAnimations from '../css/MPAnimations';
import MPStandardDialog from '../dialog/MPStandardDialog';

interface MPExpandableContentProps {
  content: ReactNode;
  moreContent: ReactNode;
  className?: string;
  moreContentLabel?: string;
  moreContentLabelClassName?: string;
  title?: string;
  viewMoreFontClassName?: string;
}

const StyledMoreButton = styled('span')`
  cursor: pointer;
  margin-top: 10px;
  display: block;
`;

export default function MPExpandableContent({
  content,
  moreContent,
  className,
  title,
  moreContentLabel = 'View More',
  moreContentLabelClassName,
  viewMoreFontClassName = MPFonts.textSmallBold,
}: MPExpandableContentProps) {
  const [showMoreContent, setShowMoreContent] = useState(false);
  const show = useCallback(() => setShowMoreContent(true), []);
  const hide = useCallback(() => setShowMoreContent(false), []);
  const showOnEnter = useOnEnterKey(show);

  return (
    <div className={className}>
      {content}
      {!!moreContent && (
        <div className={moreContentLabelClassName}>
          <StyledMoreButton
            onClick={show}
            role="button"
            tabIndex={0}
            onKeyDown={showOnEnter}
            className={joinClasses(
              viewMoreFontClassName,
              MPAnimations.Color.DarkToLight
            )}
          >
            {moreContentLabel}
          </StyledMoreButton>
          <MPStandardDialog open={showMoreContent} onClose={hide} title={title}>
            {moreContent}
          </MPStandardDialog>
        </div>
      )}
    </div>
  );
}
