import { ReactNode } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/pages/product/ProductLabels.module.css';

interface ProductDefaultLabelProps {
  label: string;
  value: ReactNode;
  link?: string;
}

function ProductDefaultLabel({ label, value, link }: ProductDefaultLabelProps) {
  return (
    <div className={styles.productLabel}>
      <div
        className={joinClasses(
          styles.productLabelText,
          styles.secondary,
          MPFonts.textNormalMedium
        )}
      >
        {label}
      </div>
      <div className={joinClasses(styles.productLabelText, MPFonts.price)}>
        {link ? (
          <a
            className={joinClasses(
              styles.productLabelText,
              styles.link,
              MPFonts.price,
              'defaultLink'
            )}
            href={link}
          >
            {value}
          </a>
        ) : (
          <div
            className={joinClasses(
              styles.productLabelText,
              styles.link,
              MPFonts.price
            )}
          >
            {value}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductDefaultLabel;
