import { selector, useRecoilValue } from 'recoil';

import Session from 'Session';

const creatorDetails = selector({
  /* eslint-disable sort-keys-fix/sort-keys-fix */
  key: 'Session-CreatorDetails',
  get: ({ get }) => {
    const session = get(Session.recoilValue);
    return session.getAsyncCreatorDetails();
  },
});

export default function useCreatorDetails() {
  return useRecoilValue(creatorDetails);
}
