import { SvgIcon } from '@mui/material';

export default function EditIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.7756 5.38494C20.4911 4.96702 20.15 4.56803 19.7709 4.18814C19.4298 3.86521 18.9368 3.4853 18.5957 3.25728C18.027 2.85829 17.2497 2.93436 16.7758 3.40927L4.70096 15.491C4.66301 15.529 4.62519 15.586 4.60615 15.643L3.03292 20.1261C2.95715 20.3731 3.01401 20.639 3.18458 20.81C3.31734 20.943 3.46887 21 3.63956 21C3.71532 21 3.79122 20.9811 3.86699 20.962L8.32161 19.3853C8.37847 19.3663 8.43532 19.3283 8.47327 19.2903L20.5671 7.17056C21.0598 6.69579 21.1357 5.93586 20.7755 5.385L20.7756 5.38494ZM5.34549 16.0419C6.36904 16.7448 7.24108 17.6186 7.94241 18.6444L5.34549 19.5562C5.25068 19.3662 5.13697 19.1763 4.96641 19.0054C4.79584 18.8344 4.60621 18.7205 4.41676 18.6255L5.34549 16.0419Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
