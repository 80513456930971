import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const SoldArtworkIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0909 8.54541H3V21.6363H5.86194L10.9764 15.0854L16.0909 21.6363V8.54541ZM8.72387 12.2235C8.72387 13.3552 7.80979 14.2693 6.67808 14.2693C5.54636 14.2693 4.63228 13.3552 4.63228 12.2235C4.63228 11.0918 5.54636 10.1777 6.67808 10.1777C7.80979 10.1777 8.72387 11.0918 8.72387 12.2235Z"
      fill="currentColor"
    />
    <path
      d="M12.8181 2L15.2726 4.45455L12.8181 6.90909L10.3635 4.45455L12.8181 2Z"
      fill="currentColor"
    />
    <path
      d="M19.3637 10.1818L21 11.8181L19.3637 13.4545L17.7273 11.8181L19.3637 10.1818Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default SoldArtworkIcon;
