import { Route } from 'react-router-dom';

import Reload from 'components/Reload';

import AboutPage from './AboutPage';

const AboutRoute = (
  <Route path="about" element={<AboutPage />}>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default AboutRoute;
