import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

const CreateOptionsRoute = (
  <Route path="create-options" element={<SlashRedirect />}>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default CreateOptionsRoute;
