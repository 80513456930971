/**
 * @generated SignedSource<<ca06fa8032e05e47296c593314fbe154>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type IsFollowingEnum = "TRUE" | "FALSE" | "NOT_ALLOWED";
export type FollowersMutation$variables = {
  globalSlug: string;
  follow: boolean;
};
export type FollowersMutation$data = {
  readonly followV2: {
    readonly followers: {
      readonly id: string;
      readonly isFollowing: IsFollowingEnum;
      readonly following: number;
      readonly followers: number;
      readonly views: number;
    } | null;
    readonly followUser: {
      readonly id: string;
      readonly isFollowing: IsFollowingEnum;
    } | null;
  } | null;
};
export type FollowersMutation = {
  variables: FollowersMutation$variables;
  response: FollowersMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "follow"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "globalSlug"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFollowing",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "follow",
        "variableName": "follow"
      },
      {
        "kind": "Variable",
        "name": "globalSlug",
        "variableName": "globalSlug"
      }
    ],
    "concreteType": "FollowV2",
    "kind": "LinkedField",
    "name": "followV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FollowersV2Type",
        "kind": "LinkedField",
        "name": "followers",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "following",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "views",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FollowUserType",
        "kind": "LinkedField",
        "name": "followUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FollowersMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FollowersMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "eb813424bbe9cc70562776c828e32e94",
    "id": null,
    "metadata": {},
    "name": "FollowersMutation",
    "operationKind": "mutation",
    "text": "mutation FollowersMutation(\n  $globalSlug: String!\n  $follow: Boolean!\n) {\n  followV2(globalSlug: $globalSlug, follow: $follow) {\n    followers {\n      id\n      isFollowing\n      following\n      followers\n      views\n    }\n    followUser {\n      id\n      isFollowing\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "53ffd5d570a8c8d4e69a2d9c3fc9fb1f";

export default node;
