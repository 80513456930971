.container {
  --modularPage-gap: 32px;
  --modularPage-leftMargin: 32px;
  --modularPage-rightMargin: 32px;

  padding: 0 var(--modularPage-gap);

  @media (--mq-is-mobile) {
    --modularPage-gap: 24px;
    --modularPage-leftMargin: 0px;
    --modularPage-rightMargin: 0px;

    padding: 0;
  }
}

.header {
  padding: var(--modularPage-gap) var(--modularPage-gap) 0 var(--modularPage-gap);
}

.content {
  gap: var(--modularPage-gap);
  margin: 0 auto var(--modularPage-gap) auto;
  max-width: 1379px;

  @media screen and (min-width: 1920px) {
    max-width: 1699px;
  }
}

.content > section {
  margin: 0 auto;
  width: 100%;

  &:not(.large):not(.medium) {
    max-width: 670px;

    @media screen and (min-width: 1920px) {
      max-width: 832px;
    }
  }

  &.medium {
    max-width: 906px;

    @media screen and (min-width: 1920px) {
      max-width: 1120px;
    }
  }
}

.artworksCarouselContent {
  @media (--mq-is-not-mobile) {
    left: 8px;
  }
}
