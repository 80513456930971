import { ComponentProps } from 'react';
import { SvgIcon } from '@mui/material';

export default function WalletConnectIcon(
  props: ComponentProps<typeof SvgIcon>
) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#icon-wallet-connect-clip-path)">
        <path
          d="M12.0168 24.5024C18.6363 24.5024 24.0024 19.1363 24.0024 12.5168C24.0024 5.89738 18.6363 0.53125 12.0168 0.53125C5.39738 0.53125 0.03125 5.89738 0.03125 12.5168C0.03125 19.1363 5.39738 24.5024 12.0168 24.5024Z"
          fill="#3396FF"
          stroke="#66B1FF"
          strokeWidth="0.0288346"
          strokeMiterlimit="22.9256"
        />
        <path
          d="M7.35598 9.44982C9.92226 6.94121 14.0937 6.94121 16.6696 9.44982L16.9771 9.74777C17.1021 9.87272 17.1021 10.0746 16.9771 10.1995L15.9199 11.228C15.8526 11.2952 15.7468 11.2952 15.6892 11.228L15.2663 10.8147C13.4689 9.06536 10.5662 9.06536 8.76887 10.8147L8.31713 11.2568C8.24985 11.3241 8.14412 11.3241 8.08645 11.2568L7.02919 10.2284C6.90424 10.1034 6.90424 9.90156 7.02919 9.77661L7.36559 9.44982H7.35598ZM18.8514 11.5836L19.7933 12.5063C19.9183 12.6312 19.9183 12.8331 19.7933 12.958L15.545 17.1006C15.4201 17.2255 15.2086 17.2255 15.0837 17.1006L12.0656 14.1595C12.0368 14.1306 11.9791 14.1306 11.9503 14.1595L8.93227 17.1006C8.80732 17.2255 8.59587 17.2255 8.47092 17.1006L4.22262 12.958C4.09767 12.8331 4.09767 12.6312 4.22262 12.5063L5.16455 11.5836C5.2895 11.4586 5.50095 11.4586 5.6259 11.5836L8.64392 14.5247C8.67276 14.5535 8.73043 14.5535 8.75926 14.5247L11.7773 11.5836C11.9022 11.4586 12.1137 11.4586 12.2386 11.5836L15.2567 14.5247C15.2855 14.5535 15.3432 14.5535 15.372 14.5247L18.39 11.5836C18.515 11.4586 18.7264 11.4586 18.8514 11.5836Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="icon-wallet-connect-clip-path">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
