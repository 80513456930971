import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const PauseIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <rect
      x="3.5459"
      y="22.3945"
      width="23.6727"
      height="5.07273"
      transform="rotate(-90 3.5459 22.3945)"
      fill="currentColor"
    />
    <rect
      x="15.3822"
      y="22.3945"
      width="23.6727"
      height="5.07273"
      transform="rotate(-90 15.3822 22.3945)"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default PauseIcon;
