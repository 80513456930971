import { MouseEvent as ReactMouseEvent, useCallback, useState } from 'react';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';
import { Hash } from 'viem';

import { MPColorClass, MPDivider, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { MpErrors } from 'types/__generated__/graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import ErrorDisplay from 'components/Error';
import ConnectorSelector from 'components/wallet/ConnectorSelector';
import { METAMASK_LINK } from 'constants/ExternalUrls';
import ROUTES from 'constants/Routes';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import { areSameAddress } from 'utils/areSameAddress';
import isWalletError from 'utils/errors/wallet';
import getAddressDisplay from 'utils/getAddressDisplay';

import * as styles from 'css/components/wallet/NewWalletConnectDialog.module.css';

export default function SelectWalletConnectDialog({
  open,
  onClose,
  address,
  onSelected = noop,
  onError = noop,
}) {
  const [_error, _setError] = useState(undefined);
  const setError = useCallback(
    (e) => {
      _setError(e);
      onError(e);
    },
    [_setError, onError]
  );

  const cachedAccount = useCachedAccount();

  const error = isWalletError.RequestPermissionAlreadyPending(_error)
    ? new Error('Please check your wallet provider for pending actions')
    : _error?.name === MpErrors.WalletAssociatedWithAnotherAccount
    ? new Error(
        `It looks like you've already registered this wallet with a different account. You can login into that account with this wallet directly. If you'd rather use this account, please dissociate this wallet from the account settings page of that account.`
      )
    : _error?.name === MpErrors.WalletAlreadyConnected
    ? undefined
    : _error;

  return (
    <StackStateDialog title="Select Wallet" open={open} onClose={onClose}>
      <>
        <div>
          Please select the wallet with the address {address}.
          {cachedAccount.address
            ? ` You may need to disconnect your currently connected account first.`
            : ''}
        </div>
        <ErrorDisplay error={error} />
        <ConnectorSelector
          canDisconnect
          title=""
          onError={setError}
          onConnection={(addresses) => {
            if (addresses.find((add) => areSameAddress(add, address))) {
              onSelected();
              onClose();
            } else {
              setError(
                new Error(
                  `Connected Account does not match ${getAddressDisplay(
                    address
                  )}`
                )
              );
            }
          }}
        />
        <div className={styles.walletLinkSection}>
          <a
            className={joinClasses('underline', 'invisibleAnchor')}
            href={METAMASK_LINK}
            target="_blank"
            rel="noreferrer"
          >
            Don&#39;t have a wallet?
          </a>
        </div>
        <MPDivider />
        <div
          className={joinClasses(
            MPColorClass.SolidNeutralGray3,
            MPFonts.paragraphSmall,
            styles.termsAndConditions
          )}
        >
          By connecting a wallet you agree to the{' '}
          <Link
            to={ROUTES.TERMS()}
            target="_blank"
            reloadDocument
            className={joinClasses('underline', 'invisibleAnchor')}
          >
            Terms and Conditions
          </Link>{' '}
          and{' '}
          <Link
            to={ROUTES.PRIVACY()}
            target="_blank"
            reloadDocument
            className={joinClasses('underline', 'invisibleAnchor')}
          >
            Privacy Policy
          </Link>{' '}
          and agree to receive periodic updates.
        </div>
      </>
    </StackStateDialog>
  );
}

export function useSelectWalletConnectDialog(
  address: Hash,
  onSelected = noop,
  setError = noop
): [(e?: ReactMouseEvent<HTMLElement, MouseEvent>) => void, JSX.Element] {
  const [isDialogOpen, openDialog, closeDialog] = useSimpleDialogController({
    preventDefault: true,
  });

  return [
    openDialog,
    isDialogOpen ? (
      <SelectWalletConnectDialog
        address={address}
        onError={setError}
        open={isDialogOpen}
        onClose={closeDialog}
        onSelected={onSelected}
      />
    ) : null,
  ];
}
