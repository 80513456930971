import { usePreloadedQuery } from 'react-relay';

import ExploreHeroEditorialQueryType, {
  ExploreHeroEditorialQuery,
} from 'graphql/__generated__/ExploreHeroEditorialQuery.graphql';

import HeroEditorialCard from 'components/cards/HeroEditorialCard';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

interface HeroProps {
  heroEditorialQuery: WithLoadQueryProps<ExploreHeroEditorialQuery>;
}

function Hero({ heroEditorialQuery }: HeroProps) {
  const result = usePreloadedQuery<ExploreHeroEditorialQuery>(
    ExploreHeroEditorialQueryType,
    heroEditorialQuery.queryRef
  );

  const editorial = result.editorial.heroEditorial;

  return editorial && <HeroEditorialCard editorial={editorial} />;
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    Hero,
    {
      heroEditorialQuery: { concreteRequest: ExploreHeroEditorialQueryType },
    },
    { grouppedLoadingKey: 'explore:hero-editorial' }
  ),
  { errorFallback: null, hideState: true }
);
