/* eslint-disable import/prefer-default-export */

/**
 * This function attempts to get the number of display characters. Across
 * unicode code points, joiners, graphemes, variation selectors, trying to figure this out is actually
 * pretty insane. went with the solution in the comment @ https://dev.to/coolgoose/quick-and-easy-way-of-counting-utf-8-characters-in-javascript-23ce
 * over https://blog.jonnew.com/posts/poo-dot-length-equals-two
 * and does not represent an exhaustive attempt to cover all cases.
 * @param str String to check
 * @returns number of characters displayed.
 */
export function charCount(str: string) {
  return str.split(/(\P{Mark}\p{Mark}*)/u).filter(Boolean).length;
}
