import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const CheckCircleIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M12 4C7.58146 4 4 7.5819 4 12C4 16.4181 7.58191 20 12 20C16.4181 20 20 16.4181 20 12C20 7.5819 16.4181 4 12 4ZM10.7022 15.5479L8.06439 12.9096L9.02619 11.9479L10.7022 13.6239L15.6304 8.69561L16.5922 9.65741L10.7022 15.5479Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default CheckCircleIcon;
