import { Connector } from 'wagmi';

import { WalletId } from 'utils/jwt/walletUtils';

/**
 * Each wallet provider gets boiled down to a single ref irrespective of
 * connector type.
 *
 */
function getCompFunction(connectorId: WalletId) {
  return connectorId === WalletId.Metamask
    ? (connector) =>
        connector.id === WalletId.Metamask ||
        connector.id === WalletId.MetamaskMobile ||
        connector.id === WalletId.MetamaskSDK
    : connectorId === WalletId.Coinbase
    ? (connector) =>
        connector.id === WalletId.Coinbase ||
        connector.id === WalletId.CoinbaseSDK
    : (connector) => connector.id === connectorId;
}

/**
 * The SDK versions will coexist alongside the other, prefer
 * the injected versions over the SDKs.
 * @returns
 */
function getSortValue(connector: Connector) {
  if (
    connector.id === WalletId.Metamask ||
    connector.id === WalletId.MetamaskMobile
  )
    return -2;
  if (connector.id === WalletId.Coinbase) return -1;
  if (connector.id === WalletId.CoinbaseSDK) return 1;
  if (connector.id === WalletId.MetamaskSDK) return 2;
  return 0;
}

export default function findConnector(
  connectors: readonly Connector[],
  connectorId: WalletId
) {
  return connectors
    .slice()
    .sort((a, b) => getSortValue(a) - getSortValue(b))
    .find(getCompFunction(connectorId));
}
