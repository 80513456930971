import { useCallback, useMemo } from 'react';

import {
  MPActionButton,
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { HomepageSectionsQuery$data } from 'graphql/__generated__/HomepageSectionsQuery.graphql';
import { SortOrderEnum, SortTypeEnum } from 'types/__generated__/graphql';

import Marquee from 'components/Marquee';
import ROUTES from 'constants/Routes';
import { UNBREAKABLE_SPACE_UNICODE } from 'constants/Symbols';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';
import toNumericShorthand from 'utils/numericShorthand';

import ArtworkCard, { ArtworkCardProps } from './ArtworkCard';
import HeroCard from './HeroCard';

import * as styles from 'css/components/cards/HeroSeriesCard.module.css';

type CollectionType =
  HomepageSectionsQuery$data['homepageSections']['edges'][number]['node']['collection'];

type StatType = {
  label: string;
  value: number | string;
};

interface HeroSeriesCardProps {
  collection: Pick<
    CollectionType,
    | 'author'
    | 'contract'
    | 'collectionName'
    | 'floorPrice'
    | 'numOfListedNfts'
    | 'numOfOwners'
    | 'pk'
    | 'slug'
    | 'totalItems'
    | 'totalSupply'
  > & {
    nfts: { edges: ReadonlyArray<{ node: ArtworkCardProps['nft'] }> };
    totalVolume: Pick<CollectionType['totalVolume'], 'totalVolumeInUsd'>;
  };
}

export default function HeroSeriesCard({ collection }: HeroSeriesCardProps) {
  const track = useHomepageGTM();

  const handleClick = useCallback(
    () =>
      track.clickCard(
        CardType.HeroSeriesCard,
        collection.pk,
        collection.collectionName
      ),
    [track, collection.pk, collection.collectionName]
  );

  const stats = useMemo(
    () =>
      (
        [
          {
            label: 'Artworks',
            value: toNumericShorthand(collection.totalItems),
          },
          {
            label: 'Floor',
            value:
              collection.floorPrice?.floorPriceInUsd &&
              `$${toNumericShorthand(collection.floorPrice.floorPriceInUsd)}`,
          },
          {
            label: 'Total Sales',
            value:
              collection.totalVolume?.totalVolumeInUsd &&
              `$${toNumericShorthand(collection.totalVolume.totalVolumeInUsd)}`,
          },
          {
            label: 'Collectors',
            value: toNumericShorthand(collection.numOfOwners),
          },
          {
            label: 'Listed',
            value: collection.numOfListedNfts
              ? `${Math.trunc(
                  (collection.numOfListedNfts * 100) / collection.totalSupply
                )}%`
              : null,
          },
        ] as StatType[]
      ).filter(({ value }) => !!value),
    [collection]
  );

  return (
    <HeroCard
      to={ROUTES.PROFILE.SHOP_WITH_FILTERS(collection.slug, {
        sortOrder: SortOrderEnum.Asc,
        sortType: SortTypeEnum.Price,
      })}
      className={joinClasses(
        CSSGlobal.Cursor.Pointer,
        CSSGap[32],
        MPColorClass.CommonBlack,
        MPBackgroundColorClass.CommonWhite,
        'anchor',
        styles.container
      )}
      onClick={handleClick}
    >
      <div
        className={joinClasses(
          CSSGlobal.Flex.CenteredCol,
          styles.artworksContainer
        )}
      >
        <Marquee
          className={styles.artworks}
          disablePauseOnHover
          speedMultiplier={collection.nfts.edges.length * 3}
        >
          {collection.nfts.edges.map(({ node: nft }) => (
            <ArtworkCard
              key={nft.pk}
              className={joinClasses(
                MPBackgroundColorClass.BackgroundDefault,
                styles.artwork
              )}
              nft={nft}
              disableBrowserNavigate
              hideDetails
            />
          ))}
        </Marquee>
      </div>

      <div
        className={joinClasses(
          CSSGlobal.Flex.ColCenterAlign,
          CSSGap[24],
          styles.rail
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[2])}>
          <span className={MPFonts.headline4}>{collection.collectionName}</span>

          <span
            className={joinClasses(
              MPFonts.textNormalMedium,
              MPColorClass.SolidNeutralGray5
            )}
          >
            a{' '}
            {collection.contract.isGenerative
              ? `generative${UNBREAKABLE_SPACE_UNICODE}`
              : ''}
            series by{' '}
            <span className={MPColorClass.CommonBlack}>
              {generateFormattedUserFullName(collection.author.fullName)}
            </span>
          </span>
        </div>

        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[12])}>
          {stats.map(({ label, value }) => (
            <div
              key={label}
              className={joinClasses(
                CSSGlobal.Flex.RowSpaceBetween,
                CSSGlobal.Flex.RowCenterAlign
              )}
            >
              <span
                className={joinClasses(
                  MPFonts.textSmallMedium,
                  MPColorClass.SolidNeutralGray5
                )}
              >
                {label}
              </span>
              <span
                className={joinClasses(
                  MPFonts.textNormalMedium,
                  MPColorClass.CommonBlack
                )}
              >
                {value}
              </span>
            </div>
          ))}
        </div>

        <MPActionButton fullWidth size="large">
          Explore the Series
        </MPActionButton>
      </div>
    </HeroCard>
  );
}
