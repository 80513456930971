import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { MPColorValue } from '@mp-frontend/core-components';

import STRIPE_KEY from 'constants/Stripe';
import useSession from 'hooks/useSession';

const options: StripeElementsOptions = {
  appearance: {
    rules: {
      '.Input': {
        border: `1px solid ${MPColorValue.SolidNeutralGray3}`,
        boxShadow: 'none',
        padding: '11px 16px',
      },
      '.Input.Input--empty:hover': {
        borderColor: MPColorValue.SolidNeutralGray3 as string,
      },
      '.Input::placeholder': {
        color: MPColorValue.SolidNeutralGray3 as string,
      },
      '.Input:focus': {
        backgroundColor: MPColorValue.CommonWhite as string,
        borderColor: MPColorValue.CommonBlack as string,
        borderWidth: '2px',
        boxShadow: 'none',
        outline: 'none',
      },
      '.Input:hover': {
        backgroundColor: MPColorValue.BackgroundDefault as string,
        borderColor: MPColorValue.CommonBlack as string,
        borderWidth: '2px',
        padding: '10px 15px',
      },
      '.Label': {
        fontSize: '14px',
        fontWeight: '600',
        letterSpacing: '-0.112px',
        lineHeight: '1.4',
        marginBottom: '0',
        paddingBottom: '4px',
      },
    },
    variables: {
      borderRadius: '0',
      colorBackground: MPColorValue.CommonWhite as string,
      colorDanger: MPColorValue.ErrorMain as string,
      colorPrimary: MPColorValue.PrimaryMain as string,
      colorText: MPColorValue.PrimaryMain as string,
      fontSizeBase: '14px',
      spacingGridRow: '14px',
    },
  },
};

const Stripe = {
  Dev: loadStripe(STRIPE_KEY.DEV),
  Production: loadStripe(STRIPE_KEY.PRODUCTION),
};

export default function useStripePromise() {
  const session = useSession();
  const tier = session.environment as 'production' | 'staging' | 'development';
  return [
    tier === 'production' ? Stripe.Production : Stripe.Dev,
    options,
  ] as const;
}
