import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { APP_NAME } from 'constants/Utils';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import setDocTitle from 'utils/setDocTitle';

import { ACTIONS } from './filters/ActionsFilter';
import ActivityPageFilters, {
  FiltersState,
  FiltersStateChangeEvent,
} from './ActivityPageFilters';
import ActivityPageTable from './ActivityPageTable';

import * as styles from 'css/pages/activity/ActivityPage.module.css';

export const ACTIVITY_PER_PAGE = 10;

const QUERY_PARAM_DELIMITER = '~';

const DEFAULT_QUERY_FILTERS = {
  actions: ACTIONS,
  activityDate: {},
  isShowFollowing: false,
  price: {},
  userNames: [],
} as const;

const DEFAULT_FILTERS: FiltersState = {
  actions: [],
  activityDate: {},
  isShowFollowing: false,
  price: {},
  userNames: [],
};

const QUERY_TO_FILTERS_TRANFORM = {
  activityDate: (values: string[]): FiltersState['activityDate'] => ({
    max: values[1] || undefined,
    min: values[0] || undefined,
  }),
  isShowFollowing: (values: string[]): FiltersState['isShowFollowing'] =>
    values.length === 0 ? false : values[0].toLowerCase() === 'true',
  price: (values: string[]): FiltersState['price'] => ({
    max: values[1] ? parseFloat(values[1]) : undefined,
    min: values[0] ? parseFloat(values[0]) : undefined,
  }),
} as const;

const FILTERS_TO_QUERY_TRANSFORM = {
  activityDate: (value: FiltersState['activityDate']): string[] | null =>
    !value.min && !value.max ? null : [value.min || '', value.max || ''],
  isShowFollowing: (value: FiltersState['isShowFollowing']): boolean[] =>
    !value ? [false] : [true],
  price: (value: FiltersState['price']): string[] | null =>
    !value.min && !value.max
      ? null
      : [
          value.min ? value.min.toString() : '',
          value.max ? value.max.toString() : '',
        ],
} as const;

function ActivityPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<FiltersState>(DEFAULT_FILTERS);

  useEffect(() => setDocTitle(['Activity', APP_NAME].join(' | ')), []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newFilters: FiltersState = { ...DEFAULT_FILTERS };

    Object.keys(filters).forEach((key) => {
      const values = queryParams.has(key)
        ? queryParams.get(key).split(QUERY_PARAM_DELIMITER)
        : [];
      const transformValue = QUERY_TO_FILTERS_TRANFORM[key]?.(values);
      newFilters[key] =
        typeof transformValue === 'boolean'
          ? transformValue
          : transformValue || values;
    });

    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleChange = useCallback(
    (changedFilters: FiltersStateChangeEvent) => {
      const newFilters = {
        ...filters,
        ...changedFilters,
      };

      const queryParams = new URLSearchParams();
      Object.entries(newFilters).forEach(([key, value]) => {
        if (
          value &&
          (Object.keys(value).length || typeof value === 'boolean')
        ) {
          const values = FILTERS_TO_QUERY_TRANSFORM[key]
            ? FILTERS_TO_QUERY_TRANSFORM[key](value)
            : value;

          if (!values) return;

          queryParams.set(key, values.join(QUERY_PARAM_DELIMITER));
        } else {
          queryParams.delete(key);
        }
      });

      navigate({ search: queryParams.toString() });
    },
    [filters, navigate]
  );

  return (
    <div>
      <div
        className={joinClasses(
          MPFonts.headline2,
          CSSGlobal.Flex.CenteredRow,
          CSSGlobal.Cursor.Default,
          styles.title
        )}
      >
        Activity
      </div>

      <ActivityPageFilters filters={filters} onChange={handleChange} />

      <ActivityPageTable
        activityQuery={{
          variables: {
            ...Object.entries(filters).reduce(
              (memo, [key, value]: [string, string[]]) => ({
                ...memo,
                [key]:
                  Object.keys(value).length || typeof value === 'boolean'
                    ? value
                    : DEFAULT_QUERY_FILTERS[key],
              }),
              {} as FiltersState
            ),
            currencyMode: CurrencyDisplayMode.USD,
            first: ACTIVITY_PER_PAGE,
          },
        }}
      />
    </div>
  );
}

export default ActivityPage;
