import { Dayjs, OpUnitType } from 'dayjs';
import pluralize from 'pluralize';

import dayjs from 'utils/datetime/dayjsex';

/** Function takes a timestamp and will return either a map of time differences between now and the
    arg (should be from the past) or if the singular flag is passed, the first positive diff in sequence.

    For example, if singular = True and we pass a date which has elapsed 0 years 6 months, and 13 days,
    this function will return 6m as the first unit type in sequence with a positive diff.
*/
export default function getTimeSinceX(
  date: Dayjs,
  singular = false
): Record<any, string> | string {
  const timeSinceMap = {};
  const unitSet: OpUnitType[] = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
  ]; // must be defined in strict sequence of operation
  for (let i = 0; i < unitSet.length; i++) {
    const diff = dayjs().diff(date, unitSet[i]);
    const diffStr = `${diff} ${pluralize(unitSet[i], diff)} ago`;
    if (singular && diff > 0) {
      return diffStr;
    }
    timeSinceMap[unitSet[i]] = diffStr;
  }
  if (singular) return ''; // if our loop didn't exit, exit here for singular returns
  return timeSinceMap;
}
