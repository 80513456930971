import { SvgIcon } from '@mui/material';

export default function ActivityIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96653 10.0748H12.7931C13.1994 11.3188 14.3692 12.2176 15.7489 12.2176C17.1286 12.2176 18.2984 11.3188 18.7047 10.0748H20.0335C20.5673 10.0748 21 9.64205 21 9.10825C21 8.57446 20.5673 8.14173 20.0335 8.14173H18.7043C18.2977 6.89827 17.1282 6 15.7489 6C14.3696 6 13.2001 6.89827 12.7935 8.14173H3.96653C3.43273 8.14173 3 8.57446 3 9.10825C3 9.64205 3.43273 10.0748 3.96653 10.0748ZM15.7489 7.93305C15.0996 7.93305 14.5732 8.45945 14.5732 9.10879C14.5732 9.75813 15.0996 10.2845 15.7489 10.2845C16.3982 10.2845 16.9246 9.75813 16.9246 9.10879C16.9246 8.45945 16.3982 7.93305 15.7489 7.93305Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96653 16.5023H5.2955C5.70248 17.7451 6.87167 18.6428 8.25053 18.6428C9.62939 18.6428 10.7986 17.7451 11.2056 16.5023H20.0335C20.5673 16.5023 21 16.0695 21 15.5357C21 15.0019 20.5673 14.5692 20.0335 14.5692H11.2067C10.8008 13.3246 9.63068 12.4252 8.25053 12.4252C6.87038 12.4252 5.70029 13.3246 5.29435 14.5692H3.96653C3.43273 14.5692 3 15.0019 3 15.5357C3 16.0695 3.43273 16.5023 3.96653 16.5023ZM8.25053 14.3583C7.60119 14.3583 7.0748 14.8847 7.0748 15.534C7.0748 16.1833 7.60119 16.7097 8.25053 16.7097C8.89987 16.7097 9.42626 16.1833 9.42626 15.534C9.42626 14.8847 8.89987 14.3583 8.25053 14.3583Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
