import { useMemo } from 'react';

import { tagPush } from './base';

export default function useActivityGTM() {
  return useMemo(
    () => ({
      clickTile(
        artwork: string,
        artist: string,
        type: string,
        amount: string,
        productSlug: string,
        editionInfo: string
      ) {
        return tagPush('Activity - Tile Click', {
          amount,
          artist,
          artwork,
          editionInfo,
          productSlug,
          type,
        });
      },

      toggleFilter(filter: string, open: boolean) {
        return tagPush('Activity - Filter Toggle', {
          filter,
          toggle: open ? 'on' : 'off',
        });
      },
    }),
    []
  );
}
