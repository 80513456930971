import { SvgIcon } from '@mui/material';

export default function AddImageIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.2501 5.24977H18.7502V3.74993C18.7502 3.33559 18.4144 3 18.0003 3C17.586 3 17.2501 3.3356 17.2501 3.74993V5.24977H15.7503C15.3359 5.24977 15.0003 5.58564 15.0003 5.9997C15.0003 6.41404 15.3359 6.74989 15.7503 6.74989H17.2501V8.24974C17.2501 8.66408 17.586 8.99967 18.0003 8.99967C18.4144 8.99967 18.7502 8.66407 18.7502 8.24974V6.74989H20.2501C20.6644 6.74989 21 6.41403 21 5.9997C21 5.58562 20.6644 5.24977 20.2501 5.24977Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7497 5.24967H5.99977C5.20431 5.24967 4.44132 5.56592 3.87863 6.1283C3.31598 6.69095 3 7.45398 3 8.24971V17.9996C3 18.7951 3.31599 19.558 3.87863 20.1207C4.44127 20.6834 5.20431 20.9994 5.99977 20.9994H15.7497C16.5454 20.9994 17.3084 20.6834 17.8711 20.1207C18.4335 19.5581 18.7497 18.7951 18.7497 17.9996V11.2497C18.7497 10.8353 18.4138 10.4998 17.9998 10.4998C17.5854 10.4998 17.2496 10.8354 17.2496 11.2497V14.6884L14.7785 12.2209C14.486 11.93 14.0136 11.93 13.7209 12.2209L11.9998 13.9384L9.52868 11.4709C9.23598 11.18 8.76356 11.18 8.47113 11.4709L4.49985 15.4382V8.24971C4.49985 7.85185 4.65784 7.47045 4.9393 7.18901C5.22049 6.90781 5.60213 6.74982 5.99973 6.74982H12.7497C13.164 6.74982 13.4996 6.41396 13.4996 5.99963C13.4996 5.58555 13.164 5.24967 12.7497 5.24967ZM8.99974 13.061L11.4711 15.5323C11.6116 15.6731 11.8025 15.7521 12.0016 15.7521C12.2006 15.7521 12.3916 15.6731 12.5323 15.5323L14.2498 13.8109L17.2498 16.8109L17.2496 17.9998C17.2496 18.3974 17.0916 18.7791 16.8104 19.0602C16.5289 19.3417 16.1475 19.4997 15.7497 19.4997H5.99978C5.60219 19.4997 5.22053 19.3417 4.93935 19.0602C4.65789 18.779 4.4999 18.3974 4.4999 17.9998V17.5609L8.99974 13.061Z"
        fill="currentColor"
      />
      <path
        d="M12.3752 10.4997C12.9964 10.4997 13.4999 9.99593 13.4999 9.37468C13.4999 8.75342 12.9964 8.24963 12.3752 8.24963C11.7536 8.24963 11.2501 8.75344 11.2501 9.37468C11.2501 9.99595 11.7537 10.4997 12.3752 10.4997Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
