import { SvgIcon } from '@mui/material';

export default function ArrowDropUpIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect fill="white" />
      <g clipPath="url(#clip0_961_70093)">
        <path d="M19 17L12 10L5 17H19Z" fill="currentColor" />
      </g>
      <rect
        x="19"
        y="9"
        width="14"
        height="3"
        transform="rotate(-180 19 9)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
