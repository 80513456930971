import {
  MPColorClass,
  MPExpandableText,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import THRESHOLDS from 'constants/Thresholds';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { AccountArtistFragment } from 'types/graphql/Account';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import toNumericShorthand from 'utils/numericShorthand';

import User, { UserProps } from './User';

export interface FullArtistProps {
  artist: Omit<AccountArtistFragment, 'totalVolume'> & {
    totalVolume?: AccountArtistFragment['totalVolume'];
  };
  bottomSection?: UserProps['bottomSection'];
  className?: string;
  hideSales?: boolean;
  topSection?: UserProps['topSection'];
}

export default function FullArtist({
  artist,
  className,
  hideSales = false,
  bottomSection = 'follow',
  topSection,
}: FullArtistProps) {
  const metSalesThreshold =
    !hideSales && artist.totalVolume?.totalVolumeInUsd > THRESHOLDS.TOTAL_SALES;
  const metAudienceThreshold = artist.followerCount > THRESHOLDS.AUDIENCE;

  return (
    <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[24], className)}>
      <User
        bottomSection={bottomSection}
        topSection={topSection}
        size="large"
        user={artist}
      />

      {!!artist.biography && (
        <MPExpandableText
          content={artist.biography}
          lineClamp={5}
          title={generateFormattedUserFullName(artist.fullName)}
        />
      )}

      {!!(metSalesThreshold || metAudienceThreshold) && (
        <div
          className={joinClasses(
            CSSGlobal.Cursor.Default,
            CSSGlobal.Flex.Col,
            CSSGap[16]
          )}
        >
          {!!metSalesThreshold && (
            <div
              className={joinClasses(
                CSSGlobal.Flex.RowSpaceBetween,
                CSSGlobal.Flex.NoWrap,
                CSSGap[8]
              )}
            >
              <span
                className={joinClasses(
                  MPFonts.textNormalMedium,
                  MPColorClass.SolidNeutralGray5
                )}
              >
                Sales
              </span>
              <span className={MPFonts.subPrice}>
                ${toNumericShorthand(artist.totalVolume.totalVolumeInUsd)}
              </span>
            </div>
          )}

          {!!metAudienceThreshold && (
            <div
              className={joinClasses(
                CSSGlobal.Flex.RowSpaceBetween,
                CSSGlobal.Flex.NoWrap,
                CSSGap[8]
              )}
            >
              <span
                className={joinClasses(
                  MPFonts.textNormalMedium,
                  MPColorClass.SolidNeutralGray5
                )}
              >
                Followers
              </span>
              <span className={MPFonts.subPrice}>
                {toNumericShorthand(artist.followerCount)}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
