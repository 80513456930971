import { Dispatch, SetStateAction, startTransition, useState } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { ProductOwnerStates } from './ProductOwnerUpdateListing';

import * as styles from '../../../css/pages/product/ProductOwnerUpdateListing.module.css';
import * as modalStyles from '../../../css/pages/product/ProductSetBuyNow.module.css';

interface ProductOwnerListingSelectionProps {
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

function ProductOwnerListingSelection({
  setOwnershipState,
}: ProductOwnerListingSelectionProps) {
  const [selectedState, setSelectedState] = useState<ProductOwnerStates>(null);

  return (
    <div>
      <div className={modalStyles.productSellContainer}>
        <div className={modalStyles.productSellMessageContainer}>
          <div className={MPFonts.textNormalRegular}>
            Choose how you would like to list your artwork
          </div>
          <div className={styles.productListingSelectionContainer}>
            <div
              className={joinClasses(
                styles.productListingSelection,
                selectedState === ProductOwnerStates.FIXED_SALE
                  ? styles.productListingSelectionSelected
                  : ''
              )}
              onClick={() => {
                startTransition(() =>
                  setSelectedState(ProductOwnerStates.FIXED_SALE)
                );
              }}
              onKeyPress={() => {
                startTransition(() =>
                  setSelectedState(ProductOwnerStates.FIXED_SALE)
                );
              }}
              role="button"
              tabIndex={0}
            >
              <div className={MPFonts.textNormalMedium}>Buy Now</div>
              <div
                className={joinClasses(
                  MPFonts.textSmallRegular,
                  styles.productListingSelectionDescription
                )}
              >
                Set a fixed price
              </div>
            </div>
            <div
              className={joinClasses(
                styles.productListingSelection,
                selectedState === ProductOwnerStates.RESERVE
                  ? styles.productListingSelectionSelected
                  : ''
              )}
              onClick={() => setSelectedState(ProductOwnerStates.RESERVE)}
              onKeyPress={() => setSelectedState(ProductOwnerStates.RESERVE)}
              role="button"
              tabIndex={0}
            >
              <div className={MPFonts.textNormalMedium}>Reserve</div>
              <div
                className={joinClasses(
                  MPFonts.textSmallRegular,
                  styles.productListingSelectionDescription
                )}
              >
                Set a price with a timer
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={modalStyles.productSellFooter}>
        <div className={modalStyles.productSellListingBtnContainer}>
          <MPActionButton
            fullWidth
            onClick={() => {
              startTransition(() => setOwnershipState(selectedState));
            }}
            disabled={!selectedState}
            size="large"
          >
            Continue
          </MPActionButton>
        </div>
      </div>
    </div>
  );
}

export default ProductOwnerListingSelection;
