.header {
  max-width: 857px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;


  @media (--mq-is-mobile) {
    text-align: left;
    justify-content: unset;
  }
}

.description {
  white-space: pre-wrap;
}
