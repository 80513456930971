/**
 * @generated SignedSource<<bc6e1b39b27c32d4b8c486e1ddf4ce4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateProductBidInETHArguments = {
  bidAmountInWei: any;
  bidderAddress: `0x${string}`;
  email: string;
  nonce: number;
  transactionId: `0x${string}`;
  productId: string;
};
export type ListingCreateProductBidInETHMutation$variables = {
  requestData: CreateProductBidInETHArguments;
};
export type ListingCreateProductBidInETHMutation$data = {
  readonly createProductBidInEth: {
    readonly success: boolean | null;
    readonly bidId: number | null;
  } | null;
};
export type ListingCreateProductBidInETHMutation = {
  variables: ListingCreateProductBidInETHMutation$variables;
  response: ListingCreateProductBidInETHMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "CreateProductBidInETHMutation",
    "kind": "LinkedField",
    "name": "createProductBidInEth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bidId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingCreateProductBidInETHMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingCreateProductBidInETHMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fe4b274f3e2929dafd079c93ceaa6d5d",
    "id": null,
    "metadata": {},
    "name": "ListingCreateProductBidInETHMutation",
    "operationKind": "mutation",
    "text": "mutation ListingCreateProductBidInETHMutation(\n  $requestData: CreateProductBidInETHArguments!\n) {\n  createProductBidInEth(requestData: $requestData) {\n    success\n    bidId\n  }\n}\n"
  }
};
})();

(node as any).hash = "d77b197455839624dd387cca812e0727";

export default node;
