.notificationBody {
  display: flex;
  flex-direction: column;
  padding: 24px var(--horz-margin);
}

.notificationInnerBody {
  display: flex;
}

.notificationIcon {
  margin-top: 4px;
  margin-right: 12px;
  width: 30px;
  flex-shrink: 0;
}

.notificationDivider {
  border-bottom: 1px solid var(--color-divider);
  position: relative;
  top: 24px;
}

.unreadBackground {
  background: var(--color-commonWhite);
}

.readBackground {
  background: var(--color-grey100);
}
