import {
  BaseSyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  WheelEvent,
} from 'react';
import { debounce } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { usePaginationFragment, usePreloadedQuery } from 'react-relay';

import { MPFonts, useIsMobile } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsRelatedSlideShowData, {
  NFTsRelatedSlideShow$key,
} from 'graphql/__generated__/NFTsRelatedSlideShow.graphql';
import NFTsRelatedSlideShowType, {
  NFTsRelatedSlideShowQuery,
} from 'graphql/__generated__/NFTsRelatedSlideShowQuery.graphql';

import useInfiniteQueryScroll from 'hooks/useInfiniteQueryScroll';
import CSSGlobal from 'types/enums/css/Global';
import { NFTType } from 'types/graphql/NFT';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import {
  WithLoadQueryPropsV1,
  withLoadQueryV1,
} from 'utils/hocs/withLoadQuery';

import { RELATED_IMAGE_PAGE_SIZE_LIMIT } from '../ProductContainer';
import ProductRelatedSlide from './ProductRelatedSlide';

import * as pageStyles from 'css/pages/product/ProductPage.module.css';
import * as styles from 'css/pages/product/ProductRelatedSlideShow.module.css';

const WHEEL_BLOCKED_PERIOD = 1000;
const WHEEL_DEBOUNCE_PERIOD = 100;
const SCROLL_DEBOUNCE_PERIOD = 100;
const WHEEL_MINIMUM_THRESHOLD = 5;
const MINIMUM_SLIDE_FETCH_MORE_COUNT = 5;
const BUTTON_TOP_OFFSET_DESKTOP = 116;
const BUTTON_TOP_OFFSET_MOBILE = 150;

function ProductRelatedSlideShow({
  queryRef,
}: WithLoadQueryPropsV1<NFTsRelatedSlideShowQuery>) {
  const isMobile = useIsMobile();
  const visibilityRef = useRef(null);
  const scrollRef = useRef(null);
  const {
    ref: scrollContainerRef,
    inView: isScrollSlideVisible,
    entry,
  } = useInView({ threshold: isMobile ? 0.5 : 0.9 });
  // Mobile Version we will still use ScrollPosition
  const [scrollPosition, setScrollPosition] = useState(0);
  // This is just for the desktop version
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  // This is for desktop to block wheeling for a specific period.
  const wheelingBlocked = useRef(false);
  const scrollToTopButton = document.querySelector(
    '#scrollToTopButton'
  ) as HTMLElement | null;
  const parentWindow = window.parent;
  const parentDocument = parentWindow.document;
  const productPage = parentDocument.querySelector(
    '#productPage'
  ) as HTMLElement | null;
  const relatedArtworks = parentDocument.querySelector(
    '#relatedArtworks'
  ) as HTMLElement | null;
  const getScrollToTopTransform = (distance = -200) =>
    `translate(-50%, ${distance}px)`;

  const {
    data: nfts,
    loading,
    loadMore,
    hasMore,
  }: {
    data: NFTType[];
    hasMore: boolean;
    loadMore(size: number): void;
    loading: boolean;
  } = useInfiniteQueryScroll({
    getConnectionField: (data) => data.results,
    pageSize: RELATED_IMAGE_PAGE_SIZE_LIMIT,
    paginatedQueryResults: usePaginationFragment<
      NFTsRelatedSlideShowQuery,
      NFTsRelatedSlideShow$key
    >(
      NFTsRelatedSlideShowData,
      usePreloadedQuery<NFTsRelatedSlideShowQuery>(
        NFTsRelatedSlideShowType,
        queryRef
      )
    ),
    ref: visibilityRef,
    scrollRef,
  });

  const slideToBottom = () => {
    if (isMobile) {
      entry.target.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    } else {
      productPage.scrollTo(0, productPage.scrollHeight);
    }
  };

  const handleEndScroll = useMemo(
    () =>
      debounce((event: BaseSyntheticEvent, postion) => {
        // handle the end scroll if the user goes back we want to
        // show the back to top.
        const elementHeight = event.target.clientHeight;
        const thresholdForBackToTopButton = isMobile ? 0 : elementHeight;
        if (
          postion > event.target.scrollTop &&
          event.target.scrollTop > thresholdForBackToTopButton
        ) {
          const distance = isMobile
            ? BUTTON_TOP_OFFSET_MOBILE
            : BUTTON_TOP_OFFSET_DESKTOP;
          scrollToTopButton.style.transform = getScrollToTopTransform(distance);
        } else {
          scrollToTopButton.style.transform = getScrollToTopTransform();
        }
      }, SCROLL_DEBOUNCE_PERIOD),
    [scrollToTopButton.style, isMobile]
  );

  const handleScroll = (event: BaseSyntheticEvent) => {
    const scrollRemaining = event.target.scrollHeight - event.target.scrollTop;
    const elementHeight = event.target.clientHeight;
    setScrollPosition(event.target.scrollTop);
    handleEndScroll(event, scrollPosition);
    // When we get to certain height in the scroll we will fetch more artworks.
    const reloadTrigger = elementHeight * MINIMUM_SLIDE_FETCH_MORE_COUNT;
    if (scrollRemaining < reloadTrigger && hasMore && !loading) {
      loadMore(RELATED_IMAGE_PAGE_SIZE_LIMIT);
    }
  };

  const unBlockWheel = () => {
    setTimeout(() => {
      wheelingBlocked.current = false;
    }, WHEEL_BLOCKED_PERIOD);
  };

  const scrollCurrentSlideIntoView = () => {
    const slide = entry.target.querySelector(
      `#slide_${nfts[currentSlideIdx]?.id}`
    );
    if (slide) {
      slide.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  };

  const handleWheel = useMemo(
    () =>
      debounce((event: WheelEvent, idx: number) => {
        // handle wheel this will only be used for the desktop.
        if (event.deltaY > 0) {
          setCurrentSlideIdx(idx < nfts.length - 1 ? idx + 1 : idx);
        } else {
          setCurrentSlideIdx(idx > 0 ? idx - 1 : 0);
        }
        unBlockWheel();
      }, WHEEL_DEBOUNCE_PERIOD),
    [nfts]
  );

  const onWheel = (event: WheelEvent) => {
    if (Math.abs(event.deltaY) < WHEEL_MINIMUM_THRESHOLD || isMobile) return;
    // on wheel will only be used for the desktop.
    if (!wheelingBlocked.current) {
      wheelingBlocked.current = true;
      if (isScrollSlideVisible) {
        handleWheel(event, currentSlideIdx);
      } else if (event.deltaY > 0) {
        slideToBottom();
        scrollCurrentSlideIntoView();
        unBlockWheel();
      } else {
        unBlockWheel();
      }
    }
  };

  useEffect(() => {
    if (!isScrollSlideVisible) {
      scrollToTopButton.style.transform = getScrollToTopTransform();
    }
    if (!isMobile) {
      if (
        isScrollSlideVisible &&
        entry.target.scrollHeight > 0 &&
        currentSlideIdx > 0
      ) {
        productPage.style.overflow = 'hidden';
      } else {
        productPage.style.overflow = 'auto';
      }
    }
  }, [
    isScrollSlideVisible,
    scrollToTopButton,
    currentSlideIdx,
    isMobile,
    entry,
    productPage,
  ]);

  useEffect(() => {
    if (isMobile && entry?.target) {
      if (!isScrollSlideVisible) {
        relatedArtworks.style.overflow = 'hidden';
      } else {
        slideToBottom();
        relatedArtworks.style.overflow = 'auto';
      }
    }
  }, [isMobile, isScrollSlideVisible, entry, relatedArtworks]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMobile || !entry || !isScrollSlideVisible) return;
    scrollCurrentSlideIntoView();
  }, [currentSlideIdx, isMobile, entry]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    nfts?.length > 0 && (
      <div
        className={joinClasses(
          pageStyles.productPageContainer,
          pageStyles.slideOverrides
        )}
      >
        <div className={styles.productRelatedSlideShow} ref={scrollRef}>
          <div
            className={joinClasses(
              isMobile ? MPFonts.headline4 : MPFonts.headline2,
              styles.productRelatedHeader
            )}
          >
            Related Artworks
          </div>
          <div
            className={joinClasses(
              styles.productRelatedSlideShowSnapScrollContainer,
              CSSGlobal.NoScrollbar
            )}
            onScroll={handleScroll}
            onWheel={onWheel}
            ref={scrollContainerRef}
            onMouseEnter={slideToBottom}
            id="relatedArtworks"
          >
            {nfts.map((nft, index) => (
              <div
                className={joinClasses(
                  styles.productRelatedSlideWrapper,
                  styles.productRelatedSlideWrapperSlider,
                  'flexCenter'
                )}
                key={`slide_${nft.id}`}
                id={`slide_${nft.id}`}
              >
                <ProductRelatedSlide nft={nft} position={index} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}

export default withDefaultErrorBoundary(
  withLoadQueryV1(ProductRelatedSlideShow, NFTsRelatedSlideShowType),
  {
    hideState: true,
  }
);
