import { HTMLAttributes, memo } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/avatar/ProfileIcon.module.css';

interface ProfileIconProps extends HTMLAttributes<HTMLImageElement> {
  alt: string;
  source: string;
  className?: string;
}

function ProfileIcon({ source, alt, className, ...props }: ProfileIconProps) {
  return (
    <img
      alt={alt}
      className={joinClasses(styles.profileIcon, className)}
      src={source}
      {...props}
    />
  );
}

export default memo(ProfileIcon);
