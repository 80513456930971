import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface VerifiedIconProps extends SvgIconProps {
  fill?: string;
}

const VerifiedIcon = forwardRef<SVGSVGElement, VerifiedIconProps>(
  ({ fill, ...props }: VerifiedIconProps, ref) => (
    <SvgIcon {...props} ref={ref}>
      <defs>
        <linearGradient id="CC-verified-icon" x2="0.35" y2="1">
          <stop offset="0%" stopColor="var(--CC-verified-icon-color-start)" />
          <stop offset="40%" stopColor="var(--CC-verified-icon-color-mid)" />
          <stop offset="100%" stopColor="var(--CC-verified-icon-color-end)" />
        </linearGradient>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9361 6.78293C19.256 8.22208 19.8356 9.52139 20.6552 10.8207C21.1149 11.5402 21.1149 12.4598 20.6552 13.1793C19.8157 14.4786 19.256 15.7579 18.9361 17.2171C18.7362 18.0767 18.0765 18.7362 17.2171 18.9361C15.7779 19.256 14.4786 19.8356 13.1793 20.6552C12.4598 21.1149 11.5402 21.1149 10.8207 20.6552C9.52143 19.8157 8.24209 19.256 6.78293 18.9361C5.92334 18.7362 5.26378 18.0765 5.06388 17.2171C4.74401 15.7779 4.16439 14.4786 3.34483 13.1793C2.88506 12.4598 2.88506 11.5402 3.34483 10.8207C4.18434 9.52142 4.74401 8.22214 5.06388 6.78293C5.26379 5.92334 5.92347 5.26378 6.78293 5.06388C8.22208 4.74401 9.52139 4.16439 10.8207 3.34483C11.5402 2.88506 12.4598 2.88506 13.1793 3.34483C14.4786 4.18434 15.7579 4.74401 17.2171 5.06388C18.0767 5.26379 18.7362 5.92347 18.9361 6.78293ZM15.5187 10.4547C15.7906 10.1816 15.7631 9.76328 15.4571 9.52047C15.1512 9.27765 14.6827 9.30226 14.4107 9.57542L11.0118 12.9896L9.58926 11.5607C9.31731 11.2876 8.84884 11.2629 8.54289 11.5058C8.23695 11.7486 8.20939 12.1669 8.48134 12.44L10.4578 14.4253C10.5985 14.5666 10.8001 14.6474 11.0118 14.6474C11.2235 14.6474 11.4251 14.5666 11.5657 14.4253L15.5187 10.4547Z"
        fill={fill || 'currentColor'}
      />
    </SvgIcon>
  )
);

export default VerifiedIcon;
