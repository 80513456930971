import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

import {
  MPDropdown,
  MPStyledTextField,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses, KeyboardEventKey } from '@mp-frontend/core-utils';

import useStateBackedObjRef from 'hooks/useStateBackedObjRef';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

export type PriceFilterValue = Partial<{
  max: string;
  min: string;
}>;

interface PriceFilterProps {
  onChange: (value: PriceFilterValue) => void;
  value: PriceFilterValue;
}

export default function PriceFilter({ onChange, value }: PriceFilterProps) {
  const [priceRef, , setPrice] = useStateBackedObjRef(value);
  const [lastValidMinPrice, setLastValidMinPrice] = useState(value.min);
  const [lastValidMaxPrice, setLastValidMaxPrice] = useState(value.max);
  const [shouldChange, setShouldChange] = useState(false);
  const closedRef = useRef(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    setPrice('min', value.min);
    setPrice('max', value.max);
  }, [value, setPrice]);

  useEffect(() => {
    if (!shouldChange) return;

    setShouldChange(false);
    onChange(priceRef.current);
  }, [priceRef, shouldChange, onChange]);

  const constrainMaxPrice = useCallback(() => {
    const isPriceLessthanMin =
      priceRef.current.min &&
      parseFloat(priceRef.current.max) < parseFloat(priceRef.current.min);
    const passesFloatyRegex = /^[0-9]*\.?[0-9]*$/.test(priceRef.current.max);
    const hasInvalidLeadingZero =
      priceRef.current.max?.length > 1 && priceRef.current.max[0] === '0';

    isPriceLessthanMin || !passesFloatyRegex || hasInvalidLeadingZero
      ? setPrice('max', lastValidMaxPrice)
      : setLastValidMaxPrice(priceRef.current.max);
  }, [priceRef, lastValidMaxPrice, setPrice, setLastValidMaxPrice]);

  const constrainMinPrice = useCallback(() => {
    const isPriceGreaterthanMax =
      priceRef.current.max &&
      parseFloat(priceRef.current.min) > parseFloat(priceRef.current.max);
    const passesFloatyRegex = /^[0-9]*\.?[0-9]*$/.test(priceRef.current.min);
    const hasInvalidLeadingZero =
      priceRef.current.min?.length > 1 && priceRef.current.min[0] === '0';

    isPriceGreaterthanMax || !passesFloatyRegex || hasInvalidLeadingZero
      ? setPrice('min', lastValidMinPrice)
      : setLastValidMinPrice(priceRef.current.min);
  }, [priceRef, lastValidMinPrice, setPrice, setLastValidMinPrice]);

  const handleSetPrice = useCallback(
    (type: 'min' | 'max') => (priceValue: string) => setPrice(type, priceValue),
    [setPrice]
  );

  const handleKeyPress = useCallback(
    (constrainPrice: () => void) =>
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== KeyboardEventKey.enter) return;

        constrainPrice();
        setShouldChange(true);
      },
    []
  );

  const handleToggleDropdown = useCallback(
    (open: boolean) => {
      if (closedRef.current === open) return;
      closedRef.current = open;
      if (open) return;

      onChange(priceRef.current);
    },
    [closedRef, priceRef, onChange]
  );

  return (
    <MPDropdown title="Price Range" onToggle={handleToggleDropdown}>
      <div
        className={joinClasses(
          isMobile ? CSSGlobal.Flex.InlineRow : CSSGlobal.Flex.CenteredRow,
          CSSGap[16]
        )}
      >
        <MPStyledTextField
          label="Minimum Price (USD)"
          placeholder="Min Price"
          value={priceRef.current.min ?? ''}
          setValue={handleSetPrice('min')}
          onBlur={constrainMinPrice}
          onKeyPress={handleKeyPress(constrainMinPrice)}
          inputMode="numeric"
          startAdornment="$"
        />
        <MPStyledTextField
          label="Maximum Price (USD)"
          placeholder="Max Price"
          value={priceRef.current.max ?? ''}
          setValue={handleSetPrice('max')}
          onBlur={constrainMaxPrice}
          onKeyPress={handleKeyPress(constrainMaxPrice)}
          inputMode="numeric"
          startAdornment="$"
        />
      </div>
    </MPDropdown>
  );
}
