import {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isEqualWith, sortBy } from 'lodash';
import {
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from 'react-relay';
import { Link } from 'react-router-dom';
import { InputAdornment } from '@mui/material';

import {
  MPActionButton,
  MPButton,
  MPDivider,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { CommentIcon } from '@mp-frontend/core-components/icons';
import {
  joinClasses,
  useOnEnterKey,
  useRefState,
} from '@mp-frontend/core-utils';

import NFTsCommentsData, {
  NFTsComments$data,
  NFTsComments$key,
} from 'graphql/__generated__/NFTsComments.graphql';
import NFTsCommentsType, {
  NFTsCommentsQuery,
} from 'graphql/__generated__/NFTsCommentsQuery.graphql';

import Panel from 'components/panels/Panel';
import ROUTES from 'constants/Routes';
import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
} from 'hooks/useCommentMutation';
import useInfiniteQueryScroll, {
  ScrollDirection,
} from 'hooks/useInfiniteQueryScroll';
import useSession from 'hooks/useSession';
import { NFTType } from 'types/graphql/NFT';
import timePassedSinceDate from 'utils/datetime/timePassedSinceDate';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import getProfileImage from 'utils/getProfileImage';
import {
  WithLoadQueryPropsV1,
  withLoadQueryV1,
} from 'utils/hocs/withLoadQuery';
import withLoginRequiredClick from 'utils/hocs/withLoginRequiredClick';

import * as styles from '../../css/pages/product/ProductSocialBar.module.css';

type NFTsCommentType = NFTsComments$data['results']['edges'][0]['node'];
type NFTsCommentViewType = Pick<
  NFTsCommentType,
  'body' | 'user' | 'createdAt' | 'updatedAt'
> & {
  id: number;
};

const MAX_COMMENT_CHARACTERS = 300;
const PAGE_SIZE = 25;

interface SubmitButtonProps {
  children: ReactNode;
  disabled: boolean;
  onClick: (event: MouseEvent) => void;
  onKeyPress: (event: KeyboardEvent) => void;
}

const SubmitButton = withLoginRequiredClick<SubmitButtonProps>(
  ({ onClick, onKeyPress, disabled, children }: SubmitButtonProps) => (
    <div
      role="button"
      tabIndex={0}
      className={styles.commentSubmitButton}
      data-disabled={disabled}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {children}
    </div>
  )
);

const commentsComparator = (
  a: NFTsCommentType | NFTsCommentType[],
  b: NFTsCommentType | NFTsCommentType[]
) => {
  if (Array.isArray(a) || Array.isArray(b)) {
    return undefined;
  }

  return a.pk === b.pk;
};

interface ProductCommentsProps {
  onDelete: (id: number, onSuccess: () => void) => void;
  onEdit: (
    id: number,
    body: string,
    onSuccess: (updatedComment: Partial<NFTsCommentViewType>) => void
  ) => void;
  queryRef: PreloadedQuery<NFTsCommentsQuery>;
  setBottomRef: (node: HTMLDivElement) => void;
  className?: string;
}

function ProductComments({
  queryRef,
  className,
  onEdit,
  onDelete,
  setBottomRef,
}: ProductCommentsProps) {
  const session = useSession();
  const visibilityRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { data, loading } = useInfiniteQueryScroll({
    direction: ScrollDirection.Up,
    getConnectionField: (response: any) => response.results,
    pageSize: PAGE_SIZE,
    paginatedQueryResults: usePaginationFragment<
      NFTsCommentsQuery,
      NFTsComments$key
    >(
      NFTsCommentsData,
      usePreloadedQuery<NFTsCommentsQuery>(NFTsCommentsType, queryRef)
    ),
    ref: visibilityRef,
    scrollRef,
  });
  const prevDataRef = useRef<NFTsCommentType[]>([]);
  const [comments, setComments] = useState<NFTsCommentViewType[]>([]);

  const prevDataEqualsToReceived = isEqualWith(
    data,
    prevDataRef.current,
    commentsComparator
  );
  useEffect(() => {
    if (prevDataEqualsToReceived) return;

    setComments(
      sortBy(
        data.map(
          ({ pk, body, createdAt, updatedAt, user }) =>
            ({
              body,
              createdAt,
              id: Number(pk),
              updatedAt,
              user,
            } as NFTsCommentViewType)
        ),
        'createdAt'
      )
    );
    prevDataRef.current = data;
  }, [prevDataEqualsToReceived]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditClick = useCallback(
    (event: MouseEvent, comment: NFTsCommentViewType) => {
      event.preventDefault();
      const commentId = comment.id;
      onEdit(
        commentId,
        comment.body,
        (updatedComment: Partial<NFTsCommentViewType>) => {
          setComments((prevComments) =>
            prevComments.map((prevComment) =>
              prevComment.id === commentId
                ? { ...prevComment, ...updatedComment }
                : prevComment
            )
          );
        }
      );
    },
    [onEdit]
  );

  const handleDeleteClick = useCallback(
    (event: MouseEvent, comment: NFTsCommentViewType) => {
      event.preventDefault();
      const commentId = comment.id;
      onDelete(commentId, () => {
        setComments((prevComments) =>
          prevComments.filter((prevComment) => prevComment.id !== commentId)
        );
      });
    },
    [onDelete]
  );

  return (
    <div
      className={joinClasses(className, !comments.length ? styles.empty : null)}
      ref={scrollRef}
    >
      <div ref={visibilityRef}>&nbsp;</div>

      {loading || comments.length ? (
        <>
          {comments.map((comment: NFTsCommentViewType, idx: number) => (
            <Fragment key={comment.id}>
              <div className={styles.commentItem}>
                <Link
                  className={styles.commentItemHeader}
                  to={ROUTES.PROFILE.GALLERY.COLLECTED(
                    comment.user.store.storeSlug
                  )}
                >
                  <div
                    className={styles.commentItemProfileImage}
                    style={{
                      backgroundImage: `url(${getProfileImage(
                        comment.user.profileImageUrl
                      )})`,
                    }}
                  />
                  <div
                    className={joinClasses(
                      'hoverableLink',
                      MPFonts.textNormalMedium,
                      styles.commentItemUsername
                    )}
                  >
                    {generateFormattedUserFullName(comment.user.username)}
                  </div>
                  <div className={styles.commentItemDate}>
                    {timePassedSinceDate(comment.createdAt)}
                  </div>
                </Link>

                <div
                  className={joinClasses(
                    MPFonts.paragraphSmall,
                    styles.commentItemContent
                  )}
                >
                  {comment.body}
                </div>

                {session.isLoggedIn() &&
                comment.user.username === session.account.username ? (
                  <div className={styles.commentItemFooter}>
                    <MPButton
                      variant="text"
                      size="medium"
                      className={styles.commentItemAction}
                      onClick={(event) => handleEditClick(event, comment)}
                    >
                      Edit
                    </MPButton>
                    <MPButton
                      variant="text"
                      size="medium"
                      className={styles.commentItemAction}
                      onClick={(event) => handleDeleteClick(event, comment)}
                    >
                      Delete
                    </MPButton>
                  </div>
                ) : null}
              </div>

              {idx < comments.length - 1 ? (
                <div className={styles.commentDivider}>
                  <MPDivider />
                </div>
              ) : null}
            </Fragment>
          ))}

          <div ref={(node) => setBottomRef(node)} />
        </>
      ) : (
        <div
          className={joinClasses(
            MPFonts.textNormalMedium,
            styles.commentNoContent
          )}
        >
          Be the first to leave a comment for this artwork.
        </div>
      )}
    </div>
  );
}

interface ProductCommentsPanelProps
  extends WithLoadQueryPropsV1<NFTsCommentsQuery> {
  nft: NFTType;
  onClose: () => void;
  onComment: () => void;
  open: boolean;
}

const ProductCommentsPanel = withLoadQueryV1(
  ({ queryRef, nft, open, onClose, onComment }: ProductCommentsPanelProps) => {
    const {
      pk: nftId,
      metadata: { pk: nftMetadataId, title, thumbnailImage },
    } = nft;

    const session = useSession();
    const [bottomRef, setBottomRef] = useRefState<HTMLDivElement>(null);
    const [createCommentMutation, createCommentLoading] =
      useCreateCommentMutation();
    const [editCommentMutation, editCommentLoading] = useEditCommentMutation();
    const [deleteCommentMutation, deleteCommentLoading] =
      useDeleteCommentMutation();
    const [editingState, setEditingState] = useState<{
      commentId: number;
      onSuccess: (updatedComment: Partial<NFTsCommentViewType>) => void;
    } | null>(null);
    const [deletingState, setDeletingState] = useState<{
      commentId: number;
      onSuccess: () => void;
    } | null>(null);
    const [commentingError, setCommentingError] = useState<string | null>(null);
    const [comment, setComment] = useState<string>('');
    const { refetch } = usePaginationFragment<
      NFTsCommentsQuery,
      NFTsComments$key
    >(
      NFTsCommentsData,
      usePreloadedQuery<NFTsCommentsQuery>(NFTsCommentsType, queryRef)
    );

    const errorMessage =
      comment && comment.length > MAX_COMMENT_CHARACTERS
        ? `Comment should be less than ${MAX_COMMENT_CHARACTERS} characters.`
        : commentingError;
    const disabled =
      createCommentLoading || editCommentLoading || !comment || !!errorMessage;

    const scrollToBottom = useCallback(() => {
      if (!bottomRef) return;

      bottomRef.scrollIntoView({
        block: 'center',
      });
    }, [bottomRef]);

    const handleSubmitClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();

        if (disabled) return;

        if (editingState) {
          editCommentMutation({
            onCompleted({
              editCommentNft: { success, comment: updatedComment },
            }) {
              if (!success) {
                return;
              }

              editingState.onSuccess({
                body: updatedComment.body,
                updatedAt: updatedComment.updatedAt,
              });
              setComment('');
              setCommentingError(null);
              setEditingState(null);
            },
            variables: { body: comment, commentId: editingState.commentId },
          });
        } else {
          createCommentMutation({
            onCompleted({ commentNft: { success } }) {
              if (!success) {
                return;
              }

              refetch(
                {
                  first: PAGE_SIZE,
                  nftMetadataId,
                  parentId: null,
                },
                {
                  fetchPolicy: 'store-and-network',
                  onComplete: () => setTimeout(scrollToBottom, 100),
                }
              );
              setComment('');
              setCommentingError(null);
              onComment();
            },
            onError(error) {
              setCommentingError(error?.message);
            },
            variables: {
              body: comment,
              nftId: Number(nftId),
              nftMetadataId: Number(nftMetadataId),
              parentId: null,
              replyToUser: null,
            },
          });
        }
      },
      [
        comment,
        createCommentMutation,
        disabled,
        editCommentMutation,
        editingState,
        scrollToBottom,
        nftId,
        nftMetadataId,
        onComment,
        refetch,
      ]
    );
    const handleSubmitPress = useOnEnterKey(handleSubmitClick);

    const handleDeleteClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();

        const { commentId, onSuccess } = deletingState;

        deleteCommentMutation({
          onCompleted({ deleteCommentNft: { success } }) {
            if (!success) {
              return;
            }

            onSuccess();
            setDeletingState(null);
          },
          variables: { commentId },
        });
      },
      [deletingState, deleteCommentMutation]
    );
    const handleDeleteClose = useCallback(() => setDeletingState(null), []);

    useEffect(() => {
      if (!open) return;

      scrollToBottom();
    }, [scrollToBottom, open]);

    return (
      <>
        <Panel anchor="right" title="Comments" open={open} onClose={onClose}>
          <div className={styles.commentDialog}>
            <div className={styles.commentNft}>
              <div
                className={styles.commentNftImage}
                style={{ backgroundImage: `url(${thumbnailImage})` }}
              />
              <div
                className={joinClasses(
                  MPFonts.textNormalMedium,
                  styles.commentNftTitle,
                  'textOverflowEllipsis'
                )}
              >
                {title}
              </div>
            </div>

            <ProductComments
              queryRef={queryRef}
              className={styles.commentContent}
              onEdit={(
                commentId: number,
                body: string,
                onSuccess: (
                  updatedComment: Partial<NFTsCommentViewType>
                ) => void
              ) => {
                if (editCommentLoading) return;

                setComment(body);
                setEditingState({
                  commentId,
                  onSuccess,
                });
              }}
              onDelete={(commentId: number, onSuccess: () => void) => {
                if (deleteCommentLoading) return;

                setDeletingState({
                  commentId,
                  onSuccess,
                });
              }}
              setBottomRef={setBottomRef}
            />

            <div className={styles.commentFooter}>
              <div
                className={styles.commentProfileImage}
                style={{ backgroundImage: `url(${session.getProfileImage()})` }}
              />
              <div
                className={joinClasses(
                  MPFonts.textNormalMedium,
                  styles.commentProfileUsername
                )}
              >
                {session.isLoggedIn() ? session.account?.username : ''}
              </div>

              <MPStyledTextField
                placeholder="Comment on this artwork"
                value={comment}
                className={styles.commentInput}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setComment(event.target.value)
                }
                onKeyDown={handleSubmitPress}
                endAdornment={
                  <InputAdornment
                    position="start"
                    className={styles.commentEndAdornment}
                  >
                    <SubmitButton
                      disabled={disabled}
                      onClick={handleSubmitClick}
                      onKeyPress={handleSubmitPress}
                    >
                      <CommentIcon sx={{ fontSize: 16 }} />
                    </SubmitButton>
                  </InputAdornment>
                }
                multiline
                error={errorMessage}
              />
            </div>
          </div>
        </Panel>

        <MPStandardDialog
          title="Delete Comment"
          open={!!deletingState}
          onClose={handleDeleteClose}
        >
          <div className={styles.commentDeletingDialog}>
            <div className={MPFonts.paragraphNormal}>
              Are you sure you want to delete this comment?
            </div>

            <MPActionButton
              fullWidth
              variant="primary"
              onClick={handleDeleteClick}
            >
              Delete
            </MPActionButton>
          </div>
        </MPStandardDialog>
      </>
    );
  },
  NFTsCommentsType
);

export default function useCommentProduct({ nft }: { nft: NFTType }) {
  const { metadata } = nft;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [commentsCount, setCommentsCount] = useState<number>(
    metadata.commentCount
  );
  const [didUserComment, setDidUserComment] = useState<boolean>(
    metadata.currentAccountComment
  );

  const handleOpenMouseClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setShowDialog(true);
  }, []);
  const handleOpenKeyboardPress = useOnEnterKey(handleOpenMouseClick);

  const handleClose = useCallback(() => setShowDialog(false), [setShowDialog]);

  const handleComment = useCallback(() => {
    setCommentsCount((prevCount) => prevCount + 1);
    setDidUserComment(true);
  }, [setCommentsCount, setDidUserComment]);

  return [
    commentsCount,
    didUserComment,
    handleOpenMouseClick,
    handleOpenKeyboardPress,
    <ProductCommentsPanel
      nft={nft}
      queryVariables={{
        first: PAGE_SIZE,
        nftMetadataId: metadata.pk,
        parentId: null,
      }}
      open={showDialog}
      onClose={handleClose}
      onComment={handleComment}
    />,
  ] as const;
}
