import ExploreArtistsPaginatedFragmentType, {
  ExploreArtistsPaginatedFragment$data,
} from 'graphql/__generated__/ExploreArtistsPaginatedFragment.graphql';
import PaginatedExploreArtistsQueryType, {
  PaginatedExploreArtistsQuery,
} from 'graphql/__generated__/PaginatedExploreArtistsQuery.graphql';

import UserCard from 'components/cards/UserCard';

import Cards from '../Cards';
import Layout, { BaseLayoutProps } from '../Layout';
import { ExploreType } from '../types';
import Chips from './Chips';
import {
  ArtistsFiltersState,
  Filters,
  useArtistsFiltersQueryValues,
} from './Filters';
import Hero from './Hero';

interface ExploreArtistsProps extends BaseLayoutProps {
  filters: ArtistsFiltersState;
  onFiltersChange: (value: Partial<ArtistsFiltersState>) => void;
  onFiltersReset: () => void;
}

function ExploreArtists({
  filters,
  onFiltersChange,
  onFiltersReset,
  ...props
}: ExploreArtistsProps) {
  const queryValues = useArtistsFiltersQueryValues(filters);

  return (
    <Layout
      {...props}
      hero={<Hero heroArtistQuery={{ variables: {} }} />}
      cards={
        <Cards<
          PaginatedExploreArtistsQuery,
          ExploreArtistsPaginatedFragment$data['artist']['results']['edges'][number]['node']
        >
          exploreType={ExploreType.Artists}
          filters={
            <>
              <Filters state={filters} onChange={onFiltersChange} />
              <Chips
                filters={filters}
                onChange={onFiltersChange}
                onReset={onFiltersReset}
              />
            </>
          }
          variables={queryValues}
          fragmentConcreteRequest={ExploreArtistsPaginatedFragmentType}
          queryConcreteRequest={PaginatedExploreArtistsQueryType}
          renderElement={(artist) => (
            <UserCard
              key={artist.id}
              user={artist}
              biography={artist.biography}
              createdArtworks={artist.createdArtworks}
              store={artist.store}
            />
          )}
        />
      }
    />
  );
}

export default ExploreArtists;
