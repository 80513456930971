import { forwardRef, MouseEvent, ReactNode } from 'react';
import { merge } from 'lodash';
import { SxProps, Tab, tabClasses, TabProps, Theme } from '@mui/material';
import { styled } from '@mui/system';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';
import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { InfoIcon } from '../icons';
import { MPTooltip } from '..';
import MPTabsSize from './size';
import MPTabsVariant from './variant';

const TruncatedSpan = styled('span')`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`;

const designFontMap: Record<MPTabsVariant, Record<MPTabsSize, MPFonts>> = {
  [MPTabsVariant.Text]: {
    [MPTabsSize.Small]: MPFonts.textNormalMedium,
    [MPTabsSize.Medium]: MPFonts.textNormalMedium,
  },
  [MPTabsVariant.Standard]: {
    [MPTabsSize.Small]: MPFonts.textSmallMedium,
    [MPTabsSize.Medium]: MPFonts.textNormalMedium,
  },
};

const designSxMap: Record<MPTabsVariant, SxProps<Theme>> = {
  [MPTabsVariant.Text]: {
    [`&.${tabClasses.selected}, &:hover`]: {
      color: MPColorValue.PrimaryDark,
    },
    color: MPColorValue.SolidNeutralGray3,
    fontSize: '12px',
    height: '36px',
    letterSpacing: '1.42273px',
    lineHeight: '17px',
    padding: '0.5rem 0px',
  },
  [MPTabsVariant.Standard]: {
    [`&.${tabClasses.root}`]: {
      borderRadius: '0px',
      color: MPColorValue.SolidNeutralGray3,
      cursor: 'pointer',
      minHeight: 'auto',
      minWidth: '40px',
      padding: '10px',
    },
    [`&.${tabClasses.selected}`]: {
      background: MPColorValue.BackgroundDefault,
      borderBottomColor: MPColorValue.CommonBlack,
      borderBottomStyle: 'solid',
      borderBottomWidth: '2px',
      color: MPColorValue.CommonBlack,
    },
    [`&.${tabClasses.root}:not(.${tabClasses.selected}):not(.${tabClasses.disabled}):hover`]:
      {
        borderBottomColor: MPColorValue.SolidNeutralGray3,
        borderBottomStyle: 'solid',
        borderBottomWidth: '2px',
        color: MPColorValue.SolidNeutralGray3,
      },
    [`&.${tabClasses.disabled}`]: {
      alignItems: 'center',
      cursor: 'default',
      display: 'flex',
      flexDirection: 'row',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      gap: '2px',
      lineHeight: 'inherit',
      pointerEvents: 'none',
      textDecoration: 'line-through',
    },
    [`&.${tabClasses.disabled} svg`]: {
      cursor: 'default',
      fontSize: 16,
      pointerEvents: 'auto',
    },
  },
};

const baseSx = {
  borderRadius: '2px',
  maxWidth: '50%',
  minWidth: '85px',
  textAlign: 'center',
  textTransform: 'none',
  width: 'fit-content',
};

export interface MPTabProps extends TabProps {
  /**
   *
   */
  className?: string;

  /**
   * ref to component that should replace the default button
   */
  component?: any;

  /**
   * Either filled or standard
   */
  design?: MPTabsVariant;

  /**
   * Either disabled or enabled
   */
  disabled?: boolean;

  /**
   * A tooltip message to display when the tab is disabled
   */
  disabledMessage?: ReactNode;

  /**
   * This is normally controlled by the MPTabs component, only use if this tab is non controlled.
   */
  selected?: boolean;

  /**
   * Size of the tab
   */
  size?: MPTabsSize;

  /**
   * Overrides MUI CSS stylings.
   */
  sx?: SxProps<Theme>;

  /**
   * Alternate Font for Tab
   */
  tabFont?: MPFonts;

  /**
   * Path the tab should take you to
   */
  to?: string;
}

const MPTab = forwardRef<HTMLDivElement, MPTabProps>(
  (
    {
      label,
      className,
      sx = {},
      tabFont,
      design = MPTabsVariant.Standard,
      size = MPTabsSize.Small,
      disabledMessage = null,
      ...passedProps
    }: MPTabProps,
    ref
  ) => {
    const mergedSx = merge({}, baseSx, designSxMap[design], sx);

    const newLabel = (
      <>
        {label && typeof label === 'string' ? (
          <TruncatedSpan>{label}</TruncatedSpan>
        ) : (
          label
        )}

        {disabledMessage ? (
          <MPTooltip
            title={disabledMessage}
            placement="top"
            onClick={(event: MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <InfoIcon fontSize="inherit" />
          </MPTooltip>
        ) : null}
      </>
    );

    return (
      <Tab
        ref={ref}
        className={[className, tabFont || designFontMap[design][size]]
          .filter(Boolean)
          .join(' ')}
        sx={mergedSx}
        label={newLabel}
        {...passedProps}
      />
    );
  }
);

export default MPTab;
