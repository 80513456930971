import { MPFonts, MPStandardDialog } from '@mp-frontend/core-components';

interface ArtworkReservedViewProps {
  onClose: () => void;
}

function ArtworkReservedView({ onClose }: ArtworkReservedViewProps) {
  return (
    <MPStandardDialog onClose={onClose} title="Artwork Reserved">
      <div className={MPFonts.paragraphSmall}>
        This artwork is being purchased by another collector. Please try again.
      </div>
    </MPStandardDialog>
  );
}

export default ArtworkReservedView;
