.container {
  @media (--mq-is-mobile) {
    padding: 0 24px
  }
}

.sections {
  gap: 60px;
  padding: 60px 0;

  @media (--mq-is-mobile) {
    gap: 48px;
    padding: 24px 0;
  }
}

.sections .header {
  max-width: 906px;
  gap: 20px;
  text-align: center;

  @media (--mq-is-mobile) {
    gap: 16px;
    text-align: left;
  }
}

.sections section {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1379px;
  padding: 0 32px;
  width: 100%;

  @media screen and (min-width: 1920px) {
    max-width: 1699px;
  }

  @media (--mq-is-mobile) {
    padding: 0;
  }
}
