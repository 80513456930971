.panelBody {
  display: flex;
  flex-direction: column;
  gap: 41px;
  overflow: overlay;
  flex: 1 1 auto;
  padding-left: var(--horz-margin);
  padding-right: var(--horz-margin);
  box-sizing: border-box;
}

.firstNonEmptyElement {
  padding-top: 12px;
}

.panelFooter {
  flex: 1 0 auto;
  padding-left: var(--horz-margin);
  padding-right: var(--horz-margin);
  padding-top: 4px;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sectionHeading {
  color: var(--color-SolidNeutralGray5);
  margin-bottom: 5px;
}

.linkSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bodyWithFooter, .bodyWithoutFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.bodyWithFooter, .bodyWithoutFooter > .panelBody  {
  padding-bottom: var(--vert-margin);
}

.socialIcons {
  gap: 4px;
  margin-left: -3px;
  margin-bottom: -5px;
}

.footerLinks {
  gap: 16px;
  margin-bottom: -3px;
}

@media (--mq-is-mobile) {
  .bodyWithFooter {
    padding-bottom: 0;
  }

  .panelFooter {
    padding: 0 0 24px;
  }

  .panelBody {
    padding: 32px var(--horz-margin) 48px;
    background-color: var(--color-backgroundDefault);
  }

  .footerLinks {
    justify-content: flex-start;
    gap: 16px;
  }
}
.panelPriceWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
}

.panelPriceEth {
  position: relative;
  top: -1.5px;
}
