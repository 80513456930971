import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { PageWithFooter } from 'components/Footer';

import useSetDocTitle from '../useSetDocTitle';
import Activity from './Activity';
import Collection from './Collection';
import SavedArtworks from './SavedArtworks';

import * as styles from 'css/pages/settings/wallet/index.module.css';

export default function CollectionPage() {
  useSetDocTitle('Collection');

  return (
    <PageErrorBoundary>
      <PageWithFooter>
        <div className={styles.walletPage}>
          <Collection />
          <Activity />
          <SavedArtworks />
        </div>
      </PageWithFooter>
    </PageErrorBoundary>
  );
}
