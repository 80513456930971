import ProductDefaultLabel from 'pages/product/productLabels/ProductDefaultLabel';
import ProductPriceLabel from 'pages/product/productLabels/ProductPriceLabel';
import ProductTimerLabel, {
  TimerType,
} from 'pages/product/productLabels/ProductTimerLabel';
import {
  OpenEditionDropMetadataType,
  OpenEditionType,
} from 'types/graphql/OpenEdition';
import {
  generateShortEthPriceString,
  generateShortUsdPriceString,
} from 'utils/currency/generatePricing';
import { getNFTPresaleState } from 'utils/nftUtils';

interface OpenEditionLabelsProps {
  dropMetadata: Omit<
    OpenEditionDropMetadataType,
    'dropsAt' | 'endsAt' | 'listing'
  >;
  dropsAt: OpenEditionDropMetadataType['dropsAt'];
  editionsSold: OpenEditionType['editionsSold'];
  endsAt: OpenEditionDropMetadataType['endsAt'];
  forceUpdate: () => void;
  openEdition: OpenEditionType;
}

export default function OpenEditionLabels({
  dropMetadata,
  dropsAt,
  endsAt,
  editionsSold,
  openEdition,
  forceUpdate,
}: OpenEditionLabelsProps) {
  const { showPresale, isPresaleEligible } = getNFTPresaleState(
    !!openEdition.presalePriceInUsd,
    dropMetadata
  );

  return (
    <>
      <ProductPriceLabel
        label="Buy Now"
        isUSDPrimary
        usdPrice={generateShortUsdPriceString(openEdition.priceInUsd)}
        ethPrice={generateShortEthPriceString(openEdition.priceInEther)}
      />
      {!!showPresale && (
        <ProductPriceLabel
          label="Pre-Sale Price"
          isHidden={!isPresaleEligible}
          isPremium={isPresaleEligible}
          isUSDPrimary
          usdPrice={generateShortUsdPriceString(openEdition.presalePriceInUsd)}
          ethPrice={generateShortEthPriceString(
            openEdition.presalePriceInEther
          )}
        />
      )}
      <ProductDefaultLabel label="Editions Purchased" value={editionsSold} />
      {showPresale && isPresaleEligible ? (
        <ProductTimerLabel
          type={TimerType.Presale}
          startDate={dropMetadata.drop.presale.startsAt}
          endDate={dropMetadata.drop.presale.endsAt}
          onEnd={forceUpdate}
        />
      ) : (
        <ProductTimerLabel
          type={TimerType.OpenEdition}
          startDate={dropsAt}
          endDate={endsAt}
          upcomingLabel={
            showPresale && !isPresaleEligible
              ? 'Public Sale Starts In'
              : undefined
          }
          onEnd={forceUpdate}
        />
      )}
    </>
  );
}
