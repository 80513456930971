import { useAccount } from 'wagmi';

import { DEFAULT_GAS_LIMIT } from 'constants/contract/DefaultGasLimit';

export default function useDefaultContractOverrides() {
  const { address: account } = useAccount();

  return {
    account,
    gasLimit: DEFAULT_GAS_LIMIT,
  };
}
