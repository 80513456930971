import { MpErrors } from 'types/__generated__/graphql';

import MPGraphQLError from './MPGraphQLError';

export default class FourOFour extends MPGraphQLError {
  constructor() {
    super({
      code: 400,
      message: 'Resource Not Found',
      name: MpErrors.NotFound,
    });
  }
}
