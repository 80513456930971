/**
 * @generated SignedSource<<f1aa266a51f303100fd9cc14226e229b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountRegisterWalletMutation$variables = {
  address: `0x${string}`;
  signature: string;
  isCreationEnabled: boolean;
};
export type AccountRegisterWalletMutation$data = {
  readonly registerWallet: {
    readonly success: boolean | null;
  } | null;
};
export type AccountRegisterWalletMutation = {
  variables: AccountRegisterWalletMutation$variables;
  response: AccountRegisterWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCreationEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signature"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "isCreationEnabled",
            "variableName": "isCreationEnabled"
          },
          {
            "kind": "Variable",
            "name": "signature",
            "variableName": "signature"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "RegisterWalletMutation",
    "kind": "LinkedField",
    "name": "registerWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRegisterWalletMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountRegisterWalletMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9012a1ba23bd82792a9822b2d2ad9f16",
    "id": null,
    "metadata": {},
    "name": "AccountRegisterWalletMutation",
    "operationKind": "mutation",
    "text": "mutation AccountRegisterWalletMutation(\n  $address: HexString!\n  $signature: String!\n  $isCreationEnabled: Boolean!\n) {\n  registerWallet(requestData: {address: $address, signature: $signature, isCreationEnabled: $isCreationEnabled}) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b7f2d74a9d23f444a1d5fd7c95e7b30";

export default node;
