import { Dispatch, SetStateAction, startTransition } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import { NFTType } from 'types/graphql/NFT';

import ProductApprovalExternal from './ProductApprovalExternal';
import ProductApprovalOBO from './ProductApprovalOBO';

import * as styles from '../../../css/pages/product/ProductSetBuyNow.module.css';

interface ProductOwnerApproveProps {
  approvalRegistryContract: NFTContractQuery$data['nftContract'];
  currentTransactionHash: string;
  invalidate: () => void;
  nft: NFTType;
  saleContract: Pick<
    NFTContractQuery$data['nftContract'],
    'abidata' | 'address'
  >;
  saleContractApprovalRefetch: () => void;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  message?: string;
}

const DEFAULT_MESSAGE =
  'You have not given wallet approval so we can transfer your NFT once it sells. Please do so now by clicking below.';

function ProductOwnerApprove({
  nft,
  saleContract,
  approvalRegistryContract,
  currentTransactionHash,
  setCurrentTransactionHash,
  saleContractApprovalRefetch,
  invalidate,
  message = DEFAULT_MESSAGE,
}: ProductOwnerApproveProps) {
  return (
    <div>
      <div className={styles.productSellContainer}>
        <div
          className={joinClasses(
            styles.productSellMessageContainer,
            MPFonts.paragraphNormal
          )}
        >
          {message}
        </div>
      </div>
      <div className={styles.productSellFooter}>
        <div className={styles.productSellListingBtnContainer}>
          <>
            {!nft.contract.isExternal ? (
              <ProductApprovalOBO
                approvalRegistryContract={approvalRegistryContract}
                currentTransactionHash={currentTransactionHash}
                invalidateNFTForSaleApproval={() => {
                  startTransition(() => {
                    saleContractApprovalRefetch();
                    invalidate();
                  });
                }}
                saleContract={saleContract}
                setCurrentTransactionHash={setCurrentTransactionHash}
                nft={nft}
              />
            ) : (
              <ProductApprovalExternal
                externalTokenContract={nft.contract}
                currentTransactionHash={currentTransactionHash}
                invalidateNFTForSaleApproval={() => {
                  startTransition(() => {
                    saleContractApprovalRefetch();
                    invalidate();
                  });
                }}
                saleContract={saleContract}
                setCurrentTransactionHash={setCurrentTransactionHash}
                nft={nft}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export default ProductOwnerApprove;
