.MPStyledTextField {
  :global(.MuiOutlinedInput-root) {
    padding: 0px;
  }
  :global(.MuiInputBase-root) {
    padding: 10px 16px;
    border: none;
  }
  :global(.MuiInputBase-input) {
    padding: 0;
  }
  :global(.MuiInputBase-formControl) {
    background-color: var(--color-commonWhite);
    border-radius: 0;
    font-size: inherit;
    line-height: inherit;

    :global(.MuiOutlinedInput-notchedOutline) {
      border: 1px solid var(--color-commonBlack);
    }
  }
  :global(.MuiInputBase-formControl):global(.MuiInputBase-empty) :global(.MuiOutlinedInput-notchedOutline) {
    border-color: var(--color-SolidNeutralGray3);
  }
  :global(.MuiInputBase-formControl):not(:global(.Mui-focused)):hover {
    background-color: var(--color-backgroundDefault);

    :global(.MuiOutlinedInput-notchedOutline) {
      border: 2px solid var(--color-commonBlack);
    }

    &:global(.MuiInputBase-empty):global(.MuiOutlinedInput-notchedOutline) {
      border: 2px solid var(--color-SolidNeutralGray3);
    }
  }
  :global(.MuiInputBase-formControl):global(.Mui-error):hover, :global(.MuiInputBase-formControl):global(.Mui-error):global(.Mui-focused) {
    color: var(--color-errorMain);

    :global(.MuiInputAdornment-root) :global(.MuiTypography-root) {
      color: var(--color-errorMain);
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border: 2px solid var(--color-errorMain);
    }
  }
  :global(.MuiInputBase-formControl):global(.MuiInputBase-empty) :global(.MuiInputAdornment-root) :global(.MuiTypography-root) {
    color: var(--color-SolidNeutralGray3);
  }
  :global(.MuiInputBase-formControl):not(:global(.Mui-focused)):hover:global(.MuiInputBase-empty) :global(.MuiOutlinedInput-notchedOutline) {
    border-color: var(--color-SolidNeutralGray3);
  }
  :global(.MuiInputBase-formControl):global(.Mui-focused) {
    background-color: var(--color-commonWhite);
    :global(.MuiOutlinedInput-notchedOutline) {
      border: 2px solid var(--color-commonBlack);
    }
    :global(.MuiInputAdornment-root) :global(.MuiTypography-root) {
      color: var(--color-commonBlack);
    }
  }
  :global(.MuiInputBase-formControl):global(.Mui-error) {
    color: var(--color-errorLight);

    :global(.MuiOutlinedInput-notchedOutline) {
      border: 1px solid var(--color-errorLight);
    }
    :global(.MuiInputAdornment-root) :global(.MuiTypography-root) {
      color: var(--color-errorLight)
    }
    :not(:global(.Mui-focused)):hover {
      color: var(--color-errorMain);
      :global(.MuiOutlinedInput-notchedOutline) {
        border: 2px solid var(--color-errorLight);
      }
      :global(.MuiInputAdornment-root) :global(.MuiTypography-root) {
        color: var(--color-errorMain);
      }
    }
    :global(.Mui-focused) {
      color: var(--color-errorMain);
      :global(.MuiOutlinedInput-notchedOutline) {
        border: 2px solid var(--color-errorMain);
      }
      :global(.MuiInputAdornment-root) :global(.MuiTypography-root) {
        color: var(--color-commonBlack);
      }
    }
  }
  :global(.MuiInputLabel-asterisk) {
    display: 'none',
  }
  :global(.MuiInputLabel-formControl) {
    color: var(--color-SolidNeutralGray3);
    font-size: inherit;
    line-height: inherit;
    padding-left: 2px;
    top: 50%;
    transform: translate(14px, -50%);
  }
  :global(.MuiInputLabel-formControl):global(.Mui-focused) {
    display: 'none',
  }
}

.label {
  cursor: default;
  display: block;
  margin-bottom: 0;
  padding-bottom: 4px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.errorMessage {
  color: var(--color-errorMain);
  margin: 4px 2px 4px 0;
  min-height: 20px;
}

@media (--mq-is-mobile) {
  .container {
    gap: 6px;
  }
}
