import { useEffect, useRef } from 'react';

import useSession from 'hooks/useSession';

export default function useOnNewLogin(callback: () => void) {
  const session = useSession();
  const wasFirst = useRef('-1');

  useEffect(() => {
    if (wasFirst.current === '-1') {
      wasFirst.current = session.account?.id;
    } else if (wasFirst.current !== session.account?.id) {
      wasFirst.current = session.account?.id;
      callback();
    }
  }, [session.account?.id, callback]);
}
