import { ComponentProps } from 'react';
import { SvgIcon } from '@mui/material';

export default function MPLogo(props: ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon
      width="127"
      height="30"
      viewBox="0 0 124.09 17.69"
      fill="none"
      style={{ fontSize: 'initial', height: 'initial', width: 'initial' }}
      {...props}
    >
      <g>
        <path
          d="m15.46,1.02v15.84h-2.52V5.28l-3.46,11.59h-2.63L3.38,5.28v11.59H.86V1.02h3.44l3.87,12.89L12,1.02h3.46Z"
          fill="currentColor"
        />
        <path
          d="m20.08,9.39l-2.41-.45c.25-2.52,2.32-3.69,4.7-3.69,3.22,0,4.88,1.71,4.88,4.14v4.5c0,.68.14,1.06.61,1.06.18,0,.47-.05.58-.09v1.98c-.7.18-1.15.18-1.44.18-1.17,0-1.8-.7-1.98-1.62-.45.56-1.64,1.64-3.55,1.69-2.63,0-4.21-1.46-4.21-3.62,0-1.78,1.42-3.28,4.86-3.28h2.7v-.79c0-.88-.36-2.09-2.45-2.09-1.39,0-2.25.72-2.29,2.09Zm4.75,3.06v-.45h-2.68c-2.02,0-2.41.88-2.41,1.46,0,.77.52,1.57,2,1.57,1.98,0,3.08-1.35,3.08-2.59Z"
          fill="currentColor"
        />
        <path
          d="m37.56,5.48h3.28l-4.93,4.72,5.38,6.66h-2.92l-4.09-5.06-1.44,1.37v3.69h-2.45V1.02h2.45v9.02l4.72-4.57Z"
          fill="currentColor"
        />
        <path
          d="m49.87,12.9l2.25.65c-.52,1.64-2.14,3.53-5.24,3.53s-5.4-2.41-5.4-5.92,2.07-5.92,5.22-5.92,5.15,2.11,5.15,5.54c0,.36-.02.72-.07,1.1h-7.78c.02,1.67,1.24,3.17,2.88,3.17,1.19,0,2.43-.59,2.99-2.16Zm-.63-2.83c-.07-1.69-.88-2.77-2.54-2.77s-2.52,1.35-2.59,2.77h5.13Z"
          fill="currentColor"
        />
        <path
          d="m59.99,5.32v2.09h-1.28c-1.71,0-2.43,1.1-2.43,3.35v6.1h-2.43V5.48h2.2l.22,1.24c.43-.65,1.21-1.46,2.61-1.46.31,0,.77.02,1.1.07Z"
          fill="currentColor"
        />
        <path
          d="m60.91,13.51l2.38-.45c.16.9.7,2,2.52,2,.41,0,2.39-.23,2.39-1.62,0-1.08-1.1-1.28-2.41-1.44-2-.25-4.54-.38-4.54-3.35,0-1.93,1.93-3.4,4.36-3.4s4.48,1.01,4.81,3.55l-2.38.45c-.14-1.42-1.03-1.96-2.39-1.96-1.19,0-1.98.65-1.98,1.35,0,.92.85,1.17,2.18,1.31,2.59.25,4.77.83,4.77,3.49,0,2.39-2.11,3.65-4.81,3.65-2.92,0-4.59-1.53-4.9-3.58Z"
          fill="currentColor"
        />
        <path
          d="m72.34,16.86V1.02h6.32c2.7,0,4.41,2.02,4.41,4.7s-1.71,4.7-4.41,4.7h-4.43v6.43h-1.89Zm1.89-14.22v6.14h4.36c1.53,0,2.59-1.51,2.59-3.06s-1.06-3.08-2.59-3.08h-4.36Z"
          fill="currentColor"
        />
        <path d="m87.67,1.02v15.84h-1.82V1.02h1.82Z" fill="currentColor" />
        <path
          d="m89.98,9.01c.11-2.52,2.11-3.76,4.5-3.76,3.28,0,4.68,1.6,4.68,4.03v4.77c0,.85.14,1.35.72,1.35.18,0,.43-.04.61-.11v1.57c-.52.11-.88.14-1.17.14-1.19,0-1.8-.76-1.89-1.8-.38.52-1.51,1.82-3.71,1.89-2.66,0-4.18-1.42-4.18-3.51s1.75-3.22,4.68-3.22h3.13v-1.03c0-1.08-.34-2.48-2.86-2.48-1.62,0-2.66,1.06-2.7,2.5l-1.8-.34Zm7.36,2.81h-3.13c-2.36,0-2.79,1.08-2.79,1.75,0,.88.63,1.91,2.36,1.91,2.41,0,3.55-1.62,3.55-3.13v-.54Z"
          fill="currentColor"
        />
        <path
          d="m111.36,13.31c-.29,1.71-1.69,3.78-4.75,3.78-3.22,0-5.22-2.39-5.22-5.92s2-5.92,5.22-5.92c3.04,0,4.39,2.07,4.72,3.8-.61.11-1.22.25-1.82.34-.38-1.62-1.42-2.54-2.9-2.54-2.09,0-3.33,1.78-3.33,4.32s1.24,4.34,3.33,4.34c1.53,0,2.54-.95,2.95-2.54.58.09,1.19.23,1.8.34Z"
          fill="currentColor"
        />
        <path
          d="m123.26,13.44c-.36,1.6-2,3.65-5.11,3.65s-5.24-2.41-5.24-5.92,2.02-5.92,5.11-5.92,4.99,2.07,4.99,5.58c0,.29,0,.61-.04.95h-8.08c.02,1.84,1.33,3.73,3.26,3.73,1.49,0,2.88-.81,3.38-2.5l1.73.43Zm-2.25-3.15c-.04-2.11-.94-3.44-2.99-3.44s-3.04,1.71-3.06,3.44h6.05Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
