import { lazy } from 'react';
import { Route } from 'react-router-dom';

import SlashRedirect from 'pages/SlashRedirect';

const SendPage = lazy(() => import('./SendPage'));

const SendRoute = (
  <Route path="send2">
    <Route index element={<SlashRedirect />} />
    <Route path=":nftSlug" element={<SendPage />} />
  </Route>
);

export default SendRoute;
