.headerContainer {
  margin: 70px 0 0 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.headerSubTextContainer {
  margin: 0 0 70px 0;
  width: 769px;
  word-wrap: break-word;
  text-align: center;
  line-height: 138%;
}

.headerActionBtns {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 25px;
  justify-content: center;
}

@media (--mq-is-mobile) {
  .headerSubTextContainer {
    margin: 39px 0;
    width: 90vw;
  }
  .headerContainer {
    margin: 20px 24px;
  }
}
