import { useIsMobile } from '@mp-frontend/core-components';

import InfoTooltip from 'components/InfoTooltip';
import { APP_NAME } from 'constants/Utils';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import useCurrentBalance from 'hooks/wallet/useCachedCurrentBalance';
import CSSGlobal from 'types/enums/css/Global';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { areSameAddress } from 'utils/areSameAddress';
import generatePriceString from 'utils/currency/generatePricing';

import { Wallets } from 'Session';

interface SubcomponentProps {
  wallet: Wallets[0] | undefined;
}

export function CreatedArtworksSubcomponent({ wallet }: SubcomponentProps) {
  return (
    <div className={CSSGlobal.Flex.Col}>
      <span className={CSSGlobal.Flex.InlineRowCenterAlign}>
        Minted + Imported Artworks&nbsp;
        <InfoTooltip
          title={`Number of Artworks you have created or imported onto ${APP_NAME}`}
          placement="right"
        />{' '}
      </span>
      <span>{wallet.createdTokenNumber}</span>
    </div>
  );
}

export function CollectedArtworksSubcomponent({ wallet }: SubcomponentProps) {
  const title = wallet?.isSafebox
    ? `Artworks in SafeBox can't be transferred or listed for sale until 72 hours after purchase.`
    : `Number of Artworks you have collected on ${APP_NAME} that you did not create`;

  return (
    <div className={CSSGlobal.Flex.Col}>
      <span className={CSSGlobal.Flex.InlineRowCenterAlign}>
        Collected Artworks&nbsp;
        <InfoTooltip title={title} placement="right" />{' '}
      </span>
      <span>{wallet.collectedTokenNumber}</span>
    </div>
  );
}

export function BalanceSubcomponent({ wallet }: SubcomponentProps) {
  const account = useCachedAccount();
  const balance = useCurrentBalance();
  const isMobile = useIsMobile();
  return areSameAddress(account?.address, wallet.address) ? (
    <div className={CSSGlobal.Flex.Col}>
      <span className={CSSGlobal.Flex.InlineRowCenterAlign}>Balance</span>
      <span>
        {balance.isReady
          ? generatePriceString(
              parseFloat(balance.eth),
              CurrencyDisplayMode.ETH,
              {
                isMobile,
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
              }
            )
          : '--'}
      </span>
    </div>
  ) : null;
}
