import { PreloadedQuery, useFragment, usePreloadedQuery } from 'react-relay';

import ProfilesFragment, {
  ProfilesFragment$key,
} from 'graphql/__generated__/ProfilesFragment.graphql';
import GetProfile, {
  ProfilesQuery,
} from 'graphql/__generated__/ProfilesQuery.graphql';

export type useProfileDataQueryRef = PreloadedQuery<ProfilesQuery>;

export default function useProfileData(
  profileQueryRef: useProfileDataQueryRef
) {
  return useFragment<ProfilesFragment$key>(
    ProfilesFragment,
    usePreloadedQuery<ProfilesQuery>(GetProfile, profileQueryRef).profile
  );
}
