.collectionHeader {
  margin-top: 24px;
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.firstRow {
  gap: 16px;
}

.firstRow.isCollection {
  flex-flow: nowrap;
  display: flex;
  width: 100%;
  margin-bottom: 42px;
  justify-content: space-between;
}

.titleSocialRow {
  flex: 1 1 0;
  justify-content: space-between;
  align-items: center;
}

.actionRow {
  flex: 1 1 0;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  margin-top: -2px;
  height: 44px;
}

.actionRowLeft {
  display: flex;
  gap: 12px;
  align-items: center;
}

.actionRowRight {
  display: flex;
  gap: 8px;
  width: 320px;
  position: relative;
  top: 12px;
}

.profileInfoSection {
  flex: 1 1 0;
  width: calc(100% - 100px);
}

.socialActionSection {
  margin-top: -16px;
  justify-content: end;
}

.socialLinks {
  flex-direction: row;
  display: flex;
  gap: 22px;
  align-items: flex-end;
}

.socialLinkAnchor {
  height: 24px;
}

.titleDescription {
  width: 100%;
  max-height: 170px;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  justify-content: flex-start;
}

.title {
  white-space: pre;
  color: var(--color-textPrimary);
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  width: 100%;
  margin: 16px 0 32px;
  padding: 0;
  line-height: 21px;
  color: var(--color-textPrimary);
}

.trimmedDescription {
  max-width: 700px;
  word-wrap: break-word;
}

.joinDate, .curatedBy {
  margin-top: 14px;
  color: var(--color-SolidNeutralGray3);
}


.curatedBy {
  margin-bottom: -14px;
}

a.curatedLink {
  color: var(--color-primaryP45);
}

.profilePhoto {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  object-fit: cover;
  box-sizing: border-box;
}


.actionButtonSection {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex: 0;
  min-height: 40px;
}

.profileFollowers {
  justify-self: flex-start;
  flex-grow: 1;
  align-self: center;
}

.moreSocialMediaMenuIcon {
  cursor: pointer;
}

.alignSelfStart {
  align-self: start;
}

@media (--mq-is-mobile) {
  .actionButtonSection {
    min-height: 30px;
  }

  .actionRow {
    flex: 0 1 0;
    justify-content: normal;
    margin-top: 5px;
    height: auto;
  }

  .collectionHeader {
    margin-top: 22px;
    margin-bottom: 2px;
  }

  .description {
    margin: 16px 0 24px;
  }

  .description.isCollection {
    margin-top: 0;
  }

  .firstRow {
    gap: 13px;
  }

  .mobileBadgeWrapper {
    flex: 1 0 auto;
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }

  .profileInfoSection {
    width: calc(100% - 81px);
  }

  .joinDate, .curatedBy {
    margin-top: 0px;
  }

  .curatedBy {
    margin-bottom: 0px;
  }

  .profilePhoto {
    width: 68px;
    height: 68px;
  }

  .titleSocialRow {
    flex: 0 1 0;
  }
}
