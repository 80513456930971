/* eslint-disable max-classes-per-file */
import { useCallback, useEffect } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useRecoilValue } from 'recoil';
import { Abi, Hash } from 'viem';
import { Config, useConfig, useReadContract } from 'wagmi';
import { readContract } from 'wagmi/actions';

import {
  ApprovalTypeEnum,
  CreatorContractType,
} from 'types/__generated__/graphql';

import useApprovedCreatorRegistry from 'hooks/contracts/ApprovedCreatorRegistry';
import useManifoldContract from 'hooks/contracts/useManifoldContract';
import useTLContract from 'hooks/contracts/useTLContract';
import useVaultCoreContract from 'hooks/contracts/VaultCore';
import {
  RecoilDropSaleContractQuery,
  useDigitalMediaSaleCoreV3Contract,
} from 'hooks/useSaleContract';
import useSession from 'hooks/useSession';
import { areSameAddress } from 'utils/areSameAddress';

function getPending({
  contract,
  config,
  approvalType,
  abi,
  walletAddress,
  vaultCoreAddress,
  dropSaleCoreAddress,
}: {
  abi: Abi;
  approvalType: ApprovalTypeEnum;
  config: Config;
  contract: CreatorContractType;
  dropSaleCoreAddress: Hash;
  vaultCoreAddress: Hash;
  walletAddress: Hash;
}) {
  const address = contract.address as Hash;

  switch (approvalType) {
    case ApprovalTypeEnum.VaultCoreApproval:
      return readContract(config, {
        abi,
        address,
        args: [walletAddress, vaultCoreAddress], // sol function params: [user_address, operator_address]
        functionName: 'isApprovedForAll',
      });
    case ApprovalTypeEnum.AddAdminApproval:
      if (contract.isManifold) {
        return readContract(config, {
          abi,
          address,
          args: [contract.manifoldAdminAddress as Hash],
          functionName: 'isAdmin',
        });
      }
      return readContract(config, {
        abi,
        address,
        args: [],
        functionName: 'ADMIN_ROLE',
      })
        .then((res) =>
          readContract(config, {
            abi,
            address,
            args: [res],
            functionName: 'getRoleMembers',
          })
        )
        .then((res) =>
          ((res as Array<Hash>) ?? []).some((memberAddress) =>
            areSameAddress(memberAddress, contract.manifoldAdminAddress as Hash)
          )
        );
    case ApprovalTypeEnum.DropSaleCoreApproval:
      return readContract(config, {
        abi,
        address,
        args: [dropSaleCoreAddress, walletAddress], // sol function params: [operator_address, user_address]
        functionName: 'tokenOperatorToCustodialAccounts',
      });
    default:
      return undefined;
  }
}

const needsApprovalAtom = atom<boolean>(false);
const approvalsUpdateAtom = atom(0);
const incrementApprovalsUpdateAtom = atom(
  (get) => get(approvalsUpdateAtom),
  (get, set) => {
    set(approvalsUpdateAtom, get(approvalsUpdateAtom) + 1);
  }
);

export function useGetApprovals() {
  const walletAddress = useSession().account.wallets.find(
    (wallet) => wallet.isCreatorWallet && wallet.isSelectedMintingWallet
  )?.address;

  const [contracts] = useSession().contracts.useReadWrite();
  const [stale, markStale] = useAtom(incrementApprovalsUpdateAtom);
  const dropSaleCoreAddress = useRecoilValue(
    RecoilDropSaleContractQuery
  )?.address;
  const vaultCoreAddress: Hash = useVaultCoreContract()?.address as Hash;
  const [needsApproval, setNeedsApproval] = useAtom(needsApprovalAtom);
  const config = useConfig();

  const contractsChangedKey = contracts.map((c) => c.address).join('-');
  const getApprovals = useCallback(
    () =>
      contracts.reduce((calls, contract) => {
        const { abi } = JSON.parse(contract.abidata);
        contract.requireApprovalsOfContract.forEach((approvalType) => {
          const pending = getPending({
            abi,
            approvalType,
            config,
            contract,
            dropSaleCoreAddress,
            vaultCoreAddress,
            walletAddress,
          });
          if (pending) calls.push(pending);
        });
        return calls;
      }, []),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [
      contractsChangedKey,
      stale,
      config,
      dropSaleCoreAddress,
      vaultCoreAddress,
      walletAddress,
    ]
  );
  useEffect(() => {
    Promise.all(getApprovals()).then((approvals) => {
      setNeedsApproval(approvals.some((needsApprovalRes) => !needsApprovalRes));
    });
  }, [getApprovals, setNeedsApproval]);
  return [needsApproval, markStale];
}

export default (function createPendingApprovalsManager() {
  const map: Record<string, any> = {};
  const cache = {};

  const generateCacheKey = function generatePendingApprovalsCacheKey({
    address,
    args,
    functionName,
  }: {
    address: Hash;
    args: Array<string>;
    functionName: string;
  }) {
    return `${address.toLowerCase()}-${args
      .map((add) => add.toString().toLowerCase())
      .join('-')}-${functionName}`;
  };

  return {
    generateCacheKey,
    useReadDigitalMediaSaleCoreApproval(
      accountAddress
    ): [boolean, () => void, boolean] {
      const digitalMediaSaleCoreContract = useDigitalMediaSaleCoreV3Contract();
      const approvedCreatorRegistryContract = useApprovedCreatorRegistry();
      const key = `${approvedCreatorRegistryContract.address.toLowerCase()}-${accountAddress.toLowerCase()}-${digitalMediaSaleCoreContract.address.toLowerCase()}-tokenOperatorToCustodialAccounts`;
      if (!map[key]) {
        map[key] = atom<boolean>(undefined);
      }
      const contractInfo = {
        abi: approvedCreatorRegistryContract.abi,
        account: accountAddress,
        address: approvedCreatorRegistryContract.address as Hash,
        args: [digitalMediaSaleCoreContract.address, accountAddress],
        functionName: 'tokenOperatorToCustodialAccounts',
      };

      const [isApproved, refetch, isFetching] = ((res) => [
        res.data === undefined ? undefined : !!res.data,
        res.refetch,
        res.isFetching,
        res.error,
      ])(
        useReadContract({
          ...contractInfo,
          query: {
            placeholderData: cache[generateCacheKey(contractInfo)],
          },
        })
      );

      const markStale = useSetAtom(incrementApprovalsUpdateAtom);
      const setApproval = useSetAtom(map[key]);
      useEffect(() => {
        setApproval((prev) => {
          if (prev !== isApproved) {
            markStale();
          }
          return isApproved;
        });
      });
      return [isApproved, refetch, isFetching];
    },
    useReadDropSaleCoreApproval({
      contract,
      dropSaleCoreAddress,
      accountAddress,
    }): [boolean, () => void, boolean] {
      const key = `${contract.address.toLowerCase()}-${accountAddress.toLowerCase()}-${dropSaleCoreAddress.toLowerCase()}-tokenOperatorToCustodialAccounts`;
      if (!map[key]) {
        map[key] = atom<boolean>(undefined);
      }
      const contractInfo = {
        abi: JSON.parse(contract.abidata).abi as any,
        account: accountAddress,
        address: contract.address as Hash,
        args: [dropSaleCoreAddress, accountAddress],
        functionName: 'tokenOperatorToCustodialAccounts',
      };

      const [isApproved, refetch, isFetching] = ((res) => [
        res.data === undefined ? undefined : !!res.data,
        res.refetch,
        res.isFetching,
        res.error,
      ])(
        useReadContract({
          ...contractInfo,
          query: {
            placeholderData: cache[generateCacheKey(contractInfo)],
          },
        })
      );
      const markStale = useSetAtom(incrementApprovalsUpdateAtom);
      const setApproval = useSetAtom(map[key]);
      useEffect(() => {
        setApproval((prev) => {
          if (prev !== isApproved) {
            markStale();
          }
          return isApproved;
        });
      });
      return [isApproved, refetch, isFetching];
    },
    useReadManifoldAdminApproval(
      contract: any,
      accountAddress: Hash
    ): [boolean, () => void, boolean] {
      const { useIsAdmin } = useManifoldContract({
        abi: JSON.parse(contract.abidata).abi,
        account: accountAddress,
        contractAddress: contract.address as Hash,
      });
      const key = `${contract.address.toLowerCase()}-${accountAddress.toLowerCase()}-${contract.manifoldAdminAddress.toLowerCase()}-isAdmin`;
      if (!map[key]) {
        map[key] = atom<boolean>(undefined);
      }

      const [isAdmin, refetch, isFetching] = ((res) => [
        res.data === undefined ? undefined : !!res.data,
        res.refetch,
        res.isFetching,
      ])(
        useIsAdmin({
          adminAddress: contract.manifoldAdminAddress as Hash,
          query: {
            placeholderData:
              cache[
                generateCacheKey({
                  address: contract.address,
                  args: [contract.manifoldAdminAddress],
                  functionName: 'isAdmin',
                })
              ],
          },
        })
      );
      const markStale = useSetAtom(incrementApprovalsUpdateAtom);
      const setApproval = useSetAtom(map[key]);
      useEffect(() => {
        setApproval((prev) => {
          if (prev !== isAdmin) {
            markStale();
          }
          return isAdmin;
        });
      });
      return [isAdmin, refetch, isFetching];
    },
    useReadTLAdminApproval(
      contract: any,
      accountAddress: Hash
    ): [boolean, () => void, boolean] {
      const { useIsAdmin } = useTLContract({
        abi: JSON.parse(contract.abidata).abi,
        account: accountAddress,
        contractAddress: contract.address as Hash,
      });
      const key = `${contract.address.toLowerCase()}-${accountAddress.toLowerCase()}-${contract.manifoldAdminAddress.toLowerCase()}-isAdmin`;
      if (!map[key]) {
        map[key] = atom<boolean>(undefined);
      }

      const [isAdmin, refetch, isFetching] = ((res) => [
        res.data === undefined ? undefined : !!res.data,
        res.refetch,
        res.isFetching,
      ])(
        useIsAdmin({
          adminAddress: contract.manifoldAdminAddress as Hash,
          query: {
            placeholderData:
              cache[
                generateCacheKey({
                  address: contract.address,
                  args: [contract.manifoldAdminAddress],
                  functionName: 'isAdmin',
                })
              ],
          },
        })
      );
      const markStale = useSetAtom(incrementApprovalsUpdateAtom);
      const setApproval = useSetAtom(map[key]);
      useEffect(() => {
        setApproval((prev) => {
          if (prev !== isAdmin) {
            markStale();
          }
          return isAdmin;
        });
      });
      return [isAdmin, refetch, isFetching];
    },
    useReadVaultCoreApproval({
      contract,
      vaultCoreContractAddress,
      accountAddress,
    }): [boolean, () => void, boolean] {
      const key = `${contract.address.toLowerCase()}-${accountAddress.toLowerCase()}-${vaultCoreContractAddress.toLowerCase()}-isApprovedForAll`;
      if (!map[key]) {
        map[key] = atom<boolean>(undefined);
      }
      const contractInfo = {
        abi: JSON.parse(contract.abidata).abi as any,
        account: accountAddress,
        address: contract.address as Hash,
        args: [accountAddress, vaultCoreContractAddress],
        functionName: 'isApprovedForAll',
      };

      const [isApproved, refetch, isFetching] = ((res) => [
        res.data === undefined ? undefined : !!res.data,
        res.refetch,
        res.isFetching,
        res.error,
      ])(
        useReadContract({
          ...contractInfo,
          query: {
            placeholderData: cache[generateCacheKey(contractInfo)],
          },
        })
      );
      const markStale = useSetAtom(incrementApprovalsUpdateAtom);
      const setApproval = useSetAtom(map[key]);
      useEffect(() => {
        setApproval((prev) => {
          if (prev !== isApproved) {
            markStale();
          }
          return isApproved;
        });
      });

      return [isApproved, refetch, isFetching];
    },
  };
})();
