import {
  MPActionButton,
  MPActionButtonProps,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import useSession from 'hooks/useSession';
import useSimpleDialogController from 'hooks/useSimpleDialogController';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import Contract from './Contract';
import { useGetApprovals } from './PendingApprovalsManager';

import * as styles from 'css/pages/settings/wallet/wallets/ManageWalletDialog/Contracts/index.module.css';

import { type Wallets } from 'Session';

function ContractsDialog({ isOpen, cancel, address, hasPendingApprovals }) {
  const buttons = hasPendingApprovals ? (
    []
  ) : (
    <MPActionButton variant="primary" fullWidth size="large" onClick={cancel}>
      Continue
    </MPActionButton>
  );
  const [contracts] = useSession().contracts.useReadWrite();

  return (
    <StackStateDialog
      title={
        hasPendingApprovals
          ? 'Approve Contracts'
          : 'All Required Contracts Approved'
      }
      open={isOpen}
      onClose={cancel}
      actionButton={buttons}
    >
      {hasPendingApprovals ? (
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
          {contracts.map((contract) => (
            <Contract
              className={styles.contract}
              contract={contract}
              key={contract.address}
              walletAddress={address}
            />
          ))}
        </div>
      ) : (
        <div
          className={joinClasses(
            CSSGlobal.TextAlign.Centered,
            MPFonts.paragraphSmall
          )}
        >
          Your contract approval was successful
        </div>
      )}
    </StackStateDialog>
  );
}

interface ContractsProps {
  simpleDialogController: ReturnType<typeof useSimpleDialogController>;
  wallet: Wallets[0];
  variant?: MPActionButtonProps['variant'];
}

export default function Contracts({
  wallet,
  simpleDialogController,
  variant = 'primary',
}: ContractsProps) {
  const [isContractsDialogOpen, openContractsDialog, closeContractsDialog] =
    simpleDialogController;
  const [hasPendingApprovals] = useGetApprovals();
  return isContractsDialogOpen || hasPendingApprovals ? (
    <>
      <MPActionButton
        variant={variant}
        fullWidth
        size="large"
        onClick={openContractsDialog}
      >
        Approve&nbsp;Required&nbsp;Contracts
      </MPActionButton>
      {!!isContractsDialogOpen && (
        <ContractsDialog
          isOpen={isContractsDialogOpen}
          cancel={closeContractsDialog}
          address={wallet.address}
          hasPendingApprovals={hasPendingApprovals}
        />
      )}
    </>
  ) : null;
}
