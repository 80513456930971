.container {
  align-items: center;
  display: flex;
  justify-content: center;

  &.absolute {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.spinner {
  height: 64px;
  width: 64px;
  margin: 2rem 0px;
}
