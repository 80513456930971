import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';

import { MPFonts, MPStyledTextField } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { useHandleSetETH, useHandleSetUSD } from 'hooks/pricing/useMax';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import toNumericShorthand from 'utils/numericShorthand';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';
import DelistLink from '../UpdateListing/DelistLink';

import * as styles from 'css/pages/product/ProductSetBuyNow.module.css';

interface SetBuyNowContentProps {
  button: any;
  buyNowState: [string, Dispatch<SetStateAction<string>>];
  nft: NFTType;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
  error?: Error;
}

export default function Content({
  error,
  nft,
  buyNowState,
  setOwnershipState,
  button,
}: SetBuyNowContentProps) {
  const [buyNowPrice, setBuyNowPrice] = buyNowState;
  const canDelist = nft.listing.liveSale || nft.listing.hasReservePrice;
  const { isCustodialOwner } = nft;
  const currencyDisplay = isCustodialOwner
    ? CurrencyDisplayMode.USD
    : CurrencyDisplayMode.ETH;

  const [, handleSetBuyNowUSD] = useHandleSetUSD(setBuyNowPrice);
  const [, handleSetBuyNowETH] = useHandleSetETH(setBuyNowPrice);
  const handleSetBuyNowPriceListingAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      currencyDisplay === CurrencyDisplayMode.USD
        ? handleSetBuyNowUSD(event)
        : handleSetBuyNowETH(event),
    [currencyDisplay, handleSetBuyNowUSD, handleSetBuyNowETH]
  );

  const buyNowDisplayDisplayUSD =
    currencyDisplay === CurrencyDisplayMode.USD && buyNowPrice
      ? buyNowPrice
      : '';
  const buyNowDisplayDisplayETH =
    currencyDisplay === CurrencyDisplayMode.ETH && buyNowPrice
      ? buyNowPrice
      : '';

  const buyNowFloat = parseFloat(buyNowPrice) || 0;
  const dollarAmount =
    currencyDisplay === CurrencyDisplayMode.ETH
      ? toNumericShorthand(buyNowFloat * nft.currentEthRate)
      : buyNowPrice ?? 0;
  const ethAmount =
    currencyDisplay === CurrencyDisplayMode.ETH
      ? buyNowPrice ?? 0
      : toNumericShorthand(buyNowFloat / nft.currentEthRate);

  return (
    <div>
      <div className={styles.productSellContainer}>
        <div
          className={joinClasses(
            MPFonts.paragraphNormal,
            styles.productSellMessageContainer
          )}
        >
          Set a fixed price for your listing
          {!!error && <p>{error.message}</p>}
        </div>
        <div>
          {currencyDisplay === CurrencyDisplayMode.USD ? (
            <MPStyledTextField
              startAdornment="$"
              value={buyNowDisplayDisplayUSD ?? ''}
              onChange={handleSetBuyNowPriceListingAmount}
              inputMode="decimal"
              placeholder="Amount In USD"
            />
          ) : (
            <MPStyledTextField
              value={buyNowDisplayDisplayETH ?? ''}
              onChange={handleSetBuyNowPriceListingAmount}
              inputMode="decimal"
              placeholder="Amount In ETH"
            />
          )}
        </div>
        <div className={styles.buyReservePricingContainer}>
          <div
            className={joinClasses(
              styles.buyNowPriceItem,
              MPFonts.textNormalMedium
            )}
          >
            Total
          </div>
          <div className={styles.buyNowPriceItem}>
            {currencyDisplay === CurrencyDisplayMode.USD ? (
              <span className={MPFonts.price}>${dollarAmount}&nbsp;</span>
            ) : (
              <span className={MPFonts.price}>ETH {ethAmount}&nbsp;</span>
            )}
            {currencyDisplay === CurrencyDisplayMode.USD ? (
              <span
                className={joinClasses(
                  MPFonts.subPrice,
                  styles.buyReserveSecondaryPrice
                )}
              >
                {ethAmount} ETH
              </span>
            ) : (
              <span
                className={joinClasses(
                  MPFonts.subPrice,
                  styles.buyReserveSecondaryPrice
                )}
              >
                ${dollarAmount}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.productSellFooter}>
        <div className={styles.productSellListingBtnContainer}>
          {button}
          {!!canDelist && <DelistLink setOwnershipState={setOwnershipState} />}
        </div>
      </div>
    </div>
  );
}
