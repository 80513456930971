import { ArtistsFiltersState } from './artists/Filters';
import { ArtworksFiltersState } from './artworks/Filters';
import { EditorialsFiltersState } from './editorials/Filters';
import { ExhibitionsFiltersState } from './exhibitions/Filters';

export enum ExploreType {
  Artists = 'Artists',
  Artworks = 'Artworks',
  Editorial = 'Editorial',
  Exhibitions = 'Exhibitions',
}

export type FiltersStateType =
  | ArtistsFiltersState
  | ArtworksFiltersState
  | EditorialsFiltersState
  | ExhibitionsFiltersState;
