import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import DefaultSuspense from 'components/DefaultSuspense';
import PillTabs from 'components/tabs/PillTabs';

import { PAGESLUG } from './pageSlug';

const PILLS_TABS_CONFIG = {
  [PAGESLUG.collection]: 'Collection',
  [PAGESLUG.wallets]: 'Wallet',
  [PAGESLUG.account]: 'Account',
};

export default function SettingsPage() {
  const pageSlug = useLocation()
    .pathname.split('/')
    .filter((a) => a)
    .at(-1);
  const navigate = useNavigate();
  const navigateToPage = useCallback(
    (slug) => navigate(`/settings/${slug}`),
    [navigate]
  );
  const showNewCollectionDesign = useGateValue('gate_settings_v3_collection');

  return (
    <>
      {!!showNewCollectionDesign && (
        <PillTabs
          config={PILLS_TABS_CONFIG}
          onChange={navigateToPage}
          selectedValue={PAGESLUG[pageSlug]}
        />
      )}
      <DefaultSuspense>
        <Outlet />
      </DefaultSuspense>
    </>
  );
}
