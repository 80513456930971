/**
 * @generated SignedSource<<2ad98b084c884a3dab8d0c57e33c61a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NFTCommentMutation$variables = {
  nftMetadataId: number;
  nftId: number;
  body: string;
  parentId?: number | null;
  replyToUser?: number | null;
};
export type NFTCommentMutation$data = {
  readonly commentNft: {
    readonly success: boolean | null;
    readonly comment: {
      readonly pk: string;
      readonly id: string;
      readonly body: string;
      readonly createdAt: any;
      readonly parentId: number | null;
      readonly threadCount: number;
      readonly user: {
        readonly id: string;
        readonly pk: string;
        readonly fullName: string | null;
        readonly profileImageUrl: string;
        readonly store: {
          readonly storeSlug: string | null;
        } | null;
      } | null;
      readonly replyToUser: {
        readonly id: string;
        readonly pk: string;
        readonly fullName: string | null;
        readonly profileImageUrl: string;
        readonly store: {
          readonly storeSlug: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type NFTCommentMutation = {
  variables: NFTCommentMutation$variables;
  response: NFTCommentMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nftId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nftMetadataId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "replyToUser"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "body",
        "variableName": "body"
      },
      {
        "kind": "Variable",
        "name": "nftId",
        "variableName": "nftId"
      },
      {
        "kind": "Variable",
        "name": "nftMetadataId",
        "variableName": "nftMetadataId"
      },
      {
        "kind": "Variable",
        "name": "parentId",
        "variableName": "parentId"
      },
      {
        "kind": "Variable",
        "name": "replyToUser",
        "variableName": "replyToUser"
      }
    ],
    "kind": "ObjectValue",
    "name": "requestData"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "threadCount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeSlug",
  "storageKey": null
},
v16 = [
  (v8/*: any*/),
  (v7/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "StoreTypeV3",
    "kind": "LinkedField",
    "name": "store",
    "plural": false,
    "selections": [
      (v15/*: any*/)
    ],
    "storageKey": null
  }
],
v17 = [
  (v8/*: any*/),
  (v7/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "StoreTypeV3",
    "kind": "LinkedField",
    "name": "store",
    "plural": false,
    "selections": [
      (v15/*: any*/),
      (v8/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CommentNFTMutation",
        "kind": "LinkedField",
        "name": "commentNft",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTCommentType",
            "kind": "LinkedField",
            "name": "comment",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAbstractType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAbstractType",
                "kind": "LinkedField",
                "name": "replyToUser",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CommentNFTMutation",
        "kind": "LinkedField",
        "name": "commentNft",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NFTCommentType",
            "kind": "LinkedField",
            "name": "comment",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAbstractType",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAbstractType",
                "kind": "LinkedField",
                "name": "replyToUser",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77251be55e9a7e367b9d1c9c5eedd3b3",
    "id": null,
    "metadata": {},
    "name": "NFTCommentMutation",
    "operationKind": "mutation",
    "text": "mutation NFTCommentMutation(\n  $nftMetadataId: Int!\n  $nftId: Int!\n  $body: String!\n  $parentId: Int\n  $replyToUser: Int\n) {\n  commentNft(requestData: {nftMetadataId: $nftMetadataId, nftId: $nftId, body: $body, parentId: $parentId, replyToUser: $replyToUser}) {\n    success\n    comment {\n      pk\n      id\n      body\n      createdAt\n      parentId\n      threadCount\n      user {\n        id\n        pk\n        fullName\n        profileImageUrl\n        store {\n          storeSlug\n          id\n        }\n      }\n      replyToUser {\n        id\n        pk\n        fullName\n        profileImageUrl\n        store {\n          storeSlug\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8c1af7d2193e03e5a126e936e78e6a7";

export default node;
