.statusBar {
  background-color: var(--color-commonBlack);
  height: 48px;
  color: var(--color-commonWhite);
  padding: 0 32px;
  box-sizing: border-box;
}

.statusBar.soldOut {
  background-color: var(--color-goldMain);
  height: 48px;
  color: var(--color-commonWhite);
  padding: 0 32px;
}

.actionButton {
  --actionButtonColor-hover: rgba(255,255,255,0.6);
  --actionButtonColor-active: rgba(255,255,255,0.2);
  --actionButtonColor: var(--color-commonWhite);

  padding-left: 18px;
  padding-right: 12px;
}

.noActionTimer {
  padding-left: 18px;
  padding-right: 12px;
}

.actionButtonTxt {
  white-space: nowrap;
}
