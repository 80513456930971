import { SvgIcon } from '@mui/material';

export default function BidAcceptIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.9524 6.56982L15.3868 8.0042L13.9524 9.43858L12.5181 8.0042L13.9524 6.56982Z"
        fill="currentColor"
      />
      <path
        d="M5.09011 9.65186L6.55864 11.1204L5.09011 12.5889L3.62158 11.1204L5.09011 9.65186Z"
        fill="currentColor"
      />
      <path
        d="M19.3313 7.68823L13.2779 14.493L9.87979 11.0949L2.87012 18.1131L4.75701 20L9.87979 14.8687L13.312 18.3009L21.1414 9.49828L19.3313 7.68823Z"
        fill="currentColor"
      />
      <path
        d="M8.85499 9.97658L9.78562 7.91893L11.8433 6.98829L9.78562 6.05765L8.85499 4L7.92435 6.05765L5.8667 6.98829L7.92435 7.91893L8.85499 9.97658Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
