import { PureComponent, useLayoutEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { MpErrors } from 'types/__generated__/graphql';

import DefaultSuspense from 'components/DefaultSuspense';

interface RedirectProps {
  path: string;
  resetErrorBoundary: () => void;
}

function Redirect({ resetErrorBoundary, path }: RedirectProps) {
  const location = useLocation();
  const LocationRef = useRef(location.pathname);

  useLayoutEffect(() => {
    if (location.pathname !== LocationRef.current) resetErrorBoundary();
  });

  return <Navigate to={path} replace />;
}

class RedirectErrorBoundary extends PureComponent<
  { children: any },
  { error: any }
> {
  static getDerivedStateFromError(error) {
    return { error };
  }

  static willCatch(error) {
    return error?.name === MpErrors.Redirect && error?.additionalData?.path;
  }

  constructor(props) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    if (!this.shouldRedirect()) {
      throw error;
    }
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  shouldRedirect() {
    return RedirectErrorBoundary.willCatch(this.state.error);
  }

  render() {
    if (this.shouldRedirect()) {
      return (
        <DefaultSuspense>
          <Redirect
            resetErrorBoundary={this.resetErrorBoundary}
            path={`/${this.state.error.additionalData.path}`}
          />
        </DefaultSuspense>
      );
    }
    return <DefaultSuspense>{this.props.children}</DefaultSuspense>;
  }
}

export default RedirectErrorBoundary;
