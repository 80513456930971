import { SvgIcon } from '@mui/material';

export default function MoreIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="surface1">
        <path
          stroke="none"
          fillRule="nonzero"
          fill="currentColor"
          d="M 10.105469 0 L 10.105469 10.105469 L 0 10.105469 L 0 13.894531 L 10.105469 13.894531 L 10.105469 24 L 13.894531 24 L 13.894531 13.894531 L 24 13.894531 L 24 10.105469 L 13.894531 10.105469 L 13.894531 0 Z M 10.105469 0 "
        />
      </g>
    </SvgIcon>
  );
}
