import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  startTransition,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import {
  MPAnimations,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  NotificationsIcon,
  RemoveIcon,
  SettingsIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import NotificationsCountQuery, {
  NotificationsCountQuery as NotificationsCountQueryType,
} from 'graphql/__generated__/NotificationsCountQuery.graphql';
import NotificationsQuery, {
  NotificationsQuery as NotificationsQueryType,
} from 'graphql/__generated__/NotificationsQuery.graphql';
import NotificationsSettingsQuery, {
  NotificationsSettingsQuery as NotificationsSettingsQueryType,
} from 'graphql/__generated__/NotificationsSettingsQuery.graphql';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import Panel from 'components/panels/Panel';
import GTM from 'GTM';
import useLoadQuery from 'hooks/useLoadQuery';
import nFormatter from 'utils/string/nFormatter';

import NotificationsList from './NotificationsList';
import NotificationsSettings from './NotificationsSettings';

import * as styles from 'css/components/Navbar/NotificationComponent.module.css';
import * as navBarStyles from 'css/components/Navbar/Unified/index.module.css';

interface NotificationProps {
  countRef: MutableRefObject<number> | null;
  onClick: () => unknown;
  open: boolean;
  queryRef: PreloadedQuery<NotificationsCountQueryType>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function NotificationBell({
  countRef,
  queryRef,
  onClick,
}: Omit<NotificationProps, 'open' | 'setOpen'>) {
  const { notificationsCount } = usePreloadedQuery<NotificationsCountQueryType>(
    NotificationsCountQuery,
    queryRef
  );
  const isMobile = useIsMobile();

  // eslint-disable-next-line no-param-reassign
  countRef.current = notificationsCount;

  return (
    <div
      className={joinClasses(
        styles.notificationsSection,
        !notificationsCount ? MPAnimations.Color.DarkToLight : '',
        notificationsCount
          ? styles.notificationsSectionCount
          : styles.notificationsSectionNoCount,
        styles.isUnified
      )}
      onClick={onClick}
      role="button"
      tabIndex={-1}
      aria-hidden="true"
    >
      <NotificationsIcon fontSize="21" />
      {!!notificationsCount && !isMobile && (
        <span
          className={joinClasses(
            styles.notificationsCount,
            styles.isUnified,
            MPFonts.textSmallMedium
          )}
        >
          {nFormatter(notificationsCount, null, 0, 1, 1)}
        </span>
      )}
    </div>
  );
}

interface NotificationsWrapperProps {
  open: NotificationProps['open'];
  setOpen: NotificationProps['setOpen'];
}

enum SubPanel {
  NOTIFICATIONS = 'NOTIFICATIONS',
  SETTINGS = 'SETTINGS',
}

interface NotificationsProps {
  isSettingsOpen: boolean;
  invalidateNotificationCount?: () => void;
  showNotifications?: boolean;
}

function Notifications({
  isSettingsOpen,
  invalidateNotificationCount,
  showNotifications,
}: NotificationsProps) {
  const [notificationsQueryRef, , , invalidateNotifications] =
    useLoadQuery<NotificationsQueryType>(NotificationsQuery, {
      first: 20,
    });
  const [notificationsSettingsQueryRef, , , invalidateNotificationSettings] =
    useLoadQuery<NotificationsSettingsQueryType>(
      NotificationsSettingsQuery,
      {}
    );

  return (
    <DefaultErrorBoundary>
      {isSettingsOpen
        ? !!notificationsSettingsQueryRef && (
            <NotificationsSettings
              notificationsSettingsQueryRef={notificationsSettingsQueryRef}
              invalidateNotificationSettings={invalidateNotificationSettings}
              invalidateNotifications={invalidateNotifications}
              invalidateNotificationCount={invalidateNotificationCount}
            />
          )
        : !!notificationsQueryRef && (
            <NotificationsList
              className={styles.unifiedNoticationList}
              showNotifications={showNotifications}
              notificationsQueryRef={notificationsQueryRef}
              invalidateNotificationCount={invalidateNotificationCount}
            />
          )}
    </DefaultErrorBoundary>
  );
}

function NotificationsWrapper({ open, setOpen }: NotificationsWrapperProps) {
  const isMobile = useIsMobile();
  const [visibleSubPanel, setVisibleSubPanel] = useState(
    SubPanel.NOTIFICATIONS
  );
  const [notificationsCountQueryRef, , , invalidateNotificationCount] =
    useLoadQuery<NotificationsCountQueryType>(NotificationsCountQuery, {});

  const countRef = useRef(null);

  const toggleNotifications = useCallback(() => {
    setOpen((prev) => {
      GTM.notification.trackDrawerToggle(!prev, countRef.current);
      if (prev) setVisibleSubPanel(SubPanel.NOTIFICATIONS);
      return !prev;
    });
  }, [setOpen]);

  const handleNotificationBellClick = useCallback(() => {
    startTransition(() => {
      toggleNotifications();
      invalidateNotificationCount();
    });
  }, [toggleNotifications, invalidateNotificationCount]);

  const modalProps = useMemo(
    () => ({
      container: window.parent.document.body,
    }),
    []
  );

  const actionIcons = useMemo(
    () => [
      visibleSubPanel === SubPanel.NOTIFICATIONS ? (
        <SettingsIcon
          data-action_wrapper_classname={joinClasses(
            styles.notificationWrapperIcon,
            MPAnimations.Color.DarkToLight
          )}
          key="notis_settings"
          fontSize="small"
          onClick={() => setVisibleSubPanel(SubPanel.SETTINGS)}
        />
      ) : (
        <SettingsIcon
          data-action_wrapper_classname={joinClasses(
            styles.notificationSettingsWrapperIcon,
            MPAnimations.Color.LightToDark
          )}
          key="notis"
          fontSize="small"
          onClick={() => setVisibleSubPanel(SubPanel.NOTIFICATIONS)}
        />
      ),
    ],
    [visibleSubPanel]
  );

  return (
    !!notificationsCountQueryRef && (
      <>
        {(!isMobile || !open) && (
          <NotificationBell
            countRef={countRef}
            queryRef={notificationsCountQueryRef}
            onClick={handleNotificationBellClick}
          />
        )}
        {!!isMobile && !!open && (
          <div
            className={joinClasses(
              navBarStyles.navIcon,
              MPAnimations.Color.LightToDark,
              navBarStyles.dark
            )}
          >
            <RemoveIcon onClick={handleNotificationBellClick} />
          </div>
        )}
        <Panel
          isNav
          anchor="right"
          trackingTitle="Notifications"
          title={
            countRef.current > 0
              ? `${nFormatter(
                  countRef.current,
                  null,
                  0,
                  undefined,
                  0
                )} New Notifications`
              : 'Notifications'
          }
          ModalProps={modalProps}
          open={open}
          onClose={toggleNotifications}
          actionIcons={actionIcons}
        >
          <Notifications
            invalidateNotificationCount={invalidateNotificationCount}
            isSettingsOpen={visibleSubPanel === SubPanel.SETTINGS}
            showNotifications
          />
        </Panel>
      </>
    )
  );
}

interface NotificationComponentProps {
  open: NotificationProps['open'];
  setOpen: NotificationProps['setOpen'];
}

export default function NotificationComponent({
  open,
  setOpen,
}: NotificationComponentProps) {
  return (
    <DefaultErrorBoundary hideState>
      <NotificationsWrapper open={open} setOpen={setOpen} />
    </DefaultErrorBoundary>
  );
}
