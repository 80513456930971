import {
  HTMLAttributes,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  MPAnimations,
  MPColorClass,
  MPFonts,
  MPIconButton,
  MPIconButtonProps,
  MPIconButtonThemes,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  DefaultUserIcon,
  MenuIcon,
  RemoveIcon,
  SearchIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import ProfileIcon from 'components/avatar/ProfileIcon';
import SuspenselessErrorBoundary from 'components/ErrorBoundaries/SuspenselessErrorBoundary';
import ROUTES from 'constants/Routes';
import useSession from 'hooks/useSession';
import { ExploreType } from 'pages/explore/types';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';
import useNavbarGTM from 'utils/GTM/navbar';
import withLoginRequiredClick from 'utils/hocs/withLoginRequiredClick';

import { ExploreNavLink } from './LinkSection';
import NotificationComponent from './Notifications';
import ProfilePanel from './ProfilePanel';
import SearchPanel from './SearchPanel';
import SitePanel from './SitePanel';
import { CloseAllPanelsContext } from './useCloseAllPanelsContext';

import * as styles from 'css/components/Navbar/Unified/index.module.css';

const LoginButton = withLoginRequiredClick<MPIconButtonProps>(MPIconButton);

function MPLogo({ closeAllPanels }: { closeAllPanels: () => void }) {
  const isDjango = useContext(IsDjangoContext);
  const Track = useNavbarGTM();
  return (
    <>
      <Link
        to={ROUTES.HOME()}
        reloadDocument={isDjango}
        className={joinClasses(
          MPColorClass.SolidNeutralOffBlack,
          MPAnimations.Color.DarkToLight,
          'animatable',
          'invisibleAnchor'
        )}
        onClick={() => {
          closeAllPanels();
          Track.clickNavigationItem(ROUTES.HOME());
        }}
      >
        <span className={joinClasses(MPFonts.textLargeMedium, styles.mplogo)}>
          Makers
        </span>
        <span className={joinClasses(MPFonts.textLargeRegular, styles.mplogo)}>
          Place
        </span>
      </Link>
    </>
  );
}

function NavIconWrapper({
  children,
  className,
  onClick,
}: Omit<HTMLAttributes<HTMLDivElement>, 'role'>) {
  const ariaOnEnter = useOnEnterKey(onClick);
  return (
    <div
      className={
        className ?? joinClasses(styles.navIcon, MPAnimations.Color.DarkToLight)
      }
      onClick={onClick}
      onKeyPress={ariaOnEnter}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
}

export enum PANEL_CONTROL {
  NOTIS = 'NOTIFICATIONS',
  PROFILE = 'PROFILE',
  SEARCH = 'SEARCH',
  SITE = 'SITE',
}

export default function Navbar() {
  const isMobile = useIsMobile();
  const session = useSession();
  const isDjango = useContext(IsDjangoContext);

  const [isSitePanelOpen, setIsSitePanelOpen] = useState(false);
  const [isNotiPanelOpen, setIsNotiPanelOpen] = useState(false);
  const [isProfilePanelOpen, setIsProfilePanelOpen] = useState(false);
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState(false);

  const Panels = useMemo(
    () => ({
      [PANEL_CONTROL.SITE]: setIsSitePanelOpen,
      [PANEL_CONTROL.NOTIS]: setIsNotiPanelOpen,
      [PANEL_CONTROL.PROFILE]: setIsProfilePanelOpen,
      [PANEL_CONTROL.SEARCH]: setIsSearchPanelOpen,
    }),
    []
  );

  const Track = useNavbarGTM();

  const setPanelIsOpen = useCallback(
    (key: PANEL_CONTROL, value: SetStateAction<boolean>) => {
      if (value === true) {
        Track.panelOpen(key);
      }
      Object.entries(Panels).forEach(([k, set]) => {
        set(
          k === key
            ? typeof value === 'boolean'
              ? value
              : (prev) => {
                  if (!prev) Track.panelOpen(key);
                  return value(prev);
                }
            : false
        );
      });
    },
    [Panels, Track]
  );

  const location = useLocation();

  const closeAllPanels = useCallback(() => {
    setIsSitePanelOpen(false);
    setIsNotiPanelOpen(false);
    setIsProfilePanelOpen(false);
    setIsSearchPanelOpen(false);
  }, []);

  useEffect(() => {
    // execute on location change
    closeAllPanels();
  }, [location.pathname, location.search, closeAllPanels]);

  const closeSearchPanel = useCallback(
    () => setPanelIsOpen(PANEL_CONTROL.SEARCH, false),
    [setPanelIsOpen]
  );

  return (
    <CloseAllPanelsContext value={closeAllPanels}>
      <div id="navbarTarget" className={styles.navBar}>
        <div className={styles.navBarCol}>
          <div className={styles.navIconSection}>
            {(!isMobile || !isSitePanelOpen) && (
              <MPIconButton
                theme={MPIconButtonThemes.NAV}
                onClick={() => setPanelIsOpen(PANEL_CONTROL.SITE, true)}
              >
                <MenuIcon />
              </MPIconButton>
            )}
            {!!isMobile && !!isSitePanelOpen && (
              <MPIconButton
                theme={MPIconButtonThemes.DARK}
                onClick={() => setPanelIsOpen(PANEL_CONTROL.SITE, false)}
              >
                <RemoveIcon />
              </MPIconButton>
            )}
            {(!isMobile || !isSearchPanelOpen) && (
              <MPIconButton
                theme={MPIconButtonThemes.NAV}
                onClick={() => setPanelIsOpen(PANEL_CONTROL.SEARCH, true)}
              >
                <SearchIcon fontSize="small" />
              </MPIconButton>
            )}
            {!!isMobile && !!isSearchPanelOpen && (
              <MPIconButton
                theme={MPIconButtonThemes.DARK}
                onClick={() => setPanelIsOpen(PANEL_CONTROL.SEARCH, false)}
              >
                <RemoveIcon />
              </MPIconButton>
            )}
          </div>
          {!isMobile && !isDjango && (
            <ExploreNavLink
              exploreType={ExploreType.Artworks}
              fontClassName={MPFonts.textSmallMedium}
              to={ROUTES.EXPLORE.ARTWORKS()}
              reloadDocument={isDjango}
              onClick={closeAllPanels}
            >
              Artworks
            </ExploreNavLink>
          )}
          {!isMobile && !isDjango && (
            <ExploreNavLink
              exploreType={ExploreType.Exhibitions}
              fontClassName={MPFonts.textSmallMedium}
              to={ROUTES.EXHIBITIONS()}
              reloadDocument
              onClick={closeAllPanels}
            >
              Exhibitions
            </ExploreNavLink>
          )}
        </div>
        <div className={styles.navBarCol}>
          <MPLogo closeAllPanels={closeAllPanels} />
        </div>
        <div className={styles.navBarCol}>
          <div className={styles.navIconSection}>
            {session.isLoggedIn() && (
              <NotificationComponent
                open={isNotiPanelOpen}
                setOpen={(val) => setPanelIsOpen(PANEL_CONTROL.NOTIS, val)}
              />
            )}
            <div>
              <LoginButton
                theme={MPIconButtonThemes.DARK}
                style={{
                  display: !session.isLoggedIn() ? 'auto' : 'none',
                }}
              >
                <DefaultUserIcon fontSize="small" />
              </LoginButton>

              {session.isLoggedIn() && (
                <>
                  {(!isMobile || !isProfilePanelOpen) && (
                    <NavIconWrapper
                      className={joinClasses(
                        styles.navIcon,
                        styles.profileIconWrapper
                      )}
                      onClick={() =>
                        setPanelIsOpen(PANEL_CONTROL.PROFILE, true)
                      }
                    >
                      <ProfileIcon
                        source={session.getProfileImage()}
                        alt=""
                        onClick={() =>
                          setPanelIsOpen(PANEL_CONTROL.PROFILE, true)
                        }
                      />
                    </NavIconWrapper>
                  )}
                  {!!isMobile && !!isProfilePanelOpen && (
                    <MPIconButton
                      theme={MPIconButtonThemes.DARK}
                      onClick={() =>
                        setPanelIsOpen(PANEL_CONTROL.PROFILE, false)
                      }
                    >
                      <RemoveIcon />
                    </MPIconButton>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <SuspenselessErrorBoundary hideState>
          <SitePanel
            open={isSitePanelOpen}
            onClose={() => setPanelIsOpen(PANEL_CONTROL.SITE, false)}
          />
        </SuspenselessErrorBoundary>
        <SuspenselessErrorBoundary hideState>
          <SearchPanel open={isSearchPanelOpen} onClose={closeSearchPanel} />
        </SuspenselessErrorBoundary>
        <SuspenselessErrorBoundary hideState>
          <ProfilePanel
            open={isProfilePanelOpen}
            useDark
            onClose={() => setPanelIsOpen(PANEL_CONTROL.PROFILE, false)}
          />
        </SuspenselessErrorBoundary>
      </div>
    </CloseAllPanelsContext>
  );
}
