/**
 * @generated SignedSource<<1abf0d4f111d5c33adba2fe60c9a5016>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditorialSortTypeEnum = "CREATED_AT";
export type SortOrderEnum = "DESC" | "ASC";
export type PaginatedExploreEditorialsQuery$variables = {
  cursor?: string | null;
  first?: number | null;
  query?: string | null;
  sortOrder?: SortOrderEnum | null;
  sortType?: EditorialSortTypeEnum | null;
};
export type PaginatedExploreEditorialsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExploreEditorialsPaginatedFragment">;
};
export type PaginatedExploreEditorialsQuery = {
  variables: PaginatedExploreEditorialsQuery$variables;
  response: PaginatedExploreEditorialsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortOrder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "sortOrder"
  },
  {
    "kind": "Variable",
    "name": "sortType",
    "variableName": "sortType"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaginatedExploreEditorialsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ExploreEditorialsPaginatedFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaginatedExploreEditorialsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExploreEditorialQuery",
        "kind": "LinkedField",
        "name": "editorial",
        "plural": false,
        "selections": [
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "concreteType": "EditorialConnection",
            "kind": "LinkedField",
            "name": "editorials",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EditorialEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTEditorialType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "backgroundImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "order",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pk",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "results",
            "args": (v1/*: any*/),
            "filters": [
              "query",
              "sortOrder",
              "sortType"
            ],
            "handle": "connection",
            "key": "PaginatedExploreEditorialsQuery__results",
            "kind": "LinkedHandle",
            "name": "editorials"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c57114d2aa325af12afe4573143b782e",
    "id": null,
    "metadata": {},
    "name": "PaginatedExploreEditorialsQuery",
    "operationKind": "query",
    "text": "query PaginatedExploreEditorialsQuery(\n  $cursor: String\n  $first: Int\n  $query: String\n  $sortOrder: SortOrderEnum\n  $sortType: EditorialSortTypeEnum\n) {\n  ...ExploreEditorialsPaginatedFragment\n}\n\nfragment ExploreEditorialsPaginatedFragment on Query {\n  editorial {\n    results: editorials(first: $first, after: $cursor, query: $query, sortOrder: $sortOrder, sortType: $sortType) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          backgroundImageUrl\n          description\n          id\n          order\n          pk\n          title\n          type\n          viewUrl\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4dbc76b68f0e703eecf9c557bcce525";

export default node;
