.container {
  background-color: var(--color-backgroundDefault);
  height: var(--appBodyHeight);
  left: 0;
  position: fixed;
  top: var(--navBar-height);
  width: 100vw;
}

.loader {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}
