import { useState } from 'react';
import { useSwitchChain } from 'wagmi';
import * as Sentry from '@sentry/react';
import { useStatsigClient } from '@statsig/react-bindings';

import { useTrackingContext } from 'components/trackingContext';
import { STATSIG_EVENT } from 'constants/Statsig';
import MPError, { MPErrorName } from 'errors/MPError';
import GTM, { EcommercePaymentType, EcommercePurchaseType } from 'GTM';
import useSession from 'hooks/useSession';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { PurchasableNFTType } from 'types/graphql/NFT';
import IsGlobalContractError from 'utils/errors/contracts/global';
import isWalletError from 'utils/errors/wallet';
import { getSafeWeiAmountFromEthUserInput } from 'utils/EthPriceUtils';
import getCurrentWalletName from 'utils/getCurrentWalletName';
import {
  getMinimumAcceptableBid,
  nftHasRankedAuction,
  nftHasUserPlacedExistingRankedOffer,
} from 'utils/nftUtils';

import {
  usePaymentFormState,
  useWalletConnectionState,
} from '../usePaymentFormState';
import useBidOn from './useEthMutations';

const useBidProductWithEthereum = ({
  nft,
  bidAmount,
  bitAmountUsd,
  enableGlobalOffer,
}: {
  bidAmount: string;
  bitAmountUsd: string;
  enableGlobalOffer: boolean;
  nft: PurchasableNFTType;
}) => {
  const statsigClient = useStatsigClient();
  const session = useSession();
  const [, updateFormState, resetFormState] = usePaymentFormState();
  const [isPending, setIsPending] = useState(false);

  const walletConnectionState = useWalletConnectionState();
  const { switchChainAsync } = useSwitchChain();
  const [transactionHash, setTransactionHash] = useState<string>(null);
  const resetTransactionHash = () => setTransactionHash(null);
  const { source } = useTrackingContext();

  const bidAmountFloat = parseFloat(bidAmount) || 0;
  const bidAmountWei = getSafeWeiAmountFromEthUserInput(bidAmountFloat);
  const minimumAcceptableBid = getMinimumAcceptableBid(
    nft,
    CurrencyDisplayMode.ETH
  );
  const hasUserPlacedExistingRankedOffer =
    nftHasUserPlacedExistingRankedOffer(nft);
  const rankedAuctionBidAmountFloat =
    hasUserPlacedExistingRankedOffer && bidAmountFloat && bidAmountFloat > 0
      ? bidAmountFloat -
        ((nft.listing.rankedAuction.lastBid?.isEtherBid &&
          nft.listing.rankedAuction.lastBid?.bidInEther) ||
          0)
      : bidAmountFloat;
  const rankedAuctionBidAmountWei = getSafeWeiAmountFromEthUserInput(
    rankedAuctionBidAmountFloat
  );
  const hasRankedAuction = nftHasRankedAuction(nft);

  const bidOn = useBidOn({
    bidAmountWei,
    nft,
    rankedAuctionBidAmountWei,
  });

  const placeBid = async (): Promise<void> => {
    if (isPending || !walletConnectionState.isConnected) return;
    statsigClient.logEvent(
      STATSIG_EVENT.MP.PRODUCT.BEGIN_CHECKOUT,
      bitAmountUsd,
      {
        is_presale: `${false}`,
        nft_id: nft.pk,
        nft_name: nft.metadata.title,
        nft_price_eth: bidAmount,
        nft_price_usd: bitAmountUsd,
        offer_or_purchase: EcommercePurchaseType.Offer,
        payment_type: EcommercePaymentType.ETH,
      }
    );
    try {
      setIsPending(true);
      if (walletConnectionState.isConnectedToWrongNetwork) {
        await switchChainAsync({ chainId: session.contractNetwork });
      }

      resetFormState();

      const transaction = hasRankedAuction
        ? bidOn.rankedAuction
        : enableGlobalOffer
        ? bidOn.globalOffer
        : bidOn.product;
      const transactionResult = await transaction();
      setTransactionHash(transactionResult);

      GTM.ecommerce.purchase(
        nft,
        {
          coupon: null,
          offer_or_purchase: EcommercePurchaseType.Offer,
          payment_type: EcommercePaymentType.ETH,
          total_order_count: 1,
          transaction_id: transactionResult,
          value: nft.listing?.lowestAskInUsd?.toString() ?? null,
          value_ether: nft.listing?.lowestAskInEth ?? null,
          wallet_type: getCurrentWalletName(),
        },
        source
      );
      statsigClient.logEvent(
        STATSIG_EVENT.MP.PRODUCT.PURCHASE_SUCCESS,
        bitAmountUsd,
        {
          is_presale: `${false}`,
          nft_id: nft.pk,
          nft_name: nft.metadata.title,
          nft_price_eth: bidAmount,
          nft_price_usd: bitAmountUsd,
          offer_or_purchase: EcommercePurchaseType.Offer,
          payment_type: EcommercePaymentType.ETH,
        }
      );
      updateFormState({ isSuccess: true });
    } catch (error) {
      if (isWalletError.userRejected(error)) {
        updateFormState({ isValidating: false });
      } else {
        updateFormState({
          mutationError: error,
        });
        GTM.ecommerce.error(error.toString(), {
          offer_or_purchase: EcommercePurchaseType.Offer,
        });
        statsigClient.logEvent(
          STATSIG_EVENT.MP.PRODUCT.PURCHASE_ERROR,
          bitAmountUsd,
          {
            is_presale: `${false}`,
            nft_id: nft.pk,
            nft_name: nft.metadata.title,
            nft_price_eth: bidAmount,
            nft_price_usd: bitAmountUsd,
            offer_or_purchase: EcommercePurchaseType.Offer,
            payment_type: EcommercePaymentType.ETH,
          }
        );
        Sentry.captureException(error);
      }
    } finally {
      setIsPending(false);
    }
  };

  const manager = enableGlobalOffer
    ? bidOn.managers.globalOffer
    : hasRankedAuction
    ? bidOn.managers.rankedAuction
    : bidOn.managers.productBid;

  const validationError =
    bidAmountFloat < minimumAcceptableBid
      ? hasRankedAuction && hasUserPlacedExistingRankedOffer
        ? new MPError(MPErrorName.MUST_INCREASE_OFFER_OVER_PREVIOUS)
        : new MPError(MPErrorName.BELOW_MINIMUM_BID, [
            `${minimumAcceptableBid.toString()} ${CurrencyDisplayMode.ETH}`,
          ])
      : undefined;

  const [prevSimulationError, setPrevSimulationError] = useState(
    manager.simulate.error
  );
  const simulationError = manager.simulate.isFetching
    ? prevSimulationError
    : (() => {
        if (prevSimulationError !== manager.simulate.error) {
          setPrevSimulationError(manager.simulate.error);
        }
        return manager.simulate.error;
      })();

  return [
    placeBid,
    {
      isDisabled:
        isPending ||
        !walletConnectionState.isConnected ||
        manager.mutate.isPending ||
        manager.simulate.isPending ||
        !bidAmount ||
        !!validationError,
      isPending,
      mutationError: manager.mutate.error,
      simulationError:
        walletConnectionState.isConnected &&
        bidAmountFloat > 0 &&
        !IsGlobalContractError.BidPriceMustBePositive(simulationError)
          ? simulationError
          : undefined,
      validationError,
    },
    transactionHash,
    resetTransactionHash,
  ] as const;
};

export default useBidProductWithEthereum;
