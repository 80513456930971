import { SvgIcon } from '@mui/material';

export default function VideoIcon(props) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0)">
        <g filter="url(#filter0_d)">
          <path
            d="M16 9.5 V 13.5 L 20.5 16 V 7 L 16 9.5 Z"
            fill="currentColor"
          />
          <path d="M 1.5 6 H 14 V 17 H 1.5 V 6 Z" fill="currentColor" />
          <path
            d="M16 9.5 V 13.5 L 20.5 16 V 7 L 16 9.5 Z"
            stroke="currentColor"
            strokeLinejoin="round"
          />
          <path
            d="M 1.5 6 H 14 V 17 H 1.5 V 6 Z"
            stroke="currentColor"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="-1.5"
          width="25"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
