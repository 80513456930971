/**
 * @generated SignedSource<<82626a8a3b7764ed8ea9b1c0c53eff74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RejectProductBidWithCustodialWalletArguments = {
  bidId: string;
};
export type NFTRejectProductBidWithCustodialWalletMutation$variables = {
  requestData: RejectProductBidWithCustodialWalletArguments;
};
export type NFTRejectProductBidWithCustodialWalletMutation$data = {
  readonly rejectProductBidWithCustodialWallet: {
    readonly success: boolean | null;
  } | null;
};
export type NFTRejectProductBidWithCustodialWalletMutation = {
  variables: NFTRejectProductBidWithCustodialWalletMutation$variables;
  response: NFTRejectProductBidWithCustodialWalletMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "RejectProductBidWithCustodialWalletMutation",
    "kind": "LinkedField",
    "name": "rejectProductBidWithCustodialWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTRejectProductBidWithCustodialWalletMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTRejectProductBidWithCustodialWalletMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a400eaeb54e6c7967d90c4dbbd00ad1",
    "id": null,
    "metadata": {},
    "name": "NFTRejectProductBidWithCustodialWalletMutation",
    "operationKind": "mutation",
    "text": "mutation NFTRejectProductBidWithCustodialWalletMutation(\n  $requestData: RejectProductBidWithCustodialWalletArguments!\n) {\n  rejectProductBidWithCustodialWallet(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d2914f47314714ccc30376cfa2a11a0";

export default node;
