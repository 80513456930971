.appBody {
  --oneHundredVh: "100%";
  --appBodyHeight: calc(100vh - var(--navBar-height));
  height: var(--appBodyHeight);
  overflow-y: auto;
  overflow-y: overlay;
  position: relative;
}

@media (--mq-is-mobile) {
  .appBody {
    --appBodyHeight: calc(var(--oneHundredVh) - var(--navBar-height));
  }
}

.bodyWrapper {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  background-color: var(--color-backgroundDefault);
}
