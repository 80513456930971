import { ReactElement, ReactNode, useState } from 'react';
import { merge } from 'lodash';
import { useMediaQuery } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { SxProps, Theme } from '@mui/system';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';
import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import useDefaultTheme from '../hooks/useDefaultTheme';
import ColorValue from '../themes/default/colors';

export enum TooltipVariants {
  BLANK = 'BLANK',
  DEFAULT = 'DEFAULT',
}

const variantSxMap: Record<TooltipVariants, SxProps<Theme>> = {
  [TooltipVariants.BLANK]: {
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 0,
    color: ColorValue.White,
    margin: 0,
    maxWidth: 'none',
    padding: 0,
  },
  [TooltipVariants.DEFAULT]: {
    backgroundColor: MPColorValue.SolidNeutralGray5,
    borderRadius: '20px',
    boxShadow: 'none',
    color: MPColorValue.CommonWhite,
    cursor: 'default',
    maxWidth: '310px',
    padding: '14px 16px',
    [`&.${tooltipClasses.tooltip} a, &.${tooltipClasses.tooltip} a:visited`]: {
      color: 'inherit',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      textDecoration: 'underline',
    },
  },
};

const DEFAULT_PLACEMENT = 'bottom';

export interface MPTooltipProps extends Omit<TooltipProps, 'sx' | 'placement'> {
  /**
   * Anchor Element for Determining where to place the tooltip
   */
  children: ReactElement<any, any>;

  /**
   * Node/Text containing tooltip content
   */
  title: NonNullable<ReactNode> | string;

  /**
   * Tooltip placement is overwritten on mobile if it's of the right or left variants
   * @default 'bottom'
   */
  placement?: TooltipProps['placement'];

  /**
   * Overrides MUI tooltip props
   */
  sx?: SxProps<Theme>;

  /**
   *
   */
  variant?: TooltipVariants;
}

// MakersPlace Tooltips are controlled by default for performance reasons
export default function MPTooltip({
  title,
  children,
  placement = DEFAULT_PLACEMENT,
  sx = {},
  variant = TooltipVariants.DEFAULT,
  ...props
}: MPTooltipProps) {
  const isMobile = useMediaQuery(useDefaultTheme().breakpoints.down('desktop'));
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  // !!TODO: This SxProps<Theme> doesn't work - fix later
  const mergedSx: any = merge({}, variantSxMap[variant], sx);

  const newPlacement =
    isMobile && (placement.startsWith('right') || placement.startsWith('left'))
      ? 'bottom'
      : placement;
  // The extra space in title is required, otherwise the tooltip will not be shown.
  return (
    <Tooltip
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      componentsProps={{
        tooltip: { className: MPFonts.textSmallRegular, sx: mergedSx },
      }}
      title={isOpen ? title : ' '}
      placement={newPlacement}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
