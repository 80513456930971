.container .logo {
  height: 93px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}

.container.full .logo {
  height: 45px;
  background-position: left center;
}
