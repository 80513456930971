import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const FullScreenIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00005 3.9978L11 3.9978V7.1978L7.20005 7.1978V11H4.00005V3.9978ZM13 4.00005H20.0023V11H16.8023V7.20005H13V4.00005ZM3.9978 20L3.9978 13H7.1978V16.8001H11V20H3.9978ZM20 13V20.0023H13V16.8023H16.8V13H20Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default FullScreenIcon;
