/**
 * @generated SignedSource<<eff58b2a787fe269eeba57b2849a01e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AutocompleteQuery$variables = {
  str?: string | null;
};
export type AutocompleteQuery$data = {
  readonly autocomplete: {
    readonly creators: ReadonlyArray<{
      readonly fullName: string | null;
      readonly username: string | null;
      readonly storeSlug: string | null;
      readonly userProfileUrl: string | null;
    } | null> | null;
    readonly collectors: ReadonlyArray<{
      readonly fullName: string | null;
      readonly username: string | null;
      readonly storeSlug: string | null;
      readonly userProfileUrl: string | null;
    } | null> | null;
    readonly creations: ReadonlyArray<{
      readonly title: string | null;
      readonly productUrl: string | null;
    } | null> | null;
    readonly collections: ReadonlyArray<{
      readonly collectionName: string | null;
      readonly collectionSlug: string | null;
      readonly collectionLogoUrl: string | null;
    } | null> | null;
    readonly allowedCollections: boolean | null;
  } | null;
};
export type AutocompleteQuery = {
  variables: AutocompleteQuery$variables;
  response: AutocompleteQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "str"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "storeSlug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userProfileUrl",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "str",
        "variableName": "str"
      }
    ],
    "concreteType": "AutoCompleteType",
    "kind": "LinkedField",
    "name": "autocomplete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoCompleteCreatorType",
        "kind": "LinkedField",
        "name": "creators",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoCompleteCollectorType",
        "kind": "LinkedField",
        "name": "collectors",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoCompleteCreationType",
        "kind": "LinkedField",
        "name": "creations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoCompleteCollectionType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionSlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionLogoUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "allowedCollections",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AutocompleteQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AutocompleteQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6697cff5451668aeded1d023bde74f50",
    "id": null,
    "metadata": {},
    "name": "AutocompleteQuery",
    "operationKind": "query",
    "text": "query AutocompleteQuery(\n  $str: String\n) {\n  autocomplete(str: $str) {\n    creators {\n      fullName\n      username\n      storeSlug\n      userProfileUrl\n    }\n    collectors {\n      fullName\n      username\n      storeSlug\n      userProfileUrl\n    }\n    creations {\n      title\n      productUrl\n    }\n    collections {\n      collectionName\n      collectionSlug\n      collectionLogoUrl\n    }\n    allowedCollections\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7308c58620bf6b9ed0f9e1a3efda19e";

export default node;
