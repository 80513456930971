import ProductActions, { ProductActionsProps } from './ProductActions';
import ProductActionsContainer from './ProductActionsContainer';

export default function ProductActionsSection(props: ProductActionsProps) {
  return (
    <ProductActionsContainer>
      <ProductActions {...props} />
    </ProductActionsContainer>
  );
}
