import { TextField, TextFieldProps } from '@mui/material';

import { MPFonts } from '../..';

export default function MPFormTextInput({
  children,
  variant = 'outlined',
  ...passedProps
}: TextFieldProps) {
  return (
    <TextField
      sx={{ fontFamily: MPFonts.inputText, margin: '8px 0px' }}
      variant={variant}
      {...passedProps}
    >
      {children}
    </TextField>
  );
}
