import { ChangeEvent, useCallback, useState } from 'react';

import {
  MPCheckbox,
  MPDivider,
  MPDropdown,
  MPDropdownProps,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useSession from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';

import ArtistFilterList from './ArtistsFilterList';
import ArtistFilterSearchInput from './ArtistsFilterSearchInput';

import * as styles from 'css/components/filters/ArtistsFilter.module.css';

export type ArtistsFilterValue = Partial<{
  isShowFollowing: boolean;
  userNames: string[];
}>;

export interface ArtistsFilterProps extends Pick<MPDropdownProps, 'onToggle'> {
  onChange: (value: ArtistsFilterValue) => void;
  value: ArtistsFilterValue;
  checkboxLabel?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  title?: string;
}

function ArtistsFilter({
  title = 'Artists',
  inputPlaceholder = 'Artist',
  checkboxLabel = 'Artists You Follow',
  inputLabel = 'Artist Search',
  value,
  onChange,
  onToggle,
}: ArtistsFilterProps) {
  const session = useSession();
  const [removedUserNames, setRemovedUserNames] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<string[]>([]);

  const handleIsFollowingChange = useCallback(
    (checked: boolean) => {
      onChange({ ...value, isShowFollowing: checked ? true : undefined });
    },
    [value, onChange]
  );

  const handleUserNamesChange = useCallback(
    (checked: boolean, userName: string) => {
      onChange({
        ...value,
        userNames: checked
          ? [...value.userNames, userName]
          : value.userNames.filter((u) => u !== userName),
      });
      if (!checked && searchResults.length > 0)
        setRemovedUserNames((prev) => [...prev, userName]);
    },
    [value, onChange, searchResults.length]
  );

  const showDivider = value.userNames.length > 0 || session.isLoggedIn();

  return (
    <MPDropdown title={title} onToggle={onToggle}>
      <div className={styles.artistFilterContainer}>
        <ul className={joinClasses(CSSGlobal.Flex.Col, styles.artistsFilter)}>
          {session.isLoggedIn() ? (
            <li key="artist-following">
              <MPCheckbox
                label={checkboxLabel}
                name="artist-following"
                isChecked={value.isShowFollowing}
                onChange={(
                  event: ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => handleIsFollowingChange(checked)}
              />
            </li>
          ) : null}
          <ArtistFilterList
            userNames={value.userNames}
            userNamesToExclude={value.userNames}
            onChange={handleUserNamesChange}
          />
        </ul>
        {showDivider ? (
          <MPDivider className={styles.activityArtistDivider} />
        ) : null}
        <ArtistFilterSearchInput
          accountsQuery={{ variables: { searchText: '' } }}
          userNames={value.userNames}
          userNamesToExclude={removedUserNames}
          placeholder={inputPlaceholder}
          label={inputLabel}
          setSearchResults={setSearchResults}
        />
        <div className={styles.availableArtistSection}>
          <ul className={joinClasses(CSSGlobal.Flex.Col, styles.artistsFilter)}>
            <ArtistFilterList
              userNames={searchResults}
              userNamesToExclude={value.userNames}
              onChange={handleUserNamesChange}
            />
          </ul>
        </div>
      </div>
    </MPDropdown>
  );
}

export default ArtistsFilter;
