import { ChangeEvent, useCallback, useState } from 'react';
import pluralize from 'pluralize';

import { PurchasedArtworkIcon } from '@mp-frontend/core-components/icons';

import AccountStripeCardsQueryType, {
  AccountStripeCardsQuery,
} from 'graphql/__generated__/AccountStripeCardsQuery.graphql';
import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';

import { UNBREAKABLE_SPACE_UNICODE } from 'constants/Symbols';
import { useCreditCardPaymentFormState } from 'hooks/product/usePaymentFormState';
import {
  usePurchaseOpenEditionWithCreditCard,
  usePurchaseOpenEditionWithEthereum,
} from 'hooks/product/usePurchaseOpenEdition';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import SuccessView from '../../product/purchaseOfferDialog/SuccessView';
import PurchaseDialog from './PurchaseDialog';

export enum OpenEditionPurchaseStates {
  PURCHASE = 'PURCHASE',
  PURCHASE_SUCCESS = 'SUCCESS',
}

interface OpenEditionPurchaseDialogProps {
  allowFiat: boolean;
  contractQuery: WithLoadQueryProps<NFTContractQuery>;
  isPresale: boolean;
  onClose: () => void;
  openEditionId: number;
  priceInEth: number;
  priceInUsd: number;
  stripeQuery: WithLoadQueryProps<AccountStripeCardsQuery>;
  forceUpdate?: () => void;
}

function OpenEditionPurchaseDialog({
  openEditionId,
  contractQuery,
  isPresale,
  priceInEth,
  priceInUsd,
  allowFiat,
  stripeQuery,
  forceUpdate,
  onClose,
}: OpenEditionPurchaseDialogProps) {
  const [openEditionPurchaseState, setOpenEditionPurchaseState] =
    useState<OpenEditionPurchaseStates>(OpenEditionPurchaseStates.PURCHASE);
  const [paymentFormState, updateFormState] = useCreditCardPaymentFormState();
  const [count, setCount] = useState(1);

  const [
    purchaseWithEthereum,
    purchaseWithEthereumState,
    resetETHPurchaseFormState,
    transactionHash,
    resetTransactionHash,
  ] = usePurchaseOpenEditionWithEthereum({
    count,
    depositFundContractQueryRef: contractQuery.queryRef,
    isPresale,
    openEditionId,
    priceInEth,
    priceInUsd,
  });

  const onSuccessPurchase = useCallback(() => {
    stripeQuery.invalidate();
    resetTransactionHash();
    setOpenEditionPurchaseState(OpenEditionPurchaseStates.PURCHASE_SUCCESS);
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [resetTransactionHash, setOpenEditionPurchaseState, stripeQuery]);

  const [
    purchaseWithCreditCard,
    purchaseWithCreditCardState,
    resetCreditCardFormState,
  ] = usePurchaseOpenEditionWithCreditCard({
    ...paymentFormState,
    count,
    isPresale,
    onSuccess: onSuccessPurchase,
    openEditionId,
    priceInEth,
    priceInUsd,
  });

  const handleSuccessViewClose = useCallback(() => {
    setOpenEditionPurchaseState(OpenEditionPurchaseStates.PURCHASE);
    resetCreditCardFormState();
    resetETHPurchaseFormState();
    setCount(1);
    onClose();
    forceUpdate?.();
  }, [
    onClose,
    forceUpdate,
    resetCreditCardFormState,
    resetETHPurchaseFormState,
    setOpenEditionPurchaseState,
  ]);

  const handleCountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCount(parseInt(event.target.value, 10));
    },
    []
  );

  const successMessage = `You Purchased ${
    count === 1 ? `an` : count
  }${UNBREAKABLE_SPACE_UNICODE}${pluralize('Artworks', count)}`;

  return {
    [OpenEditionPurchaseStates.PURCHASE]: (
      <PurchaseDialog
        purchaseWithCreditCard={purchaseWithCreditCard}
        purchaseWithEthereum={purchaseWithEthereum}
        updateFormState={updateFormState}
        purchaseWithEthereumState={purchaseWithEthereumState}
        purchaseWithCreditCardState={purchaseWithCreditCardState}
        stripeQuery={stripeQuery}
        priceInEth={priceInEth}
        priceInUsd={priceInUsd}
        onCountChange={handleCountChange}
        count={count.toString()}
        allowFiat={allowFiat}
        purchaseWithEthereumTransaction={transactionHash}
        onClose={onClose}
        onSuccessPurchase={onSuccessPurchase}
      />
    ),
    [OpenEditionPurchaseStates.PURCHASE_SUCCESS]: (
      <SuccessView
        Icon={PurchasedArtworkIcon}
        title={successMessage}
        message="Share this moment to celebrate!"
        onClose={handleSuccessViewClose}
        variant="premium"
        link={window.location.href}
      />
    ),
  }[openEditionPurchaseState];
}

export default withDefaultErrorBoundary(
  withLoadQuery(OpenEditionPurchaseDialog, {
    contractQuery: { concreteRequest: NFTContractQueryType },
    stripeQuery: { concreteRequest: AccountStripeCardsQueryType },
  }),
  {
    hideState: true,
  }
);
