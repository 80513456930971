import { MouseEvent, useCallback, useState } from 'react';

import { MPFonts, MPStandardDialog } from '@mp-frontend/core-components';
import { joinClasses, useOnEnterKey } from '@mp-frontend/core-utils';

import CSSGlobal from 'types/enums/css/Global';
import { PurchasableNFTType } from 'types/graphql/NFT';
import generateEditionString from 'utils/generateEditionText';
import { nftHasRankedAuction } from 'utils/nftUtils';

import { ProductMultiEditionProvenance } from '../productProvenance/MultiEditionProvenance';
import ProductDefaultLabel from './ProductDefaultLabel';

import * as styles from 'css/pages/product/ProductLabels.module.css';

interface ProductEditionLabelProps {
  nft: PurchasableNFTType;
}

export default function ProductEditionLabel({ nft }: ProductEditionLabelProps) {
  const { totalSupply } = nft.metadata;
  const { printEdition } = nft;

  const [showEditionActivity, setShowEditionActivity] =
    useState<boolean>(false);
  const hasRankedAuction = nftHasRankedAuction(nft);

  const handleOpen = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (totalSupply > 1) {
        setShowEditionActivity(true);
      }
    },
    [totalSupply]
  );
  const handleOpenAria = useOnEnterKey(handleOpen);
  const handleClose = useCallback(() => setShowEditionActivity(false), []);

  return !hasRankedAuction ? (
    <>
      <div
        role="button"
        tabIndex={0}
        className={styles.productLabel}
        onClick={handleOpen}
        onKeyPress={handleOpenAria}
      >
        <div
          className={joinClasses(
            styles.productLabelText,
            styles.secondary,
            MPFonts.textNormalMedium,
            {
              hoverableLink: totalSupply > 1,
              [CSSGlobal.Cursor.Pointer]: totalSupply > 1,
            }
          )}
        >
          Edition
        </div>
        <div
          className={joinClasses(styles.productLabelText, MPFonts.price, {
            hoverableLink: totalSupply > 1,
            [CSSGlobal.Cursor.Pointer]: totalSupply > 1,
          })}
        >
          {generateEditionString(printEdition, totalSupply)}
        </div>
      </div>

      <MPStandardDialog
        title="Edition Activity"
        open={showEditionActivity}
        onClose={handleClose}
      >
        <ProductMultiEditionProvenance
          multiProvenanceQuery={{
            variables: { nftMetadataId: parseInt(nft.metadata.pk, 10) },
          }}
          nft={nft}
        />
      </MPStandardDialog>
    </>
  ) : (
    <ProductDefaultLabel label="Total Editions" value={totalSupply} />
  );
}
