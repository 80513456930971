import { MPColorValue, MPTooltip } from '@mp-frontend/core-components';

import { SocialLink } from '../SocialLink';

import * as styles from 'css/pages/profiles/ProfileHeader.module.css';

interface SocialIconLinkProps {
  socialMedia: Omit<SocialLink, 'linkType'> & { linkType: string };
}

export default function SocialIconLink({ socialMedia }: SocialIconLinkProps) {
  return (
    <MPTooltip key={socialMedia.linkType} title={socialMedia.tooltip}>
      <a
        target="_blank"
        className={styles.socialLinkAnchor}
        href={socialMedia.href}
        rel="noreferrer"
      >
        <socialMedia.Icon htmlColor={MPColorValue.SolidNeutralGray5} />
      </a>
    </MPTooltip>
  );
}
