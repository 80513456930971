import { SvgIcon } from '@mui/material';

export default function CaretIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.40041 7.34948C2.88056 7.82827 2.86484 8.61958 3.3653 9.11693L10.9123 16.6169C11.1556 16.8587 11.4903 16.9967 11.8411 16.9999C12.1919 17.0031 12.5293 16.8712 12.7774 16.6339L20.6173 9.13391C21.1276 8.64576 21.1276 7.85431 20.6173 7.36614C20.1071 6.87797 19.2798 6.87795 18.7696 7.3661L11.8713 13.9653L5.24786 7.38308C4.7474 6.88573 3.92027 6.87069 3.40041 7.34948Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
