import {
  startTransition,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useAccount } from 'wagmi';

import { MPDialog, useIsMobile } from '@mp-frontend/core-components';
import { CheckCircleIcon } from '@mp-frontend/core-components/icons';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import useTokenReadContract from 'hooks/contracts/useTokenReadContract';
import useSaleContract from 'hooks/useSaleContract';
import { NFTType } from 'types/graphql/NFT';
import { areSameAddress } from 'utils/areSameAddress';
import { HexString } from 'utils/jwt/walletUtils';

import ProductPendingOnChain from '../ProductPendingOnChain';
import SuccessView from '../purchaseOfferDialog/SuccessView';
import WalletConnection from '../purchaseOfferDialog/WalletConnection';
import ProductOwnerDelisting from './Delist';
import ProductOwnerApprove from './ProductOwnerApprove';
import ProductOwnerListingSelection from './ProductOwnerListSelection';
import ProductOwnerWalletDisconnect from './ProductOwnerWalletDisconnect';
import ProductSetReserve from './ProductSetReserve';
import ProductSetBuyNow from './SetBuyNow';

import * as styles from '../../../css/pages/product/ProductOwnerUpdateListing.module.css';

interface ProductOwnerUpdateListingProps {
  approvalRegistryContract: NFTContractQuery$data['nftContract'];
  invalidate: () => void;
  nft: NFTType;
  setShowDialog: (show: boolean) => void;
  showDialog: boolean;
}

export enum ProductOwnerStates {
  APPROVED = 'APPROVED',
  DELIST = 'DELIST',
  DELISTED = 'DELISTED',
  DELIST_PENDING = 'DELIST_PENDING',
  FIXED_SALE = 'FIXED_SALE',
  FIXED_SALE_LISTED = 'FIXED_SALE_LISTED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_FIXED_SALE = 'PENDING_FIXED_SALE',
  RESERVE = 'RESERVE',
  RESERVE_LISTED = 'RESERVE_LISTED',
  WALLET_DISCONNECTED = 'WALLET_DISCONNECTED',
  WALLET_MISMATCH = 'WALLET_MISMATCH',
}

const SUCCESS_MSG_FOR_STATES = {
  DEFAULT: 'Your Artwork was Updated',
  DELISTED: 'Your Artwork was Delisted',
  FIXED_SALE_LISTED: 'Your Artwork was Listed',
  RESERVE_LISTED: 'Your Artwork was Listed',
};

function ProductOwnerUpdateListing({
  nft,
  invalidate,
  setShowDialog,
  showDialog,
  approvalRegistryContract,
}: ProductOwnerUpdateListingProps) {
  const { address: currentUserAddress, isConnected: isWalletConnected } =
    useAccount();
  const [currentTransactionHash, setCurrentTransactionHash] =
    useState<string>(null);
  const [showPendingOnChain, setShowPendingOnChain] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const nftGeneralSaleContract = useSaleContract({
    nft,
  });

  const saleContract = nft.listing?.liveSale?.contract
    ? nft.listing?.liveSale.contract
    : nftGeneralSaleContract;

  const tokenContractAddress = nft.contract.address;
  const tokenContractABI = JSON.parse(nft.contract.abidata).abi;

  const { useIsApprovedForAll } = useTokenReadContract({
    abi: tokenContractABI,
    contractAddress: tokenContractAddress as HexString,
  });

  const {
    data: isSaleContractApprovedByUser,
    isFetching: isSaleContractApprovalFetching,
    isRefetching: isSaleContractApprovalRefetching,
    refetch: saleContractApprovalRefetch,
  } = useIsApprovedForAll({
    operatorAddress: saleContract.address,
    owner: currentUserAddress,
  });

  const isApprovalFetching =
    isSaleContractApprovalFetching && isSaleContractApprovalRefetching;
  const [ownershipState, setOwnershipState] = useState<ProductOwnerStates>(
    ProductOwnerStates.PENDING_APPROVAL
  );
  const { hasReservePrice, liveSale } = nft.listing;
  const isWalletMismatch =
    !nft.listing?.hasReservePrice &&
    !nft.isCustodialOwner &&
    !areSameAddress(nft.currentOwnerAddress as HexString, currentUserAddress);

  const getTitleForModalDictFromState = useCallback(() => {
    const hasListing = !!liveSale || nft.listing.hasReservePrice;
    const SELL_TITLE = hasListing ? 'Edit Listing' : 'Sell Artwork';
    const DELIST_TITLE = 'Delist Artwork';
    const WALLET_DISCONNECTED_TITLE = 'Connect Wallet';
    const WALLET_MISMATCH_TITLE = 'Wallet Mismatch';
    const PENDING_APPROVAL_TITLE = 'List Artwork for Sale';
    return {
      APPROVED: SELL_TITLE,
      DELIST: DELIST_TITLE,
      DELISTED: DELIST_TITLE,
      DELIST_PENDING: DELIST_TITLE,
      FIXED_SALE: SELL_TITLE,
      FIXED_SALE_LISTED: SELL_TITLE,
      PENDING_APPROVAL: PENDING_APPROVAL_TITLE,
      PENDING_FIXED_SALE: SELL_TITLE,
      RESERVE: SELL_TITLE,
      RESERVE_LISTED: SELL_TITLE,
      WALLET_DISCONNECTED: WALLET_DISCONNECTED_TITLE,
      WALLET_MISMATCH: WALLET_MISMATCH_TITLE,
    };
  }, [liveSale, nft.listing?.hasReservePrice]);

  const setDefaultOwnerShipStates = useCallback(() => {
    startTransition(() => {
      if (!isWalletConnected && !nft.isCustodialOwner) {
        setOwnershipState(ProductOwnerStates.WALLET_DISCONNECTED);
      } else if (isWalletMismatch && !nft.isCustodialOwner) {
        setOwnershipState(ProductOwnerStates.WALLET_MISMATCH);
      } else if (!isApprovalFetching) {
        if (!isSaleContractApprovedByUser && !nft.isCustodialOwner) {
          setOwnershipState(ProductOwnerStates.PENDING_APPROVAL);
        } else if (hasReservePrice) {
          setOwnershipState(ProductOwnerStates.RESERVE);
        } else if (liveSale) {
          setOwnershipState(ProductOwnerStates.FIXED_SALE);
        } else {
          setOwnershipState(ProductOwnerStates.APPROVED);
        }
      }
    });
  }, [
    isApprovalFetching,
    isSaleContractApprovedByUser,
    hasReservePrice,
    liveSale,
    setOwnershipState,
    isWalletConnected,
    isWalletMismatch,
    nft.isCustodialOwner,
  ]);

  useEffect(() => {
    setDefaultOwnerShipStates();
  }, [
    isApprovalFetching,
    isSaleContractApprovedByUser,
    hasReservePrice,
    liveSale,
    setDefaultOwnerShipStates,
  ]);

  useEffect(() => {
    if (
      currentTransactionHash &&
      (ownershipState === ProductOwnerStates.PENDING_APPROVAL ||
        ownershipState === ProductOwnerStates.PENDING_FIXED_SALE ||
        ownershipState === ProductOwnerStates.DELIST_PENDING)
    ) {
      setShowPendingOnChain(true);
    } else {
      setShowPendingOnChain(false);
    }
  }, [currentTransactionHash, ownershipState]);

  const updateOwnerShipStatesAfterSuccessOnChain = () => {
    startTransition(() => {
      if (ownershipState === ProductOwnerStates.PENDING_APPROVAL) {
        setOwnershipState(ProductOwnerStates.APPROVED);
      } else if (ownershipState === ProductOwnerStates.PENDING_FIXED_SALE) {
        setOwnershipState(ProductOwnerStates.FIXED_SALE_LISTED);
      } else if (ownershipState === ProductOwnerStates.DELIST_PENDING) {
        setOwnershipState(ProductOwnerStates.DELISTED);
      }
      setCurrentTransactionHash(null);
    });
  };

  const hasBackIcon =
    ((ownershipState === ProductOwnerStates.FIXED_SALE ||
      ownershipState === ProductOwnerStates.RESERVE) &&
      !nft.listing.hasReservePrice &&
      !nft.listing.liveSale) ||
    ownershipState === ProductOwnerStates.DELIST;

  const updateProductStateOnBackAction = () => {
    startTransition(() => {
      if (
        ownershipState === ProductOwnerStates.FIXED_SALE ||
        ownershipState === ProductOwnerStates.RESERVE
      ) {
        setOwnershipState(ProductOwnerStates.APPROVED);
      } else if (ownershipState === ProductOwnerStates.DELIST) {
        if (nft.listing.liveSale) {
          setOwnershipState(ProductOwnerStates.FIXED_SALE);
        } else {
          setOwnershipState(ProductOwnerStates.RESERVE);
        }
      }
    });
  };

  const isListedNow =
    ownershipState === ProductOwnerStates.FIXED_SALE_LISTED ||
    ownershipState === ProductOwnerStates.RESERVE_LISTED;

  const successTitle = isListedNow ? 'Congratulations!' : 'Success!';
  const successMessage =
    SUCCESS_MSG_FOR_STATES[ownershipState] || SUCCESS_MSG_FOR_STATES.DEFAULT;

  return (
    <>
      {!!showDialog && (
        <MPDialog
          open={showDialog}
          onClose={() => {
            setDefaultOwnerShipStates();
            setShowDialog(false);
          }}
          title={getTitleForModalDictFromState()[ownershipState]}
          sx={{
            '& .MuiDialog-paper': {
              maxHeight: 'var(--maxDialogHeight)',
              width: isMobile ? '100%' : 'var(--mp-defaultDialogWidth)',
            },
          }}
          onPrefixClick={hasBackIcon ? updateProductStateOnBackAction : null}
        >
          <div>
            <>
              <div>
                {ownershipState === ProductOwnerStates.WALLET_MISMATCH && (
                  <ProductOwnerWalletDisconnect nft={nft} />
                )}
                {ownershipState === ProductOwnerStates.WALLET_DISCONNECTED && (
                  <div className={styles.disconnectedWalletContainer}>
                    <WalletConnection />
                  </div>
                )}
              </div>
              {ownershipState === ProductOwnerStates.PENDING_APPROVAL &&
                !currentTransactionHash && (
                  <ProductOwnerApprove
                    nft={nft}
                    approvalRegistryContract={approvalRegistryContract}
                    currentTransactionHash={currentTransactionHash}
                    setCurrentTransactionHash={setCurrentTransactionHash}
                    saleContract={saleContract}
                    saleContractApprovalRefetch={saleContractApprovalRefetch}
                    invalidate={invalidate}
                  />
                )}
              {ownershipState === ProductOwnerStates.APPROVED && (
                <ProductOwnerListingSelection
                  setOwnershipState={setOwnershipState}
                />
              )}
              {ownershipState === ProductOwnerStates.RESERVE && (
                <ProductSetReserve
                  nft={nft}
                  setOwnershipState={setOwnershipState}
                />
              )}
              {ownershipState === ProductOwnerStates.FIXED_SALE && (
                <ProductSetBuyNow
                  nft={nft}
                  saleContract={saleContract}
                  setCurrentTransactionHash={setCurrentTransactionHash}
                  setOwnershipState={setOwnershipState}
                />
              )}
              {ownershipState === ProductOwnerStates.DELIST && (
                <ProductOwnerDelisting
                  nft={nft}
                  saleContract={saleContract}
                  setCurrentTransactionHash={setCurrentTransactionHash}
                  setOwnershipState={setOwnershipState}
                />
              )}
            </>
            {!!showPendingOnChain && (
              <Suspense>
                <ProductPendingOnChain
                  queryVariables={{ txtId: currentTransactionHash }}
                  onSuccess={() => updateOwnerShipStatesAfterSuccessOnChain()}
                  nft={nft}
                />
              </Suspense>
            )}
            {(ownershipState === ProductOwnerStates.FIXED_SALE_LISTED ||
              ownershipState === ProductOwnerStates.DELISTED ||
              ownershipState === ProductOwnerStates.RESERVE_LISTED) && (
              <SuccessView
                Icon={CheckCircleIcon}
                title={successTitle}
                message={successMessage}
                onClose={() => {
                  setShowDialog(false);
                  invalidate();
                  setDefaultOwnerShipStates();
                }}
              />
            )}
          </div>
        </MPDialog>
      )}
    </>
  );
}

export default ProductOwnerUpdateListing;
