import { useContext, useMemo } from 'react';
import { ErrorMessage, FormikContext } from 'formik';

import { joinClasses } from '@mp-frontend/core-utils';

import { MPFonts } from '../..';
import MPTextField, { MPTextFieldProps } from './MPTextField';

import * as styles from '../css/text_field/MPStyledTextField.module.css';

let _autoIncrement = 0;
function autoIncrement() {
  return ++_autoIncrement;
}

interface MPStyledTextFieldProps
  extends Omit<MPTextFieldProps, 'error' | 'InputProps'> {
  /**
   * Errors will cause jumps in the UI if the space they occupy isn't "empty" beforehand.
   * Default is not to reserve this space to maintain existing product input behavior, but
   * passing in false will lead 1 line + padding to reserved beforehand
   */
  dontReserveErrorSpace?: boolean;

  /**
   * We include formik error handling, even if there isn't a formik context, the default formik
   * behavior is to only show an error once the field has been touched. you can override any formik
   * error handling by passing in an error.
   */
  error?: string;

  /**
   * Classname joined with the internal text field component for css overriding.
   */
  textFieldClassName?: string;
}

/**
 * Notes:
 * Passing in any row property will automatically turn on multiline.
 *
 * We automatically use the formik context even if there is no formik context. This leaves a warning inside
 * the console
 *
 * Properties that used to be passed into InputProps now are passed in directly, inputMode, startAdornment.
 */
export default function MPStyledTextField({
  label,
  id,
  className,
  multiline,
  rows,
  error,
  minRows,
  maxRows,
  name,
  textFieldClassName,
  dontReserveErrorSpace = true,
  endAdornment = false,
  ...passedProps
}: MPStyledTextFieldProps) {
  const generatedId = useMemo(autoIncrement, []);

  const textFieldId =
    id || (label ? `MPStyledTextField_${generatedId}` : undefined);

  const multiLineProps =
    multiline || !!rows || !!minRows || !!maxRows
      ? {
          maxRows,
          minRows,
          multiline: true,
          rows,
        }
      : {};

  const formikContext = useContext(FormikContext);
  const errors = formikContext?.errors;
  const touched = formikContext?.touched;

  const hasError: boolean =
    !!error || (!!name && !!errors[name] && !!touched[name]);

  return (
    <div className={joinClasses(styles.container, className)}>
      {label ? (
        <label
          htmlFor={textFieldId}
          className={joinClasses(MPFonts.textSmallSemiBold, styles.label)}
        >
          {label}
        </label>
      ) : null}

      <MPTextField
        error={hasError}
        name={name}
        {...multiLineProps}
        id={textFieldId}
        className={joinClasses(styles.MPStyledTextField, textFieldClassName)}
        endAdornment={endAdornment}
        {...passedProps}
      />
      {!!(hasError || !dontReserveErrorSpace) && (
        <div
          className={joinClasses(MPFonts.textSmallMedium, styles.errorMessage)}
        >
          {!error ? (
            !!name && (
              <ErrorMessage name={name}>{(msg) => <>{msg}</>}</ErrorMessage>
            )
          ) : (
            <>{error}</>
          )}
        </div>
      )}
    </div>
  );
}
