import { useCallback, useRef, useState } from 'react';
import { useAccount } from 'wagmi';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { MpErrors } from 'types/__generated__/graphql';

import useSession from 'hooks/useSession';
import { AccountConnectionStatus } from 'utils/jwt/walletUtils';
import { NFTCardSelectionType } from 'utils/nftUtils';

export interface CreditCardPaymentFormState {
  cardHolderName: string;
  cardIsValid: boolean;
  cardSelectionType: NFTCardSelectionType;
  discountCode: string;
  remember: boolean;
  savedCardId: string;
}

export const DEFAULT_CREDIT_CARD_PAYMENT_FORM_STATE = {
  cardHolderName: '',
  cardIsValid: false,
  cardSelectionType: NFTCardSelectionType.New,
  discountCode: '',
  remember: false,
  savedCardId: '',
} as CreditCardPaymentFormState;

export const useCreditCardPaymentFormState = () => {
  const [state, setState] = useState<CreditCardPaymentFormState>({
    cardHolderName: '',
    cardIsValid: false,
    cardSelectionType: NFTCardSelectionType.New,
    discountCode: '',
    remember: false,
    savedCardId: '',
  });

  const update = useCallback(
    (newState: Partial<CreditCardPaymentFormState>) => {
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState]
  );

  return [state, update] as const;
};

export interface FormState {
  isDisabled: boolean;
  isPending: boolean;
  isSuccess: boolean;
  isValidating: boolean;
  mutationError: Error;
  simulationError: Error;
  validationError: Error;
}

export const DEFAULT_FORM_STATE = {
  isDisabled: false,
  isSuccess: false,
  isValidating: false,
  mutationError: undefined,
  simulationError: undefined,
  validationError: undefined,
} as FormState;

export const usePaymentFormState = () => {
  const state = useRef<FormState>({ ...DEFAULT_FORM_STATE });

  const update = useCallback(
    (newState: Partial<FormState>) => {
      state.current = { ...DEFAULT_FORM_STATE, ...newState };
    },
    [state]
  );

  const reset = useCallback(() => {
    state.current = { ...DEFAULT_FORM_STATE };
  }, [state]);

  return [state.current, update, reset] as const;
};

export const useStripePayment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const getPaymentMethodId = useCallback(
    async (
      cardSelectionType: NFTCardSelectionType,
      savedCardId: string,
      cardHolderName: string
    ): Promise<string> => {
      if (cardSelectionType === NFTCardSelectionType.Saved) {
        return savedCardId;
      }

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        throw new Error('Stripe not initialized');
      }

      const result = await stripe.createPaymentMethod({
        billing_details: {
          name: cardHolderName,
        },
        card: elements.getElement('card'),
        type: 'card',
      });

      if (result.error) {
        throw new Error(result.error?.message);
      }

      return result.paymentMethod.id;
    },
    [stripe, elements]
  );

  const withNextPaymentAction = useCallback(
    <VariablesType extends { requestData: unknown }, ResponseType>(
        mutation: (
          variables: VariablesType['requestData']
        ) => Promise<ResponseType>
      ) =>
      async (
        variables: VariablesType['requestData'] & { paymentMethodId: string }
      ): Promise<ResponseType> => {
        if (!stripe) {
          throw new Error('Stripe not initialized');
        }

        const { paymentMethodId, ...otherVariables } = variables;

        try {
          const mutationResult = await mutation({
            ...otherVariables,
            paymentMethodId,
          });
          return mutationResult;
        } catch (error) {
          if (
            error.name === MpErrors.StripePaymentRequiredAction &&
            error.additionalData?.clientSecret
          ) {
            const result = await stripe.handleCardAction(
              error.additionalData.clientSecret
            );
            if (result.error) {
              throw new Error(result.error?.message);
            }

            const mutationResult = await mutation({
              ...otherVariables,
              paymentIntentId: result.paymentIntent.id,
            });
            return mutationResult;
          }

          throw error;
        }
      },
    [stripe]
  );

  return { getPaymentMethodId, withNextPaymentAction } as const;
};

export const useWalletConnectionState = () => {
  const session = useSession();
  const { status: connectionStatus } = useAccount();
  const account = useAccount();

  const isConnected = connectionStatus === AccountConnectionStatus.Connected;
  const isConnectedToWrongNetwork =
    isConnected && session.contractNetwork !== account?.chainId;

  return {
    isConnected,
    isConnectedToWrongNetwork,
  } as const;
};

export default {
  useCreditCardPaymentFormState,
  usePaymentFormState,
  useWalletConnectionState,
};
