import { useEffect, useRef, useState } from 'react';
import { IntersectionObserverProps } from 'react-intersection-observer';

function useIntersection(
  element: HTMLElement,
  options: Pick<
    IntersectionObserverProps,
    'root' | 'rootMargin' | 'threshold'
  > = {}
) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (!element) {
      if (observerRef.current) observerRef.current.disconnect();
      setState(false);
      return undefined;
    }
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      ([entry]) => setState(entry.isIntersecting),
      {
        root: options.root ?? null,
        rootMargin: options.rootMargin ?? '0px',
        threshold: options.threshold,
      }
    );

    observerRef.current.observe(element);
    return () => {
      observerRef.current.unobserve(element);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  return isVisible;
}

export default useIntersection;
