import { Component } from 'react';

import { MpErrors } from 'types/__generated__/graphql';

import { SiteLoader } from 'components/SiteLoadingSuspense';
import { AUTH_TOKEN } from 'constants/Auth';

class SessionErrorBoundary extends Component<
  { children: any },
  { error: any }
> {
  static getDerivedStateFromError(error) {
    return { error };
  }

  static willCatch(error) {
    return (
      error?.name === MpErrors.JwtSessionExpired &&
      localStorage.getItem(AUTH_TOKEN)
    );
  }

  constructor(props) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    if (!this.isJWTTokenExpired()) {
      throw error;
    }
  }

  isJWTTokenExpired() {
    return SessionErrorBoundary.willCatch(this.state.error);
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  render() {
    if (this.isJWTTokenExpired()) {
      if (this.state.error?.additionalData?.isInitialPageLoad) {
        window.setTimeout(() => {
          localStorage.removeItem(AUTH_TOKEN);
          window.location.reload();
        }, 0);
        return <SiteLoader />;
      }
    }
    return this.props.children;
  }
}

export default SessionErrorBoundary;
