/**
 * @generated SignedSource<<1dd2ca40facd137ef94f75c55baca060>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationActionEnum = "BID_ACCEPTED" | "BID_RECEIVED" | "BID_OUTBID" | "GLOBAL_BID_ACCEPTED" | "GLOBAL_BID_RECEIVED" | "GLOBAL_BID_OUTBID" | "SALE_ACCEPTED" | "COMMENTED" | "LIKED" | "FOLLOWED" | "MINT_FROM_FOLLOWED_CREATOR" | "MINT_FROM_PURCHASED_BEFORE_CREATOR" | "MINT_OF_GENERATIVE_ART";
export type NotificationsSettingsQuery$variables = {};
export type NotificationsSettingsQuery$data = {
  readonly notificationsSettings: ReadonlyArray<{
    readonly pk: string;
    readonly id: string;
    readonly objectAction: NotificationActionEnum;
    readonly objectActionValue: string | null;
    readonly isMuted: boolean;
    readonly user: {
      readonly id: string;
    };
  }> | null;
};
export type NotificationsSettingsQuery = {
  variables: NotificationsSettingsQuery$variables;
  response: NotificationsSettingsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationMuteType",
    "kind": "LinkedField",
    "name": "notificationsSettings",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pk",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectAction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectActionValue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isMuted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountSessionType",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsSettingsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsSettingsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f25f41f54aff68abcc3ace434f1c73bb",
    "id": null,
    "metadata": {},
    "name": "NotificationsSettingsQuery",
    "operationKind": "query",
    "text": "query NotificationsSettingsQuery {\n  notificationsSettings {\n    pk\n    id\n    objectAction\n    objectActionValue\n    isMuted\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c952f963b2bde9617b58e5598a6f29e";

export default node;
