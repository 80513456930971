import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from './animations.module.css';

const MPAnimations = {
  Color: {
    DarkToLight: joinClasses('animatable', styles.textStyle),
    LightToDark: joinClasses('animatable', styles.lightToDark),
  } as const,

  Visibility: {
    Hidden: styles.hidden,
    Visible: joinClasses(styles.hidden, styles.visible),
  } as const,
} as const;

export default MPAnimations;
