/* eslint-disable import/prefer-default-export */
import Session from '../../Session';

// Request is that all empty strings be turned into nulls, to facilitate GTM setting of default values.
function recursivelySetEmptyStringsToNull(obj: Record<string, any>) {
  if (Array.isArray(obj)) {
    return obj.map((item) => recursivelySetEmptyStringsToNull(item));
  }

  const res = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] != null) {
      res[key] = recursivelySetEmptyStringsToNull(obj[key]);
    } else if (obj[key] === '') {
      res[key] = null;
    } else {
      res[key] = obj[key];
    }
  });
  return res;
}

/* eslint-disable camelcase */
declare global {
  interface Window {
    analytics_vars: {
      login_type: string;
      user_id: string;
    };
    dataLayer: Array<Record<string, any>>;
  }
}
/* eslint-enable camelcase */

window.dataLayer = window.dataLayer || [];

window.analytics_vars = window.analytics_vars || {
  login_type: null,
  user_id: null,
};

async function genGlobalParams() {
  const session = await Session.awaitSessionData();
  return {
    _clear: true,
    dl_source: 'mp',
    login_type: session.lastLogin,
    user_id: session.account?.pk,
  };
}

export async function tagPush(event, obj) {
  const baseParams = await genGlobalParams();
  window.dataLayer.push({
    event,
    ...baseParams,
    ...recursivelySetEmptyStringsToNull(obj),
  });
}
