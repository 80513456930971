/**
 * Docs indicate that the "correct" way to check is to instanceof the error and walk up the custom trail and ducktype.
 * - However our contracts gives minimal error info
 * - instanceof comparison to Viem types is flaky, so we can only compare exactly against the short message, which is only
 *   good on a per function basis, and could break whenever there is an update to any of the wagmi stack.
 */

import { InsufficientFundsError } from 'viem';

const IsGlobalContractError = {
  BidPriceMustBePositive: (err: any) =>
    // F our contracts
    err?.reason === 'send msg.value'
      ? true
      : err?.cause && err !== err?.cause
      ? IsGlobalContractError.BidPriceMustBePositive(err.cause)
      : false,
  InsufficientFunds: (err: any) =>
    (err?.shortMessage &&
      err.shortMessage ===
        'The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account.') ||
    err instanceof InsufficientFundsError
      ? true
      : err?.cause && err !== err.cause
      ? IsGlobalContractError.InsufficientFunds(err.cause)
      : false,
  InvalidInput: (err: any) =>
    err?.reason === 'Error msg.value'
      ? true
      : err?.cause && err !== err?.cause
      ? IsGlobalContractError.InvalidInput(err.cause)
      : false,
  SenderSellerMismatch: (err: any) =>
    err?.reason === 'msg.sender != seller'
      ? true
      : err.cause && err !== err.cause
      ? IsGlobalContractError.SenderSellerMismatch(err.cause)
      : false,
};

export default IsGlobalContractError;
