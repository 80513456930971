import { ReactNode } from 'react';

import { MPDivider, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';

interface SectionProps {
  children: ReactNode;
  title: string;
}

export default function Section({ children, title }: SectionProps) {
  return (
    <>
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[24])}>
        <div
          className={joinClasses(MPFonts.headline4, CSSGlobal.Cursor.Default)}
        >
          {title}
        </div>

        {children}
      </div>

      <MPDivider
        className={joinClasses(CSSMargin.BOTTOM[24], CSSMargin.TOP[24])}
      />
    </>
  );
}
