import { type Config, useSimulateContract, useWriteContract } from 'wagmi';
import { type WriteContractMutate } from 'wagmi/query';

export default function useCreateContractActionObject(
  args: Parameters<WriteContractMutate<Config>>[0]
) {
  const simulateContractObj = useSimulateContract({
    query: {
      retry: false,
    },
    ...args,
  });
  const { writeContract, writeContractAsync, ...writeContractRemaining } =
    useWriteContract();
  const writeContractObj = {
    write: () => writeContract(args),
    writeAsync: () => writeContractAsync(args),
    ...writeContractRemaining,
  };

  return {
    mutate: writeContractObj,
    simulate: simulateContractObj,
  };
}
