// There's no obvious analogue for seeking on a progress bar for jsx-ally.
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { MouseEvent, useCallback } from 'react';

import { MPFonts, MPIconButtonThemes } from '@mp-frontend/core-components';
import {
  FullScreenIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  RemoveIcon,
  UnmuteIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import DropVideoPlayerActionIcon, {
  DropVideoPlayerActionIconSize,
} from './ActionIcon';
import { DropVideoPlayerControlsProps } from './DropVideoPlayerControlsProps';
import DropVideoPlayerMode from './mode';
import StandardBar from './StandardBar';

import * as containerStyles from 'css/pages/drops/components/VideoPlayer/index.module.css';
import * as styles from 'css/pages/drops/components/VideoPlayer/PlayerControls.module.css';

export enum DropVideoPlayerStatus {
  FullScreen = 'fullscreen',
  Mute = 'mute',
  Pause = 'pause',
  Play = 'play',
  Progress = 'progress',
  Unmute = 'unmute',
}

export default function DropVideoPlayerControls({
  mode,
  player,
  screenHandle,
  isPlaying,
  setIsPlaying,
  isMuted,
  setIsMuted,
  playerTimeState,
  title,
  onControlClick,
  onOverlayClick,
}: DropVideoPlayerControlsProps) {
  const [progressBar, setProgressBarRef] = useRefState<HTMLDivElement>(null);

  const handleProgressClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const rect = progressBar?.getBoundingClientRect();
      if (!rect) return;
      event.stopPropagation();
      player.seekTo((event.clientX - rect.x) / rect.width);
      onControlClick?.(DropVideoPlayerStatus.Progress);
    },
    [progressBar, player, onControlClick]
  );

  return (
    <>
      {mode === DropVideoPlayerMode.LARGE && (
        <div className={containerStyles.overlay} onClick={onOverlayClick}>
          <div className={joinClasses(styles.navBar)}>
            {!!screenHandle.active && (
              <DropVideoPlayerActionIcon
                onClick={(event) => {
                  event.stopPropagation();
                  screenHandle.exit();
                }}
                Icon={RemoveIcon}
                theme={MPIconButtonThemes.DARK}
              />
            )}
          </div>
          <div className={CSSGlobal.Flex.CenteredRow}>
            <DropVideoPlayerActionIcon
              size={DropVideoPlayerActionIconSize.LARGE}
              onClick={(event) => {
                event.stopPropagation();
                setIsPlaying((state) => !state);
                onControlClick?.(
                  isPlaying
                    ? DropVideoPlayerStatus.Pause
                    : DropVideoPlayerStatus.Play
                );
              }}
              Icon={isPlaying ? PauseIcon : PlayIcon}
            />
          </div>
          <div className={joinClasses(styles.largeActionBar)}>
            <div
              className={joinClasses(
                CSSGlobal.Flex.Col,
                styles.largeActionBarLeft,
                MPFonts.textSmallMedium,
                CSSGap[4]
              )}
            >
              <div className={CSSGlobal.Ellipsis}>{title || <>&nbsp;</>}</div>
              <div
                ref={setProgressBarRef}
                onClick={handleProgressClick}
                className={styles.progressTrack}
              >
                <div
                  className={styles.progressBar}
                  style={{ width: `${playerTimeState.percentPlayed}%` }}
                />
              </div>
              {playerTimeState.seekDisplay}
            </div>
            <div className={joinClasses(styles.largeActionBarRight)}>
              <DropVideoPlayerActionIcon
                onClick={(event) => {
                  event.stopPropagation();
                  setIsMuted((state) => !state);
                  onControlClick?.(
                    isMuted
                      ? DropVideoPlayerStatus.Unmute
                      : DropVideoPlayerStatus.Mute
                  );
                }}
                Icon={isMuted ? MuteIcon : UnmuteIcon}
              />
              {!screenHandle.active && (
                <DropVideoPlayerActionIcon
                  onClick={() => {
                    screenHandle.enter();
                    onControlClick?.(DropVideoPlayerStatus.FullScreen);
                  }}
                  Icon={FullScreenIcon}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {mode === DropVideoPlayerMode.STANDARD && (
        <StandardBar
          title={title}
          screenHandle={screenHandle}
          setIsPlaying={setIsPlaying}
          isPlaying={isPlaying}
        />
      )}
    </>
  );
}
