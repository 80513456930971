import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

interface ProductTitleProps {
  title: string;
}

function ProductTitle({ title }: ProductTitleProps) {
  return (
    <div
      className={joinClasses(
        MPFonts.productDetailsTitle,
        'textOverflowEllipsis'
      )}
    >
      {title}
    </div>
  );
}

export default ProductTitle;
