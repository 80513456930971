import { styled } from '@mui/material';

import MPAnimations from '../css/MPAnimations';

const InnerButton = styled('span')`
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: underline;
  text-transform: inherit;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export default function MPInlineTextLinkButton({ children, ...passedProps }) {
  return (
    <InnerButton className={MPAnimations.Color.DarkToLight} {...passedProps}>
      {children}
    </InnerButton>
  );
}
