.filter {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
}

.searchWrapper {
  display: flex;
  border: 1px solid transparent;
  width: 100%;
  background-color: var(--color-grey100);
  border-radius: 6px;
  margin: 14px 0 0;
}

.searchWrapper.isFocused {
  background-color: var(--color-commonWhite);
}

.searchIconWrapper {
  display: flex;
  margin: 14px 10px 12px 18px;
}

.searchIconWrapper > .searchIconSVG {
  width: 15px;
  height: 15px;
  cursor: default;
}

.results {
  border-radius: 6px;
  border: 1px solid var(--color-grey100);
  height: 178px;
}

.userImg {
  border-radius: 50%;
  height: 46px;
  width: 46px;
}

.collabRow {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-bottom: 1px solid var(--color-grey100);
}

.addCollaboratorButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--color-grey200);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addCollaboratorButton:hover {
  border: 1px solid var(--color-grey300);
}

.collabName {
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.noSearchResults {
  text-align: center;
  margin-top: 20px;
}

.percentRow {
  padding: 36px 6px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.percentTrackSection {
  width: 100%;
}

.changeCollaboratorButtonWrapper {
  margin-top: 20px;
}

.collabUserProfileSpan {
  display: inline-flex;
  align-items: center;
}

.collabUserProfileImg {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}