import { ReactNode, useCallback, useMemo } from 'react';

import { MPColorValue, MPFonts } from '@mp-frontend/core-components';
import { TimerIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { TagTypeEnum } from 'types/__generated__/graphql';

import AuctionTimer from 'components/auction/AuctionTimer';
import ROUTES from 'constants/Routes';
import useCountdown from 'hooks/useCountdown';
import Embed from 'pages/product/productPreview/Embed';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { HomepageNFTType, NFTType } from 'types/graphql/NFT';
import { generateShortUsdPriceString } from 'utils/currency/generatePricing';
import generateEditionString from 'utils/generateEditionText';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';
import generateTitleText from 'utils/generateTitleText';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';
import { isNFTDynamic, MIN_CHARGE_AMOUNT_USD } from 'utils/nftUtils';

import InViewVideo from './InViewVideo';
import StandardCard from './StandardCard';

import * as styles from 'css/components/cards/ArtworkCard.module.css';

const MAX_FOOTER_LABELS = 3;

const TagName: Record<TagTypeEnum, string> = {
  ENDING_SOON: 'Ending Soon',
  FEATURED: 'Featured',
  NEW_MINTS: 'New Mints',
  RANKED_AUCTION: 'Ranked Auction',
  RECENTLY_SOLD: 'Recently Sold',
  RECENT_BID: 'Recent Bid',
};

export interface ArtworkCardProps {
  nft: Pick<NFTType, 'pk' | 'printEdition'> & {
    contract: Pick<NFTType['contract'], 'isExternal'>;
    currentOwner: Pick<NFTType['currentOwner'], 'fullName' | 'id'>;
    listing: Pick<
      NFTType['listing'],
      | 'availableForPurchase'
      | 'hasReservePrice'
      | 'lowestAskInUsd'
      | 'productAuction'
      | 'productSlug'
      | 'rankedAuction'
      | 'reservePriceInUsd'
    > & {
      liveBid: Pick<NFTType['listing']['liveBid'], 'bidInUsd'>;
    } & {
      lastSale: Pick<NFTType['listing']['lastSale'], 'usdPriceAtSale'>;
    };
    metadata: Pick<
      NFTType['metadata'],
      | 'artworkImageUrl'
      | 'artworkVideoUrl'
      | 'hasVideo'
      | 'highResImage'
      | 'id'
      | 'rawfileExtension'
      | 'title'
      | 'totalSupply'
    > & {
      author: Pick<NFTType['metadata']['author'], 'fullName' | 'id'>;
    };
    tag?: HomepageNFTType['tag'];
  };
  className?: string;
  disableBrowserNavigate?: boolean;
  hideDetails?: boolean;
  hideTag?: boolean;
}

function ArtworkCard({
  className,
  nft,
  disableBrowserNavigate = false,
  hideDetails = false,
  hideTag = false,
}: ArtworkCardProps) {
  const track = useHomepageGTM();

  const handleClick = useCallback(
    () => track.clickCard(CardType.ArtworkCard, nft.pk, nft.metadata.title),
    [track, nft.pk, nft.metadata.title]
  );

  const auction = nft.listing.productAuction?.endsAt
    ? nft.listing.productAuction
    : nft.listing.rankedAuction;

  const { days, hours, minutes, seconds } = useCountdown({
    endDate: auction?.endsAt,
  });
  const showAuction = auction && (hours > 0 || minutes > 0 || seconds > 0);
  const showTag = !!(nft.tag && !hideTag);
  const showDetails = !hideDetails;

  const footerLabels = useMemo(() => {
    const labels = [
      {
        name: 'Edition',
        value: generateEditionString(
          nft.printEdition,
          nft.metadata.totalSupply
        ),
      },
    ] as { name: string; value: ReactNode }[];
    if (!nft) return labels;

    if (
      nft.listing.availableForPurchase &&
      nft.listing.lowestAskInUsd &&
      nft.listing.lowestAskInUsd >= MIN_CHARGE_AMOUNT_USD
    )
      labels.push({
        name: 'Buy Now',
        value: generateShortUsdPriceString(nft.listing.lowestAskInUsd),
      });
    if (
      nft.listing.liveBid?.bidInUsd &&
      nft.listing.liveBid.bidInUsd >= MIN_CHARGE_AMOUNT_USD
    )
      labels.push({
        name: 'Current Bid',
        value: generateShortUsdPriceString(nft.listing.liveBid.bidInUsd),
      });
    if (
      nft.listing.rankedAuction?.highestBid?.bidInUsd &&
      nft.listing.rankedAuction.highestBid.bidInUsd >= MIN_CHARGE_AMOUNT_USD
    )
      labels.push({
        name: 'Highest Bid',
        value: generateShortUsdPriceString(
          nft.listing.rankedAuction.highestBid.bidInUsd
        ),
      });

    if (showAuction && labels.length < MAX_FOOTER_LABELS) {
      labels.push({
        name: 'Time Left',
        value: (
          <div
            className={joinClasses(
              CSSGlobal.Flex.RowCenterAlign,
              CSSGlobal.Flex.NoWrap,
              CSSGap[2]
            )}
          >
            <TimerIcon
              fill={MPColorValue.CommonBlack}
              fontSize="18"
              stroke={MPColorValue.CommonWhite}
            />
            <AuctionTimer
              {...{ days, hours, minutes, seconds }}
              className={CSSGlobal.Ellipsis}
            />
          </div>
        ),
      });
    }
    if (
      nft.listing.hasReservePrice &&
      nft.listing.reservePriceInUsd &&
      nft.listing.reservePriceInUsd >= MIN_CHARGE_AMOUNT_USD &&
      labels.length < MAX_FOOTER_LABELS
    ) {
      labels.push({
        name: 'Reserve Price',
        value: generateShortUsdPriceString(nft.listing.reservePriceInUsd),
      });
    }
    if (
      nft.listing.lastSale &&
      nft.listing.lastSale.usdPriceAtSale &&
      nft.listing.lastSale.usdPriceAtSale >= MIN_CHARGE_AMOUNT_USD &&
      labels.length < MAX_FOOTER_LABELS &&
      nft.tag === TagTypeEnum.RecentlySold
    ) {
      labels.push({
        name: 'Last Sale',
        value: generateShortUsdPriceString(nft.listing.lastSale.usdPriceAtSale),
      });
    }

    if (
      nft.currentOwner?.id &&
      nft.currentOwner.id !== nft.metadata.author.id &&
      labels.length < MAX_FOOTER_LABELS
    ) {
      labels.push({
        name: 'Collector',
        value: generateFormattedUserFullName(nft.currentOwner.fullName),
      });
    }

    return labels;
  }, [showAuction, days, hours, minutes, seconds, nft]);

  return (
    <StandardCard
      to={ROUTES.NFT(nft.listing.productSlug)}
      className={joinClasses(
        'commonPadding',
        CSSGlobal.Flex.CenteredCol,
        CSSGap[32],
        styles.artworkCardContainer,
        {
          [styles.interactable]: !disableBrowserNavigate,
          [styles.inverted]: showAuction,
          [styles.centered]: !showDetails,
        },
        className
      )}
      disableBrowserNavigate={disableBrowserNavigate}
      onClick={handleClick}
    >
      {!!showTag && (
        <div
          className={joinClasses(
            MPFonts.textSmallMedium,
            styles.artworkCardSecondaryText
          )}
        >
          {TagName[nft.tag]}
        </div>
      )}

      <div
        className={joinClasses(
          CSSGlobal.Flex.CenteredCol,
          styles.artworkAssetContainer,
          !showTag || !showDetails ? styles.large : styles.medium
        )}
      >
        {nft.metadata.hasVideo ? (
          <InViewVideo
            className={joinClasses(
              styles.artworkAsset,
              styles.artworkAssetMedia
            )}
            poster={nft.metadata.artworkImageUrl}
            src={nft.metadata.artworkVideoUrl}
          />
        ) : isNFTDynamic(nft.metadata.rawfileExtension) ? (
          <Embed
            className={joinClasses(
              styles.artworkAsset,
              styles.artworkAssetEmbedded
            )}
            media={{
              hasVideo: nft.metadata.hasVideo,
              highResUrl: nft.metadata.highResImage,
              id: nft.metadata.id,
              lowResUrl: nft.metadata.artworkImageUrl,
              mediumResUrl: nft.metadata.artworkImageUrl,
              rawfileExtension: nft.metadata.rawfileExtension,
              videoUrl: nft.metadata.artworkVideoUrl,
            }}
          />
        ) : (
          <img
            src={nft.metadata.artworkImageUrl}
            alt={nft.metadata.title}
            loading="lazy"
            className={joinClasses(
              styles.artworkAsset,
              styles.artworkAssetMedia
            )}
          />
        )}
      </div>

      {!!showDetails && (
        <div
          className={joinClasses(
            CSSGlobal.Flex.Col,
            CSSGlobal.Ellipsis,
            CSSGap[18]
          )}
        >
          <div className={joinClasses(CSSGlobal.Flex.CenteredCol, CSSGap[4])}>
            <div
              className={joinClasses(
                MPFonts.textNormalMedium,
                CSSGlobal.Ellipsis
              )}
            >
              {generateTitleText(nft)}
            </div>
            <div
              className={joinClasses(
                CSSGlobal.Ellipsis,
                MPFonts.textNormalMedium,
                styles.artworkCardSecondaryText
              )}
            >
              {generateFormattedUserFullName(nft.metadata.author.fullName)}
            </div>
          </div>

          <div className={joinClasses(CSSGlobal.Flex.CenteredRow, CSSGap[18])}>
            {footerLabels.map(({ name, value }) => (
              <div
                key={name}
                className={joinClasses(CSSGlobal.Flex.Col, CSSGap[2])}
              >
                <div
                  className={joinClasses(
                    MPFonts.textSmallMedium,
                    CSSGlobal.Flex.CenteredRow,
                    styles.artworkCardSecondaryText
                  )}
                >
                  {name}
                </div>
                <div
                  className={joinClasses(
                    MPFonts.textSmallMedium,
                    CSSGlobal.Flex.CenteredRow,
                    styles.artworkCardFooterValue
                  )}
                >
                  <span className={CSSGlobal.Ellipsis}>{value}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </StandardCard>
  );
}

export default ArtworkCard;
