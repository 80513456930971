.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  max-width: 80vw;
  max-height: 100%;

  @media (--mq-is-mobile) {
    max-width: 95vw;
    max-height: 90%;
    position: relative;
    top: 10px;
  }
}

.currentMedia {
  align-items: center;
  color: inherit;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-height: 80%;
  justify-content: center;
  cursor: pointer;
  position: relative;

  &:link {
    text-decoration: none;
    color: var(--color-commonBlack);
    cursor: pointer;
  }

  & > img,
  & > video {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 1;

    @media (--mq-is-mobile) {
      max-height: 75%;
    }
  }

  @media (--mq-is-mobile) {
    overflow: initial;
    max-height: 100%;
  }

  & > .loader {
    display: none;
  }

  &.isLoading {
    > .loader {
      display: flex;
    }

    > img,
    > video {
      opacity: 0;
    }
  }
}

.productRelatedSlide {
  justify-content: center;
  align-items: center;
  height: 95%;
  position: relative;
  top: var(--pdp-padding);

  @media (--mq-is-mobile) {
    top: 0;
  }

  &.productRelatedSlideFirst {
    justify-content: flex-start;
    @media (--mq-is-mobile) {
      justify-content: center;
    }
  }
}

.productRelatedTitle {
  margin: 8px 0;
  width: 100%;
  text-align: center;
  overflow-wrap: break-word;
  word-break: break-word;
}

.productRelatedAuthorName {
  width: 100%;
  text-align: center;
}

@media (--mq-is-mobile) {
  .productRelatedTitle {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
