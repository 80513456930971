function WhatIsGasContent() {
  return (
    <div>
      <p>
        MakersPlace is run on the Ethereum blockchain, which is considered a
        world computer, operated by many computers across the world. This means
        that interacting with it incurs a transaction fee called&nbsp;
        <span className="bold">gas</span> to compensate the operators of
        Ethereum.
      </p>
      <p>
        MakersPlace has historically paid this gas fee when the blockchain is
        not congested. However, due to recent high demand on the blockchain, we
        require gas fees to be paid by the artist or collector.
      </p>
      <p>
        When you perform any site action that relies on the blockchain (e.g
        creation, setting prices, sending, etc) you will need to pay the
        blockchain fee by clicking the &#34;Speed Up&#34; icon beside your
        pending action. Your transaction will complete within a few minutes.
      </p>
    </div>
  );
}

export default {
  Component: WhatIsGasContent,
  title: 'How do you "Speed Up" a transaction?',
};
