import Lightbox from 'yet-another-react-lightbox';

import {
  MPIconButton,
  MPIconButtonThemes,
  useIsMobile,
} from '@mp-frontend/core-components';
import {
  MagnifyingMinusIcon,
  MagnifyingPlusIcon,
  RemoveIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { DefaultLoader } from 'components/DefaultSuspense';
import { isNFTDynamic, isNFTGif, NFTMedia } from 'utils/nftUtils';

import Embed from './Embed';
import { getRenderedMediaSize } from './Image';
import Video, { VideoProps } from './Video';
import { MediaSize } from '.';

import * as styles from 'css/pages/product/ProductPreview.module.css';

import Zoom from 'yet-another-react-lightbox/plugins/zoom';

interface FullScreenPreviewProps {
  loaded: boolean;
  media: NFTMedia;
  onClose: () => void;
  open: boolean;
  size: MediaSize;
  videoProps: VideoProps & { ref: (instance: HTMLVideoElement | null) => void };
}

export default function FullScreenPreviewV2({
  open,
  loaded,
  media,
  size,
  videoProps,
  onClose,
}: FullScreenPreviewProps) {
  const isMobile = useIsMobile();

  return (
    <>
      <Lightbox
        open={open}
        close={onClose}
        className={styles.currentMediaFullScreen}
        plugins={[Zoom]}
        controller={{
          closeOnBackdropClick: true,
          closeOnPullDown: true,
        }}
        slides={[
          (media.hasVideo
            ? {
                src: media.videoUrl,
                type: 'video',
              }
            : {
                src: media.highResUrl,
                type: isNFTDynamic(media.rawfileExtension) ? 'html' : 'image',
              }) as any,
        ]}
        render={{
          buttonClose: () => (
            <MPIconButton
              key="close"
              theme={
                isMobile ? MPIconButtonThemes.GRAY : MPIconButtonThemes.DARK
              }
              onClick={onClose}
            >
              <RemoveIcon alt="Close Full Screen" />
            </MPIconButton>
          ),
          buttonNext: () => null,
          buttonPrev: () => null,
          buttonZoom: (zoomRef) =>
            zoomRef.disabled || isNFTGif(media.rawfileExtension) ? null : (
              <MPIconButton
                theme={
                  isMobile ? MPIconButtonThemes.GRAY : MPIconButtonThemes.DARK
                }
                onClick={(event) => {
                  event.preventDefault();
                  zoomRef.zoom === 1 ? zoomRef.zoomIn() : zoomRef.zoomOut();
                }}
              >
                {zoomRef.zoom === 1 ? (
                  <MagnifyingPlusIcon alt="Zoom In" />
                ) : (
                  <MagnifyingMinusIcon alt="Zoom Out" />
                )}
              </MPIconButton>
            ),
          iconLoading: () => (
            <DefaultLoader
              position="absolute"
              className={joinClasses(styles.loader, {
                [styles.isLoading]: !loaded,
              })}
            />
          ),
          slide: ({ slide }) =>
            ({
              html: <Embed media={media} isFullScreen />,
              video: (
                <Video
                  {...videoProps}
                  {...getRenderedMediaSize(size, {
                    height: window.innerHeight,
                    width: window.innerWidth,
                  })}
                  src={media.videoUrl}
                  isFullScreen
                />
              ),
            }[slide.type] || null),
        }}
        zoom={{ zoomInMultiplier: 3 }}
        carousel={{ finite: true }}
        animation={{ fade: 300 }}
      />
    </>
  );
}
