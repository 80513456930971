import { useCallback, useEffect, useState } from 'react';
import { debounce, noop } from 'lodash';
import { useInView } from 'react-intersection-observer';

interface UseVideoProps {
  autoPlayOnLoad: boolean;
}

function useVideo({ autoPlayOnLoad }: UseVideoProps) {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoHovered, setVideoHovered] = useState(false);
  const { ref, inView, entry } = useInView({
    delay: 100,
    fallbackInView: true,
    skip: !autoPlayOnLoad,
    trackVisibility: true,
    triggerOnce: true,
  });
  const videoNode = entry?.target as HTMLVideoElement;

  const onVideoLoaded = () => setVideoLoaded(true);

  const setLoaded = useCallback(() => {
    if (videoNode) onVideoLoaded();
  }, [videoNode]);

  const playVideo = (shouldPlay: boolean) => {
    if (shouldPlay) {
      setVideoHovered(true);
    }
  };

  const delayedMouseEnterHandler = debounce(
    (shouldPlay) => playVideo(shouldPlay),
    250
  );

  const onMouseEnterHandler = () => delayedMouseEnterHandler(true);

  // If the video is hovered and the content is ready, play it
  useEffect(() => {
    if (videoNode && videoHovered && videoLoaded) {
      videoNode.play().catch(noop);
    }
  }, [videoHovered, videoNode, videoLoaded]);

  useEffect(() => {
    if (videoNode?.readyState) {
      setLoaded();
      if (inView && autoPlayOnLoad) {
        videoNode.play().catch(noop);
      }
    }
  }, [videoNode, videoNode?.readyState, setLoaded, autoPlayOnLoad, inView]);

  return { onMouseEnterHandler, setLoaded, videoRef: ref };
}

export default useVideo;
