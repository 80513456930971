import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useSimpleDialogController from 'hooks/useSimpleDialogController';
import WhatIsGasDialog from 'pages/faq/blockChainAndDigitalWallets/whatIsGas/whatIsGasDialog';

import * as styles from 'css/pages/creator-guidelines/content.module.css';

export default function CreatorGuidelinesContent() {
  const [isWhatIsGasDialogOpen, openWhatIsGasDialog, closeWhatIsGasDialog] =
    useSimpleDialogController({ preventDefault: true });
  return (
    <>
      <div className={joinClasses(MPFonts.bodyMedium, styles.intro)}>
        <h2 className={MPFonts.titleMedium}>5-Step Creator Guidelines</h2>
        <p className={MPFonts.bodyLarge}>
          <span className="bold">
            Your fans and customers might love your work
          </span>
          , but in order to sell your creations you&#39;ll need to connect with
          your customer. To best make use of MakersPlace to sell your unique
          digital creations, here are <span className="bold">5 steps</span> we
          ask you to follow.
        </p>
      </div>
      <div className={styles.guideline}>
        <h3 className={MPFonts.titleSmall}>
          1. Upload your Best Digital Creations
        </h3>
        <p>
          <span className="bold">Put your best foot forward.</span> As you first
          begin to build your store on MakersPlace you want to make the best
          first impression with your audience. We recommend uploading only your
          best and highest quality version of your creations.
        </p>
        <p>
          As an added bonus, since MakersPlace helps ascribe authenticity and
          ownership over your digital work, once it&#39;s uploaded to the
          blockchain you&#39;re also protecting your best digital works as well.
        </p>
      </div>
      <div className={styles.guideline}>
        <h3 className={MPFonts.titleSmall}>
          2. Share the Story Behind each Creation
        </h3>
        <p>
          <span className="bold">
            Stories help build emotional connections.
          </span>
          &nbsp; For every creation you upload and sell on MakersPlace, we
          recommend sharing the background and story behind your work. This is
          one of the most important factors that a customer considers when
          making a purchase. Here are some suggestions for your story:
        </p>
        <ol className="indent">
          <li>
            <p>What is your creation about?</p>
          </li>
          <li>
            <p>What inspired you to create this piece?</p>
          </li>
          <li>
            <p>How did you create your work? Briefly describe the process.</p>
          </li>
        </ol>
        <p>
          You don&#39;t need to answer all of these questions, but&nbsp;
          <span className="bold">don&#39;t leave your story empty</span>.
        </p>
        <p>
          Finally keep it concise and&nbsp;
          <span className="bold">be yourself. This is your story</span>.
        </p>
      </div>
      <div className={styles.guideline}>
        <h3 className={MPFonts.titleSmall}>3. Set Reasonable Prices</h3>
        <ol className="indent">
          <li>
            <p>
              <span className="bold">Price above production costs.</span> Did
              you know that&nbsp;
              <span className={MPColorClass.Grey600}>
                <a
                  href="/faq/#gas-fees"
                  className="invisibleAnchor"
                  target="_BLANK"
                  onClick={openWhatIsGasDialog}
                >
                  there are production costs associated with creating a unique
                  digital creation
                </a>
              </span>
              ? MakersPlace is currently subsidizing all creation fees,
              that&#39;s why we recommend pricing all of your creations
              above&nbsp;
              <span className="bold">0.03 ETH</span>.
            </p>
          </li>
          <li>
            <p>
              <span className="bold">
                Price based on your brand and popularity.
              </span>
              &nbsp; If you&#39;re just getting started as a creator you&#39;ll
              want to set a lower price until you gain popularity. If you&#39;ve
              established a brand then feel free to price accordingly. Here is a
              helpful chart:
            </p>
          </li>
        </ol>
        <img
          src="/static/img/pricing-guide.png"
          className={styles.pricingGuideImg}
        />
      </div>
      <div className={styles.guideline}>
        <h3 className={MPFonts.titleSmall}>
          4. Keep your Digital Creations Unique
        </h3>
        <p>
          <span className="bold">
            You&#39;re selling unique digital creations.
          </span>
          &nbsp; MakersPlace allows you to sell truly unique digital creations,
          however you may be tempted to upload or sell additional copies of your
          digital work, please don&#39;t. Your customers trust that they&#39;re
          buying a limited edition and unique version of your digital work,
          you&#39;ll break this trust if they find duplicate copies being sold
          online.
        </p>
      </div>
      <div className={styles.guideline}>
        <h3 className={MPFonts.titleSmall}>5. Share with your Fans!</h3>
        <p>
          <span className="bold">Share your amazing work with the world.</span>
          &nbsp; Your fans love your unique work, so share it with all of your
          friends and fans across as many social platforms as possible. We
          recommend sharing your work on the following social platforms:
        </p>
        <ol className="indent">
          <li>
            <p>
              <a href="https://instagram.com" target="_BLANK" rel="noreferrer">
                Instagram
              </a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://facebook.com" target="_BLANK" rel="noreferrer">
                Facebook
              </a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://twitter.com" target="_BLANK" rel="noreferrer">
                Twitter
              </a>
            </p>
          </li>
          <li>
            <p>
              <a href="https://pinterest.com" target="_BLANK" rel="noreferrer">
                Pinterest
              </a>
            </p>
          </li>
        </ol>
      </div>
      <WhatIsGasDialog
        isOpen={isWhatIsGasDialogOpen}
        cancel={closeWhatIsGasDialog}
      />
    </>
  );
}
