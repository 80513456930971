/**
 * @generated SignedSource<<e1aaca78e838b441acf57f038f2bd028>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateReservePriceArguments = {
  availableForSale: boolean;
  productId: number;
  reserveCurrency: string;
  reservePrice: number;
  auctionDate?: string | null;
};
export type ListingCreateReservePriceMutation$variables = {
  request_data: CreateReservePriceArguments;
};
export type ListingCreateReservePriceMutation$data = {
  readonly createReservePrice: {
    readonly success: boolean | null;
  } | null;
};
export type ListingCreateReservePriceMutation = {
  variables: ListingCreateReservePriceMutation$variables;
  response: ListingCreateReservePriceMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request_data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "request_data"
      }
    ],
    "concreteType": "CreateReservePriceMutation",
    "kind": "LinkedField",
    "name": "createReservePrice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingCreateReservePriceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingCreateReservePriceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3c8922acbf26fa223ddde8d52ce6b214",
    "id": null,
    "metadata": {},
    "name": "ListingCreateReservePriceMutation",
    "operationKind": "mutation",
    "text": "mutation ListingCreateReservePriceMutation(\n  $request_data: CreateReservePriceArguments!\n) {\n  createReservePrice(requestData: $request_data) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "0970717515c536bae40bcae839eca5bf";

export default node;
