import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const TwitterIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M19.3986 7.45298C19.0455 7.47866 18.7268 7.50433 18.5201 7.52144C17.5813 6.32335 16.367 5.81844 14.8856 6.05805C12.0435 6.71701 11.8541 9.52398 11.8541 9.52398L5.5067 7.05932C5.45502 7.17057 5.41196 7.27327 5.3689 7.38452C4.97273 8.36011 4.55072 9.44696 4.75742 10.5509C5.13636 12.5449 6.19569 13.3579 8.18517 14.6073L3.5 18.1503C3.5 18.1503 5.93732 19.9389 9.02057 19.9988C9.58038 20.0073 10.166 19.9731 10.7517 19.8447C10.8033 19.8362 10.8464 19.819 10.8895 19.8105C13.0598 19.3912 14.989 18.3214 16.4703 16.8238C18.2531 15.0267 19.3986 12.5962 19.5364 9.90052L21.5 7.29894C21.5 7.29894 20.3287 7.38452 19.39 7.46154L19.3986 7.45298Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default TwitterIcon;
