import { usePreloadedQuery } from 'react-relay';
import { Hash } from 'viem';

import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import {
  ContractNameEnum,
  CreatorContractType,
} from 'types/__generated__/graphql';

import useTokenContract from 'hooks/contracts/useTokenContract';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import PendingApprovalsManager from '../PendingApprovalsManager';
import Approval from './Approval';

interface VaultCoreApprovalProps {
  contract: CreatorContractType;
  walletAddress: Hash;
  className?: string;
  contractTitle?: string;
}

function SetVaultCoreButton({
  contractQuery,
  contract,
  walletAddress,
  className,
  contractTitle = '',
}: {
  contractQuery: WithLoadQueryProps<NFTContractQuery>;
} & VaultCoreApprovalProps) {
  const { nftContract: vaultCoreContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      contractQuery.queryRef
    );
  const { useSetApprovalForAll } = useTokenContract({
    abi: JSON.parse(contract.abidata).abi as any,
    contractAddress: contract.address as Hash,
  });

  const fetchParams = PendingApprovalsManager.useReadVaultCoreApproval({
    accountAddress: walletAddress,
    contract,
    vaultCoreContractAddress: vaultCoreContract.address as Hash,
  });
  const manager = useSetApprovalForAll({
    account: walletAddress,
    approved: !fetchParams[0],
    operatorAddress: vaultCoreContract.address as Hash,
  });

  return (
    <Approval
      className={className}
      title={`Contract Approval${contractTitle ? ` - ${contractTitle}` : ''}`}
      explanation="You will grant MakersPlace the abilities to move tokens in and out of a particular wallet address."
      fetchParams={fetchParams}
      manager={manager}
      walletAddress={walletAddress}
    />
  );
}

const SetVaultCoreButtonWithLoad = withLoadQuery(SetVaultCoreButton, {
  contractQuery: { concreteRequest: NFTContractQueryType },
});

export default function VaultCoreApproval(props: VaultCoreApprovalProps) {
  return (
    <SetVaultCoreButtonWithLoad
      {...props}
      contractQuery={{
        variables: { name: ContractNameEnum.VaultCore },
      }}
    />
  );
}
