.artworkCardContainer {
  --standardCard-desktopHeight: 589px;

  background: var(--color-commonWhite);
  box-sizing: border-box;
  gap: 32px;
  justify-content: flex-start;

  @media screen and (min-width: 1920px) {
    gap: 24px;
  }

  @media (--mq-is-mobile) {
    gap: 20px;
  }

  &.centered {
    justify-content: center;
  }
}

.artworkCardSecondaryText {
  color: var(--color-SolidNeutralGray5);
}

.artworkCardFooterValue {
  width: 104px;

  @media (--mq-is-mobile) {
    width: 86px;
  }
}

.artworkCardContainer.inverted {
  background: var(--color-commonBlack);
  color: var(--color-commonWhite);

  & .artworkCardSecondaryText {
    color: var(--color-SolidNeutralGray2);
  }
}

.artworkAssetContainer {
  &.medium {
    height: var(--artworkCard-assetCustomDesktopHeight, 336px);

    @media screen and (min-width: 1920px) {
      height: var(--artworkCard-assetCustomMonitorHeight, 463px);
    }

    @media (--mq-is-mobile) {
      height: var(--artworkCard-assetCustomMobileHeight, 267px);
    }
  }

  &.large {
    height: var(--artworkCard-assetCustomDesktopHeight, 388px);

    @media screen and (min-width: 1920px) {
      height: var(--artworkCard-assetCustomMonitorHeight, 507px);
    }

    @media (--mq-is-mobile) {
      height: var(--artworkCard-assetCustomMobileHeight, 306px);
    }
  }
}

.artworkAsset {
  filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, .16));
  transition: transform 300ms ease-out;
}

@media (hover: hover) {
  .artworkCardContainer.interactable > .artworkAssetContainer .artworkAsset:hover {
    transform: scale(1.02);
  }
}

.artworkAssetMedia {
  max-width: 100%;
  max-height: 100%;
}

.artworkAssetEmbedded {
  height: 100%;
  object-fit: contain;
  width: 100%;

  @media (--mq-is-mobile) {
    min-height: unset;
  }
}
