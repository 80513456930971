import { Route } from 'react-router-dom';

import AccountSettingsPage from './account/AccountSettingsPage';
import ContractsPage from './contracts/ContractsPage';
import CollectionPage from './collection';
import { PAGESLUG } from './pageSlug';
import SettingsPage from './SettingsPage';
import WalletPage from './wallet';

const SettingsRoute = (
  <Route path="settings" element={<SettingsPage />}>
    <Route path={PAGESLUG.collection} element={<CollectionPage />} />
    <Route path={PAGESLUG.wallets} element={<WalletPage />} />
    <Route path={PAGESLUG.contracts}>
      <Route index element={<ContractsPage />} />
    </Route>
    <Route path={PAGESLUG.account}>
      <Route index element={<AccountSettingsPage />} />
    </Route>
  </Route>
);

export default SettingsRoute;
