import { Dispatch, SetStateAction, useCallback } from 'react';

import {
  MPActionButton,
  MPFonts,
  MPStandardDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { usePurchaseWithWireTransfer } from 'hooks/product/usePurchaseProduct';
import useHasDatePassed from 'hooks/useHasDatePassed';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import { PurchasableNFTType } from 'types/graphql/NFT';

import { WireTransferViewType } from './PaymentViewPurchase';
import PaymentViewReserveLockTimer from './PaymentViewReserveLockTimer';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

interface WireTransferConfirmationProps {
  nft: PurchasableNFTType;
  onClose: () => void;
  onSuccess: () => void;
  reserveLockEndDate: Date;
  setWireTransferView: Dispatch<SetStateAction<WireTransferViewType>>;
}

function WireTransferConfirmation({
  setWireTransferView,
  nft,
  onClose,
  reserveLockEndDate,
  onSuccess,
}: WireTransferConfirmationProps) {
  const isReserveLockExpired = useHasDatePassed(reserveLockEndDate);
  const [purchaseWithWireTransfer, purchaseWithWireTransferState] =
    usePurchaseWithWireTransfer({
      nftId: parseInt(nft.pk, 10),
      onSuccess,
    });

  const resetState = useCallback(() => {
    setWireTransferView(WireTransferViewType.INITIAL);
  }, [setWireTransferView]);

  return (
    <MPStandardDialog
      title="Make a Purchase"
      onPrefixClick={resetState}
      onClose={onClose}
    >
      <div
        className={joinClasses(MPFonts.paragraphSmall, CSSMargin.BOTTOM[24])}
      >
        Clicking the button below confirms you have completed the wire transfer.
        The artwork will be immediately reserved for you.
      </div>
      <MPActionButton
        fullWidth
        onClick={purchaseWithWireTransfer}
        isLoading={purchaseWithWireTransferState.isDisabled}
        disabled={
          purchaseWithWireTransferState.isValidating || isReserveLockExpired
        }
        size="large"
      >
        Confirm Wire Transfer
      </MPActionButton>
      <div
        className={joinClasses(CSSGlobal.Flex.CenteredRow, CSSMargin.TOP[16])}
      >
        <PaymentViewReserveLockTimer
          classes={{
            container: styles.reserved,
            text: MPFonts.paragraphSmall,
          }}
          reserveLockEndDate={reserveLockEndDate}
          stringTemplates={{
            expired: 'Your reservation has expired. Close and try again.',
            notExpired: 'Reserved for the next $[minutes]m $[seconds]s',
          }}
        />
      </div>
    </MPStandardDialog>
  );
}

export default WireTransferConfirmation;
