/**
 * @generated SignedSource<<57bdb13f5193d56f0ef2e308c7e2125f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountStripeCardsQuery$variables = {};
export type AccountStripeCardsQuery$data = {
  readonly stripeCards: ReadonlyArray<{
    readonly id: string | null;
    readonly brand: string | null;
    readonly number: string | null;
    readonly image: string | null;
    readonly expMonth: number | null;
    readonly expYear: number | null;
    readonly name: string | null;
  }>;
};
export type AccountStripeCardsQuery = {
  variables: AccountStripeCardsQuery$variables;
  response: AccountStripeCardsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PaymentMethodType",
    "kind": "LinkedField",
    "name": "stripeCards",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "brand",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "image",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expMonth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountStripeCardsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountStripeCardsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4c1b1e36187ac5b961b8ba43584e8fdc",
    "id": null,
    "metadata": {},
    "name": "AccountStripeCardsQuery",
    "operationKind": "query",
    "text": "query AccountStripeCardsQuery {\n  stripeCards {\n    id\n    brand\n    number\n    image\n    expMonth\n    expYear\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b3488711515d03afa884b5b66a31487";

export default node;
