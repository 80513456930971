/**
 * @generated SignedSource<<a2b691a13a8b4c1e70bf6248ee0487ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type IsFollowingEnum = "TRUE" | "FALSE" | "NOT_ALLOWED";
export type FollowersQuery$variables = {
  global_slug: string;
};
export type FollowersQuery$data = {
  readonly followers: {
    readonly id: string;
    readonly isFollowing: IsFollowingEnum;
    readonly following: number;
    readonly followers: number;
    readonly views: number;
  };
};
export type FollowersQuery = {
  variables: FollowersQuery$variables;
  response: FollowersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "global_slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "globalSlug",
        "variableName": "global_slug"
      }
    ],
    "concreteType": "FollowersV2Type",
    "kind": "LinkedField",
    "name": "followers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isFollowing",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "following",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "followers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "views",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FollowersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FollowersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4367092d6cdb59d832084b1865323200",
    "id": null,
    "metadata": {},
    "name": "FollowersQuery",
    "operationKind": "query",
    "text": "query FollowersQuery(\n  $global_slug: String!\n) {\n  followers(globalSlug: $global_slug) {\n    id\n    isFollowing\n    following\n    followers\n    views\n  }\n}\n"
  }
};
})();

(node as any).hash = "56c8826a4047b89d1b508eac78b27941";

export default node;
