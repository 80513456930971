.filterRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.inline {
    @media (--mq-is-not-mobile) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &.inlineMobile {
    @media (--mq-is-mobile) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &.scroll {
    @media (--mq-is-mobile) {
      overflow: auto;
    }
  }
}
