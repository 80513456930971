import { PreloadedQuery } from 'react-relay';

import { AccountStripeCardsQuery } from 'graphql/__generated__/AccountStripeCardsQuery.graphql';

import useProductTimers from 'hooks/product/useProductTimers';
import { PurchasableNFTType } from 'types/graphql/NFT';
import { NFTActionType } from 'utils/nftUtils';

import PaymentViewOffer, { ModalType } from './PaymentViewOffer';
import PaymentViewPurchase from './PaymentViewPurchase';

interface PaymentViewProps {
  invalidate: () => void;
  invalidateStripeCards: () => void;
  nft: PurchasableNFTType;
  onClose: () => void;
  onSuccess: () => void;
  reserveLockEndDate: Date;
  stripeCardsQueryRef: PreloadedQuery<AccountStripeCardsQuery>;
  type: NFTActionType;
}

export default function PaymentView({
  type,
  nft,
  reserveLockEndDate,
  onClose,
  onSuccess,
  stripeCardsQueryRef,
}: PaymentViewProps) {
  const { showTimer } = useProductTimers(nft);

  const commonProps = {
    nft,
    onClose,
    onSuccess,
    stripeCardsQueryRef,
  };

  switch (type) {
    case NFTActionType.BUY:
      return (
        <PaymentViewPurchase
          {...commonProps}
          reserveLockEndDate={reserveLockEndDate}
        />
      );
    case NFTActionType.OFFER:
      return (
        <PaymentViewOffer
          {...commonProps}
          modalType={showTimer ? ModalType.Bid : ModalType.Offer}
        />
      );
    default:
      return null;
  }
}
