import { MPColorClass } from '@mp-frontend/core-components';

import useSimpleDialogController from 'hooks/useSimpleDialogController';

import HowToSpeedUpDialog from '../speedUp/howToSpeedUpDialog';

function WhatIsGasContent() {
  const [
    isHowToSpeedUpDialogOpen,
    openHowToSpeedUpDialog,
    closeHowToSpeedUpDialog,
  ] = useSimpleDialogController({ preventDefault: true });
  return (
    <div>
      <p>
        Ethereum is considered a world computer, operated by many computers
        across the world. This means that interacting with it incurs a
        transaction fee called <span className="bold">gas</span> to compensate
        the operators of Ethereum.
      </p>
      <p>
        This <span className="bold">gas</span> fee is paid in Ether and can vary
        in price depending on how quickly you wish to publish your creations and
        the demand on the blockchain. Generally, you should expect this fee to
        range from $3 ~ $15 dollars.
      </p>
      <p>
        At this time, gas fees associated with creation, setting prices and
        sending artworks will need to be paid for by the artist or
        collector.&nbsp;
      </p>
      <p className={MPColorClass.Grey600}>
        <a
          href="/faq/#gas-fees-payment"
          className="invisibleAnchor"
          onClick={openHowToSpeedUpDialog}
        >
          Learn how to &#34;Speed Up&#34; a transaction.
        </a>
      </p>
      <HowToSpeedUpDialog
        isOpen={isHowToSpeedUpDialogOpen}
        cancel={closeHowToSpeedUpDialog}
      />
    </div>
  );
}

export default {
  Component: WhatIsGasContent,
  title: 'What is gas (gwei)?',
};
