import { cloneElement, ReactElement, useCallback, useMemo } from 'react';
import { Hash } from 'viem';
import { useSwitchChain } from 'wagmi';

import {
  MPActionButton,
  MPActionButtonProps,
} from '@mp-frontend/core-components';

import useSession from 'hooks/useSession';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import {
  areSameAddress,
  hasUserRegisteredWalletAddress,
} from 'utils/areSameAddress';
import { AccountConnectionStatus } from 'utils/jwt/walletUtils';

import { useSelectWalletConnectDialog } from './SelectWalletConnectDialog';

function useGetValidationState(
  requiredAddress,
  warnIfAddressNotRegistered = false
) {
  const { status, address, chainId } = useCachedAccount();
  const session = useSession();

  return {
    isDisconnected: status === AccountConnectionStatus.Disconnected,
    isUnregisteredAddress:
      warnIfAddressNotRegistered &&
      !!address &&
      !hasUserRegisteredWalletAddress(address, session.account.wallets),
    isWrongAddress:
      !!requiredAddress && !areSameAddress(address, requiredAddress),
    isWrongNetwork: session.contractNetwork !== chainId,
  };
}

interface WalletClickActionComponentProps {
  children: ReactElement;
  onAction: () => void;
  requiredAddress?: Hash;
  warnIfAddressNotRegistered?: boolean;
}

export default function WalletClickActionComponent({
  onAction,
  children,
  requiredAddress,
  warnIfAddressNotRegistered = false,
}: WalletClickActionComponentProps) {
  const { contractNetwork } = useSession();
  const { switchChainAsync } = useSwitchChain();

  const validationState = useGetValidationState(
    requiredAddress,
    warnIfAddressNotRegistered
  );

  const [openSelectWalletDialog, selectWalletDialogJSX] =
    useSelectWalletConnectDialog(requiredAddress, onAction);

  const handleClick = useCallback(async () => {
    switch (true) {
      case validationState.isDisconnected:
      case validationState.isWrongAddress:
        if (requiredAddress) {
          openSelectWalletDialog();
        } else {
          // TODO (brian): https://app.asana.com/0/1206901512949392/1208650551756165/f
        }
        return;
      default:
        break;
    }

    if (validationState.isWrongNetwork) {
      await switchChainAsync({ chainId: contractNetwork });
    }

    onAction();
  }, [
    contractNetwork,
    switchChainAsync,
    onAction,
    requiredAddress,
    openSelectWalletDialog,
    validationState.isDisconnected,
    validationState.isWrongAddress,
    validationState.isWrongNetwork,
  ]);

  const passedChildren = useMemo(
    () => cloneElement(children, { onClick: handleClick }),
    [children, handleClick]
  );

  return (
    <>
      {passedChildren}
      {selectWalletDialogJSX}
    </>
  );
}
interface WalletActionButtonProps
  extends Omit<
    WalletClickActionComponentProps & MPActionButtonProps,
    'children'
  > {
  children: ReactElement | Array<ReactElement> | string;
}

/**
 * This should eventually replace components/WalletActionButton in a following diff
 * TODO (brian): https://app.asana.com/0/1206901512949392/1208650551756168/f
 */
export function WalletActionButton({
  onAction,
  children,
  requiredAddress,
  warnIfAddressNotRegistered,
  ...passedProps
}: WalletActionButtonProps) {
  return (
    <WalletClickActionComponent
      onAction={onAction}
      requiredAddress={requiredAddress}
      warnIfAddressNotRegistered={warnIfAddressNotRegistered}
    >
      <MPActionButton {...passedProps}>{children}</MPActionButton>
    </WalletClickActionComponent>
  );
}
