import { PureComponent, ReactNode, Suspense } from 'react';

import DefaultSuspense, {
  type DefaultSuspenseProps,
} from 'components/DefaultSuspense';

import FourOFourErrorBoundary from './FourOFourErrorBoundary';
import RedirectErrorBoundary from './RedirectErrorBoundary';

export interface DefaultErrorBoundaryProps {
  children: any;
  defaultSuspenseProps?: Omit<DefaultSuspenseProps, 'children'>;
  errorFallback?: ReactNode;
  hideState?: boolean;
  suspenseless?: boolean;
}

class DefaultErrorBoundary extends PureComponent<
  DefaultErrorBoundaryProps,
  { error: any }
> {
  constructor(props: DefaultErrorBoundaryProps) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    if (
      FourOFourErrorBoundary.willCatch(error) ||
      RedirectErrorBoundary.willCatch(error)
    )
      throw error;
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  render() {
    const {
      children,
      defaultSuspenseProps = {},
      errorFallback,
      hideState = false,
      suspenseless = false,
    } = this.props;

    if (this.state.error) {
      // You can render any custom fallback UI
      return errorFallback !== undefined ? (
        errorFallback
      ) : hideState ? (
        <div />
      ) : (
        <>Oops, something went wrong...</>
      );
    }
    return suspenseless ? (
      <>{children}</>
    ) : hideState ? (
      <Suspense>{children}</Suspense>
    ) : (
      <DefaultSuspense {...defaultSuspenseProps}>{children}</DefaultSuspense>
    );
  }
}

export default DefaultErrorBoundary;
