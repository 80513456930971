export default class MPGraphQLError extends Error {
  code: number;

  name: string;

  additionalData: Record<string, any>;

  constructor(error: any) {
    super(error.message);
    this.code = parseInt(error.code, 10);
    this.name = error.name;
    if (error.additionalData) this.additionalData = error.additionalData;
  }
}
