.container {
  display: flex;
  width: 100%;
}

.container :global(.defaultLink) {
  color: var(--color-commonBlack);

  &:hover {
    color: var(--color-SolidNeutralGray3);
    text-decoration: none;
  }

  &:active {
    color: var(--color-SolidNeutralGray1);
  }
}
