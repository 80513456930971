import { BigNumber } from 'ethers';

import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject } from 'utils/jwt/walletUtils';

import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export default function useDepositFundContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();
  const useDeposit = ({
    requestId,
    metadataId,
    value,
  }: {
    metadataId: number;
    requestId: number;
    value: BigNumber;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [requestId, metadataId],
      functionName: 'deposit',
      value: value.toBigInt(),
      ...defaultOverrides,
    });

  return { useDeposit };
}
