.notificationsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notificationsNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid var(--color-divider);
  border-top: 1px solid var(--color-divider);
}

.notificationsBody {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.notificationsList {
  /* 100vh - minus global nav (80px) and noti nav (72px) heights */
  height: calc(100vh - 80px - 72px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.defaultNotification {
  /* 100vh - minus global nav (80px) and noti nav (72px) heights */
  height: calc(100vh - 80px - 72px);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--color-SolidNeutralGray3);
}

.notificationsList::-webkit-scrollbar {
  display: none;
}

.centerTextIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.backOrCloseIcon:hover {
  cursor: pointer;
}

.notificationSettings {
  cursor: pointer;
}

.notificationLoader {
  height: 45px;
  width: 45px;
  margin: 0;
}

@media (--mq-is-mobile) {
  .notificationsList {
    height: calc(100vh - 63px - 74px);
  }
}
