import { ethers } from 'ethers';

const generateFormattedUserFullName = (username: string) => {
  if (ethers.utils.isAddress(username)) {
    const prefix = username.substring(0, 4);
    const suffix = username.substring(username.length - 4, username.length);
    return `${prefix}...${suffix}`;
  }
  return username;
};

export default generateFormattedUserFullName;
