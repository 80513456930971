import { MPColorClass, MPTooltip } from '@mp-frontend/core-components';
import { InfoIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

export default function LabelWithTooltip({
  label,
  tooltip: tooltipTitle,
}: {
  label: string;
  tooltip: string;
}) {
  return (
    <span className={joinClasses(CSSGlobal.Flex.InlineRow, CSSGap[2])}>
      {label}
      <MPTooltip title={tooltipTitle} placement="bottom-start">
        <InfoIcon className={MPColorClass.SolidNeutralGray3} fontSize="16" />
      </MPTooltip>
    </span>
  );
}
