import { Route } from 'react-router-dom';

import LogoutPage from './Logout';

const LogoutRoute = (
  <Route path="logout" element={<LogoutPage />}>
    <Route path="*" element={<LogoutPage />} />
  </Route>
);

export default LogoutRoute;
