.input .label {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.input .label > .icon {
  height: 16px;
  width: 16px;
}

.tooltip {
  margin-left: -10%;
}
