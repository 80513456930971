import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const MinusIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <rect
      x="19"
      y="13.5"
      width="14"
      height="3"
      transform="rotate(-180 19 13.5)"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default MinusIcon;
