import { useCallback } from 'react';
import { Disposable, useMutation, UseMutationConfig } from 'react-relay';

import NFTCommentDeleteMutationType, {
  NFTCommentDeleteMutation as NFTCommentDeleteMutationParameters,
} from 'graphql/__generated__/NFTCommentDeleteMutation.graphql';
import NFTCommentEditMutationType, {
  NFTCommentEditMutation as NFTCommentEditMutationParameters,
} from 'graphql/__generated__/NFTCommentEditMutation.graphql';
import NFTCommentMutationType, {
  NFTCommentMutation as NFTCommentMutationParameters,
} from 'graphql/__generated__/NFTCommentMutation.graphql';

import GTM from '../GTM';

export function useCreateCommentMutation(): [
  (config: UseMutationConfig<NFTCommentMutationParameters>) => Disposable,
  boolean
] {
  const [submit, isLoading] = useMutation<NFTCommentMutationParameters>(
    NFTCommentMutationType
  );

  const handleSubmit = useCallback(
    (config: UseMutationConfig<NFTCommentMutationParameters>) =>
      submit({
        ...config,
        onCompleted(
          response: NFTCommentMutationParameters['response'],
          errors
        ) {
          GTM.social.trackComment(config.variables.nftId);
          if (config.onCompleted) config.onCompleted(response, errors);
        },
      }),
    [submit]
  );
  return [handleSubmit, isLoading];
}

export function useDeleteCommentMutation(): [
  (config: UseMutationConfig<NFTCommentDeleteMutationParameters>) => Disposable,
  boolean
] {
  const [submit, isLoading] = useMutation<NFTCommentDeleteMutationParameters>(
    NFTCommentDeleteMutationType
  );

  const handleSubmit = useCallback(
    (config: UseMutationConfig<NFTCommentDeleteMutationParameters>) =>
      submit(config),
    [submit]
  );
  return [handleSubmit, isLoading];
}

export function useEditCommentMutation(): [
  (config: UseMutationConfig<NFTCommentEditMutationParameters>) => Disposable,
  boolean
] {
  const [submit, isLoading] = useMutation<NFTCommentEditMutationParameters>(
    NFTCommentEditMutationType
  );

  const handleSubmit = useCallback(
    (config: UseMutationConfig<NFTCommentEditMutationParameters>) =>
      submit(config),
    [submit]
  );
  return [handleSubmit, isLoading];
}

export default {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
};
