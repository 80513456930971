import { forwardRef } from 'react';
import { merge } from 'lodash';
import { keyframes, styled } from '@mui/material';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const scaleUp = keyframes`
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
`;

const blink = keyframes`
  0%, 100% {
    stroke: var(--timer-stroke);
  }
  50% {
    stroke: var(--color-SolidNeutralGray5);
  }
}`;

const Circle = styled('circle')`
  animation: ${scaleUp} 0.8s infinite alternate, ${blink} 1.2s infinite linear;
  transform-origin: center;
  stroke-width: 1.3px;
`;

type TimerIconProps = Omit<MPSVGIconProps, 'fill' | 'stroke'> & {
  fill?: string | MPColorValue;
  stroke?: string | MPColorValue;
};

const TimerIcon = forwardRef<any, TimerIconProps>(
  (
    {
      fill = 'var(--color-commonWhite)',
      stroke = 'var(--color-commonBlack)',
      ...props
    },
    ref
  ) => (
    <MPSVGIcon
      {...props}
      style={merge({}, props.style, {
        '--timer-fill': fill,
        '--timer-stroke': stroke,
      } as any)}
      ref={ref}
    >
      <Circle
        cx="12"
        cy="12"
        r="9.44444"
        fill="var(--timer-fill)"
        stroke="var(--timer-stroke)"
        stroke-width="1.11111"
      />
      <circle cx="12" cy="12" r="3" fill="var(--timer-stroke)" />
    </MPSVGIcon>
  )
);

export default TimerIcon;
