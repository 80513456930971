import { SvgIcon } from '@mui/material';

export default function ArtstationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M21 16.3566C21 15.9957 20.8979 15.652 20.7108 15.3598L14.7902 4.97959C14.4839 4.39527 13.8885 4 13.1909 4H10.0605L19.1966 19.9828L20.6427 17.4565C20.9149 16.9753 21 16.7691 21 16.3566Z"
        fill="currentColor"
      />
      <path
        d="M3 16.3394L4.51418 18.986C4.82042 19.5875 5.43289 20 6.13043 20H16.2193L14.1267 16.3394H3Z"
        fill="currentColor"
      />
      <path
        d="M8.58034 6.59505L12.6465 13.7272H4.49717L8.58034 6.59505Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
