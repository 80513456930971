interface ConditionalWrapperProps {
  children: JSX.Element;
  condition: boolean;
  wrapper: (children) => JSX.Element;
}

export default function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps) {
  return condition ? wrapper(children) : children;
}
