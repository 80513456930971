import MPSVGIcon from './MPSVGIcon';

export default function NotifyNewMintIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4V20H7.5L13.75 12L20 20V4ZM11 8.5C11 9.88 9.88 11 8.5 11C7.12 11 6 9.88 6 8.5C6 7.12 7.12 6 8.5 6C9.88 6 11 7.12 11 8.5Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
