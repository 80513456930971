import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const ExternalLinkIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M8.02396 4V7.37658H14.166L4 17.6195L6.41232 20L16.5783 9.75706V15.818H20V4H8.02396Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default ExternalLinkIcon;
