import MPSVGIcon from './MPSVGIcon';

export default function NotifyFollowIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        d="M11.9995 11.2921C14.0172 11.2921 15.6456 9.66373 15.6456 7.64603C15.6456 5.62832 14.0172 4 11.9995 4C9.98184 4 8.35352 5.62832 8.35352 7.64603C8.35352 9.66373 9.98184 11.2921 11.9995 11.2921Z"
        fill="currentColor"
      />
      <path
        d="M19.0792 12.9203H4.91992V20H19.0792V12.9203Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
