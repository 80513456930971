import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePreloadedQuery } from 'react-relay';

import {
  MPActionButton,
  MPAnimations,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountGetNewsletterSubscriptionStatusQueryType, {
  AccountGetNewsletterSubscriptionStatusQuery,
} from 'graphql/__generated__/AccountGetNewsletterSubscriptionStatusQuery.graphql';

import MPLogo from 'components/icons/MPLogo';
import {
  DISCORD_MP_PROFILE_URL,
  INSTAGRAM_MP_PROFILE_URL,
  LEVER_CAREERS_URL,
  MEDIUM_MP_PROFILE_URL,
  TWITTER_MP_PROFILE_URL,
} from 'constants/ExternalUrls';
import ROUTES from 'constants/Routes';
import useNewsletterSignUp from 'hooks/useNewsletterSignUp';
import useOnBodyResize from 'hooks/useOnBodyResize';
import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { SUBSCRIPTION_SOURCE } from 'utils/GTM/newletter';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/components/Footer.module.css';

const NewsletterSection = withDefaultErrorBoundary(
  withLoadQuery(
    ({
      subscriptionQuery: { queryRef },
      onHide,
    }: {
      onHide: () => void;
      subscriptionQuery: WithLoadQueryProps<AccountGetNewsletterSubscriptionStatusQuery>;
    }) => {
      const newsletter = useNewsletterSignUp(SUBSCRIPTION_SOURCE.FOOTER);
      const handleShowNewsletterClick = useCallback(
        () => newsletter.show(),
        [newsletter]
      );
      const { newsletterSubscriptionStatus } =
        usePreloadedQuery<AccountGetNewsletterSubscriptionStatusQuery>(
          AccountGetNewsletterSubscriptionStatusQueryType,
          queryRef
        );

      useEffect(() => {
        if (!newsletterSubscriptionStatus) return;

        onHide();
      }, [newsletterSubscriptionStatus, onHide]);

      return (
        <div className={styles.newsletter}>
          <div className={styles.title}>Newsletter</div>
          <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
            <div className={MPFonts.textNormalRegular}>
              Stay on top of upcoming exhibitions, events and digital art news.
            </div>
            <div>
              <MPActionButton
                variant="secondary-black"
                onClick={handleShowNewsletterClick}
              >
                Subscribe
              </MPActionButton>

              {newsletter.Dialog}
            </div>
          </div>
        </div>
      );
    },
    {
      subscriptionQuery: {
        concreteRequest: AccountGetNewsletterSubscriptionStatusQueryType,
      },
    }
  ),
  { hideState: true }
);

type Link = {
  name: string;
  url: string;
  ctaDetail?: string;
  ctaModule?: string;
  external?: boolean;
};

const HELP_LINKS: ReadonlyArray<Link> = [
  {
    ctaDetail: ROUTES.CONCIERGE(),
    ctaModule: 'concierge_program',
    name: 'Concierge Program',
    url: ROUTES.CONCIERGE(),
  },
  {
    name: 'FAQ',
    url: ROUTES.FAQ(),
  },
  {
    name: 'Support',
    url: `${ROUTES.FAQ()}#support`,
  },
  {
    name: 'Your Purchases',
    url: ROUTES.YOUR.PURCHASES(),
  },
  {
    name: 'Privacy Policy',
    url: ROUTES.PRIVACY(),
  },
];

const COMPANY_LINKS: ReadonlyArray<Link> = [
  {
    name: 'About',
    url: ROUTES.ABOUT(),
  },
  {
    name: 'Mission & Team',
    url: ROUTES.ABOUT.VALUES(),
  },
  {
    name: 'Terms of Service',
    url: ROUTES.TERMS(),
  },
  {
    external: true,
    name: 'Careers',
    url: LEVER_CAREERS_URL,
  },
];

const SOCIAL_LINKS: ReadonlyArray<Link> = [
  {
    name: 'Discord',
    url: DISCORD_MP_PROFILE_URL,
  },
  {
    name: 'Instagram',
    url: INSTAGRAM_MP_PROFILE_URL,
  },
  {
    name: 'Twitter',
    url: TWITTER_MP_PROFILE_URL,
  },
  {
    name: 'Medium',
    url: MEDIUM_MP_PROFILE_URL,
  },
];

interface FooterProps {
  className?: string;
}

const Footer = forwardRef<HTMLDivElement, FooterProps>(
  ({ className }: FooterProps, ref) => {
    const session = useSession();
    const [showNewsletterDialog, setShownNewsletterDialog] =
      useState<boolean>(true);
    const hideNewsletterDialog = useCallback(
      () => setShownNewsletterDialog(false),
      []
    );

    return (
      <div ref={ref} className={styles.outerContainer}>
        <section
          className={joinClasses(className, styles.container, {
            [styles.hasNewsletter]: showNewsletterDialog,
          })}
        >
          <div className={styles.logoAndCopyright}>
            <a className="invisibleAnchor" href={ROUTES.HOME()}>
              <MPLogo
                className={joinClasses(
                  MPAnimations.Color.LightToDark,
                  styles.logo
                )}
              />
            </a>
            <div
              className={joinClasses(MPFonts.paragraphXSmall, styles.copyright)}
            >
              &copy; {new Date().getFullYear()} Onchain Labs, Inc. All rights
              reserved.
            </div>
          </div>
          <ul
            title="Help"
            className={joinClasses(
              MPFonts.textNormalRegular,
              MPAnimations.Color.LightToDark,
              styles.help
            )}
          >
            {HELP_LINKS.map((link) => (
              <li key={link.name}>
                <a
                  href={link.url}
                  className={MPAnimations.Color.LightToDark}
                  {...(link.ctaModule && link.ctaDetail
                    ? {
                        'data-cta-detail': link.ctaDetail,
                        'data-cta-module': link.ctaModule,
                      }
                    : {})}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
          <ul
            title="Company"
            className={joinClasses(MPFonts.textNormalRegular, styles.company)}
          >
            {COMPANY_LINKS.map((link) => (
              <li key={link.name}>
                <a
                  href={link.url}
                  className={MPAnimations.Color.LightToDark}
                  {...(link.external
                    ? { rel: 'noreferrer', target: '_blank' }
                    : {})}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
          <ul
            title="Follow Us"
            className={joinClasses(MPFonts.textNormalRegular, styles.social)}
          >
            {SOCIAL_LINKS.map((link) => (
              <li key={link.name}>
                <a
                  href={link.url}
                  target="_blank"
                  rel="noreferrer"
                  className={MPAnimations.Color.LightToDark}
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>

          {!!showNewsletterDialog && (
            <NewsletterSection
              subscriptionQuery={{
                variables: { email: session.account?.email || '' },
              }}
              onHide={hideNewsletterDialog}
            />
          )}
        </section>
      </div>
    );
  }
);

export function PageWithFooter({ children }: { children: ReactNode }) {
  const footerRef = useRef<HTMLDivElement>(null);
  const { width: bodyWidth, height: bodyHeight } = useOnBodyResize();
  const [footerHeight, setFooterHeight] = useState<number>(0);

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.clientHeight);
    }
  }, [bodyWidth, bodyHeight]);

  return (
    <div
      className={joinClasses(CSSGlobal.Flex.Col, styles.page)}
      style={
        {
          '--footer-height': `${footerHeight}px`,
        } as any
      }
    >
      <div className={styles.pageContent}>{children}</div>

      <Footer ref={footerRef} />
    </div>
  );
}

export default Footer;
