import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { PageWithFooter } from 'components/Footer';
import useSession from 'hooks/useSession';

import useSetDocTitle from '../useSetDocTitle';
import CreditCard from './creditCard';
import Wallets from './wallets';
import Withdrawals from './withdrawals';

import * as styles from 'css/pages/settings/wallet/index.module.css';

export default function AccountPage() {
  useSetDocTitle('Wallets & Withdrawals');
  useSession().contracts.preLoad();

  return (
    <PageErrorBoundary>
      <PageWithFooter>
        <div className={styles.walletPage}>
          <CreditCard stripeCardQuery={{ variables: {} }} />
          <Wallets />
          <Withdrawals />
        </div>
      </PageWithFooter>
    </PageErrorBoundary>
  );
}
