import { Route } from 'react-router-dom';

import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';

import OpenEditionPage from './OpenEditionPage';

export default (
  <Route
    path="oe/:slugAndId"
    element={
      <PageErrorBoundary>
        <OpenEditionPage />
      </PageErrorBoundary>
    }
  />
);
