import { SvgIcon } from '@mui/material';

export default function MoreHorizontalIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.8005 11.8C13.8005 12.7942 12.9946 13.6001 12.0004 13.6001C11.0063 13.6001 10.2004 12.7942 10.2004 11.8C10.2004 10.8059 11.0063 10 12.0004 10C12.9946 10 13.8005 10.8059 13.8005 11.8Z"
        fill="currentColor"
      />
      <path
        d="M6.60009 11.8001C6.60009 12.7942 5.79418 13.6001 4.80004 13.6001C3.80591 13.6001 3 12.7942 3 11.8001C3 10.806 3.80591 10 4.80004 10C5.79418 10 6.60009 10.806 6.60009 11.8001Z"
        fill="currentColor"
      />
      <path
        d="M19.2 13.6001C20.1941 13.6001 21 12.7942 21 11.8C21 10.8059 20.1941 10 19.2 10C18.2058 10 17.3999 10.8059 17.3999 11.8C17.3999 12.7942 18.2058 13.6001 19.2 13.6001Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
