import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGlobal from 'types/enums/css/Global';

import { ActivityCardBaseProps } from './ActivityCard';

import * as styles from 'css/pages/activity/ActivityCard.module.css';

function ActivityCardHeader({ showCollector = false }: ActivityCardBaseProps) {
  return (
    <div
      className={joinClasses(
        CSSGlobal.Cursor.Default,
        styles.activityHeaderContainer,
        styles.activityGrid
      )}
    >
      <div className={joinClasses(MPFonts.textSmallMedium, styles.title)}>
        Artwork
      </div>
      <div className={joinClasses(MPFonts.textSmallMedium, styles.artist)}>
        {showCollector ? 'Collector' : 'Artist'}
      </div>
      <div className={joinClasses(MPFonts.textSmallMedium, styles.edition)}>
        Edition
      </div>
      <div className={joinClasses(MPFonts.textSmallMedium, styles.transaction)}>
        Transaction
      </div>
      <div className={joinClasses(MPFonts.textSmallMedium, styles.price)}>
        Price
      </div>
      <div className={joinClasses(MPFonts.textSmallMedium, styles.date)}>
        Date
      </div>
    </div>
  );
}

export default ActivityCardHeader;
