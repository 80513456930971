import MPSVGIcon from './MPSVGIcon';

export default function NotifyOutbidIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        d="M22 5H15.2014L17.6446 7.43361L13.7624 11.9242L9.92854 8.08064L2 16.0188L4.13423 18.1531L9.92854 12.3491L13.8204 16.2313L19.6823 9.47127L22 11.789V5Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
