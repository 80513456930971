import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { UnlockableIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import CongratulationDialog from 'components/dialogs/CongratulationDialog';
import { SUPPORT_EMAIL } from 'constants/ExternalUrls';

import * as styles from 'css/pages/product/ProductClaimUnlockable.module.css';

interface CongratulationsProps {
  onClose: () => void;
}

export default function CongratulationsModal({
  onClose,
}: CongratulationsProps) {
  return (
    <CongratulationDialog open onClose={onClose} title={null}>
      <div className={joinClasses(styles.container, styles.congratulations)}>
        <UnlockableIcon className={styles.icon} />

        <div
          className={joinClasses('centerText', MPFonts.headline3, styles.title)}
        >
          Congratulations
        </div>

        <div
          className={joinClasses(
            'centerText',
            MPFonts.textNormalMedium,
            styles.message
          )}
        >
          <div>
            The creator will be notified of your unlock, and will be in touch
            with your delivery&apos;s estimated time of arrival.
          </div>

          <div>
            Please contact{' '}
            <a
              href={`mailto:${SUPPORT_EMAIL}`}
              target="_blank"
              className="invisibleAnchor"
              rel="noreferrer"
            >
              {SUPPORT_EMAIL}
            </a>{' '}
            if you have any questions.
          </div>
        </div>

        <MPActionButton
          size="large"
          className={styles.action}
          onClick={onClose}
        >
          Continue
        </MPActionButton>
      </div>
    </CongratulationDialog>
  );
}
