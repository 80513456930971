import { useMemo } from 'react';

export default function useQueryParamMemo(record: Record<string, any>) {
  const values = Object.entries(record ?? {})
    .filter((entry) => !!entry[1])
    .map((entry) => entry[0]);

  // Make it simplier to support arrays as param type
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const params = useMemo(() => values, [values.join('-')]);
  return values.length ? params : undefined;
}
