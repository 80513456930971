import { useCallback } from 'react';

import {
  MPAnimations,
  MPBackgroundColorClass,
  MPButton,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { HomepageSectionsQuery$data } from 'graphql/__generated__/HomepageSectionsQuery.graphql';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';

import HeroCard from './HeroCard';

import * as standardCardStyles from 'css/components/cards/EditorialCard.module.css';
import * as styles from 'css/components/cards/HeroEditorialCard.module.css';

type EditorialType = Omit<
  HomepageSectionsQuery$data['homepageSections']['edges'][number]['node']['editorial'],
  'order'
>;

interface HeroEditorialCardProps {
  editorial: EditorialType;
}

export default function HeroEditorialCard({
  editorial,
}: HeroEditorialCardProps) {
  const track = useHomepageGTM();

  const handleClick = useCallback(
    () =>
      track.clickCard(
        CardType.HeroEditorialCard,
        editorial.pk,
        editorial.title
      ),
    [track, editorial.pk, editorial.title]
  );

  return (
    <HeroCard
      disableInAppNavigate
      to={editorial.viewUrl}
      className={joinClasses(
        CSSGlobal.Cursor.Pointer,
        MPBackgroundColorClass.CommonBlack,
        MPColorClass.CommonWhite,
        'anchor',
        styles.container
      )}
      style={
        {
          '--heroEditorialCard-backgroundImageUrl': `url(${editorial.backgroundImageUrl})`,
        } as any
      }
      onClick={handleClick}
    >
      <div
        className={joinClasses(
          CSSGlobal.Flex.ColCenterAlign,
          CSSGap[18],
          styles.rail
        )}
      >
        <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[8])}>
          {!!editorial.type && (
            <span
              className={joinClasses(
                MPFonts.textSmallMedium,
                MPColorClass.GoldMain
              )}
            >
              {editorial.type}
            </span>
          )}
          <div className={MPFonts.headline4}>{editorial.title}</div>
        </div>

        <div className={joinClasses(styles.image, styles.mobile)} />

        {!!editorial.description && (
          <div
            className={joinClasses(
              MPFonts.paragraphSmall,
              CSSGlobal.Ellipsis,
              standardCardStyles.paragraph,
              styles.description
            )}
          >
            {editorial.description}
          </div>
        )}

        <div>
          <MPButton
            className={joinClasses(
              MPColorClass.CommonWhite,
              MPAnimations.Color.LightToDark
            )}
            size="large"
            variant="text"
            sx={{ padding: '0' }}
          >
            Continue Reading
          </MPButton>
        </div>
      </div>

      <div className={joinClasses(styles.image, styles.desktop)} />
    </HeroCard>
  );
}
