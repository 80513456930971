import MPSVGIcon from './MPSVGIcon';

export default function NotifySaleIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0909 8.72546H3V21.8164H5.86194L10.9764 15.2655L16.0909 21.8164V8.72546ZM8.72387 12.4035C8.72387 13.5353 7.80979 14.4493 6.67808 14.4493C5.54636 14.4493 4.63228 13.5353 4.63228 12.4035C4.63228 11.2718 5.54636 10.3577 6.67808 10.3577C7.80979 10.3577 8.72387 11.2718 8.72387 12.4035Z"
        fill="currentColor"
      />
      <path
        d="M12.8181 2.18005L15.2726 4.6346L12.8181 7.08914L10.3635 4.6346L12.8181 2.18005Z"
        fill="currentColor"
      />
      <path
        d="M19.3637 10.3618L21 11.9982L19.3637 13.6345L17.7273 11.9982L19.3637 10.3618Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
