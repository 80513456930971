import { lazy } from 'react';
import { Route } from 'react-router-dom';

import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import SlashRedirect from 'pages/SlashRedirect';

const DropPage = lazy(() => import('../drops/DropPage'));

export const DropsRoute = (
  <Route path="drops">
    <Route index element={<SlashRedirect />} />
    <Route
      path=":dropSlug"
      element={
        <PageErrorBoundary>
          <DropPage />
        </PageErrorBoundary>
      }
    />
  </Route>
);

const ExhibitionRoute = (
  <Route path="exhibition">
    <Route index element={<SlashRedirect />} />
    <Route
      path=":dropSlug"
      element={
        <PageErrorBoundary>
          <DropPage />
        </PageErrorBoundary>
      }
    />
  </Route>
);

export default ExhibitionRoute;
