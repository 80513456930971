import MPSVGIcon from './MPSVGIcon';

export default function EtherscanIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        d="M6.27563 18.9501C7.83716 20.2376 9.8315 21.0084 12.0126 21.0084C16.2473 21.0084 19.7862 18.0945 20.7537 14.1642L6.27563 18.9501Z"
        fill="currentColor"
      />
      <path
        d="M6.36917 16.1125V9.37835H9.33946V15.1299L10.4173 14.7741V7.99765H13.3876V13.7915L14.4908 13.4273V6.66776H17.4611V12.4447L21 11.2758C20.6266 6.64235 16.7567 3 12.0212 3C7.0396 3.00847 3 7.04047 3 12.0127C3 13.7661 3.50919 15.3925 4.37482 16.7732L6.36917 16.1125Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
