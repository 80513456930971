import { ChangeEvent, DetailedHTMLProps, TextareaHTMLAttributes } from 'react';
import { styled } from '@mui/system';

import { joinClasses } from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import * as styles from '../css/text_field/MPTextArea.module.css';

const InputBox = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-bottom: 16px;
`;

const StyledTextarea = styled('textarea')`
  border: 1px solid ${(props) => props.theme.palette.primary.p20};
  border-radius: 0;
  resize: none;
  outline: none;
`;
export interface MPTextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  /**
   * Label describing the purpose of the text area
   */
  label: string | JSX.Element;

  /**
   * unique identifier for form purposes
   */
  name: string;

  /**
   * Setter for the State Value, if this is not Set, the Text Field will use _local State for Setting Value.
   */
  onChange: (e: ChangeEvent<any>) => void;

  /**
   * State Value for the Text Field input.
   */
  value: string;

  /**
   * Optional Value for placeholder text.
   */
  placeholder?: string;

  /**
   * Optional textarea row height
   */
  rows?: number;
}

export function MPTextArea({
  label,
  value,
  name,
  onChange,
  rows = 1,
  placeholder,
  className,
  ...passedProps
}: MPTextAreaProps) {
  return (
    <InputBox className={className}>
      <span className={joinClasses(MPFonts.textSmallSemiBold, styles.label)}>
        {label}
      </span>
      <StyledTextarea
        className={joinClasses(MPFonts.paragraphSmall, styles.textarea)}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        rows={rows}
        {...passedProps}
      />
    </InputBox>
  );
}
