import { Navigate, Route } from 'react-router-dom';

function ProfileRedirect() {
  return <Navigate to="/store/edit/profile" replace />;
}

const ProfileRedirectRoute = (
  <Route path="profile">
    <Route path="*" element={<ProfileRedirect />} />
  </Route>
);

export default ProfileRedirectRoute;
