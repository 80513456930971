import { useEffect, useState } from 'react';

import { useIsMobile } from '@mp-frontend/core-components';

/** This hook is used to make the navbar disappear when the user scrolls down */
const useVanishingNavbar = () => {
  const isMobile = useIsMobile();
  const [isIntersecting, setIsIntersecting] = useState<boolean>(true);

  useEffect(() => {
    if (isMobile) return () => undefined; // Don't run this hook on mobile devices

    const options = {
      root: null, // Observe the viewport by default
      threshold: 0.1, // Trigger when at least 10% of the target element is visible
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) =>
      setIsIntersecting(entries.some((entry) => entry.isIntersecting));

    const observer = new IntersectionObserver(handleIntersection, options);
    const thresholdElement = window.document.querySelector(
      '.thresholdElement'
    ) as HTMLElement | null;

    if (thresholdElement) {
      observer.observe(thresholdElement);
    }

    return () => {
      // Stop observing and disconnect the observer when the hook is unmounted
      if (thresholdElement) {
        observer.unobserve(thresholdElement);
      }
      observer.disconnect();
    };
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) return;

    const parentWindow = window.parent;
    const parentDocument = parentWindow.document;

    const navbar = parentDocument.querySelector(
      '#navbarTarget'
    ) as HTMLElement | null;
    const appBody = parentDocument.querySelector('#app') as HTMLElement | null;
    const root = parentDocument.querySelector('#root') as HTMLElement | null;
    const productPage = parentDocument.querySelector(
      '#productPage'
    ) as HTMLElement | null;

    if (!navbar || !appBody || !root || !productPage) return;

    if (isIntersecting) {
      root.style.backgroundColor = undefined;

      // The target element is in the viewport
      navbar.style.transition = 'all 300ms';
      navbar.style.transform = 'translateY(0)'; // Show the navbar

      // Shrink the app body size to make room for the navbar
      appBody.style.transition = 'all 250ms';
      appBody.style.height = 'var(--appBodyHeight)';
      appBody.style.transform = 'translateY(0)';

      // Shrink the product page size to make room for the navbar
      productPage.style.transition = 'all 250ms';
      productPage.style.height =
        'calc(var(--appBodyHeight) - var(--pdp-padding))';
    } else {
      root.style.backgroundColor = 'var(--color-backgroundDefault)';

      // The target element is out of the viewport
      navbar.style.transition = 'all 300ms';
      navbar.style.transform = 'translateY(-100%)'; // Hide the navbar

      // Expand the app body size to fill the viewport
      appBody.style.transition = 'all 250ms';
      appBody.style.height = '100vh';
      appBody.style.transform = 'translateY(-48px)';

      // Expand the product page size to fill the viewport
      productPage.style.transition = 'all 250ms';
      productPage.style.height = 'calc(100vh - 32px - var(--pdp-padding))';
    }
  }, [isMobile, isIntersecting]);

  return null;
};

export default useVanishingNavbar;
