import { useMemo } from 'react';

import { useTrackingContext } from 'components/trackingContext';
import {
  convertFilterValueString,
  createGATrackingQueryString,
  FilterValueType,
} from 'GTM';

import { tagPush } from './base';

export enum FilterType {
  ArtistsFilter = 'update_artists_filter',
  CuratorsFilter = 'update_curators_filter',
  GenresFilter = 'update_genres_filter',
  MoreFilter = 'update_more_filter',
  PriceFilter = 'update_price_filter',
  SoldOutFilter = 'update_sold_out_filter',
  SortFilter = 'update_sort_filter',
}

export default function useFiltersGTM() {
  const { source, exploreType } = useTrackingContext();
  return useMemo(
    () => ({
      async updateFilter(
        filterName: FilterType,
        filterData?: Record<string, FilterValueType>
      ) {
        return tagPush(filterName, {
          detail: createGATrackingQueryString({
            exploreType,
            source,
            value: convertFilterValueString(filterData),
          }),
        });
      },
    }),
    [source, exploreType]
  );
}
