import { Component } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { SUPPORT_EMAIL } from 'constants/ExternalUrls';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/ErrorBoundaries/PageErrorBoundary.module.css';

class PageErrorBoundary extends Component<{ children: any }, { error: any }> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    if (this.state.error) {
      return (
        <div
          className={joinClasses(
            styles.container,
            CSSGlobal.Flex.ColCenterAlign
          )}
        >
          <h1 className={MPFonts.headline3}>Something went wrong</h1>
          <div>
            {this.state.error.message ||
              `Error: ${this.state.error.toString()}`}
          </div>
          <a href={`mailto:${SUPPORT_EMAIL}`}>
            <MPActionButton className={styles.actionButton}>
              Email Support
            </MPActionButton>
          </a>
        </div>
      );
    }

    return this.props.children;
  }
}

export default PageErrorBoundary;
