import { useMemo } from 'react';

import { PaginatedExploreArtworksQuery } from 'graphql/__generated__/PaginatedExploreArtworksQuery.graphql';
import {
  AspectRatioEnum,
  EditionTypeEnum,
  GenreEnum,
  MediaTypeEnum,
  StatusEnum,
} from 'types/__generated__/graphql';

import ArtistsFilter from 'components/filters/ArtistsFilter';
import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import FiltersSection from 'components/filters/FilterSection';
import GenresFilter from 'components/filters/GenresFilter';
import ListedForSaleFilter from 'components/filters/ListedForSaleFilter';
import PriceFilter, { PriceFilterValue } from 'components/filters/PriceFilter';
import {
  SortArtworksFilter,
  SortArtworksFilterValue,
} from 'components/filters/SortFilter';
import useFiltersGTM, { FilterType } from 'utils/GTM/filters';

import useQueryParamMemo from '../hooks/useQueryParamMemo';
import MoreFilters from './MoreFilters';

export interface ArtworksFiltersState {
  artists: Array<string>;
  aspectRatios: Partial<Record<AspectRatioEnum, boolean>>;
  editions: Partial<Record<EditionTypeEnum, boolean>>;
  genres: Array<GenreEnum>;
  mediaTypes: Partial<Record<MediaTypeEnum, boolean>>;
  price: PriceFilterValue;
  query: string;
  showFollowingArtists: boolean;
  size: Partial<{ minHeight: string; minWidth: string }>;
  slug: string;
  sort: SortArtworksFilterValue;
  status: Partial<Record<StatusEnum, boolean>>;
  traits: Partial<Record<string, string>>;
}

export const ARTWORKS_FILTERS_DEFAULT_STATE: ArtworksFiltersState = {
  artists: [],
  aspectRatios: {},
  editions: {},
  genres: [],
  mediaTypes: {},
  price: { max: '', min: '' },
  query: '',
  showFollowingArtists: false,
  size: {},
  slug: '',
  sort: {},
  status: {},
  traits: {},
};

export const useArtworksFiltersQueryValues = (
  filters: ArtworksFiltersState
): PaginatedExploreArtworksQuery['variables'] => {
  const artists = useMemo(
    () => (filters.artists?.length ? filters.artists : undefined),
    [filters.artists]
  );
  const showFollowingArtists = useMemo(
    () => (filters.showFollowingArtists ? true : undefined),
    [filters.showFollowingArtists]
  );
  const status = useQueryParamMemo(filters.status ?? {}) as StatusEnum[];
  const editions = useQueryParamMemo(
    filters.editions ?? {}
  ) as EditionTypeEnum[];
  const price = useMemo(
    () => ({
      max: filters.price.max ? parseFloat(filters.price.max) : undefined,
      min: filters.price.min ? parseFloat(filters.price.min) : undefined,
    }),
    [filters.price.max, filters.price.min]
  );
  const size = useMemo(
    () => ({
      minHeight: filters.size?.minHeight
        ? parseFloat(filters.size.minHeight)
        : undefined,
      minWidth: filters.size?.minWidth
        ? parseFloat(filters.size.minWidth)
        : undefined,
    }),
    [filters.size?.minHeight, filters.size?.minWidth]
  );
  const genres = useMemo(
    () => (filters.genres.length ? filters.genres : undefined),
    [filters.genres]
  );

  const aspectRatios = useQueryParamMemo(
    filters.aspectRatios ?? {}
  ) as AspectRatioEnum[];

  const mediaTypes = useQueryParamMemo(
    filters.mediaTypes ?? {}
  ) as MediaTypeEnum[];

  const traits = useMemo(
    () =>
      Object.keys(filters.traits || {}).map((key) => ({
        name: filters.traits[key],
        value: key,
      })),
    [filters.traits]
  );

  return {
    artists,
    aspectRatios,
    editions,
    first: 9,
    genres,
    mediaTypes,
    price,
    query: filters.query,
    showFollowingArtists,
    size,
    slug: filters.slug,
    sortOrder: filters.sort?.order,
    sortType: filters.sort?.type,
    status,
    traits,
  };
};

interface ArtworksFiltersProps {
  onChange: (state: Partial<ArtworksFiltersState>) => void;
  state: ArtworksFiltersState;
}

export default function Filters({ state, onChange }: ArtworksFiltersProps) {
  const { updateFilter } = useFiltersGTM();
  return (
    <FilterScrollContainer>
      <FiltersSection inline inlineMobile>
        <FiltersGroup>
          <FiltersGroup title="Sorting">
            <SortArtworksFilter
              value={state.sort}
              onChange={(sort) => {
                onChange({ sort });
                updateFilter(FilterType.SortFilter, sort);
              }}
            />
          </FiltersGroup>
        </FiltersGroup>

        <FiltersGroup title="Filters">
          <ListedForSaleFilter
            value={state.status}
            onChange={(status) => onChange({ status })}
          />
          <ArtistsFilter
            value={{
              isShowFollowing: state.showFollowingArtists,
              userNames: state.artists,
            }}
            onChange={(value) => {
              onChange({
                artists: value.userNames,
                showFollowingArtists: value.isShowFollowing,
              });
              updateFilter(FilterType.ArtistsFilter, value);
            }}
          />
          <PriceFilter
            value={state.price}
            onChange={(price) => {
              onChange({ price });
              updateFilter(FilterType.PriceFilter, price);
            }}
          />
          <GenresFilter
            value={state.genres}
            onChange={(genres) => {
              onChange({ genres });
              updateFilter(FilterType.GenresFilter, { genres });
            }}
          />
          <MoreFilters state={state} onChange={onChange} />
        </FiltersGroup>
      </FiltersSection>
    </FilterScrollContainer>
  );
}
