import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const ArrowRightIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8123 13.267L11.7275 16.5627L13.4075 18.3575L19.364 11.9938L13.4075 5.62999L11.7275 7.42484L14.8132 10.7215L4.9999 10.7215L4.9999 13.267L14.8123 13.267Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default ArrowRightIcon;
