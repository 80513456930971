import { MutableRefObject, useCallback, useRef, useState } from 'react';

export default function useStateBackedObjRef<T>(
  initialState: Partial<Record<keyof T, any>>
): [
  MutableRefObject<Partial<Record<keyof T, any>>>,
  (key: keyof T) => any,
  (key: keyof T, value: any) => void
] {
  const [state, _setState] = useState(initialState);
  const ref = useRef(state);
  ref.current = state;
  const setState = useCallback(
    (key: keyof T, value: any) =>
      _setState((prev) => ({ ...prev, [key]: value })),
    [_setState]
  );
  return [ref, (key) => state[key] ?? undefined, setState];
}
