/**
 * @generated SignedSource<<e629fcd6b85f76d9de80d4613d6b83e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExploreHeroEditorialQuery$variables = {};
export type ExploreHeroEditorialQuery$data = {
  readonly editorial: {
    readonly heroEditorial: {
      readonly backgroundImageUrl: string | null;
      readonly description: string;
      readonly id: string;
      readonly order: number;
      readonly pk: string;
      readonly title: string;
      readonly type: string;
      readonly viewUrl: string;
    } | null;
  } | null;
};
export type ExploreHeroEditorialQuery = {
  variables: ExploreHeroEditorialQuery$variables;
  response: ExploreHeroEditorialQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExploreEditorialQuery",
    "kind": "LinkedField",
    "name": "editorial",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NFTEditorialType",
        "kind": "LinkedField",
        "name": "heroEditorial",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backgroundImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "order",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExploreHeroEditorialQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExploreHeroEditorialQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bcf1d7f771821ef5d2131f88f933448f",
    "id": null,
    "metadata": {},
    "name": "ExploreHeroEditorialQuery",
    "operationKind": "query",
    "text": "query ExploreHeroEditorialQuery {\n  editorial {\n    heroEditorial {\n      backgroundImageUrl\n      description\n      id\n      order\n      pk\n      title\n      type\n      viewUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4be1142c6b3726dae15d8a9d8b22aa59";

export default node;
