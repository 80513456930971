import ExploreExhibitionsPaginatedFragmentType, {
  ExploreExhibitionsPaginatedFragment$data,
} from 'graphql/__generated__/ExploreExhibitionsPaginatedFragment.graphql';
import PaginatedExploreExhibitionsQueryType, {
  PaginatedExploreExhibitionsQuery,
} from 'graphql/__generated__/PaginatedExploreExhibitionsQuery.graphql';

import ExhibitionCard from 'components/cards/ExhibitionCard';

import Cards from '../Cards';
import Layout, { BaseLayoutProps } from '../Layout';
import { ExploreType } from '../types';
import Chips from './Chips';
import Filters, {
  ExhibitionsFiltersState,
  useExhibitionsFiltersQueryValues,
} from './Filters';
import Hero from './Hero';

interface ExploreExhibitionsProps extends BaseLayoutProps {
  filters: ExhibitionsFiltersState;
  onFiltersChange: (value: Partial<ExhibitionsFiltersState>) => void;
  onFiltersReset: () => void;
}

function ExploreExhibitions({
  filters,
  onFiltersChange,
  onFiltersReset,
  ...props
}: ExploreExhibitionsProps) {
  const queryValues = useExhibitionsFiltersQueryValues(filters);

  return (
    <Layout
      {...props}
      hero={<Hero heroExhibitionQuery={{ variables: {} }} />}
      cards={
        <Cards<
          PaginatedExploreExhibitionsQuery,
          ExploreExhibitionsPaginatedFragment$data['exhibition']['results']['edges'][number]['node']
        >
          exploreType={ExploreType.Exhibitions}
          filters={
            <>
              <Filters state={filters} onChange={onFiltersChange} />
              <Chips
                filters={filters}
                onChange={onFiltersChange}
                onReset={onFiltersReset}
              />
            </>
          }
          variables={queryValues}
          fragmentConcreteRequest={ExploreExhibitionsPaginatedFragmentType}
          queryConcreteRequest={PaginatedExploreExhibitionsQueryType}
          renderElement={(exhibition) => (
            <ExhibitionCard key={exhibition.id} exhibition={exhibition} />
          )}
        />
      }
    />
  );
}

export default ExploreExhibitions;
