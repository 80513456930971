import { ChangeEvent, useCallback, useMemo } from 'react';

import MPCheckbox from '../checkbox/MPCheckbox';
import MPDropdown, { MPDropdownProps } from './MPDropdown';

import * as styles from '../css/popovers/MPDropdownSelect.module.css';

type LabelType = string;
type ValueType = string;

export interface MPDropdownSelectProps
  extends Omit<MPDropdownProps, 'children'> {
  /**
   * A map of values to labels that will be displayed in the dropdown.
   * The keys are the values that will be passed to the onChange callback.
   * The values are the labels that will be displayed in the dropdown.
   * If a label is not provided for a value, the value itself will be displayed.
   * The labels are displayed in the order they are provided in the object.
   */
  config: Record<ValueType, LabelType>;

  /**
   * The handler that is called when the selected values in the dropdown change.
   * The selected values should be an array with a single value.
   * The selected values should be an array with multiple values.
   */
  onChange: (values: string[]) => void;

  /**
   * The values that are currently selected in the dropdown.
   * If this is a single selection dropdown, this should be an array with a single value.
   * If this is a multiple selection dropdown, this should be an array with multiple unique values.
   */
  values: string[];

  /**
   * The type of selection that is allowed in the dropdown.
   * If this is 'single', the user can only select one option at a time.
   * If this is 'multiple', the user can select multiple options at a time.
   * Default: 'multiple'
   */
  selectionType?: 'single' | 'multiple';
}

/**
 * A dropdown that allows the user to select one or more options based on selectionType.
 * The selected option is displayed as the dropdown title if this is a single selection dropdown,
 * otherwise the title is displayed from props.
 */
export default function MPDropdownSelect({
  config,
  values,
  selectionType = 'multiple',
  title: defaultTitle,
  onChange,
  ...props
}: MPDropdownSelectProps) {
  const title = useMemo(
    () =>
      selectionType === 'multiple'
        ? defaultTitle
        : values.length
        ? config[values[0]] || values[0]
        : defaultTitle,
    [selectionType, defaultTitle, values, config]
  );
  const valuesSet = useMemo<Set<string>>(() => new Set(values), [values]);

  const handleCheckboxChange = useCallback(
    (value: string, checked: boolean) => {
      const newValues =
        selectionType === 'multiple'
          ? checked
            ? [...values, value]
            : values.filter((v) => v !== value)
          : checked
          ? [value]
          : [];

      onChange(newValues);
    },
    [selectionType, values, onChange]
  );

  return (
    <MPDropdown
      {...props}
      title={title}
      mobileDialogTitleOverride={defaultTitle}
    >
      <ul className={styles.list}>
        {Object.entries(config).map(([value, label]) => (
          <li key={value}>
            <MPCheckbox
              label={label || value}
              name={value}
              isChecked={valuesSet.has(value)}
              onChange={(
                event: ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => handleCheckboxChange(value, checked)}
            />
          </li>
        ))}
      </ul>
    </MPDropdown>
  );
}
