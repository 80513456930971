import { useCallback, useState } from 'react';
import { noop } from 'lodash';
import { Config, type Connector, useAccount } from 'wagmi';
import { ConnectData } from 'wagmi/query';

import {
  MPFonts,
  MPSelector,
  MPSelectorItem,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import supportedWallets from 'constants/supportedWallets';
import useMPConnect from 'hooks/wallet/useMPConnect';
import { AccountConnectionStatus, WalletId } from 'utils/jwt/walletUtils';
import {
  getConnectorIcon,
  getConnectorLink,
  getConnectorName,
} from 'utils/wallet/connectorUtils';

import * as styles from 'css/pages/product/ProductPurchaseOfferDialog.module.css';

function WalletConnection({
  onConnection = noop,
  onError = noop,
  onMutate = noop,
  onSettled = noop,
  onSuccess = noop,
}: {
  onConnection?: (connection: ConnectData<Config>) => any;
  onError?: (error) => void;
  onMutate?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
}) {
  const { status: connectionStatus, connector: connectedConnector } =
    useAccount();
  const { connectAsync, findConnector, isPending } = useMPConnect({
    mutation: { onError, onMutate, onSettled, onSuccess },
  });

  const [connectingId, setConnectingId] = useState<string | null>(null);

  const handleConnectClick = useCallback(
    async (connector: Connector, id: WalletId) => {
      try {
        if (!connector) {
          if (getConnectorLink(id)) window.open(getConnectorLink(id));
          return null;
        }

        setConnectingId(connector.id);
        const connection = await connectAsync({ connector });
        onConnection(connection);
      } catch (error) {
        onError(error);
      }
      return null;
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [connectAsync, onConnection, onError]
  );

  const Prefix = getConnectorIcon(connectedConnector?.id as WalletId);

  return (
    <>
      {connectionStatus === AccountConnectionStatus.Connected ? (
        <MPSelectorItem
          title={
            getConnectorName(connectedConnector?.id as WalletId) ||
            connectedConnector?.name
          }
          prefix={Prefix ? <Prefix /> : undefined}
          suffix="Connected"
          variant="filled"
          onClick={noop}
        />
      ) : (
        <>
          <div
            className={joinClasses(
              MPFonts.paragraphNormal,
              styles.walletConnectionTitle
            )}
          >
            Choose how you want to connect from these wallet providers.
          </div>

          <MPSelector
            items={supportedWallets.map((id) => {
              // This should always exist due to supportedWallets
              const SupportedWalletIcon = getConnectorIcon(id);

              const connector = findConnector(id);
              return {
                disabled: false,
                onClick: () => {
                  if (isPending) {
                    return;
                  }
                  handleConnectClick(connector, id);
                },
                prefix: <SupportedWalletIcon />,
                selected: isPending && connectingId === connector?.id,
                suffix: null,
                title: getConnectorName(id),
              };
            })}
          />
        </>
      )}
    </>
  );
}

export default WalletConnection;
