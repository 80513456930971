.container {
  @media (--mq-is-mobile) {
    flex-direction: column;
    gap: 24px;
  }

  &.reversed {
    flex-direction: row-reverse;

    @media (--mq-is-mobile) {
      flex-direction: column;
    }
  }

  & .descriptionColumn {
    flex-basis: 0;
    flex-grow: 1;
    white-space: pre-wrap;

    @media (--mq-is-mobile) {
      padding: 0 24px;
    }
  }

  & .cardColumn {
    width: 448px;

    @media screen and (min-width: 1920px) {
      width: 554px;
    }

    @media (--mq-is-mobile) {
      width: 100%;
    }
  }
}
