import { MouseEvent, useCallback, useState } from 'react';
import { useSwitchChain } from 'wagmi';

import {
  MPActionButton,
  MPActionButtonProps,
  MPFonts,
  MPStandardDialog,
} from '@mp-frontend/core-components';

import ROUTES from 'constants/Routes';
import { APP_NAME } from 'constants/Utils';
import useSession from 'hooks/useSession';
import useWalletValidate, {
  UseWalletValidateProps,
} from 'hooks/wallet/useWalletValidate';
import WalletConnection from 'pages/product/purchaseOfferDialog/WalletConnection';

import * as modalStyles from '../css/pages/product/ProductSetBuyNow.module.css';

export interface WalletActionButtonProps
  extends MPActionButtonProps,
    UseWalletValidateProps {
  successOnConnection?(): void;
}
export default function WalletActionButton({
  children,
  onClick,
  successOnConnection,
  restrictToAddress,
  warnIfAddressNotRegistered = false,
  ...passedProps
}: WalletActionButtonProps) {
  const { contractNetwork } = useSession();
  const { switchChainAsync } = useSwitchChain();

  const validationState = useWalletValidate({
    restrictToAddress,
    warnIfAddressNotRegistered,
  });

  const [isWalletConnectionModalOpen, setIsWalletConnectionModalOpen] =
    useState(false);

  const [isWrongAddressPromptOpen, setIsWrongAddressPromptOpen] =
    useState(false);

  const [isUnregisteredAddressPromptOpen, setIsUnregisteredAddressPromptOpen] =
    useState(false);

  const onButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
    switch (true) {
      case validationState.isDisconnected:
        setIsWalletConnectionModalOpen(true);
        return;
      case validationState.isWrongAddress:
        setIsWrongAddressPromptOpen(true);
        return;
      case validationState.isUnregisteredAddress:
        setIsUnregisteredAddressPromptOpen(true);
        return;
      default:
        break;
    }

    if (validationState.isWrongNetwork) {
      await switchChainAsync({ chainId: contractNetwork });
    }

    onClick(event);
  };

  const proceedToAccountSettings = useCallback(() => {
    window.location.href = ROUTES.ACCOUNT_SETTINGS();
  }, []);

  return (
    <>
      <MPActionButton {...passedProps} onClick={onButtonClick}>
        {children}
      </MPActionButton>

      <MPStandardDialog
        title="Connect Wallet"
        open={isWalletConnectionModalOpen}
        onClose={() => setIsWalletConnectionModalOpen(false)}
      >
        <WalletConnection
          onConnection={() => {
            if (successOnConnection) {
              successOnConnection();
            }
            setIsWalletConnectionModalOpen(false);
          }}
        />
      </MPStandardDialog>

      <MPStandardDialog
        title="Wrong Wallet"
        open={isWrongAddressPromptOpen}
        onClose={() => setIsWrongAddressPromptOpen(false)}
      >
        <p className={MPFonts.bodyMedium}>
          Switch to the address {restrictToAddress} to complete this action.
        </p>
      </MPStandardDialog>

      <MPStandardDialog
        title="Wrong Wallet"
        open={isUnregisteredAddressPromptOpen}
        onClose={() => setIsUnregisteredAddressPromptOpen(false)}
        actionButton={
          <MPActionButton
            fullWidth
            size="large"
            onClick={proceedToAccountSettings}
          >
            Proceed to Settings
          </MPActionButton>
        }
      >
        <div className={MPFonts.paragraphNormal}>
          <div className={modalStyles.productSellMessageContainer}>
            You are connected to a wallet which is not attached to your&nbsp;
            {APP_NAME} account.
          </div>

          <div>
            Please login to a different wallet, or connect this one in your
            account settings.
          </div>
        </div>
      </MPStandardDialog>
    </>
  );
}
