import Embed from 'pages/product/productPreview/Embed';
import { NFTType } from 'types/graphql/NFT';
import { CloudinaryParams, withCloudinaryParams } from 'utils/cloudinaryUtils';
import { isNFTDynamic } from 'utils/nftUtils';

import InViewVideo from './InViewVideo';

interface ExhibitionThumbnailProps {
  className: string;
  nftMetadata: Pick<
    NFTType['metadata'],
    | 'hasVideo'
    | 'highResImage'
    | 'id'
    | 'previewImage'
    | 'rawfileExtension'
    | 'standardImage'
    | 'thumbnailImage'
    | 'title'
    | 'videoUrl'
  >;
  cloudinaryParams?: CloudinaryParams;
}

function ExhibitionThumbnail({
  nftMetadata,
  className,
  cloudinaryParams,
}: ExhibitionThumbnailProps) {
  return (
    <>
      {nftMetadata.hasVideo ? (
        <InViewVideo
          className={className}
          poster={withCloudinaryParams(
            nftMetadata.thumbnailImage,
            cloudinaryParams
          )}
          src={withCloudinaryParams(nftMetadata.videoUrl, cloudinaryParams)}
        />
      ) : isNFTDynamic(nftMetadata.rawfileExtension) ? (
        <Embed
          className={className}
          media={{
            hasVideo: nftMetadata.hasVideo,
            highResUrl: nftMetadata.highResImage,
            id: nftMetadata.id,
            lowResUrl: nftMetadata.standardImage,
            mediumResUrl: nftMetadata.standardImage,
            rawfileExtension: nftMetadata.rawfileExtension,
            videoUrl: nftMetadata.videoUrl,
          }}
        />
      ) : (
        <img
          className={className}
          src={withCloudinaryParams(
            nftMetadata.thumbnailImage,
            cloudinaryParams
          )}
          alt={nftMetadata.title}
          loading="lazy"
        />
      )}
    </>
  );
}

export default ExhibitionThumbnail;
