.container {
  max-width: 390px;
  height: 100%;
  margin: auto;
  text-align: center;
  margin-top: -80px;
}

.actionButton {
  width: 290px;
  margin: 18px auto 0;
}
