type MaybeUndef<T> = T | null | undefined; // we'll want to create a type file for these and export

/**
 * Joins the passed-in CSS classes with a space.
 */
export default function joinClasses(
  ...cssClasses: Array<MaybeUndef<string> | Record<string, boolean>>
): string {
  return cssClasses
    .reduce<Array<string>>((acc, entry) => {
      if (!entry) return acc;

      if (typeof entry === 'string') {
        acc.push(entry);
      } else if (typeof entry === 'object') {
        Object.entries(entry).forEach(([key, value]) => {
          if (!value) return;

          acc.push(key);
        });
      }
      return acc;
    }, [])
    .join(' ');
}
