import { AccountArtistFragment } from 'types/graphql/Account';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';

import Author from './Author';
import OtherCollaborators from './OtherCollaborators';

interface ProductAboutArtistsProps {
  author: AccountArtistFragment;
  collaborators?: ReadonlyArray<{ user: AccountArtistFragment }>;
}

function ProductAboutArtists({
  author,
  collaborators,
}: ProductAboutArtistsProps) {
  const otherCollaborators =
    collaborators
      ?.map(({ user }) => user)
      .filter(({ pk }) => pk !== author.pk) ?? [];

  return (
    <>
      <Author artist={author} />

      {otherCollaborators.length === 1 ? (
        <Author artist={otherCollaborators[0]} />
      ) : otherCollaborators.length > 1 ? (
        <OtherCollaborators artists={otherCollaborators} />
      ) : null}
    </>
  );
}

export default withDefaultErrorBoundary(ProductAboutArtists, {
  hideState: true,
});
