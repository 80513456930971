import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const DynamicTokenIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M4 4V20H20.1475V4H4ZM6.30152 12.6233H8.61042V14.9322H6.30152V12.6233ZM10.9341 17.8681H8.61042V14.9396H10.9341V17.8681ZM8.61042 12.616V10.3144H10.9341V12.616H8.61042ZM13.2503 14.9322H10.9341V12.6233H13.2503V14.9322ZM17.8829 10.3218H15.5814V12.6455H17.8829V14.9248H15.5814V17.8608H13.2577V14.9322H15.574V12.6086H13.2577V10.3071H15.574V7.9834H13.2503V10.2923H10.9341V7.9834H8.61042V10.2923H6.30152V6.27939H17.8829V10.3218Z"
      fill="#010101"
    />
  </MPSVGIcon>
));

export default DynamicTokenIcon;
