:root {
  --mp-defaultDialogWidth: 502px;
}

:global(.MuiDialog-root).dialog .container {
  align-items: center;

  @media (--mq-is-mobile) {
    align-items: flex-end;
  }
}

:global(.MuiDialog-root).dialog .paper {
  height: fit-content;
  max-height: 95vh;

  @media (--mq-is-mobile) {
    max-height: min(100vh, 100%);
  }
}

:global(.MuiDialog-root).dialog .content {
  padding: 24px 34px;

  @media (--mq-is-mobile) {
    padding: 24px 24px;
  }
}
