import { startTransition, useCallback, useState } from 'react';

import {
  CheckCircleIcon,
  PurchasedArtworkIcon,
} from '@mp-frontend/core-components/icons';

import AccountStripeCardsType, {
  AccountStripeCardsQuery,
} from 'graphql/__generated__/AccountStripeCardsQuery.graphql';

import useProductTimers from 'hooks/product/useProductTimers';
import useLoadQuery from 'hooks/useLoadQuery';
import { PurchasableNFTType } from 'types/graphql/NFT';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import { generateTweetText, NFTActionType } from 'utils/nftUtils';

import ArtworkReservedView from './ArtworkReservedView';
import PaymentView from './paymentView';
import SuccessView from './SuccessView';

export enum ViewType {
  PAYMENT = 'payment',
  RESERVED = 'reserved',
  SUCCESS_OFFER = 'success-offer',
  SUCCESS_PURCHASE = 'success-purchase',
}

interface BuyOfferDialogProps {
  invalidate: () => void;
  isReserved: boolean;
  nft: PurchasableNFTType;
  onClose: () => void;
  reserveLockEndDate: Date;
  type: NFTActionType;
}

function PurchaseOfferDialog({
  nft,
  type,
  isReserved,
  reserveLockEndDate,
  invalidate,
  onClose,
}: BuyOfferDialogProps) {
  const [view, setView] = useState<ViewType>(
    isReserved ? ViewType.RESERVED : ViewType.PAYMENT
  );
  const { showTimer } = useProductTimers(nft);
  const [stripeCardsQueryRef, , , invalidateStripeCards] =
    useLoadQuery<AccountStripeCardsQuery>(AccountStripeCardsType, {});

  const handleSuccess = useCallback(
    () =>
      setView(
        type === NFTActionType.BUY
          ? ViewType.SUCCESS_PURCHASE
          : ViewType.SUCCESS_OFFER
      ),
    [type, setView]
  );

  const closeAndInvalidate = useCallback(() => {
    onClose();
    startTransition(() => {
      invalidate();
      invalidateStripeCards();
    });
  }, [onClose, invalidate, invalidateStripeCards]);
  switch (view) {
    case ViewType.PAYMENT:
      return (
        <PaymentView
          nft={nft}
          type={type}
          reserveLockEndDate={reserveLockEndDate}
          stripeCardsQueryRef={stripeCardsQueryRef}
          onClose={closeAndInvalidate}
          onSuccess={handleSuccess}
          invalidate={invalidate}
          invalidateStripeCards={invalidateStripeCards}
        />
      );
    case ViewType.SUCCESS_PURCHASE:
      return (
        <SuccessView
          Icon={PurchasedArtworkIcon}
          title="You Purchased an Artwork"
          message="Share this moment to celebrate!"
          onClose={closeAndInvalidate}
          variant="premium"
          link={window.location.href}
          tweet={generateTweetText(nft)}
        />
      );
    case ViewType.SUCCESS_OFFER:
      return (
        <SuccessView
          Icon={CheckCircleIcon}
          title={showTimer ? 'Your Bid is Placed' : 'You Made an Offer'}
          message={
            showTimer
              ? 'The auction is ending soon.'
              : 'The creator can accept or reject.'
          }
          onClose={closeAndInvalidate}
        />
      );
    case ViewType.RESERVED:
      return <ArtworkReservedView onClose={onClose} />;
    default:
      return null;
  }
}

export default withDefaultErrorBoundary(PurchaseOfferDialog, {
  hideState: true,
});
