import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MPColorClass,
  MPColorValue,
  MPFonts,
} from '@mp-frontend/core-components';
import {
  LikesIcon,
  NotifyBidAcceptedIcon,
  NotifyBidPlacedIcon,
  NotifyCommentedIcon,
  NotifyFollowIcon,
  NotifyNewMintIcon,
  NotifyOutbidIcon,
  NotifySaleIcon,
} from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import {
  NotificationActionEnum,
  NotificationType,
} from 'types/__generated__/graphql';

import GTM, { NotificationCategoryType } from 'GTM';
import IsDjangoContext from 'pages/navbar/IsDjangoContext';
import getTimeSinceX from 'utils/datetime/getTimeSinceX';

import * as styles from 'css/components/Navbar/Unified/Notifications/Notification.module.css';

const PRODUCT_NOTIFICATIONS = [
  NotificationActionEnum.BidOutbid,
  NotificationActionEnum.Commented,
  NotificationActionEnum.GlobalBidOutbid,
  NotificationActionEnum.Liked,
  NotificationActionEnum.MintFromFollowedCreator,
  NotificationActionEnum.MintFromPurchasedBeforeCreator,
];

type NotificationProps = {
  notification: NotificationType;
};

interface TextFragment {
  text: string;
  type: string;
  href?: string;
}

function getNotificationIcon(notificationType: NotificationActionEnum) {
  switch (notificationType) {
    case NotificationActionEnum.BidAccepted:
    case NotificationActionEnum.GlobalBidAccepted:
      return (
        <NotifyBidAcceptedIcon
          htmlColor={MPColorValue.GoldMain}
          fontSize="30"
        />
      );
    case NotificationActionEnum.BidOutbid:
    case NotificationActionEnum.GlobalBidOutbid:
      return (
        <NotifyOutbidIcon htmlColor={MPColorValue.GoldMain} fontSize="25" />
      );
    case NotificationActionEnum.BidReceived:
    case NotificationActionEnum.GlobalBidReceived:
    case NotificationActionEnum.MintOfGenerativeArt:
      return (
        <NotifyBidPlacedIcon htmlColor={MPColorValue.GoldMain} fontSize="28" />
      );
    case NotificationActionEnum.Commented:
      return <NotifyCommentedIcon htmlColor={MPColorValue.SolidNeutralGray5} />;
    case NotificationActionEnum.Followed:
      return (
        <NotifyFollowIcon
          htmlColor={MPColorValue.SolidNeutralGray5}
          fontSize="27"
        />
      );
    case NotificationActionEnum.Liked:
      return (
        <LikesIcon
          htmlColor={MPColorValue.SolidNeutralGray5 as string}
          fontSize="17"
        />
      );
    case NotificationActionEnum.MintFromFollowedCreator:
    case NotificationActionEnum.MintFromPurchasedBeforeCreator:
      return <NotifyNewMintIcon htmlColor={MPColorValue.SolidNeutralGray5} />;
    case NotificationActionEnum.SaleAccepted:
      return <NotifySaleIcon htmlColor={MPColorValue.GoldMain} fontSize="30" />;
    default:
      return null;
  }
}

function getNotificationCallout(notificationType: NotificationActionEnum) {
  switch (notificationType) {
    case NotificationActionEnum.BidAccepted:
    case NotificationActionEnum.GlobalBidAccepted:
      return 'Your Offer was Accepted';
    case NotificationActionEnum.BidOutbid:
    case NotificationActionEnum.GlobalBidOutbid:
      return 'You have been outbid';
    case NotificationActionEnum.BidReceived:
    case NotificationActionEnum.GlobalBidReceived:
      return "You've Received a Bid";
    case NotificationActionEnum.SaleAccepted:
      return "You've Sold an Artwork";
    case NotificationActionEnum.MintOfGenerativeArt:
      return 'Your Artwork is Here';
    default:
      return null;
  }
}

export default function Notification({ notification }: NotificationProps) {
  const textFragmentsParsed: TextFragment[] = JSON.parse(
    notification.textFragments
  );

  const timeSinceNotification = getTimeSinceX(
    notification.createdAt,
    true
  ) as string;

  const fullyConstructedNotification = textFragmentsParsed.map(
    ({ href, text, type }, index, elements) => {
      const nextElement =
        index < elements.length - 1 ? elements[index + 1] : null;
      if (type === 'TIMESTAMP') return null; // TODO: codegen this TIMESTAMP enum from server
      return (
        <span
          className={joinClasses(
            'breakWord',
            href ? MPFonts.textSmallBold : MPFonts.paragraphSmall,
            MPColorClass.CommonBlack
          )}
          // eslint-disable-next-line react/no-array-index-key
          key={`${notification.id}-${index}`}
        >
          {text}
          {!!nextElement && nextElement.type !== 'FULL_STOP' && <> </>}
        </span>
      );
    }
  );

  const isDjango = useContext(IsDjangoContext);
  const navigate = useNavigate();

  const handleNotificationClick = useCallback(
    (e) => {
      e.preventDefault();
      GTM.notification.trackNotificationClick(
        [
          NotificationActionEnum.Followed,
          NotificationActionEnum.Commented,
          NotificationActionEnum.Liked,
        ].includes(notification.notificationType)
          ? NotificationCategoryType.Secondary
          : NotificationCategoryType.Primary,
        notification.notificationType
      );
      if (isDjango) {
        // TODO: Remove once site is full SPA, this is used to redirect the iFrame parent (root window)
        if (window) window.parent.location.href = notification?.fullCtaLink;
      } else if (
        PRODUCT_NOTIFICATIONS.includes(notification.notificationType)
      ) {
        window.location.href = notification?.ctaLink;
      } else {
        navigate(notification?.ctaLink);
      }
      return false;
    },
    [notification, isDjango, navigate]
  );

  return (
    <button
      className="buttonToLink"
      onClick={handleNotificationClick}
      type="button"
    >
      <div
        className={joinClasses(
          styles.notificationBody,
          notification.hasRead ? styles.readBackground : styles.unreadBackground
        )}
      >
        <div className={styles.notificationInnerBody}>
          <div className={styles.notificationIcon}>
            {getNotificationIcon(notification.notificationType)}
          </div>
          <div>
            {getNotificationCallout(notification.notificationType) && (
              <>
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${notification.id}_fragment_callout`}
                  className={joinClasses(
                    'preserveWhiteSpace',
                    MPFonts.textSmallBold,
                    MPColorClass.GoldMain
                  )}
                >
                  {getNotificationCallout(notification.notificationType)}
                </span>
                <br />
              </>
            )}
            {fullyConstructedNotification}
            <br />
            <span
              key={`${notification.id}_fragment_timestamp`}
              className={joinClasses(
                'breakWord',
                MPFonts.paragraphSmall,
                MPColorClass.SolidNeutralGray5
              )}
            >
              {timeSinceNotification}
            </span>
          </div>
        </div>
        <div className={styles.notificationDivider} />
      </div>
    </button>
  );
}
