import { useGateValue } from '@statsig/react-bindings';

import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { PageWithFooter } from 'components/Footer';
import FourOFourError from 'errors/FourOFourError';
import useSession from 'hooks/useSession';

import useSetDocTitle from '../useSetDocTitle';
import Contract from './Contract';

import * as styles from 'css/pages/settings/wallet/index.module.css';

function ContractsPageContent() {
  const session = useSession();
  const [wallets] = session.useWalletInfo();
  const [contracts] = session.contracts.useReadWrite();

  const creatorWallet = wallets.find(
    (wallet) => wallet.isCreatorWallet && !wallet.isCustodialCreatorWallet
  );

  return (
    <div className={styles.walletPage}>
      {!!creatorWallet &&
        contracts.map((contract) => (
          <Contract
            key={contract.address}
            walletAddress={creatorWallet.address}
            contract={contract}
            showAll
          />
        ))}
    </div>
  );
}

export default function ContractsPage() {
  useSetDocTitle('Contracts');

  const allowedToSee = useGateValue('gate_internal_tools');
  if (!allowedToSee) throw new FourOFourError();
  return (
    <PageErrorBoundary>
      <PageWithFooter>
        <ContractsPageContent />
      </PageWithFooter>
    </PageErrorBoundary>
  );
}
