import { HTMLAttributes, ReactElement } from 'react';
import { SvgIconProps } from '@mui/material';

import MPAnimations from '../css/MPAnimations';
import { MPSVGIconProps } from '../icons/MPSVGIcon';

import * as styles from '../css/buttons/MPIconButton.module.css';

export const MPIconButtonThemes = {
  DARK: styles.darkColorTheme,
  DEFAULT: styles.defaultColorTheme,
  GOLD: styles.goldColorTheme,
  GRAY: styles.grayColorTheme,
  NAV: styles.navColorTheme,
} as const;

export interface MPIconButtonProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'role' | 'children'> {
  // Only allow Icons ()
  children: ReactElement<MPSVGIconProps | SvgIconProps>;
  theme?: string;
}

const joinClasses = (...classes: (string | null | undefined)[]): string =>
  classes.filter(Boolean).join(' ');

export default function MPIconButton({
  children,
  className,
  theme = MPIconButtonThemes.DEFAULT,
  ...props
}: MPIconButtonProps) {
  const transitionClass = [
    MPIconButtonThemes.DEFAULT,
    MPIconButtonThemes.NAV,
  ].includes(theme)
    ? MPAnimations.Color.DarkToLight
    : MPAnimations.Color.LightToDark;

  return (
    <div
      className={joinClasses(
        'animatable',
        className,
        styles.button,
        theme,
        transitionClass
      )}
      role="button"
      tabIndex={0}
      {...props}
    >
      {children}
    </div>
  );
}
