import { PureComponent, useLayoutEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/ErrorBoundaries/FourOFourErrorBoundary.module.css';

export function FourOFour({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void;
}) {
  const location = useLocation();
  const FourOFourLocation = useRef(location.pathname);

  useLayoutEffect(() => {
    if (location.pathname !== FourOFourLocation.current) resetErrorBoundary();
  });

  return (
    <div
      className={joinClasses(styles.container, CSSGlobal.Flex.ColCenterAlign)}
    >
      <h1 className={MPFonts.headline3}>Page not found</h1>
      <div>The URL you requested does not exist.</div>
      <Link to={ROUTES.HOME()}>
        <MPActionButton className={styles.actionButton}>
          Go back to the Homepage
        </MPActionButton>
      </Link>
    </div>
  );
}

class FourOFourErrorBoundary extends PureComponent<
  { children: any },
  { error: any }
> {
  static getDerivedStateFromError(error) {
    return { error };
  }

  static willCatch(error) {
    return error.code === 400 || error.code === 404;
  }

  constructor(props) {
    super(props);
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    if (!FourOFourErrorBoundary.willCatch(error)) throw error;
  }

  resetErrorBoundary() {
    this.setState({ error: null });
  }

  render() {
    if (this.state.error?.code === 400 || this.state.error?.code === 404) {
      // You can render any custom fallback UI
      return <FourOFour resetErrorBoundary={this.resetErrorBoundary} />;
    }
    return this.props.children;
  }
}

export default FourOFourErrorBoundary;
