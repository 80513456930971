import { useCallback } from 'react';

import { StatusEnum } from 'types/__generated__/graphql';

import ToggleFilter from './ToggleFilter';

export type ListedForSaleFilterValue = Partial<Record<StatusEnum, boolean>>;

interface ListedForSaleFilterProps {
  onChange: (value: ListedForSaleFilterValue) => void;
  value: ListedForSaleFilterValue;
}

function ListedForSaleFilter({ value, onChange }: ListedForSaleFilterProps) {
  const isSelected = !!value[StatusEnum.BuyNow];

  const handleToggle = useCallback(
    () => onChange({ ...value, [StatusEnum.BuyNow]: !isSelected }),
    [value, isSelected, onChange]
  );

  return (
    <ToggleFilter
      title="Listed For Sale"
      onToggle={handleToggle}
      isSelected={isSelected}
    />
  );
}

export default ListedForSaleFilter;
