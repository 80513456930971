.protectedPage {
  height: 100%;
  padding: 0 24px;

  > .protectedForm {
    max-width: 439px;

    @media (--mq-is-mobile) {
      max-width: 100%;
    }
  }
}
