import { BaseError } from 'viem';

import IsGlobalContractError from './global';

/**
 * Docs indicate that the "correct" way to check is to instanceof the error and walk up the custom trail and ducktype.
 * - However our contracts gives minimal error info
 * - instanceof comparison to Viem types is flaky, so we can only compare exactly against the short message, which is only
 *   good on a per function basis, and could break whenever there is an update to any of the wagmi stack.
 */
const IsDigitalSaleCoreContractError = {
  acceptBid: {
    SenderSellerMismatch: (err) =>
      IsGlobalContractError.SenderSellerMismatch(err),
    getMessage(err, ownerAddress: string) {
      if (!err) return undefined;
      return IsGlobalContractError.SenderSellerMismatch(err)
        ? `In order to accept this offer, you need to be logged into your digital wallet: ${ownerAddress}`
        : err?.message;
    },
  } as const,
  createSale: {
    WeiPriceMustBePositive: (err: any) =>
      // See https://github.com/MakersPlace/snapshotcontracts/blob/f637f5e65e7ad573d438404a72537af519301df9/mainnet/0xf74e503d3c67b941242fc08d59718fd68a3357c0/contracts/DigitalMediaSaleCore.sol#L100
      (err as BaseError)?.shortMessage ===
      'The contract function "createSale" reverted with the following reason:\nprice > 0',
  } as const,
  rejectBid: {
    SenderSellerMismatch: (
      err,
      senderAddress: string,
      ownerAddress: string
    ) => {
      if (senderAddress === ownerAddress) return false;
      return err.reason && err.reason === 'msg.sender not authorized'
        ? true
        : err.cause && err !== err.cause
        ? IsDigitalSaleCoreContractError.rejectBid.SenderSellerMismatch(
            err.cause,
            senderAddress,
            ownerAddress
          )
        : false;
    },
    getMessage(err, senderAddress: string, ownerAddress: string) {
      if (!err) return undefined;
      return IsDigitalSaleCoreContractError.rejectBid.SenderSellerMismatch(
        err,
        senderAddress,
        ownerAddress
      )
        ? `In order to reject this offer, you need to be logged into your digital wallet: ${ownerAddress}`
        : err?.message;
    },
  } as const,
};

export default IsDigitalSaleCoreContractError;
