import { useState } from 'react';
import { erc20Abi, Hash } from 'viem';
import { useReadContract } from 'wagmi';

import { CreatorContractType } from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import { APP_NAME } from 'constants/Utils';
import getAddressDisplay from 'utils/getAddressDisplay';

import DigitalMediaSaleCoreButton from './DigitalMediaSaleCoreButton';
import DropSaleCoreButton from './DropSaleCoreButton';
import ManifoldAdminButton from './ManifoldAdminButton';
import TLAdminButton from './TLAdminButton';
import VaultCoreButton from './VaultCoreButton';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

interface ContractProps {
  contract: CreatorContractType;
  walletAddress: Hash;
  showAll?: boolean;
}

export default function Contract({
  contract,
  walletAddress,
  showAll = false,
}: ContractProps) {
  const [error, setError] = useState<Error>();
  const requiresApproval = contract.isMakersplace
    ? !contract.hasVaultCoreApproval
    : contract.isManifold
    ? !contract.hasManifoldAdminApproval || !contract.hasVaultCoreApproval
    : false;

  const result = useReadContract({
    abi: erc20Abi,
    address: contract.address as Hash,
    functionName: 'name',
  });

  const { abi } = JSON.parse(contract.abidata);

  return showAll || requiresApproval ? (
    <div className={pageStyles.contract}>
      <div className={pageStyles.itemStatusRow}>
        <span>{result.data ?? getAddressDisplay(contract.address)}</span>
        <span>
          {!!contract.isMakersplace && `${APP_NAME} Contract`}
          {!!contract.isManifold && 'Manifold Contract'}
        </span>
      </div>
      <ErrorDisplay error={error} />
      {abi.find((f) => f.name === 'tokenOperatorToCustodialAccounts') &&
        abi.find((f) => f.name === 'setOboApprovalForAll') && (
          <>
            <DigitalMediaSaleCoreButton
              contract={contract}
              walletAddress={walletAddress}
              setError={setError}
              onlyShowApproval={!showAll}
            />
            <DropSaleCoreButton
              contract={contract}
              walletAddress={walletAddress}
              setError={setError}
              onlyShowApproval={!showAll}
            />
          </>
        )}
      {abi.find((f) => f.name === 'isApprovedForAll') &&
        abi.find((f) => f.name === 'setApprovalForAll') && (
          <VaultCoreButton
            contract={contract}
            walletAddress={walletAddress}
            setError={setError}
            onlyShowApproval={!showAll}
          />
        )}
      {!!contract.isManifold && (
        <ManifoldAdminButton
          contract={contract}
          walletAddress={walletAddress}
          setError={setError}
          onlyShowApproval={!showAll}
        />
      )}
      {!!contract.isTransientLabs && (
        <TLAdminButton
          contract={contract}
          walletAddress={walletAddress}
          setError={setError}
          onlyShowApproval={!showAll}
        />
      )}
    </div>
  ) : null;
}
