/* eslint-disable typescript-sort-keys/string-enum */
enum ColorValue {
  DefaultBlack = '#0d0d0d',
  EmphasizedBlack = '#000',
  Gray = '#656565',
  DisabledGray = 'rgba(0,0,0,0.38)',
  Orange = '#FF5C00',
  DarkOrange = '#E35200',
  LightOrange = '#FFC700',
  Green = '#2E7D32',
  DarkGreen = '#1B5E20',
  LightGreen = '#4CAF50',
  Red = '#EE5E5E',
  DarkRed = '#C62828',
  LightRed = '#FF94A3',
  White = '#FFF',
  SelectedGray = '#EFEFEF', // sync with Tyler on this
  BackgroundWhite = '#F5F5F5',
  SolidNeutralGray1 = '#D9D9D9',
  SolidNeutralGray2 = '#BDBDBD',
  SolidNeutralGray3 = '#A1A1A1',
  SolidNeutralGray4 = '#858585',
  SolidNeutralGray5 = '#696969',
  SolidNeutralGray6 = '#4D4D4D',
  SolidNeutralGray7 = '#303030',
  OffBlack = '#141414',
  OrangePrimary = '#FF8000',
  OrangeLowlight = '#E57300',
  OrangeHighlight = '#FF8A14',
  Overlay = 'rgba(105, 105, 105, 0.60)',
  Transparent = 'transparent',
  AnchorBlue = '#0E98E5',
  Gold = '#DC9B5A',
}

/**
 * Migration Notes:
 *  MPOrange -> OrangePrimary
 *  LightGray -> SolidNeutralGray3
 *  DarkGray -> SolidNeutralGray4
 */

export default ColorValue;
