import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import ROUTES from 'constants/Routes';
import useHomepageGTM from 'utils/GTM/homepage';
import { incrementViewCount } from 'utils/localStorageUtils';

import * as styles from 'css/pages/homepage/InitialVisitHeader.module.css';

function InitialVisitHeader() {
  const navigate = useNavigate();
  const track = useHomepageGTM();
  useEffect(() => {
    incrementViewCount();
  }, []);

  const handleClick = () => {
    track.clickLearnMoreOnInitialVisit();
    navigate(ROUTES.ABOUT());
  };

  return (
    <div className={styles.headerContainer}>
      <p
        className={joinClasses(
          MPFonts.homepageHeader,
          styles.headerSubTextContainer
        )}
      >
        The world&apos;s most trusted place for rare, authentic and curated
        digital art.
        <br />
        <div className={styles.headerActionBtns}>
          <MPActionButton variant="secondary" onClick={handleClick}>
            How to Get Started
          </MPActionButton>
        </div>
      </p>
    </div>
  );
}

export default InitialVisitHeader;
