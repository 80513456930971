import { useReducer } from 'react';

const DAY_IN_SECS = 3600;
const MIN_IN_SECS = 60;

function getTimeDisplay(seconds) {
  let timeLeft = seconds;
  let displayString = '';
  if (timeLeft >= DAY_IN_SECS) {
    displayString += `${Math.floor(timeLeft / DAY_IN_SECS)}:`;
    timeLeft %= DAY_IN_SECS;
    const minutes = Math.floor(timeLeft / MIN_IN_SECS);
    displayString += `${minutes < 10 ? '0' : ''}${minutes}:`;
    timeLeft %= MIN_IN_SECS;
    displayString += `${timeLeft < 10 ? '0' : ''}${Math.floor(timeLeft)}`;
  } else if (timeLeft >= MIN_IN_SECS) {
    displayString += `${Math.floor(timeLeft / MIN_IN_SECS)}:`;
    timeLeft %= MIN_IN_SECS;
    displayString += `${timeLeft < 10 ? '0' : ''}${Math.floor(timeLeft)}`;
  } else if (timeLeft > 0) {
    displayString += `0:${timeLeft < 10 ? '0' : ''}${Math.floor(timeLeft)}`;
  } else {
    return '0:00';
  }
  return displayString;
}

enum TIME_STATE_DISPATCH_TYPE {
  DURATION,
  SETPLAYED,
}

function timeStateReducer(state, action) {
  switch (action.type) {
    case TIME_STATE_DISPATCH_TYPE.DURATION:
      return {
        ...state,
        duration: action.duration,
        durationDisplay: getTimeDisplay(action.duration),
      };
    case TIME_STATE_DISPATCH_TYPE.SETPLAYED:
      return {
        ...state,
        playedSeconds: action.playedSeconds,
        playedSecondsDisplay: getTimeDisplay(action.playedSeconds),
      };
    default:
      return state;
  }
}

export default function usePlayerTimeState() {
  const [state, dispatch] = useReducer(timeStateReducer, {
    duration: 0,
    durationDisplay: '',
    playedSeconds: 0,
    playedSecondsDisplay: '0:00',
  });

  return {
    percentPlayed:
      state.duration && state.playedSeconds
        ? (state.playedSeconds * 100) / state.duration
        : 0,
    seekDisplay: `${state.playedSecondsDisplay} / ${state.durationDisplay}`,
    setDuration: (seconds: number) =>
      dispatch({ duration: seconds, type: TIME_STATE_DISPATCH_TYPE.DURATION }),
    setPlayedSeconds: (seconds: number) =>
      dispatch({
        playedSeconds: seconds,
        type: TIME_STATE_DISPATCH_TYPE.SETPLAYED,
      }),
  };
}

export type PlayerTimeState = ReturnType<typeof usePlayerTimeState>;
