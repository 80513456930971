import { usePreloadedQuery } from 'react-relay';

import ExploreHeroExhibitionQueryType, {
  ExploreHeroExhibitionQuery,
} from 'graphql/__generated__/ExploreHeroExhibitionQuery.graphql';

import HeroExhibitionCard from 'components/cards/HeroExhibitionCard';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

interface HeroProps {
  heroExhibitionQuery: WithLoadQueryProps<ExploreHeroExhibitionQuery>;
}

function Hero({ heroExhibitionQuery }: HeroProps) {
  const result = usePreloadedQuery<ExploreHeroExhibitionQuery>(
    ExploreHeroExhibitionQueryType,
    heroExhibitionQuery.queryRef
  );

  const exhibition = result.exhibition.heroExhibition;

  return exhibition && <HeroExhibitionCard exhibition={exhibition} />;
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    Hero,
    {
      heroExhibitionQuery: { concreteRequest: ExploreHeroExhibitionQueryType },
    },
    { grouppedLoadingKey: 'explore:hero-exhibition' }
  ),
  { errorFallback: null, hideState: true }
);
