.walletPage {
  width: 544px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  gap: 60px;
  box-sizing: border-box;
}

@media (--mq-is-mobile) {
  .walletPage {
    width: 100%;
    padding: 0 24px;
    margin-top: 24px;
    gap: 20px;
  }
}
