/**
 * @generated SignedSource<<cd5cfc9305672942e3f7e1c51c651148>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SessionWalletsMutation$variables = {};
export type SessionWalletsMutation$data = {
  readonly session: {
    readonly account: {
      readonly id: string;
      readonly wallets: ReadonlyArray<{
        readonly address: `0x${string}`;
        readonly collectedTokenNumber: number;
        readonly createdTokenNumber: number;
        readonly isCreatorWallet: boolean;
        readonly isCustodialCreatorWallet: boolean;
        readonly isSafebox: boolean;
        readonly isSaleContractApproval: boolean;
        readonly isSelectedMintingWallet: boolean;
        readonly name: string;
      }>;
    } | null;
  } | null;
};
export type SessionWalletsMutation = {
  variables: SessionWalletsMutation$variables;
  response: SessionWalletsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SessionMutation",
    "kind": "LinkedField",
    "name": "session",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountSessionType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WalletAbstractType",
            "kind": "LinkedField",
            "name": "wallets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectedTokenNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdTokenNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCreatorWallet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCustodialCreatorWallet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSafebox",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSaleContractApproval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSelectedMintingWallet",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionWalletsMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionWalletsMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "648a04414af133645710c64290357a4f",
    "id": null,
    "metadata": {},
    "name": "SessionWalletsMutation",
    "operationKind": "mutation",
    "text": "mutation SessionWalletsMutation {\n  session {\n    account {\n      id\n      wallets {\n        address\n        collectedTokenNumber\n        createdTokenNumber\n        isCreatorWallet\n        isCustodialCreatorWallet\n        isSafebox\n        isSaleContractApproval\n        isSelectedMintingWallet\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "20853face97ce92d6f4405068d652844";

export default node;
