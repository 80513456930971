import { useCallback, useEffect, useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { HomepageSectionsQuery$data } from 'graphql/__generated__/HomepageSectionsQuery.graphql';
import NFTsByArtworkListQueryType, {
  NFTsByArtworkListQuery,
} from 'graphql/__generated__/NFTsByArtworkListQuery.graphql';
import { HomepageArtworkListTypeEnum } from 'types/__generated__/graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import Carousel from 'components/carousel/Carousel';
import GTM from 'GTM';
import { setPasswordForProduct } from 'pages/product/ProductPage';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/homepage/HomepageCuratedArtworks.module.css';
import * as homepageCardStyles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

function CuratedArtworks({
  artworkList,
  curatedNftsQuery: { queryRef },
  className,
  carouselContentClassName,
}: {
  artworkList: Pick<
    HomepageSectionsQuery$data['homepageSections']['edges'][number]['node']['artworkList'],
    'id' | 'listType' | 'name' | 'pk' | 'showFooterDetails'
  > & { isProtected?: boolean };
  curatedNftsQuery: WithLoadQueryProps<NFTsByArtworkListQuery>;
  carouselContentClassName?: string;
  className?: string;
}) {
  const carouselName = `curated_artworks_${artworkList.pk}`;

  const result = usePreloadedQuery<NFTsByArtworkListQuery>(
    NFTsByArtworkListQueryType,
    queryRef
  );
  const nfts = useMemo(
    () => result.nfts.edges.map(({ node }) => node),
    [result]
  );

  const logViewAbleCardsToGA = useCallback(
    (start: number, end: number) =>
      GTM.ecommerce.trackViewItemList(nfts.slice(start, end), carouselName),
    [carouselName, nfts]
  );

  useEffect(() => {
    const { password } = queryRef.variables;
    if (password) {
      nfts.forEach((nft) => {
        if (nft.isProtected) {
          setPasswordForProduct(nft.listing.productSlug, password);
        }
      });
    }
  }, [nfts, queryRef]);

  return nfts.length && artworkList.id ? (
    artworkList.listType === HomepageArtworkListTypeEnum.Carousel ? (
      <Carousel
        header={
          artworkList.name || artworkList.name === ''
            ? artworkList.name
            : 'Featured Artworks'
        }
        items={nfts.map((nft, idx) => (
          <ArtworkCard
            key={nft.id}
            nft={nft}
            className={joinClasses(homepageCardStyles.card, {
              [homepageCardStyles.last]: idx === nfts.length - 1,
            })}
            hideDetails={!artworkList.showFooterDetails}
            hideTag={!artworkList.showFooterDetails}
          />
        ))}
        className={className}
        carouselContentClassName={carouselContentClassName}
        containerName={carouselName}
        logViewAbleCardsToGA={logViewAbleCardsToGA}
      />
    ) : artworkList.listType === HomepageArtworkListTypeEnum.Grid ? (
      <section
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGap[20],
          styles.gridContainer,
          className
        )}
      >
        <div
          className={joinClasses(MPFonts.headline4, CSSGlobal.Cursor.Default)}
        >
          {artworkList.name}
        </div>

        <div className={styles.grid}>
          {nfts.map((nft) => (
            <ArtworkCard
              key={nft.id}
              nft={nft}
              hideDetails={!artworkList.showFooterDetails}
              hideTag={!artworkList.showFooterDetails}
            />
          ))}
        </div>
      </section>
    ) : null
  ) : null;
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    CuratedArtworks,
    { curatedNftsQuery: { concreteRequest: NFTsByArtworkListQueryType } },
    { grouppedLoadingKey: 'homepage:curated-artworks-carousel' }
  ),
  { errorFallback: null, hideState: true }
);
