import { UIEvent, useEffect, useMemo, useRef, useState } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useStickyPinned, { StickyElementState } from 'hooks/useStickyPinned';
import { hasUserRegisteredAddress } from 'utils/areSameAddress';
import { HexString } from 'utils/jwt/walletUtils';

import LeaderboardRow, {
  LeaderboardRowData,
  LeaderboardRowProps,
} from './LeaderboardRow';

import * as styles from 'css/pages/product/ProductRankedAuction.module.css';

import { SessionType } from 'Session';

interface LeaderboardTableProps<TData extends LeaderboardRowData> {
  columns: string[];
  classes?: Partial<{
    emptyMessage: string;
  }>;
  currentUserAddress?: HexString;
  currentUserWallets?: SessionType['account']['wallets'];
  currentUsername?: string;
  emptyMessage?: string;
  maxRows?: number;
  renderCells?: LeaderboardRowProps<TData>['renderCells'];
  rows?: TData[];
}

export default function LeaderboardTable<TData extends LeaderboardRowData>({
  columns,
  currentUserAddress,
  currentUserWallets,
  currentUsername,
  classes = {},
  emptyMessage,
  maxRows,
  rows = [],
  renderCells,
}: LeaderboardTableProps<TData>) {
  const tableRef = useRef<HTMLTableElement>(null);
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const ownRowRef = useRef(null);
  const [scrollBarWidth, setScrollBarWidth] = useState<number>(0);
  const [scrollEnded, setScrollEnded] = useState<boolean>(
    maxRows && rows.length <= maxRows
  );
  const [ownIdx, setOwnIdx] = useState<number>(-1);
  const [stuck, stickyState] = useStickyPinned(ownRowRef, bodyRef);
  const lastIdx = useMemo(() => rows.length - 1, [rows]);

  const detectScrollBar = () => {
    if (!tableRef?.current?.clientWidth || !bodyRef?.current?.clientWidth) {
      return;
    }

    setScrollBarWidth(
      Math.abs(tableRef.current.clientWidth - bodyRef.current.clientWidth) + 5
    );
  };

  const handleScroll = (event: UIEvent<HTMLTableSectionElement>) => {
    const target = event.target as HTMLTableSectionElement;
    const { scrollHeight, scrollTop, clientHeight } = target;
    setScrollEnded(scrollHeight - scrollTop === clientHeight);
    detectScrollBar();
  };

  useEffect(() => {
    setOwnIdx(
      rows.findIndex(
        ({ userAddress, user }) =>
          (user?.username && user.username === currentUsername) ||
          hasUserRegisteredAddress(
            userAddress,
            currentUserAddress,
            currentUserWallets
          )
      )
    );
    detectScrollBar();
  }, [currentUserAddress, currentUsername, currentUserWallets, rows]);

  return (
    <div className={styles.leaderboardTableScrollableContainer}>
      <div className={styles.leaderboardTableContainer}>
        <table
          ref={tableRef}
          className={joinClasses(styles.leaderboardTable, {
            [styles.showBottomBorder]:
              maxRows && rows.length > 0 && rows.length < maxRows,
            [styles.limitedHeight]: !!maxRows,
          })}
        >
          <thead>
            <tr>
              {columns.map((column, idx) => (
                <td
                  key={column}
                  className={MPFonts.textSmallMedium}
                  style={{
                    paddingRight:
                      idx === columns.length - 1 ? scrollBarWidth : null,
                  }}
                >
                  {column}
                </td>
              ))}
            </tr>
          </thead>

          <tbody ref={bodyRef} onScroll={handleScroll}>
            {rows
              .slice(
                0,
                maxRows
                  ? rows.length < maxRows
                    ? rows.length
                    : lastIdx
                  : rows.length
              )
              .map((row, idx) => {
                const isOwn = ownIdx === idx;

                return (
                  <LeaderboardRow
                    key={row.user?.username ?? row.userAddress}
                    rowRef={(node) => {
                      if (isOwn) {
                        ownRowRef.current = node;
                      }
                    }}
                    className={joinClasses({
                      [styles.own]: isOwn,
                      [styles.sticky]: isOwn,
                      [styles.stuck]: isOwn && stuck,
                    })}
                    index={idx}
                    columns={columns}
                    data={{ ...row, isOwn }}
                    renderCells={renderCells}
                  />
                );
              })}
          </tbody>
        </table>

        {rows.length === 0 && emptyMessage ? (
          <div
            className={joinClasses(
              MPFonts.textSmallMedium,
              styles.leaderboardNoBidsMessage,
              classes.emptyMessage
            )}
          >
            {emptyMessage}
          </div>
        ) : maxRows && rows.length >= maxRows ? (
          <table
            className={joinClasses(styles.leaderboardTable, styles.withLowest, {
              [styles.showBottomBorder]: true,
              [styles.showTopBorder]:
                ([
                  StickyElementState.ScrollingUpEnter,
                  StickyElementState.ScrollingUpLeave,
                  StickyElementState.ScrollingDownEnter,
                  StickyElementState.ScrollingDownLeave,
                ].includes(stickyState) ||
                  (maxRows && stickyState === null && rows.length >= maxRows) ||
                  !ownRowRef.current) &&
                !scrollEnded,
            })}
            style={{ paddingRight: scrollBarWidth }}
          >
            <tbody>
              <LeaderboardRow
                className={joinClasses({
                  [styles.own]: ownIdx === lastIdx,
                })}
                index={lastIdx}
                columns={columns}
                data={rows[lastIdx]}
                renderCells={renderCells}
              />
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
}
