import { useEffect } from 'react';

function startAnimation(e) {
  if (e.target?.classList.contains('animatable')) {
    e.target.classList.add('isAnimating');
  }
}

function endAnimation(e) {
  if (e.target?.classList.contains('isAnimating')) {
    e.target?.classList.remove('isAnimating');
  }
}

export default function useAnimation() {
  useEffect(() => {
    window.parent.addEventListener('animationend', endAnimation);
    window.parent.addEventListener('click', startAnimation);
    return () => {
      window.parent.removeEventListener('click', startAnimation);
      window.parent.removeEventListener('animationend', endAnimation);
    };
  }, []);
}
