import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useRef,
} from 'react';

import emptyFunc from 'utils/emptyFunc';

interface ChildrenProps {
  children: ReactNode | undefined;
}

interface ContextProps {
  value: () => void;
}

const InnerCloseAllPanelsContext = createContext<MutableRefObject<() => void>>({
  current: emptyFunc,
});

// We'll enforce only allowing primitives as way of preventing the tracking context
// from containing all the data.
export function CloseAllPanelsContext({
  children,
  value,
}: ContextProps & ChildrenProps) {
  const ref = useRef(value ?? emptyFunc);
  return (
    <InnerCloseAllPanelsContext.Provider value={ref}>
      {children}
    </InnerCloseAllPanelsContext.Provider>
  );
}

export default function useCloseAllPanelsContext() {
  return useContext(InnerCloseAllPanelsContext).current;
}
