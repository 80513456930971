import { MPFonts } from '@mp-frontend/core-components';
import { ViewIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTType } from 'types/graphql/NFT';
import toNumericShorthand from 'utils/numericShorthand';

import * as styles from 'css/pages/product/ProductSocialBar.module.css';

interface ViewProps {
  nft: NFTType;
}

export default function ProductView({ nft }: ViewProps) {
  const { metadata } = nft;
  const viewCount = metadata.viewCount || 1;

  return (
    <div className={joinClasses(styles.action, styles.views)}>
      <ViewIcon sx={{ width: '20px' }} />
      <span className={MPFonts.notificationBoldText}>
        {toNumericShorthand(viewCount)}
      </span>
    </div>
  );
}
