import { merge } from 'lodash';
import Switch, { SwitchProps } from '@mui/material/Switch';

import { MPColorValue } from '../..';

export type MPSwitchProps = SwitchProps;

export default function MPSwitch({ sx = {}, ...passedProps }: MPSwitchProps) {
  const baseSx = {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: MPColorValue.CommonBlack,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        color: MPColorValue.CommonWhite,
        transform: 'translateX(16px)',
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        border: '6px solid var(--color-commonWhite)',
        color: MPColorValue.CommonBlack,
      },
      color: MPColorValue.CommonBlack,
      padding: '1px',
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      height: '20px',
      left: '1px',
      position: 'relative',
      top: '1px',
      width: '20px',
    },
    '& .MuiSwitch-track': {
      backgroundColor: MPColorValue.Grey100,
      border: `0.5px solid ${MPColorValue.Grey300}`,
      borderRadius: '12px',
      boxSizing: 'border-box',
      opacity: 1,
    },
    height: 24,
    margin: '8px',
    padding: 0,
    width: 40,
  };

  const mergedSx = merge(baseSx, sx);
  return <Switch sx={mergedSx} {...passedProps} />;
}
