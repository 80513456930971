import { merge } from 'lodash';
import Grid, { GridProps } from '@mui/material/Grid';

export type MPGridProps = GridProps;

export default function MPGrid({ sx = {}, ...passedProps }: MPGridProps) {
  const baseSx = {};

  const mergedSx = merge(baseSx, sx);
  return <Grid sx={mergedSx} {...passedProps} />;
}
