import MPSVGIcon from './MPSVGIcon';

export default function AddIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <rect
        x="19.7695"
        y="13.6642"
        width="15.5391"
        height="3.3298"
        transform="rotate(-180 19.7695 13.6642)"
        fill="currentColor"
      />
      <rect
        x="10.3345"
        y="19.7692"
        width="15.5391"
        height="3.3298"
        transform="rotate(-90 10.3345 19.7692)"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
