import { HTMLAttributes } from 'react';
import { Chip, ChipProps } from '@mui/material';

import { joinClasses } from '@mp-frontend/core-utils';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { MinusIcon } from '../../icons';

import * as styles from '../css/chips/MPChip.module.css';

export type MPChipProps = Omit<ChipProps, 'classes' | 'variant'> & {
  variant?: 'filled' | 'outlined';
};

const variantStyles: Record<MPChipProps['variant'], string> = {
  filled: styles.filled,
  outlined: styles.outlined,
};

function DeleteIcon(
  props: Omit<HTMLAttributes<HTMLDivElement>, 'role' | 'children'>
) {
  return (
    <div {...props} role="button" tabIndex={0} className={styles.deleteIcon}>
      <MinusIcon fontSize="18" />
    </div>
  );
}

export default function MPChip({
  variant = 'filled',
  ...passedProps
}: MPChipProps) {
  return (
    <Chip
      {...passedProps}
      classes={{
        label: joinClasses(MPFonts.textSmallMedium, styles.label),
        root: joinClasses(styles.root, variantStyles[variant], {
          [styles.hasDeleteIcon]: !!passedProps.onDelete,
        }),
      }}
      deleteIcon={<DeleteIcon />}
      variant={variant}
    />
  );
}
