.blockquote {
  --spacing: 4px;

  margin: 0 0 0 calc(var(--spacing) * 4);
  padding: var(--spacing) calc(var(--spacing) * 2);
  position: relative;

  &::before {
    content: ' ';
    background-color: var(--color-SolidNeutralGray1);
    border-radius: 10px;
    bottom: calc(var(--spacing) * 2);
    display: block;
    left: calc(var(--spacing) * -1);
    position: absolute;
    top: calc(var(--spacing) * 2);
    width: 2px;
  }
}
