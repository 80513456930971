:root {
  --activity-preview-size: 60px;

  @media (--mq-is-mobile) {
    --activity-preview-size: 113px;
  }
}

.activityCardContainer {
  margin-bottom: 16px;
  display: flex;
}

.activityCard {
  background-color: var(--color-commonWhite);
  padding: 15px;
  width: 100%;
}

.activityGrid {
  display: grid;
  grid-template-areas: "image title artist edition transaction price date";
  grid-template-columns: var(--activity-preview-size) 3fr 2fr 1fr 1fr 1.5fr 1.5fr;
  grid-template-rows: 1fr;
  column-gap: 16px;

  @media (--mq-is-mobile) {
    grid-template-areas:
      "image transaction date"
      "image title title"
      "image artist artist"
      "image edition edition"
      "image price price";
    grid-template-columns: var(--activity-preview-size) 1fr max-content;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 4px;
  }

  & > div {
    align-items: center;
    display: flex;
  }

  & .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-area: image;
    height: var(--activity-preview-size);
    width: var(--activity-preview-size);
  }

  & .title {
    grid-area: title;
    overflow: hidden;
  }

  & .transaction {
    grid-area: transaction;
  }

  & .date {
    grid-area: date;

    @media (--mq-is-mobile) {
      color: var(--color-SolidNeutralGray3);
    }
  }

  & .artist {
    grid-area: artist;
    overflow: hidden;
  }

  & .price {
    grid-area: price;

    & > .combined {
      display: flex;
      gap: 10px;

      & > .secondary {
        color: var(--color-SolidNeutralGray5)
      }
    }
  }

  & .edition {
    grid-area: edition;
  }
}

.activityHeaderContainer {
  padding: 0 16px 11px 16px;
  color: var(--color-SolidNeutralGray5);

  &.activityGrid {
    grid-template-areas: "title image artist edition transaction price date";
    grid-template-columns: 3fr var(--activity-preview-size) 2fr 1fr 1fr 1.5fr 1.5fr;
  }
}
