/**
 * @generated SignedSource<<4f2d236fe837c123baa878c3030a834d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProvenanceObjectType = "MINT" | "SALE" | "LAZY_MINT" | "LISTING" | "PRODUCT_BID" | "MEDIA_BID" | "PURCHASE" | "TRANSFER";
export type NFTsMultiProvenanceQuery$variables = {
  nftMetadataId: number;
};
export type NFTsMultiProvenanceQuery$data = {
  readonly nftMultiEditionProvenance: ReadonlyArray<{
    readonly type: ProvenanceObjectType;
    readonly source: string | null;
    readonly dest: string | null;
    readonly edition: number | null;
    readonly amountUsd: number | null;
    readonly amountEth: number | null;
    readonly currency: string | null;
    readonly date: any | null;
    readonly productSlug: string | null;
    readonly store: {
      readonly id: string;
      readonly pk: string;
      readonly storeSlug: string | null;
      readonly storeName: string;
      readonly owner: {
        readonly profileImageUrl: string;
      } | null;
    } | null;
  } | null> | null;
};
export type NFTsMultiProvenanceQuery = {
  variables: NFTsMultiProvenanceQuery$variables;
  response: NFTsMultiProvenanceQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nftMetadataId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "nftMetadataId",
    "variableName": "nftMetadataId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dest",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edition",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountUsd",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountEth",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productSlug",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeSlug",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTsMultiProvenanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProvenanceMultiType",
        "kind": "LinkedField",
        "name": "nftMultiEditionProvenance",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreTypeV3",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAbstractType",
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTsMultiProvenanceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProvenanceMultiType",
        "kind": "LinkedField",
        "name": "nftMultiEditionProvenance",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreTypeV3",
            "kind": "LinkedField",
            "name": "store",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAbstractType",
                "kind": "LinkedField",
                "name": "owner",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f3fcd688758e85cf924048292cee193",
    "id": null,
    "metadata": {},
    "name": "NFTsMultiProvenanceQuery",
    "operationKind": "query",
    "text": "query NFTsMultiProvenanceQuery(\n  $nftMetadataId: Int!\n) {\n  nftMultiEditionProvenance(nftMetadataId: $nftMetadataId) {\n    type\n    source\n    dest\n    edition\n    amountUsd\n    amountEth\n    currency\n    date\n    productSlug\n    store {\n      id\n      pk\n      storeSlug\n      storeName\n      owner {\n        profileImageUrl\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1fde6a2880411a4989d8fe16094b4fa5";

export default node;
