import { ReactNode, useCallback, useRef } from 'react';

import { useIsMobile } from '@mp-frontend/core-components';
import {
  CarouselLeftArrow,
  CarouselRightArrow,
} from '@mp-frontend/core-components/icons';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import useIntersection from 'hooks/useIntersection';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/filters/FilterScrollContainer.module.css';

interface FilterScrollContainerProps {
  children: ReactNode;
}

enum ScrollDirection {
  Left = 'left',
  Right = 'right',
}

const SCROLL_GRADIENT_WIDTH = 162;

export default function FilterScrollContainer({
  children,
}: FilterScrollContainerProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [leftEdgeRef, setLeftEdgeRef] = useRefState<HTMLSpanElement>(null);
  const [rightEdgeRef, setRightEdgeRef] = useRefState<HTMLSpanElement>(null);
  const isMobile = useIsMobile();

  const scroll = useCallback((direction: ScrollDirection) => {
    const { scrollLeft, clientWidth, scrollWidth } =
      scrollContainerRef.current!;
    const maxScrollLeft = scrollWidth - clientWidth;
    const scrollAmount = clientWidth - SCROLL_GRADIENT_WIDTH;

    const newScrollLeft =
      direction === ScrollDirection.Left
        ? Math.max(scrollLeft - scrollAmount, 0)
        : Math.min(scrollLeft + scrollAmount, maxScrollLeft);

    scrollContainerRef.current!.scrollTo({
      behavior: 'smooth',
      left: newScrollLeft,
    });
  }, []);

  const handlerScrollLeft = useCallback(
    () => scroll(ScrollDirection.Left),
    [scroll]
  );
  const handlerScrollRight = useCallback(
    () => scroll(ScrollDirection.Right),
    [scroll]
  );

  const canScrollBack = !useIntersection(leftEdgeRef, {
    root: scrollContainerRef.current,
  });
  const canScrollForward = !useIntersection(rightEdgeRef, {
    root: scrollContainerRef.current,
  });

  return (
    <div
      className={joinClasses(
        CSSGlobal.Flex.InlineRow,
        styles.scrollContainerWrapper
      )}
      style={
        {
          '--scroll-gradient-width': `${SCROLL_GRADIENT_WIDTH}px`,
        } as any
      }
    >
      {!!canScrollBack && (
        <>
          <div
            className={styles.scrollActionArrow}
            onClick={handlerScrollLeft}
            role="link"
            onKeyUp={handlerScrollLeft}
            tabIndex={0}
          >
            <CarouselLeftArrow fontSize="16" />
          </div>
          <div
            className={joinClasses(
              styles.gradientOverlay,
              styles.gradientOverlayLeft
            )}
          />
        </>
      )}
      <div
        ref={scrollContainerRef}
        className={joinClasses(
          CSSGlobal.NoScrollbar,
          styles.scrollContainerOuter
        )}
      >
        {!isMobile && <span ref={setLeftEdgeRef}>&nbsp;</span>}
        {children}
        {!isMobile && <span ref={setRightEdgeRef}>&nbsp;</span>}
      </div>
      {!!canScrollForward && (
        <>
          <div
            className={joinClasses(
              styles.gradientOverlay,
              styles.gradientOverlayRight
            )}
          />
          <div
            className={styles.scrollActionArrow}
            onClick={handlerScrollRight}
            role="link"
            onKeyDown={handlerScrollRight}
            tabIndex={0}
          >
            <CarouselRightArrow fontSize="16" />
          </div>
        </>
      )}
    </div>
  );
}
