.footer {
  box-sizing: border-box;

  @media (--mq-is-mobile) {
    flex-direction: column;
  }

  > .section {
    @media (--mq-is-not-mobile) {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
}
