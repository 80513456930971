import { joinClasses } from '@mp-frontend/core-utils';

import { FollowButton } from 'components/buttons/FollowButton';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { AccountArtistFragment } from 'types/graphql/Account';

import User from './User';

interface ArtistMiniProps {
  artist: AccountArtistFragment;
}

export default function MiniArtist({ artist }: ArtistMiniProps) {
  return (
    <div
      className={joinClasses(
        CSSGlobal.Flex.RowCenterAlign,
        CSSGlobal.Flex.RowSpaceBetween,
        CSSGlobal.Flex.NoWrap,
        CSSGap[8]
      )}
    >
      <User user={artist} />
      <FollowButton
        username={artist.username}
        userId={parseInt(artist.pk, 10)}
      />
    </div>
  );
}
