import { MPDivider } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import FullArtist from 'components/accounts/FullArtist';
import CSSMargin from 'types/enums/css/Margin';
import { AccountArtistFragment } from 'types/graphql/Account';

interface AuthorProps {
  artist: AccountArtistFragment;
}

export default function Author({ artist }: AuthorProps) {
  return (
    <>
      <MPDivider />
      <FullArtist
        artist={artist}
        className={joinClasses(CSSMargin.TOP[32], CSSMargin.BOTTOM[32])}
      />
    </>
  );
}
