import { ReactNode } from 'react';

import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

interface FiltersGroupProps {
  children: ReactNode;
  title?: string;
}

export default function FiltersGroup({ title, children }: FiltersGroupProps) {
  return (
    <div className={joinClasses(CSSGlobal.Flex.InlineRow, CSSGap[12])}>
      {title ? (
        <div
          className={joinClasses(
            MPFonts.textSmallSemiBold,
            MPColorClass.SolidNeutralGray5,
            CSSGlobal.Cursor.Default,
            CSSGlobal.Flex.CenteredCol
          )}
        >
          {title}
        </div>
      ) : null}
      <div className={joinClasses(CSSGlobal.Flex.InlineRow, CSSGap[12])}>
        {children}
      </div>
    </div>
  );
}
