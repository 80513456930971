import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from '../MPSVGIcon';

const DinersIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M20 12.4058C20 8.61734 16.8379 5.99886 13.3732 6H10.3917C6.88572 5.99874 4 8.61814 4 12.4058C4 15.8709 6.8858 18.7178 10.3917 18.701H13.3732C16.8376 18.7177 20 15.87 20 12.4058Z"
      fill="#0079BE"
    />
    <path
      d="M10.4105 6.53662C7.20689 6.53761 4.61101 9.13963 4.60986 12.351C4.61066 15.5618 7.20666 18.1634 10.4105 18.1646C13.6148 18.1636 16.2115 15.5618 16.2119 12.351C16.2115 9.13955 13.6149 6.53776 10.4105 6.53662Z"
      fill="white"
    />
    <path
      d="M6.74414 12.3353C6.74719 10.766 7.72528 9.42786 9.10448 8.89612V15.7737C7.72528 15.2422 6.74715 13.9047 6.74414 12.3353ZM11.7362 15.7754V8.89631C13.1159 9.4268 14.0955 10.7657 14.098 12.3357C14.0955 13.9062 13.1159 15.2441 11.7362 15.7756V15.7754Z"
      fill="#0079BE"
    />
  </MPSVGIcon>
));

export default DinersIcon;
