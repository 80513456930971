// TODO(brian): Hack to make this work, once the API is done remove
export function parseCookie(str: string) {
  return str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {} as Record<string, string>);
}

export default function getToken() {
  return parseCookie(document.cookie).csrftoken;
}
