import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/global/Padding.module.css';

const CSSPadding = {
  AROUND: {
    '16': joinClasses(styles.paddingHorizontal16, styles.paddingVertical16),
    '24': joinClasses(styles.paddingHorizontal24, styles.paddingVertical24),
  },
  HORIZONTAL: {
    '16': styles.paddingHorizontal16,
    '24': styles.paddingHorizontal24,
  } as const,
  VERTICAL: {
    '16': styles.paddingVertical16,
    '24': styles.paddingVertical24,
  },
} as const;

export default CSSPadding;
