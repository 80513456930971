import { ChangeEvent, useMemo } from 'react';

import { MPCheckbox } from '@mp-frontend/core-components';

interface ArtistsFilterListProps {
  onChange: (checked: boolean, value: string) => void;
  userNames: string[];
  userNamesToExclude: string[];
}

export default function ArtistsFilterList({
  userNames,
  userNamesToExclude,
  onChange,
}: ArtistsFilterListProps) {
  const excludedSet = useMemo(
    () => new Set(userNamesToExclude),
    [userNamesToExclude]
  );

  return (
    <>
      {userNames.map((userName) => (
        <li key={userName}>
          <MPCheckbox
            label={userName}
            name={userName}
            isChecked={excludedSet.has(userName)}
            onChange={(
              event: ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => onChange(checked, userName)}
          />
        </li>
      ))}
    </>
  );
}
