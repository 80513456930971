import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from '../MPSVGIcon';

const VisaIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M10.6472 15.1682H8.99906L10.0291 8.83785H11.6774L10.6472 15.1682ZM7.61256 8.83785L6.04131 13.1919L5.85538 12.2543L5.85555 12.2547L5.30099 9.40782C5.30099 9.40782 5.23393 8.83785 4.51917 8.83785H1.92159L1.89111 8.94503C1.89111 8.94503 2.68546 9.1103 3.6151 9.6686L5.04698 15.1684H6.7642L9.38633 8.83785H7.61256ZM20.5759 15.1682H22.0892L20.7698 8.83768H19.4449C18.8331 8.83768 18.6841 9.30944 18.6841 9.30944L16.2261 15.1682H17.9441L18.2877 14.2279H20.3829L20.5759 15.1682ZM18.7623 12.9289L19.6283 10.56L20.1155 12.9289H18.7623ZM16.3549 10.3602L16.5901 9.00074C16.5901 9.00074 15.8644 8.72473 15.1078 8.72473C14.2899 8.72473 12.3476 9.08219 12.3476 10.8204C12.3476 12.4558 14.6272 12.4761 14.6272 13.3352C14.6272 14.1942 12.5825 14.0403 11.9077 13.4986L11.6627 14.92C11.6627 14.92 12.3986 15.2774 13.523 15.2774C14.6477 15.2774 16.3444 14.6951 16.3444 13.1101C16.3444 11.4642 14.0444 11.311 14.0444 10.5954C14.0445 9.87959 15.6496 9.97153 16.3549 10.3602Z"
      fill="#2566AF"
    />
    <path
      d="M5.86923 12.254L5.31466 9.40713C5.31466 9.40713 5.2476 8.83716 4.53284 8.83716H1.93527L1.90479 8.94435C1.90479 8.94435 3.15328 9.20309 4.35081 10.1725C5.49584 11.0991 5.86923 12.254 5.86923 12.254Z"
      fill="#E6A540"
    />
  </MPSVGIcon>
));

export default VisaIcon;
