import { BigNumber } from 'ethers';
import { RecoilState, useRecoilValue } from 'recoil';
import { graphQLSelector } from 'recoil-relay';
import { Hash } from 'viem';

import ConcreteNFTContractRequest, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import RelayEnvironment from '../../RelayEnvironment';
import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export const RankedAuctionCoreContractQuery: RecoilState<
  NFTContractQuery['response']['nftContract']
> = graphQLSelector({
  environment: RelayEnvironment,
  key: 'rankedAuctionCoreContract',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.RankedAuctionCore }),
});

export default function useDeprecatedRankedAuctionCoreContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();

  const useAuctionBidOnToken = ({
    tokenContractAddress,
    value,
  }: {
    tokenContractAddress: HexString;
    value: BigNumber;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenContractAddress],
      functionName: 'auctionBidOnToken',
      value: value.toBigInt(),
      ...defaultOverrides,
    });

  return {
    useAuctionBidOnToken,
  };
}

// This should eventually replace the default function
export function useRankedAuctionCoreContract() {
  const contract = useRecoilValue(RankedAuctionCoreContractQuery);
  return useDeprecatedRankedAuctionCoreContract({
    abi: JSON.parse(contract.abidata).abi,
    contractAddress: contract.address as Hash,
  });
}
