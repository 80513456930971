import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import useResizeObserver from 'use-resize-observer';

const useOnBodyResizeState = atom({
  default: { height: undefined, width: undefined },
  key: 'useOnBodyResize',
});

export function useSetBodyResize<T extends HTMLElement>(ref) {
  const setBodyResize = useSetRecoilState(useOnBodyResizeState);
  useResizeObserver<T>({
    onResize: (size) => setBodyResize(size),
    ref,
  });
}

export default function useOnBodyResize() {
  return useRecoilValue(useOnBodyResizeState);
}
