import { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import { noop } from 'lodash';
import { useExplore } from 'pages/explore';
import { useFragment, usePreloadedQuery } from 'react-relay';
import { ConcreteRequest, OperationType, ReaderFragment } from 'relay-runtime';

import { joinClasses } from '@mp-frontend/core-utils';

import BaseCarousel from 'components/carousel/Carousel';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import { useTrackingContext } from 'components/trackingContext';
import { EXPLORE_TYPE_GRAPHQL_NAMESPACE } from 'pages/explore/Cards';
import { ExploreType } from 'pages/explore/types';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as homepageCardStyles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

const MAX_ELEMENTS = 12;

interface BaseSearchCarouselProps<TElement> {
  exploreType: ExploreType;
  fragmentConcreteRequest: ReaderFragment;
  queryConcreteRequest: ConcreteRequest;
  renderElement: (element: TElement, className: string) => ReactNode;
  search: string;
}

function BaseSearchCarousel<
  TQuery extends OperationType,
  TQueryFragmentKey extends KeyType,
  TElement
>({
  search,
  query,
  exploreType,
  fragmentConcreteRequest,
  queryConcreteRequest,
  renderElement,
}: BaseSearchCarouselProps<TElement> & {
  query: WithLoadQueryProps<TQuery>;
}) {
  const explore = useExplore();
  const { source } = useTrackingContext();
  const gtm_container = `explore:${exploreType.toLocaleLowerCase()}`;

  const result = useFragment<TQueryFragmentKey>(
    fragmentConcreteRequest,
    usePreloadedQuery<TQuery>(queryConcreteRequest, query.queryRef) as any
  );

  const elements = useMemo(
    () =>
      result[EXPLORE_TYPE_GRAPHQL_NAMESPACE[exploreType]].results.edges.map(
        (edge: { node: TElement }) => edge.node
      ),
    [exploreType, result]
  );

  const handleViewAllClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      explore.open(source, {
        filters: { query: search },
        hideHero: true,
        type: exploreType,
      });
    },
    [source, explore, search, exploreType]
  );

  return (
    !!elements.length && (
      <BaseCarousel
        header={<>{exploreType}</>}
        viewAllLink={elements.length >= MAX_ELEMENTS ? exploreType : null}
        onViewAllClick={handleViewAllClick}
        items={elements.map((element, idx) =>
          renderElement(
            element,
            joinClasses(homepageCardStyles.card, {
              [homepageCardStyles.last]: idx === elements.length - 1,
            })
          )
        )}
        containerName={gtm_container}
        logViewAbleCardsToGA={noop}
      />
    )
  );
}

export default function SearchCarousel<
  TQuery extends OperationType,
  TQueryFragmentKey extends KeyType,
  TElement
>(props: BaseSearchCarouselProps<TElement>) {
  const Carousel = useMemo(
    () =>
      withLoadQuery<any>(
        BaseSearchCarousel<TQuery, TQueryFragmentKey, TElement>,
        { query: { concreteRequest: props.queryConcreteRequest } },
        {
          grouppedLoadingKey: `search:${props.exploreType.toLocaleLowerCase()}-carousel`,
        }
      ),
    [props.exploreType, props.queryConcreteRequest]
  );

  return (
    <DefaultErrorBoundary suspenseless hideState>
      <Carousel
        {...props}
        query={{
          variables: { first: MAX_ELEMENTS, query: props.search || '' },
        }}
      />
    </DefaultErrorBoundary>
  );
}
