import { useCallback } from 'react';

import { MPActionButton, MPChip } from '@mp-frontend/core-components';

import { DropPhaseEnum } from 'types/__generated__/graphql';

import FiltersGroup from 'components/filters/FilterGroup';
import FilterScrollContainer from 'components/filters/FilterScrollContainer';
import CSSGlobal from 'types/enums/css/Global';

import useFiltersApplied from '../hooks/useFiltersApplied';
import { ExhibitionsFiltersState } from './Filters';

interface ChipsProps {
  filters: Partial<ExhibitionsFiltersState>;
  onChange: (state: Partial<ExhibitionsFiltersState>) => void;
  onReset: () => void;
}

export function ChipsList({ filters, onChange }: Omit<ChipsProps, 'onReset'>) {
  return (
    <>
      {!!filters.query && (
        <MPChip
          label={`Results for: "${filters.query}"`}
          onDelete={() => onChange({ query: '' })}
        />
      )}
      {!!filters.status[DropPhaseEnum.SoldOut] && (
        <MPChip
          label="Sold Out"
          onDelete={() =>
            onChange({
              status: { ...filters.status, [DropPhaseEnum.SoldOut]: false },
            })
          }
        />
      )}
      {!!filters.showFollowingArtists && (
        <MPChip
          label="Artists You Follow"
          onDelete={() => onChange({ showFollowingArtists: false })}
        />
      )}
      {!!filters.artists.length && (
        <>
          {filters.artists.map((artist) => (
            <MPChip
              key={artist}
              label={artist}
              onDelete={() =>
                onChange({
                  artists: filters.artists.filter((a) => a !== artist),
                })
              }
            />
          ))}
        </>
      )}
      {!!filters.showFollowingCurators && (
        <MPChip
          label="Curators You Follow"
          onDelete={() => onChange({ showFollowingCurators: false })}
        />
      )}
      {!!filters.curators.length && (
        <>
          {filters.curators.map((curator) => (
            <MPChip
              key={curator}
              label={curator}
              onDelete={() =>
                onChange({
                  curators: filters.curators.filter((c) => c !== curator),
                })
              }
            />
          ))}
        </>
      )}
    </>
  );
}

export default function Chips({ filters, onChange, onReset }: ChipsProps) {
  const areFiltersApplied = useFiltersApplied(filters);
  const handleReset = useCallback(() => onReset(), [onReset]);

  return areFiltersApplied ? (
    <FilterScrollContainer>
      <FiltersGroup>
        <ChipsList filters={filters} onChange={onChange} />
        <MPActionButton
          className={CSSGlobal.Width.maxContent}
          variant="tertiary"
          onClick={handleReset}
        >
          Clear All
        </MPActionButton>
      </FiltersGroup>
    </FilterScrollContainer>
  ) : null;
}
