import { SvgIcon } from '@mui/material';

export default function InstagramIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.0001 10.1243C10.5252 10.1243 9.32275 11.3267 9.32275 12.8016C9.32275 14.2766 10.5252 15.471 12.0001 15.471C13.4751 15.471 14.6775 14.2686 14.6775 12.8016C14.6775 11.3347 13.4751 10.1243 12.0001 10.1243Z"
        fill="currentColor"
      />
      <path
        d="M4 4V20H20V4H4ZM12 17.8758C9.2024 17.8758 6.91784 15.5992 6.91784 12.8016C6.91784 10.004 9.19439 7.71944 12 7.71944C14.8056 7.71944 17.0822 9.99599 17.0822 12.8016C17.0822 15.6072 14.8056 17.8758 12 17.8758ZM17.4028 7.76754C16.8016 7.76754 16.3126 7.27856 16.3126 6.67735C16.3126 6.07615 16.8016 5.58717 17.4028 5.58717C18.004 5.58717 18.493 6.07615 18.493 6.67735C18.493 7.27856 18.004 7.76754 17.4028 7.76754Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
