.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  cursor: default;
}

.verticalPadding {
  padding: 32px 0;
}

.biography {
  white-space: pre-wrap;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headerImage {
  & a,
  & img {
    display: block;
    height: 68px;
    flex-shrink: 0;
    width: 68px;
  }

  & img {
    border-radius: 50%;
    background-image: var(--color-SolidNeutralGray1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.headerFrame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
}

a.headerArtist {
  display: inline-block;
  max-width: 100%;
}

.counters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.countersContent {
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > .text {
    color: var(--color-SolidNeutralGray5);
  }

  & > .value {
    color: var(--color-commonBlack);
  }
}

.otherCollaboratorsTitle {
  padding-top: 32px;
}

.otherCollaboratorsBody {
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  display: flex;
  padding: 0;
  flex-shrink: 0;
}

.otherCollaboratorsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otherCollaboratorsItemName {
  white-space: nowrap;
}

.otherCollaboratorsItemProfile {
  display: flex;
  align-items: center;
  gap: 8px;
}

.otherCollaboratorsItemImage {
  & a,
  & img {
    display: block;
    height: 24px;
    width: 24px;
    flex-shrink: 0;
  }

  & img {
    border-radius: 50%;
    background-image: var(--color-SolidNeutralGray1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.otherCollaboratorItemPopover {
  padding: 24px;
  width: 290px;

  & :global(.defaultLink) {
    color: var(--color-commonBlack);

    &:hover {
      color: var(--color-SolidNeutralGray3);
      text-decoration: none;
    }

    &:active {
      color: var(--color-SolidNeutralGray1);
    }
  }
}
