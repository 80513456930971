import { MPFonts } from '@mp-frontend/core-components';
import { CommentIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import useCommentProduct from 'hooks/product/useCommentProduct';
import { NFTType } from 'types/graphql/NFT';
import toNumericShorthand from 'utils/numericShorthand';

import * as styles from 'css/pages/product/ProductSocialBar.module.css';

interface ProductCommentProps extends Record<string, unknown> {
  nft: NFTType;
}

export default function ProductComment({ nft }: ProductCommentProps) {
  const [
    commentsCount,
    didUserComment,
    onOpenMouseClick,
    onOpenKeyboardPress,
    Dialog,
  ] = useCommentProduct({ nft });

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={onOpenMouseClick}
        onKeyPress={onOpenKeyboardPress}
        className={joinClasses(
          'hoverableLink',
          styles.action,
          styles.comments,
          didUserComment ? styles.byCurrentUser : null
        )}
      >
        <CommentIcon
          variant={didUserComment ? 'filled' : 'outlined'}
          sx={{ fontSize: '18px' }}
        />
        <span className={MPFonts.notificationBoldText}>
          {commentsCount > 0 ? toNumericShorthand(commentsCount) : 'Comment'}
        </span>
      </div>

      {Dialog}
    </>
  );
}
