.notificationsSettingsBody {
  align-items: flex-start;
  padding: 24px 32px;
}

.instructionBox {
  margin-bottom: 5px;
}

.notificationsSettingsRow {
  justify-content: space-between;
  padding: 24px 0;
}

.notificationsSettingsRowLeft {
  align-items: center;
  flex: 1;
  gap: 12px;
}

span.notificationsSettingsRowRight {
  flex-shrink: 1;
  margin: 0;
}

.centerIcon {
  width: 17px;
  flex-shrink: 0;
  flex-grow: 0;
}

.notificationsSettingsDivider {
  border-bottom: 1px solid var(--color-divider);
  width: 100%;
}
