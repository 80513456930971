/**
 * @generated SignedSource<<c5541883828da5c62c208b6b2919aae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnlockNFTUnlockableArguments = {
  nftId: number;
  amountInCents?: number | null;
  rememberCard?: boolean | null;
  shippingAddressLine1?: string | null;
  shippingAddressLine2?: string | null;
  shippingAddressPostalCode?: string | null;
  shippingAddressCity?: string | null;
  shippingAddressState?: string | null;
  shippingAddressCountry?: string | null;
  shippingAddressFullName?: string | null;
  shippingAddressPhoneNumber?: string | null;
  paymentIntentId?: string | null;
  paymentMethodId?: string | null;
};
export type NFTUnlockNftUnlockableMutation$variables = {
  requestData: UnlockNFTUnlockableArguments;
};
export type NFTUnlockNftUnlockableMutation$data = {
  readonly unlockNftUnlockable: {
    readonly success: boolean | null;
  } | null;
};
export type NFTUnlockNftUnlockableMutation = {
  variables: NFTUnlockNftUnlockableMutation$variables;
  response: NFTUnlockNftUnlockableMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "UnlockNFTUnlockableMutation",
    "kind": "LinkedField",
    "name": "unlockNftUnlockable",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTUnlockNftUnlockableMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTUnlockNftUnlockableMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a155f390b60db6593211eadf93ffbb6",
    "id": null,
    "metadata": {},
    "name": "NFTUnlockNftUnlockableMutation",
    "operationKind": "mutation",
    "text": "mutation NFTUnlockNftUnlockableMutation(\n  $requestData: UnlockNFTUnlockableArguments!\n) {\n  unlockNftUnlockable(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "c33b6ca4e581e221798245c885a5975f";

export default node;
