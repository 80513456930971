import { Dispatch, SetStateAction, startTransition, useState } from 'react';
import { useMutation } from 'react-relay';

import { MPActionButton } from '@mp-frontend/core-components';

import CreateSaleWithCustodialWalletMutation from 'graphql/__generated__/ListingCreateSaleWithCustodialWalletMutation.graphql';

import useLogging from 'hooks/useLogging';
import { NFTType } from 'types/graphql/NFT';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';
import Content from './Content';
import useBuyNowState from './useBuyNowState';

interface CustodialProps {
  nft: NFTType;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

export default function Custodial({ nft, setOwnershipState }: CustodialProps) {
  const { logNFTException } = useLogging();
  const [isValidating, setIsValidating] = useState(false);

  const [commitCreateSaleWithCustodialWalletMutation] = useMutation(
    CreateSaleWithCustodialWalletMutation
  );

  const buyNowState = useBuyNowState(nft);
  const [buyNowPrice] = buyNowState;

  const [error, setError] = useState<Error>(undefined);

  const createOrUpdateSaleWithCustodialWallet = async ({
    productId,
    salePrice,
    availableForSale,
  }) =>
    new Promise((resolve, reject) => {
      setError(undefined);
      commitCreateSaleWithCustodialWalletMutation({
        onCompleted: (data: any) => {
          resolve(data);
        },
        onError(err) {
          setError(err);
          reject(err);
        },
        variables: {
          request_data: {
            availableForSale,
            productId,
            salePrice,
          },
        },
      });
    });

  const confirmCustodialListing = async () => {
    try {
      setIsValidating(true);
      await createOrUpdateSaleWithCustodialWallet({
        availableForSale: true,
        productId: parseInt(nft.listing.pk, 10),
        salePrice: buyNowPrice,
      });
      setIsValidating(false);
      startTransition(() => {
        setOwnershipState(ProductOwnerStates.FIXED_SALE_LISTED);
      });
    } catch (err) {
      setIsValidating(false);
      setError(err);
      logNFTException(nft.pk, err);
    }
  };

  return (
    <Content
      setOwnershipState={setOwnershipState}
      nft={nft}
      error={error}
      buyNowState={buyNowState}
      button={
        <MPActionButton
          fullWidth
          onClick={confirmCustodialListing}
          disabled={isValidating || (parseFloat(buyNowPrice) || 0) === 0}
          size="large"
        >
          {nft.listing.liveSale ? 'Confirm' : 'List Artwork'}
        </MPActionButton>
      }
    />
  );
}
