import { useCallback, useState } from 'react';

import { AuctionTypeEnum } from 'types/__generated__/graphql';

import useForceUpdate from 'hooks/useForceUpdate';
import { ListingType } from 'types/graphql/Listing';
import { NFTDropMetadataType, NFTRankedAuctionType } from 'types/graphql/NFT';
import hasDatePassed from 'utils/hasDatePassed';

type SimplifiedNFTType = {
  listing: Pick<
    ListingType,
    | 'availableForBid'
    | 'availableForPurchase'
    | 'rankedAuction'
    | 'productAuction'
  >;
  metadata: {
    dropMetadata: Pick<NFTDropMetadataType, 'dropsAt'> & {
      isSoldInCollectionDrop?: NFTDropMetadataType['isSoldInCollectionDrop'];
    };
  };
};

export default function useProductTimers(nft: SimplifiedNFTType) {
  const [forceUpdate] = useForceUpdate();
  const [rankedAuction, setAuction] = useState<NFTRankedAuctionType>(
    nft.listing.rankedAuction
  );

  const showRankedAuctionTimer =
    rankedAuction?.auctionType === AuctionTypeEnum.Ranked;
  const showProductAuctionTimer =
    !showRankedAuctionTimer &&
    !!nft.listing.productAuction &&
    !hasDatePassed(nft.listing.productAuction.endsAt);
  const showDropsTimer =
    !showRankedAuctionTimer &&
    !showProductAuctionTimer &&
    !!nft.metadata.dropMetadata &&
    !nft.metadata.dropMetadata.isSoldInCollectionDrop &&
    !nft.listing.availableForBid &&
    !nft.listing.availableForPurchase &&
    !hasDatePassed(nft.metadata.dropMetadata.dropsAt);

  const onProductTimerEnd = useCallback(() => forceUpdate(), [forceUpdate]);
  const onAuctionUpdate = useCallback(
    (partialAuction: NFTRankedAuctionType) => {
      setAuction((prevAuction) => ({ ...prevAuction, ...partialAuction }));
      forceUpdate();
    },
    [forceUpdate]
  );

  return {
    onAuctionUpdate,
    onProductTimerEnd,
    showDropsTimer,
    showProductAuctionTimer,
    showRankedAuctionTimer,
    showTimer:
      showDropsTimer || showProductAuctionTimer || showRankedAuctionTimer,
  };
}
