/**
 * Light utilities on top of wagmi's useConnect
 */

import { useMemo } from 'react';
import { useConnect, UseConnectParameters } from 'wagmi';

import { WalletId } from 'utils/jwt/walletUtils';

import findConnector from './findConnector';
import useGetAccounts from './useGetAccounts';

export default function useMPConnect(props?: UseConnectParameters) {
  const wagmiConnect = useConnect(props);
  const accountCache = useGetAccounts(wagmiConnect.connectors);
  return useMemo(
    () => ({
      findConnector: (connectorId: WalletId) =>
        findConnector(wagmiConnect.connectors, connectorId),
      findConnectorByAddress: accountCache.getConnectorByAddress,
      ...wagmiConnect,
    }),
    [wagmiConnect, accountCache.getConnectorByAddress]
  );
}
