import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/cards/LogoCard.module.css';

interface LogoCardProps {
  imageUrl: string;
  name: string;
  viewUrl: string;
  description?: string;
}

export default function LogoCard({
  name,
  description,
  imageUrl,
  viewUrl,
}: LogoCardProps) {
  return (
    <a
      href={viewUrl}
      target="_blank"
      rel="noreferrer"
      title={name}
      className={joinClasses(
        MPColorClass.CommonBlack,
        MPBackgroundColorClass.CommonWhite,
        'anchor',
        CSSGlobal.Flex.Col,
        CSSGap[8],
        styles.container,
        { [styles.full]: !!description }
      )}
    >
      <div
        className={styles.logo}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      {!!description && (
        <div className={MPFonts.textNormalMedium}>{description}</div>
      )}
    </a>
  );
}
