.productDescription {
  cursor: default;
  white-space: pre-wrap;
}

.productionDescriptionMinHeight {
  min-height: 26px;
}

.productDescriptionVideo {
  height: 200px;
  margin-bottom: 20px;
}
