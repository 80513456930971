import MPSVGIcon from './MPSVGIcon';

export default function WidthIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <rect
        x="5.60021"
        y="5.59997"
        width="12.7998"
        height="12.8003"
        stroke="var(--color-SolidNeutralGray1)"
        strokeWidth="3.19994"
        fill="var(--color-commonWhite)"
      />
      <line
        x1="20"
        y1="5.59997"
        x2="3.99999"
        y2="5.59997"
        stroke="currentColor"
        strokeWidth="3.19994"
      />
    </MPSVGIcon>
  );
}
