import { Route } from 'react-router-dom';

import PageErrorBoundary from 'components/ErrorBoundaries/PageErrorBoundary';
import { TrackingContext } from 'components/trackingContext';
import { EcommerceSourceType } from 'GTM';

import ProductPage from './ProductPage';

const ProductRoute = (
  <Route
    path="product/:nftSlug"
    element={
      <PageErrorBoundary>
        <TrackingContext source={EcommerceSourceType.ProductPage}>
          <ProductPage />
        </TrackingContext>
      </PageErrorBoundary>
    }
  />
);

export default ProductRoute;
