import { SvgIcon } from '@mui/material';

export default function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.1436 21.2342L18.7344 16.7175C20.1497 14.9554 20.9989 12.7026 20.9989 10.2491C20.9989 4.59479 16.5136 0 11.0049 0C5.49621 0 1 4.59479 1 10.2491C1 15.9033 5.48532 20.4981 11.0049 20.4981C12.7468 20.4981 14.3689 20.0297 15.795 19.2268L20.4545 24L23.1436 21.2454V21.2342ZM4.82123 10.2491C4.82123 6.74721 7.58645 3.9145 11.0049 3.9145C14.4233 3.9145 17.1885 6.74721 17.1885 10.2491C17.1885 13.7509 14.4233 16.5836 11.0049 16.5836C7.58645 16.5836 4.82123 13.7509 4.82123 10.2491Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
