import { useCallback, useRef, useState } from 'react';
import {
  Formik,
  FormikErrors,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useMutation } from 'react-relay';
import * as Yup from 'yup';

import {
  MPActionButton,
  MPAnimations,
  MPBackgroundColorClass,
  MPColorClass,
  MPDialogProps,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
  MPTextFieldProps,
} from '@mp-frontend/core-components';
import { ViewIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountChangePasswordMutationType, {
  AccountChangePasswordMutation,
} from 'graphql/__generated__/AccountChangePasswordMutation.graphql';
import { ChangePasswordArguments, MpErrors } from 'types/__generated__/graphql';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSPadding from 'types/enums/css/Padding';
import emptyFunc from 'utils/emptyFunc';
import saveBearerToken from 'utils/jwt/saveBearerToken';

const DIALOG_TITLE = 'Change Password';
const CUSTOM_ERROR_MESSAGE = {
  [MpErrors.LoginNotValid]: 'The current password you entered is incorrect.',
};

function PasswordTextField({
  isVisible,
  onVisibilityToggle,
  autoComplete = 'new-password',
  ...props
}: Pick<
  MPTextFieldProps,
  | 'autoComplete'
  | 'label'
  | 'name'
  | 'placeholder'
  | 'required'
  | 'value'
  | 'onChange'
  | 'onBlur'
> & {
  error: string;
  isVisible: boolean;
  onVisibilityToggle: () => void;
}) {
  return (
    <MPStyledTextField
      autoComplete={autoComplete}
      endAdornment={
        <ViewIcon
          className={joinClasses(
            CSSGlobal.Cursor.Pointer,
            isVisible
              ? MPAnimations.Color.DarkToLight
              : MPAnimations.Color.LightToDark,
            isVisible
              ? MPColorClass.CommonBlack
              : MPColorClass.SolidNeutralGray1
          )}
          fontSize="16"
          onClick={onVisibilityToggle}
        />
      }
      fullWidth
      type={isVisible ? 'text' : 'password'}
      {...props}
    />
  );
}

type FormikValues = {
  confirmNewPassword: string;
  newPassword: string;
  oldPassword: string;
};

const defaultFormikValues: FormikValues = {
  confirmNewPassword: '',
  newPassword: '',
  oldPassword: '',
};

const defaultFormikProps = {
  handleSubmit: emptyFunc,
  isSubmitting: false,
};

const ChangePasswordSchema = Yup.object().shape({
  confirmNewPassword: Yup.string()
    .required('Password cannot be left blank.')
    .when(['newPassword'], (newPassword: string, schema) =>
      schema.test({
        message:
          'Passwords do not match. Please ensure both fields are the same.',
        name: 'matches',
        test: (value: string) => value === newPassword,
      })
    ),
  newPassword: Yup.string()
    .required('New password cannot be left blank.')
    /* eslint-disable-next-line no-template-curly-in-string */
    .min(8, 'Password must be at least ${min} characters.')
    /* eslint-disable-next-line no-template-curly-in-string */
    .max(128, 'Password must be at most ${max} characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,128}$/,
      'Please use at least 8 characters, with at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character.'
    )
    .notOneOf(
      [Yup.ref('oldPassword')],
      'New password cannot be the same as your current password.'
    ),
  oldPassword: Yup.string().required('Current password cannot be left blank.'),
});

function validateForm(values: FormikValues) {
  try {
    validateYupSchema(values, ChangePasswordSchema, true);
  } catch (err) {
    return yupToFormErrors(err);
  }
  return undefined;
}

function renderError(
  error:
    | string
    | string[]
    | FormikErrors<FormikValues>
    | FormikErrors<FormikValues>[],
  isTouched?: boolean
) {
  if (!error || !isTouched) return undefined;

  const convertFormikErrors = (errors: string | FormikErrors<FormikValues>) =>
    typeof errors === 'string' ? errors : Object.values(errors).join(', ');
  return Array.isArray(error)
    ? error.map(convertFormikErrors).join(', ')
    : convertFormikErrors(error);
}

export default function PasswordDialog({
  onClose,
}: Pick<MPDialogProps, 'onClose'>) {
  const formikPropsRef = useRef(defaultFormikProps);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const handleToggleOldPasswordVisibility = useCallback(
    () => setIsOldPasswordVisible((prev) => !prev),
    []
  );
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const handleToggleNewPasswordVisibility = useCallback(
    () => setIsNewPasswordVisible((prev) => !prev),
    []
  );
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const handleToggleConfirmPasswordVisibility = useCallback(
    () => setIsConfirmPasswordVisible((prev) => !prev),
    []
  );

  const [changePassword, isChangingPassword] =
    useMutation<AccountChangePasswordMutation>(
      AccountChangePasswordMutationType
    );
  const handleSubmit = useCallback(
    (values: FormikValues, { setSubmitting, setFieldError }) => {
      if (isChangingPassword) return;

      const variables: ChangePasswordArguments = {
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
      };

      changePassword({
        onCompleted: (result) => {
          setSubmitting(false);
          saveBearerToken(result.changePassword.token);
          setIsSuccess(true);
        },
        onError: (error) => {
          const message = CUSTOM_ERROR_MESSAGE[error.name] || error.message;

          if (
            [MpErrors.LoginNotValid, MpErrors.NotActiveUser].includes(
              error.name as MpErrors
            )
          ) {
            setFieldError('oldPassword', message);
          } else {
            setFieldError('newPassword', message);
          }
          setSubmitting(false);
        },
        variables,
      });
      setSubmitting(true);
    },
    [changePassword, isChangingPassword]
  );

  const handleSuccessfulClose = useCallback(() => {
    onClose({}, 'closeIconClick');
    setIsSuccess(false);
  }, [onClose]);

  return !isSuccess ? (
    <MPStandardDialog title={DIALOG_TITLE} onClose={onClose}>
      <Formik<FormikValues>
        initialValues={defaultFormikValues}
        validate={validateForm}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => {
          formikPropsRef.current = props;

          return (
            <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[24])}>
              <PasswordTextField
                autoComplete="password"
                error={renderError(
                  props.errors.oldPassword,
                  props.touched.oldPassword
                )}
                label="Current Password"
                name="oldPassword"
                placeholder="Enter your current password"
                required
                value={props.values.oldPassword}
                isVisible={isOldPasswordVisible}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                onVisibilityToggle={handleToggleOldPasswordVisibility}
              />

              <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
                <PasswordTextField
                  autoComplete="new-password"
                  error={renderError(
                    props.errors.newPassword,
                    props.touched.newPassword
                  )}
                  label="New Password"
                  name="newPassword"
                  placeholder="Create your new password"
                  required
                  value={props.values.newPassword}
                  isVisible={isNewPasswordVisible}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  onVisibilityToggle={handleToggleNewPasswordVisibility}
                />

                <PasswordTextField
                  autoComplete="new-password"
                  error={renderError(
                    props.errors.confirmNewPassword,
                    props.touched.confirmNewPassword
                  )}
                  name="confirmNewPassword"
                  placeholder="Confirm your new password"
                  required
                  value={props.values.confirmNewPassword}
                  isVisible={isConfirmPasswordVisible}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  onVisibilityToggle={handleToggleConfirmPasswordVisibility}
                />
              </div>

              <MPActionButton
                disabled={props.isSubmitting || !props.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="primary"
                onClick={props.submitForm}
              >
                Update
              </MPActionButton>

              <div
                className={joinClasses(
                  MPFonts.paragraphSmall,
                  MPBackgroundColorClass.BackgroundDefault,
                  CSSGlobal.Cursor.Default,
                  CSSPadding.AROUND[24]
                )}
              >
                Changing your password will log you out of all your active
                sessions except the one you&apos;re using at this time.
              </div>
            </div>
          );
        }}
      </Formik>
    </MPStandardDialog>
  ) : (
    <MPStandardDialog title={DIALOG_TITLE} onClose={handleSuccessfulClose}>
      <div
        className={joinClasses(
          CSSGlobal.Cursor.Default,
          CSSGlobal.Flex.Col,
          CSSGap[16]
        )}
      >
        <div className={MPFonts.paragraphSmall}>
          You have successfully updated your password
        </div>

        <MPActionButton
          fullWidth
          size="large"
          variant="primary"
          onClick={handleSuccessfulClose}
        >
          Continue
        </MPActionButton>
      </div>
    </MPStandardDialog>
  );
}
