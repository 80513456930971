import { forwardRef } from 'react';
import { keyframes, styled } from '@mui/system';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const rectWidthHeight = keyframes`
  0%, 100% { width: 5px; height: 5px; }
  50% { width: 10px; height: 10px; }
`;

const rectPosition = keyframes`
  0%, 100% { x: 1.7px; y: 1.7px; }
  50% { x: 4.7px; y: 4.7px; }
`;

const circleRadius = keyframes`
  0%, 100% { r: 7.5px; }
  50% { r: 3.25px; }
`;

const circlePosition = keyframes`
  0%, 100% { cx: 12px; cy: 12px; }
  50% { cx: 17px; cy: 17px; }
`;

const StyledRect = styled('rect')`
  animation: ${rectWidthHeight} 2s infinite, ${rectPosition} 2s infinite;
  will-change: width, height, x, y;
`;

const StyledCircle = styled('circle')`
  animation: ${circleRadius} 2s infinite, ${circlePosition} 2s infinite;
  will-change: r, cx, cy;
`;

const LoaderIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <StyledRect x="1.7" y="1.7" width="5" height="5" />
    <StyledCircle cx="12" cy="12" r="7.5" />
  </MPSVGIcon>
));

export default LoaderIcon;
