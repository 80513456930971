.notificationsSection {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notificationsSection:focus {
  outline: none;
}

.notificationsSectionNoCount {
  padding: 8px;
  border-radius: 50%;
}

.notificationsSectionNoCount:not(.isUnified):hover path {
  color: var(--color-primaryMain);
}

.notificationsSectionCount {
  padding: 8px 10px;
  border-radius: 500px;
}

.notificationsCount {
  margin-left: 4px;
  min-width: 12px;
  text-align: left;
}

.notificationsDrawer {
  position: fixed;
  top: 80px;
  right: 0px;
  width: 25vw;
  height: 100%;
  z-index: 999;
  background: var(--color-commonWhite);
  overflow: hidden;
}

@media (--mq-is-mobile) {
  .notificationsDrawer {
    position: fixed;
    top: 63px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 1000;
    border: none;
  }
}

/* This section is for Unified Navbar code, */
.notificationsSectionNoCount.isUnified, .notificationsSectionCount.isUnified {
  padding: 0px;
  background-color: var(--color-commonWhite);
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

div.unifiedNoticationList {
  height: 100%;
}

.notificationsSectionNoCount.isUnified {
  color: var(--color-SolidNeutralGray5);
}

.notificationsSectionCount.isUnified  {
  color: var(--color-goldMain);
}

.notificationsSectionCount.isUnified:hover > svg, .notificationsSectionCount.isUnified:hover > .notificationsCount  {
  opacity: 0.5;
  transition: opacity 300ms ease-in-out;
}

.notificationsSectionCount.isUnified:active > svg, .notificationsSectionCount.isUnified:active > .notificationsCount  {
  opacity: 0.2;
  transition: opacity 1200ms ease-in-out;
}

.notificationsCount.isUnified {
  margin-left: 6px;
}

.notificationSettingsWrapperIcon {
  background-color: var(--color-primaryMain);
  color: var(--color-commonWhite);
}

.notificationWrapperIcon {
  color: var(--color-SolidNeutralGray5);
}

div.notificationWrapperIcon {
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid var(--color-SolidNeutralGray1);
}

@media (--mq-is-not-mobile) {
  .notificationsSectionCount.isUnified {
    height: 32px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 16px;
  }
}

@media(--mq-is-mobile) {
  .notificationsSectionCount.isUnified  {
    color: var(--color-commonWhite);
    background-color: var(--color-goldMain);
  }
}
