import { useMutation } from 'react-relay';
import { useDisconnect } from 'wagmi';

import SessionLogoutMutation from 'graphql/__generated__/SessionLogoutMutation.graphql';
import { LogoutMutation } from 'types/__generated__/graphql';

import ROUTES from 'constants/Routes';
import deleteBearerToken from 'utils/jwt/deleteBearerToken';
import deleteBearerTokenExpireAt from 'utils/jwt/deleteBearerTokenExpireAt';

import Session from 'Session';

export default function useLogout() {
  const { disconnectAsync } = useDisconnect();
  const [commitLogoutMutation] = useMutation(SessionLogoutMutation);

  /**
   * Commit `LogoutMutation` asynchronously
   * @returns LogoutMutation
   */
  const commitLogoutMutationAsync = (): Promise<LogoutMutation> =>
    new Promise((resolve, reject) => {
      commitLogoutMutation({
        onCompleted: resolve,
        onError: reject,
        variables: {},
      });
    });

  /**
   * Logs out the current user, deletes local user data, and redirects to home
   * page
   */
  const logout = async (): Promise<void> => {
    // Commit "SessionLogoutMutation"
    await commitLogoutMutationAsync();

    // Delete bearer token and last authentication time from localStorage
    deleteBearerToken();
    deleteBearerTokenExpireAt();

    // Delete user session recoil value
    Session.deleteSession();

    // Reload session for statsig key
    await Session.awaitSessionData();

    // Disconnect from wallet
    await disconnectAsync();

    // Trigger hard reload so Django app loads if needed
    (window as any).location = ROUTES.HOME();
  };

  return logout;
}
