.container {
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-around;
  outline: none;
  padding: 0;
}

.container > .icon {
  align-items: center;
  border-radius: 100%;
  color: inherit;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  &.defaultVariant {
    color: var(--color-SolidNeutralGray5);
    background: var(--color-backgroundDefault);

    &:hover {
      color: var(--color-SolidNeutralGray3);
    }

    &:active {
      color: var(--color-SolidNeutralGray1);
    }
  }

  &.premiumVariant {
    color: var(--color-commonWhite);
    background: var(--color-goldMain);

    &:hover {
      background: var(--color-goldP60);
    }

    &:active {
      background: var(--color-goldP20);
    }
  }
}

.container > .title {
  color: var(--color-commonBlack);
}
