import { useCallback } from 'react';
import { Disposable, useMutation, UseMutationConfig } from 'react-relay';

import NFTLikeMutation, {
  NFTLikeMutation as NFTLikeMutationParameters,
} from 'graphql/__generated__/NFTLikeMutation.graphql';

import GTM from '../GTM';

function useToggleLikeMutation(
  currentLikeValue: boolean
): [
  (
    config: UseMutationConfig<NFTLikeMutationParameters>,
    details?: Record<string, string>
  ) => Disposable,
  boolean
] {
  const [like, isLiking] =
    useMutation<NFTLikeMutationParameters>(NFTLikeMutation);

  const trackLike = useCallback(
    (
      config: UseMutationConfig<NFTLikeMutationParameters>,
      details: Record<string, string> = {}
    ) =>
      like({
        ...config,
        onCompleted(response: NFTLikeMutationParameters['response'], errors) {
          GTM.social.trackLike(
            !currentLikeValue,
            config.variables.nftId,
            details
          );
          if (config.onCompleted) config.onCompleted(response, errors);
        },
      }),
    [like, currentLikeValue]
  );
  return [trackLike, isLiking];
}

export default useToggleLikeMutation;
