import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const CopyIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M20.6219 7.06275C19.6211 6.06043 18.3052 5.54999 16.9893 5.54999L17.8511 9.02099C18.0272 9.11379 18.1847 9.21588 18.3237 9.36437C18.6851 9.72632 18.889 10.2182 18.889 10.7286C18.889 11.2391 18.6851 11.731 18.3237 12.0929L14.237 16.1857C13.8756 16.5477 13.3844 16.7518 12.8748 16.7518C12.3651 16.7518 11.8739 16.5477 11.5125 16.1857C11.1511 15.8238 10.9473 15.3319 10.9473 14.8214C10.9473 14.4131 11.077 14.0326 11.2994 13.7078L10.5024 9.86553L9.21435 11.1556C7.19417 13.1788 7.19417 16.4641 9.21435 18.4873C10.2244 19.4989 11.5496 20.0094 12.8748 20.0094C14.1999 20.0094 15.5251 19.4989 16.5352 18.4873L20.6219 14.3945C22.6421 12.3713 22.6421 9.08595 20.6219 7.06275Z"
      fill="currentColor"
    />
    <path
      d="M14.7835 5.52204C13.7734 4.51044 12.4483 4 11.1231 4C9.79793 4 8.47277 4.51044 7.46268 5.52204L3.38525 9.60557C1.36507 11.6288 1.36507 14.9142 3.38525 16.9374C4.38607 17.9397 5.70197 18.4501 7.01787 18.4501L6.15605 14.9791C5.97998 14.8863 5.82244 14.7842 5.68344 14.6357C5.32203 14.2738 5.11816 13.7819 5.11816 13.2715C5.11816 12.761 5.32203 12.2691 5.68344 11.9072L9.77013 7.81439C10.1315 7.45244 10.6227 7.24826 11.1324 7.24826C11.642 7.24826 12.1332 7.45244 12.4946 7.81439C12.856 8.17633 13.0599 8.66821 13.0599 9.17865C13.0599 9.6891 12.856 10.181 12.4946 10.5429L12.3093 10.7285L13.0969 14.5336L14.7835 12.8445C16.8037 10.8213 16.8037 7.53596 14.7835 5.51276V5.52204Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default CopyIcon;
