import {
  Dispatch,
  RefCallback,
  SetStateAction,
  useCallback,
  useState,
} from 'react';

/**
 * Synchronizes updates with reference changes through useState
 *
 * @param initialState Initial State Value
 * @returns  [referenceValue,reference setter,  state setter]
 */
export default function useRefState<T>(
  initialState = null,
  refCallback = null
): [T, RefCallback<T>, Dispatch<SetStateAction<T>>] {
  const [refValue, setRef] = useState<T>(initialState);
  const cachedRef = useCallback(
    (ref) => {
      if (ref) setRef(ref);
      if (ref && refCallback) refCallback(ref);
    },
    [refCallback]
  );
  return [refValue, cachedRef, setRef];
}
