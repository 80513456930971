/**
 * @generated SignedSource<<04fd80571776d338b9a8b279a8dfdec3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GraphPageViewObjectType = "PROFILE" | "NFT";
export type NFTlogViewMutation$variables = {
  objectId: number;
  objectType: GraphPageViewObjectType;
};
export type NFTlogViewMutation$data = {
  readonly logView: {
    readonly success: boolean | null;
  } | null;
};
export type NFTlogViewMutation = {
  variables: NFTlogViewMutation$variables;
  response: NFTlogViewMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objectType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "objectId",
            "variableName": "objectId"
          },
          {
            "kind": "Variable",
            "name": "objectType",
            "variableName": "objectType"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "LogView",
    "kind": "LinkedField",
    "name": "logView",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTlogViewMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NFTlogViewMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f31651ef05472312ac18809b4b803a69",
    "id": null,
    "metadata": {},
    "name": "NFTlogViewMutation",
    "operationKind": "mutation",
    "text": "mutation NFTlogViewMutation(\n  $objectId: Int!\n  $objectType: GraphPageViewObjectType!\n) {\n  logView(requestData: {objectId: $objectId, objectType: $objectType}) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "b361dc95a115ea05fd94a0d50d435345";

export default node;
