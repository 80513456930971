import { Navigate, useLocation } from 'react-router-dom';

import Reload from 'components/Reload';

/**
This exists while we're partially on the django site. All links should contain a trailing slash;
but just in case for ppl who manually type it in and forget we'll redirect to the correct place
Once we're migrated over, a trailing slash should route to the same place as without.
*/
function SlashRedirect() {
  const { pathname } = useLocation();
  return !pathname.endsWith('/') ? (
    <Navigate to={`${pathname}/`} replace />
  ) : (
    <Reload />
  );
}

export default SlashRedirect;
