import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export default function useApprovedCreatorRegistryContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();

  const useSetOboApprovalForAll = ({
    operatorAddress,
    isApproved,
    account,
  }: {
    isApproved: boolean;
    operatorAddress: HexString;
    account?: HexString;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      account: account ?? defaultOverrides.account,
      address: contractAddress,
      args: [operatorAddress, isApproved],
      functionName: 'setOboApprovalForAll',
      gasLimit: defaultOverrides.gasLimit,
    });

  return { useSetOboApprovalForAll };
}
