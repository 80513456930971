import { useCallback, useState } from 'react';

export default function useDownloadFile() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownload = useCallback(
    (url: string, filename: string) => {
      setIsLoading(true);
      fetch(url).then((response) => {
        response
          .clone()
          .blob()
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const linkEl = document.createElement('a');
            linkEl.href = blobUrl;
            linkEl.download = filename;
            linkEl.click();
            setIsLoading(false);
          });
      });
    },
    [setIsLoading]
  );

  return [handleDownload, isLoading] as const;
}
