import { useEffect } from 'react';
import { browserTracingIntegration, init, setUser } from '@sentry/react';
import { useStatsigClient } from '@statsig/react-bindings';

import SENTRY_DSN from 'constants/Sentry';
import { STATSIG_EVENT } from 'constants/Statsig';
import ENV_VARS from 'envVars';

import useSession from './useSession';

export default function useSentryConfig() {
  const session = useSession();
  const statsigClient = useStatsigClient();

  useEffect(() => {
    const tier = session.environment as
      | 'production'
      | 'staging'
      | 'development';

    const dsn =
      tier === 'production' && !ENV_VARS.isDev
        ? SENTRY_DSN.PRODUCTION
        : SENTRY_DSN.DEV;

    init({
      beforeSend(event, hint) {
        statsigClient.logEvent(STATSIG_EVENT.SENTRY.APP_ERROR, hint.event_id);
        return event;
      },
      dsn,
      integrations: [browserTracingIntegration()],
      tracesSampleRate: 1.0,
    });
  }, [session, statsigClient]);

  useEffect(
    () =>
      setUser({
        id: session.account?.pk,
      }),
    [session.account?.pk]
  );
}
