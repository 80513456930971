.productBidContainer {
  width: 342px;
  background-color: var(--color-commonBlack);
  color: var(--color-commonWhite);
  margin-bottom: 24px;
}

.productBidGrid {
  display: grid;
  grid-template-columns: 68px 274px;
  margin: 19px 0 18px 0;
}

.productAcceptBidTitle {
  color: var(--color-goldMain);
}

.productBidAcceptIcon {
  margin-left: 22px;
  height: 32px;
  width: 32px;
  color: var(--color-goldMain);
}

.productOfferTxt {
  margin: 8px 0 2px 0;
  max-width: 90%;
}

.productOfferCTAContainer {
  display: flex;
  justify-content: space-between;
  margin: 18px 16px;
  gap: 12px;
}

.productOfferRejectCTA,
.productOfferRejectCTA:hover {
  color: var(--color-commonWhite);
  border-color: var(--color-commonWhite);
}

.productBidTimeAgo {
  color: var(--color-SolidNeutralGray1);
}

@media (--mq-is-mobile) {
  .productBidContainer {
    width: 100%;
    margin-bottom: 0;
  }
}
