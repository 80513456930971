.productRelatedSlideWrapperSlider {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.productRelatedSlideWrapper {
  background-color: var(--color-commonWhite);
  height: 100vh;
}

.productRelatedHeader {
  margin: 90px 0;
  width: 100%;
  text-align: center;
  cursor: default;
}

.productRelatedSlideShow {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
}

.productRelatedSlideShowSnapScrollContainer {
  overflow: hidden;
  scroll-snap-type: y mandatory;
  height: 100vh;
  position: relative;
  background-color: var(--color-commonWhite);
  scroll-behavior: smooth;
  @media (--mq-is-mobile) {
    height: 92vh;
    overflow: auto;
  }
}

.loadingIcon {
  height: 50px;
  width: 50px;
}

@media (--mq-is-mobile) {
  .productRelatedSlideWrapper {
    padding: 0;
    margin: 0;
  }

  .productRelatedHeader {
    text-align: center;
    top: 0;
    margin: 8px;
  }

  .productRelatedSlideShow {
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;
    top: initial;
  }
}
