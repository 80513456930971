import { useCallback } from 'react';

import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTsSeriesFragment$data } from 'graphql/__generated__/NFTsSeriesFragment.graphql';

import ROUTES from 'constants/Routes';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import useHomepageGTM, { CardType } from 'utils/GTM/homepage';
import toNumericShorthand from 'utils/numericShorthand';

import StandardCard from './StandardCard';

import * as styles from 'css/components/cards/SeriesCard.module.css';

interface SeriesCardStatProps {
  title: string;
  value: string;
}

function SeriesCardStat({ title, value }: SeriesCardStatProps) {
  return (
    <div className={CSSGlobal.Flex.RowSpaceBetween}>
      <div
        className={joinClasses(
          MPFonts.textSmallMedium,
          MPColorClass.SolidNeutralGray5
        )}
      >
        {title}
      </div>
      <div className={MPFonts.textNormalMedium}>{value}</div>
    </div>
  );
}

interface SeriesCardProps {
  series: Pick<
    NFTsSeriesFragment$data,
    | 'author'
    | 'collectionName'
    | 'contract'
    | 'id'
    | 'nftMetadatas'
    | 'numOfOwners'
    | 'pk'
    | 'slug'
    | 'totalSupply'
    | 'totalVolume'
  >;
  className?: string;
}

function SeriesCard({ className, series }: SeriesCardProps) {
  const track = useHomepageGTM();

  const handleClick = useCallback(
    () =>
      track.clickCard(CardType.SeriesCard, series.pk, series.collectionName),
    [track, series.pk, series.collectionName]
  );

  const {
    nftMetadatas,
    collectionName,
    author,
    contract,
    numOfOwners,
    totalSupply,
    totalVolume,
    slug,
  } = series;

  return (
    <StandardCard
      className={joinClasses(
        CSSGlobal.Flex.Col,
        CSSGap[18],
        MPColorClass.CommonBlack,
        MPBackgroundColorClass.CommonWhite,
        styles.container,
        className
      )}
      to={ROUTES.PROFILE.SHOP(slug)}
      onClick={handleClick}
    >
      <div
        className={joinClasses(
          CSSGlobal.Flex.Col,
          CSSGap[24],
          CSSGlobal.Width.fullWidth
        )}
      >
        <div
          className={joinClasses(
            CSSGlobal.Flex.Row,
            CSSGap[8],
            styles.assetsSection
          )}
        >
          {nftMetadatas.edges.map(({ node }) => (
            <div
              key={node.id}
              className={joinClasses(
                styles.assetContainer,
                MPBackgroundColorClass.BackgroundDefault,
                CSSGlobal.Flex.CenteredRow
              )}
            >
              <img className={styles.asset} src={node.previewImage} />
            </div>
          ))}
        </div>
        <div className={CSSGlobal.Flex.Col}>
          <div className={MPFonts.textLargeMedium}>{collectionName}</div>
          <div>
            <span className={MPColorClass.SolidNeutralGray5}>
              a{contract.isGenerative ? ` generative` : ''} series by
            </span>{' '}
            {author.fullName}
          </div>
        </div>
        <div className={joinClasses(CSSGlobal.Flex.Row, CSSGap[10])}>
          <SeriesCardStat
            title="Artworks"
            value={toNumericShorthand(totalSupply)}
          />
          <SeriesCardStat
            title="Total Sales"
            value={`$${toNumericShorthand(totalVolume.totalVolumeInUsd)}`}
          />
          <SeriesCardStat
            title="Collectors"
            value={toNumericShorthand(numOfOwners)}
          />
        </div>
      </div>
    </StandardCard>
  );
}
export default SeriesCard;
