import { usePreloadedQuery } from 'react-relay';

import ExploreHeroArtworkQueryType, {
  ExploreHeroArtworkQuery,
} from 'graphql/__generated__/ExploreHeroArtworkQuery.graphql';

import HeroArtworkCard from 'components/cards/HeroArtworkCard';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

interface HeroProps {
  heroArtworkQuery: WithLoadQueryProps<ExploreHeroArtworkQuery>;
}

function Hero({ heroArtworkQuery }: HeroProps) {
  const result = usePreloadedQuery<ExploreHeroArtworkQuery>(
    ExploreHeroArtworkQueryType,
    heroArtworkQuery.queryRef
  );

  const nft = result.artwork.heroNft;

  return nft && <HeroArtworkCard nft={nft} nftMetadata={nft.metadata} />;
}

export default withDefaultErrorBoundary(
  withLoadQuery(
    Hero,
    {
      heroArtworkQuery: { concreteRequest: ExploreHeroArtworkQueryType },
    },
    { grouppedLoadingKey: 'explore:hero-artwork' }
  ),
  { errorFallback: null, hideState: true }
);
