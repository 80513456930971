import { Hash } from 'viem';

import { MPColorClass } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import useSession from 'hooks/useSession';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import useMPConnect from 'hooks/wallet/useMPConnect';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { areSameAddress } from 'utils/areSameAddress';
import { type WalletId } from 'utils/jwt/walletUtils';
import { get24PxConnectorIcon } from 'utils/wallet/connectorUtils';

import WalletNameDisplay from './WalletNameDisplay';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

import { type Wallets } from 'Session';

interface WalletProps {
  wallet?: Wallets[0];
}

export default function PayoutWallet({ wallet }: WalletProps) {
  const account = useCachedAccount();
  const session = useSession();
  const { findConnectorByAddress } = useMPConnect();
  const Icon = get24PxConnectorIcon(
    findConnectorByAddress(wallet?.address)?.id as WalletId
  );
  return (
    <>
      <div className={pageStyles.itemStatusRow}>
        <span
          className={joinClasses(
            CSSGlobal.Flex.InlineRowCenterAlign,
            CSSGap[8],
            CSSGlobal.Ellipsis
          )}
        >
          {Icon || null}
          <span
            className={joinClasses(
              CSSGlobal.Ellipsis,
              MPColorClass.CommonBlack
            )}
          >
            <WalletNameDisplay
              name={wallet?.name}
              address={session.account.ethPayoutWallet.address as Hash}
            />
          </span>
        </span>
        {areSameAddress(account.address, wallet?.address) && (
          <span>Connected</span>
        )}
      </div>
    </>
  );
}
