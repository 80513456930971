import { ReactNode } from 'react';

import { MPDivider } from '@mp-frontend/core-components';

import { useTrackingContext } from 'components/trackingContext';
import GTM from 'GTM';
import CSSMargin from 'types/enums/css/Margin';

import ProductCollapsibleContainer from '../ProductCollapsibleContainer';

interface LeaderboardContainerProps {
  children: ReactNode;
  collapsible?: boolean;
  hasBottomBorder?: boolean;
}

export default function LeaderboardContainer({
  children,
  collapsible = false,
  hasBottomBorder = false,
}: LeaderboardContainerProps) {
  const { source, dropTitle } = useTrackingContext();
  return (
    <>
      <ProductCollapsibleContainer
        title="Leaderboard"
        showIcon={collapsible}
        collapsed={collapsible}
        collapsible={collapsible}
        onToggle={() => GTM.pdp.trackClickLeaderboardType(source, dropTitle)}
      >
        {children}
      </ProductCollapsibleContainer>

      {!!hasBottomBorder && <MPDivider className={CSSMargin.BOTTOM[32]} />}
    </>
  );
}
