.updateNFTPage {
  padding: 48px;
}

.headerContent {
  text-align: center;
  margin-bottom: 32px;
}

.flexOptions {
  justify-content: space-between;
}

.creatorGuidelines {
  margin-top: 16px;
  display: inline-block;
}

.popupLink {
  text-decoration: underline;
}

.creationAddressBlock {
  margin-top: 20px;
}

.creationAddress {
  font-weight: 600;
}

.centerTextIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.advancedSettings {
  cursor: pointer;
}

.actionRow {
  margin-top: 24px;
}

.alignVCenter {
  align-items: center;
}

.fakeFileInput {
  border-radius: 4px;
  border: 1px solid var(--color-primaryP20);
  margin-bottom: 1rem;
  cursor: pointer;
}

div.fakeFileInputTextArea {
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: 0;
  position: relative;
}

.fakeFileInputTextArea:before {
  position: absolute;
  content: ' ';
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.previewSection {
  display: flex;
}

.previewExplanation {
  text-align: center;
  border: 1px dashed var(--color-primaryP20);
  min-height: 300px;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
}

.assetPreview {
  max-width: 100%;
  max-height: 100%;
}

.digitalMediaVideoWrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  cursor: pointer;
}

svg.playIcon {
  font-size: 50px;
}

.editionsBlurb {
  margin-bottom: 12px;
}

.collabUserProfileSpan {
  display: inline-flex;
  align-items: center;
}

.collabUserProfileImg {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.globalError {
  text-align: center;
  color: var(--color-errorMain);
  margin: 1rem 0;
}