import { MPFonts, useIsMobile } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { PaymentMethodType } from 'types/__generated__/graphql';

import useSimpleDialogController from 'hooks/useSimpleDialogController';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import creditCardUtils from 'utils/creditCard';

import ManageCardDialog from './ManageCardDialog';

import * as styles from 'css/pages/settings/wallet/creditCard/CreditCardRow.module.css';

interface CreditCardRowProps {
  card: PaymentMethodType;
  invalidate: () => void;
  isDefault: boolean;
}

export default function CreditCardRow({
  card,
  isDefault,
  invalidate,
}: CreditCardRowProps) {
  const isMobile = useIsMobile();

  const [
    isManageCCDialogOpen,
    openManageCCDialog,
    closeManageCCDialogOpen,
    ,
    ariaOpenManageCCDialog,
  ] = useSimpleDialogController({ preventDefault: true });

  const Exp = (
    <span className={styles.exp}>
      {String(card.expMonth).padStart(2, '0')}/
      {card.expYear.toString().slice(-2)}
    </span>
  );

  const CreditCardIcon = creditCardUtils.getIcon(card.brand);

  return (
    <>
      <div
        className={joinClasses(styles.row, MPFonts.textSmallMedium)}
        onClick={openManageCCDialog}
        role="button"
        tabIndex={0}
        onKeyPress={ariaOpenManageCCDialog}
      >
        <span
          className={joinClasses(
            styles.topRow,
            CSSGlobal.Flex.InlineRowCenterAlign,
            CSSGap[8]
          )}
        >
          <span
            className={joinClasses(
              CSSGlobal.Flex.InlineRowCenterAlign,
              CSSGap[8]
            )}
          >
            <CreditCardIcon />
            <span>●●●●&nbsp;●●●●&nbsp;●●●●&nbsp;{card.number}</span>
          </span>
          {!isMobile ? Exp : !!isDefault && <span>Default</span>}
        </span>
        {isMobile ? Exp : !!isDefault && <span>Default</span>}
      </div>
      {!!isManageCCDialogOpen && (
        <ManageCardDialog
          card={card}
          isOpen={isManageCCDialogOpen}
          cancel={closeManageCCDialogOpen}
          isDefault={isDefault}
          invalidate={invalidate}
        />
      )}
    </>
  );
}
