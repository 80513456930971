import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation } from 'react-relay';
import { useAccount } from 'wagmi';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';
import NFTCreateApprovalMutationType, {
  NFTCreateApprovalMutation,
} from 'graphql/__generated__/NFTCreateApprovalMutation.graphql';
import { NFTsQuery$data } from 'graphql/__generated__/NFTsQuery.graphql';

import WalletActionButton from 'components/WalletActionButton';
import useDigitalMediaCoreContract from 'hooks/contracts/useDigitalMediaCoreContract';
import useLogging from 'hooks/useLogging';
import usePublicClient from 'hooks/useWeb3Client';
import { NFTType } from 'types/graphql/NFT';
import { HexString } from 'utils/jwt/walletUtils';

interface ProductApprovalExternalProps {
  currentTransactionHash: string;
  externalTokenContract: NFTsQuery$data['nfts']['edges'][0]['node']['contract'];
  invalidateNFTForSaleApproval: () => void;
  nft: NFTType;
  saleContract: Pick<
    NFTContractQuery$data['nftContract'],
    'abidata' | 'address'
  >;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
}

function ProductApprovalExternal({
  currentTransactionHash,
  saleContract,
  externalTokenContract,
  setCurrentTransactionHash,
  nft,
  invalidateNFTForSaleApproval,
}: ProductApprovalExternalProps) {
  const [
    isValidatingSaleContractApproval,
    setIsValidatingSaleContractApproval,
  ] = useState(false);

  const provider = usePublicClient();
  const { logNFTException } = useLogging();
  const { address: currentUserAddress } = useAccount();

  const [nftSaleContractApprovalMutation] =
    useMutation<NFTCreateApprovalMutation>(NFTCreateApprovalMutationType);

  const { useSetApprovalForAll } = useDigitalMediaCoreContract({
    abi: JSON.parse(externalTokenContract.abidata).abi,
    contractAddress: externalTokenContract.address as HexString,
  });

  const setApprovalForAll = useSetApprovalForAll({
    approved: true,
    operatorAddress: saleContract.address,
  });

  useEffect(() => {
    if (setApprovalForAll.mutate.isError) {
      setIsValidatingSaleContractApproval(false);
    }
  }, [setApprovalForAll.mutate.error, setApprovalForAll.mutate.isError]);

  const isLoading =
    isValidatingSaleContractApproval ||
    setApprovalForAll.mutate.isPending ||
    setApprovalForAll.simulate.isFetching ||
    setApprovalForAll.simulate.isPending;

  const approveSaleContractForExternalToken = async () => {
    try {
      setIsValidatingSaleContractApproval(true);
      const transactionResult = await setApprovalForAll.mutate.writeAsync();
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      setIsValidatingSaleContractApproval(false);
      setCurrentTransactionHash(transactionResult);
      invalidateNFTForSaleApproval();
      nftSaleContractApprovalMutation({
        onCompleted() {
          setIsValidatingSaleContractApproval(false);
        },
        variables: {
          request_data: {
            approvalContractAddress: externalTokenContract.address,
            nonce,
            operatorContractAddress: saleContract.address,
            transactionId: transactionResult,
            userAddress: currentUserAddress,
          },
        },
      });
    } catch (error) {
      logNFTException(nft.pk, error);
      setIsValidatingSaleContractApproval(false);
      setApprovalForAll.mutate.reset();
    }
  };

  return (
    <>
      {!currentTransactionHash && (
        <>
          <WalletActionButton
            fullWidth
            onClick={approveSaleContractForExternalToken}
            restrictToAddress={nft.currentOwnerAddress as HexString}
            size="large"
            disabled={isLoading}
          >
            Give Approval
          </WalletActionButton>
        </>
      )}
    </>
  );
}

export default ProductApprovalExternal;
