import { ReactNode } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/pages/product/ProductActions.module.css';

interface ProductActionsContainerProps {
  children: ReactNode;
  className?: string;
}

function ProductActionsContainer({
  className,
  children,
}: ProductActionsContainerProps) {
  return (
    <div className={joinClasses(className, styles.productActionsContainer)}>
      {children}
    </div>
  );
}

export default ProductActionsContainer;
