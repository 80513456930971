import { SvgIcon } from '@mui/material';

export default function ArrowDropDownIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect fill="white" />
      <g clipPath="url(#clip0_158_6828)">
        <path d="M5 6L12 13L19 6H5Z" fill="currentColor" />
      </g>
      <rect x="5" y="14" width="14" height="3" fill="currentColor" />
    </SvgIcon>
  );
}
