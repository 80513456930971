import { usePreloadedQuery } from 'react-relay';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountStripeCards, {
  AccountStripeCardsQuery,
} from 'graphql/__generated__/AccountStripeCardsQuery.graphql';

import useSimpleDialogController from 'hooks/useSimpleDialogController';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import AddNewCardDialog from './AddNewCardDialog';
import CreditCardRow from './CreditCardRow';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

interface CreditCardContentsProps {
  stripeCardQuery: WithLoadQueryProps<AccountStripeCardsQuery>;
}

function CreditCardSection({ stripeCardQuery }: CreditCardContentsProps) {
  const { stripeCards } = usePreloadedQuery<AccountStripeCardsQuery>(
    AccountStripeCards,
    stripeCardQuery.queryRef
  );

  const [isAddNewCardDialogOpen, openAddNewCardDialog, closeAddNewCardDialog] =
    useSimpleDialogController({ preventDefault: true });

  return (
    <div>
      <div className={joinClasses(MPFonts.headline4, pageStyles.sectionTitle)}>
        Credit Cards
      </div>
      <div
        className={joinClasses(
          pageStyles.sectionExplanation,
          MPFonts.paragraphSmall
        )}
      >
        Your default credit card will be used to make purchases in USD.
      </div>
      <div className={pageStyles.section}>
        {!!stripeCards.length && (
          <>
            <div className={MPFonts.textSmallSemiBold}>Your Credit Cards</div>
            <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[24])}>
              {stripeCards.map((card) => (
                <CreditCardRow
                  key={card.id}
                  card={card}
                  isDefault={card === stripeCards[0]}
                  invalidate={stripeCardQuery.invalidate}
                />
              ))}
            </div>
          </>
        )}
        <MPActionButton
          size="large"
          fullWidth
          variant={stripeCards.length ? 'secondary' : 'primary'}
          onClick={openAddNewCardDialog}
        >
          Add New Credit Card
        </MPActionButton>
        {!!isAddNewCardDialogOpen && (
          <AddNewCardDialog
            isOpen
            invalidate={stripeCardQuery.invalidate}
            cancel={closeAddNewCardDialog}
          />
        )}
      </div>
    </div>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(CreditCardSection, {
    stripeCardQuery: {
      concreteRequest: AccountStripeCards,
      queryImmediately: true,
    },
  }),
  {
    suspenseless: true,
  }
);
