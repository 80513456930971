import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { debounce, noop } from 'lodash';

import {
  MPActionButton,
  MPColorValue,
  MPDialog,
  MPSlider,
  MPTooltip,
  useIsMobile,
} from '@mp-frontend/core-components';
import { AddIcon, SearchIcon } from '@mp-frontend/core-components/icons';
import {
  joinClasses,
  KeyboardEventKey,
  useOnEnterKey,
} from '@mp-frontend/core-utils';

import LEGACY_API from 'constants/LegacyAPI';
import useSession from 'hooks/useSession';
import CSSGlobal from 'types/enums/css/Global';
import getToken from 'utils/getToken';

import * as dialogStyles from 'css/global/dialog.module.css';
import * as styles from 'css/pages/digital-media/create/AddCollaboratorDialog.module.css';

interface User {
  image: string;
  name: string;
  username: string;
}

export interface CollabUserInfo {
  percent: number;
  user: User;
}

interface SelectCollaboratorProps {
  setCollabUser: (i: User) => void;
}

function SelectCollaborator({ setCollabUser }: SelectCollaboratorProps) {
  const [filterInputValue, setFilterInputValue] = useState('');
  const ariaSetCollabUser = useCallback(
    (e: KeyboardEvent<HTMLDivElement>, user: User) => {
      if (
        [KeyboardEventKey.enter, KeyboardEventKey.numpadEnter].includes(e.key)
      ) {
        e.preventDefault(); // Ensure it is only this code that runs
        setCollabUser(user);
      }
    },
    [setCollabUser]
  );
  const [filterValue, setFilterValue] = useState('');
  const debouncedSetFilterValue = debounce((val) => setFilterValue(val), 250);
  const [searchList, setSearchList] = useState<{
    list: Array<User>;
    term: string;
  }>();
  const [isSearching, setIsSearching] = useState(false);

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!filterValue || isSearching || filterValue === searchList?.term) return;
    const headers = new Headers();
    headers.append('X-CSRFToken', getToken());
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    fetch(LEGACY_API.GET_VALID_COLLABORATORS(filterValue), {
      headers,
      method: 'GET',
    })
      .then((resp) => resp.json())
      .then((res) => {
        setIsSearching(false);
        setSearchList({
          list: res.users,
          term: filterValue,
        });
      })
      .catch(() => {
        setIsSearching(false);
      });
    setIsSearching(true);
  }, [filterValue, isSearching, searchList]);

  return (
    <>
      <div className={CSSGlobal.Flex.Row}>
        <div
          className={joinClasses(
            styles.searchWrapper,
            isFocused ? 'isFocused' : undefined
          )}
        >
          <span className={styles.searchIconWrapper}>
            <SearchIcon
              className={styles.searchIconSVG}
              htmlColor={
                isFocused ? MPColorValue.PrimaryMain : MPColorValue.Grey500
              }
              alt="Update Category"
            />
          </span>
          <input
            className={styles.filter}
            type="text"
            name="item-filter"
            value={filterInputValue}
            onChange={(event) => {
              setFilterInputValue(event.target.value);
              debouncedSetFilterValue(event.target.value);
            }}
            placeholder="Type the co-creator's username (they must be following you)"
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </div>
      </div>
      <div className={styles.results}>
        {!!searchList?.list && !searchList.list.length && (
          <div className={styles.noSearchResults}>
            We couldn&#39;t find any matches for your search.
          </div>
        )}
        {(searchList?.list ?? []).map((user) => (
          <div className={styles.collabRow} key={user.username}>
            <img className={styles.userImg} src={user.image} />
            <div className={styles.collabName}>{user.name}</div>
            <div
              className={styles.addCollaboratorButton}
              onClick={() => setCollabUser(user)}
              onKeyPress={(e: KeyboardEvent<HTMLDivElement>) =>
                ariaSetCollabUser(e, user)
              }
              role="button"
              tabIndex={0}
            >
              <AddIcon htmlColor="#000" alt="Create a New Category" />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function SelectPercentages({ collabUser, setCollabUser, state }) {
  const session = useSession();
  const [userPercentage, setUserPercentage] = state;
  const sx = {
    '& .MuiSlider-valueLabel': {
      '&:after': {
        content: '"%"',
      },
    },
  };

  return (
    <div>
      <div className={styles.percentRow}>
        <img className={styles.userImg} src={session.account.profileImageUrl} />
        <div className={styles.percentTrackSection}>
          <div className={styles.collabName}>{session.account.fullName}</div>
          <MPSlider
            sx={sx}
            min={1}
            max={99}
            value={userPercentage}
            onChange={(e, val: number) => setUserPercentage(val)}
            valueLabelDisplay="on"
          />
        </div>
      </div>
      <div className={styles.percentRow}>
        <img className={styles.userImg} src={collabUser.image} />
        <div className={styles.percentTrackSection}>
          <div className={styles.collabName}>{collabUser.name}</div>
          <MPSlider
            sx={sx}
            min={1}
            max={99}
            value={100 - userPercentage}
            onChange={(e, val: number) => setUserPercentage(100 - val)}
            valueLabelDisplay="on"
          />
        </div>
      </div>
      <div
        className={joinClasses(
          'flexCenter',
          styles.changeCollaboratorButtonWrapper
        )}
      >
        <MPActionButton onClick={() => setCollabUser(null)} variant="secondary">
          Change Collaborator
        </MPActionButton>
      </div>
    </div>
  );
}

interface AddCollaboratorDialogProps {
  cancel: () => void;
  isOpen: boolean;
  initialCollabUser?: User;
  initialPercentage?: number;
  onUpdate?: (collabUsers?: Array<CollabUserInfo>) => void;
}

export default function AddCollaboratorDialog({
  isOpen,
  cancel,
  initialCollabUser,
  onUpdate = noop,
  initialPercentage = 50,
}: AddCollaboratorDialogProps) {
  const isMobile = useIsMobile();
  const [collabUser, setCollabUser] = useState<User>(initialCollabUser);
  const percentState = useState(initialPercentage);
  const update = () => {
    onUpdate(
      !collabUser ? undefined : [{ percent: percentState[0], user: collabUser }]
    );
    cancel();
  };
  const ariaUpdate = useOnEnterKey(update);
  return (
    <MPDialog
      onClose={cancel}
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          width: isMobile ? '100%' : '700px',
        },
      }}
      title="Add Collaborator"
      actionButton={
        <MPActionButton
          onClick={update}
          onKeyPress={ariaUpdate}
          tabIndex={0}
          disabled={!collabUser}
        >
          Confirm
          {!!collabUser && (
            <>
              &nbsp;Adding:&nbsp;
              <span className={styles.collabUserProfileSpan}>
                <MPTooltip title={collabUser.name} placement="top" arrow>
                  <img
                    className={styles.collabUserProfileImg}
                    src={collabUser.image}
                  />
                </MPTooltip>
              </span>
            </>
          )}
        </MPActionButton>
      }
    >
      <div className={dialogStyles.defaultContent}>
        {!collabUser && <SelectCollaborator setCollabUser={setCollabUser} />}
        {!!collabUser && (
          <SelectPercentages
            collabUser={collabUser}
            setCollabUser={setCollabUser}
            state={percentState}
          />
        )}
      </div>
    </MPDialog>
  );
}
