import { MPFonts } from '@mp-frontend/core-components';
import { LockIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/pages/product/ProductLabels.module.css';

interface ProductPriceLabelProps {
  ethPrice: string;
  isUSDPrimary: boolean;
  label: string;
  usdPrice: string;
  isHidden?: boolean;
  isPremium?: boolean;
}

function ProductPriceLabel({
  label,
  usdPrice,
  ethPrice,
  isUSDPrimary,
  isHidden = false,
  isPremium = false,
}: ProductPriceLabelProps) {
  const primaryPrice = isUSDPrimary ? usdPrice : ethPrice;
  const secondaryPrice = isUSDPrimary ? ethPrice : usdPrice;

  return (
    <div className={styles.productLabel}>
      <div
        className={joinClasses(
          MPFonts.textNormalMedium,
          styles.productLabelText,
          isPremium ? styles.premium : styles.secondary
        )}
      >
        {label}
      </div>
      <div
        className={joinClasses(MPFonts.price, styles.productLabelText, {
          [styles.premium]: !isHidden && isPremium,
          [styles.secondary]: isHidden,
        })}
      >
        {isHidden ? (
          <LockIcon fontSize="20" />
        ) : (
          <>
            <span className={MPFonts.price}>{primaryPrice}&nbsp;</span>

            <span
              className={joinClasses(
                MPFonts.subPrice,
                styles.productLabelText,
                isPremium ? styles.premium : styles.secondary
              )}
            >
              {secondaryPrice}
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default ProductPriceLabel;
