/**
 * @generated SignedSource<<4ec1bf898dfa4ac120af0c2cee2b4bdf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DropPhaseEnum = "LIVE" | "NEW" | "UPCOMING" | "PRESALE" | "SOLD_OUT";
export type ExploreHeroExhibitionQuery$variables = {
  nftMetadatasCount?: number | null;
};
export type ExploreHeroExhibitionQuery$data = {
  readonly exhibition: {
    readonly heroExhibition: {
      readonly creators: ReadonlyArray<{
        readonly biography: string | null;
        readonly followerCount: number;
        readonly fullName: string | null;
        readonly id: string;
        readonly pk: string;
        readonly profileImageUrl: string;
        readonly totalVolume: {
          readonly totalVolumeInEth: number | null;
          readonly totalVolumeInUsd: number | null;
        } | null;
        readonly username: string;
      } | null> | null;
      readonly dropPhase: DropPhaseEnum;
      readonly dropTitle: string | null;
      readonly dropsAt: any | null;
      readonly hasVideoPreview: boolean | null;
      readonly heroBackgroundColor: {
        readonly r: number | null;
        readonly g: number | null;
        readonly b: number | null;
      };
      readonly id: string;
      readonly pk: string;
      readonly presenterText: string;
      readonly slug: string | null;
      readonly sortedNftMetadatas: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly dropMetadata: {
              readonly videoPreviewImageUrl: string | null;
              readonly previewVideoStartTime: string;
            } | null;
            readonly hasVideo: boolean | null;
            readonly highResImage: string | null;
            readonly id: string;
            readonly mediaMetadata: {
              readonly height: number | null;
              readonly width: number | null;
            } | null;
            readonly previewImage: string | null;
            readonly rawfileExtension: string;
            readonly standardImage: string | null;
            readonly thumbnailImage: string | null;
            readonly title: string;
            readonly videoUrl: string | null;
          } | null;
        } | null>;
      } | null;
      readonly subscribePhoneNumber: string | null;
      readonly totalLimitedEditions: number | null;
      readonly totalOneOfOnes: number | null;
      readonly totalOpenEditions: number | null;
      readonly totalRankedAuctions: number | null;
      readonly heroDescription: string;
    } | null;
  } | null;
};
export type ExploreHeroExhibitionQuery = {
  variables: ExploreHeroExhibitionQuery$variables;
  response: ExploreHeroExhibitionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 15,
    "kind": "LocalArgument",
    "name": "nftMetadatasCount"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountAbstractType",
  "kind": "LinkedField",
  "name": "creators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "biography",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "followerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TotalVolumeType",
      "kind": "LinkedField",
      "name": "totalVolume",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalVolumeInEth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalVolumeInUsd",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropPhase",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropTitle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dropsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVideoPreview",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "RGB",
  "kind": "LinkedField",
  "name": "heroBackgroundColor",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "r",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "g",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "b",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presenterText",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "nftMetadatasCount"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoPreviewImageUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previewVideoStartTime",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVideo",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highResImage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previewImage",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rawfileExtension",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "standardImage",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailImage",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoUrl",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscribePhoneNumber",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalLimitedEditions",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalOneOfOnes",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalOpenEditions",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRankedAuctions",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "heroDescription",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExploreHeroExhibitionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExploreExhibitionQuery",
        "kind": "LinkedField",
        "name": "exhibition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DropAbstractType",
            "kind": "LinkedField",
            "name": "heroExhibition",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "NFTMetadataConnectionTypeConnection",
                "kind": "LinkedField",
                "name": "sortedNftMetadatas",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMetadataConnectionTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTMetadataAbstractType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DropMediaMetadataType",
                            "kind": "LinkedField",
                            "name": "dropMetadata",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NFTMediaMetadataType",
                            "kind": "LinkedField",
                            "name": "mediaMetadata",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExploreHeroExhibitionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExploreExhibitionQuery",
        "kind": "LinkedField",
        "name": "exhibition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DropAbstractType",
            "kind": "LinkedField",
            "name": "heroExhibition",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "NFTMetadataConnectionTypeConnection",
                "kind": "LinkedField",
                "name": "sortedNftMetadatas",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NFTMetadataConnectionTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NFTMetadataAbstractType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DropMediaMetadataType",
                            "kind": "LinkedField",
                            "name": "dropMetadata",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NFTMediaMetadataType",
                            "kind": "LinkedField",
                            "name": "mediaMetadata",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "62479870d0337e3789ff8a139922c5b7",
    "id": null,
    "metadata": {},
    "name": "ExploreHeroExhibitionQuery",
    "operationKind": "query",
    "text": "query ExploreHeroExhibitionQuery(\n  $nftMetadatasCount: Int = 15\n) {\n  exhibition {\n    heroExhibition {\n      creators {\n        biography\n        followerCount\n        fullName\n        id\n        pk\n        profileImageUrl\n        totalVolume {\n          totalVolumeInEth\n          totalVolumeInUsd\n        }\n        username\n      }\n      dropPhase\n      dropTitle\n      dropsAt\n      hasVideoPreview\n      heroBackgroundColor {\n        r\n        g\n        b\n      }\n      id\n      pk\n      presenterText\n      slug\n      sortedNftMetadatas(first: $nftMetadatasCount) {\n        edges {\n          node {\n            dropMetadata {\n              videoPreviewImageUrl\n              previewVideoStartTime\n              id\n            }\n            hasVideo\n            highResImage\n            id\n            mediaMetadata {\n              height\n              width\n              id\n            }\n            previewImage\n            rawfileExtension\n            standardImage\n            thumbnailImage\n            title\n            videoUrl\n          }\n        }\n      }\n      subscribePhoneNumber\n      totalLimitedEditions\n      totalOneOfOnes\n      totalOpenEditions\n      totalRankedAuctions\n      heroDescription\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69fddd3e1ffa05cdd28b53387b5cf916";

export default node;
