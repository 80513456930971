import {
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Collapse } from '@mui/material';
import { styled } from '@mui/system';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';
import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { ArrowDropDownIcon, ArrowDropUpIcon } from '../icons';

const StyledContent = styled('div')`
  overflow: hidden;
`;

const StyledButton = styled('div')`
  color: ${MPColorValue.SolidNeutralGray5};
  cursor: pointer;
  display: flex;
  margin: 15px auto 0;
  position: relative;
  width: 100px;
`;

const StyledButtonTitle = styled('div')`
  cursor: pointer;
  margin: auto;
`;

export interface MPExpandableProps {
  children: (props: { rerender: () => void }) => ReactNode;
  maxHeight: number;
}

export function MPUpdatableExpandable({
  children,
  maxHeight,
}: MPExpandableProps) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandable, setExpandable] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [rerender, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const element = ref.current;
    if (!element || element.clientHeight < maxHeight) return;

    setExpandable(true);
  }, [rerender, ref, children, maxHeight]);

  const handleToggle = useCallback(
    (event: MouseEvent | KeyboardEvent) => {
      event.preventDefault();
      setExpanded((prevValue) => !prevValue);
    },
    [setExpanded]
  );

  const handleRerender = useCallback((): void => {
    forceUpdate();
  }, [forceUpdate]);

  const [title, Icon] = expanded
    ? ['View Less', ArrowDropUpIcon]
    : ['View More', ArrowDropDownIcon];

  return expandable ? (
    <>
      <Collapse
        in={expanded}
        collapsedSize={maxHeight}
        sx={{
          maskImage: expanded
            ? 'none'
            : `linear-gradient(to bottom, ${MPColorValue.SolidNeutralGray5} 60%, transparent 100%)`,
        }}
      >
        <StyledContent>{children({ rerender: handleRerender })}</StyledContent>
      </Collapse>

      <StyledButton
        role="button"
        tabIndex={0}
        onClick={handleToggle}
        onKeyDown={handleToggle}
      >
        <Icon />

        <StyledButtonTitle className={MPFonts.textSmallMedium}>
          {title}
        </StyledButtonTitle>
      </StyledButton>
    </>
  ) : (
    <StyledContent ref={ref}>
      {children({ rerender: handleRerender })}
    </StyledContent>
  );
}

export default function MPExpandable({
  children,
  maxHeight,
}: Omit<MPExpandableProps, 'children'> & {
  children: ReactNode;
}) {
  return (
    <MPUpdatableExpandable maxHeight={maxHeight}>
      {() => children}
    </MPUpdatableExpandable>
  );
}
