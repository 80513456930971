import { MPDialog, useIsMobile } from '@mp-frontend/core-components';

import WhatIsGasContent from './Content';

import * as dialogStyles from 'css/global/dialog.module.css';

interface WhatIsGasDialogProps {
  cancel: () => void;
  isOpen: boolean;
}

export default function WhatIsGasDialog({
  isOpen,
  cancel,
}: WhatIsGasDialogProps) {
  const isMobile = useIsMobile();

  return (
    <MPDialog
      onClose={cancel}
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          width: isMobile ? '100%' : '700px',
        },
      }}
      title={WhatIsGasContent.title}
    >
      <div className={dialogStyles.defaultContent}>
        <WhatIsGasContent.Component />
      </div>
    </MPDialog>
  );
}
