import { useMutation } from 'react-relay';
import { Hash } from 'viem';

import CreateGlobalBidInETHMutation, {
  ListingCreateGlobalBidInETHMutation$data,
  ListingCreateGlobalBidInETHMutation$variables,
} from 'graphql/__generated__/ListingCreateGlobalBidInETHMutation.graphql';
import CreateProductBidInETHMutation, {
  ListingCreateProductBidInETHMutation$data,
  ListingCreateProductBidInETHMutation$variables,
} from 'graphql/__generated__/ListingCreateProductBidInETHMutation.graphql';
import CreateRankedBidInETHMutation, {
  ListingCreateRankedBidInETHMutation$data,
  ListingCreateRankedBidInETHMutation$variables,
} from 'graphql/__generated__/ListingCreateRankedBidInETHMutation.graphql';
import ValidateCreateETHBidMutation, {
  ListingValidateCreateETHBidMutation$data,
  ListingValidateCreateETHBidMutation$variables,
} from 'graphql/__generated__/ListingValidateCreateETHBidMutation.graphql';

import useDigitalMediaSaleCoreContract from 'hooks/contracts/useDigitalMediaSaleCoreContract';
import { useGlobalBidCoreContract } from 'hooks/contracts/useGlobalDigitalMediaBidCoreContract';
import { useRankedAuctionCoreContract } from 'hooks/contracts/useRankedAuctionCoreContract';
import useSaleContract from 'hooks/useSaleContract';
import useSession from 'hooks/useSession';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import { type ContractActionObject, HexString } from 'utils/jwt/walletUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

function useEthMutations() {
  const asyncCommitValidateProductBid = promisifyMutationWithRequestData<
    ListingValidateCreateETHBidMutation$variables,
    ListingValidateCreateETHBidMutation$data
  >(useMutation(ValidateCreateETHBidMutation)[0]);

  const asyncCommitCreateProductBid = promisifyMutationWithRequestData<
    ListingCreateProductBidInETHMutation$variables,
    ListingCreateProductBidInETHMutation$data
  >(useMutation(CreateProductBidInETHMutation)[0]);

  const asyncCommitCreateGlobalBid = promisifyMutationWithRequestData<
    ListingCreateGlobalBidInETHMutation$variables,
    ListingCreateGlobalBidInETHMutation$data
  >(useMutation(CreateGlobalBidInETHMutation)[0]);

  const asyncCommitCreateRankedAuctionBid = promisifyMutationWithRequestData<
    ListingCreateRankedBidInETHMutation$variables,
    ListingCreateRankedBidInETHMutation$data
  >(useMutation(CreateRankedBidInETHMutation)[0]);

  return {
    asyncCommitCreateGlobalBid,
    asyncCommitCreateProductBid,
    asyncCommitCreateRankedAuctionBid,
    asyncCommitValidateProductBid,
  };
}

function useContractManagers(nft, bidAmountWei, rankedAuctionBidAmountWei) {
  const nftGeneralSaleContract = useSaleContract({
    nft,
  });

  // For placing bid, always using the latest contract.
  // (Note: ProductBid and Sale are using the same contract)
  // It doesn't need to be the same as the saleContract
  const { useBidOnToken } = useDigitalMediaSaleCoreContract({
    abi: JSON.parse(nftGeneralSaleContract.abidata).abi,
    contractAddress: nftGeneralSaleContract.address as HexString,
  });

  /* GlobalBid */
  const { useGlobalBidOnToken } = useGlobalBidCoreContract();

  /* RankedAunction */
  const { useAuctionBidOnToken } = useRankedAuctionCoreContract();

  return {
    globalOffer: useGlobalBidOnToken({
      mediaId: nft.metadata.onchainId,
      tokenAddress: nft.contract.address as HexString,
      value: bidAmountWei,
    }),
    productBid: useBidOnToken({
      bidAmount: bidAmountWei,
      tokenAddress: nft.contract.address,
      tokenId: nft.onchainId,
    }),
    rankedAuction: useAuctionBidOnToken({
      tokenContractAddress: nft.contract.address as HexString,
      value: rankedAuctionBidAmountWei,
    }),
  };
}

export default function useBidOn({
  nft,
  bidAmountWei,
  rankedAuctionBidAmountWei,
}) {
  const session = useSession();
  const {
    address: bidderAddress,
    asyncTransactionCount,
    refetchTransactionCount,
  } = useCachedAccount();
  const emailAddress = session.account?.email || '';
  const digitalMediaId = parseInt(nft.metadata.pk, 10);
  const digitalMediaReleaseId = parseInt(nft.pk, 10);
  const productId = parseInt(nft.listing.pk, 10);

  const {
    asyncCommitValidateProductBid,
    asyncCommitCreateProductBid,
    asyncCommitCreateGlobalBid,
    asyncCommitCreateRankedAuctionBid,
  } = useEthMutations();

  const contractManagers = useContractManagers(
    nft,
    bidAmountWei,
    rankedAuctionBidAmountWei
  );

  const _writeContract: (ContractActionObject) => Promise<[number, Hash]> =
    async function writeContract(contractManager: ContractActionObject) {
      contractManager.mutate.reset();

      const validationResult = await asyncCommitValidateProductBid({
        bidAmount: bidAmountWei.toString(),
        bidderAddress,
        isGlobalBid: false,
        productId,
      });

      if (!validationResult.validateCreateEthBid.success) {
        throw new Error('Count not validate product bid with ETH');
      }

      const nonce = (await asyncTransactionCount()) + 1;
      const result = await contractManager.mutate.writeAsync();
      return [nonce, result];
    };

  return {
    globalOffer: async () => {
      const [nonce, transactionId] = await _writeContract(
        contractManagers.globalOffer
      );

      const creationResult = await asyncCommitCreateGlobalBid({
        bidAmountInWei: bidAmountWei.toString(),
        bidderAddress,
        digitalMediaId,
        digitalMediaReleaseId,
        email: emailAddress,
        nonce,
        transactionId,
      });
      refetchTransactionCount();

      if (!creationResult.createGlobalBidInEth.success) {
        throw new Error('Could not place global offer bid with ETH');
      }

      return transactionId;
    },
    managers: contractManagers,
    product: async () => {
      const [nonce, transactionId] = await _writeContract(
        contractManagers.productBid
      );

      const creationResult = await asyncCommitCreateProductBid({
        bidAmountInWei: parseFloat(bidAmountWei.toString()),
        bidderAddress,
        email: emailAddress,
        nonce,
        productId: productId.toString(),
        transactionId,
      });
      refetchTransactionCount();

      if (!creationResult.createProductBidInEth.success) {
        throw new Error('Count not place product bid with ETH');
      }
      return transactionId;
    },
    rankedAuction: async () => {
      const [nonce, transactionId] = await _writeContract(
        contractManagers.rankedAuction
      );

      const creationResult = await asyncCommitCreateRankedAuctionBid({
        bidAmountInWei: rankedAuctionBidAmountWei.toString(),
        bidderAddress,
        digitalMediaReleaseId,
        email: emailAddress,
        nonce,
        transactionId,
      });
      refetchTransactionCount();

      if (!creationResult.createRankedBidInEth.success) {
        throw new Error('Could not place ranked auction bid with ETH');
      }

      return transactionId;
    },
  };
}
