import { BigNumber } from 'ethers';
import { RecoilState, useRecoilValue } from 'recoil';
import { graphQLSelector } from 'recoil-relay';
import { Hash } from 'viem';

import ConcreteNFTContractRequest, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import { ContractNameEnum } from 'types/__generated__/graphql';

import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import RelayEnvironment from '../../RelayEnvironment';
import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export const RecoilGlobalBidCoreContractQuery: RecoilState<
  NFTContractQuery['response']['nftContract']
> = graphQLSelector({
  environment: RelayEnvironment,
  key: 'globalBidCoreContract',
  mapResponse: (data) => data.nftContract,
  query: ConcreteNFTContractRequest,
  variables: () => ({ name: ContractNameEnum.GlobalDigitalMediaBidCore }),
});

export default function useDeprecatedGlobalDigitalMediaBidCoreContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();

  const useGlobalBidOnToken = ({
    mediaId,
    tokenAddress,
    value,
  }: {
    mediaId: string;
    tokenAddress: HexString;
    value: BigNumber;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [mediaId, tokenAddress],
      functionName: 'globalBidOnToken',
      value: value.toBigInt(),
      ...defaultOverrides,
    });

  const useCancelGlobalBid = ({
    tokenAddress,
    bidId,
    digitalMediaId,
  }: {
    bidId: number;
    digitalMediaId: number;
    tokenAddress: HexString;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [digitalMediaId, tokenAddress, bidId],
      functionName: 'cancelGlobalBid',
      ...defaultOverrides,
    });

  const useAcceptGlobalBid = ({
    tokenId,
    tokenAddress,
    onchainId,
  }: {
    onchainId: number;
    tokenAddress: HexString;
    tokenId: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenId, tokenAddress, onchainId],
      functionName: 'acceptGlobalBid',
      ...defaultOverrides,
    });

  return {
    useAcceptGlobalBid,
    useCancelGlobalBid,
    useGlobalBidOnToken,
  };
}

// This should eventually replace the default function
export function useGlobalBidCoreContract() {
  const contract = useRecoilValue(RecoilGlobalBidCoreContractQuery);
  return useDeprecatedGlobalDigitalMediaBidCoreContract({
    abi: JSON.parse(contract.abidata).abi,
    contractAddress: contract.address as Hash,
  });
}
