import { useAccount } from 'wagmi';

import useSession from 'hooks/useSession';
import { NFTType } from 'types/graphql/NFT';

interface ProductOwnershipProps {
  nft?: Pick<NFTType, 'currentOwnerAddress'> & {
    currentOwner: Pick<NFTType['currentOwner'], 'pk'>;
  };
}

function useProductOwnership({ nft }: ProductOwnershipProps): boolean {
  const { address: currentUserAddress } = useAccount();
  const session = useSession();

  const isUserOwnerOfTheToken =
    nft &&
    (nft.currentOwnerAddress === currentUserAddress ||
      session.account?.pk === nft.currentOwner.pk);

  return isUserOwnerOfTheToken;
}

export default useProductOwnership;
