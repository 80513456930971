import { KeyboardEvent, MouseEvent, useCallback } from 'react';

export const KeyboardEventKey = {
  enter: 'Enter',
  numpadEnter: 'NumpadEnter',
  tab: 'Tab',
};

export default function useOnEnterKey<T extends Element>(
  callback: (event: KeyboardEvent<T> | MouseEvent<T>) => void
) {
  return useCallback(
    (event: KeyboardEvent) => {
      if (
        [KeyboardEventKey.enter, KeyboardEventKey.numpadEnter].includes(
          event.key
        )
      ) {
        event.preventDefault(); // Ensure it is only this code that runs
        callback(event as any);
      }
    },
    [callback]
  );
}
