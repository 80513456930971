import { SvgIcon } from '@mui/material';

export default function AboutIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M3 4.88889C3 3.84568 3.80589 3 4.8 3H19.2C20.1941 3 21 3.84568 21 4.88889V15.2778C21 16.321 20.1941 17.1667 19.2 17.1667H15.0728L12.6364 19.7234C12.2849 20.0922 11.7151 20.0922 11.3636 19.7234L8.92721 17.1667H4.8C3.80589 17.1667 3 16.321 3 15.2778V4.88889ZM19.2 4.88889H4.8V15.2778H9.3C9.53869 15.2778 9.76761 15.3773 9.9364 15.5544L12 17.7199L14.0636 15.5544C14.2324 15.3773 14.4613 15.2778 14.7 15.2778H19.2V4.88889ZM6.6 8.19444C6.6 7.67284 7.00294 7.25 7.5 7.25H16.5C16.9971 7.25 17.4 7.67284 17.4 8.19444C17.4 8.71605 16.9971 9.13889 16.5 9.13889H7.5C7.00294 9.13889 6.6 8.71605 6.6 8.19444ZM6.6 11.9722C6.6 11.4506 7.00294 11.0278 7.5 11.0278H12.9C13.3971 11.0278 13.8 11.4506 13.8 11.9722C13.8 12.4938 13.3971 12.9167 12.9 12.9167H7.5C7.00294 12.9167 6.6 12.4938 6.6 11.9722Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
