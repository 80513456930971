import { useEffect } from 'react';

import {
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import FullArtist from 'components/accounts/FullArtist';
import HeroEditorialCard from 'components/cards/HeroEditorialCard';
import LogoCard from 'components/cards/LogoCard';
import Footer from 'components/Footer';
import { APP_NAME } from 'constants/Utils';
import CSSGlobal from 'types/enums/css/Global';
import setDocTitle from 'utils/setDocTitle';

import AboutGrid, { AboutGridCard } from './AboutGrid';
import AboutSection from './AboutSection';
import {
  CONCIERGE_EDITORIAL,
  CURATORS,
  EXECUTIVE_TEAM,
  INVESTORS,
  MISSION_EDITORIAL,
  PRESS,
} from './constants';

import * as styles from 'css/pages/about/AboutPage.module.css';

function AboutPage() {
  useEffect(() => {
    setDocTitle(['About', APP_NAME].join(' | '));

    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
      <div
        className={joinClasses(
          MPBackgroundColorClass.BackgroundDefault,
          styles.container
        )}
      >
        <div className={joinClasses(styles.sections, CSSGlobal.Flex.Col)}>
          <section
            className={joinClasses(
              CSSGlobal.Flex.CenteredRow,
              CSSGlobal.Cursor.Default,
              styles.header
            )}
          >
            <div className={MPFonts.headline3}>
              Bridging the gap between traditional art and web 3
            </div>
            <div className={MPFonts.textLargeRegular}>
              {APP_NAME} is a digital art marketplace for creating, selling, and
              collecting unique blockchain-secured art. Join to explore
              authentic digital art from global artists.
            </div>
          </section>

          <section id="values">
            <HeroEditorialCard editorial={MISSION_EDITORIAL} />
          </section>
          <AboutSection
            id="curators"
            title={`${APP_NAME} Curators`}
            description={`${APP_NAME} is a digital art marketplace for creating, selling, and collecting unique blockchain-secured art. \nJoin to explore authentic digital art from global artists.`}
          >
            <AboutGrid>
              {CURATORS.map((user) => (
                <AboutGridCard key={user.id}>
                  <FullArtist
                    artist={user}
                    bottomSection="none"
                    topSection={
                      <span
                        className={joinClasses(
                          MPColorClass.SolidNeutralGray5,
                          MPFonts.textSmallMedium
                        )}
                      >
                        {user.title}
                      </span>
                    }
                  />
                </AboutGridCard>
              ))}
            </AboutGrid>
          </AboutSection>
          <AboutSection
            id="concierge"
            title={`${APP_NAME} Concierge`}
            description={`${APP_NAME} is a digital art marketplace for creating, selling, and collecting unique blockchain-secured art. \nJoin to explore authentic digital art from global artists.`}
          >
            <HeroEditorialCard editorial={CONCIERGE_EDITORIAL} />
          </AboutSection>
          <AboutSection
            id="executive-team"
            title="Executive Team"
            description={`${APP_NAME} is a digital art marketplace for creating, selling, and collecting unique blockchain-secured art. \nJoin to explore authentic digital art from global artists.`}
          >
            <AboutGrid>
              {EXECUTIVE_TEAM.map((user) => (
                <AboutGridCard key={user.id}>
                  <FullArtist
                    artist={user}
                    bottomSection="none"
                    topSection={
                      <span
                        className={joinClasses(
                          MPColorClass.SolidNeutralGray5,
                          MPFonts.textSmallMedium
                        )}
                      >
                        {user.title}
                      </span>
                    }
                  />
                </AboutGridCard>
              ))}
            </AboutGrid>
          </AboutSection>
          <AboutSection
            id="investors"
            title="Our Investors"
            description={`${APP_NAME} is a digital art marketplace for creating, selling, and collecting unique blockchain-secured art. \nJoin to explore authentic digital art from global artists.`}
          >
            <AboutGrid>
              {INVESTORS.map((investor) => (
                <AboutGridCard key={investor.name}>
                  <LogoCard
                    name={investor.name}
                    imageUrl={investor.imageUrl}
                    viewUrl={investor.viewUrl}
                  />
                </AboutGridCard>
              ))}
            </AboutGrid>
          </AboutSection>
          <AboutSection
            id="press"
            title="Featured Press"
            description={`${APP_NAME} is a digital art marketplace for creating, selling, and collecting unique blockchain-secured art. \nJoin to explore authentic digital art from global artists.`}
          >
            <AboutGrid>
              {PRESS.map((press) => (
                <AboutGridCard key={press.name}>
                  <LogoCard
                    name={press.name}
                    description={press.description}
                    imageUrl={press.imageUrl}
                    viewUrl={press.viewUrl}
                  />
                </AboutGridCard>
              ))}
            </AboutGrid>
          </AboutSection>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AboutPage;
