.slide {
  overflow: auto;
  scrollbar-width: none;
}

.scrollContainerWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.scrollContainerOuter {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  width: 100%;
}

.scrollContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.gradientOverlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: var(--scroll-gradient-width);
  pointer-events: none;
  display: block;
  z-index: 1;

  @media (--mq-is-mobile) {
    display: none;
  }
}

.gradientOverlayRight {
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(245, 245, 245, 0) -4.76%,
    var(--color-backgroundDefault) 68.75%
  );
}

.gradientOverlayLeft {
  background: linear-gradient(
    90deg,
    var(--color-backgroundDefault) 30%,
    rgba(245, 245, 245, 0) 100%
  );
}

.scrollActionArrow {
  align-items: center;
  background-color: var(--color-commonWhite);
  border-radius: 50%;
  box-sizing: content-box;
  color: var(--color-commonBlack);
  display: flex;
  height: 29px;
  width: 29px;
  justify-content: center;
  position: relative;
  top: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;

  @media (--mq-is-mobile) {
    display: none;
  }

  &:hover,
  &:active {
    transition: color 300ms ease-out, border-color 300ms ease-out;
  }

  &:hover {
    border-color: var(--color-SolidNeutralGray1);
    color: var(--color-SolidNeutralGray5);
  }

  &:active {
    border-color: var(--color-SolidNeutralGray1);
    color: var(--color-SolidNeutralGray1);
  }
}
