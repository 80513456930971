import { Dispatch, SetStateAction } from 'react';

import { MPFonts } from '@mp-frontend/core-components';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import { NFTType } from 'types/graphql/NFT';
import { nftHasCustodialSale } from 'utils/nftUtils';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';
import CustodialDelistButton from './CustodialDelistButton';
import WalletDelistButton from './WalletDelistButton';

import * as styles from 'css/pages/product/ProductSetBuyNow.module.css';

interface ProductOwnerDelistingProps {
  nft: NFTType;
  saleContract: Pick<
    NFTContractQuery$data['nftContract'],
    'abidata' | 'address'
  >;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

function ProductOwnerDelisting({
  nft,
  saleContract,
  setCurrentTransactionHash,
  setOwnershipState,
}: ProductOwnerDelistingProps) {
  const isCustodialDelisting =
    nftHasCustodialSale(nft) || nft.listing.hasReservePrice;

  const showDelistingBlockchainMsg =
    !nft.isCustodialOwner && !nft.listing.hasReservePrice;

  return (
    <div>
      <div className={styles.productSellContainer}>
        <div className={styles.productSellMessageContainer}>
          <p className={MPFonts.paragraphNormal}>
            Delisting will cancel your lowest ask price. Buyers will continue to
            see that you&apos;re looking to sell this artwork.{' '}
            {!!showDelistingBlockchainMsg && (
              <>
                You will incur applicable blockchain fees associated with this
                action, since it will be performed with your digital wallet.
              </>
            )}
          </p>
        </div>
      </div>
      <div className={styles.productSellFooter}>
        <div className={styles.productSellListingBtnContainer}>
          {isCustodialDelisting ? (
            <CustodialDelistButton
              nft={nft}
              setOwnershipState={setOwnershipState}
            >
              Confirm
            </CustodialDelistButton>
          ) : (
            <WalletDelistButton
              nft={nft}
              saleContract={saleContract}
              setCurrentTransactionHash={setCurrentTransactionHash}
              setOwnershipState={setOwnershipState}
            >
              Confirm
            </WalletDelistButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductOwnerDelisting;
