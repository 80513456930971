.dialogTitle {
  --exploreDialog-prefixSize: 32px;
  --exploreDialog-horizontalPadding: 24px;

  box-sizing: border-box;
  padding: 16px var(--exploreDialog-horizontalPadding) 16px 0;
  width: 100%;

  @media (--mq-is-mobile) {
    --exploreDialog-prefixSize: 0px;
  }
}

.dialogDivider {
  border-color: var(--color-backgroundDefault);
}

.dialogContent {
  padding: 0;
  position: relative;
}
