.list {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2px;
  list-style-type: none;
  margin: -8px;
  padding: 0;

  @media (--mq-is-mobile) {
    gap: 6px;
  }

  & > li > label {
    box-sizing: border-box;
    padding: 8px;
    width: 100%;

    &:hover {
      background-color: var(--color-backgroundDefault);
    }
  }
}
