/**
 * @generated SignedSource<<ebbfab92ff2a762d70994172fb2514bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountAddCreditCardMutation$variables = {
  name: string;
  tokenStr: string;
  isDefault?: boolean | null;
};
export type AccountAddCreditCardMutation$data = {
  readonly addCreditCard: {
    readonly success: boolean | null;
  } | null;
};
export type AccountAddCreditCardMutation = {
  variables: AccountAddCreditCardMutation$variables;
  response: AccountAddCreditCardMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDefault"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenStr"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "isDefault",
            "variableName": "isDefault"
          },
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          },
          {
            "kind": "Variable",
            "name": "tokenStr",
            "variableName": "tokenStr"
          }
        ],
        "kind": "ObjectValue",
        "name": "requestData"
      }
    ],
    "concreteType": "AddCreditCardMutation",
    "kind": "LinkedField",
    "name": "addCreditCard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountAddCreditCardMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountAddCreditCardMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "73f52cecf9338d4e9fed0f42dfb65cd0",
    "id": null,
    "metadata": {},
    "name": "AccountAddCreditCardMutation",
    "operationKind": "mutation",
    "text": "mutation AccountAddCreditCardMutation(\n  $name: String!\n  $tokenStr: String!\n  $isDefault: Boolean\n) {\n  addCreditCard(requestData: {name: $name, tokenStr: $tokenStr, isDefault: $isDefault}) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "bca22b8a4b800e7d91687d44f9f0a7bc";

export default node;
