import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from '../MPSVGIcon';

const JCBIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M20.7707 16.3116C20.7707 17.7521 19.6032 18.9202 18.1622 18.9202H4V8.609C4 7.1681 5.16758 6 6.60875 6H20.7706L20.7706 16.3116L20.7707 16.3116Z"
      fill="white"
    />
    <path
      d="M16.1531 12.6865C16.5576 12.6953 16.9644 12.6686 17.3673 12.7003C17.7753 12.7764 17.8736 13.3941 17.5112 13.5964C17.2638 13.7297 16.97 13.646 16.7019 13.6695H16.1531V12.6865ZM17.6012 11.5738C17.6911 11.891 17.3853 12.1758 17.0797 12.1321H16.1531C16.1596 11.833 16.1404 11.5083 16.1625 11.2249C16.5337 11.2353 16.9084 11.2035 17.2774 11.2415C17.436 11.2813 17.5687 11.4117 17.6012 11.5738ZM19.8313 6.86953C19.8485 7.47533 19.8338 8.11316 19.8387 8.73125C19.8376 11.2442 19.8412 13.7571 19.8368 16.2701C19.8205 17.2119 18.9858 18.0303 18.0506 18.0491C17.1144 18.0529 16.1781 18.0496 15.2418 18.0507V14.2516C16.2619 14.2464 17.2826 14.2623 18.3023 14.2437C18.7754 14.214 19.2935 13.9019 19.3156 13.3813C19.3712 12.8584 18.8783 12.4968 18.4103 12.4397C18.2304 12.435 18.2357 12.3873 18.4103 12.3664C18.8567 12.2699 19.2072 11.808 19.0759 11.3453C18.964 10.8587 18.4261 10.6703 17.9787 10.6713C17.0666 10.6651 16.1542 10.6704 15.242 10.6686C15.2479 9.95937 15.2297 9.24937 15.2518 8.54066C15.3241 7.61587 16.1797 6.85322 17.1018 6.86977C18.0118 6.86963 18.9216 6.86963 19.8313 6.8696V6.86953Z"
      fill="url(#paint0_linear_9142_4493)"
    />
    <path
      d="M4.93418 8.63612C4.95747 7.69583 5.79568 6.8842 6.72974 6.87046C7.66246 6.86759 8.59531 6.87004 9.52802 6.86921C9.52546 10.0152 9.53307 13.1616 9.52418 16.3074C9.48824 17.2363 8.65917 18.0325 7.73529 18.0489C6.80081 18.0523 5.86623 18.0494 4.93172 18.0503V14.1232C5.83944 14.3377 6.79133 14.4289 7.71736 14.2867C8.27089 14.1976 8.87652 13.9258 9.06393 13.3515C9.20184 12.8603 9.12419 12.3433 9.14472 11.8391V10.6683H7.54213C7.53493 11.4427 7.55688 12.2184 7.53054 12.9918C7.48734 13.4672 7.01653 13.7693 6.56816 13.7532C6.0121 13.7591 4.91019 13.3502 4.91019 13.3502C4.90742 11.8993 4.92632 10.0823 4.93418 8.63626V8.63612Z"
      fill="url(#paint1_linear_9142_4493)"
    />
    <path
      d="M10.1254 11.1406C10.041 11.1585 10.1084 10.8533 10.0868 10.7375C10.0926 10.0054 10.0748 9.27245 10.0966 8.54086C10.1687 7.61216 11.0309 6.84761 11.9568 6.86935H14.6834C14.6808 10.0154 14.6884 13.1617 14.6795 16.3076C14.6436 17.2364 13.8144 18.0326 12.8905 18.049C11.956 18.0526 11.0214 18.0496 10.0868 18.0505V13.7478C10.7251 14.2715 11.5926 14.353 12.3878 14.3544C12.9872 14.3542 13.5832 14.2618 14.1654 14.1236V13.3353C13.5093 13.6623 12.7381 13.87 12.0108 13.6821C11.5035 13.5558 11.1352 13.0655 11.1434 12.542C11.0846 11.9975 11.4038 11.4227 11.9388 11.2608C12.6031 11.0529 13.3271 11.2119 13.9498 11.4823C14.0833 11.5522 14.2187 11.6388 14.1653 11.4158V10.7962C13.1239 10.5484 12.0156 10.4573 10.9693 10.7268C10.6664 10.8122 10.3714 10.9418 10.1254 11.1407V11.1406Z"
      fill="url(#paint2_linear_9142_4493)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9142_4493"
        x1="15.2104"
        y1="12.221"
        x2="19.8097"
        y2="12.221"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007B40" />
        <stop offset="1" stopColor="#55B330" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9142_4493"
        x1="4.83393"
        y1="12.5668"
        x2="9.45266"
        y2="12.5668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D2970" />
        <stop offset="1" stopColor="#006DBA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9142_4493"
        x1="10.0801"
        y1="12.3292"
        x2="14.6825"
        y2="12.3292"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E2B2F" />
        <stop offset="1" stopColor="#E30138" />
      </linearGradient>
    </defs>
  </MPSVGIcon>
));

export default JCBIcon;
