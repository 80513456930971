/* eslint-disable import/prefer-default-export */
import MPGraphQLError from 'errors/MPGraphQLError';
import IsGlobalContractError from 'utils/errors/contracts/global';
import isWalletError from 'utils/errors/wallet';

interface State {
  mutationError?: MPGraphQLError | Error;
  simulationError?: MPGraphQLError | Error;
}

export function getGeneralError(state: State) {
  let generalError = state.mutationError
    ? state.mutationError
    : state.simulationError;
  if (
    IsGlobalContractError.InsufficientFunds(generalError) ||
    isWalletError.ConnectorNotConnected(generalError)
  )
    generalError = undefined;
  if (IsGlobalContractError.InvalidInput(generalError) && !state.mutationError)
    generalError = undefined;
  return generalError;
}
