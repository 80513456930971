import { Route } from 'react-router-dom';

import Reload from 'components/Reload';

import ActivityPage from './ActivityPage';

const ActivityRoute = (
  <Route path="activity" element={<ActivityPage />}>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default ActivityRoute;
