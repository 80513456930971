import { ETH_SYMBOL, USD_SYMBOL } from 'constants/Symbols';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import assertUnreachable from 'utils/assertUnreachable';

// When possible, use the recoil selector SelectedCurrencyDisplaySymbol to get
// the user's currently selected symbol
export default function getCurrencyDisplaySymbol(currency: string) {
  switch (currency) {
    case CurrencyDisplayMode.USD:
      return USD_SYMBOL;
    case CurrencyDisplayMode.ETH:
      return ETH_SYMBOL;
    default:
      return assertUnreachable(currency);
  }
}
