import { BigNumber } from 'ethers';

import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export default function useDigitalMediaSaleCoreContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();

  const useBidOnToken = ({
    tokenId,
    bidAmount,
    tokenAddress,
  }: {
    bidAmount: BigNumber;
    tokenAddress: string;
    tokenId: string;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenId, tokenAddress],
      functionName: 'bidOnToken',
      value: bidAmount.toBigInt(),
      ...defaultOverrides,
    });

  const useCancelBid = ({
    tokenId,
    onchainId,
    tokenAddress,
  }: {
    onchainId: string;
    tokenAddress: string;
    tokenId: string;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenId, tokenAddress, onchainId],
      functionName: 'cancelBid',
      ...defaultOverrides,
    });

  const useAcceptBid = ({
    tokenId,
    onchainId,
    tokenAddress,
  }: {
    onchainId: string;
    tokenAddress: string;
    tokenId: string;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenId, tokenAddress, onchainId],
      functionName: 'acceptBid',
      ...defaultOverrides,
    });

  const useCreateSale = ({
    tokenId,
    tokenAddress,
    price,
    acceptFiat,
  }: {
    acceptFiat: boolean;
    price: bigint;
    tokenAddress: HexString;
    tokenId: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenId, tokenAddress, price, acceptFiat],
      functionName: 'createSale',
      ...defaultOverrides,
    });

  const useCancelSale = ({
    tokenId,
    tokenAddress,
  }: {
    tokenAddress: HexString;
    tokenId: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenId, tokenAddress],
      functionName: 'cancelSale',
      ...defaultOverrides,
    });

  const usePurchase = ({
    tokenId,
    tokenAddress,
    value,
  }: {
    tokenAddress: HexString;
    tokenId: string;
    value: BigNumber;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenId, tokenAddress],
      functionName: 'purchase',
      value: value.toBigInt(),
      ...defaultOverrides,
    });

  return {
    useAcceptBid,
    useBidOnToken,
    useCancelBid,
    useCancelSale,
    useCreateSale,
    usePurchase,
  };
}
