enum FontFamily {
  Bold = 'NB Akademie Pro Bold',
  Book = 'NB Akademie Pro Book',
  Light = 'NB Akademie Pro Light',
  Medium = 'NB Akademie Pro Medium',
  Regular = 'NB Akademie Pro Regular',
  SemiBold = 'NB Akademie Pro SemiBold',
}

export const FontFamilyCssVariable: Record<keyof typeof FontFamily, string> =
  Object.keys(FontFamily).reduce(
    (acc, fontFamily) => ({
      ...acc,
      [fontFamily]: `--font-${fontFamily.replace(/\s/g, '').toLowerCase()}`,
    }),
    {} as Record<keyof typeof FontFamily, string>
  );

export const FontFamilyCssVariableValue: Record<
  keyof typeof FontFamily,
  string
> = Object.keys(FontFamily).reduce(
  (acc, fontFamily) => ({
    ...acc,
    [fontFamily]: `var(${FontFamilyCssVariable[fontFamily]})`,
  }),
  {} as Record<keyof typeof FontFamily, string>
);

export default FontFamily;
