import { useMemo } from 'react';

import { useTrackingContext } from 'components/trackingContext';
import { createGATrackingQueryString, DropClickEventType } from 'GTM';

import { tagPush } from './base';

export default function useDropPageGTM() {
  const { dropTitle } = useTrackingContext();
  return useMemo(
    () => ({
      async trackClickCreatorActivityAvatar(
        event: DropClickEventType,
        creatorUsername: string
      ) {
        return tagPush(event, {
          detail: createGATrackingQueryString({
            creatorName: creatorUsername,
            dropTitle,
          }),
        });
      },
      async trackClickDropMoreInfoModalVideoPlayer(action: string) {
        return tagPush(DropClickEventType.DropMoreInfoModalVideoPlayer, {
          detail: createGATrackingQueryString({ action, dropTitle }),
        });
      },
      async trackClickEventType(event: DropClickEventType) {
        return tagPush(event, {
          detail: createGATrackingQueryString({ dropTitle }),
        });
      },
    }),
    [dropTitle]
  );
}
