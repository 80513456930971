import { useEffect, useMemo, useRef } from 'react';
import { useQueryLoaderHookType } from 'react-relay/relay-hooks/useQueryLoader';
import { PreloadableConcreteRequest, useQueryLoader } from 'react-relay';
import { FetchPolicy, GraphQLTaggedNode, OperationType } from 'relay-runtime';

export type useLoadQueryHookType<TQuery extends OperationType> = [
  useQueryLoaderHookType<TQuery>[0],
  useQueryLoaderHookType<TQuery>[1],
  useQueryLoaderHookType<TQuery>[2],
  () => void
];

enum LoadedStep {
  INITIAL,
  QUERIED_ONCE,
  MOUNTED_AND_QUERYABLE,
}

/* eslint-disable react-hooks/exhaustive-deps */
function useLoadQuery<TQuery extends OperationType>(
  query: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>,
  queryParams: TQuery['variables'],
  fetchPolicy: FetchPolicy | null | undefined = 'network-only',
  queryImmediately = false
): useLoadQueryHookType<TQuery> {
  const timeLoadedRef = useRef(
    queryImmediately ? LoadedStep.INITIAL : LoadedStep.MOUNTED_AND_QUERYABLE
  );
  const [queryRef, loadQuery, disposeFunction] = useQueryLoader<TQuery>(query);
  const invalidate = () => loadQuery(queryParams, { fetchPolicy });

  useMemo(() => {
    if (timeLoadedRef.current === LoadedStep.INITIAL) {
      timeLoadedRef.current = LoadedStep.QUERIED_ONCE;
      /**
       *  This is an anti-pattern, there should be no side-effects in the render portion of a function, as React reserves
       *  the right to go in and out of the render phases, and threatens to break
       *  things. However a suspended element is not mounted, and we can't load queries in that phase. Doing things this way
       *  allows us to delegate suspense to a single element, which is very critical according to Design.
       */
      loadQuery(queryParams);
    }
  }, []);

  useEffect(() => {
    if (timeLoadedRef.current === LoadedStep.MOUNTED_AND_QUERYABLE)
      loadQuery(queryParams);
    if (timeLoadedRef.current === LoadedStep.QUERIED_ONCE)
      timeLoadedRef.current = LoadedStep.MOUNTED_AND_QUERYABLE;
  }, Object.values(queryParams));

  return [queryRef, loadQuery, disposeFunction, invalidate];
}

export default useLoadQuery;
