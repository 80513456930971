import { useEffect } from 'react';

import { APP_NAME } from 'constants/Utils';
import setDocTitle from 'utils/setDocTitle';

export default function useSetDocTitle(pageTitle: string) {
  useEffect(() => {
    setDocTitle(`Settings / ${pageTitle} | ${APP_NAME}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
