import { ReactNode } from 'react';
import { merge } from 'lodash';
import { Button, ButtonProps, SxProps, Theme } from '@mui/material';

import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { LoaderIcon } from '../../icons';

export type MPButtonVariant = 'contained' | 'outlined' | 'text';

export interface MPButtonProps extends Omit<ButtonProps, 'size' | 'isLoading'> {
  /**
   * Contents of the Button
   */
  children?: ReactNode;

  /**
   * Controls whether or not the loading start adornment is seen, conflicts with startIcon
   */
  isLoading?: boolean;

  /**
   * Allowed Sizes
   */
  size?: 'small' | 'medium' | 'large' | 'toggle';

  /**
   * The system prop that allows defining system overrides as well as additional CSS styles
   */
  sx?: SxProps<Theme>;

  /**
   * Allowed variants
   */
  variant?: MPButtonVariant;
}

const sizeMap = {
  large: MPFonts.textSmallSemiBold,
  medium: MPFonts.buttonMedium,
  small: MPFonts.buttonSmall,
  toggle: MPFonts.buttonToggle,
};

const sizeMapSx = {
  large: { padding: '10px 18px 9.2px 18px' },
  medium: { padding: '8px 20px' },
  small: { padding: '6px 16px 7px 16px' },
  toggle: { padding: '8px 18px' },
};

const sizeMapLoading = {
  large: 16,
  medium: 14,
  small: 12,
  toggle: 13,
};

export default function MPButton({
  children,
  sx,
  className,
  startIcon,
  size = 'medium',
  variant = 'contained',
  isLoading = false,
  disabled = false,
  disableFocusRipple = true,
  disableRipple = true,
  disableTouchRipple = true,
  ...passedProps
}: MPButtonProps) {
  const mergedSx = merge(
    {
      borderRadius: '100px',
      textTransform: 'unset',
    },
    sizeMapSx[size],
    sx ?? {}
  );
  const mergedStartIcon =
    startIcon ||
    (isLoading ? (
      <LoaderIcon color="inherit" fontSize={`${sizeMapLoading[size]}`} />
    ) : undefined);

  return (
    <Button
      {...passedProps}
      className={className ? `${className} ${sizeMap[size]}` : sizeMap[size]}
      variant={variant}
      sx={mergedSx}
      startIcon={mergedStartIcon}
      disabled={disabled || isLoading}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      disableTouchRipple={disableTouchRipple}
    >
      {children}
    </Button>
  );
}
