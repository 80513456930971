import { SvgIcon } from '@mui/material';

export default function FlameIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        stroke="none"
        fillRule="evenodd"
        fill="currentColor"
        d="M 10.691406 0 C 10.75 7.597656 0 7.054688 0 15.210938 C 0 21.566406 8.003906 24 11.503906 24 C 15.003906 24 24 22.910156 24 15.117188 C 24 12.425781 22.613281 10.265625 19.835938 8.636719 C 20.925781 9.988281 22.039062 14.25 16.730469 15.96875 C 14.726562 16.617188 11.757812 15.699219 12.199219 13.015625 C 12.667969 10.15625 16.476562 11.8125 16.476562 6.675781 C 16.476562 3.503906 12.722656 1.316406 10.691406 0 Z M 10.691406 0 "
      />
    </SvgIcon>
  );
}
