import { buttonClasses } from '@mui/material';
import { styled } from '@mui/system';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';
import { MPFonts } from '../themes/default/__generated__/MPFontsEnum';

import { MPButton } from '..';
import MPSelectorItem, { MPSelectorItemProps } from './MPSelectorItem';

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledHeader = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledLabel = styled('div')`
  color: ${MPColorValue.CommonBlack};
  cursor: default;
`;

const StyledList = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled('li')`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const actionButtonSx = {
  [`&.${buttonClasses.root}, &.${buttonClasses.root}:hover`]: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    minWidth: 'unset',
    padding: 0,
    textDecoration: 'underline',
  },
  [`&.${buttonClasses.root}`]: {
    [`&:hover`]: {
      color: MPColorValue.SolidNeutralGray3,
    },
    color: MPColorValue.SolidNeutralGray5,
  },
};

export interface MPSelectorProps {
  items: MPSelectorItemProps[];
  action?: { onClick: () => void; title: string };
  label?: string;
}

export default function MPSelector({ items, action = null, label = null }) {
  return (
    <StyledContainer>
      <StyledHeader>
        {label ? (
          <StyledLabel className={MPFonts.inputLabel}>{label}</StyledLabel>
        ) : null}

        {action ? (
          <MPButton
            type="button"
            className={MPFonts.buttonMedium}
            onClick={action.onClick}
            variant="text"
            sx={actionButtonSx}
          >
            {action.title}
          </MPButton>
        ) : null}
      </StyledHeader>

      <StyledList>
        {items.map((item: MPSelectorItemProps) => (
          <StyledListItem key={item.title}>
            <MPSelectorItem {...item} />
          </StyledListItem>
        ))}
      </StyledList>
    </StyledContainer>
  );
}
