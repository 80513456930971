import { SvgIcon } from '@mui/material';

export default function ViewIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.9945 4C5.37032 4 0 11.6364 0 11.6364C0 11.6364 5.37032 19.2727 11.9945 19.2727C18.6186 19.2727 23.9889 11.6364 23.9889 11.6364C23.9889 11.6364 18.6186 4 11.9945 4ZM11.9945 15.3032C9.98613 15.3032 8.36616 13.666 8.36616 11.6364C8.36616 9.60673 9.98613 7.96956 11.9945 7.96956C14.0028 7.96956 15.6227 9.60673 15.6227 11.6364C15.6227 13.666 14.0028 15.3032 11.9945 15.3032Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
