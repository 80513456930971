import ExploreArtworksPaginatedFragmentType from 'graphql/__generated__/ExploreArtworksPaginatedFragment.graphql';
import { NFTsProductCardFragment$data } from 'graphql/__generated__/NFTsProductCardFragment.graphql';
import PaginatedExploreArtworksQueryType, {
  PaginatedExploreArtworksQuery,
} from 'graphql/__generated__/PaginatedExploreArtworksQuery.graphql';

import ArtworkCard from 'components/cards/ArtworkCard';

import Cards from '../Cards';
import Layout, { BaseLayoutProps } from '../Layout';
import { ExploreType } from '../types';
import Chips from './Chips';
import Filters, {
  ArtworksFiltersState,
  useArtworksFiltersQueryValues,
} from './Filters';
import Hero from './Hero';

interface ExploreArtworksProps extends BaseLayoutProps {
  filters: ArtworksFiltersState;
  onFiltersChange: (value: Partial<ArtworksFiltersState>) => void;
  onFiltersReset: () => void;
}

function ExploreArtworks({
  filters,
  onFiltersChange,
  onFiltersReset,
  ...props
}: ExploreArtworksProps) {
  const queryValues = useArtworksFiltersQueryValues(filters);

  return (
    <Layout
      {...props}
      hero={<Hero heroArtworkQuery={{ variables: {} }} />}
      cards={
        <Cards<PaginatedExploreArtworksQuery, NFTsProductCardFragment$data>
          exploreType={ExploreType.Artworks}
          filters={
            <>
              <Filters state={filters} onChange={onFiltersChange} />
              <Chips
                filters={filters}
                onChange={onFiltersChange}
                onReset={onFiltersReset}
              />
            </>
          }
          variables={queryValues}
          fragmentConcreteRequest={ExploreArtworksPaginatedFragmentType}
          queryConcreteRequest={PaginatedExploreArtworksQueryType}
          renderElement={(nft) => <ArtworkCard key={nft.id} nft={nft} />}
        />
      }
    />
  );
}

export default ExploreArtworks;
