import { ReactNode, Suspense } from 'react';

import { LoaderIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/DefaultSuspense.module.css';

export interface DefaultLoaderProps {
  className?: string;
  loaderClassName?: string;
  position?: 'absolute' | 'relative';
}

export function DefaultLoader({
  className,
  loaderClassName,
  position,
}: DefaultLoaderProps) {
  return (
    <div
      className={joinClasses(className, styles.container, {
        [styles.absolute]: position === 'absolute',
      })}
    >
      <LoaderIcon className={joinClasses(styles.spinner, loaderClassName)} />
    </div>
  );
}

export interface DefaultSuspenseProps extends DefaultLoaderProps {
  children: ReactNode;
}

export default function DefaultSuspense({
  children,
  ...props
}: DefaultSuspenseProps) {
  return (
    <Suspense fallback={<DefaultLoader {...props} />}>{children}</Suspense>
  );
}
