.container {
  background-color: var(--color-commonBlack);
  box-sizing: border-box;
  color: var(--color-commonWhite);
  cursor: default;
  height: 0;
  overflow: hidden;
  position: relative;
  text-align: left;
  transition: height 0.4s ease-in-out;
  width: 100%;
}

.container.show {
  height: 48px;
}

.container > .content {
  padding: 0 48px;
}

.link {
  color: inherit;
  text-decoration: underline;
}

:global(#navbar-root):has(.container.show) ~ :global(.page_content_wrp) {
  padding-top: calc(5.5em + 48px);
}

:global(.react_content_main) {
  padding-top: 0px;
}

:global(#navbar-root):has(.container.show) ~ :global(.react_content_main) {
  padding-top: 48px;
  transition: padding 0.2s ease-in-out;
}

.closeButton {
  position: absolute;
  right: 12;
  top: 8;
}

@media (--mq-is-mobile) {
  .container.show {
    height: 92px;
  }

  .container > .content {
    padding: 16px 66px 16px 24px;
  }

  .closeButton {
    position: absolute;
    right: 24;
    top: 30;
  }


  :global(#navbar-root):has(.container.show) ~ :global(.page_content_wrp) {
    padding-top: calc(5.5em + 92px);
  }

  :global(#navbar-root):has(.container.show) ~ :global(.react_content_main) {
    padding-top: 72px;
  }
}
