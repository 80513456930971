// Social Links
export const INSTAGRAM_PROTOCOLLESS_BASE_URL = 'instagram.com/';
export const INSTAGRAM_BASE_URL = `https://${INSTAGRAM_PROTOCOLLESS_BASE_URL}`;
export const INSTAGRAM_MP_PROFILE_URL = `${INSTAGRAM_BASE_URL}makersplace/`;
export const TWITTER_PROTOCOLLESS_BASE_URL = 'twitter.com/';
export const TWITTER_BASE_URL = `https://${TWITTER_PROTOCOLLESS_BASE_URL}`;
export const TWITTER_PROFILE_USERNAME = 'makersplace';
export const TWITTER_MP_PROFILE_URL = `${TWITTER_BASE_URL}${TWITTER_PROFILE_USERNAME}/`;
export const FACEBOOK_PROTOCOLLESS_BASE_URL = 'facebook.com';
export const FACEBOOK_BASE_URL = `https://${FACEBOOK_PROTOCOLLESS_BASE_URL}/`;
export const FACEBOOK_MP_PROFILE_URL = `${FACEBOOK_BASE_URL}makersplace/`;
export const DISCORD_PROTOCOLLESS_USR_BASE_URL = 'discordapp.com/users/';
export const DISCORD_USR_BASE_URL = `https://${DISCORD_PROTOCOLLESS_USR_BASE_URL}/`;
export const DISCORD_MP_PROFILE_URL = 'https://discord.gg/MakersPlace';
export const PINTEREST_PROTOCOLLESS_BASE_URL = 'pinterest.com/';
export const PINTEREST_BASE_URL = `https://${PINTEREST_PROTOCOLLESS_BASE_URL}`;
export const PINTEREST_MP_PROFILE_URL = `${PINTEREST_BASE_URL}makersplaceco/`; // eslint-disable-line @cspell/spellchecker
export const YOUTUBE_CHANNEL_BASE_URL = 'https://youtube.com/';
export const YOUTUBE_USER_BASE_URL = 'https://youtube.com/user/';
export const ARTSTATION_BASE_URL = 'https://artstation.com/';
export const BEHANCE_BASE_URL = 'https://behance.net/';
export const STEEMIT_BASE_URL = 'https://steemit.com/@';
export const METAMASK_LINK = 'https://metamask.io/';
export const METAMASK_DEEP_LINK_URL = 'https://metamask.app.link/dapp/';
export const COINBASE_LINK_URL = 'https://go.cb-w.com/dapp?cb_url=';
export const MEDIUM_MP_PROFILE_URL = 'https://medium.com/makersplace/';
export const LEVER_CAREERS_URL = 'https://jobs.lever.co/MakersPlace';
export const ETH_TO_USD_EXCHANGE_RATE_URL =
  'https://api.coinbase.com/v2/exchange-rates?currency=ETH';
export const SUPPORT_EMAIL = 'supporthelp@makersplace.com';
export const GM_STUDIO_GENERATOR = 'https://www.gmgenerator.io/eth';
