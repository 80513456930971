import { useMemo } from 'react';

import { useTrackingContext } from 'components/trackingContext';

import { tagPush } from './base';

export default function useNavbarGTM() {
  const trackingContext = useTrackingContext();
  return useMemo(
    () => ({
      clickNavigationItem: function navbarTrackingClickNavigationItem(
        item: string
      ) {
        tagPush('navbar_click_navigation_item', {
          item,
          panel: trackingContext.panel,
        });
      },
      panelOpen: function navbarTrackingPanelOpen(panel?: string) {
        tagPush('navbar_panel_open', { panel: panel ?? trackingContext.panel });
      },
      search: function navbarTrackingSearch(term: string) {
        tagPush('navbar_search', { panel: trackingContext.panel, query: term });
      },
    }),
    [trackingContext]
  );
}
