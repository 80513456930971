import { useAccount, useDisconnect } from 'wagmi';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { NFTType } from 'types/graphql/NFT';
import generateFormattedUserFullName from 'utils/generateFormattedUserFullName';

import * as styles from '../../../css/pages/product/ProductSetBuyNow.module.css';

interface ProductOwnerWalletDisconnectProps {
  nft: NFTType;
}

function ProductOwnerWalletDisconnect({
  nft,
}: ProductOwnerWalletDisconnectProps) {
  const { address: currentUserAddress } = useAccount();
  const { disconnect } = useDisconnect();
  return (
    <div>
      <div className={styles.productSellContainer}>
        <div
          className={joinClasses(
            MPFonts.paragraphNormal,
            styles.productSellMessageContainer
          )}
        >
          In order to list this artwork on sale, you will need to connect to
          your Digital wallet:&nbsp;
          {generateFormattedUserFullName(nft.currentOwnerAddress)}
          &nbsp;and disconnect from Digital Wallet:&nbsp;
          {generateFormattedUserFullName(currentUserAddress)}
        </div>
      </div>
      <div className={styles.productSellFooter}>
        <div className={styles.productSellListingBtnContainer}>
          <MPActionButton fullWidth size="large" onClick={() => disconnect()}>
            Disconnect
          </MPActionButton>
        </div>
      </div>
    </div>
  );
}

export default ProductOwnerWalletDisconnect;
