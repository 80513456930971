import { ReactNode, useRef } from 'react';

interface ITabPanel {
  currentSelected: string;
  value: string;
  children?: ReactNode;
}

function TabPanel({ value, currentSelected, children }: ITabPanel) {
  const prevValue = useRef(value);
  const wasShown = useRef(false);
  const changedValue = value !== prevValue.current;
  const isSelected = currentSelected === value;
  if (changedValue) {
    prevValue.current = value;
    wasShown.current = false;
  }
  if (isSelected) {
    wasShown.current = true;
  }
  return isSelected || (!changedValue && wasShown.current) ? (
    <div className={!isSelected ? 'hidden' : ''}>{children}</div>
  ) : null;
}

export default TabPanel;
