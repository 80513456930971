import MPSVGIcon from './MPSVGIcon';

export default function HeightIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <rect
        x="5.6"
        y="5.6"
        width="12.8"
        height="12.7999"
        stroke="var(--color-SolidNeutralGray1)"
        strokeWidth="3.2"
        fill="var(--color-commonWhite)"
      />
      <line
        x1="18.4"
        y1="20"
        x2="18.4"
        y2="4.00007"
        stroke="currentColor"
        strokeWidth="3.2"
      />
    </MPSVGIcon>
  );
}
