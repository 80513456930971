.fullScreenOverlayExperiment {
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: var(--navBar-height);
  transform: translate(-50%, 0);
  z-index: 5000;

  &.loading {
    display: none;
  }
}

.fullScreenOverlayExperiment button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  z-index: 1000;
}

.fullScreenOverlayExperiment img,
.fullScreenOverlayExperiment video {
  height: 100%;
  max-height: calc(min(100vw, 100vh - var(--navBar-height)));
  max-width: calc(min(100vw, 100vh));
  object-fit: contain;
  pointer-events: none;
  width: auto;
}
