import { ReactNode } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

type VariantType = 'filled' | 'outlined';

interface LikesIconProps extends MPSVGIconProps {
  variant?: VariantType;
}

const variantsMap: Record<VariantType, ReactNode> = {
  filled: (
    <path
      fill="currentColor"
      d="M11.1661 4.00956L11.9027 4.7444L12.6393 4.00956C14.9985 1.64756 18.8629 1.26964 21.4463 3.40069C24.4247 5.85716 24.5848 10.2872 21.916 12.9641L11.9027 23L1.88942 12.9641C-0.779373 10.2872 -0.619246 5.86766 2.35912 3.40069C4.93183 1.28014 8.80692 1.64756 11.1661 4.00956Z"
    />
  ),
  outlined: (
    <path
      fill="currentColor"
      d="M11.9115 23L2.17336 13.835C0.703922 12.4436 -0.0806088 10.5347 0.0065613 8.57979C0.106184 6.6364 1.08996 4.7965 2.70883 3.54307C5.31148 1.51918 9.14697 1.50768 11.8991 3.38208C14.6636 1.51918 18.4866 1.51918 21.0893 3.54307C22.7081 4.7965 23.6795 6.6249 23.7915 8.57979C23.8912 10.5347 23.1066 12.4436 21.6372 13.835L11.8991 23H11.9115ZM7.36621 6.01543C6.69375 6.01543 6.0462 6.21092 5.51073 6.6249C4.81337 7.16537 4.40242 7.92433 4.36507 8.77528C4.31525 9.61473 4.65148 10.4312 5.27413 11.0177L11.8991 17.2618L18.524 11.0177C19.1466 10.4312 19.4829 9.61473 19.433 8.77528C19.3832 7.93583 18.9847 7.16537 18.2874 6.6249C17.0919 5.70495 15.2364 5.85444 14.0534 6.95838L11.8991 8.98227L9.74471 6.95838C9.0847 6.33741 8.213 6.01543 7.35376 6.01543H7.36621Z"
    />
  ),
};

export default function LikesIcon({
  variant = 'filled',
  ...props
}: LikesIconProps) {
  return <MPSVGIcon {...props}>{variantsMap[variant]}</MPSVGIcon>;
}
