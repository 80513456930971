import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/common/LineClamp.module.css';

interface LineClampProps {
  className: string;
  lineClamp: number;
  text: string;
}

export default function LineClamp({
  lineClamp,
  text,
  className,
}: LineClampProps) {
  return (
    <div
      style={{ '--line-clamp': lineClamp } as any}
      className={joinClasses(className, styles.lineClampText)}
    >
      {text}
    </div>
  );
}
