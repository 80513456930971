import { SvgIcon } from '@mui/material';

export default function ActivityIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2644 9.29106L8.85577 12.6193C8.71603 12.9495 8.38833 13.1645 8.025 13.1645H3.675C3.30221 13.1645 3 12.867 3 12.5C3 12.133 3.30221 11.8355 3.675 11.8355H7.725L9.54068 7.54537C9.874 6.75782 11.0344 6.84356 11.2446 7.67126L13.2856 15.7089L14.6942 12.3807C14.834 12.0505 15.1617 11.8355 15.525 11.8355H20.325C20.6978 11.8355 21 12.133 21 12.5C21 12.867 20.6978 13.1645 20.325 13.1645H15.825L14.0093 17.4546C13.676 18.2422 12.5156 18.1565 12.3054 17.3287L10.2644 9.29106Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
