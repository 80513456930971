/**
 * @generated SignedSource<<575fc318c5912c9c4e1ca7af29d2d821>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LockListingArguments = {
  productId: number;
  isPresale?: boolean | null;
};
export type ListingLockListingMutation$variables = {
  requestData: LockListingArguments;
};
export type ListingLockListingMutation$data = {
  readonly lockListing: {
    readonly response: {
      readonly canReserveEditions: boolean | null;
      readonly lockedAt: any | null;
      readonly lockedProductId: number | null;
      readonly productUrl: string | null;
    } | null;
  } | null;
};
export type ListingLockListingMutation = {
  variables: ListingLockListingMutation$variables;
  response: ListingLockListingMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "LockListingMutation",
    "kind": "LinkedField",
    "name": "lockListing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LockProductType",
        "kind": "LinkedField",
        "name": "response",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canReserveEditions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedProductId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListingLockListingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListingLockListingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2798ab25890c9656bc69f343cae0944d",
    "id": null,
    "metadata": {},
    "name": "ListingLockListingMutation",
    "operationKind": "mutation",
    "text": "mutation ListingLockListingMutation(\n  $requestData: LockListingArguments!\n) {\n  lockListing(requestData: $requestData) {\n    response {\n      canReserveEditions\n      lockedAt\n      lockedProductId\n      productUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6fd435bcad410d4ba9e7bffcf22d058";

export default node;
