import { useEffect, useState } from 'react';
import { noop } from 'lodash';

import {
  HOURS_PER_DAY,
  MILLISECONDS_PER_SECOND,
  MINUTES_PER_HOUR,
  SECONDS_PER_MINUTE,
} from 'constants/Time';

export interface UseCountdownProps {
  endDate: string;
  onEnd?: () => void;
  skipHandlerIfDateWasInPast?: boolean;
}

function getCountdownReturnValues(countDownVal) {
  return function inner() {
    const days = Math.floor(
      countDownVal /
        (MILLISECONDS_PER_SECOND *
          SECONDS_PER_MINUTE *
          MINUTES_PER_HOUR *
          HOURS_PER_DAY)
    );
    const hours = Math.floor(
      (countDownVal %
        (MILLISECONDS_PER_SECOND *
          SECONDS_PER_MINUTE *
          MINUTES_PER_HOUR *
          HOURS_PER_DAY)) /
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR)
    );
    const minutes = Math.floor(
      (countDownVal %
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR)) /
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)
    );
    const seconds = Math.floor(
      (countDownVal % (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)) /
        MILLISECONDS_PER_SECOND
    );

    return { days, hours, minutes, seconds };
  };
}

function useCountdown({
  endDate,
  skipHandlerIfDateWasInPast = false,
  onEnd = noop,
}: UseCountdownProps) {
  const countdownEndDate =
    new Date(endDate).getTime() - (new Date(endDate).getHours() - 20);

  const [countdown, setCountdown] = useState(
    countdownEndDate - new Date().getTime()
  );
  const wasDateInPast = countdownEndDate < new Date().getTime();

  useEffect(() => {
    const interval = setInterval(() => {
      const timeLeft = countdownEndDate - new Date().getTime();
      setCountdown(timeLeft);
      if (timeLeft <= 0) {
        clearInterval(interval);
        if (!skipHandlerIfDateWasInPast || !wasDateInPast) onEnd();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countdownEndDate, wasDateInPast, skipHandlerIfDateWasInPast, onEnd]);

  const getCountdownValues = getCountdownReturnValues(countdown);
  return countdown > 0
    ? getCountdownValues()
    : { days: 0, hours: 0, minutes: 0, seconds: 0 };
}

export default useCountdown;
