import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const useBodyRefState = atom({
  default: null,
  key: 'bodyRef',
});

export function useSetBodyRef() {
  const setBodyRef = useSetRecoilState(useBodyRefState);
  return useCallback(
    (ref) => {
      if (ref) setBodyRef(ref);
    },
    [setBodyRef]
  );
}

export default function useBodyRef() {
  return useRecoilValue(useBodyRefState);
}
