import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPBackgroundColorClass,
  MPColorClass,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
  MPTooltip,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountDisableTwoFactor, {
  AccountDisableTwoFactorMutation,
} from 'graphql/__generated__/AccountDisableTwoFactorMutation.graphql';
import AccountFinalizeTwoFactorSetup, {
  AccountFinalizeTwoFactorSetupMutation,
} from 'graphql/__generated__/AccountFinalizeTwoFactorSetupMutation.graphql';
import AccountSetUpTwoFactor, {
  AccountSetUpTwoFactorMutation,
} from 'graphql/__generated__/AccountSetUpTwoFactorMutation.graphql';
import { MpErrors, VerifyChannelEnum } from 'types/__generated__/graphql';

import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSPadding from 'types/enums/css/Padding';

import SubSection from '../common/SubSection';
import LabelWithTooltip from '../personal/LabelWithTooltip';

import { SessionType } from 'Session';

const CHANNEL_TITLE = {
  [VerifyChannelEnum.Email]: 'Email',
  [VerifyChannelEnum.Sms]: 'SMS',
};

const CHANNEL_RECIPIENT = {
  [VerifyChannelEnum.Email]: 'email address',
  [VerifyChannelEnum.Sms]: 'phone number',
};

const CHANNEL_ACCOUNT_PROPERTY: Record<
  VerifyChannelEnum,
  keyof SessionType['account']
> = {
  [VerifyChannelEnum.Email]: 'email',
  [VerifyChannelEnum.Sms]: 'phoneNumber',
};

enum TwoFactorDialogStep {
  EnterCode = 1,
  Success = 2,
}

function TwoFactorSetUpDialog({
  channel,
  onClose,
}: {
  onClose: (isEnabled?: boolean) => void;
  channel?: VerifyChannelEnum;
}) {
  const session = useSession();
  const [currentStep, setCurrentStep] = useState<TwoFactorDialogStep>(
    TwoFactorDialogStep.EnterCode
  );
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const title = useMemo(() => `2FA for ${CHANNEL_TITLE[channel]}`, [channel]);
  const recipient = useMemo(
    () =>
      channel === VerifyChannelEnum.Email
        ? session.account.email
        : session.account.phoneNumber,
    [channel, session.account.email, session.account.phoneNumber]
  );
  const containerClass = useMemo(
    () =>
      joinClasses(
        MPColorClass.CommonBlack,
        CSSGlobal.Cursor.Default,
        CSSGlobal.Flex.Col,
        CSSGap[24]
      ),
    []
  );

  const [setUpTwoFactor, isTwoFactorSetting] =
    useMutation<AccountSetUpTwoFactorMutation>(AccountSetUpTwoFactor);
  const handleSendCode = useCallback(
    () =>
      setUpTwoFactor({
        onCompleted: () => {
          setCodeError('');
          setCurrentStep(TwoFactorDialogStep.EnterCode);
        },
        onError: (error) => setCodeError(error.message),
        variables: { channel },
      }),
    [channel, setUpTwoFactor]
  );
  const [finalizeTwoFactorSetup, isTwoFactorFinalizing] =
    useMutation<AccountFinalizeTwoFactorSetupMutation>(
      AccountFinalizeTwoFactorSetup
    );
  const handleFinalize = useCallback(
    () =>
      finalizeTwoFactorSetup({
        onCompleted: () => setCurrentStep(TwoFactorDialogStep.Success),
        onError: (error) => setCodeError(error.message),
        variables: { channel, code },
      }),
    [channel, code, finalizeTwoFactorSetup]
  );
  const handleClose = useCallback(() => onClose(false), [onClose]);
  const handleSuccessfulClose = useCallback(() => onClose(true), [onClose]);

  useEffect(() => {
    handleSendCode();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    [TwoFactorDialogStep.EnterCode]: (
      <MPStandardDialog title={title} onClose={handleClose}>
        <div className={containerClass}>
          <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
            <div className={MPFonts.paragraphSmall}>
              Enter the authentication code that was just sent to{' '}
              <span className={MPFonts.textSmallMedium}>{recipient}</span>.
            </div>

            <MPStyledTextField
              autoFocus
              error={codeError}
              fullWidth
              label="Authentication Code"
              placeholder="Enter 2FA code"
              type="text"
              value={code}
              setValue={setCode}
            />
          </div>

          <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
            <MPActionButton
              disabled={!code || isTwoFactorSetting || isTwoFactorFinalizing}
              fullWidth
              size="large"
              variant="primary"
              onClick={handleFinalize}
            >
              Continue
            </MPActionButton>

            <MPActionButton
              disabled={isTwoFactorSetting}
              fullWidth
              size="large"
              variant="secondary"
              onClick={handleSendCode}
            >
              Resend Code
            </MPActionButton>
          </div>
        </div>
      </MPStandardDialog>
    ),
    [TwoFactorDialogStep.Success]: (
      <MPStandardDialog title={title} onClose={handleClose}>
        <div className={containerClass}>
          <div className={MPFonts.paragraphSmall}>
            2FA for {CHANNEL_TITLE[channel]} has been successfully set up. From
            now on, you&apos;ll be asked to enter a verification code sent to{' '}
            <span className={MPFonts.textSmallMedium}>{recipient}</span> when
            signing in.
          </div>

          <MPActionButton
            fullWidth
            size="large"
            variant="primary"
            onClick={handleSuccessfulClose}
          >
            Continue
          </MPActionButton>
        </div>
      </MPStandardDialog>
    ),
  }[currentStep];
}

function TwoFactorSwitchMessage({ channel }: { channel: VerifyChannelEnum }) {
  return (
    <div
      className={joinClasses(
        MPColorClass.CommonBlack,
        MPBackgroundColorClass.BackgroundDefault,
        MPFonts.paragraphSmall,
        CSSGlobal.Cursor.Default,
        CSSPadding.AROUND[24]
      )}
    >
      In order to enable 2FA for{' '}
      {
        CHANNEL_TITLE[
          channel === VerifyChannelEnum.Email
            ? VerifyChannelEnum.Sms
            : VerifyChannelEnum.Email
        ]
      }
      , please disable 2FA for {CHANNEL_TITLE[channel]}.
    </div>
  );
}

export default function TwoFactorSubSection() {
  const session = useSession();

  const [twoFactorEmailEnabled, setTwoFactorEmailEnabled] = useState(
    session.account.twoFactorEmailEnabled
  );
  const [twoFactorSmsEnabled, setTwoFactorSmsEnabled] = useState(
    session.account.twoFactorSmsEnabled
  );
  const [showTwoFactorDialog, setShowTwoFactorDialog] =
    useState<VerifyChannelEnum | null>(null);

  const [disableCode, setDisableCode] = useState('');
  const [disableTwoFactor, isTwoFactorDisabling] =
    useMutation<AccountDisableTwoFactorMutation>(AccountDisableTwoFactor);

  const [showDisableTwoFactorDialog, setShowDisableTwoFactorDialog] =
    useState(false);
  const handleCloseDisableTwoFactorDialog = useCallback(() => {
    setShowDisableTwoFactorDialog(false);
    setDisableCode('');
  }, []);

  const handleDisableTwoFactor = useCallback(
    () =>
      disableTwoFactor({
        onCompleted: () => {
          setTwoFactorEmailEnabled(false);
          setTwoFactorSmsEnabled(false);
          setShowDisableTwoFactorDialog(false);
          setDisableCode('');
        },
        onError: (error) => {
          if (error.name === MpErrors.TwoFactorNeed_2FaToChange_2Fa) {
            setShowDisableTwoFactorDialog(true);
          }
        },
        variables: {
          requestData: { code: disableCode || undefined },
        },
      }),
    [disableCode, disableTwoFactor]
  );

  const handleCloseTwoFactorDialog = useCallback(
    (isEnabled = false) => {
      if (isEnabled) {
        if (showTwoFactorDialog === VerifyChannelEnum.Email) {
          setTwoFactorEmailEnabled(true);
        } else {
          setTwoFactorSmsEnabled(true);
        }
      }

      setShowTwoFactorDialog(null);
    },
    [showTwoFactorDialog]
  );

  return (
    <>
      <SubSection
        title={
          <LabelWithTooltip
            label="Two-Factor Authentication"
            tooltip="Two-Factor Authentication (2FA) is an extra layer of security to ensure that only you have the ability to log in and perform sensitive actions on your account."
          />
        }
      >
        {twoFactorEmailEnabled || twoFactorSmsEnabled ? (
          <>
            {twoFactorEmailEnabled ? (
              <MPActionButton
                disabled={isTwoFactorDisabling}
                fullWidth
                isLoading={isTwoFactorDisabling}
                size="large"
                variant="secondary-black"
                onClick={handleDisableTwoFactor}
              >
                2FA for Email Enabled
              </MPActionButton>
            ) : (
              <MPActionButton
                disabled={isTwoFactorDisabling}
                fullWidth
                isLoading={isTwoFactorDisabling}
                size="large"
                variant="secondary-black"
                onClick={handleDisableTwoFactor}
              >
                2FA for SMS Enabled
              </MPActionButton>
            )}

            <TwoFactorSwitchMessage
              channel={
                twoFactorEmailEnabled
                  ? VerifyChannelEnum.Email
                  : VerifyChannelEnum.Sms
              }
            />
          </>
        ) : (
          <>
            {[VerifyChannelEnum.Email, VerifyChannelEnum.Sms].map((channel) => {
              const isDisabled =
                !session.account[CHANNEL_ACCOUNT_PROPERTY[channel]];
              const Button = (
                <MPActionButton
                  disabled={isDisabled}
                  fullWidth
                  size="large"
                  variant="secondary"
                  onClick={() => setShowTwoFactorDialog(channel)}
                >
                  Enable 2FA for {CHANNEL_TITLE[channel]}
                </MPActionButton>
              );

              return (
                <Fragment key={channel}>
                  {isDisabled ? (
                    <MPTooltip
                      placement="top"
                      title={`To enable 2FA for ${CHANNEL_TITLE[channel]}, please enter your ${CHANNEL_RECIPIENT[channel]} above.`}
                    >
                      <span>{Button}</span>
                    </MPTooltip>
                  ) : (
                    Button
                  )}
                </Fragment>
              );
            })}
          </>
        )}
      </SubSection>

      {!!showTwoFactorDialog && (
        <TwoFactorSetUpDialog
          channel={showTwoFactorDialog}
          onClose={handleCloseTwoFactorDialog}
        />
      )}

      {!!showDisableTwoFactorDialog && (
        <MPStandardDialog
          title="Disable 2FA"
          onClose={handleCloseDisableTwoFactorDialog}
        >
          <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
            <div className={MPFonts.paragraphSmall}>
              Enter the authentication code that was just sent to{' '}
              <span className={MPFonts.textSmallMedium}>
                {session.account.twoFactorEmailEnabled
                  ? session.account.email
                  : session.account.phoneNumber}
              </span>
              .
            </div>

            <MPStyledTextField
              autoFocus
              fullWidth
              label="Authentication Code"
              placeholder="Enter 2FA code"
              type="text"
              value={disableCode}
              setValue={setDisableCode}
            />

            <MPActionButton
              disabled={!disableCode || isTwoFactorDisabling}
              fullWidth
              size="large"
              variant="primary"
              onClick={handleDisableTwoFactor}
            >
              Continue
            </MPActionButton>
          </div>
        </MPStandardDialog>
      )}
    </>
  );
}
