import DefaultErrorBoundary from './DefaultErrorBoundary';

class SuspenselessErrorBoundary extends DefaultErrorBoundary {
  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return !this.props.hideState ? (
        <>Oops, something went wrong...</>
      ) : (
        <div />
      );
    }
    return <>{this.props.children}</>;
  }
}

export default SuspenselessErrorBoundary;
