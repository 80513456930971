/**
 * @generated SignedSource<<946bc1945de9fed47d25b117f0314986>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SessionFreeMintingInfoMutation$variables = {};
export type SessionFreeMintingInfoMutation$data = {
  readonly session: {
    readonly account: {
      readonly id: string;
      readonly freeMintingInfo: {
        readonly count: number;
        readonly earliestExpiry: any | null;
      };
    } | null;
  } | null;
};
export type SessionFreeMintingInfoMutation = {
  variables: SessionFreeMintingInfoMutation$variables;
  response: SessionFreeMintingInfoMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SessionMutation",
    "kind": "LinkedField",
    "name": "session",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountSessionType",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FreeMintInfoType",
            "kind": "LinkedField",
            "name": "freeMintingInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "earliestExpiry",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionFreeMintingInfoMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionFreeMintingInfoMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b512973cfb15ce795261157e3e0e36e4",
    "id": null,
    "metadata": {},
    "name": "SessionFreeMintingInfoMutation",
    "operationKind": "mutation",
    "text": "mutation SessionFreeMintingInfoMutation {\n  session {\n    account {\n      id\n      freeMintingInfo {\n        count\n        earliestExpiry\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e39594b85730a0cae064ab80c8319c9b";

export default node;
