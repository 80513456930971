import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import MPStandardDialog, {
  MPStandardDialogProps,
} from '@mp-frontend/core-components/src/dialog/MPStandardDialog';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/dialogs/StackStateDialog.module.css';

const StackContext = createContext(undefined);

interface StackState {
  actionButtonRef: MutableRefObject<HTMLDivElement>;
  childrenRef: MutableRefObject<HTMLDivElement>;
  setNext: Dispatch<StackState>;
  title: string;
  close?: () => void;
  next?: StackState;
  onClose?: () => void;
  shouldBreak?: boolean;
}

function useStackContext(title, onClose, shouldBreak, actionButton) {
  const contextStack = useContext(StackContext);
  const [next, setNext] = useState<StackState>();
  const childrenRef = useRef<HTMLDivElement>();
  const actionButtonRef = useRef<HTMLDivElement>();
  const close = contextStack?.close;
  const stack = useMemo<StackState>(
    () => ({
      actionButtonRef,
      childrenRef,
      close: close || onClose,
      hasActionButton:
        !!actionButton &&
        (!(actionButton instanceof Array) || !!actionButton.length),
      next,
      onClose,
      setNext,
      shouldBreak,
      title,
    }),
    [next, setNext, title, onClose, shouldBreak, actionButton, close]
  );

  useEffect(() => {
    if (contextStack) contextStack.setNext(stack);
    return () => {
      if (contextStack) contextStack.setNext(undefined);
    };
  }, [contextStack, stack]);
  return {
    actionButtonRef,
    childrenRef,
    isBase: !contextStack,
    prevStack: contextStack,
    stack,
  };
}

function getLastStack(stack) {
  let temp = stack;
  while (temp.next) temp = temp.next;
  return temp;
}

function getTitle(stack, defaultTitle) {
  return getLastStack(stack).title ?? defaultTitle;
}

export interface StackStateDialogProps extends MPStandardDialogProps {
  children: ReactElement | Array<ReactElement>;
  shouldBreak?: boolean;
}

export function useStackStateDialogContext() {
  return useContext(StackContext);
}

export default function StackStateDialog({
  actionButton,
  title,
  onClose,
  children,
  shouldBreak,
  ...props
}: StackStateDialogProps) {
  const { isBase, prevStack, stack, actionButtonRef, childrenRef } =
    useStackContext(title, onClose, shouldBreak, actionButton);

  const back = useCallback(() => {
    let temp = stack;
    while (temp.next) temp = temp.next;
    temp.onClose?.();
  }, [stack]);

  const lastStack = getLastStack(stack);

  return (
    <StackContext.Provider value={stack}>
      {isBase ? (
        <MPStandardDialog
          onPrefixClick={
            stack.next && !lastStack.shouldBreak ? back : undefined
          }
          actionSx={{ display: lastStack.hasActionButton ? 'block' : 'none' }}
          title={getTitle(stack, title)}
          onClose={onClose}
          actionButton={
            <>
              <div
                className={joinClasses(styles.actionButton, {
                  hidden: !!stack.next,
                })}
              >
                {actionButton}
              </div>
              <div
                className={joinClasses(styles.actionButton, {
                  hidden: !stack.next,
                })}
                ref={actionButtonRef}
              />
            </>
          }
          {...props}
        >
          <div className={stack.next ? 'hidden' : ''}>{children}</div>
          <div className={stack.next ? '' : 'hidden'} ref={childrenRef} />
        </MPStandardDialog>
      ) : (
        <>
          {!!prevStack.actionButtonRef.current &&
            createPortal(
              <>
                <div
                  className={joinClasses(styles.actionButton, {
                    hidden: !!stack.next,
                  })}
                >
                  {actionButton}
                </div>
                <div
                  className={joinClasses(styles.actionButton, {
                    hidden: !stack.next,
                  })}
                  ref={actionButtonRef}
                />
              </>,
              prevStack.actionButtonRef.current
            )}
          {!!prevStack.childrenRef.current &&
            createPortal(
              <>
                <div className={stack.next ? 'hidden' : ''}>{children}</div>
                <div className={stack.next ? '' : 'hidden'} ref={childrenRef} />
              </>,
              prevStack.childrenRef.current
            )}
        </>
      )}
    </StackContext.Provider>
  );
}
