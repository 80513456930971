import { ReactNode, useCallback } from 'react';
import { TwitterShareButton } from 'react-share';

import { MPFonts } from '@mp-frontend/core-components';
import { TwitterIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import { TWITTER_PROFILE_USERNAME } from 'constants/ExternalUrls';
import GTM from 'GTM';

import { BaseShareButtonProps } from './BaseShareButton';

import * as styles from 'css/components/buttons/ShareButton.module.css';

const variantClassNames = {
  default: styles.defaultVariant,
  premium: styles.premiumVariant,
} as const;

const DEFAULT_HASHTAGS = ['raredigitalart', 'digitalart'];

interface TwitterButtonProps extends BaseShareButtonProps {
  tweet?: string;
}

export function TwitterButton({
  children,
  link,
  tweet,
}: Omit<TwitterButtonProps, 'variant'> & {
  children: ReactNode;
}) {
  const trackShare = useCallback(
    (method: string) => GTM.social.trackShare(link, method),
    [link]
  );

  return (
    <TwitterShareButton
      title={tweet}
      url={link}
      via={TWITTER_PROFILE_USERNAME}
      hashtags={DEFAULT_HASHTAGS}
      className={styles.container}
      onClick={() => trackShare('twitter')}
    >
      {children}
    </TwitterShareButton>
  );
}

export default function DefaultTwitterButton({
  variant = 'default',
  ...props
}: TwitterButtonProps) {
  return (
    <TwitterButton {...props}>
      <div className={joinClasses(styles.icon, variantClassNames[variant])}>
        <TwitterIcon />
      </div>

      <span className={joinClasses(MPFonts.textSmallMedium, styles.title)}>
        Twitter
      </span>
    </TwitterButton>
  );
}
