import { ComponentType } from 'react';

import DefaultErrorBoundary, {
  DefaultErrorBoundaryProps,
} from 'components/ErrorBoundaries/DefaultErrorBoundary';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface WithDefaultErrorBoundaryProps {}
export type WithDefaultErrorBoundaryConfig = Omit<
  DefaultErrorBoundaryProps,
  'children'
>;

const withDefaultErrorBoundary = <TProps extends WithDefaultErrorBoundaryProps>(
  Component: ComponentType<TProps>,
  config: WithDefaultErrorBoundaryConfig = {}
) => {
  function Wrapper({
    defaultErrorBoundaryConfig = {},
    ...props
  }: TProps & { defaultErrorBoundaryConfig?: WithDefaultErrorBoundaryConfig }) {
    return (
      <DefaultErrorBoundary {...config} {...defaultErrorBoundaryConfig}>
        <Component {...(props as TProps)} />
      </DefaultErrorBoundary>
    );
  }
  Wrapper.displayName = `withDefaultErrorBoundary(${
    Component.displayName ?? Component.name ?? 'AnonymousComponent'
  })`;

  return Wrapper;
};

export default withDefaultErrorBoundary;
