img.profileIcon {
  border: 0px;
  width: 24px;
  height: 24px;
}

.userRow {
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 1px 0;
}

.panelBody {
  padding-top: 3px;
}

@media (--mq-is-mobile) {
  .panelBody {
    padding-top: 39px;
  }

  .panelBody > div:nth-child(2) {
    margin-top: -4px;
  }
}
