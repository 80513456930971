export default function useFiltersApplied<T extends Record<string, unknown>>(
  filters: Partial<T>
): boolean {
  return Object.entries(filters).some(
    ([key, value]) =>
      key !== 'sort' &&
      !!(Array.isArray(value)
        ? value.length
        : typeof value === 'object'
        ? Object.values(value).some(Boolean)
        : value)
  );
}
