import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  MPStandardDialog,
  MPStyledTextField,
  MPTooltip,
} from '@mp-frontend/core-components';
import { InfoIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import DropSubscribeMutation, {
  DropSubscribeMutation$data,
  DropSubscribeMutation$variables,
} from 'graphql/__generated__/DropSubscribeMutation.graphql';
import { DropAbstractType } from 'types/__generated__/graphql';

import useSession from 'hooks/useSession';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { convertToDate } from 'utils/nftUtils';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

import * as styles from 'css/pages/drops/components/SubscriptionDialog.module.css';

interface SubscriptionDialogProps {
  dropId: DropAbstractType['pk'];
  dropTitle: DropAbstractType['dropTitle'];
  dropsAt: DropAbstractType['dropsAt'];
  onClose: (subscribed?: boolean) => void;
}

export default function SubscriptionDialog({
  dropId,
  dropTitle,
  dropsAt,
  onClose,
}: SubscriptionDialogProps) {
  const session = useSession();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    session.account?.phoneNumber ?? ''
  );
  const [subscribeMutation] = useMutation(DropSubscribeMutation);
  const subscribeAsync = promisifyMutationWithRequestData<
    DropSubscribeMutation$variables,
    DropSubscribeMutation$data
  >(subscribeMutation);
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  }, []);
  const handleSubmit = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault();

      if (!phoneNumber) return;

      setIsLoading(true);
      try {
        const result = await subscribeAsync({
          dropFamilyId: +dropId,
          isSubscribe: true,
          phoneNumber,
        });
        onClose(result.dropSubscribe.success);
      } catch {
        setIsLoading(false);
      }
    },
    [dropId, phoneNumber, subscribeAsync, onClose]
  );

  const hasPhoneNumber = !!session.account?.phoneNumber;
  const isPhoneNumberValid = !!(
    phoneNumber && /^[+\d][+\d\s]{9,}$/.test(phoneNumber)
  );

  return (
    <MPStandardDialog
      title="Get Notified"
      actionButton={
        <MPActionButton
          autoFocus
          disabled={!isPhoneNumberValid || isLoading}
          fullWidth
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Sign up for notifications
        </MPActionButton>
      }
      onClose={() => onClose()}
    >
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[24])}>
        <div
          className={joinClasses(
            MPFonts.textNormalMedium,
            CSSGlobal.Flex.Col,
            CSSGap[10]
          )}
        >
          <div>{dropTitle}</div>
          <div className={MPColorClass.SolidNeutralGray5}>
            {convertToDate(dropsAt, 'hh:mmA z on dddd, MMMM D YYYY')}
          </div>
        </div>

        <div className={MPFonts.paragraphNormal}>
          Subscribe to receive SMS updates when the exhibition goes live, and if
          auctions are ending.
        </div>

        <MPStyledTextField
          className={styles.input}
          disabled={hasPhoneNumber || isLoading}
          error={
            isPhoneNumberValid || phoneNumber.length < 10
              ? undefined
              : 'Invalid phone number'
          }
          label={
            <div className={styles.label}>
              Phone Number
              {!!hasPhoneNumber && (
                <MPTooltip
                  title="This number is already associated with your account. To change this, visit account settings."
                  placement="top-start"
                  classes={{ tooltip: styles.tooltip }}
                >
                  <InfoIcon
                    className={joinClasses(
                      MPColorClass.SolidNeutralGray3,
                      styles.icon
                    )}
                  />
                </MPTooltip>
              )}
            </div>
          }
          placeholder="+1 234 567 8910"
          type="tel"
          value={phoneNumber}
          onChange={handleChange}
        />
      </div>
    </MPStandardDialog>
  );
}
