import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import { TrackingContext } from 'components/trackingContext';
import { EcommerceSourceType } from 'GTM';

import ModularPage from './ModularPage';

export default (
  <Route
    path="p/:pageSlug"
    element={
      <TrackingContext source={EcommerceSourceType.ModularPage}>
        <ModularPage />
      </TrackingContext>
    }
  >
    <Route path="*" element={<Reload />} />
  </Route>
);
