import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from '../MPSVGIcon';

const HiperIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M22.2864 4H1.71413C0.781825 4 0.0249023 4.75692 0.0249023 5.68923V17.6954C0.0249023 18.6277 0.781825 19.3846 1.71413 19.3846H22.2895C23.2218 19.3846 23.9787 18.6277 23.9787 17.6954V5.68923C23.9757 4.75692 23.2187 4 22.2864 4Z"
      fill="#F37421"
    />
    <path
      d="M3.0769 8.30469V13.6893H4.29844V11.437H6.3046V13.6893H7.51998V8.30469H6.3046V10.3755H4.29844V8.30469H3.0769Z"
      fill="white"
    />
    <path
      d="M18.0369 12.1232C18.0523 12.0339 18.0769 11.8524 18.0769 11.6432C18.0769 10.677 17.5969 9.69238 16.3353 9.69238C14.9784 9.69238 14.363 10.7878 14.363 11.7785C14.363 13.0001 15.123 13.7693 16.4492 13.7693C16.9753 13.7693 17.4646 13.6893 17.8646 13.5293L17.7046 12.7078C17.3784 12.8124 17.04 12.8678 16.6246 12.8678C16.0584 12.8678 15.563 12.6278 15.523 12.1262H18.0369V12.1232ZM15.5107 11.2924C15.5415 10.9662 15.7507 10.5016 16.2615 10.5016C16.8215 10.5016 16.9477 10.997 16.9477 11.2924H15.5107Z"
      fill="white"
    />
    <path
      d="M18.4646 13.6893H19.68V11.7077C19.68 11.6124 19.6892 11.517 19.7046 11.437C19.7846 11.0616 20.0892 10.8216 20.5354 10.8216C20.6708 10.8216 20.7754 10.837 20.8615 10.8524V9.71082C20.7723 9.69543 20.7169 9.69543 20.6061 9.69543C20.2308 9.69543 19.7508 9.93543 19.5508 10.5016H19.52L19.48 9.78159H18.4338C18.4492 10.117 18.4646 10.4924 18.4646 11.0677V13.6893Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75377 8.08936C9.09838 8.08936 9.37838 8.36936 9.37838 8.71397C9.37838 9.05859 9.09838 9.33859 8.75377 9.33859C8.40915 9.33859 8.12915 9.05859 8.12915 8.71397C8.12915 8.36936 8.40915 8.08936 8.75377 8.08936Z"
      fill="#FFE700"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3199 12.7786H11.9292C12.5415 12.7786 12.8184 12.3878 12.8184 11.9786C12.8184 11.5694 12.7876 10.6463 12.0338 10.6463C11.1661 10.6463 11.3045 11.794 11.3107 12.394C11.3076 12.5232 11.3168 12.6524 11.3199 12.7786ZM8.12915 9.77858H9.37838V11.9817C9.37838 12.3909 9.60607 12.7817 10.1076 12.7817C10.1107 11.7909 10.1076 10.7694 10.0738 9.77858H11.1168C11.1384 9.96935 11.1599 10.1601 11.1784 10.3509C11.6707 9.36627 13.2245 9.58166 13.7476 10.4155C14.2861 11.274 14.4707 13.7355 11.9292 13.7355H11.3384C11.3445 14.234 11.3445 14.7355 11.3445 15.237H10.0953C10.0953 14.757 10.0984 14.2524 10.1015 13.7355C8.75069 13.7294 8.12915 12.874 8.12915 11.9817V9.77858Z"
      fill="white"
    />
  </MPSVGIcon>
));

export default HiperIcon;
