import { startTransition } from 'react';

import { MPFonts } from '@mp-frontend/core-components';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';

import * as styles from 'css/pages/product/ProductSetBuyNow.module.css';

export default function DelistLink({ setOwnershipState }) {
  const updateDelistingState = () => {
    startTransition(() => {
      setOwnershipState(ProductOwnerStates.DELIST);
    });
  };

  return (
    <div className={styles.deslistingMessage}>
      <div className={MPFonts.paragraphSmall}>
        You can delist your artwork&nbsp;
        <span
          className="anchorV2 pointer"
          role="button"
          tabIndex={0}
          onClick={updateDelistingState}
          onKeyDown={updateDelistingState}
        >
          here
        </span>
      </div>
    </div>
  );
}
