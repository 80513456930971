import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  startTransition,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { usePreloadedQuery } from 'react-relay';

import { MPFonts, MPStyledTextField } from '@mp-frontend/core-components';
import { SearchIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountsQueryType, {
  AccountsQuery,
} from 'graphql/__generated__/AccountsQuery.graphql';

import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/components/filters/ArtistsFilter.module.css';

const MINIMUM_SEARCH_TEXT_LENGTH = 2;
const WAIT_PERIOD_STOP_TYPING = 500;

interface ArtistsFilterSearchInputProps {
  accountsQuery: WithLoadQueryProps<AccountsQuery>;
  label: string;
  placeholder: string;
  setSearchResults: Dispatch<SetStateAction<string[]>>;
  userNames: string[];
  userNamesToExclude: string[];
}

function ArtistsFilterSearchInput({
  userNames,
  accountsQuery,
  userNamesToExclude,
  label,
  placeholder,
  setSearchResults,
}: ArtistsFilterSearchInputProps) {
  const { accounts } = usePreloadedQuery<AccountsQuery>(
    AccountsQueryType,
    accountsQuery.queryRef
  );
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const availableUsernames = accounts.map(({ username }) => username);
    const uniqueArtistUsernames = [
      ...new Set([...availableUsernames, ...userNamesToExclude]),
    ].sort();
    const availableForSelectionArtist = uniqueArtistUsernames.filter(
      (username) => !userNames.includes(username)
    );
    setSearchResults(availableForSelectionArtist);
  }, [accounts, userNames, userNamesToExclude, setSearchResults]);

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      startTransition(() => {
        if (
          searchText.length > MINIMUM_SEARCH_TEXT_LENGTH ||
          searchText.length === 0
        ) {
          accountsQuery.loadQuery({ searchText });
          if (searchText.length === 0) setSearchResults([]);
        }
      });
    }, WAIT_PERIOD_STOP_TYPING);

    return () => {
      clearTimeout(typingTimer);
    };
  }, [searchText, accountsQuery, setSearchResults]);

  const handleSearchTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    },
    [setSearchText]
  );

  return (
    <div className={styles.artistSearchSection}>
      <div
        className={joinClasses(
          MPFonts.textSmallMedium,
          styles.artistSearchTitle
        )}
      >
        {label}
      </div>
      <MPStyledTextField
        placeholder={placeholder}
        inputMode="text"
        onChange={handleSearchTextChange}
        startAdornment={<SearchIcon className={styles.artistSearchIcon} />}
      />
    </div>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(ArtistsFilterSearchInput, {
    accountsQuery: { concreteRequest: AccountsQueryType },
  }),
  {
    hideState: true,
  }
);
