import { SvgIcon } from '@mui/material';

export default function TimerFilledIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM11.3841 8.40032C11.3841 7.7463 10.8539 7.21611 10.1999 7.21611C9.54586 7.21611 9.01567 7.7463 9.01567 8.40032V12.1971C9.01567 12.5227 9.14975 12.834 9.38637 13.0576L12.9864 16.4609C13.4616 16.9102 14.2111 16.8891 14.6604 16.4138C15.1097 15.9386 15.0887 15.1891 14.6134 14.7398L11.3841 11.687V8.40032Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
