import { forwardRef } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const _InfoOutlinedIcon = forwardRef<any, Omit<MPSVGIconProps, 'IconClass'>>(
  (props, ref) => (
    <MPSVGIcon {...props} ref={ref} IconClass={InfoOutlinedIcon} />
  )
);

export default _InfoOutlinedIcon;
