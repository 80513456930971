import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from '../MPSVGIcon';

const EloIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M6.48544 10.3503C6.66372 10.29 6.85512 10.2585 7.05438 10.2585C7.92484 10.2585 8.65109 10.8773 8.81627 11.6979L10.0485 11.4462C9.76538 10.0514 8.53311 9 7.05438 9C6.71616 9 6.39105 9.05506 6.08691 9.15731L6.48544 10.3503Z"
      fill="#FFF100"
    />
    <path
      d="M5.03039 14.346L5.86414 13.4021C5.49184 13.0717 5.25849 12.5919 5.25849 12.0545C5.25849 11.5196 5.49184 11.0372 5.86414 10.7094L5.03039 9.76819C4.39852 10.3293 4 11.1447 4 12.0571C4 12.9669 4.39852 13.7849 5.03039 14.346Z"
      fill="#00A3DF"
    />
    <path
      d="M8.81645 12.4164C8.64865 13.237 7.92502 13.8532 7.05456 13.8532C6.8553 13.8532 6.6639 13.8217 6.483 13.7614L6.08447 14.9543C6.38861 15.0566 6.71634 15.1117 7.05456 15.1117C8.53329 15.1117 9.76556 14.0629 10.0487 12.6681L8.81645 12.4164Z"
      fill="#EE4023"
    />
    <path
      d="M14.1545 13.2606C13.95 13.4599 13.6747 13.5805 13.3706 13.5752C13.1608 13.5726 12.9668 13.5097 12.8042 13.4048L12.3952 14.055C12.6757 14.2307 13.0035 14.3356 13.3601 14.3408C13.8766 14.3487 14.3485 14.1442 14.692 13.8112L14.1545 13.2606ZM13.4152 10.6099C12.3874 10.5942 11.5379 11.4174 11.5222 12.4452C11.5169 12.8306 11.627 13.1924 11.8237 13.4913L15.2006 12.0466C15.0119 11.2365 14.2883 10.6256 13.4152 10.6099M12.2956 12.592C12.2904 12.55 12.2878 12.5055 12.2878 12.4609C12.2982 11.8553 12.7964 11.3702 13.4021 11.3807C13.7324 11.3859 14.026 11.5354 14.2227 11.7714L12.2956 12.592ZM16.2625 9.77087V13.3707L16.8865 13.6303L16.5902 14.3408L15.9715 14.0838C15.8325 14.0235 15.7381 13.9318 15.6673 13.8269C15.5992 13.722 15.5467 13.5752 15.5467 13.3812V9.77087H16.2625ZM18.5147 11.4358C18.6249 11.399 18.7402 11.3807 18.8634 11.3807C19.3957 11.3807 19.8362 11.7582 19.9384 12.259L20.6909 12.1043C20.5178 11.2522 19.7654 10.6125 18.8634 10.6125C18.6563 10.6125 18.4571 10.6466 18.2735 10.7069L18.5147 11.4358ZM17.6259 13.8715L18.1345 13.2973C17.9064 13.0954 17.7648 12.8017 17.7648 12.474C17.7648 12.1463 17.909 11.8526 18.1345 11.6534L17.6259 11.0792C17.2405 11.42 16.9966 11.9208 16.9966 12.4766C16.9966 13.0325 17.2405 13.5306 17.6259 13.8715ZM19.9384 12.6968C19.8362 13.1976 19.393 13.5752 18.8634 13.5752C18.7428 13.5752 18.6249 13.5542 18.5147 13.5175L18.2709 14.2464C18.4571 14.3093 18.6563 14.3434 18.8634 14.3434C19.7654 14.3434 20.5178 13.7037 20.6909 12.8516L19.9384 12.6968Z"
      fill="black"
    />
  </MPSVGIcon>
));

export default EloIcon;
