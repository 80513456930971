import { Hash } from 'viem';

import { CreatorContractType } from 'types/__generated__/graphql';

import useTLContract from 'hooks/contracts/useTLContract';

import PendingApprovalsManager from '../PendingApprovalsManager';
import Approval from './Approval';

interface TLAdminApprovalProps {
  contract: CreatorContractType;
  walletAddress: Hash;
  className?: string;
  contractTitle?: string;
}

export default function TLAdminApproval({
  contract,
  walletAddress,
  className,
  contractTitle = '',
}: TLAdminApprovalProps) {
  const { useSetAdmin } = useTLContract({
    abi: JSON.parse(contract.abidata).abi,
    account: walletAddress,
    contractAddress: contract.address as Hash,
  });

  const approveAdminManager = useSetAdmin({
    adminAddress: contract.manifoldAdminAddress as Hash,
    isAdmin: true,
  });

  const revokeAdminManager = useSetAdmin({
    adminAddress: contract.manifoldAdminAddress as Hash,
    isAdmin: false,
  });

  const fetchParams = PendingApprovalsManager.useReadTLAdminApproval(
    contract,
    walletAddress
  );

  return (
    <Approval
      className={className}
      title={`Admin Access${contractTitle ? ` - ${contractTitle}` : ''}`}
      explanation="You will grant MakersPlace admin access to your personal contract in order to mint artworks during your exhibition."
      fetchParams={fetchParams}
      manager={fetchParams[0] ? revokeAdminManager : approveAdminManager}
      walletAddress={walletAddress}
    />
  );
}
