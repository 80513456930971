import { useCallback } from 'react';
import { useMutation } from 'react-relay';
import { Hash } from 'viem';
import { useAccount } from 'wagmi';

import NFTAcceptEthBidPersonalWalletMutation, {
  NFTAcceptProductBidWithPersonalWalletMutation$data,
  NFTAcceptProductBidWithPersonalWalletMutation$variables,
} from 'graphql/__generated__/NFTAcceptProductBidWithPersonalWalletMutation.graphql';

import useProductAcceptBidEth from 'hooks/contracts/DigitalMediaSaleCore/useAcceptBid';
import useLogging from 'hooks/useLogging';
import usePublicClient from 'hooks/useWeb3Client';
import { NFTType } from 'types/graphql/NFT';
import { promisifyMutationWithRequestData } from 'utils/promisifyMutation';

function useAcceptPersonalWalletEthBid(nft: NFTType): {
  acceptPersonalWalletEthBid: () => Promise<Hash>;
  error: Error | null;
} {
  const { logNFTException } = useLogging();
  const { address: currentUserAddress } = useAccount();
  const provider = usePublicClient();
  const {
    writeAsync,
    simulationError,
    reset: resetAcceptBid,
  } = useProductAcceptBidEth(nft);
  const currentBid = nft.listing.liveBid;

  const acceptEthBidPersonalWalletMutationAsync =
    promisifyMutationWithRequestData<
      NFTAcceptProductBidWithPersonalWalletMutation$variables,
      NFTAcceptProductBidWithPersonalWalletMutation$data
    >(useMutation(NFTAcceptEthBidPersonalWalletMutation)[0]);

  const acceptPersonalWalletEthBid = useCallback(async (): Promise<Hash> => {
    if (simulationError) throw simulationError;
    try {
      const transactionResult = await writeAsync().catch((err) => {
        throw err;
      });
      const nonce =
        (await provider.getTransactionCount(currentUserAddress)) + 1;
      await acceptEthBidPersonalWalletMutationAsync({
        bidId: parseInt(currentBid.pk, 10),
        cancelledByCustomer: false,
        fromAddress: currentUserAddress,
        nonce,
        transactionId: transactionResult,
      }).catch((err) => {
        throw err;
      });
      return transactionResult;
    } catch (error) {
      logNFTException(nft.pk, error);
      resetAcceptBid();
      throw error;
    }
  }, [
    writeAsync,
    resetAcceptBid,
    simulationError,
    provider,
    acceptEthBidPersonalWalletMutationAsync,
    logNFTException,
    nft.pk,
    currentUserAddress,
    currentBid.pk,
  ]);

  return {
    acceptPersonalWalletEthBid,
    // TODO: (WAGMI) determine if we should return both errors
    error: simulationError,
  };
}

export default useAcceptPersonalWalletEthBid;
