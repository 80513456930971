/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
function reusable(strings, ...ignored) {
  return function reuseTemplate(...values) {
    return strings.map((s, i) => `${s}${values[i] || ''}`).join('');
  };
}

export enum MPErrorName {
  BELOW_MINIMUM_BID = 'BELOW_MINIMUM_BID',
  MUST_INCREASE_OFFER_OVER_PREVIOUS = 'MUST_INCREASE_OFFER_OVER_PREVIOUS',
}

/* eslint-disable-next-line @typescript-eslint/no-unsafe-function-type */
const MPErrorNameToMessageMap: Record<MPErrorName, Function | string> = {
  [MPErrorName.BELOW_MINIMUM_BID]: reusable`Your Bid/Offer must be at least ${0}`,
  [MPErrorName.MUST_INCREASE_OFFER_OVER_PREVIOUS]:
    'Your new offer must be higher than your last offer', // ranked auction
};

export default class MPError extends Error {
  name: string;

  additionalData: Record<string, any>;

  constructor(errorName: MPErrorName, additionalData: Array<string> = []) {
    super(
      typeof MPErrorNameToMessageMap[errorName] === 'function'
        ? /* eslint-disable-next-line @typescript-eslint/no-unsafe-function-type */
          (MPErrorNameToMessageMap[errorName] as Function).call(
            null,
            ...additionalData
          )
        : MPErrorNameToMessageMap[errorName]
    );
    this.name = errorName;
  }
}
