import { useCallback, useEffect, useMemo, useState } from 'react';
import { FullScreen } from 'react-full-screen';
import ReactPlayer from 'react-player/lazy';

import { useIsMobile } from '@mp-frontend/core-components';
import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import DropVideoPlayerMode from './mode';
import DropVideoPlayerControls, {
  DropVideoPlayerStatus,
} from './PlayerControls';
import useFullScreenHandle from './useFullScreenHandle';
import usePlayerTimeState from './useTimeState';

import * as styles from 'css/pages/drops/components/VideoPlayer/index.module.css';

export { default as DropVideoPlayerMode } from './mode';

export interface DropVideoPlayerProps {
  source: string;
  autoPlay?: boolean;
  cover?: boolean;
  loop?: boolean;
  mode?: DropVideoPlayerMode;
  onControlsClick?: (action: DropVideoPlayerStatus) => void;
  title?: string;
}

const playerModeClassMap = {
  [DropVideoPlayerMode.COMPACT]: '',
  [DropVideoPlayerMode.STANDARD]: styles.standardMode,
  [DropVideoPlayerMode.LARGE]: styles.largeMode,
  [DropVideoPlayerMode.HERO]: styles.largeMode,
};

export default function DropVideoPlayer({
  source,
  title = '',
  autoPlay = false,
  cover = false,
  loop = false,
  mode = DropVideoPlayerMode.STANDARD,
  onControlsClick,
}: DropVideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [isMuted, setIsMuted] = useState(autoPlay);
  const playerTimeState = usePlayerTimeState();
  const [player, setPlayerRef] = useRefState<ReactPlayer>(null);
  const screenHandle = useFullScreenHandle();
  const isMobile = useIsMobile();
  const displayMode =
    screenHandle.active || (mode === DropVideoPlayerMode.HERO && !isMobile)
      ? DropVideoPlayerMode.LARGE
      : mode;

  const handleClick = useCallback(() => {
    // force full screen
    if (isMobile && mode === DropVideoPlayerMode.HERO) {
      if (!screenHandle.active) {
        screenHandle.enter();
        setIsPlaying(true);
        setIsMuted(false);
      }
      screenHandle.node.current?.blur();
    }
  }, [screenHandle, setIsPlaying, isMobile, mode]);

  const handleOverlayClick = useMemo(
    () => (isMobile ? () => screenHandle.node.current?.blur() : () => {}),
    [screenHandle, isMobile]
  );

  useEffect(() => {
    if (!screenHandle.node) return;
    screenHandle.node.current.setAttribute('tabIndex', '-1');
  }, [screenHandle.node]);

  return (
    <FullScreen
      className={joinClasses(
        styles.container,
        playerModeClassMap[displayMode],
        cover && !screenHandle.active && styles.cover
      )}
      handle={screenHandle}
    >
      <ReactPlayer
        playsinline
        ref={setPlayerRef}
        className={styles.player}
        url={source}
        playing={isPlaying}
        onProgress={({ playedSeconds }: { playedSeconds: number }) =>
          playerTimeState.setPlayedSeconds(playedSeconds)
        }
        onDuration={(duration: number) => playerTimeState.setDuration(duration)}
        volume={1}
        muted={isMuted}
        onPause={() => setIsPlaying(false)}
        onStart={() => setIsPlaying(true)}
        onPlay={() => setIsPlaying(true)}
        onClick={handleClick}
        loop={loop}
        width="100%"
        height={cover ? 'auto' : '100%'}
        progressInterval={100}
      />
      <DropVideoPlayerControls
        playerTimeState={playerTimeState}
        isMuted={isMuted}
        setIsMuted={setIsMuted}
        mode={displayMode}
        player={player}
        title={title}
        screenHandle={screenHandle}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        onControlClick={onControlsClick}
        onOverlayClick={handleOverlayClick}
      />
    </FullScreen>
  );
}
