import { useCallback, useState } from 'react';
import { CardElement, CardElementProps } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

import { MPColorValue, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import * as styles from 'css/components/CardElement.module.css';

enum CardState {
  COMPLETE = 'complete',
  EMPTY = 'empty',
  ERROR = 'error',
}

export default function MPCardElement({
  onChange,
  ...props
}: Omit<CardElementProps, 'options'>) {
  const [cardElementState, setCardElementState] = useState<
    keyof Pick<typeof styles, `${CardState}`>
  >(CardState.EMPTY);

  const handleCardChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error?.message) {
        setCardElementState(CardState.ERROR);
      } else if (event.empty) {
        setCardElementState(CardState.EMPTY);
      } else if (event.complete) {
        setCardElementState(CardState.COMPLETE);
      }
      if (onChange) onChange(event);
    },
    [onChange]
  );

  return (
    <div
      className={joinClasses(
        styles.paymentFormCreditCardElementWrapper,
        styles[cardElementState]
      )}
    >
      <CardElement
        options={{
          classes: {
            base: MPFonts.inputText,
          },
          hideIcon: true,
          style: {
            base: {
              '::placeholder': {
                color: MPColorValue.SolidNeutralGray3 as string,
              },
              color: MPColorValue.CommonBlack as string,
              textTransform: 'capitalize',
            },
            invalid: {
              ':focus': {
                color: MPColorValue.ErrorMain as string,
              },
              ':hover': {
                color: MPColorValue.ErrorMain as string,
              },
              color: MPColorValue.ErrorLight as string,
            },
          },
        }}
        onChange={handleCardChange}
        {...props}
      />
    </div>
  );
}
