.filters {
  @media (--mq-is-mobile) {
    padding: 0 0 0 var(--explore-gap)
  }
}

.grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;

  @media (--mq-is-mobile) {
    grid-template-columns: repeat(auto-fill, 100%);
    padding: 0 var(--explore-gap)
  }
}
