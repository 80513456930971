import { atom, useAtom } from 'jotai';
import { createConfig, fallback, http, unstable_connector } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { coinbaseWallet, injected, walletConnect } from '@wagmi/connectors';

import { APP_LOGO_URL, APP_NAME } from 'constants/Utils';
import useSession from 'hooks/useSession';

const WALLET_CONNECT_PROJECT_ID = 'fa0afa8050fedfa5a5d62c89a823434e';

// eslint-disable-next-line @cspell/spellchecker
export const TESTNET_ALCHEMY_KEY = 'k0X4UDtqvlnkb9Y_9nnTI8POxXNSh8Co';
export const MAINNET_ALCHEMY_KEY = 'gYooSU0nqS5jQc7AWtk94DthD-vdIz8r';

export const wagmiAtom = atom<ReturnType<typeof createConfig> | any>(false);

// TODO read up on EIP-6963
// TODO figure out websockets (do we even use it?) on alchemy
const useWagmiClient = () => {
  const session = useSession();
  const [wagmiConfig, setWagmiConfig] = useAtom(wagmiAtom);

  const selectedChain =
    session.contractNetwork === sepolia.id ? sepolia : mainnet;

  if (!wagmiConfig) {
    const connectors: Parameters<typeof createConfig>[0]['connectors'] = [
      walletConnect({
        metadata: {
          description: '',
          icons: [APP_LOGO_URL],
          name: APP_NAME,
          url: `${window.location.host}${window.location.pathname}`,
        },
        projectId: WALLET_CONNECT_PROJECT_ID,
        qrModalOptions: {
          themeVariables: {
            '--wcm-z-index': '99999',
          },
        },
      }),
    ];

    if (window.ethereum?.isCoinbaseBrowser)
      connectors.push(
        coinbaseWallet({
          appLogoUrl: APP_LOGO_URL,
          appName: APP_NAME,
          preference: 'eoaOnly',
        })
      );

    const config = createConfig({
      chains: [selectedChain],
      connectors,
      transports: {
        [mainnet.id]: fallback([
          unstable_connector(injected),
          http(`https://eth-mainnet.g.alchemy.com/v2/${MAINNET_ALCHEMY_KEY}`),
        ]),
        [sepolia.id]: fallback([
          unstable_connector(injected),
          http(`https://eth-sepolia.g.alchemy.com/v2/${TESTNET_ALCHEMY_KEY}`),
        ]),
      },
    });
    setWagmiConfig(config);
    return config;
  }
  return wagmiConfig;
};

export default useWagmiClient;
