import { atom, selector } from 'recoil';
import { getCurrencyDisplaySymbol } from 'utils';

import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';

const SelectedCurrencyDisplayMode = atom<CurrencyDisplayMode>({
  // localStorage key is still used in the bitbucket repo
  default:
    ((Object.values(CurrencyDisplayMode) as Array<string>).includes(
      localStorage.getItem('currencyDisplayMode')
    ) &&
      (localStorage.getItem('currencyDisplayMode') as CurrencyDisplayMode)) ||
    CurrencyDisplayMode.USD,

  key: 'SelectedCurrencyDisplayMode',
});

export const SelectedCurrencyDisplaySymbol = selector({
  get: ({ get }) => getCurrencyDisplaySymbol(get(SelectedCurrencyDisplayMode)),
  key: 'CurrencyDisplayModeSymbol',
});

export default SelectedCurrencyDisplayMode;
