import { SvgIcon } from '@mui/material';

export default function ShareIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.47 14.99C18.5777 14.99 17.7541 15.253 17.0562 15.6989L10.7302 12.2115L17.4337 8.51834C18.0515 8.8385 18.7378 9.03287 19.4814 9.03287C21.9752 9.03287 24 7.00905 24 4.51644C24 2.02382 21.9752 0 19.4814 0C16.9876 0 14.9628 2.02382 14.9628 4.51644C14.9628 4.76798 14.9971 5.01953 15.0429 5.25965L7.87035 9.20438C7.04671 8.27823 5.85701 7.68366 4.51859 7.68366C2.02479 7.68366 0 9.70748 0 12.2001C0 14.6927 2.02479 16.7165 4.51859 16.7165C5.85701 16.7165 7.04671 16.122 7.87035 15.1958L14.9971 19.1177C14.9971 19.2434 14.9628 19.3578 14.9628 19.4836C14.9628 21.9762 16.9876 24 19.4814 24C21.9752 24 24 21.9762 24 19.4836C24 16.9909 21.9752 14.9671 19.4814 14.9671L19.47 14.99Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
