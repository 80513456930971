import { SvgIcon } from '@mui/material';

export default function BehanceIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.12545 8.66714H9.03985C9.03985 8.66714 9.74824 8.66714 9.74824 9.70896C9.74824 10.7508 9.33174 10.9018 8.8591 10.9018H6.12545V8.66714Z"
        fill="currentColor"
      />
      <path
        d="M8.90569 15.1207H6.12545V12.4449H9.03985C9.03985 12.4449 10.0957 12.4308 10.0957 13.8201C10.0957 14.9781 9.32388 15.1095 8.90569 15.1207Z"
        fill="currentColor"
      />
      <path
        d="M18.0283 12.4449H14.7395C14.7395 12.4449 14.9551 10.9012 16.5015 10.9012C18.048 10.9012 18.0283 12.4449 18.0283 12.4449Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8765 3H5.12349C3.95145 3 3 3.95145 3 5.12349V18.8765C3 20.0486 3.95145 21 5.12349 21H18.8765C20.0486 21 21 20.0486 21 18.8765V5.12349C21 3.95145 20.0486 3 18.8765 3ZM10.3455 11.4586C10.3455 11.4586 11.8734 11.3452 11.8734 9.55348C11.8734 7.76173 10.6233 6.88718 9.03985 6.88718H3.82963V16.9007H9.03985C9.03985 16.9007 12.2203 17.0011 12.2203 13.9453C12.2203 13.9453 12.359 11.4592 10.3455 11.4592V11.4586ZM16.4628 9.43504C12.6121 9.43504 12.616 13.2824 12.616 13.2824C12.616 13.2824 12.3517 17.11 16.4634 17.11C16.4634 17.11 19.8897 17.3059 19.8897 14.4471H18.1277C18.1277 14.4471 18.1866 15.5237 16.5223 15.5237C16.5223 15.5237 14.7603 15.6416 14.7603 13.7814H19.9486C19.9486 13.7814 20.5161 9.43504 16.4634 9.43504H16.4628ZM18.4392 7.47715H14.3084V8.71038H18.4392V7.47715Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
