import { ReactNode } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/about/AboutSection.module.css';

interface AboutSectionProps {
  children: ReactNode;
  description: string;
  id: string;
  title: string;
}

function AboutSection({ id, title, description, children }: AboutSectionProps) {
  return (
    <section
      id={id}
      className={joinClasses(
        CSSGlobal.Flex.Col,
        CSSGap[32],
        CSSGlobal.Cursor.Default
      )}
    >
      <div
        className={joinClasses(CSSGap[8], styles.header, CSSGlobal.Flex.Col)}
      >
        <div className={MPFonts.headline4}>{title}</div>
        <div
          className={joinClasses(MPFonts.textSmallRegular, styles.description)}
        >
          {description}
        </div>
      </div>
      {children}
    </section>
  );
}

export default AboutSection;
