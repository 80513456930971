import { joinClasses } from '@mp-frontend/core-utils';

import DropVideoPlayer, {
  DropVideoPlayerMode,
} from 'pages/drops/components/VideoPlayer';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/modularPage/sections/VideoBlockSection.module.css';

interface VideoBlockSectionProps {
  externalUrl: string;
  videoUrl: string;
  className?: string;
}

function VideoBlockSection({
  videoUrl,
  externalUrl,
  className,
}: VideoBlockSectionProps) {
  return (
    <section
      className={joinClasses(
        CSSGlobal.Flex.Col,
        CSSGap[16],
        styles.container,
        className
      )}
    >
      {videoUrl ? (
        <DropVideoPlayer
          source={videoUrl}
          cover
          mode={DropVideoPlayerMode.HERO}
        />
      ) : (
        <iframe
          title="Video Player"
          src={externalUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </section>
  );
}

export default VideoBlockSection;
