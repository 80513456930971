import { Route } from 'react-router-dom';

import Reload from 'components/Reload';
import SlashRedirect from 'pages/SlashRedirect';

const RegisterRoute = (
  <Route path="register" element={<SlashRedirect />}>
    <Route path="*" element={<Reload />} />
  </Route>
);

export default RegisterRoute;
