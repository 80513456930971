import { startTransition, useEffect, useState } from 'react';

import {
  MPActionButton,
  MPColorClass,
  MPFonts,
  useIsMobile,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { MpErrors } from 'types/__generated__/graphql';

import ErrorDisplay from 'components/Error';
import NewWalletConnectDialog from 'components/wallet/NewWalletConnectDialog';
import useWallets from 'hooks/session/useWallets';
import { useRefreshSession } from 'hooks/useSession';
import useRegister from 'hooks/wallet/mutations/useRegister';
import useCachedAccount from 'hooks/wallet/useCachedAccount';
import useCachedCurrentBalance from 'hooks/wallet/useCachedCurrentBalance';
import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import generatePriceString from 'utils/currency/generatePricing';
import isWalletError from 'utils/errors/wallet';
import getAddressDisplay from 'utils/getAddressDisplay';

import LinkSection from './LinkSection';

import * as panelStyles from 'css/components/Navbar/Unified/panel.module.css';
import * as styles from 'css/components/Navbar/Unified/PanelProfileWallet.module.css';

export default function PanelProfileWallet() {
  const account = useCachedAccount();
  const { eth, usd, isReady } = useCachedCurrentBalance();
  const isMobile = useIsMobile();
  const refreshSession = useRefreshSession();

  const [isConnectWalletDialogOpen, setIsConnectWalletDialogOpen] =
    useState(false);

  const [wallets] = useWallets();
  const [registerWallet] = useRegister();

  const [error, setError] = useState(undefined);

  const displayableError = !isWalletError.userRejected(error)
    ? error?.name === MpErrors.WalletAssociatedWithAnotherAccount
      ? new Error(
          `It looks like you've already registered this wallet with a different account. You can login into that account with this wallet directly. If you'd rather use this account, please dissociate this wallet from the account settings page of that account.`
        )
      : error
    : undefined;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (error) {
      const id = setTimeout(
        () => setError((prev) => (prev === error ? undefined : prev)),
        5000
      );
      return () => clearTimeout(id);
    }
  }, [error]);

  // Edge case where our API returns back before the database has finalized.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (error?.name === MpErrors.WalletAlreadyConnected) {
      startTransition(refreshSession);
    }
  }, [error, refreshSession]);

  return (
    <>
      {account.isConnected ? (
        <>
          <LinkSection
            title={`ETH Balance (${getAddressDisplay(account.address)})`}
          >
            <div className={panelStyles.panelPriceWrapper}>
              <div className={MPFonts.textLargeRegular}>
                {generatePriceString(parseFloat(eth), CurrencyDisplayMode.ETH, {
                  isMobile,
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4,
                })}
              </div>
              <div
                className={joinClasses(
                  MPFonts.textSmallMedium,
                  MPColorClass.SolidNeutralGray4,
                  panelStyles.panelPriceEth
                )}
              >
                {!!isReady &&
                  generatePriceString(
                    parseFloat(usd),
                    CurrencyDisplayMode.USD,
                    {
                      isMobile,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }
                  )}
              </div>
            </div>
            {displayableError ? (
              <ErrorDisplay error={displayableError} />
            ) : wallets.find(
                (wallet) =>
                  wallet.address.toLowerCase() ===
                  account.address?.toLowerCase()
              ) ? (
              <></>
            ) : (
              <MPActionButton
                variant="secondary"
                size="small"
                onClick={() =>
                  registerWallet(account.address, account.connector)
                    .then(() => setError(undefined))
                    .catch((e) => setError(e))
                }
              >
                Verify
              </MPActionButton>
            )}
          </LinkSection>
        </>
      ) : (
        <>
          <MPActionButton
            variant="secondary"
            className={styles.mobileConnectWalletButton}
            size="small"
            onClick={() => setIsConnectWalletDialogOpen(true)}
          >
            Connect Wallet
          </MPActionButton>
        </>
      )}
      {!!isConnectWalletDialogOpen && (
        <NewWalletConnectDialog
          title="Connect Wallet"
          description="Choose a wallet to connect. You will need to connect and verify this wallet in order to continue."
          isCreationEnabled={false}
          onError={setError}
          open={isConnectWalletDialogOpen}
          onClose={() => setIsConnectWalletDialogOpen(false)}
        />
      )}
    </>
  );
}
