.container {
  background-image: var(--pdp-rail-exhibitionBackgroundImage);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  position: relative;
}

.content {
  align-items: flex-start;
  background-image: var(--pdp-rail-exhibitionGradient);
  box-sizing: border-box;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  padding: 16px;
  position: absolute;
  top: 0;
  width: 100%;

  & > .label {
    opacity: .5;
  }
}
