import { ReactNode, useEffect, useRef, useState } from 'react';
import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider as BaseStatsigProvider } from '@statsig/react-bindings';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';

import useSession from 'hooks/useSession';

import { SiteLoader } from './SiteLoadingSuspense';

type StatsigUser = {
  email?: string;
  userID?: string;
};

export const convertAccountToStatsigUser = (
  account: Partial<{
    email: string;
    pk: string;
  }> = {}
): StatsigUser => ({
  email: account?.email,
  userID: account?.pk,
});

export default function StatsigProvider({ children }: { children: ReactNode }) {
  const session = useSession();
  const [client, setClient] = useState<StatsigClient>(null);
  const isInitializing = useRef<boolean>(false);

  useEffect(() => {
    if (!session.statsigClientKey || isInitializing.current || client) return;

    (async () => {
      isInitializing.current = true;

      const statsigClient = new StatsigClient(
        session.statsigClientKey,
        convertAccountToStatsigUser(session.account),
        {
          environment: {
            tier: session.environment as
              | 'production'
              | 'staging'
              | 'development',
          },
        }
      );

      runStatsigSessionReplay(statsigClient);
      runStatsigAutoCapture(statsigClient);
      await statsigClient.initializeAsync();
      setClient(statsigClient);

      isInitializing.current = false;
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.statsigClientKey, client]);

  return client ? (
    <BaseStatsigProvider client={client}>{children}</BaseStatsigProvider>
  ) : (
    <SiteLoader />
  );
}
