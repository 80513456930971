import { SyntheticEvent, useState } from 'react';
import { usePreloadedQuery } from 'react-relay';

import {
  MPExpandable,
  MPTabsSize,
  MPUpdatableExpandable,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import NFTsTagAndProvenanceQueryType, {
  NFTsTagAndProvenanceQuery,
} from 'graphql/__generated__/NFTsTagAndProvenanceQuery.graphql';

import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import Tabs from 'components/tabs/Tabs';
import ProductCollapsibleContainer from 'pages/product/ProductCollapsibleContainer';
import { NFTType } from 'types/graphql/NFT';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import ProductMultiEditionProvenance from './MultiEditionProvenance';
import ThisEditionProvenance from './ThisEditionProvenance';

import * as styles from 'css/pages/product/ProductProvenance.module.css';

enum EditionType {
  Multiple = 'Multiple',
  Single = 'Single',
}

interface ProductProvenanceProps {
  nft: NFTType;
  tagAndProvenanceQuery: WithLoadQueryProps<NFTsTagAndProvenanceQuery>;
}

function ProductProvenance({
  nft,
  tagAndProvenanceQuery: { queryRef },
}: ProductProvenanceProps) {
  const { nfts } = usePreloadedQuery<NFTsTagAndProvenanceQuery>(
    NFTsTagAndProvenanceQueryType,
    queryRef
  );
  const provenanceNFT = nfts.edges[0].node;

  const TABS = [
    { label: 'This Edition', to: '#', value: EditionType.Single },
    { label: 'Edition Activity', to: '#', value: EditionType.Multiple },
  ];
  const [editionMode, setEditionMode] = useState<EditionType>(
    EditionType.Single
  );

  return (
    <>
      <ProductCollapsibleContainer
        title="Provenance"
        collapsed={false}
        collapsible={false}
        showIcon={false}
      >
        <section className={styles.provenanceSection}>
          {provenanceNFT.metadata.totalSupply > 1 && (
            <div className={styles.provenanceTabs}>
              <Tabs
                currentTab={editionMode}
                size={MPTabsSize.Medium}
                tabs={TABS}
                onChange={(event: SyntheticEvent, value: EditionType) =>
                  setEditionMode(value)
                }
              />
            </div>
          )}
          <div
            className={joinClasses(styles.provenanceTab, {
              [styles.active]: editionMode === EditionType.Single,
            })}
          >
            <MPExpandable maxHeight={300}>
              <ThisEditionProvenance nft={provenanceNFT} />
            </MPExpandable>
          </div>
          <div
            className={joinClasses(styles.provenanceTab, {
              [styles.active]: editionMode === EditionType.Multiple,
            })}
          >
            <DefaultErrorBoundary>
              <MPUpdatableExpandable maxHeight={333}>
                {({ rerender }) => (
                  <ProductMultiEditionProvenance
                    nft={nft}
                    onRefresh={rerender}
                  />
                )}
              </MPUpdatableExpandable>
            </DefaultErrorBoundary>
          </div>
        </section>
      </ProductCollapsibleContainer>
    </>
  );
}

export default withDefaultErrorBoundary(
  withLoadQuery(ProductProvenance, {
    tagAndProvenanceQuery: { concreteRequest: NFTsTagAndProvenanceQueryType },
  }),
  { hideState: true }
);
