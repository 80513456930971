import { NFTRankedAuctionType } from 'types/graphql/NFT';

import { nftHasRankedAuction } from './nftUtils';

export default function generateTitleText(nft: {
  contract: { isExternal: boolean };
  metadata: { title: string };
  listing?: { rankedAuction: NFTRankedAuctionType };
}) {
  if (nftHasRankedAuction(nft) || nft.contract.isExternal) {
    return nft.metadata.title.replace(/(.*)( #\d+\/\d+)$/, '$1');
  }

  return nft.metadata.title;
}
