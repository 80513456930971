import { useCallback } from 'react';

import { MPDropdownSelect } from '@mp-frontend/core-components';

import { ActivityActionEnum } from 'types/__generated__/graphql';

import useActivityGTM from 'utils/GTM/activity';

const TITLE = 'Transaction Types';

export const ACTIONS = [
  ActivityActionEnum.Sale,
  ActivityActionEnum.Offer,
  ActivityActionEnum.Bid,
  ActivityActionEnum.Mint,
] as const;

export const ACTION_TITLES = {
  [ActivityActionEnum.Mint]: 'Mints',
  [ActivityActionEnum.Offer]: 'Offers',
  [ActivityActionEnum.Bid]: 'Bids',
  [ActivityActionEnum.Sale]: 'Sales',
  [ActivityActionEnum.Transfer]: 'Transfers',
  [ActivityActionEnum.Mint.toLowerCase()]: 'Mint',
  [ActivityActionEnum.Offer.toLowerCase()]: 'Offer',
  [ActivityActionEnum.Bid.toLowerCase()]: 'Bid',
  [ActivityActionEnum.Sale.toLowerCase()]: 'Sale',
  [ActivityActionEnum.Transfer.toLowerCase()]: 'Transfer',
} as const;

const ACTION_CONFIG = Object.fromEntries(
  ACTIONS.map((action) => [action, ACTION_TITLES[action]])
);

interface ActionsFilterProps {
  onChange: (values: ActivityActionEnum[]) => void;
  values: ActivityActionEnum[];
}

export default function ActionsFilter({
  values,
  onChange,
}: ActionsFilterProps) {
  const track = useActivityGTM();

  const handleToggle = useCallback(
    (open: boolean) => track.toggleFilter(TITLE, open),
    [track]
  );

  return (
    <MPDropdownSelect
      title={TITLE}
      config={ACTION_CONFIG}
      values={values}
      onChange={onChange}
      onToggle={handleToggle}
      placement="bottom-end"
    />
  );
}
