import { useCallback, useEffect, useRef, useState } from 'react';

import { MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { DefaultLoader } from 'components/DefaultSuspense';
import GTM from 'GTM';
import CSSGlobal from 'types/enums/css/Global';
import { NFTType } from 'types/graphql/NFT';

import * as styles from 'css/pages/product/ProductRelatedSlide.module.css';

interface ProductRelatedSlideProps {
  nft: NFTType;
  position: number;
}

function ProductRelatedSlide({ nft, position }: ProductRelatedSlideProps) {
  const {
    pk: nft_id,
    metadata,
    listing: { fullNftUrl },
  } = nft;
  const videoRef = useRef<HTMLVideoElement>(undefined);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const handleLoaded = useCallback(() => setHasLoaded(true), []);

  const onSlideClick = useCallback(
    () => GTM.pdp.trackRelatedCardClick(position, nft_id),
    [nft_id, position]
  );

  useEffect(() => {
    if (!videoRef.current?.readyState) return;

    setHasLoaded(true);
  }, [videoRef.current?.readyState, setHasLoaded]);

  return (
    <div
      className={joinClasses(styles.productRelatedSlide, CSSGlobal.Flex.Col)}
    >
      <div className={styles.container}>
        <a
          href={fullNftUrl}
          className={joinClasses(styles.currentMedia, {
            [styles.isLoading]: !hasLoaded,
          })}
          onClick={onSlideClick}
        >
          <DefaultLoader className={styles.loader} position="absolute" />
          {metadata.hasVideo ? (
            <video
              ref={(node) => {
                videoRef.current = node;
              }}
              autoPlay
              playsInline
              loop
              muted
              controls={false}
              onLoadedMetadata={handleLoaded}
              onLoadedData={handleLoaded}
            >
              <source src={metadata.videoUrl} type="video/mp4" />
            </video>
          ) : (
            <img
              aria-hidden="true"
              onLoad={handleLoaded}
              width={metadata.mediaMetadata?.width}
              src={metadata.standardImage}
            />
          )}
          <div
            className={joinClasses(
              MPFonts.headline3,
              styles.productRelatedTitle
            )}
          >
            {nft.metadata.title}
          </div>
          <div
            className={joinClasses(
              MPFonts.textNormalMedium,
              styles.productRelatedAuthorName
            )}
          >
            by {nft.metadata.author.fullName}
          </div>
        </a>
      </div>
    </div>
  );
}

export default ProductRelatedSlide;
