import MPSVGIcon from './MPSVGIcon';

export default function RemoveIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <rect
        x="19.7705"
        y="13.6499"
        width="15.54"
        height="3.3"
        transform="rotate(-180 19.7705 13.6499)"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
