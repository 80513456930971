import { forwardRef } from 'react';
import { merge } from 'lodash';
import { Popover, popoverClasses, PopoverProps } from '@mui/material';

const defaultSx = {
  [`& .${popoverClasses.paper}`]: {
    borderRadius: 0,
    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.16)',
    pointerEvents: 'auto',
  },
  pointerEvents: 'none',
};

export type MPPopoverProps = PopoverProps;

const MPPopover = forwardRef<any, MPPopoverProps>(
  ({ sx, children, ...otherProps }: MPPopoverProps, ref) => {
    const mergedSx = merge({}, defaultSx, sx);
    return (
      <Popover {...otherProps} ref={ref} sx={mergedSx}>
        {children}
      </Popover>
    );
  }
);

export default MPPopover;
