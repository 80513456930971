import {
  MPDialog,
  MPDialogProps,
  useIsMobile,
} from '@mp-frontend/core-components';

interface PaymentViewDialogProps {
  children: MPDialogProps['children'];
  onClose: MPDialogProps['onClose'];
  title: string;
}

export default function PaymentViewDialog({
  title,
  children,
  onClose,
}: PaymentViewDialogProps) {
  const isMobile = useIsMobile();

  return (
    <MPDialog
      maxWidth="desktop"
      title={title}
      open
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: isMobile ? '100vh' : '95vh',
          width: isMobile ? '100vw' : 'var(--mp-defaultDialogWidth)',
        },
      }}
    >
      {children}
    </MPDialog>
  );
}
