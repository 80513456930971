import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const CarouselRightArrow = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M17.7167 12L9.78764 19.7954H6.28345L14.2125 12L6.28345 4.20459H9.78764L17.7167 12Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default CarouselRightArrow;
