import { Component } from 'react';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { SUPPORT_EMAIL } from 'constants/ExternalUrls';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/ErrorBoundaries/SiteDownErrorBoundary.module.css';

function SiteDown() {
  return (
    <div
      className={joinClasses(styles.container, CSSGlobal.Flex.ColCenterAlign)}
    >
      <h1 className={MPFonts.headline3}>Something went wrong</h1>
      <div>
        Apologies for the inconvenience. It seems like something unexpected
        happened. If you need assistance, send us an email.
      </div>
      <a href={`mailto:${SUPPORT_EMAIL}`}>
        <MPActionButton className={styles.actionButton}>
          Email Support
        </MPActionButton>
      </a>
    </div>
  );
}

class SiteDownErrorBoundary extends Component<
  { children: any },
  { error: any }
> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <SiteDown />;
    }

    return this.props.children;
  }
}

export default SiteDownErrorBoundary;
