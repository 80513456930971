import { UseMPMutationConfig } from 'react-relay';
import { MutationParameters } from 'relay-runtime';

export function promisifyMutation<VariablesType, ResponseType>(
  mutation: (config: UseMPMutationConfig<MutationParameters>) => void,
  overrides?: Partial<
    Omit<
      UseMPMutationConfig<MutationParameters>,
      'onCompleted' | 'onError' | 'variables'
    >
  >
): (variables: VariablesType) => Promise<ResponseType> {
  const innerFunction = (variables: VariablesType) =>
    new Promise<ResponseType>((onCompleted, onError) => {
      mutation({
        onCompleted,
        onError,
        variables,
        ...overrides,
      });
    });

  return innerFunction;
}

/**
 * This is a helper function to promisify mutations that wraps variables in a requestData object
 * @param mutation
 * @param overrides
 * @returns a function that takes in the variables and returns a promise of the response type
 */
export function promisifyMutationWithRequestData<
  VariablesType extends { requestData: unknown },
  ResponseType
>(
  mutation: (config: UseMPMutationConfig<MutationParameters>) => void,
  overrides?: Partial<
    Omit<
      UseMPMutationConfig<MutationParameters>,
      'onCompleted' | 'onError' | 'variables'
    >
  >
): (variables: VariablesType['requestData']) => Promise<ResponseType> {
  return (variables: VariablesType['requestData']) =>
    promisifyMutation<
      { requestData: VariablesType['requestData'] },
      ResponseType
    >(
      mutation,
      overrides
    )({ requestData: variables });
}

export default promisifyMutation;
