.actionButton {
  width: 100%;
  height: 100%;
}

.actionButton:not(:global(.hidden)) {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 16px;
}
