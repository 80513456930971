.container {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  width: max-content;
}

.popperContainer {
  z-index: 10;
}

.popper {
  background-color: var(--color-commonWhite);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
  margin: 4px 0;
  padding: 16px;
  width: max-content;
  overflow: auto;
  min-width: 130px;
}

.dialogContainer {
  align-items: flex-end;
}

.dialogPaper {
  height: fit-content;
  max-width: 100%;
}

.dialogContainer .dialogPaper .dialogContentRoot {
  padding: 16px 24px 24px;
}
