import { SvgIcon } from '@mui/material';

export default function BackIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55181 10.7205L12.6366 7.42479L10.9566 5.62995L5.0001 11.9937L10.9566 18.3575L12.6366 16.5627L9.55092 13.266H19V10.7205H9.55181Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
