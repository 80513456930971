import { Hash } from 'viem';
import { useReadContract, useSimulateContract, useWriteContract } from 'wagmi';

import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { areSameAddress } from 'utils/areSameAddress';

import useDefaultContractOverrides from './useDefaultContractOverrides';

let cache;
interface ReadAdminRoleProps {
  abi: any;
  address: Hash;
}

export default function useWrappedTLContract({
  abi: _abi,
  contractAddress: _contractAddress,
  account: _account,
}: WagmiWriteContractManagerProps) {
  if (!cache) {
    let CACHED_ADMIN_ROLE;
    const getCachedRole = () => CACHED_ADMIN_ROLE;
    let promiseResolver;

    const hasCachedAdminRolePromise = new Promise<() => string>((res) => {
      promiseResolver = res;
    });

    const useReadAdminRole = function useReadAdminRole({
      abi,
      address,
    }: ReadAdminRoleProps) {
      const adminRole = useReadContract({
        abi,
        address,
        args: [],
        functionName: 'ADMIN_ROLE',
      });
      if (adminRole.data) {
        if (!CACHED_ADMIN_ROLE) {
          CACHED_ADMIN_ROLE = adminRole.data;
          promiseResolver(getCachedRole);
        }
        CACHED_ADMIN_ROLE = adminRole.data;
      }
      return adminRole;
    };

    const useCachedReadAdminRole = function useCachedReadAdminRole({
      abi,
      address,
    }: ReadAdminRoleProps) {
      useReadAdminRole({ abi, address });
      return CACHED_ADMIN_ROLE;
    };

    cache = function useTLContract({
      abi,
      contractAddress,
      account,
    }: WagmiWriteContractManagerProps) {
      const defaultOverrides = useDefaultContractOverrides();

      const useSetAdmin = ({
        adminAddress,
        isAdmin,
      }: {
        adminAddress: Hash;
        isAdmin: boolean;
      }) => {
        const adminRole = useCachedReadAdminRole({
          abi,
          address: contractAddress,
        });
        const baseArgs = {
          abi,
          account: account ?? defaultOverrides.account,
          address: contractAddress,
          functionName: 'setRole',
          gasLimit: defaultOverrides.gasLimit,
        };
        const simulateContractObj = useSimulateContract({
          query: {
            retry: false,
          },
          ...baseArgs,
          args: [adminRole, [adminAddress], isAdmin],
          enabled: !!adminRole,
        });

        const { writeContract, writeContractAsync, ...writeContractRemaining } =
          useWriteContract();
        const writeContractObj = {
          write: (_adminRole: string) =>
            writeContract({
              ...baseArgs,
              args: [_adminRole, [adminAddress], isAdmin],
            }),
          writeAsync: async () => {
            const _getAdminRole = await hasCachedAdminRolePromise;
            return writeContractAsync({
              ...baseArgs,
              args: [_getAdminRole(), [adminAddress], isAdmin],
            });
          },
          ...writeContractRemaining,
        };

        return {
          mutate: writeContractObj,
          simulate: simulateContractObj,
        };
      };

      const useIsAdmin = ({
        adminAddress,
        query,
      }: {
        adminAddress: Hash;
        query?: any;
      }) => {
        const adminRole = useReadAdminRole({
          abi,
          address: contractAddress,
        });
        const roleMembers = useReadContract({
          abi,
          address: contractAddress,
          args: [adminRole.data],
          functionName: 'getRoleMembers',
          query,
        });

        return {
          data:
            adminRole.isFetched && roleMembers.isFetched
              ? (roleMembers.data as Array<Hash>).some((address) =>
                  areSameAddress(address, adminAddress)
                )
              : undefined,

          isFetching: adminRole.isFetching || roleMembers.isFetching,
          // watch out as refetch before adminRole is stable can result in undefined behavior
          refetch: roleMembers.refetch,
        };
      };
      return { useIsAdmin, useSetAdmin };
    };
  }
  return cache({
    abi: _abi,
    account: _account,
    contractAddress: _contractAddress,
  });
}
