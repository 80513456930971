import {
  MPActionButton,
  MPFonts,
  MPStandardDialog,
} from '@mp-frontend/core-components';

import * as styles from 'css/pages/product/ProductClaimUnlockable.module.css';

interface GreetingsProps {
  onClose: () => void;
  onNext: () => void;
}

export default function GreetingsModal({ onClose, onNext }: GreetingsProps) {
  return (
    <MPStandardDialog
      title="Get Ready To Unlock"
      actionButton={
        <MPActionButton fullWidth autoFocus onClick={onNext}>
          Begin
        </MPActionButton>
      }
      onClose={onClose}
    >
      <div className={styles.container}>
        <div className={MPFonts.paragraphSmall}>
          All information provided in this form is secure, and shared only with
          the verified creator shipping your artwork.
        </div>
      </div>
    </MPStandardDialog>
  );
}
