import { NFTType } from 'types/graphql/NFT';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';

import ProductComment from './ProductComment';
import ProductLike from './ProductLike';
import ProductShare from './ProductShare';
import ProductView from './ProductView';

import * as styles from 'css/pages/product/ProductSocialBar.module.css';

interface ProductSocialBarProps extends Record<string, unknown> {
  nft: NFTType;
}

function ProductSocialBar({ nft }: ProductSocialBarProps) {
  return (
    <div className={styles.container}>
      <ProductLike nft={nft} />
      <ProductComment nft={nft} />
      <ProductView nft={nft} />
      <ProductShare nft={nft} />
    </div>
  );
}

export default withDefaultErrorBoundary(ProductSocialBar, { hideState: true });
