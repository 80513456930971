import { useAccount } from 'wagmi';

import useSession from 'hooks/useSession';
import {
  areSameAddress,
  hasUserRegisteredWalletAddress,
} from 'utils/areSameAddress';
import { AccountConnectionStatus, HexString } from 'utils/jwt/walletUtils';

export interface UseWalletValidateProps {
  restrictToAddress?: HexString;
  warnIfAddressNotRegistered?: boolean;
}

export default function useWalletValidate({
  restrictToAddress,
  warnIfAddressNotRegistered,
}: UseWalletValidateProps = {}) {
  const { status, address, chainId } = useAccount();
  const session = useSession();

  const isDisconnected = status === AccountConnectionStatus.Disconnected;
  const isWrongNetwork = session.contractNetwork !== chainId;
  const isWrongAddress =
    !!restrictToAddress && !areSameAddress(address, restrictToAddress);
  const isUnregisteredAddress =
    warnIfAddressNotRegistered &&
    !!address &&
    !hasUserRegisteredWalletAddress(address, session.account.wallets);

  return {
    isDisconnected,
    isUnregisteredAddress,
    isValid: !isWrongAddress && !isUnregisteredAddress,
    isWrongAddress,
    isWrongNetwork,
  };
}
