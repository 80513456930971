import { merge } from 'lodash';
import { Drawer, DrawerProps, useMediaQuery } from '@mui/material';

import { MPColorValue } from '../themes/default/__generated__/MPColorsEnum';

import useDefaultTheme from '../hooks/useDefaultTheme';

export interface MPDrawerProps extends DrawerProps {
  clipTop?: number;
}

export default function MPDrawer({
  children,
  clipTop,
  sx,
  ...props
}: MPDrawerProps) {
  const isMobile = useMediaQuery(useDefaultTheme().breakpoints.down('desktop'));
  const mergedSx = merge(
    {
      '.MuiBackdrop-root': {
        backgroundColor: MPColorValue.BackgroundOverlay,
        boxShadow: `none`,
        display: isMobile ? 'none' : 'auto',
      },
      '.MuiDrawer-paper': {
        boxShadow: `none`,
      },
      boxShadow: `none`,
    },
    clipTop
      ? {
          '.MuiBackdrop-root': {
            top: `${clipTop}px`,
          },
          '.MuiDrawer-paper': {
            borderTop: `1px solid ${MPColorValue.SolidNeutralGray1}`,
            top: `${clipTop}px`,
          },
          top: `${clipTop}px`,
        }
      : {},
    sx || {}
  );

  return (
    <Drawer sx={mergedSx} {...props}>
      {children}
    </Drawer>
  );
}
