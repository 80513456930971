/**
 * @generated SignedSource<<ee1981d46f6b75b80fd4c5fb282c689d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationActionEnum = "BID_ACCEPTED" | "BID_RECEIVED" | "BID_OUTBID" | "GLOBAL_BID_ACCEPTED" | "GLOBAL_BID_RECEIVED" | "GLOBAL_BID_OUTBID" | "SALE_ACCEPTED" | "COMMENTED" | "LIKED" | "FOLLOWED" | "MINT_FROM_FOLLOWED_CREATOR" | "MINT_FROM_PURCHASED_BEFORE_CREATOR" | "MINT_OF_GENERATIVE_ART";
export type MuteNotificationsArguments = {
  isMuted: boolean;
  objectAction: NotificationActionEnum;
};
export type NotificationsMuteMutation$variables = {
  request_data: MuteNotificationsArguments;
};
export type NotificationsMuteMutation$data = {
  readonly muteNotifications: {
    readonly success: boolean | null;
  } | null;
};
export type NotificationsMuteMutation = {
  variables: NotificationsMuteMutation$variables;
  response: NotificationsMuteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request_data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "request_data"
      }
    ],
    "concreteType": "MuteNotificationsMutation",
    "kind": "LinkedField",
    "name": "muteNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsMuteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsMuteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "693e3ccbbf6f641e96984326d79bd2d8",
    "id": null,
    "metadata": {},
    "name": "NotificationsMuteMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationsMuteMutation(\n  $request_data: MuteNotificationsArguments!\n) {\n  muteNotifications(requestData: $request_data) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc7be686b81b01d51e5092950ab69e1b";

export default node;
