import MPSVGIcon from './MPSVGIcon';

export default function ViewOriginalIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        d="M15.3746 4.25H4V15.6246L8.62539 20.25H20V8.87539L15.3746 4.25ZM17.0619 10.4116V17.3203H9.11228V9.36228H17.0703V10.4116H17.0619Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
