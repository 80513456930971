import { SvgIcon } from '@mui/material';

export default function NextArrow(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14 7.00006L7.77779 0.777832H5.02793L10.2779 6.02783H0V7.97228H10.2779L5.02793 13.2223H7.77779L14 7.00006Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
