.container {
  padding: 0 0 8px;
  width: 100%;
}

.container.open {
  padding: 0 0 32px;
}

.actionContainer {
  align-items: center;
  padding: 32px 0 24px;
  cursor: pointer;
  display: flex;
}

.actionContainer.disabled {
  cursor: default;
}

.title {
  flex-basis: 0;
  flex-grow: 1;
  text-align: left;
}
