import { SvgIcon } from '@mui/material';

export default function SendIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M2.00892 20.2428C2.00943 20.6015 2.37629 20.8429 2.70596 20.7016L21.9283 12.4595C22.3322 12.2863 22.3322 11.7137 21.9283 11.5405L2.70596 3.29841C2.37629 3.15706 2.00943 3.39855 2.00892 3.75723L2.00063 9.56161C2.00027 9.81248 2.18587 10.0248 2.43454 10.0579L13.2829 11.5044C13.8615 11.5815 13.8615 12.4185 13.2829 12.4956L2.43454 13.9421C2.18587 13.9752 2.00027 14.1875 2.00063 14.4384L2.00892 20.2428Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
