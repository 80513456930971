.panelBody {
  width: 300px;
  box-sizing: border-box;
  --horz-margin: 34px;
  --vert-margin: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panelCell {
  overflow: hidden;
}

.panelCell:nth-child(1) {
  flex: 0 0 auto;
}

.panelCell:nth-child(2) {
  flex: 1 1 auto;
}

.header {
  display: flex;
  padding-left: var(--horz-margin);
  padding-right: var(--horz-margin);
  padding-bottom: 24px;
  padding-top: var(--vert-margin);
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  box-sizing: border-box;
}

.header:empty {
  margin: 0;
  padding: 0;
}

.header.reverse, .header.reverse > .actionIconSection  {
  flex-direction: row-reverse;
}

.actionIconSection {
  display: flex;
  gap: 10px;
  z-index: 1;
}

.actionIcon {
  border-radius: 50%;
  border: 2px solid transparent;
  object-fit: contain;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionIcon.backgroundDefault {
  background-color: var(--color-backgroundDefault);
}

.actionIcon.gray5 {
  background-color: var(--color-SolidNeutralGray5);
}

.dark {
  background-color: var(--color-primaryDark);
  color: var(--color-commonWhite);
}

.header:not(:empty) + .panelCell.showBorderTop {
  border-top: 1px solid var(--color-SolidNeutralGray1);
}

@media (--mq-is-mobile) {
  .panelBody {
    width: 100vw;
    height: 100%;
    background-color: var(--color-commonWhite);
    --horz-margin: 42px;
  }

  .panelBody.isNav {
    height: calc(100% - 64px);
    background-color: var(--color-backgroundDefault);
  }

  .header:not(:empty) + .panelCell {
    border-top: 1px solid var(--color-SolidNeutralGray1);
  }

  .panelBody:not(.isNav) .title:not(:empty) {
    width: 100%;
    position: relative;
    z-index: 0;
    left: 32px;
    text-align: center;
    flex: 1 0 100%;
  }

  .panelBody:not(.isNav) .header {
    padding-left: 24px;
    padding-right: 24px;
  }
}
