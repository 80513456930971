import { startTransition, useCallback, useState } from 'react';
import { useMutation } from 'react-relay';

import {
  MPActionButton,
  MPFonts,
  MPStyledTextField,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountRenameWallet, {
  AccountRenameWalletMutation,
} from 'graphql/__generated__/AccountRenameWalletMutation.graphql';

import StackStateDialog from 'components/dialogs/StackStateDialog';
import ErrorDisplay from 'components/Error';
import CSSGlobal from 'types/enums/css/Global';
import promisifyMutation from 'utils/promisifyMutation';

import * as styles from 'css/pages/settings/wallet/wallets/RenameWalletDialog.module.css';

import { Wallets } from 'Session';

interface RenameWalletDialogProps {
  cancel: () => void;
  invalidate: () => void;
  isOpen: boolean;
  wallet: Wallets[0];
}

export default function RenameWalletDialog({
  wallet,
  isOpen,
  invalidate,
  cancel,
}: RenameWalletDialogProps) {
  const [error, setError] = useState<Error>(undefined);
  const [walletName, setWalletName] = useState(wallet.name);
  const [isLoading, setIsLoading] = useState(false);

  const [renameWalletMutation] =
    useMutation<AccountRenameWalletMutation>(AccountRenameWallet);

  const renameWallet = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      await promisifyMutation(renameWalletMutation)({
        address: wallet?.address,
        name: walletName,
      });
      // Unfortunately, our backend call to stripe isn't fully updated yet =/
      startTransition(invalidate);
      cancel();
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [invalidate, walletName, renameWalletMutation, cancel, wallet?.address]);

  return (
    <StackStateDialog
      title="Edit Wallet Name"
      open={isOpen}
      onClose={cancel}
      actionButton={
        <MPActionButton
          variant="primary"
          fullWidth
          size="large"
          isLoading={isLoading}
          disabled={walletName === wallet.name || !walletName}
          onClick={renameWallet}
        >
          Save&nbsp;wallet&nbsp;name
        </MPActionButton>
      }
    >
      <ErrorDisplay error={error} className={CSSGlobal.TextAlign.Centered} />
      <div
        className={joinClasses(styles.explanation, MPFonts.textSmallRegular)}
      >
        You can edit your ETH wallet with a custom name
      </div>
      <MPStyledTextField
        inputClassName={MPFonts.textNormalRegular}
        value={walletName}
        setValue={setWalletName}
        placeholder="Wallet Name"
        fullWidth
        showFocusedPlaceholder
        autoFocus
        size="small"
      />
    </StackStateDialog>
  );
}
