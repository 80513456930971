import MPSVGIcon from './MPSVGIcon';

export default function RefreshIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <path
        d="M20.5366 11.6169V4.35635L17.8742 6.98441C16.385 5.16704 14.1287 4 11.5836 4C7.10713 4 3.46997 7.5902 3.46997 12C3.46997 16.4098 7.10713 20 11.5746 20C15.1305 20 18.154 17.7283 19.246 14.5746H15.7713C14.8959 15.9555 13.3435 16.882 11.5746 16.882C8.84899 16.882 6.62879 14.6904 6.62879 12C6.62879 9.30958 8.84899 7.11804 11.5746 7.11804C13.2082 7.11804 14.6522 7.902 15.5547 9.11359L15.6089 9.21158L13.1721 11.6169H20.5276H20.5366Z"
        fill="currentColor"
      />
    </MPSVGIcon>
  );
}
