/**
 * @generated SignedSource<<ea2f4e40eee2d15098b8c1f27ea88f31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExploreEditorialsPaginatedFragment$data = {
  readonly editorial: {
    readonly results: {
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly endCursor: string | null;
      };
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly backgroundImageUrl: string | null;
          readonly description: string;
          readonly id: string;
          readonly order: number;
          readonly pk: string;
          readonly title: string;
          readonly type: string;
          readonly viewUrl: string;
        } | null;
        readonly cursor: string;
      } | null>;
    } | null;
  } | null;
  readonly " $fragmentType": "ExploreEditorialsPaginatedFragment";
};
export type ExploreEditorialsPaginatedFragment$key = {
  readonly " $data"?: ExploreEditorialsPaginatedFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExploreEditorialsPaginatedFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "editorial",
  "results"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "query"
    },
    {
      "kind": "RootArgument",
      "name": "sortOrder"
    },
    {
      "kind": "RootArgument",
      "name": "sortType"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PaginatedExploreEditorialsQuery.graphql')
    }
  },
  "name": "ExploreEditorialsPaginatedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ExploreEditorialQuery",
      "kind": "LinkedField",
      "name": "editorial",
      "plural": false,
      "selections": [
        {
          "alias": "results",
          "args": [
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "sortOrder",
              "variableName": "sortOrder"
            },
            {
              "kind": "Variable",
              "name": "sortType",
              "variableName": "sortType"
            }
          ],
          "concreteType": "EditorialConnection",
          "kind": "LinkedField",
          "name": "__PaginatedExploreEditorialsQuery__results_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "EditorialEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NFTEditorialType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "backgroundImageUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pk",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "viewUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e4dbc76b68f0e703eecf9c557bcce525";

export default node;
