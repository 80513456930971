import { ReactNode } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/components/filters/FilterSection.module.css';

interface FiltersSectionProps {
  children: ReactNode;
  inline?: boolean;
  inlineMobile?: boolean;
}

export default function FiltersSection({
  children,
  inline = false,
  inlineMobile = false,
}: FiltersSectionProps) {
  return (
    <div
      className={joinClasses(
        styles.filterRow,
        styles.scroll,
        {
          [styles.inline]: inline,
          [styles.inlineMobile]: inlineMobile,
        },
        CSSGap[16],
        CSSGlobal.NoScrollbar
      )}
    >
      {children}
    </div>
  );
}
