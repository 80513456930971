import { SvgIcon } from '@mui/material';

export default function FilterIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50002 6.66663V7.99935C7.71704 8.32587 7.16669 9.09864 7.16669 9.99996C7.16669 10.9013 7.71704 11.6741 8.50002 12.0006L8.50002 17.3333H10.1667L10.1667 12.0006C10.9497 11.6741 11.5 10.9013 11.5 9.99996C11.5 9.09864 10.9497 8.32587 10.1667 7.99935V6.66663H8.50002ZM9.33335 9.49996C9.05721 9.49996 8.83335 9.72382 8.83335 9.99996C8.83335 10.2761 9.05721 10.5 9.33335 10.5C9.6095 10.5 9.83335 10.2761 9.83335 9.99996C9.83335 9.72382 9.6095 9.49996 9.33335 9.49996Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8334 6.66663H15.5V11.9993C16.283 12.3259 16.8334 13.0986 16.8334 14C16.8334 14.9013 16.283 15.6741 15.5 16.0006V17.3333H13.8334V16.0006C13.0504 15.6741 12.5 14.9013 12.5 14C12.5 13.0986 13.0504 12.3259 13.8334 11.9993V6.66663ZM14.1667 14C14.1667 13.7238 14.3905 13.5 14.6667 13.5C14.9428 13.5 15.1667 13.7238 15.1667 14C15.1667 14.2761 14.9428 14.5 14.6667 14.5C14.3905 14.5 14.1667 14.2761 14.1667 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
