import { MPExpandable } from '@mp-frontend/core-components';

import MiniArtist from 'components/accounts/MiniArtist';
import { AccountArtistFragment } from 'types/graphql/Account';

import ProductCollapsibleContainer from '../ProductCollapsibleContainer';

import * as styles from 'css/pages/product/ProductAboutArtists.module.css';

interface OtherCollaboratorsProps {
  artists: AccountArtistFragment[];
}

function OtherCollaborators({ artists }: OtherCollaboratorsProps) {
  return (
    <ProductCollapsibleContainer
      title="Other Collaborators"
      showIcon={false}
      collapsed={false}
      collapsible={false}
    >
      <MPExpandable maxHeight={150}>
        <div className={styles.otherCollaboratorsBody}>
          {artists.map((artist) => (
            <MiniArtist key={artist.pk} artist={artist} />
          ))}
        </div>
      </MPExpandable>
    </ProductCollapsibleContainer>
  );
}

export default OtherCollaborators;
