import { ReactNode, useMemo } from 'react';
import { dialogClasses } from '@mui/material/Dialog';

import { useIsMobile } from '..';
import MPDialog, { MPDialogProps } from './MPDialog';

import * as styles from '../css/dialog/MPStandardDialog.module.css';

export interface MPStandardDialogProps
  extends Pick<
    MPDialogProps,
    | 'actionButton'
    | 'container'
    | 'disableEscapeKeyDown'
    | 'onClose'
    | 'onPrefixClick'
    | 'actionSx'
  > {
  children: ReactNode;
  title: string;
  maxDesktopWidth?: number | string;
  open?: MPDialogProps['open'];
}

const defaultClasses = { container: styles.container, paper: styles.paper };
const contentClasses = { root: styles.content };

export default function MPStandardDialog({
  children,
  maxDesktopWidth,
  open = true,
  ...props
}: MPStandardDialogProps) {
  const isMobile = useIsMobile();
  const sx = useMemo(
    () => ({
      [`& .${dialogClasses.paper}`]: {
        maxWidth: isMobile
          ? '100%'
          : maxDesktopWidth ?? 'var(--mp-defaultDialogWidth, 502px)',
      },
    }),
    [isMobile, maxDesktopWidth]
  );

  return (
    <MPDialog
      {...props}
      className={styles.dialog}
      contentClasses={contentClasses}
      open={open}
      classes={defaultClasses}
      sx={sx}
    >
      {children}
    </MPDialog>
  );
}
