import { CreditCardIcons } from '@mp-frontend/core-components/icons';

const creditCardIconMap = {
  amex: CreditCardIcons.AmexIcon,
  diners: CreditCardIcons.DinersIcon,
  discover: CreditCardIcons.DiscoverIcon,
  elo: CreditCardIcons.EloIcon,
  hipercard: CreditCardIcons.HiperIcon,
  jcb: CreditCardIcons.JCBIcon,
  maestro: CreditCardIcons.MaestroIcon,
  mastercard: CreditCardIcons.MastercardIcon,
  unionpay: CreditCardIcons.UnionPayIcon,
  visa: CreditCardIcons.VisaIcon,
};

const creditCardUtils = {
  getIcon(brand: string) {
    return creditCardIconMap[brand] || CreditCardIcons.GenericIcon;
  },
};

export default creditCardUtils;
