import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const PlayIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M7.997 2.60309L21.2337 11.9938L7.997 21.3844L7.997 2.60309Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default PlayIcon;
