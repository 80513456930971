.container {
  position: relative;
  overflow: hidden;
}
.container.standardMode {
  border-radius: 16px;
}

.container.largeMode {
  border-radius: 48px;
}

.container.cover:not(:global(.fullscreen-enabled)) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player > video {
  display: block;
}

.standardBar {
  align-items: center;
  backdrop-filter: blur(16px);
  background: rgba(0, 0, 0, 0.10);
  bottom: -1px;
  color: var(--color-commonWhite);
  display: flex;
  gap: 8px;
  left: 0;
  padding: 14px 12px;
  position: absolute;
  right: 0;
  transition: 0.2s ease-in-out;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(0deg, rgba(105, 105, 105, 0.60) 0%, rgba(105, 105, 105, 0.60) 100%);
  transition: 0.2s ease-in-out;
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  grid-template-columns: 100%;
  color: var(--color-commonWhite);
  pointer-events: none;
}

@media (--mq-is-not-mobile) and (hover: hover) and (pointer: fine) {
  .container:hover > .overlay {
    opacity: 1;
    pointer-events: all;
  }
}

@media (--mq-is-mobile) {
  .container.largeMode {
    border-radius: 14px;
  }

  .container:focus-within > .overlay {
    opacity: 1;
    pointer-events: all;
  }
}

/* at the end for overwriting purposes */
.container:global(.fullscreen-enabled) {
  border-radius: 0;
  display: flex;
  align-items: center;
}
