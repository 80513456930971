import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from '../MPSVGIcon';

const MastercardIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M14.4746 7.54773H9.51416V16.4617H14.4746V7.54773Z"
      fill="#FF5F00"
    />
    <path
      d="M9.82936 12.0012C9.82858 11.1427 10.0231 10.2953 10.3982 9.52311C10.7734 8.75093 11.3193 8.07422 11.9946 7.54419C11.1583 6.88684 10.1539 6.47805 9.09628 6.36453C8.03863 6.25101 6.97039 6.43735 6.01364 6.90225C5.05689 7.36715 4.25024 8.09186 3.68589 8.99353C3.12154 9.8952 2.82227 10.9375 2.82227 12.0012C2.82227 13.0649 3.12154 14.1072 3.68589 15.0088C4.25024 15.9105 5.05689 16.6352 6.01364 17.1001C6.97039 17.565 8.03863 17.7514 9.09628 17.6378C10.1539 17.5243 11.1583 17.1155 11.9946 16.4582C11.3193 15.9282 10.7734 15.2514 10.3983 14.4793C10.0231 13.7071 9.82858 12.8597 9.82936 12.0012Z"
      fill="#EB001B"
    />
    <path
      d="M21.1657 12.0012C21.1657 13.0649 20.8665 14.1071 20.3022 15.0088C19.7379 15.9105 18.9312 16.6352 17.9745 17.1001C17.0178 17.565 15.9495 17.7514 14.8919 17.6378C13.8343 17.5243 12.8299 17.1155 11.9937 16.4582C12.6684 15.9276 13.2139 15.2508 13.5889 14.4787C13.964 13.7067 14.1589 12.8595 14.1589 12.0012C14.1589 11.1428 13.964 10.2957 13.5889 9.52364C13.2139 8.75158 12.6684 8.07475 11.9937 7.54419C12.8299 6.88684 13.8343 6.47805 14.8919 6.36453C15.9495 6.25101 17.0178 6.43736 17.9745 6.90226C18.9312 7.36717 19.7379 8.09187 20.3022 8.99355C20.8665 9.89522 21.1657 10.9375 21.1657 12.0012Z"
      fill="#F79E1B"
    />
    <path
      d="M20.6255 15.5134V15.3309H20.6991V15.2937H20.5117V15.3309H20.5853V15.5134H20.6255ZM20.9894 15.5134V15.2933H20.9319L20.8659 15.4447L20.7998 15.2933H20.7423V15.5134H20.7829V15.3474L20.8448 15.4905H20.8869L20.9488 15.347V15.5134H20.9894Z"
      fill="#F79E1B"
    />
  </MPSVGIcon>
));

export default MastercardIcon;
