export default function sortArray<T>(
  array: T[],
  compareFunction: (
    item1: T,
    item2: T,
    index1: number,
    index2: number
  ) => number
): T[] {
  return array
    .map((item, index) => ({ index, item }))
    .sort((a, b) => compareFunction(a.item, b.item, a.index, b.index))
    .map(({ item }) => item);
}
