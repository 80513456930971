import { ReactNode, useCallback, useMemo, useState } from 'react';

import {
  MPActionButton,
  MPBackgroundColorClass,
  MPColorClass,
  MPDialogProps,
  MPFonts,
  MPStandardDialog,
} from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { ProfilesFragment$data } from 'graphql/__generated__/ProfilesFragment.graphql';
import ProfilesQueryType, {
  ProfilesQuery,
} from 'graphql/__generated__/ProfilesQuery.graphql';
import { StoreLinkLinkType } from 'types/__generated__/graphql';

import User from 'components/accounts/User';
import useProductOwnership from 'hooks/product/useProductOwnership';
import useProfileData from 'pages/profiles/useProfileData';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import { AccountArtistFragment } from 'types/graphql/Account';
import { NFTType } from 'types/graphql/NFT';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';
import { getNFTPrivateSaleState } from 'utils/nftUtils';

import * as styles from 'css/pages/product/ProductPrivateCurator.module.css';

type SocialLinkType = Omit<
  ProfilesFragment$data['socialLinks'][number],
  'linkType'
> & {
  linkType: string;
};

type OrderedSocialLink = {
  baseUrl: string;
  label: string;
  linkType: SocialLinkType['linkType'];
  id?: string;
  value?: string;
};

const OrderedSocialLinks: ReadonlyArray<OrderedSocialLink> = [
  {
    baseUrl: 'x.com/',
    label: 'Twitter/X',
    linkType: StoreLinkLinkType.Twitter,
  },
  {
    baseUrl: '',
    label: 'Email',
    linkType: 'EMAIL',
  },
];

interface BaseCuratorDialogProps {
  onClose: MPDialogProps['onClose'];
  profileQuery: WithLoadQueryProps<ProfilesQuery>;
}

const BaseCuratorDialog = withDefaultErrorBoundary(
  withLoadQuery(
    ({ profileQuery, onClose }: BaseCuratorDialogProps) => {
      const profileData = useProfileData(profileQuery.queryRef);

      const links = useMemo(() => {
        const linksMap = ([] as SocialLinkType[])
          .concat((profileData?.socialLinks || []) as SocialLinkType[])
          .concat(
            profileData?.user?.email
              ? [
                  {
                    id: 'email',
                    linkType: 'EMAIL',
                    value: profileData?.user?.email,
                  },
                ]
              : []
          )
          .reduce((memo, link) => {
            // eslint-disable-next-line no-param-reassign
            memo[link.linkType] = link;

            return memo;
          }, {} as Record<string, SocialLinkType>);

        return OrderedSocialLinks.reduce((memo, link) => {
          const linkData = linksMap[link.linkType];
          if (linkData) {
            memo.push({
              ...link,
              id: linkData.id,
              value: `${link.baseUrl}${linkData.value}`,
            });
          }

          return memo;
        }, []);
      }, [profileData]);

      return (
        <MPStandardDialog
          open
          title={`Contact ${profileData.fullName}`}
          onClose={onClose}
          actionButton={
            <>
              {!!profileData.user?.email && (
                <MPActionButton
                  fullWidth
                  href={`mailto:${profileData.user.email}`}
                  size="large"
                  variant="primary"
                >
                  Email {profileData.fullName}
                </MPActionButton>
              )}
            </>
          }
        >
          <div
            className={joinClasses(
              MPColorClass.CommonBlack,
              CSSGlobal.Cursor.Default,
              CSSGlobal.Flex.Col,
              CSSGap[16]
            )}
          >
            <div className={MPFonts.paragraphSmall}>
              If you have any questions or difficulties, don&#39;t hesitate to
              contact me through the channels bellow.
            </div>

            <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[12])}>
              {links.map((link) => (
                <div
                  key={link.id}
                  className={joinClasses(
                    MPFonts.textSmallMedium,
                    MPColorClass.CommonBlack,
                    CSSGlobal.Flex.RowSpaceBetween
                  )}
                >
                  <span className={MPColorClass.SolidNeutralGray5}>
                    {link.label}
                  </span>
                  <span>{link.value}</span>
                </div>
              ))}
            </div>
          </div>
        </MPStandardDialog>
      );
    },
    {
      profileQuery: { concreteRequest: ProfilesQueryType },
    }
  ),
  {
    hideState: true,
  }
);

type CuratorDialogProps = Pick<AccountArtistFragment, 'fullName' | 'username'>;

export function CuratorDialog({
  children,
  username,
}: CuratorDialogProps & {
  children?: (props: {
    close: MPDialogProps['onClose'];
    open: () => void;
    show: boolean;
  }) => ReactNode;
}) {
  const [show, setShow] = useState<boolean>(false);

  const handleOpen = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => setShow(false), []);

  return (
    <>
      {children
        ? children({ close: handleClose, open: handleOpen, show })
        : null}

      {!!show && (
        <BaseCuratorDialog
          profileQuery={{ variables: { slug: username } }}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export function CuratorDialogWithAction(props: CuratorDialogProps) {
  return (
    <CuratorDialog {...props}>
      {({ open }) => (
        <MPActionButton
          fullWidth
          size="large"
          variant="secondary"
          onClick={open}
        >
          Contact {props.fullName}
        </MPActionButton>
      )}
    </CuratorDialog>
  );
}

export default function ProductPrivateCurator({ nft }: { nft: NFTType }) {
  const isCurrentUserTokenOwner = useProductOwnership({ nft });
  const { showPrivateSale } = getNFTPrivateSaleState(nft);

  if (
    isCurrentUserTokenOwner ||
    !showPrivateSale ||
    !nft.listing.liveSale.curators?.length
  ) {
    return null;
  }

  return (
    <div
      className={joinClasses(
        CSSGlobal.Flex.Col,
        CSSGap[12],
        CSSMargin.BOTTOM[24],
        MPBackgroundColorClass.BackgroundDefault,
        styles.container
      )}
    >
      <div className={MPFonts.paragraphSmall}>
        This artwork is part of our Private Sales program, where we curate the
        finest contemporary works for our most discerning collectors. Please
        reach out if you have any questions.
      </div>

      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[20])}>
        {nft.listing.liveSale.curators.map((curator) => (
          <div
            key={curator.pk}
            className={joinClasses(CSSGlobal.Flex.Col, CSSGap[14])}
          >
            <User
              user={curator}
              bottomSection={
                !!curator.jobTitle && (
                  <span className={MPFonts.textSmallRegular}>
                    {curator.jobTitle}
                  </span>
                )
              }
            />

            <CuratorDialogWithAction
              fullName={curator.fullName}
              username={curator.username}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
