/* If Wagmi publicClient doesn't work, we need to try this instead:
 */
import { useMemo } from 'react';
import { ethers } from 'ethers';
import { mainnet } from 'viem/chains';

import useSession from 'hooks/useSession';
import { DefaultBlock } from 'types/enums/blockChain/eth';
import { HexString } from 'utils/jwt/walletUtils';

import { MAINNET_ALCHEMY_KEY, TESTNET_ALCHEMY_KEY } from '../wagmi-client';

interface MPPublicClient extends ethers.providers.JsonRpcProvider {
  getTransactionCount: (
    address: HexString,
    blockType?: HexString | DefaultBlock
  ) => ReturnType<ethers.providers.JsonRpcProvider['getTransactionCount']>;
}

const usePublicClient: () => MPPublicClient = () => {
  const session = useSession();
  const isMainNet = session.contractNetwork === mainnet.id;

  return useMemo(() => {
    const proto = new ethers.providers.JsonRpcProvider(
      `https://eth-${isMainNet ? 'mainnet' : 'sepolia'}.g.alchemy.com/v2/${
        isMainNet ? MAINNET_ALCHEMY_KEY : TESTNET_ALCHEMY_KEY
      }`
    );

    const provider = Object.create(proto);
    provider.getTransactionCount = async function getTransactionCount(
      address: HexString,
      blockType: HexString | DefaultBlock = DefaultBlock.PENDING
    ) {
      return proto.getTransactionCount.call(this, address, blockType);
    };
    return provider;
  }, [isMainNet]);
};

export default usePublicClient;
