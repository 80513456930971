import { useCallback, useEffect, useRef } from 'react';
import { GraphQLTaggedNode, OperationType } from 'relay-runtime';

import useLoadQuery from 'hooks/useLoadQuery';

interface PollingProps<TQuery extends OperationType> {
  intervalMs: number;
  queryType: GraphQLTaggedNode;
  queryVariables: TQuery['variables'];
}

export default function usePollLoadQuery<TQuery extends OperationType>({
  intervalMs,
  queryType,
  queryVariables,
}: PollingProps<TQuery>) {
  const [queryRef, loadQuery, disposeFunction, invalidate] =
    useLoadQuery<TQuery>(queryType, queryVariables, 'store-and-network');

  const intervalRef = useRef(null);
  const stop = useCallback(() => {
    if (!intervalRef.current) return;

    clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      invalidate();
    }, intervalMs);
    return () => clearInterval(intervalRef.current);
  }, [intervalMs, queryRef, invalidate]);

  return [queryRef, loadQuery, disposeFunction, invalidate, stop] as const;
}
