import { SvgIcon } from '@mui/material';

export default function DropsBadgeIcon(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <circle cx="7.5" cy="9" r="7" fill="currentColor" fillOpacity="0.16" />
        <circle cx="7.50001" cy="8" r="2.33333" fill="currentColor" />
      </g>
    </SvgIcon>
  );
}
