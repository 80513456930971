import MPSVGIcon from './MPSVGIcon';

export default function ErcIcon(props) {
  return (
    <MPSVGIcon {...props}>
      <rect width="24" height="24" fill="white" />
      <circle cx="12" cy="12" r="10" fill="black" />
      <path
        d="M16.0002 11.8823L12.0002 5.00012L8.00024 11.8823L12.0002 14.3333L16.0002 11.8823Z"
        fill="white"
      />
      <path
        d="M11.9993 15.1819L8.00024 12.6674L11.9993 18.6672L16.0002 12.6674L11.9993 15.1819Z"
        fill="white"
      />
    </MPSVGIcon>
  );
}
