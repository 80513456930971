/**
 * @generated SignedSource<<ab590837d4f2b6a2f92ed464fb278393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeEmailAndPhoneNumberArguments = {
  email?: string | null;
  phoneNumber?: string | null;
};
export type AccountChangeEmailAndPhoneNumberMutation$variables = {
  requestData: ChangeEmailAndPhoneNumberArguments;
};
export type AccountChangeEmailAndPhoneNumberMutation$data = {
  readonly changeEmailAndPhoneNumber: {
    readonly success: boolean | null;
  } | null;
};
export type AccountChangeEmailAndPhoneNumberMutation = {
  variables: AccountChangeEmailAndPhoneNumberMutation$variables;
  response: AccountChangeEmailAndPhoneNumberMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "ChangeEmailAndPhoneNumberMutation",
    "kind": "LinkedField",
    "name": "changeEmailAndPhoneNumber",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountChangeEmailAndPhoneNumberMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountChangeEmailAndPhoneNumberMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0b10f2788d86e8440128e5187a4ba9a5",
    "id": null,
    "metadata": {},
    "name": "AccountChangeEmailAndPhoneNumberMutation",
    "operationKind": "mutation",
    "text": "mutation AccountChangeEmailAndPhoneNumberMutation(\n  $requestData: ChangeEmailAndPhoneNumberArguments!\n) {\n  changeEmailAndPhoneNumber(requestData: $requestData) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9cad320104875f4bc32f547498f5c23";

export default node;
