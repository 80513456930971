import { MPFonts } from '@mp-frontend/core-components';
import { ShareIcon } from '@mp-frontend/core-components/icons';
import { joinClasses } from '@mp-frontend/core-utils';

import useShareProduct from 'hooks/product/useShareProduct';
import { NFTType } from 'types/graphql/NFT';

import * as styles from 'css/pages/product/ProductSocialBar.module.css';

interface ShareProps {
  nft: NFTType;
}

export default function ProductShare({ nft }: ShareProps) {
  const [onMouseClick, onKeyboardPress, Dialog] = useShareProduct({ nft });

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={onMouseClick}
        onKeyPress={onKeyboardPress}
        className={joinClasses('hoverableLink', styles.action)}
      >
        <ShareIcon sx={{ width: '20px' }} />
        <span className={MPFonts.notificationBoldText}>Share</span>
      </div>

      {Dialog}
    </>
  );
}
