import { forwardRef } from 'react';

import MPSVGIcon, { MPSVGIconProps } from './MPSVGIcon';

const MuteIcon = forwardRef<any, MPSVGIconProps>((props, ref) => (
  <MPSVGIcon {...props} ref={ref}>
    <path
      d="M16 12C16 10.4267 15.0933 9.07556 13.7778 8.41778V10.3822L15.9556 12.56C15.9822 12.3822 16 12.1956 16 12ZM18.2222 12C18.2222 12.8356 18.0444 13.6178 17.7422 14.3467L19.0844 15.6889C19.6711 14.5867 20 13.3333 20 12C20 8.19556 17.3422 5.01333 13.7778 4.20444V6.03556C16.3467 6.8 18.2222 9.18222 18.2222 12ZM5.12889 4L4 5.12889L8.20444 9.33333H4V14.6667H7.55556L12 19.1111V13.1289L15.7778 16.9067C15.1822 17.3689 14.5156 17.7333 13.7778 17.9556V19.7867C15.0044 19.5111 16.1156 18.9422 17.0578 18.1778L18.8711 20L20 18.8711L12 10.8711L5.12889 4ZM12 4.88889L10.1422 6.74667L12 8.60444V4.88889Z"
      fill="currentColor"
    />
  </MPSVGIcon>
));

export default MuteIcon;
