import parsePhoneNumber from 'libphonenumber-js';

export function isValidPhoneNumber(phoneNumber: string): boolean {
  if (!phoneNumber) {
    return false;
  }

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, { extract: false });
    return parsedPhoneNumber.isValid();
  } catch {
    return false;
  }
}

export const EMAIL_REG_EX =
  /* eslint-disable-next-line no-useless-escape */
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email: string): boolean {
  return EMAIL_REG_EX.test(String(email).toLowerCase());
}

export function validateEmailInput(email: string) {
  // Ignore empty input
  if (email === '') {
    return {
      errorMessage: '',
      isError: false,
    };
  }

  if (isValidEmail(email)) {
    return {
      errorMessage: '',
      isError: false,
    };
  }

  return {
    errorMessage: 'Please enter a valid email',
    isError: true,
  };
}
