/**
 * @generated SignedSource<<974e180effbbf5ac967acbff2eba2aa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountsQuery$variables = {
  searchText: string;
};
export type AccountsQuery$data = {
  readonly accounts: ReadonlyArray<{
    readonly pk: number | null;
    readonly username: string | null;
    readonly fullName: string | null;
  } | null> | null;
};
export type AccountsQuery = {
  variables: AccountsQuery$variables;
  response: AccountsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchText"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "searchText",
        "variableName": "searchText"
      }
    ],
    "concreteType": "BasicAccountType",
    "kind": "LinkedField",
    "name": "accounts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pk",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0870a4fdf4f4afeb0d62752ebe5493c4",
    "id": null,
    "metadata": {},
    "name": "AccountsQuery",
    "operationKind": "query",
    "text": "query AccountsQuery(\n  $searchText: String!\n) {\n  accounts(searchText: $searchText) {\n    pk\n    username\n    fullName\n  }\n}\n"
  }
};
})();

(node as any).hash = "099b203d4444f30ab55dc620329004e1";

export default node;
