import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export default function useDigitalMediaCoreContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();

  const useApprove = ({
    tokenAddress,
    tokenId,
  }: {
    tokenAddress: HexString;
    tokenId: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [tokenAddress, tokenId],
      functionName: 'approve',
      ...defaultOverrides,
    });

  const useSafeTransferFrom = ({
    fromAddress,
    toAddress,
    tokenId,
  }: {
    fromAddress: HexString;
    toAddress: HexString;
    tokenId: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [fromAddress, toAddress, tokenId],
      functionName: 'safeTransferFrom(address,address,uint256)',
      ...defaultOverrides,
    });

  const useSetApprovalForAll = ({
    operatorAddress,
    approved,
  }: {
    approved: boolean;
    operatorAddress: string;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [operatorAddress, approved],
      functionName: 'setApprovalForAll',
      ...defaultOverrides,
    });

  const useCreateFirstRelease = ({
    totalSupply,
    collectionOnchainId,
    ipfsMetadataPath,
  }: {
    collectionOnchainId: number;
    ipfsMetadataPath: string;
    totalSupply: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [totalSupply, collectionOnchainId, ipfsMetadataPath],
      functionName: 'createFirstRelease',
      ...defaultOverrides,
    });

  const useCreateRelease = ({
    digitalMediaId,
  }: {
    digitalMediaId: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [digitalMediaId],
      functionName: 'createRelease',
      ...defaultOverrides,
    });

  const usePublishToken = ({
    totalSupply,
    ipfsMetadataPath,
    collectionId,
    collectibleId,
  }: {
    collectibleId: number;
    collectionId: number;
    ipfsMetadataPath: string;
    totalSupply: number;
  }): ContractActionObject => {
    const createReleaseResults = useCreateRelease({
      digitalMediaId: collectibleId,
    });

    const createFirstReleaseResults = useCreateFirstRelease({
      collectionOnchainId: collectibleId,
      ipfsMetadataPath,
      totalSupply,
    });

    if (Number(collectibleId) >= 0) {
      return createReleaseResults;
    }
    if (
      Number(totalSupply) >= 0 &&
      Number(collectionId) >= 0 &&
      ipfsMetadataPath
    ) {
      return createFirstReleaseResults;
    }
    throw new Error(
      `Invalid configuration for token publication: ${JSON.stringify({
        collectibleId,
        collectionId,
        ipfsMetadataPath,
        totalSupply,
      })}`
    );
  };

  const useCreateFirstReleaseInNewCollection = ({
    totalSupply,
    ipfsMetaDataPath,
    ipfsCollectionMetadataPath,
  }: {
    ipfsCollectionMetadataPath: string;
    ipfsMetaDataPath: string;
    totalSupply: number;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      address: contractAddress,
      args: [totalSupply, ipfsMetaDataPath, ipfsCollectionMetadataPath],
      functionName: 'createFirstReleaseInNewCollection',
      ...defaultOverrides,
    });

  return {
    useApprove,
    useCreateFirstRelease,
    useCreateFirstReleaseInNewCollection,
    useCreateRelease,
    usePublishToken,
    useSafeTransferFrom,
    useSetApprovalForAll,
  };
}
