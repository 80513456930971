import { SvgIcon } from '@mui/material';

export default function EventsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.33333 4.33333L6 0L7.66667 4.33333L12 6L7.66667 7.66667L6 12L4.33333 7.66667L0 6L4.33333 4.33333Z"
        fill="currentColor"
      />
      <path
        d="M2.08086 0.666667L3.49507 2.08088L2.08086 3.49509L0.666646 2.08088L2.08086 0.666667Z"
        fill="currentColor"
      />
      <path
        d="M11.3413 9.92709L9.92709 8.51288L8.51288 9.92709L9.92709 11.3413L11.3413 9.92709Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
