export enum MPColorClass {
  ActionActive = "colorActionActive",
  ActionDisabled = "colorActionDisabled",
  ActionDisabledBackground = "colorActionDisabledBackground",
  ActionFocus = "colorActionFocus",
  ActionHover = "colorActionHover",
  ActionPrimary = "colorActionPrimary",
  ActionSelected = "colorActionSelected",
  AnchorPrimary = "colorAnchorPrimary",
  BackgroundDefault = "colorBackgroundDefault",
  BackgroundOverlay = "colorBackgroundOverlay",
  BackgroundPaper = "colorBackgroundPaper",
  CommonBlack = "colorCommonBlack",
  CommonSelectedGray = "colorCommonSelectedGray",
  CommonWhite = "colorCommonWhite",
  Divider = "colorDivider",
  DropLive = "colorDropLive",
  DropUpcoming = "colorDropUpcoming",
  ErrorContrastText = "colorErrorContrastText",
  ErrorDark = "colorErrorDark",
  ErrorLight = "colorErrorLight",
  ErrorMain = "colorErrorMain",
  ErrorP20 = "colorErrorP20",
  ErrorP45 = "colorErrorP45",
  ErrorP5 = "colorErrorP5",
  ErrorP60 = "colorErrorP60",
  ErrorP80 = "colorErrorP80",
  GoldMain = "colorGoldMain",
  GoldP20 = "colorGoldP20",
  GoldP45 = "colorGoldP45",
  GoldP5 = "colorGoldP5",
  GoldP60 = "colorGoldP60",
  GoldP80 = "colorGoldP80",
  Grey100 = "colorGrey100",
  Grey200 = "colorGrey200",
  Grey300 = "colorGrey300",
  Grey400 = "colorGrey400",
  Grey50 = "colorGrey50",
  Grey500 = "colorGrey500",
  Grey600 = "colorGrey600",
  Grey700 = "colorGrey700",
  Grey800 = "colorGrey800",
  Grey900 = "colorGrey900",
  GreyA100 = "colorGreyA100",
  GreyA200 = "colorGreyA200",
  GreyA400 = "colorGreyA400",
  GreyA700 = "colorGreyA700",
  InfoContrastText = "colorInfoContrastText",
  InfoDark = "colorInfoDark",
  InfoLight = "colorInfoLight",
  InfoMain = "colorInfoMain",
  Mode = "colorMode",
  OrangeHighlight = "colorOrangeHighlight",
  OrangeLowlight = "colorOrangeLowlight",
  OrangePrimary = "colorOrangePrimary",
  PrimaryContrastText = "colorPrimaryContrastText",
  PrimaryDark = "colorPrimaryDark",
  PrimaryLight = "colorPrimaryLight",
  PrimaryMain = "colorPrimaryMain",
  PrimaryP20 = "colorPrimaryP20",
  PrimaryP45 = "colorPrimaryP45",
  PrimaryP5 = "colorPrimaryP5",
  PrimaryP60 = "colorPrimaryP60",
  PrimaryP80 = "colorPrimaryP80",
  SecondaryContrastText = "colorSecondaryContrastText",
  SecondaryDark = "colorSecondaryDark",
  SecondaryLight = "colorSecondaryLight",
  SecondaryMain = "colorSecondaryMain",
  SecondaryP20 = "colorSecondaryP20",
  SecondaryP45 = "colorSecondaryP45",
  SecondaryP5 = "colorSecondaryP5",
  SecondaryP60 = "colorSecondaryP60",
  SecondaryP80 = "colorSecondaryP80",
  SolidNeutralGray1 = "colorSolidNeutralGray1",
  SolidNeutralGray2 = "colorSolidNeutralGray2",
  SolidNeutralGray3 = "colorSolidNeutralGray3",
  SolidNeutralGray4 = "colorSolidNeutralGray4",
  SolidNeutralGray5 = "colorSolidNeutralGray5",
  SolidNeutralGray6 = "colorSolidNeutralGray6",
  SolidNeutralGray7 = "colorSolidNeutralGray7",
  SolidNeutralOffBlack = "colorSolidNeutralOffBlack",
  SuccessContrastText = "colorSuccessContrastText",
  SuccessDark = "colorSuccessDark",
  SuccessLight = "colorSuccessLight",
  SuccessMain = "colorSuccessMain",
  SuccessP20 = "colorSuccessP20",
  SuccessP45 = "colorSuccessP45",
  SuccessP5 = "colorSuccessP5",
  SuccessP60 = "colorSuccessP60",
  SuccessP80 = "colorSuccessP80",
  TextDisabled = "colorTextDisabled",
  TextPrimary = "colorTextPrimary",
  TextSecondary = "colorTextSecondary",
  TransparentPrimary = "colorTransparentPrimary",
  WarningContrastText = "colorWarningContrastText",
  WarningDark = "colorWarningDark",
  WarningLight = "colorWarningLight",
  WarningMain = "colorWarningMain",
}

export enum MPBackgroundColorClass {
  ActionActive = "backgroundColorActionActive",
  ActionDisabled = "backgroundColorActionDisabled",
  ActionDisabledBackground = "backgroundColorActionDisabledBackground",
  ActionFocus = "backgroundColorActionFocus",
  ActionHover = "backgroundColorActionHover",
  ActionPrimary = "backgroundColorActionPrimary",
  ActionSelected = "backgroundColorActionSelected",
  AnchorPrimary = "backgroundColorAnchorPrimary",
  BackgroundDefault = "backgroundColorBackgroundDefault",
  BackgroundOverlay = "backgroundColorBackgroundOverlay",
  BackgroundPaper = "backgroundColorBackgroundPaper",
  CommonBlack = "backgroundColorCommonBlack",
  CommonSelectedGray = "backgroundColorCommonSelectedGray",
  CommonWhite = "backgroundColorCommonWhite",
  Divider = "backgroundColorDivider",
  DropLive = "backgroundColorDropLive",
  DropUpcoming = "backgroundColorDropUpcoming",
  ErrorContrastText = "backgroundColorErrorContrastText",
  ErrorDark = "backgroundColorErrorDark",
  ErrorLight = "backgroundColorErrorLight",
  ErrorMain = "backgroundColorErrorMain",
  ErrorP20 = "backgroundColorErrorP20",
  ErrorP45 = "backgroundColorErrorP45",
  ErrorP5 = "backgroundColorErrorP5",
  ErrorP60 = "backgroundColorErrorP60",
  ErrorP80 = "backgroundColorErrorP80",
  GoldMain = "backgroundColorGoldMain",
  GoldP20 = "backgroundColorGoldP20",
  GoldP45 = "backgroundColorGoldP45",
  GoldP5 = "backgroundColorGoldP5",
  GoldP60 = "backgroundColorGoldP60",
  GoldP80 = "backgroundColorGoldP80",
  Grey100 = "backgroundColorGrey100",
  Grey200 = "backgroundColorGrey200",
  Grey300 = "backgroundColorGrey300",
  Grey400 = "backgroundColorGrey400",
  Grey50 = "backgroundColorGrey50",
  Grey500 = "backgroundColorGrey500",
  Grey600 = "backgroundColorGrey600",
  Grey700 = "backgroundColorGrey700",
  Grey800 = "backgroundColorGrey800",
  Grey900 = "backgroundColorGrey900",
  GreyA100 = "backgroundColorGreyA100",
  GreyA200 = "backgroundColorGreyA200",
  GreyA400 = "backgroundColorGreyA400",
  GreyA700 = "backgroundColorGreyA700",
  InfoContrastText = "backgroundColorInfoContrastText",
  InfoDark = "backgroundColorInfoDark",
  InfoLight = "backgroundColorInfoLight",
  InfoMain = "backgroundColorInfoMain",
  Mode = "backgroundColorMode",
  OrangeHighlight = "backgroundColorOrangeHighlight",
  OrangeLowlight = "backgroundColorOrangeLowlight",
  OrangePrimary = "backgroundColorOrangePrimary",
  PrimaryContrastText = "backgroundColorPrimaryContrastText",
  PrimaryDark = "backgroundColorPrimaryDark",
  PrimaryLight = "backgroundColorPrimaryLight",
  PrimaryMain = "backgroundColorPrimaryMain",
  PrimaryP20 = "backgroundColorPrimaryP20",
  PrimaryP45 = "backgroundColorPrimaryP45",
  PrimaryP5 = "backgroundColorPrimaryP5",
  PrimaryP60 = "backgroundColorPrimaryP60",
  PrimaryP80 = "backgroundColorPrimaryP80",
  SecondaryContrastText = "backgroundColorSecondaryContrastText",
  SecondaryDark = "backgroundColorSecondaryDark",
  SecondaryLight = "backgroundColorSecondaryLight",
  SecondaryMain = "backgroundColorSecondaryMain",
  SecondaryP20 = "backgroundColorSecondaryP20",
  SecondaryP45 = "backgroundColorSecondaryP45",
  SecondaryP5 = "backgroundColorSecondaryP5",
  SecondaryP60 = "backgroundColorSecondaryP60",
  SecondaryP80 = "backgroundColorSecondaryP80",
  SolidNeutralGray1 = "backgroundColorSolidNeutralGray1",
  SolidNeutralGray2 = "backgroundColorSolidNeutralGray2",
  SolidNeutralGray3 = "backgroundColorSolidNeutralGray3",
  SolidNeutralGray4 = "backgroundColorSolidNeutralGray4",
  SolidNeutralGray5 = "backgroundColorSolidNeutralGray5",
  SolidNeutralGray6 = "backgroundColorSolidNeutralGray6",
  SolidNeutralGray7 = "backgroundColorSolidNeutralGray7",
  SolidNeutralOffBlack = "backgroundColorSolidNeutralOffBlack",
  SuccessContrastText = "backgroundColorSuccessContrastText",
  SuccessDark = "backgroundColorSuccessDark",
  SuccessLight = "backgroundColorSuccessLight",
  SuccessMain = "backgroundColorSuccessMain",
  SuccessP20 = "backgroundColorSuccessP20",
  SuccessP45 = "backgroundColorSuccessP45",
  SuccessP5 = "backgroundColorSuccessP5",
  SuccessP60 = "backgroundColorSuccessP60",
  SuccessP80 = "backgroundColorSuccessP80",
  TextDisabled = "backgroundColorTextDisabled",
  TextPrimary = "backgroundColorTextPrimary",
  TextSecondary = "backgroundColorTextSecondary",
  TransparentPrimary = "backgroundColorTransparentPrimary",
  WarningContrastText = "backgroundColorWarningContrastText",
  WarningDark = "backgroundColorWarningDark",
  WarningLight = "backgroundColorWarningLight",
  WarningMain = "backgroundColorWarningMain",
}

export enum MPColorValue {
  ActionActivatedOpacity = 0.12,
  ActionActive = 'rgba(0, 0, 0, 0.54)',
  ActionDisabled = '#FFF',
  ActionDisabledBackground = 'rgba(0, 0, 0, 0.12)',
  ActionDisabledOpacity = 0.38,
  ActionFocus = 'rgba(0, 0, 0, 0.12)',
  ActionFocusOpacity = 0.12,
  ActionHover = 'rgba(0, 0, 0, 0.04)',
  ActionHoverOpacity = 0.04,
  ActionPrimary = '#FF8000',
  ActionSelected = 'rgba(0, 0, 0, 0.08)',
  ActionSelectedOpacity = 0.08,
  AnchorPrimary = '#0E98E5',
  BackgroundDefault = '#F5F5F5',
  BackgroundOverlay = 'rgba(105, 105, 105, 0.60)',
  BackgroundPaper = '#FFF',
  CommonBlack = '#000',
  CommonSelectedGray = '#EFEFEF',
  CommonWhite = '#fff',
  ContrastThreshold = 3,
  Divider = 'rgba(0, 0, 0, 0.12)',
  DropLive = '#4CAF50',
  DropUpcoming = '#FFC700',
  ErrorContrastText = '#fff',
  ErrorDark = '#C62828',
  ErrorLight = '#FF94A3',
  ErrorMain = '#EE5E5E',
  ErrorP20 = 'rgba(238,94,94,0.2)',
  ErrorP45 = 'rgba(238,94,94,0.45)',
  ErrorP5 = 'rgba(238,94,94,0.05)',
  ErrorP60 = 'rgba(238,94,94,0.6)',
  ErrorP80 = 'rgba(238,94,94,0.8)',
  GoldMain = '#DC9B5A',
  GoldP20 = 'rgba(220,155,90,0.2)',
  GoldP45 = 'rgba(220,155,90,0.45)',
  GoldP5 = 'rgba(220,155,90,0.05)',
  GoldP60 = 'rgba(220,155,90,0.6)',
  GoldP80 = 'rgba(220,155,90,0.8)',
  Grey100 = '#f5f5f5',
  Grey200 = '#eeeeee',
  Grey300 = '#e0e0e0',
  Grey400 = '#bdbdbd',
  Grey50 = '#fafafa',
  Grey500 = '#9e9e9e',
  Grey600 = '#757575',
  Grey700 = '#616161',
  Grey800 = '#424242',
  Grey900 = '#212121',
  GreyA100 = '#f5f5f5',
  GreyA200 = '#eeeeee',
  GreyA400 = '#bdbdbd',
  GreyA700 = '#616161',
  InfoContrastText = '#fff',
  InfoDark = '#01579b',
  InfoLight = '#03a9f4',
  InfoMain = '#0288d1',
  Mode = 'light',
  OrangeHighlight = '#FF8A14',
  OrangeLowlight = '#E57300',
  OrangePrimary = '#FF8000',
  PrimaryContrastText = '#fff',
  PrimaryDark = '#000',
  PrimaryLight = '#656565',
  PrimaryMain = '#0d0d0d',
  PrimaryP20 = 'rgba(13,13,13,0.2)',
  PrimaryP45 = 'rgba(13,13,13,0.45)',
  PrimaryP5 = 'rgba(13,13,13,0.05)',
  PrimaryP60 = 'rgba(13,13,13,0.6)',
  PrimaryP80 = 'rgba(13,13,13,0.8)',
  SecondaryContrastText = '#fff',
  SecondaryDark = '#000',
  SecondaryLight = '#656565',
  SecondaryMain = '#0d0d0d',
  SecondaryP20 = 'rgba(13,13,13,0.2)',
  SecondaryP45 = 'rgba(13,13,13,0.45)',
  SecondaryP5 = 'rgba(13,13,13,0.05)',
  SecondaryP60 = 'rgba(13,13,13,0.6)',
  SecondaryP80 = 'rgba(13,13,13,0.8)',
  SolidNeutralGray1 = '#D9D9D9',
  SolidNeutralGray2 = '#BDBDBD',
  SolidNeutralGray3 = '#A1A1A1',
  SolidNeutralGray4 = '#858585',
  SolidNeutralGray5 = '#696969',
  SolidNeutralGray6 = '#4D4D4D',
  SolidNeutralGray7 = '#303030',
  SolidNeutralOffBlack = '#141414',
  SuccessContrastText = '#fff',
  SuccessDark = '#1B5E20',
  SuccessLight = '#4CAF50',
  SuccessMain = '#2E7D32',
  SuccessP20 = 'rgba(46,125,50,0.2)',
  SuccessP45 = 'rgba(46,125,50,0.45)',
  SuccessP5 = 'rgba(46,125,50,0.05)',
  SuccessP60 = 'rgba(46,125,50,0.6)',
  SuccessP80 = 'rgba(46,125,50,0.8)',
  TextDisabled = 'rgba(0,0,0,0.38)',
  TextPrimary = '#0d0d0d',
  TextSecondary = '#0d0d0d',
  TonalOffset = 0.2,
  TransparentPrimary = 'transparent',
  WarningContrastText = '#fff',
  WarningDark = '#e65100',
  WarningLight = '#ff9800',
  WarningMain = '#ed6c02',
}

