/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable typescript-sort-keys/interface */
// sort-keys rules are disabled for the sake of semantics
import { createTheme } from '@mui/material/styles';

import MPDrawerTransition from '../../drawers/MPDrawerTransition';
import { Breakpoints } from './breakpoints';
import ColorValue from './colors';
import { FontFamilyCssVariableValue } from './fontFamily';
import MPPalette from './palette';

type Overrides = { [K in keyof typeof Breakpoints]: true };

declare module '@mui/material/styles' {
  interface BreakpointOverrides extends Overrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gold: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    selectedGray?: ColorValue;
  }

  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    p5: string;
    p20: string;
    p45: string;
    p80: string;
  }

  interface Palette {
    SolidNeutral: {
      [k: string]: string;
    };
  }
}

const MPTheme = createTheme({
  breakpoints: {
    values: {
      mobile: Breakpoints.mobile,
      desktop: Breakpoints.desktop,
      wide: Breakpoints.wide,
      xwide: Breakpoints.xwide, // Conceptually this is the point at which we'd serve 1000px wide images
    },
  },
  palette: MPPalette,
  typography: {
    fontFamily: FontFamilyCssVariableValue.Regular,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: 'var(--color-commonWhite)',
          color: 'var(--color-commonBlack)',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'var(--color-backgroundDefault)',
          },
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        // @ts-ignore
        TransitionComponent: MPDrawerTransition,
      },
    },
  },
});

export default MPTheme;
