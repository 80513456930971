import { useMemo } from 'react';

import { createGATrackingQueryString, EcommerceSourceType } from 'GTM';

import { tagPush } from './base';

export default function useExploreGTM() {
  return useMemo(
    () => ({
      open(
        source: EcommerceSourceType,
        currentTab: string,
        previousTab: string = undefined
      ) {
        return tagPush('open_explore', {
          detail: createGATrackingQueryString({
            currentTab,
            previousTab,
            source,
          }),
        });
      },
    }),
    []
  );
}
