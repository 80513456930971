import { SvgIcon } from '@mui/material';

export default function DiscordIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.287 5.3418C17.9076 4.70697 16.4515 4.2566 14.9557 4.0022C14.751 4.37003 14.5658 4.74848 14.4009 5.13596C12.8076 4.8946 11.1874 4.8946 9.59408 5.13596C9.42905 4.74852 9.24386 4.37007 9.03926 4.0022C7.54254 4.25875 6.08543 4.71019 4.70471 5.34512C1.96362 9.42202 1.22055 13.3977 1.59208 17.3169C3.19733 18.5092 4.99407 19.4159 6.90418 19.9977C7.33428 19.4162 7.71487 18.7993 8.0419 18.1535C7.42075 17.9203 6.82123 17.6326 6.25028 17.2936C6.40055 17.1841 6.54751 17.0712 6.68952 16.9616C8.35085 17.747 10.1641 18.1543 12 18.1543C13.8358 18.1543 15.6491 17.747 17.3104 16.9616C17.4541 17.0795 17.601 17.1924 17.7497 17.2936C17.1776 17.6331 16.577 17.9214 15.9547 18.1552C16.2814 18.8006 16.662 19.4171 17.0925 19.9977C19.0042 19.4183 20.8023 18.5119 22.4079 17.3185C22.8438 12.7735 21.6631 8.83439 19.287 5.3418ZM8.51251 14.9066C7.47717 14.9066 6.62182 13.9621 6.62182 12.8001C6.62182 11.6381 7.44745 10.6853 8.50921 10.6853C9.57097 10.6853 10.4197 11.6381 10.4015 12.8001C10.3834 13.9621 9.56766 14.9066 8.51251 14.9066ZM15.4874 14.9066C14.4504 14.9066 13.5984 13.9621 13.5984 12.8001C13.5984 11.6381 14.424 10.6853 15.4874 10.6853C16.5508 10.6853 17.393 11.6381 17.3748 12.8001C17.3567 13.9621 16.5426 14.9066 15.4874 14.9066Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
