import { merge } from 'lodash';
import Masonry, { MasonryProps } from '@mui/lab/Masonry';

export type MPMasonryProps = MasonryProps;

export default function MPMasonry({ sx = {}, ...passedProps }: MPMasonryProps) {
  const baseSx = {};
  if (passedProps.columns === 1) {
    baseSx[`& > *`] = {
      margin: '8px 0',
      width: '100%',
    };
  }
  const mergedSx = merge(baseSx, sx);
  return <Masonry sx={mergedSx} {...passedProps} />;
}
