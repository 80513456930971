/**
 * @generated SignedSource<<40e91020639e6b997bd44657b81c16ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountDeletePaypalWithdrawalMutation$variables = {};
export type AccountDeletePaypalWithdrawalMutation$data = {
  readonly deletePayoutPaypal: {
    readonly success: boolean | null;
  } | null;
};
export type AccountDeletePaypalWithdrawalMutation = {
  variables: AccountDeletePaypalWithdrawalMutation$variables;
  response: AccountDeletePaypalWithdrawalMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DeletePayoutPaypalMutation",
    "kind": "LinkedField",
    "name": "deletePayoutPaypal",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountDeletePaypalWithdrawalMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountDeletePaypalWithdrawalMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8c6fba254f19908159fb73049f6b537e",
    "id": null,
    "metadata": {},
    "name": "AccountDeletePaypalWithdrawalMutation",
    "operationKind": "mutation",
    "text": "mutation AccountDeletePaypalWithdrawalMutation {\n  deletePayoutPaypal {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b6365deb1a19ee0ee47f96d36aeafd1";

export default node;
