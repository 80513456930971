import { useContractRead } from 'wagmi';

import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { HexString } from 'utils/jwt/walletUtils';

export default function useTokenReadContract({
  abi,
  contractAddress,
}: WagmiWriteContractManagerProps) {
  const useIsApprovedForAll = ({
    owner,
    operatorAddress,
  }: {
    operatorAddress: string;
    owner: HexString;
  }) => {
    const {
      data,
      isError,
      isLoading,
      isFetching,
      isRefetching,
      isSuccess,
      status,
      error,
      refetch,
    } = useContractRead({
      abi,
      address: contractAddress,
      args: [owner, operatorAddress],
      functionName: 'isApprovedForAll',
    });

    return {
      data,
      error,
      isError,
      isFetching,
      isLoading,
      isRefetching,
      isSuccess,
      refetch,
      status,
    };
  };

  return { useIsApprovedForAll };
}
