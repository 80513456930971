import { useState } from 'react';

import CurrencyDisplayMode from 'types/enums/CurrencyDisplayMode';
import { NFTType } from 'types/graphql/NFT';
import { getNftBuyNowDisplayPrice } from 'utils/nftUtils';

export default function useBuyNowState(nft: NFTType) {
  const { isCustodialOwner } = nft;
  const currencyDisplay = isCustodialOwner
    ? CurrencyDisplayMode.USD
    : CurrencyDisplayMode.ETH;

  const currentBuyNowPrice = nft.listing?.liveSale
    ? parseFloat(getNftBuyNowDisplayPrice(nft, currencyDisplay))
    : null;

  return useState<string>(currentBuyNowPrice?.toString());
}
