import { useCallback, useEffect, useState } from 'react';

import {
  MILLISECONDS_PER_SECOND,
  MINUTES_PER_HOUR,
  SECONDS_PER_MINUTE,
} from 'constants/Time';

export default function useNFTBuyReserveLockCountdown(endDate: Date) {
  const getSecondsRemaining = (endDateTime: number) => {
    const secondsRemaining = Math.floor(
      ((endDateTime - new Date().getTime()) %
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)) /
        MILLISECONDS_PER_SECOND
    );
    return secondsRemaining > 0 ? secondsRemaining : 0;
  };

  const getMinutesRemaining = (endDateTime: number) => {
    const minutesRemaining = Math.floor(
      ((endDateTime - new Date().getTime()) %
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR)) /
        (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)
    );
    return minutesRemaining > 0 ? minutesRemaining : 0;
  };

  const getIsExpired = useCallback(
    () => endDate.getTime() < new Date().getTime(),
    [endDate]
  );

  const [secondsRemaining, setSecondsRemaining] = useState(
    getSecondsRemaining(endDate.getTime())
  );

  const [minutesRemaining, setMinutesRemaining] = useState(
    getMinutesRemaining(endDate.getTime())
  );

  const [isExpired, setIsExpired] = useState(getIsExpired());

  useEffect(() => {
    const interval = setInterval(() => {
      const secondsLeft = getSecondsRemaining(endDate.getTime());
      setSecondsRemaining(secondsLeft);
      setMinutesRemaining(getMinutesRemaining(endDate.getTime()));
      if (getIsExpired()) {
        setIsExpired(getIsExpired);
        clearInterval(interval);
      }
    }, MILLISECONDS_PER_SECOND);

    return () => clearInterval(interval);
  }, [endDate, getIsExpired]);

  return { isExpired, minutesRemaining, secondsRemaining };
}
