/**
 * @generated SignedSource<<1b07a047f754f09050e55e3ed817d18a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BidType = "PRODUCT" | "GLOBAL_BID" | "RANKED_AUCTION";
export type CreateGeneralBidOnNFTInCreditCardArguments = {
  bidAmountInCents: any;
  nftId: number;
  rememberCard: boolean;
  bidType: BidType;
  paymentIntentId?: string | null;
  paymentMethodId?: string | null;
};
export type BidOnNftInCreditCardMutation$variables = {
  requestData: CreateGeneralBidOnNFTInCreditCardArguments;
};
export type BidOnNftInCreditCardMutation$data = {
  readonly createGeneralBidOnNftInCc: {
    readonly intentId: string | null;
    readonly success: boolean | null;
  } | null;
};
export type BidOnNftInCreditCardMutation = {
  variables: BidOnNftInCreditCardMutation$variables;
  response: BidOnNftInCreditCardMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requestData"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "requestData",
        "variableName": "requestData"
      }
    ],
    "concreteType": "CreateGeneralBidOnNFTInCreditCardMutation",
    "kind": "LinkedField",
    "name": "createGeneralBidOnNftInCc",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "intentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BidOnNftInCreditCardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BidOnNftInCreditCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dfb813dc024de138c0e7d03f341f672b",
    "id": null,
    "metadata": {},
    "name": "BidOnNftInCreditCardMutation",
    "operationKind": "mutation",
    "text": "mutation BidOnNftInCreditCardMutation(\n  $requestData: CreateGeneralBidOnNFTInCreditCardArguments!\n) {\n  createGeneralBidOnNftInCc(requestData: $requestData) {\n    intentId\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "d310bf7afdec46ca1ffd5f77c4c6900c";

export default node;
