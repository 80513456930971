import { NFTType } from 'types/graphql/NFT';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import useDigitalMediaSaleCoreContract from '../useDigitalMediaSaleCoreContract';

interface useProductCancelBidEthReturnObject {
  reset: ContractActionObject['mutate']['reset'];
  simulationError: Error | null;
  write: ContractActionObject['mutate']['write'];
  writeAsync: ContractActionObject['mutate']['writeAsync'];
}

function useProductCancelBidEth(
  nft: NFTType
): useProductCancelBidEthReturnObject {
  const currentBid = nft.listing.liveBid;
  const { useCancelBid } = useDigitalMediaSaleCoreContract({
    abi: JSON.parse(currentBid.contract.abidata).abi,
    contractAddress: currentBid.contract.address as HexString,
  });

  const cancelBid = useCancelBid({
    onchainId: currentBid.onchainId,
    tokenAddress: nft.contract.address,
    tokenId: nft.onchainId,
  });

  return {
    reset: cancelBid.mutate.reset,
    simulationError: cancelBid.simulate.error || null,
    write: cancelBid.mutate.write,
    writeAsync: cancelBid.mutate.writeAsync,
  };
}

export default useProductCancelBidEth;
