import { usePreloadedQuery } from 'react-relay';
import { Hash } from 'viem';

import NFTContractQueryType, {
  NFTContractQuery,
} from 'graphql/__generated__/NFTContractQuery.graphql';
import {
  ContractNameEnum,
  CreatorContractType,
} from 'types/__generated__/graphql';

import { APP_NAME } from 'constants/Utils';
import useApprovedCreatorRegistryContract from 'hooks/contracts/useApprovedCreatorRegistryContract';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import PendingApprovalsManager from '../PendingApprovalsManager';
import Approval from './Approval';

interface DropSaleCoreApprovalProps {
  contract: CreatorContractType;
  walletAddress: Hash;
  className?: string;
  contractTitle?: string;
}

function SetDropSaleCoreButton({
  contractQuery,
  contract,
  walletAddress,
  contractTitle,
  className,
}: {
  contractQuery: WithLoadQueryProps<NFTContractQuery>;
} & DropSaleCoreApprovalProps) {
  const { nftContract: DropSaleCoreContract } =
    usePreloadedQuery<NFTContractQuery>(
      NFTContractQueryType,
      contractQuery.queryRef
    );
  const { useSetOboApprovalForAll } = useApprovedCreatorRegistryContract({
    abi: JSON.parse(contract.abidata).abi as any,
    contractAddress: contract.address as Hash,
  });

  const fetchParams = PendingApprovalsManager.useReadDropSaleCoreApproval({
    accountAddress: walletAddress,
    contract,
    dropSaleCoreAddress: DropSaleCoreContract.address as Hash,
  });
  const manager = useSetOboApprovalForAll({
    account: walletAddress,
    isApproved: !fetchParams[0],
    operatorAddress: DropSaleCoreContract.address as Hash,
  });

  return (
    <Approval
      className={className}
      title={`Contract Approval${contractTitle ? ` - ${contractTitle}` : ''}`}
      explanation={`You will grant MakersPlace the ability to list artworks for sale on ${APP_NAME}`}
      fetchParams={fetchParams}
      manager={manager}
      walletAddress={walletAddress}
    />
  );
}

const SetDropSaleCoreButtonWithLoad = withLoadQuery(SetDropSaleCoreButton, {
  contractQuery: { concreteRequest: NFTContractQueryType },
});

export default function DropSaleCoreApproval(props: DropSaleCoreApprovalProps) {
  return (
    <SetDropSaleCoreButtonWithLoad
      {...props}
      contractQuery={{
        variables: { name: ContractNameEnum.DropSaleCore },
      }}
    />
  );
}
