import { SvgIcon } from '@mui/material';

export interface CarouselArrowProps {
  backgroundColor: string;
  borderColor: string;
}

export default function CarouselArrow({
  backgroundColor,
  borderColor,
  ...props
}) {
  return (
    <SvgIcon {...props}>
      <rect
        x="35.5"
        y="79.5"
        width="35"
        height="79"
        rx="17.5"
        transform="rotate(180 35.5 79.5)"
        fill={backgroundColor}
      />
      <path
        d="M11.5352 40L19.1638 47.5H22.5352L14.9066 40L22.5352 32.5H19.1638L11.5352 40Z"
        fill="currentColor"
      />
      <rect
        x="35.5"
        y="79.5"
        width="35"
        height="79"
        rx="17.5"
        transform="rotate(180 35.5 79.5)"
        stroke={borderColor}
        fillOpacity="0"
      />
    </SvgIcon>
  );
}
