import { SvgIcon } from '@mui/material';

export default function SettingsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02 0V2.99862C3.24654 3.73329 2 5.47204 2 7.5C2 9.52796 3.24654 11.2667 5.02 12.0014L5.02 24H8.795L8.795 12.0014C10.5685 11.2667 11.815 9.52796 11.815 7.5C11.815 5.47204 10.5685 3.73329 8.795 2.99862V0H5.02ZM6.9075 6.375C6.28204 6.375 5.775 6.87868 5.775 7.5C5.775 8.12132 6.28204 8.625 6.9075 8.625C7.53296 8.625 8.04 8.12132 8.04 7.5C8.04 6.87868 7.53296 6.375 6.9075 6.375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1 0H20.875V11.9986C22.6485 12.7333 23.895 14.472 23.895 16.5C23.895 18.528 22.6485 20.2667 20.875 21.0014V24H17.1V21.0014C15.3265 20.2667 14.08 18.528 14.08 16.5C14.08 14.472 15.3265 12.7333 17.1 11.9986V0ZM17.855 16.5C17.855 15.8787 18.362 15.375 18.9875 15.375C19.613 15.375 20.12 15.8787 20.12 16.5C20.12 17.1213 19.613 17.625 18.9875 17.625C18.362 17.625 17.855 17.1213 17.855 16.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
