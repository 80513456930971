import { useCallback } from 'react';

import {
  MPDropdownSelect,
  MPDropdownSelectProps,
} from '@mp-frontend/core-components';

import {
  ArtistSortTypeEnum,
  EditorialSortTypeEnum,
  ExhibitionSortTypeEnum,
  SortOrderEnum,
  SortTypeEnum,
} from 'types/__generated__/graphql';

export type SortFilterValue<TType, TOrder> = Partial<{
  order: TOrder;
  type: TType;
}>;

interface SortFilterProps<TType, TOrder> {
  onChange: (value: SortFilterValue<TType, TOrder>) => void;
  value: SortFilterValue<TType, TOrder>;
}

function SortFilter<TType, TOrder>({
  config,
  value,
  onChange,
}: SortFilterProps<TType, TOrder> & Pick<MPDropdownSelectProps, 'config'>) {
  const firstSortType = Object.keys(config)[0];

  const handleChange = useCallback(
    (values: string[]) => {
      const [newSortType, newSortOrder] = values[0].split('-') as [
        TType,
        TOrder
      ];
      onChange({ order: newSortOrder, type: newSortType });
    },
    [onChange]
  );

  return (
    <MPDropdownSelect
      config={config}
      selectionType="single"
      title="Sorting"
      values={
        value.type !== firstSortType && value.type !== undefined
          ? [`${value.type}-${value.order}`]
          : [firstSortType]
      }
      onChange={handleChange}
    />
  );
}

export const getArtworkSortLabel = (
  sortType: SortTypeEnum,
  sortOrder: SortOrderEnum
) => {
  if (sortType === SortTypeEnum.Recommended || sortType === undefined) {
    return 'Recommended';
  }
  if (sortType === SortTypeEnum.Popularity) {
    return 'Popularity'; // WARNING: To be deprecated soon.
  }
  if (sortType === SortTypeEnum.CreatedAt) {
    if (sortOrder === SortOrderEnum.Asc) return 'Oldest';

    if (sortOrder === SortOrderEnum.Desc) return 'Newest';
  } else if (sortType === SortTypeEnum.Price) {
    if (sortOrder === SortOrderEnum.Asc) return 'Lowest Price';

    if (sortOrder === SortOrderEnum.Desc) return 'Highest Price';
  }

  return '';
};

const ARTWORK_FILTERS: [SortTypeEnum, SortOrderEnum][] = [
  [SortTypeEnum.Recommended, undefined],
  [SortTypeEnum.CreatedAt, SortOrderEnum.Desc],
  [SortTypeEnum.CreatedAt, SortOrderEnum.Asc],
  [SortTypeEnum.Price, SortOrderEnum.Asc],
  [SortTypeEnum.Price, SortOrderEnum.Desc],
];

const ARTWORK_FILTER_CONFIG = {
  ...Object.fromEntries(
    ARTWORK_FILTERS.map(([sortType, sortOrder]) => [
      sortType !== SortTypeEnum.Recommended
        ? `${sortType}-${sortOrder}`
        : SortTypeEnum.Recommended,
      getArtworkSortLabel(sortType, sortOrder),
    ])
  ),
};

export type SortArtworksFilterValue = SortFilterValue<
  SortTypeEnum,
  SortOrderEnum
>;

export function SortArtworksFilter({
  value,
  onChange,
}: SortFilterProps<SortTypeEnum, SortOrderEnum>) {
  return (
    <SortFilter<SortTypeEnum, SortOrderEnum>
      config={ARTWORK_FILTER_CONFIG}
      value={value}
      onChange={onChange}
    />
  );
}

export const getExhibitionSortLabel = (
  sortType: ExhibitionSortTypeEnum,
  sortOrder: SortOrderEnum
) => {
  switch (sortType) {
    case ExhibitionSortTypeEnum.DropsAt:
      if (sortOrder === SortOrderEnum.Asc) return 'Oldest';
      return 'Newest';
    case undefined:
      return 'Newest';
    default:
      return '';
  }
};

const EXHIBITION_FILTERS: [ExhibitionSortTypeEnum, SortOrderEnum][] = [
  [ExhibitionSortTypeEnum.DropsAt, SortOrderEnum.Desc],
  [ExhibitionSortTypeEnum.DropsAt, SortOrderEnum.Asc],
];

const EXHIBITION_FILTER_CONFIG = {
  ...Object.fromEntries(
    EXHIBITION_FILTERS.map(([sortType, sortOrder]) => [
      [sortType, sortOrder].filter(Boolean).join('-'),
      getExhibitionSortLabel(sortType, sortOrder),
    ])
  ),
};

export type SortExhibitionsFilterValue = SortFilterValue<
  ExhibitionSortTypeEnum,
  SortOrderEnum
>;

export function SortExhibitionsFilter({
  value,
  onChange,
}: SortFilterProps<ExhibitionSortTypeEnum, SortOrderEnum>) {
  return (
    <SortFilter<ExhibitionSortTypeEnum, SortOrderEnum>
      config={EXHIBITION_FILTER_CONFIG}
      value={value}
      onChange={onChange}
    />
  );
}

export const getArtistSortLabel = (
  sortType: ArtistSortTypeEnum,
  sortOrder: SortOrderEnum
) => {
  if (
    sortType === ArtistSortTypeEnum.TotalSales &&
    sortOrder === SortOrderEnum.Desc
  ) {
    return 'Total Sales';
  }
  if (
    sortType === ArtistSortTypeEnum.LastSoldAt &&
    sortOrder === SortOrderEnum.Desc
  ) {
    return 'Last Sold';
  }
  if (
    sortType === ArtistSortTypeEnum.LatestCreation &&
    sortOrder === SortOrderEnum.Desc
  ) {
    return 'Latest Creation';
  }
  if (
    sortType === ArtistSortTypeEnum.QuantScore &&
    sortOrder === SortOrderEnum.Desc
  ) {
    return 'Recommended';
  }

  return '';
};

const ARTIST_SORT_FILTERS: [ArtistSortTypeEnum, SortOrderEnum][] = [
  [ArtistSortTypeEnum.QuantScore, SortOrderEnum.Desc],
  [ArtistSortTypeEnum.LastSoldAt, SortOrderEnum.Desc],
  [ArtistSortTypeEnum.LatestCreation, SortOrderEnum.Desc],
  [ArtistSortTypeEnum.TotalSales, SortOrderEnum.Desc],
];

const ARTIST_FILTER_CONFIG = {
  ...Object.fromEntries(
    ARTIST_SORT_FILTERS.map(([sortType, sortOrder]) => [
      [sortType, sortOrder].filter(Boolean).join('-'),
      getArtistSortLabel(sortType, sortOrder),
    ])
  ),
};

export type SortArtistsFilterValue = SortFilterValue<
  ArtistSortTypeEnum,
  SortOrderEnum
>;

export function SortArtistFilter({
  value,
  onChange,
}: SortFilterProps<ArtistSortTypeEnum, SortOrderEnum>) {
  return (
    <SortFilter<ArtistSortTypeEnum, SortOrderEnum>
      config={ARTIST_FILTER_CONFIG}
      value={value}
      onChange={onChange}
    />
  );
}

export const getEditorialSortLabel = (
  sortType: EditorialSortTypeEnum,
  sortOrder: SortOrderEnum
) => {
  switch (sortType) {
    case EditorialSortTypeEnum.CreatedAt:
      if (sortOrder === SortOrderEnum.Asc) return 'Oldest';
      return 'Newest';
    case undefined:
      return 'Newest';
    default:
      return '';
  }
};

const EDITORIAL_FILTERS: [EditorialSortTypeEnum, SortOrderEnum][] = [
  [EditorialSortTypeEnum.CreatedAt, SortOrderEnum.Desc],
  [EditorialSortTypeEnum.CreatedAt, SortOrderEnum.Asc],
];

const EDITORIAL_FILTER_CONFIG = {
  ...Object.fromEntries(
    EDITORIAL_FILTERS.map(([sortType, sortOrder]) => [
      [sortType, sortOrder].filter(Boolean).join('-'),
      getEditorialSortLabel(sortType, sortOrder),
    ])
  ),
};

export type SortEditorialsFilterValue = SortFilterValue<
  EditorialSortTypeEnum,
  SortOrderEnum
>;

export function SortEditorialsFilter({
  value,
  onChange,
}: SortFilterProps<EditorialSortTypeEnum, SortOrderEnum>) {
  return (
    <SortFilter<EditorialSortTypeEnum, SortOrderEnum>
      config={EDITORIAL_FILTER_CONFIG}
      value={value}
      onChange={onChange}
    />
  );
}

export default SortFilter;
