.container {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 var(--heroCard-padding);

  @media (--mq-is-mobile) {
    padding: var(--heroCard-padding) 0 0 0;
  }
}

.rail {
  box-sizing: border-box;
  padding: 0 var(--heroCard-padding) 0 0;
  width: calc(350px + var(--heroCard-padding));

  @media (--mq-is-mobile) {
    padding: 0 20px var(--heroCard-padding);
    width: 100%;
  }
}

.description {
  --line-clamp: 4;
}

.image {
  overflow: hidden;
  position: relative;

  &.desktop {
    display: none;

    @media (--mq-is-not-mobile) {
      display: block;
      flex-basis: 0;
      flex-grow: 1;

      &::before {
        background-image: var(--heroEditorialCard-backgroundImageUrl);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: ' ';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: scale(1);
        transition: transform 300ms ease-out;
        width: 100%;
      }

      &:hover::before {
        transform: scale(1.05);
      }
    }
  }

  &.mobile {
    display: none;

    @media (--mq-is-mobile) {
      background-image: var(--heroEditorialCard-backgroundImageUrl);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      height: 314px;
      width: 100%;
    }
  }
}
