/**
 * @generated SignedSource<<2eab4dcda21d0ca2abfd50bf84cc405a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NFTContractQuery$variables = {
  name?: string | null;
  address?: string | null;
};
export type NFTContractQuery$data = {
  readonly nftContract: {
    readonly title: string;
    readonly symbol: string;
    readonly abidata: string;
    readonly address: string;
  } | null;
};
export type NFTContractQuery = {
  variables: NFTContractQuery$variables;
  response: NFTContractQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abidata",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NFTContractQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "NFTEthereumContractType",
        "kind": "LinkedField",
        "name": "nftContract",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NFTContractQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "NFTEthereumContractType",
        "kind": "LinkedField",
        "name": "nftContract",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "875d14f855fa1941f2b4645a2a0930bc",
    "id": null,
    "metadata": {},
    "name": "NFTContractQuery",
    "operationKind": "query",
    "text": "query NFTContractQuery(\n  $name: String\n  $address: String\n) {\n  nftContract(name: $name, address: $address) {\n    title\n    symbol\n    abidata\n    address\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad049105a830f4272e14fe27417443db";

export default node;
