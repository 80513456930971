import { useCallback, useState } from 'react';
import { useMutation } from 'react-relay';

import { MPActionButton, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import AccountInvalidateSessions, {
  AccountInvalidateSessionsMutation,
} from 'graphql/__generated__/AccountInvalidateSessionsMutation.graphql';

import ErrorDisplay from 'components/Error';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';

import SubSection from '../common/SubSection';

export default function LogoutSubSection() {
  const [invalidateSessions, isSessionsInvalidating] =
    useMutation<AccountInvalidateSessionsMutation>(AccountInvalidateSessions);
  const [error, setError] = useState<Error>(null);

  const handleClick = useCallback(
    () =>
      invalidateSessions({
        onCompleted: () => setError(null),
        onError: (serverError) => setError(serverError),
        variables: {},
      }),
    [invalidateSessions]
  );

  return (
    <SubSection title="Log out of all other active sessions">
      <div className={joinClasses(CSSGlobal.Flex.Col, CSSGap[16])}>
        <MPActionButton
          disabled={isSessionsInvalidating}
          fullWidth
          isLoading={isSessionsInvalidating}
          size="large"
          variant="secondary"
          onClick={handleClick}
        >
          Logout
        </MPActionButton>

        <ErrorDisplay
          error={error}
          className={joinClasses(
            MPFonts.paragraphSmall,
            CSSGlobal.TextAlign.Centered
          )}
          noPadding
        />
      </div>
    </SubSection>
  );
}
