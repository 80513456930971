.attributesListContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.attributesListTitle {
  display: flex;
  align-items: center;
}

.attributeItem {
  color: var(--color-commonBlack);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.attributeItemTypeContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.attributeItemType {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  white-space: nowrap;
}

.attributeItemPercentageLabel {
  color: var(--color-SolidNeutralGray4);
  margin-left: auto;
}

.attributeItemPercentageContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4px;
  align-items: flex-start;
}

.attributePercentageProgressEllipse {
  width: 4px;
  height: 4px;
  flex-shrink: 0;

  &.light {
    color: var(--color-SolidNeutralGray1);
  }
}

.attributeItemRare {
  color: var(--color-goldMain);
  fill: var(--color-goldMain);
}
