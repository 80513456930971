/**
 * This contains the list of legacy APIs we're using. When you see this file, you
 * should bug the relevant people for time to rewrite these APIs.
 */
const LEGACY_API = {
  EDIT_TAGS: (metadataId: number) => `/digital-media/${metadataId}/tags/`,
  GET_VALID_COLLABORATORS: (username: string) =>
    `/send/filter/followers/?username=${username}&only_creators=true`,
  MUTATE_CATEGORY_ORDERING: `/store/categories/ordering/`,
  MUTATE_DIGITAL_MEDIA_ORDERING: (profileSlug: string) =>
    `/store/${profileSlug}/update-creation-ordering/`,
};

export default LEGACY_API;
