import { ComponentProps } from 'react';

import { joinClasses } from '@mp-frontend/core-utils';

import CSSGlobal from 'types/enums/css/Global';

import * as styles from 'css/pages/product/ProductPage.module.css';

export default function ProductLayoutContainer({
  id,
  children,
}: ComponentProps<'div'>) {
  return (
    <div
      id={id}
      className={joinClasses(styles.productPage, CSSGlobal.NoScrollbar)}
    >
      {children}
    </div>
  );
}
