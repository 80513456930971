import { MPColorClass, MPFonts } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { APP_NAME } from 'constants/Utils';
import useMPConnect from 'hooks/wallet/useMPConnect';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import { type WalletId } from 'utils/jwt/walletUtils';
import { get24PxConnectorIcon } from 'utils/wallet/connectorUtils';

import PendingApprovalsManager, {
  useGetApprovals,
} from './ManageWalletDialog/Contracts/PendingApprovalsManager';
import WalletNameDisplay from './WalletNameDisplay';
import {
  BalanceSubcomponent,
  CreatedArtworksSubcomponent,
} from './WalletSubComponents';

import * as pageStyles from 'css/pages/settings/wallet/WalletPage.module.css';

import { type Wallets } from 'Session';

interface WalletProps {
  wallet: Wallets[0];
  showAlert?: boolean;
}

export default function CreatorWallet({
  wallet,
  showAlert = false,
}: WalletProps) {
  const { findConnectorByAddress } = useMPConnect();
  const Icon = get24PxConnectorIcon(
    findConnectorByAddress(wallet?.address)?.id as WalletId
  );
  const [didApproveDigitalMediaSaleCore] =
    PendingApprovalsManager.useReadDigitalMediaSaleCoreApproval(wallet.address);
  const [hasPendingApprovals] = useGetApprovals();

  return (
    <>
      <div className={pageStyles.itemStatusRow}>
        <span
          className={joinClasses(
            CSSGlobal.Flex.InlineRowCenterAlign,
            CSSGap[8],
            CSSGlobal.Ellipsis
          )}
        >
          {Icon || null}
          <span className={CSSGlobal.Ellipsis}>
            {wallet.isCustodialCreatorWallet ? (
              <span
                className={MPColorClass.SolidNeutralGray5}
              >{`${APP_NAME} Minting Wallet`}</span>
            ) : (
              <WalletNameDisplay
                name={wallet?.name}
                address={wallet?.address}
              />
            )}
          </span>
        </span>
        {!didApproveDigitalMediaSaleCore &&
          !wallet.isCustodialCreatorWallet && <span>Needs&nbsp;Approval</span>}
        {!!wallet.isSelectedMintingWallet && <span>Selected</span>}
      </div>
      <div className={pageStyles.walletAttributes}>
        <CreatedArtworksSubcomponent wallet={wallet} />
        <BalanceSubcomponent wallet={wallet} />
      </div>
      {!!showAlert &&
        !!wallet.isSelectedMintingWallet &&
        !wallet.isCustodialCreatorWallet &&
        !!hasPendingApprovals && (
          <div
            className={joinClasses(
              MPFonts.paragraphSmall,
              CSSGlobal.TextAlign.Centered,
              MPColorClass.CommonBlack,
              pageStyles.callout
            )}
          >
            Please review the contracts associated with this wallet.
          </div>
        )}
    </>
  );
}
