import { useReadContract } from 'wagmi';

import { WagmiWriteContractManagerProps } from 'types/WagmiWriteContractProps';
import { ContractActionObject, HexString } from 'utils/jwt/walletUtils';

import useCreateContractActionObject from './useCreateContractActionObject';
import useDefaultContractOverrides from './useDefaultContractOverrides';

export default function useManifoldContract({
  abi,
  contractAddress,
  account,
}: WagmiWriteContractManagerProps) {
  const defaultOverrides = useDefaultContractOverrides();

  const useApproveAdmin = ({
    adminAddress,
  }: {
    adminAddress: HexString;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      account: account ?? defaultOverrides.account,
      address: contractAddress,
      args: [adminAddress],
      functionName: 'approveAdmin',
      gasLimit: defaultOverrides.gasLimit,
    });

  const useRevokeAdmin = ({
    adminAddress,
  }: {
    adminAddress: HexString;
  }): ContractActionObject =>
    useCreateContractActionObject({
      abi,
      account: account ?? defaultOverrides.account,
      address: contractAddress,
      args: [adminAddress],
      functionName: 'revokeAdmin',
      gasLimit: defaultOverrides.gasLimit,
    });

  const useIsAdmin = ({
    adminAddress,
    query,
  }: {
    adminAddress: HexString;
    query?: any;
  }) =>
    useReadContract({
      abi,
      account: account ?? defaultOverrides.account,
      address: contractAddress,
      args: [adminAddress],
      functionName: 'isAdmin',
      query,
    });

  return { useApproveAdmin, useIsAdmin, useRevokeAdmin };
}
