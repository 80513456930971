import { useMemo } from 'react';
import { usePreloadedQuery } from 'react-relay';

import { joinClasses } from '@mp-frontend/core-utils';

import HomepageUpcomingExhibitionsQueryType, {
  HomepageUpcomingExhibitionsQuery,
} from 'graphql/__generated__/HomepageUpcomingExhibitionsQuery.graphql';

import UpcomingExhibitionCard from 'components/cards/UpcomingExhibitionCard';
import Carousel from 'components/carousel/Carousel';
import withDefaultErrorBoundary from 'utils/hocs/withDefaultErrorBoundary';
import withLoadQuery, { WithLoadQueryProps } from 'utils/hocs/withLoadQuery';

import * as styles from 'css/pages/homepage/HomepageStandardCardsSection.module.css';

interface UpcomingExhibitionsProps {
  exhibitionsQuery: WithLoadQueryProps<HomepageUpcomingExhibitionsQuery>;
}

function UpcomingExhibitions({
  exhibitionsQuery: { queryRef, invalidate },
}: UpcomingExhibitionsProps) {
  const result = usePreloadedQuery<HomepageUpcomingExhibitionsQuery>(
    HomepageUpcomingExhibitionsQueryType,
    queryRef
  );
  const exhibitions = useMemo(
    () =>
      result.topCarouselsUpcomingAndLive.edges.map(
        (exhibition) => exhibition.node
      ),
    [result]
  );

  return exhibitions.length > 0 ? (
    <Carousel
      header={<>Upcoming Exhibitions</>}
      containerName="upcoming_exhibitions"
      logViewAbleCardsToGA={() => undefined}
      items={exhibitions.map((exhibition, idx, array) => (
        <UpcomingExhibitionCard
          key={exhibition.id}
          className={joinClasses(styles.card, {
            [styles.last]: idx === array.length - 1,
          })}
          exhibition={exhibition}
          invalidate={invalidate}
        />
      ))}
    />
  ) : null;
}

export default withDefaultErrorBoundary(
  withLoadQuery(UpcomingExhibitions, {
    exhibitionsQuery: { concreteRequest: HomepageUpcomingExhibitionsQueryType },
  }),
  { errorFallback: null, suspenseless: true }
);
