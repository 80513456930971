import { useCallback } from 'react';

import BaseArtistsFilter, {
  ArtistsFilterProps,
} from 'components/filters/ArtistsFilter';
import useActivityGTM from 'utils/GTM/activity';

const TITLE = 'Artists';

export default function ArtistsFilter({
  value,
  onChange,
}: Pick<ArtistsFilterProps, 'value' | 'onChange'>) {
  const track = useActivityGTM();
  const handleToggle = useCallback(
    (open: boolean) => track.toggleFilter(TITLE, open),
    [track]
  );

  return (
    <BaseArtistsFilter
      title={TITLE}
      value={value}
      onChange={onChange}
      onToggle={handleToggle}
    />
  );
}
