import { Dispatch, SetStateAction } from 'react';

import { NFTContractQuery$data } from 'graphql/__generated__/NFTContractQuery.graphql';

import { NFTType } from 'types/graphql/NFT';
import { nftHasCustodialSale } from 'utils/nftUtils';

import { ProductOwnerStates } from '../ProductOwnerUpdateListing';
import Custodial from './Custodial';
import Wallet from './Wallet';

interface ProductSetBuyNowProps {
  nft: NFTType;
  saleContract: Pick<
    NFTContractQuery$data['nftContract'],
    'abidata' | 'address'
  >;
  setCurrentTransactionHash: Dispatch<SetStateAction<string>>;
  setOwnershipState: Dispatch<SetStateAction<ProductOwnerStates>>;
}

function ProductSetBuyNow({
  nft,
  saleContract,
  setCurrentTransactionHash,
  setOwnershipState,
}: ProductSetBuyNowProps) {
  return nftHasCustodialSale(nft) ? (
    <Custodial nft={nft} setOwnershipState={setOwnershipState} />
  ) : (
    <Wallet
      saleContract={saleContract}
      setCurrentTransactionHash={setCurrentTransactionHash}
      nft={nft}
      setOwnershipState={setOwnershipState}
    />
  );
}

export default ProductSetBuyNow;
