import { MPColorClass } from '@mp-frontend/core-components';
import { joinClasses } from '@mp-frontend/core-utils';

import { PageWithFooter } from 'components/Footer';
import CSSGlobal from 'types/enums/css/Global';

import useSetDocTitle from '../useSetDocTitle';
import PersonalSection from './personal';
import SecuritySection from './security';

import * as styles from 'css/pages/settings/wallet/index.module.css';

function AccountSettingsPage() {
  useSetDocTitle('Account');

  return (
    <PageWithFooter>
      <div
        className={joinClasses(
          MPColorClass.CommonBlack,
          CSSGlobal.Cursor.Default,
          styles.walletPage
        )}
      >
        <PersonalSection />
        <SecuritySection />
      </div>
    </PageWithFooter>
  );
}

export default AccountSettingsPage;
